import { AllACTIVITIES_SUCCESS, GETACTIVITY_SUCCESS, MYOPENACTIVITIES_SUCCESS, ALLMYACTIVITIES_SUCCESS } from '../constants/';


const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const AllActivitiesSuccess = (payload) => {
      return {
        type: AllACTIVITIES_SUCCESS,
        payload: payload.response.data
      }
}

export const AllMyOpenActivitiesSuccess = (payload) => {
  return {
    type: MYOPENACTIVITIES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllMyActivitiesSuccess = (payload) => {
  return {
    type: ALLMYACTIVITIES_SUCCESS,
    payload: payload.response.data
  }
}


export const GetActivitySuccess = (payload) => {
  return {
    type: GETACTIVITY_SUCCESS,
    payload: payload.response.data
  }
}


export function AllActivities() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetAllActivity', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllActivitiesSuccess({ response })) },
      () => { dispatch(AllActivitiesSuccess(null)) },
    );
  }
}

export function AllMyOpenActivities(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetMyOpenActivity', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllMyOpenActivitiesSuccess({ response })) },
      () => { dispatch(AllMyOpenActivitiesSuccess(null)) },
    );
  }
}

export function AllMyActivities(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetAllMyActivity', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllMyActivitiesSuccess({ response })) },
      () => { dispatch(AllMyActivitiesSuccess(null)) },
    );
  }
}





export function GetActivity(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetActivity', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetActivitySuccess({ response })) },
      () => { dispatch(GetActivitySuccess(null)) }
    );
  }
}


export function CreateActivity() {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, status: '1', userfrom: '',userfrom_name: '', userto: '',userto_name: '', crea_date:'',description:'',titel:'',done_date:'',due_date:'',newto:'1',newfrom:'',closedby:'',viewon:'',remarks:'',closedon:''}
    response.data = { data: data }
    dispatch(GetActivitySuccess({ response }));
  }
}

