import React from 'react';
import { connect } from 'react-redux';

import {CreateActivity,GetActivity,AllActivities,AllMyActivities } from "../../../redux/settings/activityActions";
import {setSecurityTitle, zetpopup, setPanelRight, setTitleRight, zetListMain, ZetListData, setMainTitle, setSubTitle } from "../../../redux/settings/action";
import {GetAccount} from "../../../redux/settings/accountActions";

import Badge from '@material-ui/core/Badge';
import ShoppingCartIcon from '@material-ui/icons/Message';

import PopActivity from "../../../views/activities/PopActivity.jsx";
import GenFunc from "../../../functions/gen_functions.js";
import SqlFunction from "../../../functions/sql_functions";


import {
	Nav,
	NavItem,
	NavLink,
	Navbar,
	NavbarBrand,
	Collapse,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
} from 'reactstrap';
//import * as data from './data';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from '../../../assets/images/logo-icon.png';
import logolighticon from '../../../assets/images/logo-light-icon.png';
import logodarktext from '../../../assets/images/logo-text.png';
import logolighttext from '../../../assets/images/logo-light-text.png';

const mapStateToProps = state => ({
	...state
});


class Header extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
		this.showMobilemenu = this.showMobilemenu.bind(this);
		this.sidebarHandler = this.sidebarHandler.bind(this);
		this.NewActivity = this.NewActivity.bind(this);
		this.ClickActivity = this.ClickActivity.bind(this);
		this.ClosePopActivity = this.ClosePopActivity.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.componentWillUnmount = this.componentWillUnmount.bind(this);
		this.getMyAct = this.getMyAct.bind(this);
		this.getAllMyAct = this.getAllMyAct.bind(this);
		this.getAllAct = this.getAllAct.bind(this);
        this.change = this.change.bind(this);
 		this.handleKeyPress = this.handleKeyPress.bind(this);

		this.state = {
			isOpen: false,
			activities: [],
			account: '',

		};
	}
	/*--------------------------------------------------------------------------------*/
	/*To open NAVBAR in MOBILE VIEW                                                   */
	/*--------------------------------------------------------------------------------*/
	toggle() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

   async handleKeyPress(event) {
      if(event.key === 'Enter')
      {
		event.preventDefault();      	
   		let accdata = await SqlFunction.DoSql('GetAccountbynumb',{id:this.state.account});
   		if (accdata && accdata.id)
   		{
        	this.props.dispatch(setSubTitle('Klantenfiche'))
	        await this.props.dispatch(GetAccount(accdata.id))
    	    this.props.history.push('/account');
      		this.setState({account:''})
      	}
      }
    }	
   	change(event, valid, stateName) 
    {
      if (event.target) 
      {
        let value = event.target.value;
        this.setState({account:value})
      } 
      else 
      {
        this.setState({account:event})
      }
    }

    async componentDidMount() {
  		this.timer = setInterval(() => { this.getMyAct(); }, 30000);
    	if (this.props && this.props.user && this.props.user.userData)
    	{
    		let actdata = await SqlFunction.DoSql('GetMyOpenActivity',{id:this.props.user.userData.id});
    		this.setState({activities:actdata});
		}
    }
    async getMyAct() {
    	if (this.props && this.props.user && this.props.user.userData)
    	{
    		let actdata = await SqlFunction.DoSql('GetMyOpenActivity',{id:this.props.user.userData.id});
    		this.setState({activities:actdata});
		}
    }

    async getAllAct() {
    	let that = this;
   		await this.props.dispatch(AllActivities());
	    this.props.dispatch(setPanelRight(false));
    	this.props.dispatch(setTitleRight(''));	   
    	this.props.dispatch(setMainTitle('Alle Activiteiten'));	   
		this.props.dispatch(setSecurityTitle('AllActivities'))

   		await this.props.dispatch(ZetListData('AllActivities','/allactivities'));
    	await this.props.dispatch(zetListMain('AllActivities','/allactivities'));
        that.props.history.push('/allactivities');

    }

    async getAllMyAct() {
    	let that = this;
   		await this.props.dispatch(AllMyActivities(this.props.user.userData.id));
	    this.props.dispatch(setPanelRight(false));
    	this.props.dispatch(setTitleRight(''));	   
    	this.props.dispatch(setMainTitle('Mijn Activiteiten'));	   
		this.props.dispatch(setSecurityTitle('AllMyActivities'))

   		await this.props.dispatch(ZetListData('AllMyActivities','/allmyactivities'));
    	await this.props.dispatch(zetListMain('AllMyActivities','/allmyactivities'));
        that.props.history.push('/allmyactivities');

    }


 	componentWillUnmount() {
    	clearInterval(this.timer)
  	}


    ClosePopActivity() {
        this.props.dispatch(zetpopup(null));
    }	

	async NewActivity() {
        await this.props.dispatch(CreateActivity());
        window.scrollTo(0, 0);
        this.props.dispatch(zetpopup((<PopActivity open={true} close={this.ClosePopActivity} title="Post IT"/>)));

	}

	async ClickActivity(data) {
        await this.props.dispatch(GetActivity(data.id));
        window.scrollTo(0, 0);
        this.props.dispatch(zetpopup((<PopActivity open={true} close={this.ClosePopActivity} title="Post IT"/>)));

	}



	/*--------------------------------------------------------------------------------*/
	/*To open SIDEBAR-MENU in MOBILE VIEW                                             */
	/*--------------------------------------------------------------------------------*/
	showMobilemenu() {
		document.getElementById('main-wrapper').classList.toggle('show-sidebar');
	}

	sidebarHandler = () => {
		let element = document.getElementById('main-wrapper');
		switch (this.props.settings.activeSidebarType) {
			case 'full':
			case 'iconbar':
				element.classList.toggle('mini-sidebar');
				if (element.classList.contains('mini-sidebar')) {
					element.setAttribute('data-sidebartype', 'mini-sidebar');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;

			case 'overlay':
			case 'mini-sidebar':
				element.classList.toggle('full');
				if (element.classList.contains('full')) {
					element.setAttribute('data-sidebartype', 'full');
				} else {
					element.setAttribute(
						'data-sidebartype',
						this.props.settings.activeSidebarType
					);
				}
				break;
			default:
		}
	};

	render() {
		return (
			<header className="topbar navbarbg" data-navbarbg={this.props.settings.activeNavbarBg}>
				<Navbar className={"top-navbar " + (this.props.settings.activeNavbarBg === "skin6" ? 'navbar-light' : 'navbar-dark')} expand="md">
					<div className="navbar-header" id="logobg" data-logobg={this.props.settings.activeLogoBg}>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}>
							<i className="ti-menu ti-close" />
						</span>
						{/*--------------------------------------------------------------------------------*/}
						{/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
						{/*--------------------------------------------------------------------------------*/}
						<NavbarBrand href="/">
							<b className="logo-icon">
								<img src={logodarkicon} alt="homepage" className="dark-logo" />
								<img
									src={logolighticon}
									alt="homepage"
									className="light-logo"
								/>
							</b>
							<span className="logo-text">
								<img src={logodarktext} alt="homepage" className="dark-logo" />
								<img
									src={logolighttext}
									className="light-logo"
									alt="homepage"
								/>
							</span>
						</NavbarBrand>
						{/*--------------------------------------------------------------------------------*/}
						{/* Mobile View Toggler  [visible only after 768px screen]                         */}
						{/*--------------------------------------------------------------------------------*/}
						<span className="topbartoggler d-block d-md-none" onClick={this.toggle}>
							<i className="ti-more" />
						</span>
					</div>
					<Collapse className="navbarbg" isOpen={this.state.isOpen} navbar data-navbarbg={this.props.settings.activeNavbarBg} >
						<Nav className="float-left" navbar>
							<NavItem>
								<NavLink href="#" className="d-none d-md-block" onClick={this.sidebarHandler}>
									<i className="icon-menu" />
								</NavLink>
							</NavItem>
							<NavItem>
								<form className="app-search">
									<input value={this.state.account} onKeyDown={this.handleKeyPress} onChange={event => this.change(event,true)} type="text" className="form-control" placeholder="Opzoeken klant" />
								</form>
							</NavItem>								

						</Nav>
						<Nav className="ml-auto float-right" navbar>
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Notifications Dropdown                                                   */}
							{/*--------------------------------------------------------------------------------*/}
							<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret>
       								{(this.props.activity && this.props.activity.MyNewCount && this.props.activity.MyNewCount > 0) ? 
       								(  
										<Badge color="secondary" badgeContent={this.props.activity.MyNewCount}>
          									<ShoppingCartIcon />
        								</Badge>								
       								)
       								:
       								(  
										<Badge color="secondary">
          									<ShoppingCartIcon />
        								</Badge>								
       								)
       								}
								</DropdownToggle>
								{this.state.activities  && Array.isArray(this.state.activities) &&
								<DropdownMenu right className="mailbox">
									<div className="d-flex no-block align-items-center p-3 bg-light">
										<h5 className="mb-0 font-medium">Post IT</h5>
									</div>
									<div className="message-center notifications" style={{overflowX: 'hidden', overflowY: 'auto',maxHeight: '40vh'}}>
										{/*<!-- Message -->*/}
										{this.state.activities.map((notification, index) => {
											return (
												<span href="" className="message-item" key={index}>
	                								{(this.props && this.props.user && this.props.user.userData && this.props.user.userData.id === notification.userfrom && notification.newfrom === '1') ? 
	                  								(  
														<span style={{color:'#fb9678', cursor:'pointer'}} onClick={() => {this.ClickActivity(notification)}}>
															<i className="ti-user"></i>
														</span>
	                  								)
	                  								:
	                  								(this.props && this.props.user && this.props.user.userData && this.props.user.userData.id === notification.userfrom && notification.newfrom === 1) ? 
	                  								(
														<span style={{color:'#fb9678', cursor:'pointer'}} onClick={() => {this.ClickActivity(notification)}}>
															<i className="ti-user"></i>
														</span>
	                  								)
	                  								:
	                  								(this.props && this.props.user && this.props.user.userData && this.props.user.userData.id === notification.userfrom) ? 
	                  								(
														<span onClick={() => {this.ClickActivity(notification)}}>
															<i className="ti-user"></i>
														</span>

	                  								)
	                  								:
	                  								(notification.newto === 1) ? 
	                  								(
														<span style={{color:'#fb9678', cursor:'pointer'}} onClick={() => {this.ClickActivity(notification)}}>
															<i className="ti-comment-alt"></i>
														</span>
	                  								)
	                  								:
	                  								(notification.newto === '1') ? 
	                  								(
														<span style={{color:'#fb9678', cursor:'pointer'}} onClick={() => {this.ClickActivity(notification)}}>
															<i className="ti-comment-alt"></i>
														</span>
	                  								)
	                  								:
	                  								(
														<span style={{cursor:'pointer'}} onClick={() => {this.ClickActivity(notification)}}>
															<i className="ti-comment-alt"></i>
														</span>
	                  								)
	                  								}

													<div className="mail-contnet">
														<h5  onClick={() => {this.ClickActivity(notification)}}  className="message-title">{notification.titel}</h5>
														<span className="mail-desc">
														<div onClick={() => {this.ClickActivity(notification)}} dangerouslySetInnerHTML={{ __html: notification.description }}></div>
														</span>
														<span onClick={() => {this.ClickActivity(notification)}} className="time">{notification.crea_date}</span>
													</div>
												</span>
											);
										})}
									</div>
									<div style={{cursor:'pointer'}} onClick={() => {this.getAllMyAct()}} className="nav-link text-center mb-1 text-dark">
										<strong>Bekijk all mijn berichten</strong>{' '}
										<i className="fa fa-angle-right" />
									</div>


									{ GenFunc.allowed(this.props.user.userData.secrows,'AllPostIt','edit') &&
									<div style={{cursor:'pointer'}} onClick={() => {this.getAllAct()}}  className="nav-link text-center mb-1 text-dark">
										<strong>Bekijk alle berichten</strong>{' '}
										<i className="fa fa-angle-right" />
									</div>
									}

								</DropdownMenu>
								}
							</UncontrolledDropdown>
							{/*--------------------------------------------------------------------------------*/}
							{/* End Notifications Dropdown                                                     */}
							{/*--------------------------------------------------------------------------------*/}
							{/*--------------------------------------------------------------------------------*/}
							{/* Start Messages Dropdown                                                        */}
							{/*--------------------------------------------------------------------------------*/}
							<UncontrolledDropdown nav inNavbar>
								<DropdownToggle nav caret>
									<i className="font-16 icon-note" onClick={this.NewActivity} />
								</DropdownToggle>
							</UncontrolledDropdown>
							{/*--------------------------------------------------------------------------------*/}
							{/* End Messages Dropdown                                                          */}
						</Nav>
					</Collapse>
				</Navbar>
			</header>
		);
	}
}
export default connect(mapStateToProps)(Header);

