import React from 'react';
import GenFunc from "../../functions/gen_functions.js";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux'
import styles from "../../assets/components/customInputStyle.jsx";
import {AllPeriodes, AllBtwPercentages, AllStartdatums} from "../../redux/settings/paramActions";
import { withStyles } from '@material-ui/core/styles';


var {data_id, data_code} = '';
var changed = false;
const mapStateToProps = state => ({
  ...state
});


class GridEditSelect extends React.Component {


    async componentWillMount() {
      await this.GetMenItems();
    }


    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
        this.GetMenItems = this.GetMenItems.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);        
        this.componentDidMount = this.componentDidMount.bind(this);        
        this.componentWillUnmount = this.componentWillUnmount.bind(this);        


        this.inputid = GenFunc.MakeId()
        data_id = this.props.value;
        if (this.props.colDef.selectcel)
        {
          data_code = this.props.data[this.props.colDef.selectcel];
        }
        this.state = {
            value:this.props.value,
            error:false,
            menuitems:[],
            menudata:[],
        }
    }
    async componentWillUnmount() 
    {
      if (data_id && data_id.length > 0 && changed === true )
      {
        var colId = this.props.column.getId();
        if (this.props.colDef.selectcel && data_code && data_code.length > 2)
        {
          this.props.data[this.props.colDef.selectcel] = data_code;
        }
        this.props.node.setDataValue(colId, data_id);
      }
    }

    componentDidMount() {
        this.props.agGridReact.props.onCellValueCheck(this,this.props.value)
        setTimeout(() => {
          document.activeElement.blur();
          let element = document.getElementById(this.inputid);
          element.focus()
       }, 10);    
    }
  async GetMenItems() {
    let lijst = [];
    let slkey = '';
    let listitem = null;
    let data=[];
    if (this.props.colDef.type)
    {
      switch (this.props.colDef.type) 
      {

        case 'AllPeriodes':
          if (!this.props.param.AllPeriodesData) {
            await this.props.dispatch(AllPeriodes());
          }
          if (this.props.param.AllPeriodesData && this.props.param.AllPeriodesData.data)
          {
            data = this.props.param.AllPeriodesData.data
          }
          break;
        case 'AllStartdatums':
          if (!this.props.param.AllStartdatumsData) {
            await this.props.dispatch(AllStartdatums());
          }
          if (this.props.param.AllStartdatumsData && this.props.param.AllStartdatumsData.data) {
            data = this.props.param.AllStartdatumsData.data
          }
          break;

        case 'AllTerugActie':
          data = [{id:'1',text:'Afsluiten job zonder facturatie'},{id:'2',text:'Afsluiten job met facturatie'},{id:'3',text:'Bezwaar'},{id:'4',text:'Verbeterde aangifte'},]
          break;
    
        case 'AllTarType':
            data = [{id:'PER',text:'Percentage'},{id:'FIX',text:'Vast bedrag'},{id:'AAN',text:'Aantal'}]
            break;


        case 'AllBtwPercentages':
          if (!this.props.param.AllBtwPercentagesData) {
            await this.props.dispatch(AllBtwPercentages());
          }
          if (this.props.param.AllBtwPercentagesData && this.props.param.AllBtwPercentagesData.data) {
            data = this.props.param.AllBtwPercentagesData.data
          }
          break;
        
        default:    
          break;
      }  

      for (let i=0; i < data.length; i++) 
      {
        slkey = 'APC'+GenFunc.MakeId();
        listitem =   (<MenuItem key={slkey} classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value={data[i].id}>{data[i].text}</MenuItem>);
        lijst.push(listitem);  
      }
    }
    this.setState({menuitems:lijst,menudata:data})
  }
  handleFocus() {
    this.setState({ focus: true });
  }  
  handleBlur() {
    this.setState({ focus: false });
  }  

    getValue() {
        return this.state.value;
    }
    isPopup() {
        return false;
    }
    change(event, valid, stateName) 
    {
      var colId = this.props.column.getId();
      let result = this.state.menudata.filter(o=> o.id === event.target.value);
      if (Array.isArray(result))
      {
        if (result.length > 0)
        {
          this.setState({value:result[0].id})
          data_code = result[0].text;
          data_id = result[0].id;
          changed = true;

          this.props.node.setDataValue(colId, result[0].id);

          if (this.props.colDef.selectcel && this.props.colDef.selectcel.length > 2)
          {
            this.props.data[this.props.colDef.selectcel] = result[0].text;
          }
          this.props.agGridReact.props.onCellValueCheck(this,result[0])
          this.props.agGridReact.api.tabToNextCell();
        }
      }
    }    

    render() {
      const {
        classes,
        labelText,
        id,
        labelProps,
        inputProps,
        inputRootCustomClasses,
      } = this.props;
      const formControlProps={fullWidth: true}
      const marginTop = classNames({
          [inputRootCustomClasses]: inputRootCustomClasses !== undefined
        });      
      const labelClasses = classNames({
        [" " + classes.labelRootError]: this.state.error,
        [" " + classes.labelRootSuccess]: this.state.success && !this.state.error
      });
      const underlineClasses = classNames({
        [classes.brutUnderline]: !this.state.error,
        [classes.underlineError]: this.state.error,
        [classes.underline]: true,
      });
      const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: this.state.white
      });
      var formControlClasses;
      if (formControlProps !== undefined) {
        formControlClasses = classNames(
          formControlProps.className,
          classes.formControl
        );
      } else {
        formControlClasses = classes.formControl;
      }
      let strlengte = (this.props.column.actualWidth - 22).toString()+'px';
      return (
        <FormControl style={{top:'-25px',position:'relative',marginBottom:'15px', width:strlengte}} {...formControlProps}  className={formControlClasses}>
          {labelText !== undefined ? (
            <InputLabel
              className={classes.labelRoot + " " + labelClasses}
              htmlFor={id}
              {...labelProps}
            >
              {labelText}
            </InputLabel>
          ) : null}
          <Select
            classes={{select:classes.select,
              root: this.props.classes.selectinputbrut,
              selectMenu:classes.selectMenu,
              disabled:classes.disabled,
              icon:classes.icon
            }}
            style={{position:'relative',marginBottom:'15px', width:strlengte,paddingRight:'5px'}}
            value={this.state.value}
            input={
              <Input
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                classes={{
                  input: inputClasses,
                  root: marginTop,
                  disabled: classes.disabled,
                  underline: underlineClasses,
                }}
                disabled={this.props.disabled}
                style={{paddingBottom:'15px',fontSize:'small'}}
                onChange ={this.change}
                {...inputProps}
                id={this.inputid}
              />
            }
          >
          {this.state.menuitems}

          </Select>          
        </FormControl>          
      );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(GridEditSelect));
