import React, { createRef } from 'react';
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';

import { Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import FileDownload from "../../components/FileDownload.jsx";
import { AllPostcodes } from "../../redux/settings/paramActions";
import { AllKantoren, GetKantoor, GetKantContact, GetDocKantContact, CreateKantContact, GetContKantoor } from "../../redux/settings/kantoorActions";
import { setPanelRight, setTitleRight, setSubTitle, zetview, clickview } from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import Dropzone from 'react-dropzone';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SubSpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SubSpeedDialIcon';
import SaveIcon from '@material-ui/icons/Save';
import FilePlus from '@material-ui/icons/FilePlus';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import PopAddZipCode from "../zipcode/PopAddZipcode.jsx";


const dropzoneRef = createRef();
const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point 
    if (dropzoneRef.current) {
        dropzoneRef.current.open()
    }
};
moment.locale('nl-be');
const accdropid = GenFunc.MakeId()
const accframeid = GenFunc.MakeId()
const accformid = GenFunc.MakeId()
var dropdoc = null;
const mapStateToProps = state => ({
    ...state
});

class KantContactpage extends React.Component {

    state = {
        DownloadData: { id: '' },
        tableheight: Math.floor(window.innerHeight * .70) + 'px',
        tabvalue: '3',
        edit: this.props.kantoor && this.props.kantoor.MainContData && this.props.kantoor.MainContData.data && this.props.kantoor.MainContData.data.id === 0 ? true : false,
        save: false,
        subsave: false,
        adrestel: 0,
        checkval: false,
        subspeedzien:true,
        PopUpScreen: null,
        data: this.props.kantoor && this.props.kantoor.MainContData && this.props.kantoor.MainContData.data && this.props.kantoor.MainContData.data.id === 0 ? this.props.kantoor.MainContData.data : null,
        speedopen: false,
        speedactions: [
            { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
            { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe Contactpersoon' },
            { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
        ],
        subspeedactions: [
            { icon: <FilePlus />, name: 'Document', tooltip: 'Document toevoegen' },
        ],
        subspeedopen: false,  


    };


    constructor(props, context) {
        super(props);
        this.resizeFunction = this.resizeFunction.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.DocCellClick = this.DocCellClick.bind(this);
        this.handleSpeedClose = this.handleSpeedClose.bind(this);
        this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
        this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
        this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);
        this.ZetEdit = this.ZetEdit.bind(this);
        this.CheckSave = this.CheckSave.bind(this)
        this.handleTabChange = this.handleTabChange.bind(this)
        this.MainSave = this.MainSave.bind(this)
        this.checkconfirm = this.checkconfirm.bind(this)
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
        this.handleJa = this.handleJa.bind(this)
        this.ActionClick = this.ActionClick.bind(this)
        this.SubActionClick = this.SubActionClick.bind(this)
        this.KantDetClick = this.KantDetClick.bind(this)
        this.ClosePopZipcode = this.ClosePopZipcode.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);

    }

    async handleTabChange(event, newValue) {
        if (this.state.save === true && this.state.edit === true ) 
        {
            await this.checkconfirm(this.props.kantoor.MainContData);
        }
        let subspeedzien = false;

        if (newValue === '3') {

            // Documenten
            let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'Docu','edit') === true) ? [
            { icon: <FilePlus />, name: 'Document', tooltip: 'Document toevoegen' },
            ]: null;
            this.setState({subspeedactions:subspeedactions})
            subspeedzien = true;
            await this.props.dispatch(GetDocKantContact(this.props.kantoor.MainContData.data.id))
        }
        this.setState({ tabvalue: newValue, subspeedzien:subspeedzien });
    };

    async ClosePopZipcode(retdata) {
        let data = this.state.data;
        this.setState({ PopUpScreen: null });
        if (retdata) 
        {
            data.zipcode_txt = retdata.zipcode + ' - '+retdata.place;
            data.zipcode = retdata.id;
            data.country_txt = retdata.country_txt;
            data.country = retdata.country;
            this.setState({data:data})
        }
        await this.props.dispatch(AllPostcodes());
    }

    handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:true });
    };

    handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
    };

    handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
    };

    handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
    };
    async SubActionClick(name) {
        switch (name) {
            case 'Document':
                if (this.state.save === true && this.state.edit === true) 
                {
                  await this.checkconfirm(this.props.kantoor.MainContData);
                }
                else
                {
                    this.setState({edit:false})
                }
                dropdoc = null;
                let element = document.getElementById(accdropid);
                element.click()
                break;

            default:
                break;
        }
        this.setState({ speedopen: false, subspeedopen:false });
      }


    async ActionClick(name) {
        switch (name) {
            case 'Edit':
                this.setState({ edit: true, data: this.props.kantoor.MainContData.data, speedopen: false })
                break;

            case 'New':
                await this.props.dispatch(CreateKantContact())
                this.setState({ edit: true, data: this.props.kantoor.MainContData.data, speedopen: false })
                break;

            case 'Save':
                this.MainSave();
                break;
    
            default:
                break;
        }
        this.setState({ speedopen: false, subspeedopen:false });
    }

    ZetEdit() {
        this.setState({ edit: true, data: this.props.kantoor.MainContData.data })
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
        this.props.dispatch(setTitleRight('Kantoor contactpersonen'))
        this.props.dispatch(setPanelRight(true))

        this.handleTabChange(null, '3');
    }

    componentWillUnmount() {
        this.checkconfirm(this.props.kantoor.MainContData);
        this.props.dispatch(setPanelRight(false))
        this.props.dispatch(setTitleRight(''))
        window.removeEventListener("resize", this.resizeFunction);
    }

    async componentWillReceiveProps(nextProps, nextState) {
        if (this.props.kantoor.MainContData) {
            if (this.props.kantoor.MainContData.data.id !== nextProps.kantoor.MainContData.data.id) {
                await this.checkconfirm(this.props.kantoor.MainContData);
                if (nextProps.kantoor.MainContData.data.id === 0) {
                    this.setState({ edit: true, save: false, subsave: false })
                }
                else {
                    await this.props.dispatch(GetDocKantContact(nextProps.kantoor.MainContData.data.id))
                    this.setState({ edit: false, save: false, subsave: false })
                }
                this.handleTabChange(null, '3');
            }
        }
    }

    async KantDetClick(waarde) {
        this.props.dispatch(zetview(this.props.kantoor.MainContData.data.last_name + ' ' + this.props.kantoor.MainContData.data.first_name, this.props.kantoor.MainContData.data.id, 'KANTCONT'));
        this.props.dispatch(clickview({ id: this.props.kantoor.MainContData.data.parent_account, vorm: 'KANT' }));
        await this.props.dispatch(AllKantoren(this.props))
        this.props.dispatch(setSubTitle('Kantoor'))
        await this.props.dispatch(GetKantoor(this.props.kantoor.MainContData.data.parent_account))
        await this.props.dispatch(GetContKantoor(this.props.kantoor.MainData.data.id))
        this.props.history.push('/kantoor');
        return;
    }

    async handleJa(parameters) {
        if (this.state.save === true) {
            let retdata = await SqlFunction.DoSql('ChgKantContact', this.state.data);
            if (retdata.nwzip === true) {
                this.props.dispatch(AllPostcodes())
            }

        }
        if (this.state.subsave === true) {
        }
        //    this.setState({edit:false,save:false,subsave:false})
    }


    checkconfirm(parameters) {

        if (this.state.save === true || this.state.subsave === true) {
            confirmAlert({
                closeOnEscape: false,
                closeOnClickOutside: false,
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Aanpassingen niet doorgevoerd.</h1>
                            <p>Wens je de aanpassingen alsnog te bewaren?</p>
                            <button
                                onClick={() => {
                                    this.handleJa(parameters);
                                    onClose();
                                }}
                            >
                                Ja
            </button>
                            <button onClick={() => {
                                this.setState({ edit: false, save: false, subsave: false, checkval: false })
                                onClose();
                            }}
                            >
                                Nee
            </button>
                        </div>
                    );
                }
            });
        }

    }

    resizeFunction() {
        if (window.innerHeight >= 960) {
            this.setState({ tableheight: Math.floor(window.innerHeight * .70) + 'px' });
        }
        else {
            this.setState({ tableheight: '600px' });
        }
    }


    async MainSave() {
        if (this.state.save === true) {
            let retdata = await SqlFunction.DoSql('ChgKantContact', this.state.data);
            if (retdata && retdata.nwzip === true) {
                this.props.dispatch(AllPostcodes())
            }
            this.setState({ edit: false, save: false, data: null, checkval: false })
            if (retdata) {
                await this.props.dispatch(GetKantContact(retdata.id))
            }
        }
    }

    async DocCellClick(waarde) {
        let column = waarde.column.colDef.field;
        if (column === 'upload') {
            dropdoc = waarde.data;
            let element = document.getElementById(accdropid);
            element.click()
            return;
        }
        if (column === 'verwijder') {
            await SqlFunction.DoSql('DeleteFile', waarde.data);
            await this.props.dispatch(GetDocKantContact(this.props.kantoor.MainContData.data.id))
            return;
        }
        await this.setState({ DownloadData: waarde.data });
        document.getElementById(accformid).submit();
        return;
    }

    getContextMenuItems(params, sleutel) {
        return null;
    }


    CheckSave(data) {
        let terug = true
        terug = Validation.ValidCheck(this.props.settings.sub_title, data.first_name, 'first_name', this, this.props.kantoor.AllContData.data, data);
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.last_name, 'last_name', this, this.props.kantoor.AllContData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.e_mail, 'e_mail', this, this.props.kantoor.AllContData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.parent_account, 'parent_account', this, this.props.kantoor.AllContData.data, data);
        }
        return !terug;
    }

    async ErrorCheck(that, waarde, type, colId) {
      let data = this.state.data;
      let terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.kantoor.AllContData.data, data);
      that.setState({ error: terug })
    }      


    async onDrop(files) {
        const formData = new FormData();
        this.handleTabChange(null, '0');
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('file', file);

        }
        if (dropdoc) {
            formData.set('kant_id', dropdoc.kant_id);
            formData.set('kantcont_id', dropdoc.kant_contact_id);
            formData.set('type', dropdoc.type);
            formData.set('docid', dropdoc.id);
            formData.set('docdata', dropdoc);
        }
        else {
            formData.set('kant_id', this.props.kantoor.MainContData.data.parent_account);
            formData.set('kantcont_id', this.props.kantoor.MainContData.data.id);
            formData.set('type', 'kantcontact');
        }

        await SqlFunction.DoSqlBody('saveFile', formData);
        dropdoc = null;
        this.handleTabChange(null, '3');
    };

    async ValueCheck(that, waarde, type, colId) {
        let data = this.state.data;
        let terug = false;
        switch (colId) {
            case 'kantoor_name':
                data.kantoor_name = waarde.value;
                data.parent_account = waarde.id;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.kantoor.AllContData.data, data);
                break;

            case 'zipcode_txt':
                if (waarde.id === 0)
                {
                    // openen aanmaken nieuwe postcode
                    this.setState({PopUpScreen:(<PopAddZipCode open={true}  toevobject={that}  waarde={waarde.value} close={this.ClosePopZipcode} title='Toevoegen postode aan lijst' />)});
                    // Einde aanmaken nieuwe postcode
                }

                data.zipcode_txt = waarde.value;
                data.zipcode = waarde.id;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.kantoor.AllContData.data, data);
                if (that.overzdata) {
                    data.country_txt = that.overzdata.country_txt;
                    data.country = that.overzdata.country;
                }
                break;

            default:
                data[colId] = waarde;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.kantoor.AllContData.data, data);
                break;
        }
        that.setState({ error: terug })
        terug = await this.CheckSave(data);
        this.setState({ save: terug, data: data, checkval: false })
    }

    render() {
        const { classes } = this.props;
        var { tableheight } = this.state;
        let mailref = (this.props.kantoor && this.props.kantoor.MainContData && this.props.kantoor.MainContData.data && this.props.kantoor.MainContData.data.e_mail.length > 3) ? "mailto:" + this.props.kantoor.MainContData.data.e_mail : null;

        return (
            <div >
                <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
                    <Grid container>
                        <Grid item xs={8}>
                            <h4 tag="h4" className="mt-3" onClick={() => { this.setState({ edit: false }) }}>{this.props.settings.sub_title}</h4>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        icon={<SpeedDialIcon style={{ zIndex: '10' }} />}
                        direction="left"
                        classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
                        onClose={this.handleSpeedClose}
                        onOpen={this.handleSpeedOpen}
                        open={this.state.speedopen}
                    >
                        {this.state.speedactions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipPlacement='bottom'
                                FabProps={{
                                    disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows, 'AllKantContacts', 'edit')) ? false :
                                    (action.name === 'Edit' && (this.state.edit === true)) ? true :
                                    (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows, 'AllKantContacts', 'edit')) ? true :
                                    (action.name === 'New' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows, 'AllKantContacts', 'edit')) ? false :
                                    (action.name === 'New' && (this.state.edit === true )) ? true :
                                    (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows, 'AllKantContacts', 'edit')) ? true :
                                    (action.name === 'Save' && (this.state.edit === true ) && this.state.save === true) ? false :
                                    (action.name === 'Save' && (this.state.edit === true ) && this.state.save === false) ? true :
                                    (action.name === 'Save' && this.state.edit === false ) ? true :
                                    (action.name === 'Save' && this.state.save === false) ? true : false
                                }}
                                onClick={() => { this.ActionClick(action.name) }}
                                tooltipTitle={action.tooltip}
                            />
                        ))}
                    </SpeedDial>
                    {this.state.subspeedzien && this.state.edit === false && 
                    <SpeedDial
                      ariaLabel="SpeedDial example"
                      icon={<SubSpeedDialIcon style={{zIndex:'10'}}/>}
                      direction="left"
                      classes={{root: classes.subspeedDialToolb, fab: classes.subspeedFabRoot}}           
                      onClose={this.handleSubSpeedClose}
                      onOpen={this.handleSubSpeedOpen}
                      open={this.state.subspeedopen}          
                    >
                      {this.state.subspeedactions.map(action => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipPlacement='bottom'
                          FabProps={{disabled: (action.name === 'Document' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllKantContacts','edit')) ? false :
                                               (action.name === 'Document' && (this.state.edit === true)) ? true :
                                               (action.name === 'Document' && !GenFunc.allowed(this.props.user.userData.secrows,'AllKantContacts','edit')) ? true :
                                               (action.name === 'Save' && this.state.save === false) ? true : false}}
                          onClick={() => {this.SubActionClick(action.name)}}                             
                          tooltipTitle={action.tooltip}
                        />
                      ))}
                    </SpeedDial>     
                    }     
                </Toolbar>
                {this.props.kantoor && this.props.kantoor.MainContData && this.props.kantoor.MainContData.data &&
                    <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick={true} noKeyboard={true}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container" style={{ width: '100%', maxWidth: '100%' }}>
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <button style={{ display: 'none' }} id={accdropid} type="button" onClick={openDialog}></button>
                                    <FileDownload formid={accformid} frameid={accframeid} DocData={this.state.DownloadData} />
                                    <Grid container style={{width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={this.state.edit === false ? 3 : 5}>
                                                <Card className='card_brut'>
                                                    {this.state.edit === false &&
                                                        <PerfectScrollbar className="sidebar-nav">
                                                            <CardBody style={{ textAlign: 'center', height: Math.floor(window.innerHeight * .85) + 'px' }}>
                                                                <h4 style={{ marginTop: '25px', cursor: 'pointer' }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">{this.props.kantoor.MainContData.data.last_name} {this.props.kantoor.MainContData.data.first_name} </h4>
                                                                <h6 style={{ cursor: 'pointer' }} onClick={() => { this.KantDetClick() }}>{this.props.kantoor.MainContData.data.kantoor_name}</h6>
                                                                <h6> {this.props.kantoor.MainContData.data.jobtitle}</h6>
                                                                <div onClick={() => {this.ZetEdit()}} style={{width:'100%', cursor: 'pointer'}}>
                                                                    <div className='div_line' style={{ marginBottom: '25px' }}></div>
                                                                    <h6 style={{ cursor: 'pointer' }}> <a href={mailref}>{this.props.kantoor.MainContData.data.e_mail}</a></h6>
                                                                    <small className="text-muted p-t-30 db">Telefoon</small>
                                                                    <h6>{this.props.kantoor.MainContData.data.main_phone}</h6>
                                                                    <small className="text-muted p-t-30 db">Gsm nummer</small>
                                                                    <h6>{this.props.kantoor.MainContData.data.mobile_phone}</h6>
                                                                    <div className='div_line' style={{ marginBottom: '25px' }}></div>
                                                                    <small className="text-muted p-t-30 db">Adres</small>
                                                                    <h6 onClick={() => { this.ZetEdit() }}>{this.props.kantoor.MainContData.data.street}</h6>
                                                                    <h6 onClick={() => { this.ZetEdit() }}>{this.props.kantoor.MainContData.data.zipcode_txt}</h6>
                                                                    <div className='div_line' style={{ marginBottom: '25px' }}></div>
                                                                    <small className="text-muted p-t-30 db">Informatie</small>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.props.kantoor.MainContData.data.info}
                                                                        coltype = 'STR'  
                                                                        multiline={true}                      
                                                                        disabled
                                                                        readonly = {true}
                                                                     />
                                                                    <div className='div_line' style={{ marginBottom: '25px' }}></div>
                                                                </div>
                                                            </CardBody>
                                                        </PerfectScrollbar>
                                                    }
                                                    {this.state.edit === true &&
                                                        <PerfectScrollbar styleclassName="sidebar-nav">
                                                            <CardBody style={{ textAlign: 'center', height: Math.floor(window.innerHeight * .85) + 'px' }}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.last_name}
                                                                            coltype='STR'
                                                                            labelText="Naam"
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'last_name')}
                                                                            setError={(params,value,type) => this.ErrorCheck(params,value,type,'last_name')}

                                                                        />
                                                                    </Grid>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.first_name}
                                                                            coltype='STR'
                                                                            labelText="Voornaam"
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'first_name')}
                                                                            setError={(params,value,type) => this.ErrorCheck(params,value,type,'first_name')}

                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                                            <EditAutoSelect className="card-title m-t-10"
                                                                                value={this.state.data.kantoor_name}
                                                                                labelText="Kantoor"
                                                                                lijst='AllKantoren'
                                                                                colId='kantoor_name'
                                                                                selectcel='parent_account'
                                                                                id={this.state.data.parent_account}
                                                                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'kantoor_name')}
                                                                                setError={(params,value,type) => this.ErrorCheck(params,value,type,'kantoor_name')}
                                                                            />
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.jobtitle}
                                                                            coltype='STR'
                                                                            labelText="Functie"
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'jobtitle')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.e_mail}
                                                                            coltype='STR'
                                                                            labelText="E-mail"
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'e_mail')}
                                                                            setError={(params,value,type) => this.ErrorCheck(params,value,type,'e_mail')}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.mobile_phone}
                                                                            coltype='STR'
                                                                            labelText="Gsm-nummer"
                                                                            format = {['+',/[0-9]/,/\d/,' ','(',/[0-9]/,')',/[0-9]/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/[0-9]/,/[0-9]/,/[0-9]/,' ',/[0-9]/,/[0-9]/,/[0-9]/]}
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'mobile_phone')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.main_phone}
                                                                            coltype='STR'
                                                                            labelText="Telefoon"
                                                                            format = {['+',/[0-9]/,/\d/,' ','(',/[0-9]/,')',/[0-9]/,/\d/,' ',/\d/, /\d/,' ',/\d/,/\d/,' ',/\d/,/\d/,' ',/\d/,/\d/,' ',/[0-9]/,/[0-9]/,' ',/[0-9]/,/[0-9]/]}
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'main_phone')}
                                                                        />
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.street}
                                                                            coltype='STR'
                                                                            labelText="Adres"
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'street')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <div style={{ width: '100%', textAlign: 'center' }}>
                                                                            <EditAutoSelect className="card-title m-t-10"
                                                                                value={this.state.data.zipcode_txt}
                                                                                GoogleZoek={true}
                                                                                labelText="Gemeente"
                                                                                lijst='AllPostcodes'
                                                                                colId='zipcode_txt'
                                                                                selectcel='zipcode'
                                                                                id={this.state.data.zipcode}
                                                                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'zipcode_txt')}
                                                                            />
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                                <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <small className="text-muted p-t-30 db">Info</small>
                                                                    </Grid>

                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.info}
                                                                            coltype='STR'
                                                                            multiline={true}
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'info')}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>

                                                            </CardBody>
                                                        </PerfectScrollbar>
                                                    }
                                                </Card>
                                            </Grid>
                                            <Grid item xs={this.state.edit === false ? 9 : 7}>
                                                <Card className='card_brut'>
                                                    <CardBody style={{ height: Math.floor(window.innerHeight * .85) + 'px' }}>
                                                        <div className='div_line'>
                                                            <Nav tabs>
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={classnames({ active: this.state.tabvalue === '3' })}
                                                                        onClick={(event) => { this.handleTabChange(event, '3'); }}
                                                                        style={{ cursor: 'default' }}
                                                                    >
                                                                        Documenten
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                            <TabContent activeTab={this.state.tabvalue}>
                                                                <TabPane tabId="3">
                                                                    {this.state.tabvalue === '3' && this.props.kantoor.DocContData && this.props.kantoor.DocContData.data && this.props.kantoor.DocContData.kol &&
                                                                        this.props.kantoor && this.props.kantoor.MainContData && this.props.kantoor.MainContData.data && this.props.kantoor.MainContData.data.id !== 0 &&
                                                                        <TableGrid
                                                                            tableheight={tableheight}
                                                                            columns={this.props.kantoor.DocContData.kol}
                                                                            data={this.props.kantoor.DocContData.data}
                                                                            sizetofit={true}
                                                                            sortable={false}
                                                                            suppressMenu={true}
                                                                            enableRowGroup={false}
                                                                            suppressColumnVirtualisation={true}
                                                                            filter={true}
                                                                            sleutel='DocAccounts'
                                                                            getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllSecPar')}
                                                                            editable={false}
                                                                            sql={() => this.props.dispatch(GetDocKantContact(this.props.kantoor.MainContData.data.id))}
                                                                            CellClickedEvent={(column, colDef, value) => this.DocCellClick(column, colDef, value)}
                                                                        />
                                                                    }
                                                                </TabPane>
                                                            </TabContent>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                }
                {this.state.PopUpScreen}
            </div>
        );
    }
}
export default connect(mapStateToProps)(withStyles(styles)(KantContactpage));
