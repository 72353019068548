import { ALLREGOMS_SUCCESS, ALLREGTAR_SUCCESS, REGISTRATIE_SUCCESS, GETDOCREGISTRATIE_SUCCESS, GETJOBSREGISTRATIE_SUCCESS, ALLREGISTRATIE_SUCCESS,REG_TAB, GETCORREGISTRATIE_SUCCESS } from '../constants/';

const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const AllRegOmsSuccess = (payload) => {
  return {
    type: ALLREGOMS_SUCCESS,
    payload: payload.response.data
  }
}

export const setTab = (payload) => {
    return {
        type: REG_TAB,
        payload
    }
}


export const AllRegistratieSuccess = (payload) => {
  return {
    type: ALLREGISTRATIE_SUCCESS,
    payload: payload.response.data
  }
}

export const GetDocRegistrationSuccess = (payload) => {
  return {
    type: GETDOCREGISTRATIE_SUCCESS,
    payload: payload.response.data
  }
}

export const GetCorRegistrationSuccess = (payload) => {
  return {
    type: GETCORREGISTRATIE_SUCCESS,
    payload: payload.response.data
  }
}

export const GetJobsRegistrationSuccess = (payload) => {
  return {
    type: GETJOBSREGISTRATIE_SUCCESS,
    payload: payload.response.data
  }
}

export const GetRegistratieSuccess = (payload) => {

  return {
    type: REGISTRATIE_SUCCESS,
    payload: payload.response.data
  }
}

export const AllRegTarSuccess = (payload) => {
  return {
    type: ALLREGTAR_SUCCESS,
    payload: payload.response.data
  }
}

export function AllRegOms(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllRegOms', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllRegOmsSuccess({ response }))
        }
      },
      () => dispatch(AllRegOmsSuccess(null))
    );
  }
}

export function AllRegistratie(props,path) {
  return function action(dispatch) {
    let zdata = {}
    if (path === '/dirreg')
    {
      zdata = {soort:'1'};
    }
    if (path === '/accijnz')
    {
      zdata = {soort:'2'};
    }
    if (path === '/vatref')
    {
      zdata = {soort:'3'};
    }

    let authOptions = SqlFunction.SetAuth('GetAllRegistratie', zdata);

    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllRegistratieSuccess({ response }))
        }
      },
      () => dispatch(AllRegistratieSuccess(null))
    );
  }
}

export function AllRegTar(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllRegTar', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllRegTarSuccess({ response }))
        }
      },
      () => dispatch(AllRegTarSuccess(null))
    );
  }
}

export function GetRegistration(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetRegistratie', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetRegistratieSuccess({ response })) },
      () => { dispatch(GetRegistratieSuccess(null)) }
    );
  }
}

export function GetDocRegistration(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetDocRegistratie', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetDocRegistrationSuccess({ response })) },
      () => { dispatch(GetDocRegistrationSuccess(null)) }
    );
  }
}

export function GetCorRegistration(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetCorRegistratie', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetCorRegistrationSuccess({ response })) },
      () => { dispatch(GetCorRegistrationSuccess(null)) }
    );
  }
}


export function GetJobsRegistration(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetJobsRegistratie', { id: id, from:1, end:1 });
    const request = axios(authOptions);
    return request.then(
      response => { 
        response.data.conditie = {id:id, registration_id:id};      
        dispatch(GetJobsRegistrationSuccess({ response })) 
      },
      () => { dispatch(GetJobsRegistrationSuccess(null)) }
    );
  }
}

export function CreateRegistration(creavalues) {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, accountid: '', account_name: '', land: '', land_txt: '', periodes: '', periodes_txt: '', dagen: '0', 
                  jobaanvr: true, numinaanvr: '', numinaanvr_txt: '', type: '', type_txt: '', regnummer: '', regnummerbis: '', 
                  dat_aanvr: '', dat_toek: '', dat_stop: '', dat_l_volm: '', forfetair: false, btwkant: '', btwkant_txt: '', btwsubkant: '', btwsubkant_txt: '', port_login: '', 
                  port_pasw: '', port_link: '', info: '', direind: '', jobeind: '', dirpomp: '', jobpomp: '', onhold:'0',stick_enddate:'',stick_name:'',
                  publiek: false, privaat: false, vernumeind: '', dattoekeind: '', datstopeind: '', vernumpomp: '', dattoekpomp: '', datstoppomp: '', direind_txt: '', jobeind_txt: '', dirpomp_txt: '', jobpomp_txt: '',job_id_aanvr:'', aan_jaar:0 }



    if (creavalues) {
      if (creavalues.accountid) {
        data.accountid = creavalues.accountid;
        data.account_name = creavalues.account_name;
      }
    }
    response.data = { data: data }
    dispatch(GetRegistratieSuccess({ response }));
  }
}
