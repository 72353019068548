import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Nav, Collapse } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { authenticationService } from '../../../jwt/_services';
import { UpdFoto, } from "../../../redux/settings/userActions";
import { setSecurityTitle,zetpopup } from "../../../redux/settings/action";
import {AllRegistratie} from "../../../redux/settings/registrationActions";
import { AllPostcodes, AllFactZendwijzen, AllBetalingsvoorwaarden, AllBtwRegimes, AllAansprekingen, AllFuncties, AllRekeningen, AllMainProductCodes, } from "../../../redux/settings/paramActions";
import { AllKantoren } from "../../../redux/settings/kantoorActions";
import { AllJobDefMain,AllJobDefMainIndex, AllJobStatus ,AllBetaalFin, AllVatRefCode} from "../../../redux/settings/jobsActions";
import {AllSuppliers} from "../../../redux/settings/supplierActions";

import { setPanelRight, setTitleRight, GetListmain, zetListMain, ZetListData, setMainTitle } from "../../../redux/settings/action";

import GenFunc from "../../../functions/gen_functions.js";
import PopJobIndexTarieven from "../../../views/jobs/PopJobIndexTarieven.jsx";
import PopJobCreaJaar from "../../../views/jobs/PopJobCreaJaar.jsx";
import PopJobCreaDfvJaar from "../../../views/jobs/PopJobCreaDfvJaar.jsx";


import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountBalance from "@material-ui/icons/AccountBalance";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import Registration from "@material-ui/icons/Registration";
import MenuBook from "@material-ui/icons/MenuBookTwoTone";
import AccessTime from "@material-ui/icons/AccessTime";

import { AllTaalcodes } from '../../../redux/settings/landActions';
import Dashboard from "@material-ui/icons/Dashboard";

const mapStateToProps = state => ({
	...state
});

class Sidebar extends React.Component {

	constructor(props) {
		super(props);
	    this.props.dispatch(setPanelRight(false));
    	this.props.dispatch(setTitleRight(''));	    

		this.expandLogo = this.expandLogo.bind(this);
		this.activeRoute.bind(this);
        this.SetPhoto = this.SetPhoto.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this)
		this.toggle = this.toggle.bind(this);
		this.ClosePopUp = this.ClosePopUp.bind(this);

        this.openLink.bind(this);
		this.state = {
			uicomponents: this.activeRoute("/ui-components") !== "" ? true : false,
			dashboardpages: this.activeRoute("/dahboards") !== "" ? true : false,
			dropdownOpen: false,
		    openRelaties:false,
 	      	openParameters: false,
 	      	openRegParameters: false,
 	      	openJobsParameters: false,

		};
	}
    async componentDidMount() {
	    await this.props.dispatch(GetListmain());
	    await this.props.dispatch(zetListMain('AllUsers'));
    }
    
    ClosePopUp() {
        this.props.dispatch(zetpopup(null));
    }	


	async openLink(securitytitle,name, path,component)
	{
		if (component === 'IndexTarief')
		{
			await this.props.dispatch(AllJobDefMainIndex(this.props))
	        window.scrollTo(0, 0);
            let popdata ={data:{alljobs:'1', allaccounts:'1', tarief:0, nihil:0,mintar:0, datum:''}}
    	    this.props.dispatch(zetpopup((<PopJobIndexTarieven open={true} data={popdata} close={this.ClosePopUp} title="Indexering Tarieven"/>)));
			return;
		}
		if (component === 'CreaJaarJob')
		{
			await this.props.dispatch(AllRegistratie())  
	        window.scrollTo(0, 0);
            let popdata ={data:{jaar:''}}
    	    this.props.dispatch(zetpopup((<PopJobCreaJaar open={true} data={popdata} close={this.ClosePopUp} title="Initialisatie Jobs jaar"/>)));
			return;
		}
		if (component === 'CreaJaarJobDfv')
		{
			await this.props.dispatch(AllRegistratie())  
	        window.scrollTo(0, 0);
            let popdata ={data:{jaar:''}}
    	    this.props.dispatch(zetpopup((<PopJobCreaDfvJaar open={true} data={popdata} close={this.ClosePopUp} title="Initialisatie DFV jaar"/>)));
			return;
		}

	    this.props.dispatch(setPanelRight(false));
    	this.props.dispatch(setTitleRight(''));	   
    	this.props.dispatch(setMainTitle(name));	   
		this.props.dispatch(setSecurityTitle(securitytitle))

    	if (securitytitle) 
    	{
 
       		await this.props.dispatch(ZetListData(securitytitle,path));
	    	await this.props.dispatch(zetListMain(securitytitle,path));
	    }

	    switch (securitytitle) {

			case 'Taalcodes':
				this.props.dispatch(AllTaalcodes(this.props))
				break;

			case 'Factuur zendwijzen':
				this.props.dispatch(AllFactZendwijzen(this.props))
				break;

			case 'Kantoren':
				this.props.dispatch(AllKantoren(this.props))
				break;

			case 'AllSuppliers':
				this.props.dispatch(AllSuppliers(this.props))
				break;

			case 'Betalingsvoorwaarden':
				this.props.dispatch(AllBetalingsvoorwaarden(this.props))
				break;

			case 'BTW regimes':
				this.props.dispatch(AllBtwRegimes(this.props))
				break;	

	        case 'Postcodes':
				this.props.dispatch(AllPostcodes(this.props))
				break;

	        case 'AllRekeningen':
				this.props.dispatch(AllRekeningen(this.props))
				break;

	        case 'JobMainDef':
				this.props.dispatch(AllJobDefMain(this.props))
				break;

	        case 'AllVatRefCode':
				this.props.dispatch(AllVatRefCode(this.props))
				break;

				

	        case 'AllBetaalFin':
				this.props.dispatch(AllBetaalFin(this.props))
				break;

				

			case 'AllJobStatus':
				this.props.dispatch(AllJobStatus(this.props))
				break;

			case 'Aansprekingen':
				this.props.dispatch(AllAansprekingen(this.props))
				break;

			case 'AllFuncties':
				this.props.dispatch(AllFuncties(this.props))
				break;

			case 'AllMainProductCodes':
				this.props.dispatch(AllMainProductCodes(this.props))
				break;

	        default:
	            return;
	    }		
		this.props.dispatch(setSecurityTitle(securitytitle))
    }

	toggle() {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}
    async SetPhoto(fileName) 
    {
        let data = this.props.user.userData;
        data.foto = fileName;
    	this.props.dispatch(UpdFoto(data))
    }



	/*--------------------------------------------------------------------------------*/
	/*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
	/*--------------------------------------------------------------------------------*/
	expandLogo() {
		document.getElementById("logobg").classList.toggle("expand-logo");
	}
	/*--------------------------------------------------------------------------------*/
	/*Verifies if routeName is the one active (in browser input)                      */
	/*--------------------------------------------------------------------------------*/
	activeRoute(routeName) { return this.props.location.pathname.indexOf(routeName) > -1 ? 'selected' : ''; }

	render() {
    	let datarow = this.props.user.userData.secrows;

		return (
			<aside className="left-sidebar" id="sidebarbg" data-sidebarbg={this.props.settings.activeSidebarBg} onMouseEnter={this.expandLogo} onMouseLeave={this.expandLogo}>
				<div className="scroll-sidebar">
					<PerfectScrollbar className="sidebar-nav">
						<div className="user-profile">
							<div className="user-pro-body">
								<div>
                                   <img src={this.props.user.userData.foto} alt="Profielfoto" className="rounded-circle"/>
								</div>
								<Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} className="text-center">
									<DropdownToggle tag="a" className="u-dropdown link hide-menu">
										{this.props.user.userData.name}
        						</DropdownToggle>
									<DropdownMenu>
										<DropdownItem><i className="ti-user"></i> Mijn Profiel</DropdownItem>
										<DropdownItem><i className="ti-wallet"></i> My Balance</DropdownItem>
										<DropdownItem><i className="ti-email"></i> Inbox</DropdownItem>
										<DropdownItem><i className="ti-settings"></i> Account Setting</DropdownItem>
										<DropdownItem divider />
										<DropdownItem onClick={() => authenticationService.logout(this.props)}><i className="fa fa-power-off"></i> Logout</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</div>
						</div>
						{/*--------------------------------------------------------------------------------*/}
						{/* Sidebar Menus will go here                                                */}
						{/*--------------------------------------------------------------------------------*/}
						<Nav id="sidebarnav">
							{this.props.routes.map((prop, key) => {
								if (prop.redirect) {
									return null;
								}

								else if (prop.navlabel && GenFunc.allowed(datarow,prop.security_name)) {
									return (
										<li className="nav-small-cap" key={key}>
											<i ></i>
											<span className="hide-menu">{prop.name}</span>
										</li>
									);
								}
								else if (prop.collapse && GenFunc.allowed(datarow,prop.security_name) && prop.menu) {
									let firstdd = {};
									firstdd[prop["state"]] = !this.state[prop.state];
									return (
										/*--------------------------------------------------------------------------------*/
										/* Menus wiil be goes here                                                        */
										/*--------------------------------------------------------------------------------*/
										<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key}>
											<span data-toggle="collapse" className="sidebar-link has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.setState(firstdd)} >
												{(typeof prop.icon === 'object') ? (<ListItemIcon style={{top:'0px'}}>{prop.icon}</ListItemIcon>) : null}
												{(typeof prop.icon === 'string') ? (<i className={prop.icon} />) : null}
												<span className="hide-menu">{prop.name}</span>
											</span>
											{/*--------------------------------------------------------------------------------*/}
											{/* Sub-Menus wiil be goes here                                                    */}
											{/*--------------------------------------------------------------------------------*/}
											<Collapse isOpen={this.state[prop.state]}>
												<ul className="first-level">
													{prop.child.map((prop, key) => {
														if (prop.redirect) return null;
														if (prop.collapse && GenFunc.allowed(datarow,prop.security_name)  && prop.menu) {
															let seconddd = {};
															seconddd[prop["state"]] = !this.state[prop.state];
															return (
																<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																	<span data-toggle="collapse" className="sidebar-link has-arrow" aria-expanded={this.state[prop.state]} onClick={() => this.setState(seconddd)} >
																		{(typeof prop.icon === 'object') ? (<ListItemIcon style={{top:'0px'}}>{prop.icon}</ListItemIcon>) : null}
																		{(typeof prop.icon === 'string') ? (<i className={prop.icon} />) : null}
																		<span className="hide-menu">{prop.name}</span>
																	</span>
																	{/*--------------------------------------------------------------------------------*/}
																	{/* Sub-Menus wiil be goes here                                                    */}
																	{/*--------------------------------------------------------------------------------*/}
																	<Collapse isOpen={this.state[prop.state]}>
																		<ul className="second-level">
																			{prop.subchild.map((prop, key) => {
																				if (prop.redirect) return null;
																				if (prop.collapse && GenFunc.allowed(datarow,prop.security_name)  && prop.menu) {
																					return (
																						<li className={this.activeRoute(prop.path) + ' sidebar-item'} key={key} >
																							<NavLink
																								to={prop.path} activeclassname="active" className="sidebar-link">
																								{(typeof prop.icon === 'object') ? (<ListItemIcon style={{top:'0px'}}>{prop.icon}</ListItemIcon>) : null}
																								{(typeof prop.icon === 'string') ? (<i className={prop.icon} />) : null}
																								<span className="hide-menu">{prop.name}</span>
																							</NavLink>
																						</li>
																					);
																				}
																				else
																				{
																					return null;
																				}
																			})}
																		</ul>
																	</Collapse>
																</li>
															);
														}
														if (GenFunc.allowed(datarow,prop.security_name)  && prop.menu) {
															return (
																/*--------------------------------------------------------------------------------*/
																/* Adding Sidebar Item                                                            */
																/*--------------------------------------------------------------------------------*/
																<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
																	{prop.path === 'POPUP' ? (
																	<div  className="sidebar-link" activeclassname="active" onClick={() => this.openLink(prop.security_name,prop.name,prop.path,prop.component)}>
																		{(typeof prop.icon === 'object') ? (<ListItemIcon style={{top:'0px'}}>{prop.icon}</ListItemIcon>) : null}
																		{(typeof prop.icon === 'string') ? (<i className={prop.icon} />) : null}
																		<span className="hide-menu">{prop.name}</span>
																	</div>


																	):(
																	<NavLink to={prop.path} className="sidebar-link" activeclassname="active" onClick={() => this.openLink(prop.security_name,prop.name,prop.path,prop.component)}>
																		{(typeof prop.icon === 'object') ? (<ListItemIcon style={{top:'0px'}}>{prop.icon}</ListItemIcon>) : null}
																		{(typeof prop.icon === 'string') ? (<i className={prop.icon} />) : null}
																		<span className="hide-menu">{prop.name}</span>
																	</NavLink>)}
																</li>
															);
														}
														return null;
													})}
												</ul>
											</Collapse>
										</li>
									);
								}
								else {
									if (GenFunc.allowed(datarow,prop.security_name)  && prop.menu) {
										return (
											/*--------------------------------------------------------------------------------*/
											/* Adding Sidebar Item                                                            */
											/*--------------------------------------------------------------------------------*/
											<li className={this.activeRoute(prop.path) + (prop.pro ? ' active active-pro' : '') + ' sidebar-item'} key={key}>
												<NavLink onClick={() => this.openLink(prop.security_name,prop.name,prop.path,prop.component)} to={prop.path} className="sidebar-link" activeclassname="active">
													{(prop.icon === 'AccountBalance') ? (<ListItemIcon style={{top:'0px'}}><AccountBalance/></ListItemIcon>) : 
													 (prop.icon === 'SupervisorAccount') ? (<ListItemIcon style={{top:'0px'}}><SupervisorAccount/></ListItemIcon>) : 
													 (prop.icon === 'Dashboard') ? (<ListItemIcon style={{top:'0px'}}><Dashboard/></ListItemIcon>) : 
													 (prop.icon === 'AccessTime') ? (<ListItemIcon style={{top:'0px'}}><AccessTime/></ListItemIcon>) : 
													 (prop.icon === 'MenuBook') ? (<ListItemIcon style={{top:'0px'}}><MenuBook/></ListItemIcon>) : 
													 (prop.icon === 'Registration') ? (<ListItemIcon style={{top:'0px'}}><Registration/></ListItemIcon>) : 													 
													(<i className={prop.icon} />)}
													<span className="hide-menu">{prop.name}</span>
												</NavLink>
											</li>
										);
									}
									else
									{
										return null;
									}
								}
							})}
						</Nav>
					</PerfectScrollbar>
				</div>
			</aside>
		);
	}
}
export default connect(mapStateToProps)(Sidebar);
