import { AllACCOUNTS_SUCCESS, GETACCOUNT_SUCCESS, GETDOCACCOUNT_SUCCESS, GETCONTACCOUNT_SUCCESS, GETTARACCOUNT_SUCCESS, GETREGACCOUNT_SUCCESS, GETJOBSACCOUNT_SUCCESS, 
  GETACCIJNZENACCOUNT_SUCCESS, GETVATREFUNDACCOUNT_SUCCESS, GETDIRREGACCOUNT_SUCCESS, GETACCCHANGES_SUCCESS, GETALLFACTURENACCOUNT_SUCCESS, GETFACTUURACCOUNT_SUCCESS, 
  GETALLFACTUREN_SUCCESS,GETCORACCOUNT_SUCCESS, GETFACTURATIEACCOUNT_SUCCESS, ACC_TAB, GETVOLMACCOUNT_SUCCESS, GETACCOUNTINVOICEDETAILS_SUCCESS } from '../constants/';

const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const AllAccountsSuccess = (payload) => {
  return {
    type: AllACCOUNTS_SUCCESS,
    payload: payload.response.data
  }
}

export const setTab = (payload) => {
    return {
        type: ACC_TAB,
        payload
    }
}


export const GetAccountSuccess = (payload) => {
  let hadressen = [{ afk: "HFD", adres1: "", housenum: '', adres2: "", country: "", country_txt: "", zipcode: "", zipcode_txt: "" },
  { afk: "MAIN", adres1: "", housenum: '', adres2: "", country: "", country_txt: "", zipcode: "", zipcode_txt: "" },];
  let data = payload.response.data;
  let subdata = data.data;
  if (subdata.adressen) {
    if (subdata.adressen.length < 2) {
      subdata.adressen = hadressen;
    }
  }
  else {
    subdata.adressen = hadressen;
  }
  data.data = subdata;

  return {
    type: GETACCOUNT_SUCCESS,
    payload: data
  }
}

export const GetAccountInvoiceDetailsSuccess = (payload) => {
  return {
    type: GETACCOUNTINVOICEDETAILS_SUCCESS,
    payload: payload.response.data
  }
}

export const GetDocAccountSuccess = (payload) => {
  return {
    type: GETDOCACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetVolmAccountSuccess = (payload) => {
  return {
    type: GETVOLMACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetCorAccountSuccess = (payload) => {
  return {
    type: GETCORACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}


export const GetRegAccountSuccess = (payload) => {
  return {
    type: GETREGACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetContAccountSuccess = (payload) => {
  return {
    type: GETCONTACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetTarAccountSuccess = (payload) => {
  return {
    type: GETTARACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetJobsAccountSuccess = (payload) => {
  return {
    type: GETJOBSACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetAccijnzenAccountSuccess = (payload) => {
  return {
    type: GETACCIJNZENACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetVatrefundAccountSuccess = (payload) => {
  return {
    type: GETVATREFUNDACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetDirregAccountSuccess = (payload) => {
  return {
    type: GETDIRREGACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetAccChangesSuccess = (payload) => {
  return {
    type: GETACCCHANGES_SUCCESS,
    payload: payload.response.data
  }
}

export const GetAllFacturenAccountSuccess = (payload) => {
  return {
    type: GETALLFACTURENACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetFactuurAccountSuccess = (payload) => {
  return {
    type: GETFACTUURACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetAllFacturenSuccess = (payload) => {
  return {
    type: GETALLFACTUREN_SUCCESS,
    payload: payload.response.data
  }
}

export const GetFacturatieAccountSuccess = (payload) => {
  return {
    type: GETFACTURATIEACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export function AllAccounts() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllAccounts', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllAccountsSuccess({ response })) },
      () => { dispatch(AllAccountsSuccess(null)) },
    );
  }
}

export function GetAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetAccountSuccess({ response })) },
      () => { dispatch(GetAccountSuccess(null)) }
    );
  }
} 

export function GetAccountInvoiceDetails(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetAccountInvoiceDetails', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetAccountInvoiceDetailsSuccess({ response })) },
      () => { dispatch(GetAccountInvoiceDetailsSuccess(null)) }
    );
  }
}

export function GetFacturatieAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetFacturatieAccountSuccess({ response })) },
      () => { dispatch(GetFacturatieAccountSuccess(null)) }
    );
  }
}

export function GetDocAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetDocAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetDocAccountSuccess({ response })) },
      () => { dispatch(GetDocAccountSuccess(null)) }
    );
  }
}

export function GetVolmAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetVolmAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetVolmAccountSuccess({ response })) },
      () => { dispatch(GetVolmAccountSuccess(null)) }
    );
  }
}

export function GetCorAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetCorAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetCorAccountSuccess({ response })) },
      () => { dispatch(GetCorAccountSuccess(null)) }
    );
  }
}

export function GetRegAccount(id,type) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetRegAccount', { id: id });
    if (type)
    {
      authOptions = SqlFunction.SetAuth('GetRegAccount', { id: id, soort:type });
    }
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetRegAccountSuccess({ response })) },
      () => { dispatch(GetRegAccountSuccess(null)) }
    );
  }
}

export function GetContAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetContAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetContAccountSuccess({ response })) },
      () => { dispatch(GetContAccountSuccess(null)) }
    );
  }
}

export function GetTarAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetTarAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetTarAccountSuccess({ response })) },
      () => { dispatch(GetTarAccountSuccess(null)) }
    );
  }
}

export function GetJobsAccount(id,type) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetJobsAccount', { id: id, from:1, end:1 });
    if (type)
    {
      authOptions = SqlFunction.SetAuth('GetJobsAccount', { id: id, soort:type, from:1, end:1 });
    }
    const request = axios(authOptions);
    return request.then(
      response => { 
        response.data.conditie = {id:id,soort:type, account_id:id};      
        dispatch(GetJobsAccountSuccess({ response })) 
      },
      () => { dispatch(GetJobsAccountSuccess(null)) }
    );
  }
}

export function GetAccijnzenAccount(id) {
  return function action(dispatch) {

    let authOptions = SqlFunction.SetAuth('GetAccijnzenAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetAccijnzenAccountSuccess({ response })) },
      () => { dispatch(GetAccijnzenAccountSuccess(null)) }
    );
  }
} 

export function GetVatrefundAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetVatrefundAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetVatrefundAccountSuccess({ response })) },
      () => { dispatch(GetVatrefundAccountSuccess(null)) }
    );
  }
} 

export function GetDirregAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetDirregAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetDirregAccountSuccess({ response })) },
      () => { dispatch(GetDirregAccountSuccess(null)) }
    );
  }
}

export function GetAccChanges(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetAccChanges', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetAccChangesSuccess({ response })) },
      () => { dispatch(GetAccChangesSuccess(null)) }
    );
  }
}

export function GetAllFacturenAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetAllFacturenAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetAllFacturenAccountSuccess({ response })) },
      () => { dispatch(GetAllFacturenAccountSuccess(null)) }
    );
  }
}

export function GetFactuurAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetFactuurAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetFactuurAccountSuccess({ response })) },
      () => { dispatch(GetFactuurAccountSuccess(null)) }
    );
  }
}

export function GetAllFacturen() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetAllFacturen', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetAllFacturenSuccess({ response })) },
      () => { dispatch(GetAllFacturenSuccess(null)) }
    );
  }
}

export function CreateAccount() {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, name: '',nace_code:'',acctype:'',acctype_txt:'',info:'', number: '', user: '', user_name: '', contact: '', cont_name: '', active: true, email: '', phone: '', website: '', startdate: '', enddate: '', vatnumber: '', vatreg: '' }
    let hadressen = [{ afk: "HFD", adres1: "", housenum: '', adres2: "", country: "", country_txt: "", zipcode: "", zipcode_txt: "" },
    { afk: "MAIN", adres1: "", housenum: '', adres2: "", country: "", country_txt: "", zipcode: "", zipcode_txt: "" },];
    data.adressen = hadressen;
    data.accijn = [];
    data.dirreg = [];
    data.vatregreg = [];
    response.data = { data: data }
    dispatch(GetAccountSuccess({ response }));
  }
}

//Nieuwe klant vanuit landen parameter
export function CreateAccountKantoor(creavalues) {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, name: '',info:'',acctype:0,acctype_txt:'', number: '', user: '', user_name: '', contact: '', cont_name: '', active: true, email: '', phone: '', website: '', startdate: '', enddate: '', vatnumber: '', vatreg: '' }
    let hadressen = [{ afk: "HFD", adres1: "", housenum: '', adres2: "", country: "", country_txt: "", zipcode: "", zipcode_txt: "" },
    { afk: "MAIN", adres1: "", housenum: '', adres2: "", country: "", country_txt: "", zipcode: "", zipcode_txt: "" },];
    if (creavalues) {
      if (creavalues.countryid) {
        hadressen[0].country = creavalues.countryid;
        hadressen[0].country_txt = creavalues.country_name;
      }
    }
    data.adressen = hadressen;
    data.accijn = [];
    data.dirreg = [];
    data.vatregreg = [];
    response.data = { data: data }
    dispatch(GetAccountSuccess({ response }));
  }
}
