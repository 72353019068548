import { BehaviorSubject } from 'rxjs';
import {
  logInSuccess,loginFail,
} from "../../redux/settings/userActions";
const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');



//const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));

//const currentSecid = new BehaviorSubject(JSON.parse(localStorage.getItem('secid')));
//const currentClsecid = new BehaviorSubject(JSON.parse(localStorage.getItem('clsecid')));

const currentUserSubject = new BehaviorSubject();
const currentSecid = new BehaviorSubject();
const currentClsecid = new BehaviorSubject();


export const authenticationService = {
    login,
    logout,
    currentUser: currentUserSubject.asObservable(),
    secid: currentSecid.asObservable(),
    clsecid:currentClsecid.asObservable(),
    get currentUserValue () { return currentUserSubject.value },
    get currentsecidValue () { return currentSecid.value },
    get currentclsecidValue () { return currentClsecid.value },
};


async function login(username, password,props) {
    let authOptions = SqlFunction.SetAuth('Login',{login:username,password:password});
    const request = axios(authOptions);
    return request.then(
      response => {
        props.dispatch(logInSuccess(response))
        localStorage.setItem('secid', JSON.stringify(response.data.id));
        localStorage.setItem('clsecid', JSON.stringify(response.data.secid));
        currentUserSubject.next(response.data);
//        SqlFunction.ConnectMail();
        return response.data;
      },
      err => {props.dispatch(loginFail(err));
        return err;
        }
    );
}

function logout(props) {
    // remove user from local storage to log user out
    localStorage.removeItem('secid');
    localStorage.removeItem('clsecid');
    localStorage.removeItem('currentUser');

    currentUserSubject.next(null);
    if (props)
    {
        props.history.push('/authentication/login');
    }

}
