import { ALLLANDEN_SUCCESS,ALLLANDENACC_SUCCESS, GETLAND_SUCCESS, ALLONKOSTEN_SUCCESS, GETONKOSTENLAND_SUCCESS, GETKANTORENLAND_SUCCESS, GETKLANTENLAND_SUCCESS, GETREGLAND_SUCCESS, GETTAALCODE_SUCCESS, 
    ALLTAALCODES_SUCCESS, GETFACTUUROPBOUW_SUCCESS, GETVOLMACHTENLANDEN_SUCCESS,GETVATREFCODELAND_SUCCESS,ALLPERIODESACC_SUCCESS } from '../constants/';

import blankflag from '../../assets/images/blankflag.jpg';

const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const AllLandenSuccess = (payload) => {
    return {
        type: ALLLANDEN_SUCCESS,
        payload: payload.response.data
    }
}

export const AllLandenAccSuccess = (payload) => {
    return {
        type: ALLLANDENACC_SUCCESS,
        payload: payload.response.data
    }
}

export const AllPeriodesAccSuccess = (payload) => {
    return {
        type: ALLPERIODESACC_SUCCESS,
        payload: payload.response.data
    }
}

export const GetLandSuccess = (payload) => {
    return {
        type: GETLAND_SUCCESS,
        payload: payload.response.data
    }
}

export const AllOnkostenSuccess = (payload) => {
    return {
        type: ALLONKOSTEN_SUCCESS,
        payload: payload.response.data
    }
}

export const GetOnkostenLandSuccess = (payload) => {
    return {
        type: GETONKOSTENLAND_SUCCESS,
        payload: payload.response.data
    }
}

export const GetVatRefCodeLandSuccess = (payload) => {
    return {
        type: GETVATREFCODELAND_SUCCESS,
        payload: payload.response.data
    }
}




export const GetKantorenLandSuccess = (payload) => {
    return {
        type: GETKANTORENLAND_SUCCESS,
        payload: payload.response.data
    }
}

export const GetKlantenLandSuccess = (payload) => {
    return {
        type: GETKLANTENLAND_SUCCESS,
        payload: payload.response.data
    }
}

export const GetRegLandSuccess = (payload) => {
    return {
        type: GETREGLAND_SUCCESS,
        payload: payload.response.data
    }
}

export const GetTaalcodeSuccess = (payload) => {
    return {
        type: GETTAALCODE_SUCCESS,
        payload: payload.response.data
    }
}

export const AllTaalcodesSuccess = (payload) => {
    return {
      type: ALLTAALCODES_SUCCESS,
      payload: payload.response.data
    }
  }

  export const GetFactuuropbouwSuccess = (payload) => {
    return {
      type: GETFACTUUROPBOUW_SUCCESS,
      payload: payload.response.data
    }
  }

  export const GetVolmachtenLandenSuccess = (payload) => {
    return {
      type: GETVOLMACHTENLANDEN_SUCCESS,
      payload: payload.response.data
    }
  }

export function AllLanden(props) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('AllLanden', {});
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(AllLandenSuccess({ response })) },
            err => { dispatch(AllLandenSuccess(null)) }
        );
    }
}

export function AllLandenAcc(props,id,type) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('AllLandenAcc', {id:id});
        if (type)
        {
            authOptions = SqlFunction.SetAuth('AllLandenAcc', {id:id,type:type});
        }
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(AllLandenAccSuccess({ response })) },
            err => { dispatch(AllLandenAccSuccess(null)) }
        );
    }
}

export function AllPeriodesAcc(props,id,type) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetJobPeriode', {id:id});
        if (type)
        {
            authOptions = SqlFunction.SetAuth('GetJobPeriode', {id:id,type:type});
        }

        const request = axios(authOptions);
        return request.then(
            response => { dispatch(AllPeriodesAccSuccess({ response })) },
            err => { dispatch(AllPeriodesAccSuccess(null)) }
        );
    }
}


export function GetLand(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetLand', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetLandSuccess({ response })) },
            err => { dispatch(GetLandSuccess(null)) }
        );
    }
}

export function UpdFoto(data) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('LandUpdateFoto', data);
        const request = axios(authOptions);
        return request.then(
            response => dispatch(GetLandSuccess({ data: data })),
            err => dispatch(GetLandSuccess(null))
        );
    }
}

export function AllOnkosten(props) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('AllOnkosten', {});
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(AllOnkostenSuccess({ response })) },
            err => { dispatch(AllOnkostenSuccess(null)) }
        );
    }
}

export function GetOnkostenLand(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetOnkostenLand', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetOnkostenLandSuccess({ response })) },
            err => { dispatch(GetOnkostenLandSuccess(null)) }
        );
    }
}

export function GetVatRefCodeLand(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetVatRefCodeLand', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetVatRefCodeLandSuccess({ response })) },
            err => { dispatch(GetVatRefCodeLandSuccess(null)) }
        );
    }
}


export function GetKantorenLand(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetKantorenLand', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetKantorenLandSuccess({ response })) },
            err => { dispatch(GetKantorenLandSuccess(null)) }
        );
    }
}

export function GetKlantenLand(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetKlantenLand', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetKlantenLandSuccess({ response })) },
            err => { dispatch(GetKlantenLandSuccess(null)) }
        );
    }
}

export function AllTaalcodes(props) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('AllTaalcodes', {});
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(AllTaalcodesSuccess({ response })) },
            err => { dispatch(AllTaalcodesSuccess(null)) }
        );
    }
}

export function GetTaalcode(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetTaalCode', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetTaalcodeSuccess({ response })) },
            err => { dispatch(GetTaalcodeSuccess(null)) }
        );
    }
}

export function GetFactuuropbouw(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetFactuuropbouw', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetFactuuropbouwSuccess({ response })) },
            err => { dispatch(GetFactuuropbouwSuccess(null)) }
        );
    }
}

export function GetVolmachtenLand(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetVolmachtenLand', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetVolmachtenLandenSuccess({ response })) },
            err => { dispatch(GetVolmachtenLandenSuccess(null)) }
        );
    }
}

//Nieuw land
export function CreateLand() {
    return function action(dispatch) {
        let response = { data: null }
        let data = { id: 0, code: '', name: '', munt: '', info: '', foto: blankflag, scandoc:0, defbtw:'' }
        response.data = { data: data }
        dispatch(GetLandSuccess({ response }));
    }
}

export function CreateTaalcode(){
    return function action(dispatch) {
        let response = { data: null }
        let data = { id: 0, code: '', omschrijving: '' }
        response.data = { data:data }
        dispatch(GetTaalcodeSuccess({ response }));
    }
}

export function CreateFactuuropbouw(){
    return function action(dispatch) {
        let response = { data: null }
        let data = { id: 0, taalcode: 0, factuur: '', creditnota: '', factnr: '', klantnr: '', factdate: '', factenddate: '', description: '', totaalexcl: '', prestaties: '', klant: '', 
            restitutie: '', aanvrregnr: '', periodiek: '', intracom: '', listing: '', intrastatverk: '', intrastataank: '', belastingaangifte: '', btwrecup: '', land: '', regnr: '', periode: '',
            aangiftebtw: '', ereloon: '', teruggave: '', koers: '', bedrageuro: '', nihil: '', totaalereloon: '', btw: '', betalen: '', vermelding: '', betalingfactdate: '', domiciliering: '',
            btwverlegd: '', export: '' }
        response.data = { data: data }
        dispatch(GetFactuuropbouwSuccess({ response }));
    }
}

export function GetRegLand(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetRegLand', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetRegLandSuccess({ response })) },
            err => { dispatch(GetRegLandSuccess(null)) }
        );
    }
}