import React from 'react';
import GenFunc from "../functions/gen_functions.js";
import Calculator from "./calculator/Calculator";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import styles from "../assets/components/customInputStyle.jsx";
import Clear from "@material-ui/icons/Exclamation";
import IconButton from '@material-ui/core/IconButton';
import DialPad from '@material-ui/icons/Calculator';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import Popper from '@material-ui/core/Popper';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}


interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}

class EditString extends React.Component {
    TextMaskCustom(propster:TextMaskCustomProps) {
      const { inputRef, ...other } = propster;
      return (
        <MaskedInput
          {...other}
          mask={this.props.format}
          placeholderChar={'\u2000'}
          showMask = {false}
        />      
      )
    }  
    

    NumberFormatCustom(props: NumberFormatCustomProps) {
      const { inputRef,onChange, ...other } = props;
      let prefix = this.props.prefix ? this.props.prefix : '';
      let suffix = this.props.suffix ? this.props.suffix : '';
      let showmask = "false";
//      if (prefix === 'EUR') {prefix = '€'} else {prefix = ''}
      if (suffix === 'PERC') {suffix = '%'} else {suffix = ''}

      switch (prefix) 
      {

        case 'EUR':
          prefix = '€ '
          break;

        case 'BGN':
          prefix = 'лв ';
          break;    
        case 'CHF':
          prefix = 'CHF ';
          break;    
        case 'CZK':
          prefix = 'Kč ';
          break;    
        case 'DKK':
          prefix = 'kr ';
          break;    
        case 'GBP':
          prefix = '£ ';
          break;    
        case 'HRK':
          prefix = 'kn ';
          break;    
        case 'GEL':
          prefix = '₾ ';
          break;    
        case 'HUF':
          prefix = 'ft ';
          break;    
        case 'NOK':
          prefix = 'kr ';
          break;    
        case 'PLN':
          prefix = 'zł ';
          break;    
        case 'RUB':
          prefix = '₽ ';
          break;    
        case 'RON':
          prefix = 'lei ';
          break;    
        case 'SEK':
          prefix = 'kr ';
          break;    
        case 'TRY':
          prefix = '₺ ';
          break;    
        case 'UAH':
          prefix = '₴ ';
          break;    

        default:    
          prefix = '';
          break;        
      }

      return (
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={values => {
            onChange({
              target: {
                value: values.floatValue,
              },
            });
          }}         
          isNumericString={true} 
          thousandSeparator={'.'} 
          decimalSeparator={','}  
          decimalScale={this.props.decimal ? this.props.decimal : '4'}
          format={this.props.format ? this.props.format : null}
          prefix={prefix}
          suffix={suffix}
          showmask = {showmask}
        />
      );
  }      


    constructor(props) {
        super(props);
        this.change = this.change.bind(this);

        this.TextMaskCustom = this.TextMaskCustom.bind(this);
        this.NumberFormatCustom = this.NumberFormatCustom.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.toggleCalculator = this.toggleCalculator.bind(this);
        this.handleCalculatorResult = this.handleCalculatorResult.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.inputid = GenFunc.MakeId()
        this.calcid = GenFunc.MakeId()

        this.state = {
            value:this.props.value,
            anchorEl:null,
            error:false,
            checkval:false,
            focus:false,
            calculatorOpen: false
        }
        this.anchorEl = document.getElementById(this.calcid);        
    }

    handleKeyPress(event) {
      if(event.key === 'Tab' || event.key === 'Enter')
      {
        this.props.ValueCheck(this,this.state.value,"ENTER")
      }
      if(event.key === '+' && this.props.coltype === 'NUM' && this.state.calculatorOpen === false)
      {
        document.getElementById(this.calcid).click();        

      }      
    }

  async componentWillReceiveProps(nextProps, nextState) 
    {
      if (this.state.value !== nextProps.value)
      {
        this.setState({value:nextProps.value})
//        this.props.ValueCheck(this,nextProps.value,'STRING')
      }
      if (nextProps.checkval === true)
      {
        this.props.ValueCheck(this,this.state.value,'STRING','RECEIVE')
      }

    }


    handleBlur(event) {
      if(this.props.coltype === 'NUM' && this.state.calculatorOpen === true)
      {
        this.setState({calculatorOpen:false})
      }
      this.setState({focus:false})      
      this.props.ValueCheck(this,this.state.value,"ENTER",this.state.focus)
    }

    handleCalculatorResult(result) {
      this.setState({value: result});
    }

    componentDidMount() {
        if (this.props.setError)
        {
          this.props.setError(this,this.props.value,'STRING','RECEIVE')
//          this.setState({error:error})
        }
    }
    change(event, valid, stateName) 
    {
      if (event.target) 
      {
        let value = event.target.value;
        if(this.props.timeFormat){
          value.length === 2 ? (this.state.value.length < value.length ? value += ":" : value = value.slice(0, -1)) : value = value;
        }
        this.setState({value:value})
        this.props.ValueCheck(this,event.target.value,'STRING',this.state.focus)
      } 
      else 
      {
        this.setState({value:event})
        this.props.ValueCheck(this,event,'STRING',this.state.focus)
      }
      if(this.props.coltype === 'NUM' && this.state.calculatorOpen === true)
      {
        this.setState({calculatorOpen:false})
      }      

    }
    toggleCalculator(event) {
      const calculatorOpen = !this.state.calculatorOpen;
      this.setState({
        calculatorOpen: calculatorOpen,
        anchorEl: event.currentTarget,  
      });
    }


    render() {
      const {
        classes,
        labelText,
        id,
        labelProps,
        ErrClick,      
        multiline,
        helperText,  
      } = this.props;

      const formControlProps={fullWidth: true}
      const labelClasses = classNames({
        [" " + classes.labelRootError]: this.state.error,
        [" " + classes.labelRootSuccess]: this.state.success && !this.state.error,
        [" " + classes.labelShrink]: this.state.value && this.state.value.length > 0,
      });
      const underlineClasses = classNames({
        [classes.brutUnderline]: !this.state.error,
        [classes.underlineError]: this.state.error,
        [classes.underline]: true,
      });
      const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: this.state.white
      });
      var formControlClasses;
      if (formControlProps !== undefined) {
        formControlClasses = classNames(
          formControlProps.className,
          classes.formControl
        );
      } else {
        formControlClasses = classes.formControl;
      }
      var helpTextClasses = classNames({
        [classes.labelRootError]: this.state.error,
        [classes.labelRootSuccess]: this.state.success && !this.state.error
      });      
      let readonly = (this.props.readonly ? true : false)
      let borderw = (this.props.readonly ? '0' : '1px')
      let fontw = (this.props.readonly ? 300 : 400)

      let inputComponent = (this.props.coltype === 'STR' && this.props.format) ?
          {inputComponent: this.TextMaskCustom} :
          (this.props.coltype === 'NUM') ?
          {inputComponent: this.NumberFormatCustom} : null
      return (
        <FormControl style={{position:'relative',marginBottom:'15px', width:'100%'}} {...formControlProps}  className={formControlClasses}>

          {labelText !== undefined ? (
            <InputLabel
              className={classes.labelRoot + " " + labelClasses}
              htmlFor={id}
              {...labelProps}
            >
              {labelText}
            </InputLabel>
          ) : null}
          {multiline ? ( <textarea
              classes={{
                input: inputClasses,
                disabled: classes.disabled,
                underline: underlineClasses
              }}
              style={this.props.height ? {height:this.props.height, borderWidth:borderw, fontWeight:fontw} : {height:'300px', borderWidth:borderw, fontWeight:fontw} }
              id={id}
              onChange = {event => this.change(event,true)}
              onFocus= {()=>{this.setState({focus:true})}}              
              value={this.state.value}
              readOnly={readonly}
              inputprops={{
                onChange: event => this.change(event,true),
                value:this.state.value,
                id:this.inputid,
              }}        
          ></textarea>) :
          ( <Grid    container spacing={1} alignItems="flex-end">
              <Grid  style={{display:'flex', width:'98%'}} item>
                  <Input
                    onKeyDown={this.handleKeyPress}
                    onBlur={this.handleBlur}
                    readOnly={readonly}
                    onFocus= {()=>{this.setState({focus:true})}}              
                    style={{width:'100%'}}
                    classes={{
                      input: inputClasses,
                      disabled: classes.disabled,
                      underline: underlineClasses
                    }}
                    id={id}
                    {...inputComponent}

                    inputProps={{
                      onChange: event => this.change(event,true),
                      value:this.state.value,
                      id:this.inputid,
                    }}        
                  />
                {
                  (this.props.coltype === 'NUM') ?
                  <div style={{float:'right'}}>
                    <IconButton id={this.calcid}  onClick= {(event) => { this.toggleCalculator(event)}}  style= {{marginLeft: '-20px', marginTop: '-10px'}} aria-label="toggle calculator visibility">
                      <DialPad/>
                    </IconButton>
                    <Popper id='popinp'  style={{zIndex:'1500'}} open={this.state.calculatorOpen} anchorEl={this.state.anchorEl}>
                      <Calculator resultHandler={this.handleCalculatorResult} />
                    </Popper>

                  </div>
                  : null
                }
              </Grid>
            </Grid>
          )
          }
          {this.state.error && ErrClick ? (
            <Clear className={classes.feedback + " " + classes.labelRootError} />
          ) : this.state.error ? (
            <Clear className={classes.feedback + " " + classes.labelRootError} />
          ) : null}

          {helperText !== undefined ? (
            <FormHelperText id={id + "-text"} className={helpTextClasses}>
              {helperText}
            </FormHelperText>
          ) : null}
        </FormControl>          
      );
    }
}

export default withStyles(styles)(EditString);