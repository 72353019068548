import React from 'react';
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'

import TableGrid from "../../components/TableGrid/TableGrid.jsx";

import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';

import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import LoaderGif from '../../assets/images/loadinggif.gif';

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import EditSelect from "../../components/editselect.jsx";


import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import PopViewProforma from "./PopViewProforma.jsx";
import { GetFactuuropbouw, AllLanden } from '../../redux/settings/landActions.js';
import { GetAllFacturen,  GetAccountInvoiceDetails } from '../../redux/settings/accountActions.js';
import print from 'print-js'


var gridapi = null;
var rowpinnend = null;
class PopListCreaInv extends React.Component {
    state = {
        data: null,
        kol:null,
        maindata:this.props.data,
        seljobs:this.props.seljobs,
        invoiceType:this.props.invoiceType,
        save:true,
        tabvalue:'1',
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        PopUpScreen:null,
        showLoadingGif: false,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);

        this.CellClick = this.CellClick.bind(this);
        this.CellCheck = this.CellCheck.bind(this);


        this.onGridReady = this.onGridReady.bind(this);
        this.handleSave = this.handleSave.bind(this);

        this.handleTabChange = this.handleTabChange.bind(this);
        this.ClosePopViewProforma = this.ClosePopViewProforma.bind(this);



    }
    async handleTabChange(event, newValue) {
    }
    onGridReady(params)
    {
      gridapi = params;
    }

    async componentDidMount(){
      
      let terdata = await SqlFunction.DoSql('GetAllFacturenKol',this.props.data);
      let tusdata = [];
      if (this.props.seljobs)
      {
        for(let i = 0; i < this.props.seljobs.length; i++)
        {
          let seljob = this.props.seljobs[i];
          let factrow = this.props.data[seljob.account_name];
          if (factrow && factrow.done && factrow.done === '1')
          {

          }
          else
          {
            let invdata = factrow.invoice_data;
            invdata.index = i;
            invdata.selaccount = seljob.account_name;
            tusdata.push(invdata);
            factrow.done = '1';
            this.props.data[seljob.account_name] = factrow;
          }
        }        
      }
        this.setState({data:tusdata, kol:terdata, seljobs:this.props.seljobs, maindata:this.props.data})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      let data = this.state.maindata;
      let accounts = this.props.accdata
      let printfakt = [];
      this.setState({ showLoadingGif: true })

      for(let i = 0; i < accounts.length; i++){
        let factdata = data[accounts[i]].invoice_data;
        let retdata = await SqlFunction.DoSql('ChgFactuur', factdata, this.props.seljobs)

        for (let j = 0; j < data[accounts[i]].jobs_data.length; j++) 
        {
          let sdata = data[accounts[i]].jobs_data[j];
          sdata.fact_id = retdata.id;
          sdata['job_done'] = true;
          let overzdata = { data: sdata}
          await SqlFunction.DoSql('ChgJobAfterInvoiceSave', overzdata);
        }
        printfakt.push(retdata);

      }

      for (let j = 0; j < printfakt.length; j++) 
      {
        let maildata = printfakt[j];
        await SqlFunction.DoSql('MailPdfFile', maildata);
      }

      for (let j = 0; j < printfakt.length; j++) 
      {
        let sdata = printfakt[j];
        await SqlFunction.DoSql('MakePdfFile', sdata);
      }
      let printdata = await SqlFunction.DoSql('MakeTotFactuur', { data: printfakt});
      if (printdata)
      {
        let secid = JSON.parse(localStorage.getItem('secid'));
        let clsecid = JSON.parse(localStorage.getItem('clsecid'));
        let printhtml = "https://app.brutax.com/server/index.php?function=OpenPrintBatch&secid="+secid+" &clsecid="+clsecid;
        print(printhtml)
      }
      this.setState({ showLoadingGif: false })
    }

    async ErrorCheck(that, waarde, type, colId) {
      let terug = false;
      if (colId === 'datum')
      {
        var utc = moment(waarde, "DD-MM-YYYY", true)
        if(!utc.isValid()) {terug = true}
      }
      if (colId === 'type_txt')
      {
        if (waarde)
        {
          if (waarde.length < 1)
          {
            terug = true;
          }
        }
        else
        {
            terug = true;
        }
      }
      if (colId === 'job_txt')
      {
        if (waarde)
        {
          if (waarde.length < 1)
          {
            terug = true;
          }
        }
        else
        {
            terug = true;
        }
      }

      that.setState({ error: terug })
    } 


    ValueCheck(that,waarde,type,colId) {
      let data = this.state.data;
      let save = this.state.save;
      let error = false;

      data[colId] = waarde;
      if (colId === 'datum')
      {
        var utc = moment(waarde, "DD-MM-YYYY", true)
        if(!utc.isValid()) {error = true}
      }
      if (colId === 'country_txt')
      {
        data.country_txt = waarde.value;
        data.country = waarde.id;
      }
      if (colId === 'type_txt')
      {
        data.type_txt = waarde.text;
        data.type = waarde.id;
        if (waarde.id)
        {
          if (waarde.id.length < 1)
          {
            error = true;
          }

        }
        else
        {
            error = true;
        }
      }
      if (colId === 'job_txt')
      {
        data.job_txt = waarde.value;
        data.jobid = waarde.id;
        if (waarde.id)
        {
          if (waarde.id.length < 1)
          {
            error = true;
          }

        }
        else
        {
            error = true;
        }
      }
      save = error;
      that.setState({error:error})
      this.setState({save:save, data:data})
      return;
    }       

    async CellClick(waarde) {
        var data = waarde.data
        let column = waarde.column.colDef.field;
        console.log(data)

        let retdata = null;
 
      switch (column) {
        case "save":
          break;

        case "verwijder":
          break;

          break;

        default:

          this.setState({ PopUpScreen: (<PopViewProforma open={true} data={data} factnr={this.props.factnr} seljobs={this.props.seljobs} close={this.ClosePopViewProforma} title='Te controlen factuur die wordt aangemaakt' />) });
          return;
      }
    }


    async ClosePopViewProforma(terdata, deldata) {
      let data = this.state.data;
      if (terdata && terdata.index)
      {
          data[terdata.index] = terdata;
      }
      this.setState({ PopUpScreen: null, data:data });
      if (gridapi)
      {
        let params = {force: true,};
        gridapi.api.refreshCells(params)     
      }
    }





    async CellCheck(params,waarde,datacol) {
        var colId = params.props.column.getId();
        let terug = false
        let tarief = 0
        let mintarief = 0
        let maxtarief = 0
        if (colId === 'tarief')
        {
          tarief = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(tarief)) {tarief = 0}
          params.props.data.tarief = tarief          
        }
        if (colId === 'mintarief')
        {
          mintarief = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(mintarief)) {mintarief = 0}
          params.props.data.mintarief = mintarief          
        }
        if (colId === 'maxtarief')
        {
          maxtarief = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(maxtarief)) {maxtarief = 0}
          params.props.data.maxtarief = maxtarief          

        }
        tarief = (params.props.data && params.props.data.tarief && params.props.data.tarief.constructor === String) ? parseFloat(params.props.data.tarief) : params.props.data.tarief;
        mintarief = (params.props.data && params.props.data.mintarief && params.props.data.mintarief.constructor === String) ? parseFloat(params.props.data.mintarief) : params.props.data.mintarief;
        maxtarief = (params.props.data && params.props.data.maxtarief && params.props.data.maxtarief.constructor === String) ? parseFloat(params.props.data.maxtarief) : params.props.data.maxtarief;

        if (params.props.data && params.props.data.type && params.props.data.type.length > 1 && params.props.data.tarief > 0  )
        {
            if (mintarief > 0 || maxtarief > 0)
            {
              params.props.data.save = true;
            }
            else
            {
             params.props.data.save = ''; 
            }
        }
        else
        {
            params.props.data.save = ''; 
        }        

        await params.setState({error:terug})
    }       





    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var top = '50px';
        var left = '0px';
        var bottom = '10px';

        return (
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12} lg={12}>
                      { this.state.data && this.state.data.length > 0 &&  this.state.kol &&
                        <TableGrid
                          tableheight="300px" 
                          columns={this.state.kol}
                          data={this.state.data} 
                          sizetofit={true}
                          sortable={true}
                          suppressMenu={true}
                          enableRowGroup={false}
                          suppressColumnVirtualisation={true}    
                          CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                          CellValueCheck={(params,value,error) => this.CellCheck(params,value,error)}
                          filter={false}
                          sleutel='JobStaffelTarief'
                          editable={false}
                          GridReady={this.onGridReady}
                        />
                      }
                    </Grid>
                  </Grid>
                  {this.state.showLoadingGif &&
                    <div style={{ textAlign: "center", verticalAlign: "center", lineHeight: Math.floor(window.innerHeight * .85) + 'px' }}>
                      <img alt='' src={LoaderGif} />
                    </div>
                  }

                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                    <Button color="primary"  onClick={() => this.handleSave()}>
                      Verwerking (aanmaken facturen)
                    </Button>
                </DialogActions>
                {this.state.PopUpScreen}
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
  ...state
});


PopListCreaInv.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopListCreaInv)));

