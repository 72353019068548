import React from "react";
import PropTypes from "prop-types";
import "./Calculator.css";

export default class CalculatorButton extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    wide: PropTypes.bool,
    clickHandler: PropTypes.func,
  };

  handleClick = () => {
    this.props.clickHandler(this.props.name);
  };

  render() {
    const className = [
      "calculator-button",
      this.props.wide ? "wide" : "",
    ];
    return (
      <div className={className.join(" ").trim()}>
        <button onClick={this.handleClick}>
          {
          !!this.props.children ? 
            this.props.children :          
            this.props.name
          }
        </button>
      </div>
    );
  }
}