import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";

import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import Validation from "../../functions/validation.js";
import {AllSuppliers} from "../../redux/settings/supplierActions";


class PopAddZipcode extends React.Component {
    state = {
        data: { id: 0, zipcode: '', place: '', country: '', country_txt:''},
        waarde:this.props.waarde,
        toevobject:this.props.toevobject,
        save:true,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
    }
    async componentDidMount(){
      if (!this.props.supplier.AllData) {
        await this.props.dispatch(AllSuppliers());
      }
      let hdata = this.state.data;
      var datarr = this.props.waarde.split("-");      
      if (datarr.length === 3)
      {
        hdata.zipcode = datarr[0];
        hdata.place = datarr[1];
        hdata.country_txt = datarr[2];
      }
      if (datarr.length === 2)
      {
        hdata.zipcode = datarr[0];
        hdata.place = datarr[1];
      }
      if (datarr.length === 1)
      {
        hdata.zipcode = datarr[0];
      }
      this.setState({data:hdata})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens
      let retdata = await SqlFunction.DoSql('ChgZipcode', this.state.data);
      if (this.props.close) 
      {
        this.props.close(retdata)
      }
    }
    CheckSave(data) {
        let terug = false
        terug = Validation.ValidCheck('AllZipcode', data.zipcode,'zipcode', this, this.props.param.AllPostcodesData.data, data);

        if (terug === false) {
          terug = Validation.ValidCheck('AllZipcode', data.place,'place', this, this.props.param.AllPostcodesData.data, data);
        }
        if (terug === false) {
          terug = Validation.ValidCheck('AllZipcode', data.country,'country', this, this.props.param.AllPostcodesData.data, data);
        }
        return terug;
    }

    async ValueCheck(that, waarde, colId) {
        let data = this.state.data;
        let terug = false;
        switch (colId) {
            case 'country_txt':
                data.country_txt = waarde.value;
                data.country = waarde.id;
                terug = Validation.ValidCheck('AllZipcode', waarde.value,colId, that, this.props.param.AllPostcodesData.data, data);

                break;

            default:
                data[colId] = waarde;
                terug = Validation.ValidCheck('AllZipcode', waarde,colId, that, this.props.param.AllPostcodesData.data, data);
                break;
        }
        that.setState({ error: terug })
        if (terug === false)
        {
          terug = await this.CheckSave(data);
        }
        this.setState({ save: !terug, data: data, checkval: false })
    }   


    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {fullScreen,classes, open} = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal80
                }}

                fullScreen={fullScreen}
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible",zIndex:'1021',width:'100%'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                maxWidth='md'
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',paddingLeft:'20px'}}  className={classes.blockContent}>
                  <Grid style={{marginLeft:'10px', marginRight: '10px',width: '99%'}} container spacing={1}>
                    <Grid item sm={12} md={12} lg={12}>
                        <EditString className="card-title m-t-10"
                            value={this.state.data.zipcode}
                            coltype='STR'
                            labelText="Postcode"
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'zipcode')}
                        />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <EditString className="card-title m-t-10"
                            value={this.state.data.place}
                            coltype='STR'
                            labelText="Plaats"
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'place')}
                        />
                    </Grid>
                    <div style={{ width: '100%', textAlign: 'center' }}>
                        <EditAutoSelect className="card-title m-t-10"
                            checkval={this.state.checkval}
                            value={this.state.data.country_txt}
                            labelText="Land"
                            lijst='AllLanden'
                            colId='country_txt'
                            selectcel='country'
                            id={this.state.data.country}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'country_txt')}
                        />
                    </div>
                  </Grid>
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  {this.state.save &&
                    <Button color="primary"  onClick={() => this.handleSave()}>
                      Opslaan
                    </Button>
                  }
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
  ...state
});


export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopAddZipcode)));

