const calendarStyles = {
    dow: {
        borderBottom: "none",
        cursor: "default",
        width: 100 / 7 + "%",
        fontWeight: "500",
        textAlign: "center"
    },
    rdt: {
        position: "relative",
        width: "100%"
    },
    rdtActive: {
        position: "relative",
        width: "100%"

    },
    rdtHalfDay: {
        "&:before": {
            content: '""',
            position: "absolute",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            width: "calc(50% - 20px)",
            height: "100%",
            top: 0,
            left: 0
        },
        "&:after": {
            content: '""',
            position: "absolute",
            borderLeft: "solid 40px rgba(255, 255, 255, 0.2)",
            borderBottom: "solid 40px transparent",
            top: 0,
            left: "calc(50% - 20px)"
        }
    },
    rdtArrow: {
        fontSize: "21px",
        textAlign: "center",
        verticalAlign: "top",
        width: "100px",
        cursor: "pointer",
        transition: "color 300ms",
        "&:hover": {
            color: "#fb9678"
        }
    },
    rdtClosed: {
        display: "none",
    },
    rdtCounters: {
        display: 'inline-block'
    },
    rdtDay: {
        cursor: "pointer",
        height: "40px",
        lineHeight: "40px",
        textAlign: "center",
        position: "relative",
        width: (100 / 7) + '%',
        transition: "background-color 300ms"
    },
    rdtWeekendDay: {
        "&:not($rdtDisabled)": {
            backgroundColor: "#f0f0f0"
        }
    },
    rdtViewBody: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: '5px'
        // padding: '5px'
    },
    rdtViewHeader: {
        backgroundColor: "#4F5467",
        color: "#ffffff",
        display: "flex",
        padding: "5px",
    },
    rdtDisabled: {
        background: "none",
        color: "#999999",
        cursor: "not-allowed"
    },
    rdtMonth: {
        height: "50px",
        lineHeight: '50px',
        textAlign: "center",
        width: "25%",
        cursor: "pointer",
        "&:hover:not($rdtActive):not($rdtDisabled)": {
            backgroundColor: "#eeeeee",
        }
    },
    rdtNew: {
        color: "#dddddd"
    },
    rdtOld: {
        color: "#dddddd"
    },
    rdtOpen: {
        display: 'block'
    },
    rdtPicker: {
        color:"black",
        position: "absolute",
        width: "100%",
        // padding: "4px",
        // marginTop: "1px",
        // zIndex: "99999 !important",
        backgroundColor: "#FFFFFF",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        border: "1px solid #f9f9f9",
        "& table": {
            borderCollapse: "collapse",
            width: "100%",
            margin: "0"
        },
        "& tfoot": {
            borderTop: "1px solid #f9f9f9"
        },
        "& button": {
            border: "none",
            background: "none",
            cursor: "pointer",
            "&:hover":{
                backgroundColor: "#eeeeee"
            }
        },
        "& thead": {
            backgroundColor: "#4F5467",
            color: "#ffffff",
            "& button": {
                width: "100%",
                height: "100%"
            },
            "& tr": {
                "&:first-child": {
                    '& th': {
                        cursor: "pointer",
                        transition: "color 300ms",
                        "&:hover": {
                            color: "#fb9678"
                        }
                    }
                }
            }
        }
    },
    rdtStatic: {
        "& $rdtPicker": {
            boxShadow: "none",
            position: 'static'
        }
    },
    rdtSwitch: {
        flexGrow: "1",
        fontWeight: "500",
        textAlign: "center",
        width: "100px",
        cursor: "pointer",

        transition: "color 300ms",
        "&:hover": {
            color: "#fb9678"
        }
    },
    rdtToday: {
        position: "relative",
        fontWeight: "500",
        color: "#001133"
    },
    rdtYear: {
        height: "50px",
        lineHeight: '50px',
        textAlign: "center",
        width: "25%",
        cursor: "pointer",
        "&:hover:not($rdtActive):not($rdtDisabled)": {
            backgroundColor: "#eeeeee",
        }
    },


    customIndication: {

    },
    periodBegin: {
        borderBottomLeftRadius: '20px',
        borderTopLeftRadius: '20px'
    },
    periodEnd: {
        borderBottomRightRadius: '20px',
        borderTopRightRadius: '20px'
    },
    notApproved: {
        backgroundColor: '#ea4c4c',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#c04b4b'
        },
        '&$rdtNew, &$rdtOld': {
            backgroundColor: "rgba(234, 76, 76, 0.2)",
            "&:hover": {
                backgroundColor: "rgba(192, 75, 75, 0.2)",
            }
        }
    },
    approved: {
        backgroundColor: '#2cc12c',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#2cac2c'
        },
        '&$rdtNew, &$rdtOld': {
            backgroundColor: "rgba(44, 193, 44, 0.2)",
            "&:hover": {
                backgroundColor: "rgba(44, 172, 44, 0.2)",
            }
        }
    },

    selected: {
        backgroundColor: "rgba(0, 143, 183, 0.6)",
        color: '#ffffff',
        "&:hover": {
            backgroundColor: "rgba(0, 143, 183, 1)",
        },
        '&$rdtNew, &$rdtOld': {
            backgroundColor: "rgba(0, 143, 183, 0.6)",
            "&:hover": {
                backgroundColor: "rgba(0, 143, 183, 0.6)",
            }
        }
    },
    dayStatus: {
        height: "100%",
        width: "100%",
        display: "block",
        textAlign: "center",
        transition: "background-color 300ms",
        "&:hover:not($customIndication):not($rdtActive):not($rdtNew):not($rdtOld)": {
            backgroundColor: "rgba(47, 165, 199, 0.2)"
        },
        "&$rdtNew:hover:not($customIndication):not($rdtActive), &$rdtOld:hover:not($customIndication):not($rdtActive)": {
            backgroundColor: "rgba(0, 0, 0, 0.05)"
        }
    }
};

export default calendarStyles;