import React, {
  Component,
} from 'react';

import CustomCheck from "../CustomCheck.jsx";
import GenFunc from "../../functions/gen_functions.js";

export default class GridEditCheck extends Component {
    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
        this.inputid = GenFunc.MakeId()
        this.myInput = React.createRef();
        this.state = {
            value: (this.props.value === '0' || this.props.value === 0 ) ? false : true,
            error:false,
        }
    }
    componentDidMount() {
        this.props.agGridReact.props.onCellValueCheck(this,this.props.value)
        setTimeout(() => {
          document.activeElement.blur();
          let element = document.getElementById(this.inputid);
          element.focus()
          element.select()
       }, 10);    
    }
    getValue() {
        return this.state.value;
    }
    isPopup() {
        return false;
    }
    change(event, valid, stateName) 
    {
      var colId = this.props.column.getId();
      if (event.target) 
      {
        this.setState({value:event.target.checked})
        let evtc = event.target.checked ? '1' : '0';
        this.props.node.setDataValue(colId, evtc);
        this.props.agGridReact.props.onCellValueCheck(this,evtc)

      } 
    }
    render() {
        let strlengte = this.props.column.actualWidth.toString()+'px';
        return (
          <div  style={{top:'0px',left:'0px',position:'absolute',marginBottom:'0px'}}>
            <CustomCheck
                style={{width:strlengte}}
                ref={this.myInput}
                onChange= {this.change}
                id={this.inputid}
                value={this.state.value}
            />
          </div>
        );
    }
}