const customChekStyle = {
  brutroot: {
    '&:hover': {
      backgroundColor: 'transparent',
      color:'#4F5467',
    },
  },
  brutcol: {color:'#4F5465',},  
  bruticon: {
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  brutcheckedIcon: {
    'input:hover ~ &': {
      backgroundColor: '#4F5465',
    },
  },
};

export default customChekStyle;
