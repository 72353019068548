import React, {createRef } from 'react';
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 


import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import { Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import EditSelect from "../../components/editselect.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";

import FileDownload from "../../components/FileDownload.jsx";

import {AllPostcodes} from "../../redux/settings/paramActions";
import {AllAccounts,GetAccount} from "../../redux/settings/accountActions";
import {AllContacts} from "../../redux/settings/contactActions";

import {GetContact,GetDocContact,GetCorContact,CreateContact, setTab} from "../../redux/settings/contactActions";
import {setPanelRight, setTitleRight, setSubTitle,zetview,clickview,zetpopup,resetview} from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import Dropzone from 'react-dropzone';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SubSpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SubSpeedDialIcon';
import SaveIcon from '@material-ui/icons/Save';
import FilePlus from '@material-ui/icons/FilePlus';

import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import Delete from "@material-ui/icons/Delete";

import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";

import PopDetailCor from "../templates/PopDetailCor.jsx";
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';



const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point 
  if (dropzoneRef.current) {
    dropzoneRef.current.open()
  }
};
moment.locale('nl-be');
const accdropid = GenFunc.MakeId()
const accframeid = GenFunc.MakeId()
const accformid = GenFunc.MakeId()

var dropdoc = null;

const mapStateToProps = state => ({
  ...state
});

class Contactpage extends React.Component {

  state = {
    DownloadData:{id:''},
    subspeedzien:true,
    accounts:[],
    tabsubspeedzien:true,
    tableheight: Math.floor(window.innerHeight * .70) + 'px',
    tabvalue: this.props.contact && this.props.contact.LastTab ? this.props.contact.LastTab : '1',
    edit:this.props.contact && this.props.contact.MainData && this.props.contact.MainData.data && this.props.contact.MainData.data.id === 0 ? true : false,
    save:false,
    subsave:false,
    checkval:false,
    data:this.props.contact && this.props.contact.MainData && this.props.contact.MainData.data && this.props.contact.MainData.data.id === 0 ? this.props.contact.MainData.data : null,
    speedactions:[
      { icon: <EditIcon />, name: 'Edit', tooltip:'Aanpassen gegevens' },
      { icon: <MdiNewBox />, name: 'New',  tooltip:'Nieuwe contactpersoon'  },
      { icon: <SaveIcon />, name: 'Save', tooltip:'Bewaren gegevens'  },
      { icon: <Delete />, name: 'Delete', tooltip:'Verwijderen contactpersoon'  },

    ],
    speedopen:false,
    subspeedactions: [
      { icon: <FilePlus />, name: 'Document', tooltip:'Document toevoegen'  },
    ],
    subspeedopen: false,    
  };


  constructor(props, context) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);    
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.ValueCheck = this.ValueCheck.bind(this);
    this.ErrorCheck = this.ErrorCheck.bind(this);

    this.onDrop = this.onDrop.bind(this);
    this.DocCellClick = this.DocCellClick.bind(this);
    this.CorCellClick = this.CorCellClick.bind(this);
    
    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
    this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);

    this.ZetEdit = this.ZetEdit.bind(this);
    this.CheckSave = this.CheckSave.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.MainSave = this.MainSave.bind(this)
    this.checkconfirm = this.checkconfirm.bind(this)
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
    this.handleJa = this.handleJa.bind(this)
    this.ActionClick = this.ActionClick.bind(this)
    this.SubActionClick = this.SubActionClick.bind(this)

    this.AccDetClick = this.AccDetClick.bind(this)
    this.ClosePopDetailCor = this.ClosePopDetailCor.bind(this);

    this.addAccount = this.addAccount.bind(this)
    this.DeleteAccount = this.DeleteAccount.bind(this)
    this.ClickAccount = this.ClickAccount.bind(this)
    this.ClickParAccount = this.ClickParAccount.bind(this)

    this.TitleClick = this.TitleClick.bind(this)


  }

  async handleTabChange(event, newValue)  {
    if (newValue === '1')
    {
      let subspeedactions = [
      { icon: <FilePlus />, name: 'Document', tooltip: 'Document toevoegen' },
      ]
      this.setState({subspeedactions:subspeedactions})
      // Documenten
      await this.props.dispatch(GetDocContact(this.props.contact.MainData.data.id))
    }
    if (newValue === '2')
    {
      let subspeedactions = [
      { icon: <FilePlus />, name: 'Correspondentie', tooltip: 'Document toevoegen' },
      ]
      this.setState({subspeedactions:subspeedactions})
      // Documenten
      await this.props.dispatch(GetCorContact(this.props.contact.MainData.data.id))
    }
    this.props.dispatch(setTab(newValue))
    this.setState({tabvalue:newValue});

  };

  addAccount()
  {
    let data = {};
    let arrvelden = this.state.accounts;
    let gevonden = null;
    for (let i = 0; i < arrvelden.length; i++) 
    {
        let veld = arrvelden[i];
        if (veld.account_id === this.state.data.parent_account)
        {
            gevonden = i;
        }
    }
    if (gevonden === null)
    {
      data.id= 0;
      data.account_id=this.state.data.parent_account;
      data.contact_id=this.state.data.id;
      data.account_name = this.state.data.account_name;
      arrvelden.push(data);
    }
    this.setState({accounts:arrvelden,save:true})
  }

  DeleteAccount(data) {
    let gevonden = null;
    let arrvelden = this.state.accounts;

    for (let i = 0; i < arrvelden.length; i++) 
    {
        let veld = arrvelden[i];
        if (veld.account_id === data.account_id)
        {
            gevonden = i;
        }
    }
    if (gevonden !== null)
    {
        arrvelden.splice(gevonden,1);
    }
    this.setState({accounts:arrvelden,save:true})
  }


  handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:true });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
  };

  handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
  };

  handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
  };

  async ActionClick(name)
  {
    switch (name) 
    {
      case 'Edit':
        this.setState({edit:true, data:this.props.contact.MainData.data,speedopen:false})
        break;

      case 'New':
        await this.props.dispatch(CreateContact())
        this.setState({edit:true, data:this.props.contact.MainData.data,speedopen:false})
        break;

      case 'Save':
        this.MainSave();
        break;

      case 'Delete':
          let acc_id = this.props.contact.MainData.data.parent_account;
          await SqlFunction.DoSql('DelContact',this.props.contact.MainData.data);
          if (acc_id && acc_id.length > 3)
          {
            this.props.dispatch(clickview({id:acc_id, vorm:'ACC'}));
            await this.props.dispatch(AllAccounts(this.props))
            this.props.dispatch(setSubTitle('Klantenfiche'))
            await this.props.dispatch(GetAccount(acc_id))
            this.props.history.push('/account');
          }
          else
          {
            await this.props.dispatch(AllContacts(this.props))
            this.props.history.push('/contacts');
          }


        break;

      case 'Document':
        dropdoc = null;
        let element = document.getElementById(accdropid);
        element.click()
        break;


      default:    
        break;
    }     
    this.setState({speedopen:false, subspeedopen:false});
  }
  async SubActionClick(name) {
    let data = null;
    switch (name) {
      case 'Correspondentie':
        if (this.state.save === true && this.state.edit === true ) 
        {
          await this.checkconfirm(this.props.contact.MainData);
        }
        else
        {
          this.setState({edit:false})
        }

        data = await GenFunc.zetdataleeg('Cor');
        data.account_id = this.props.contact.MainData.data.parent_account;
        data.contact_id = this.props.contact.MainData.data.id;
        data.contact_name = this.props.contact.MainData.data.last_name + ' ' + this.props.contact.MainData.data.first_name;
        data.user_id = this.props.user.userData.id;
        data.datum = GenFunc.Get_Vandaag();
        data.account_name = this.props.contact.MainData.data.account_name
        this.setState({ PopUpScreen: (<PopDetailCor open={true} data={data} close={this.ClosePopDetailCor} title='Aanmaken document' />) });
        break;

      case 'Document':
        if (this.state.save === true && this.state.edit === true) 
        {
          await this.checkconfirm(this.props.contact.MainData);
        }
        else
        {
          this.setState({edit:false})
        }

        dropdoc = null;
        let element = document.getElementById(accdropid);
        element.click()
        break;

      default:
        break;
    }
    this.setState({ speedopen: false, subspeedopen:false });
  }

  async ClosePopDetailCor(terdata) {
    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    await this.props.dispatch(GetCorContact(this.props.contact.MainData.data.id))
    this.setState({ tabvalue: '2' });
  }




  ZetEdit()
  {
     this.setState({edit:true, data:this.props.contact.MainData.data, accounts:this.props.contact.MainData.accounts})
  }
  componentDidMount(){
    window.addEventListener("resize", this.resizeFunction);
    this.props.dispatch(setTitleRight('Contactpersonen'))
    this.props.dispatch(setPanelRight(true))
    let prevtab = this.state.tabvalue
    this.handleTabChange(null,prevtab)
  }

  componentWillUnmount() 
  {
    this.checkconfirm(this.props.contact.MainData);    
    this.props.dispatch(setPanelRight(false))
    this.props.dispatch(setTitleRight(''))
    window.removeEventListener("resize", this.resizeFunction);
  }

  async componentWillReceiveProps(nextProps, nextState) 
  {
    if (this.props.contact.MainData)
    {
      if (this.props.contact.MainData.data.id !== nextProps.contact.MainData.data.id)
      {
        await this.checkconfirm(this.props.contact.MainData);
        if (nextProps.contact.MainData.data.id === 0)
        {
          this.setState({edit:true,save:false,subsave:false})
          this.handleTabChange(null,'1')
        }
        else
        {
          await this.props.dispatch(GetDocContact(nextProps.contact.MainData.data.id))
          await this.props.dispatch(GetCorContact(nextProps.contact.MainData.data.id))

          this.setState({edit:false,save:false,subsave:false})
          let prevtab = this.state.tabvalue
          this.handleTabChange(null,prevtab)
        }
      }
    }
  }

  async AccDetClick(waarde) {
    this.props.dispatch(zetview(this.props.contact.MainData.data.last_name+' '+this.props.contact.MainData.data.first_name,this.props.contact.MainData.data.id,'CONT'));
    this.props.dispatch(clickview({id:this.props.contact.MainData.data.parent_account, vorm:'ACC'}));
    await this.props.dispatch(AllAccounts(this.props))
    this.props.dispatch(setSubTitle('Klantenfiche'))
    await this.props.dispatch(GetAccount(this.props.contact.MainData.data.parent_account))
    this.props.history.push('/account');
    return;
  }

  async ClickAccount(data) {
    this.props.dispatch(zetview(this.props.contact.MainData.data.last_name+' '+this.props.contact.MainData.data.first_name,this.props.contact.MainData.data.id,'CONT'));
    this.props.dispatch(clickview({id:data.account_id, vorm:'ACC'}));
    await this.props.dispatch(AllAccounts(this.props))
    this.props.dispatch(setSubTitle('Klantenfiche'))
    await this.props.dispatch(GetAccount(data.account_id))
    this.props.history.push('/account');
    return;
  }

  async ClickParAccount(data) {
    this.props.dispatch(zetview(this.props.contact.MainData.data.last_name+' '+this.props.contact.MainData.data.first_name,this.props.contact.MainData.data.id,'CONT'));
    this.props.dispatch(clickview({id:data.parent_account, vorm:'ACC'}));
    await this.props.dispatch(AllAccounts(this.props))
    this.props.dispatch(setSubTitle('Klantenfiche'))
    await this.props.dispatch(GetAccount(data.parent_account))
    this.props.history.push('/account');
    return;
  }


  async handleJa(parameters)
  {
    if (this.state.save === true)
    {
      let savdata = this.state.data;
      savdata.accounts = this.state.accounts;
      let retdata = await SqlFunction.DoSql('ChgContact',savdata);
      if (retdata.nwzip === true)
      {
        this.props.dispatch(AllPostcodes())
      }

    }
    if (this.state.subsave === true)
    {
    }
//    this.setState({edit:false,save:false,subsave:false})
  }


  checkconfirm (parameters) 
  {

    if (this.state.save === true || this.state.subsave === true)
    {
      confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,        
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Aanpassingen niet doorgevoerd.</h1>
            <p>Wens je de aanpassingen alsnog te bewaren?</p>
            <button
              onClick={() => {
                this.handleJa(parameters);
                onClose();
              }}
            >
              Ja
            </button>
            <button onClick={() => {
                this.setState({edit:false,save:false,subsave:false,checkval:false})
                onClose();
              }}
            >
              Nee
            </button>
          </div>
        );
      }
      });
    }

  }

  resizeFunction() 
  {
    if (window.innerHeight >= 960) 
    {
      this.setState({tableheight: Math.floor(window.innerHeight * .70) + 'px'});
    }
    else
    {
      this.setState({tableheight: '600px'});
    }
  }


  async MainSave() {
    if (this.state.save === true) 
    {
      let savdata = this.state.data;
      savdata.accounts = this.state.accounts;
      // toevoegen account
      let arrvelden = savdata.accounts;
      let gevonden = null;
      let data = {}
      for (let i = 0; i < arrvelden.length; i++) 
      {
        let veld = arrvelden[i];
        if (veld.account_id === savdata.parent_account)
        {
            gevonden = i;
        }
      }
      if (gevonden === null)
      {
          data.id= 0;
          data.account_id=savdata.parent_account;
          data.contact_id=savdata.id;
          data.account_name = savdata.account_name;
          arrvelden.push(data);
      }
      savdata.accounts = arrvelden;
      let retdata = await SqlFunction.DoSql('ChgContact',savdata);
      if (retdata && retdata.nwzip === true)
      {
        this.props.dispatch(AllPostcodes())
      }
      this.setState({edit:false,save:false,data:null,checkval:false})
      if (retdata)
      {
          await this.props.dispatch(GetContact(retdata.id))
      }
    }
  }

  async TitleClick() {
    if (this.state.save === true) 
    {
      let savdata = this.state.data;
      savdata.accounts = this.state.accounts;
      let retdata = await SqlFunction.DoSql('ChgContact',savdata);
      if (retdata && retdata.nwzip === true)
      {
        this.props.dispatch(AllPostcodes())
      }
      if (retdata)
      {
          await this.props.dispatch(GetContact(retdata.id))
      }
    }
    if (this.state.edit === true) {
      this.setState({edit:false,save:false,data:null,checkval:false})
    } else 
    {
      this.ZetEdit()
    }

  }


  async DocCellClick(waarde) {
    let column = waarde.column.colDef.field;
    if (column === 'upload')
    {
      dropdoc = waarde.data;
      let element = document.getElementById(accdropid);
      element.click()
      return;
    }
    if (column === 'verwijder')
    {
      await SqlFunction.DoSql('DeleteFile',waarde.data);
      await this.props.dispatch(GetDocContact(this.props.contact.MainData.data.id))
      return;
    }
    await this.setState({DownloadData:waarde.data});
    document.getElementById(accformid).submit();
    return;
  }

  async CorCellClick(waarde) {
    let cordata = await SqlFunction.DoSql('GetCor', waarde.data);
    let title = 'Document correspondentie - ' + cordata.data.description
    window.scrollTo(0, 0);
    this.props.dispatch(zetpopup((<PopDetailCor open={true} data={cordata.data} close={this.ClosePopDetailCor} title={title} />)));
  }

  getContextMenuItems(params,sleutel) 
  {
    return null;
  }


  CheckSave(data)
  {
    let terug = true
    terug = Validation.ValidCheck(this.props.settings.sub_title,data.first_name,'first_name',this,this.props.contact.AllData.data,data) ;
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.sub_title,data.last_name,'last_name',this,this.props.contact.AllData.data,data) ;
    }
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.sub_title,data.e_mail,'e_mail',this,this.props.contact.AllData.data,data) ;
    }
    return !terug;
  }


  async onDrop(files) {
    const formData = new FormData();
    this.handleTabChange(null,'0');
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        formData.append('file', file);

    }
    if (dropdoc)
    {
      formData.set('accountid', dropdoc.account_id);
      formData.set('contactid', dropdoc.contact_id);
      formData.set('type', dropdoc.type);    
      formData.set('docid', dropdoc.id);    
      formData.set('docdata', dropdoc);    
    }
    else
    {
      formData.set('contactid', this.props.contact.MainData.data.id);
      formData.set('accountid', this.props.contact.MainData.data.parent_account);
      formData.set('type', 'contact');    
    }

    await SqlFunction.DoSqlBody('saveFile',formData);
    dropdoc = null;
    this.handleTabChange(null,'1');

  };

  async ValueCheck(that,waarde,type,colId) {
    let data = this.state.data;
    let terug = false;
    switch (colId) 
    {
      case 'account_name':
        data.account_name = waarde.value;
        data.parent_account = waarde.id;
        if (waarde.id) {this.addAccount()}
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.value,colId,that,this.props.contact.AllData.data,data) ;
        break;

      case 'salutation_txt':
      case 'salutation':

        data.salutation_txt = waarde.text;
        data.salutation = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.contact.AllData.data, data);
        break;

      case 'zipcode_txt':
        data.zipcode_txt = waarde.value;
        data.zipcode = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.value,colId,that,this.props.contact.AllData.data,data) ;
        break;

      case 'jobtitle_txt':
        data.jobtitle_txt = waarde.value;
        data.jobtitle = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.value,colId,that,this.props.contact.AllData.data,data) ;
        break;

      default:    
        data[colId] = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde,colId,that,this.props.contact.AllData.data,data) ;
        break;
    } 
    that.setState({error:terug})
    terug = await this.CheckSave(data);
    this.setState({save:terug,data:data,checkval:false})
  }       


  async ErrorCheck(that, waarde, type, colId) {
    let data = this.state.data;
    let terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.contact.AllData.data,data);
    that.setState({ error: terug })
  }      


  render() {
    const { classes } = this.props;
    var {tableheight} = this.state;
    let mailref = (this.props.contact && this.props.contact.MainData  && this.props.contact.MainData.data && this.props.contact.MainData.data.e_mail.length > 3) ? "mailto:"+this.props.contact.MainData.data.e_mail : null; 




    return (
      <div >
        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>        
          <Grid container>
            <Grid item xs={8}>          
              <h4 tag="h4" className="mt-3" style={{cursor: 'pointer'}} onClick={() => {this.TitleClick()}}>{this.props.settings.sub_title}</h4>  
            </Grid>
            <Grid item xs={4}>  
            </Grid>
          </Grid>
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? false :
                                     (action.name === 'Edit' && this.state.edit === true) ? true :
                                     (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? true :
                                     (action.name === 'New' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? false :
                                     (action.name === 'New' && this.state.edit === true) ? true :
                                     (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? true :
                                     (action.name === 'Delete' && !GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','verwijder')) ? true :
                                     (action.name === 'Save' && this.state.edit === true && this.state.save === true) ? false :
                                     (action.name === 'Save' && this.state.edit === true && this.state.save === false) ? true :
                                     (action.name === 'Save' && this.state.edit === false ) ? true : 
                                     (action.name === 'Save' && this.state.save === false) ? true : false}}
                onClick={() => {this.ActionClick(action.name)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
          </SpeedDial>          
          { (this.state.tabvalue === '1' || this.state.tabvalue === '2' ) && this.state.subspeedzien &&
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SubSpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.subspeedDialToolb, fab: classes.subspeedFabRoot}}           
            onClose={this.handleSubSpeedClose}
            onOpen={this.handleSubSpeedOpen}
            open={this.state.subspeedopen}          
          >
            {this.state.subspeedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                FabProps={{disabled: (action.name === 'Document' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? false :
                                     (action.name === 'Document' && (this.state.edit === true )) ? true :
                                     (action.name === 'Document' && !GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? true : false}}
                onClick={() => {this.SubActionClick(action.name)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
          </SpeedDial>     
          }     



        </Toolbar>
        {this.props.contact && this.props.contact.MainData && this.props.contact.MainData.data &&
          <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick ={true} noKeyboard={true}>
            {({getRootProps, getInputProps}) => (
              <section className="container" style={{width:'100%',maxWidth:'100%'}}>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <button style={{display: 'none'}} id={accdropid} type="button" onClick={openDialog}></button>         
                  <FileDownload formid={accformid} frameid={accframeid} DocData={this.state.DownloadData}/>

                  <Grid container style={{width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <Card className='card_brut'>
                          {this.state.edit === false &&
                            <PerfectScrollbar className="sidebar-nav">
                              <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} className="card-title m-t-10">{this.props.contact.MainData.data.last_name} {this.props.contact.MainData.data.first_name} </h4>
                                <div onClick={() => {this.ClickParAccount(this.props.contact.MainData.data)}} style={{width:'100%',cursor: 'pointer'}}>
                                  <h6> {this.props.contact.MainData.data.account_name}</h6> 
                                  <h6> {this.props.contact.MainData.data.account_number}</h6> 

                                </div>
                                <div onClick={() => {this.ZetEdit()}} style={{width:'100%'}}>

                                  <h6> {this.props.contact.MainData.data.extern}</h6> 
                                  <h6> {this.props.contact.MainData.data.jobtitle_txt}</h6> 
                                  <small className="text-muted p-t-30 db">Vervaldag ID</small>                  
                                  <h6>{this.props.contact.MainData.data.vervaldagid}</h6> 
                                  <small className="text-muted p-t-30 db">Aanspreektitel</small>                  
                                  <h6>{this.props.contact.MainData.data.salutation_txt}</h6> 
                                  <div className='div_line' style={{marginBottom: '25px'}}></div>
                                </div>
                                <h6 style={{cursor: 'pointer'}}> <a href={mailref}>{this.props.contact.MainData.data.e_mail}</a></h6> 
                                <div onClick={() => {this.ZetEdit()}} style={{width:'100%'}}>
                                  <small className="text-muted p-t-30 db">Telefoon</small>                  
                                  <h6>{this.props.contact.MainData.data.main_phone}</h6> 
                                  <small className="text-muted p-t-30 db">Gsm nummer</small>                  
                                  <h6>{this.props.contact.MainData.data.mobile_phone}</h6> 
                                  <div className='div_line' style={{marginBottom: '25px'}}></div>
                                  <small className="text-muted p-t-30 db">Adres</small>                  
                                  <h6>{this.props.contact.MainData.data.street}</h6> 
                                  <h6>{this.props.contact.MainData.data.zipcode_txt}</h6> 
                                  <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <small className="text-muted p-t-30 db">Bedrijven</small>                  
                                    </Grid>
                                  </Grid>
                                </div>
                                <Grid container spacing={1}>
                                  {this.props.contact.MainData.accounts  &&
                                  <Grid item sm={12} md={12} lg={12}>
                                      {this.props.contact.MainData.accounts.map( (data,i) => {
                                        if (data)
                                        {
                                          return (
                                            <Chip
                                              style={{marginTop:'5px',marginRight:'5px'}}
                                              variant="outlined"
                                              color="primary"
                                              size="small"
                                              key={GenFunc.MakeId()}
                                              onClick={() => this.ClickAccount(data)}
                                              label={data.account_name}
                                              id={GenFunc.MakeId()}
                                            />
                                          );
                                        }
                                        return null;
                                      })}    
                                  </Grid>
                                  }
                                </Grid>
                                 <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                <div onClick={() => {this.ZetEdit()}} style={{width:'100%'}}>
                                  <small className="text-muted p-t-30 db">Informatie</small>                  
                                  <EditString className="card-title m-t-10"
                                    value={this.props.contact.MainData.data.info}
                                    coltype = 'STR'  
                                    multiline={true}                      
                                    disabled
                                    readonly = {true}
                                  />
                                </div>
                                <div className='div_line' style={{marginBottom: '25px'}}></div>
                              </CardBody>
                            </PerfectScrollbar>
                          }
                          {this.state.edit === true &&
                            <PerfectScrollbar styleclassName="sidebar-nav">
                              <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.last_name}
                                        coltype = 'STR'                        
                                        labelText="Naam"
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'last_name')}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'last_name')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.first_name}
                                        coltype = 'STR'                        
                                        labelText="Voornaam"
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'first_name')}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'first_name')}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditAutoSelect className="card-title m-t-10"
                                        value={this.state.data.account_name}
                                        labelText="Hoofd bedrijf"
                                        lijst='AllAccounts'
                                        colId='account_name'
                                        selectcel='parent_account'
                                        id={this.state.data.parent_account}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'account_name')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.extern}
                                        coltype = 'STR'                        
                                        labelText="Extern Bedrijf"
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'extern')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditDateTime className="card-title m-t-10"
                                        value={this.state.data.vervaldagid}
                                        coltype = 'STR'                        
                                        labelText="Vervaldag ID"
                                        inputProps={{value:this.state.data.vervaldagid}}
                                        formControlProps={{fullWidth: true}}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'vervaldagid')}
                                      />
                                    </Grid>


                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditAutoSelect className="card-title m-t-10"
                                        value={this.state.data.jobtitle_txt}
                                        labelText="Functie"
                                        lijst='AllFuncties'
                                        colId='jobtitle_txt'
                                        selectcel='jobtitle'
                                        id={this.state.data.jobtitle}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'jobtitle_txt')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <div style={{width:'100%',textAlign:'left'}}>
                                        <EditSelect className="card-title m-t-10"
                                          value={this.state.data.salutation_txt}
                                          data={this.state.data}
                                          labelText="Aanspreektitel"
                                          type='AllAansprekingen'
                                          colId='salutation_txt'
                                          selectcel='salutation'
                                          id={this.state.data.salutation}
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'salutation_txt')}
                                        />
                                      </div>

                                    </Grid>

                                    <Grid item sm={12} md={12} lg={12}>
                                       <EditString className="card-title m-t-10"
                                        value={this.state.data.e_mail}
                                        coltype = 'STR'                        
                                        labelText="E-Mail"
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'e_mail')}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.mobile_phone}
                                        coltype = 'STR'                        
                                        labelText="Gsm nummer"
                                        format = {['+',/[0-9]/,/\d/,' ','(',/[0-9]/,')',/[0-9]/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/\d/,/\d/,/\d/,' ',/[0-9]/,/[0-9]/,/[0-9]/,' ',/[0-9]/,/[0-9]/,/[0-9]/]}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'mobile_phone')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.main_phone}
                                        coltype = 'STR'                        
                                        labelText="Telefoon"
                                        format = {['+',/[0-9]/,/\d/,' ','(',/[0-9]/,')',/[0-9]/,/\d/,' ',/\d/, /\d/,' ',/\d/,/\d/,' ',/\d/,/\d/,' ',/\d/,/\d/,' ',/[0-9]/,/[0-9]/,' ',/[0-9]/,/[0-9]/]}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'main_phone')}

                                      />
                                    </Grid>
                                  </Grid>

                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.street}
                                          coltype = 'STR'                        
                                          labelText="Adres"
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'street')}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <div style={{width:'100%',textAlign:'center'}}>
                                        <EditAutoSelect className="card-title m-t-10"
                                          value={this.state.data.zipcode_txt}
                                          GoogleZoek={true}
                                          labelText="Gemeente"
                                          lijst='AllPostcodes'
                                          colId='zipcode_txt'
                                          selectcel='zipcode'
                                          id={this.state.data.zipcode}
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'zipcode_txt')}
                                        />
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <small className="text-muted p-t-30 db">Bedrijven</small>                  
                                    </Grid>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item sm={6} md={6} lg={6}>
                                      <EditAutoSelect className="card-title m-t-10"
                                        value={this.state.data.account_name}
                                        labelText="Bedrijf"
                                        lijst='AllAccounts'
                                        colId='account_name'
                                        selectcel='parent_account'
                                        id={this.state.data.parent_account}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'account_name')}
                                      />
                                    </Grid>
                                    <Grid item sm={2} md={2} lg={2}>
                                      <div style={{width:'100%',textAlign:'left', marginLeft:'-10px',marginTop: '18px'}}>
                                        <IconButton onClick={() => this.addAccount()} color="primary"component="span">
                                           <AddIcon />
                                        </IconButton>                                    
                                      </div>
                                    </Grid>
                                    <div className='div_line' style={{marginBottom: '25px',marginTop: '25px',width:'100%'}}></div>
                                    {this.state.accounts  &&
                                    <Grid item sm={12} md={12} lg={12}>
                                        {this.state.accounts.map( (data,i) => {
                                          if (data)
                                          {
                                            return (
                                              <Chip
                                                style={{marginTop:'5px',marginRight:'5px'}}
                                                variant="outlined"
                                                color="primary"
                                                size="small"
                                                key={GenFunc.MakeId()}
                                                label={data.account_name}
                                                id={GenFunc.MakeId()}
                                                onDelete={() => this.DeleteAccount(data)}
                                              />
                                            );
                                          }
                                          return null;
                                        })}    
                                    </Grid>
                                    }
                                  </Grid>


                                  <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <small className="text-muted p-t-30 db">Info</small>                  
                                    </Grid>

                                    <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.info}
                                          coltype = 'STR'  
                                          multiline={true}                      
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'info')}
                                        />
                                    </Grid>
                                  </Grid>
                                  <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
           
                              </CardBody>
                            </PerfectScrollbar>
                          }
                        </Card>
                      </Grid>
                      <Grid item xs={9}>
                        <Card className='card_brut'>
                          <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                            <div className='div_line'>
                              <Nav tabs>
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '1' })}
                                    onClick={(event) => { this.handleTabChange(event,'1'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Documenten
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '2' })}
                                    onClick={(event) => { this.handleTabChange(event,'2'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Correspondentie
                                  </NavLink>
                                </NavItem>
                              </Nav>
                              <TabContent activeTab={this.state.tabvalue}>
                                <TabPane tabId="2">
                                  { this.state.tabvalue === '2' && this.props.contact.CorData && this.props.contact.CorData.data && this.props.contact.CorData.kol &&
                                    this.props.contact && this.props.contact.MainData && this.props.contact.MainData.data && this.props.contact.MainData.data.id !== 0 &&
                                    <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.contact.CorData.kol}
                                      data={this.props.contact.CorData.data} 
                                      sizetofit={false}
                                      sortable={false}
                                      suppressMenu={true}
                                      enableRowGroup={false}
                                      suppressColumnVirtualisation={true}                                
                                      filter={true}
                                      sleutel='CorAccounts'
                                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                      editable={false}
                                      sql={() => this.props.dispatch(GetCorContact(this.props.user.MainData.data.id))}
                                      CellClickedEvent={(column, colDef, value) => this.CorCellClick(column,colDef, value)}
                                    />
                                  }                      

                                </TabPane>
                                <TabPane tabId="1">
                                  { this.state.tabvalue === '1' && this.props.contact.DocData && this.props.contact.DocData.data && this.props.contact.DocData.kol &&
                                    this.props.contact && this.props.contact.MainData && this.props.contact.MainData.data && this.props.contact.MainData.data.id !== 0 &&
                                    <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.contact.DocData.kol}
                                      data={this.props.contact.DocData.data} 
                                      sizetofit={false}
                                      sortable={false}
                                      suppressMenu={true}
                                      enableRowGroup={false}
                                      suppressColumnVirtualisation={true}                                
                                      filter={true}
                                      sleutel='DocAccounts'
                                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                      editable={false}
                                      sql={() => this.props.dispatch(GetDocContact(this.props.user.MainData.data.id))}
                                      CellClickedEvent={(column, colDef, value) => this.DocCellClick(column,colDef, value)}
                                    />
                                  }                      
                                </TabPane>
                              </TabContent>
                            </div>
                          </CardBody>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </section>
            )}
          </Dropzone>
        }
        {this.state.PopUpScreen}

      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Contactpage));
