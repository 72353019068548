
import { ALLKANTOREN_SUCCESS, GETCONTKANTOOR_SUCCESS, GETKANTOOR_SUCCESS, GETDOCKANTOOR_SUCCESS, ALLKANTCONTACTS_SUCCESS, 
    GETKANTCONTACT_SUCCESS, GETDOCKANTCONTACT_SUCCESS, GETREGKANTOOR_SUCCESS, GETJOBSKANTOOR_SUCCESS, KANT_TAB } from '../constants/';

const INIT_STATE = {
    AllData: null,
    MainData: null,
    DocData: null,
    ContData: null,
    AllContData: null,
    MainContData: null,
    DocContData: null,
    RegData: null,
    JobsData: null,
    LastTab: '1',
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ALLKANTOREN_SUCCESS:
            return {
                ...state,
                AllData: action.payload
            };

        case KANT_TAB:
            return {
                ...state,
                LastTab: action.payload
            };

        case GETKANTOOR_SUCCESS:
            return {
                ...state,
                MainData: action.payload
            };

        case GETDOCKANTOOR_SUCCESS:
            return {
                ...state,
                DocData: action.payload
            };

        case GETCONTKANTOOR_SUCCESS:
            return {
                ...state,
                ContData: action.payload
            };

        case ALLKANTCONTACTS_SUCCESS:
            return {
                ...state,
                AllContData: action.payload
            };

        case GETKANTCONTACT_SUCCESS:
            return {
                ...state,
                MainContData: action.payload
            };

        case GETDOCKANTCONTACT_SUCCESS:
            return {
                ...state,
                DocContData: action.payload
            };

        case GETREGKANTOOR_SUCCESS:
            return {
                ...state,
                RegData: action.payload
            };

        case GETJOBSKANTOOR_SUCCESS:
            return {
                ...state,
                JobsData: action.payload
            };

        default:
            return state;

        
    }
};