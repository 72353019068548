import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont
} from "./material-kit-react.jsx";

import calendarStyles from "./calendarStyles.js";

const customInputStyle = {
  ...calendarStyles,
  disabled: {
    "&:before": {
      borderColor: "transparent !important"
    }
  },

  rdt:{
    maxHeight: '10px',
    overflow: 'visible',
    zIndex: '999999999',
    position: 'absolute',
    display: 'block',
  },
  rdtPicker: {
  position: 'absolute',
  color:'black',
  width: '125px',
  padding: '4px',
  marginTop: '1px',
  zIndex: '99999999 !important',
  background: 'red',
  boxShadow: '0 1px 3px rgba(0,0,0,.1)',
  },
  rdtOpen: {
    marginTop:'35px',
    zoom:'.8',
  },


  AutocompleteListbox: {
    margin: 0,
    padding: 0,
    zIndex: 0,
    position: 'absolute',
    width:'100%',
    listStyle: 'none',
    overflow: 'auto',
    maxHeight: 200,
    backgroundColor: '#fff',
    border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#6a7e95',
      color: '#fff',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#6a7e95',
      color: '#fff',
    },
  },




  AutocompleteOption: {
    fontSize: '0.875rem',
    fontWeight: "400",
    lineHeight: 1.5,
    fontFamily: 'inherit',
    '& > span': {
      fontFamily: 'inherit',
      fontWeight: "400",
      marginRight: 10,
      fontSize: '0.875rem',
    },
  },

  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },

  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#D2D2D2",
    },
    "&:after": {
      borderColor: primaryColor
    }
  },
  underlineError: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: dangerColor,
    },

    borderColor: dangerColor,
    "&:after": {
      borderColor: dangerColor
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor
    }
  },
  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    "& + $underline": {
      marginTop: "0px"
    }
  },

  labelShrink: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },

  labelRootError: {
    color: dangerColor + " !important"
  },
  labelRootSuccess: {
    color: successColor + " !important"
  },
  feedback: {
    position: "absolute",
    bottom: "3px",
    right: "0",
    zIndex: "2",
    display: "block",
    width: "1em",
    height: "1em",
    textAlign: "center",
    pointerEvents: "none"
  },
  feedbackRight: {
    marginRight: "22px"
  },
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
    marginBottom:'-8px',
    position: "relative",
    '&:focus': {
      background:'transparent'       ,
    },    
    "& svg,& .fab,& .far,& .fal,& .fas": {
      color: "#495057"
    }
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#FFFFFF"
    },
    "&:after": {
      borderColor: "#FFFFFF"
    }
  },
  brutUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#4F5467"
    },
    "&:after": {
      borderColor: "#4F5467"
    }
  },


  selectinputbrutnew: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2,
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important', // override inline-style
    },
  },

  selectinputbrut: {
    '& label.Mui-focused': {
      color: "#4F5467",
      borderWidth: '2px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: "#4F5467",
      borderWidth: '1px',
    },
    '& .MuiInput-underline:error': {
      borderBottomColor: "red",
      borderWidth: '1px',
    },

    '& .Mui-error:after': {
      borderBottomColor: "red",
      borderWidth: '1px',
    },

  },

  selectinputbruterror: {
    '& label.Mui-focused': {
      color: "red",
      borderWidth: '2px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: "red",
      borderWidth: '1px',
    },
    '& .MuiInput-underline:error': {
      borderBottomColor: "red",
      borderWidth: '1px',
    },

    '& .Mui-error:after': {
      borderBottomColor: "red",
      borderWidth: '1px',
    },

  },



  selectinputerror: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
  },

  input: {
    color: "#495057",
    background:'transparent',
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Poppins", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1"
    },
    '&:focus': {
      background:'transparent',
    },    
    "&::placeholder": {
      color: "#AAAAAA"
    }
  },
  whiteInput: {
    "&,&::placeholder": {
      color: "#FFFFFF",
      opacity: "1"
    }
  },
  selectMenuItemSelected: {
    backgroundColor: "#3e5569 !important",
    color: "#fff !important"
  },
  select: {
    '-moz-appearance': 'none', // Reset
    '-webkit-appearance': 'none', // Reset
    // When interacting quickly, the text can end up selected.
    // Native select can't be selected either.
    userSelect: 'none',
    paddingRight: 32,
    width: 'calc(100%)',
    minWidth: 16, // So it doesn't collapse.
    cursor: 'pointer',
    '&:focus': {
      // Show that it's not an text input
      background:'transparent'       ,
      borderRadius: 0, // Reset Chrome style
    },
    // Remove Firefox focus border
    '&:-moz-focusring': {
      color: 'transparent',
      textShadow: '0 0 0 #000',
    },
    // Remove IE11 arrow
    '&::-ms-expand': {
      display: 'none',
    },
    '&$disabled': {
      cursor: 'default',
    },
    "& selectItems": {
        color:'red',
        backgroundColor: '#2fa5c7',
    }

  },  
  selectItems: {
      position: 'absolute',
      color:'red',
      backgroundColor: 'white',
      top: '100%',
      left: '0',
      right: '0',
      zIndex: '999',
   '&:hover': {
      // Show that it's not an text input
      background:'#3e5569',
      color: "#fff",
      borderRadius: 0, // Reset Chrome style
    },      
  },
  selectOption: {
      background:'#3e5569',
      color: "#fff",
      borderRadius: 0, // Reset Chrome style
  },  
  selectMenu: {
    width: '100%', // Fix Safari textOverflow
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    zIndex: '9 !important',
    minHeight: '1.1875em', // Reset (19px), match the native input line-height
    "&selectItems div:hover": {
        backgroundColor: '#3e5569',
        color: "#fff",
    },
    "&MenuItem": {
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "2",
    whiteSpace: "nowrap",
    color: "#333",
    paddingRight: "30px",
    "&:hover": {
      backgroundColor: "#3e5569",
      color: "#fff",
    }
  },
  selectMenuItem: {
    fontSize: "13px",
    padding: "10px 20px",
    margin: "0 5px",
    borderRadius: "2px",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "2",
    whiteSpace: "nowrap",
    color: "#333",
    paddingRight: "30px",
    "&:hover": {
      backgroundColor: '#3e5569',
      color: "#fff",
    }
  },
  },  
  icon: {
    // We use a position absolute over a flexbox in order to forward the pointer events
    // to the input.
    position: 'absolute',
    right: 0,
    top: 'calc(50% - 12px)', // Center vertically
    color: '#3e5569',
    'pointer-events': 'none', // Don't block pointer events on the select under the icon.
  },  
};

export default customInputStyle;
