import React from 'react'
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 


import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {Button} from 'reactstrap';
import CashPlus from '@material-ui/icons/Cashplus';
import { Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import EditSelect from "../../components/editselect.jsx";
import EditCheck from "../../components/editcheck.jsx";


import Timeline from "../../components/TimeLine.jsx";

import {setSubTitle,zetListMain,ZetListData} from "../../redux/settings/action";
import {GetJobMain, CreateJobMain,GetsubJobMain,GetsubJobTarief,GetJobDefWorkflow} from "../../redux/settings/jobsActions";
import {setPanelRight, setTitleRight} from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SubSpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SubSpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ArrowDownwardRounded from '@material-ui/icons/ArrowDownwardRounded';
import ArrowUpwardRounded from '@material-ui/icons/ArrowUpwardRounded';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import PopJobTarieven from "./PopJobTarievenNew.jsx";
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Clear from "@material-ui/icons/Exclamation";
import SubJobNew from "@material-ui/icons/SubJobNew";

  import { DndProvider } from 'react-dnd'
//  import Backend from 'react-dnd-html5-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'  

import DndChip from "../../components/dndchip";

var gridapi = null;
var workflowstorie = null;
moment.locale('nl-be');


const mapStateToProps = state => ({
  ...state
});


class Jobdefmainpage extends React.Component {

  state = {
    DownloadData:{id:''},
    velddata:{verplicht:false,veld:'',veld_txt:'',veld_code:''},
    tableheight: Math.floor(window.innerHeight * .70) + 'px',
    tabvalue:'1', 
    pinnedSubJobData:null,
    edit:this.props.jobs && this.props.jobs.JobDefMainData && this.props.jobs.JobDefMainData.data && this.props.jobs.JobDefMainData.data.id === 0 ? true : false,
    save:false,
    subjobsave:false,
    subtariefsave: false,
    subsave:false,
    adrestel:0,
    checkval:false,
    PopUpScreen:null,
    data:this.props.jobs && this.props.jobs.JobDefMainData && this.props.jobs.JobDefMainData.data && this.props.jobs.JobDefMainData.data.id === 0 ? this.props.jobs.JobDefMainData.data : null,
    speedactions:[
      GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit') ? { icon: <EditIcon />, name: 'Edit', tooltip:'Aanpassen gegevens' } : null,
      GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit') ? { icon: <MdiNewBox />, name: 'New',  tooltip:'Nieuwe jobdefinitie maken' }:null,
      GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit') ?{ icon: <SaveIcon />, name: 'Save', tooltip:'Bewaren gegevens'  } : null,
    ],
    subspeedactions: [
      GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','edit') ? { icon: <CashPlus />, name: 'Tarieven', tooltip:'Nieuwe Tarieven'  }:null,

    ],
    speedopen: false,
    subspeedopen: false,    
    subspeedzien:true,
    workflowstories:[],
    changewfindex:null,
  };

  constructor(props, context) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);    
    this.onGridReady = this.onGridReady.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.ValueCheck = this.ValueCheck.bind(this);
    this.SecValueCheck = this.SecValueCheck.bind(this);
    this.SubJobMainChanged = this.SubJobMainChanged.bind(this);
    this.SubJobMainCheck = this.SubJobMainCheck.bind(this);
    this.SubJobMainCellClick = this.SubJobMainCellClick.bind(this);

    this.SubJobTariefChanged = this.SubJobTariefChanged.bind(this);
    this.SubJobTariefCheck = this.SubJobTariefCheck.bind(this);
    this.SubJobTariefCellClick = this.SubJobTariefCellClick.bind(this);

    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
    this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);


    this.ZetEdit = this.ZetEdit.bind(this);
    this.CheckSave = this.CheckSave.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.MainSave = this.MainSave.bind(this)
    this.checkconfirm = this.checkconfirm.bind(this)
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
    this.handleJa = this.handleJa.bind(this)
    this.ActionClick = this.ActionClick.bind(this)

    this.ClosePopTarief = this.ClosePopTarief.bind(this)
    this.getStories = this.getStories.bind(this)
    this.WorkFlowClick = this.WorkFlowClick.bind(this)
    this.ValueWorkFlowCheck = this.ValueWorkFlowCheck.bind(this)
    this.VeldCheck = this.VeldCheck.bind(this)
    this.addVeld = this.addVeld.bind(this)
    this.handleFlowSave = this.handleFlowSave.bind(this)
    this.handleFlowCancel = this.handleFlowCancel.bind(this)
    this.handleFlowDelete = this.handleFlowDelete.bind(this)

    this.handleFlowOmhoog = this.handleFlowOmhoog.bind(this)
    this.handleFlowOmlaag = this.handleFlowOmlaag.bind(this)

    this.SubJobSaveAll = this.SubJobSaveAll.bind(this)
    this.TariefSaveAll = this.TariefSaveAll.bind(this)
    this.DeleteKennis = this.DeleteKennis.bind(this)
    this.ClickKennis = this.ClickKennis.bind(this)
    this.moveChip = this.moveChip.bind(this)
    this.SubActionClick = this.SubActionClick.bind(this)
    this.SaveSubJob = this.SaveSubJob.bind(this)
    this.SaveSubTarief = this.SaveSubTarief.bind(this)

    this.getTaalcodes = this.getTaalcodes.bind(this);
    this.getTaalcodesEdit = this.getTaalcodesEdit.bind(this);
  }

  moveChip(dragIndex: number, hoverIndex: number) {
    var dragFound = dragIndex;
    var hoverFound = hoverIndex;
    const dnd_chips = workflowstorie.velden;
    const dragChip = dnd_chips[dragFound];
    workflowstorie.velden.splice(dragFound,1);
    workflowstorie.velden.splice(hoverFound, 0, dragChip);
    this.WorkFlowClickverder(this.state.changewfindex,true)
  }  

  DeleteKennis(data) {
    let gevonden = null;
    for (let i = 0; i < workflowstorie.velden.length; i++) 
    {
        let veld = workflowstorie.velden[i];
        if (veld.veld_id === data.veld_id)
        {
            gevonden = i;
        }
    }
    if (gevonden !== null)
    {
        workflowstorie.velden.splice(gevonden,1);
        this.WorkFlowClickverder(this.state.changewfindex,true)
    }
  }

  ClickKennis(data) {
    for (let i = 0; i < workflowstorie.velden.length; i++) 
    {
        let veld = workflowstorie.velden[i];
        if (veld.veld_id === data.veld_id)
        {
          if (veld.verplicht === '1' || veld.verplicht === true)
          {
            veld.verplicht = '0';
          }
          else
          {
            veld.verplicht = '1';
          }
          workflowstorie.velden[i] = veld;
        }
    }
    this.WorkFlowClickverder(this.state.changewfindex,true)
  }

  WorkFlowClick(index)
  {
    workflowstorie = this.state.workflowstories[index];
    this.WorkFlowClickverder(index);
  }

  WorkFlowClickverder(index,always)
  {
    if (workflowstorie && (this.state.changewfindex === null || always === true) && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainWorkFlow','edit'))
    {
      workflowstorie.body = ( <Grid container spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                  <EditString className="card-title m-t-10"
                                    value={workflowstorie.title}
                                    coltype = 'STR'                        
                                    labelText="Titel"
                                    ValueCheck={(params,value,type) => this.ValueWorkFlowCheck(params,value,index,'title')}
                                  />
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                  <EditString className="card-title m-t-10"
                                    value={workflowstorie.conditie}
                                    labelText='Conditie'
                                    coltype = 'STR'  
                                    multiline = {true}                      
                                    ValueCheck={(params,value,type) => this.ValueWorkFlowCheck(params,value,index,'conditie')}
                                  />
                                </Grid>

                                <Grid item sm={12} md={12} lg={12}>
                                  <EditString className="card-title m-t-10"
                                    value={workflowstorie.description}
                                    coltype = 'STR'  
                                    multiline = {true}                      
                                    labelText=""
                                    ValueCheck={(params,value,type) => this.ValueWorkFlowCheck(params,value,index,'description')}
                                  />
                                </Grid>
                                <div className='div_line' style={{marginTop: '25px',width:'100%'}}></div>
                                <Grid container spacing={1}>
                                  <Grid item sm={6} md={6} lg={6}>
                                    <EditSelect className="card-title m-t-10"
                                      value={workflowstorie.status_txt}
                                      labelText='Status bij afhandeling'
                                      data={workflowstorie}
                                      type='AllJobStatus'
                                      colId='status_txt'
                                      selectcel='status'
                                      id={workflowstorie.status}
                                      ValueCheck={(params,value,type) => this.ValueWorkFlowCheck(params,value,type,'status_txt')}
                                    />
                                  </Grid>
                                  <Grid item sm={6} md={6} lg={6}>
                                      <div style={{width:'100%',textAlign:'left', marginLeft:'-10px',marginTop: '20px'}}>
                                        <EditCheck className="card-title m-t-10"
                                          value={workflowstorie.bestanden}
                                          labelPlacement="start"
                                          labelText="Slepen documenten toegelaten"
                                          ValueCheck={(params,value,type) => this.ValueWorkFlowCheck(params,value,index,'bestanden')}
                                        />
                                      </div>
                                  </Grid>
                                </Grid>                                
                                <div className='div_line' style={{marginTop: '25px',width:'100%'}}></div>
                                <Grid item sm={12} md={12} lg={12}>
                                    <h4 tag="h4" className="mt-3">Definitie velden</h4>  
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid item sm={6} md={6} lg={6}>
                                    <EditSelect className="card-title m-t-10"
                                      value={this.state.velddata.veld_txt}
                                      data={this.state.velddata}
                                      labelText="Veld"
                                      type='AllVelden'
                                      colId='veld_txt'
                                      selectcel='veld'
                                      id={this.state.velddata.veld}
                                      ValueCheck={(params,value,type) => this.VeldCheck(params,value,index,'veld_txt')}
                                    />
                                  </Grid>
                                  <Grid item sm={4} md={4} lg={4}>
                                      <div style={{width:'100%',textAlign:'left', marginLeft:'-10px',marginTop: '20px'}}>
                                        <EditCheck className="card-title m-t-10"
                                          value={this.state.velddata.verplicht}
                                          labelPlacement="start"
                                          labelText="Verplichte ingave"
                                          ValueCheck={(params,value,type) => this.VeldCheck(params,value,index,'verplicht')}
                                        />
                                      </div>
                                  </Grid>
                                  <Grid item sm={2} md={2} lg={2}>
                                    <div style={{width:'100%',textAlign:'left', marginLeft:'-10px',marginTop: '18px'}}>
                                      <IconButton onClick={() => this.addVeld(index)} color="primary"component="span">
                                          <AddIcon />
                                      </IconButton>                                    
                                    </div>
                                  </Grid>
                                  <div className='div_line' style={{marginBottom: '25px',marginTop: '25px',width:'100%'}}></div>
                                  {workflowstorie && workflowstorie.velden &&
                                  <Grid item sm={12} md={12} lg={12}>
                                    <DndProvider backend={HTML5Backend}>
                                          
                                    {workflowstorie.velden.map( (data,i) => {
                                      let icon;
                                      if (data)
                                      {
                                        if (data.verplicht === '1' || data.verplicht === true) 
                                        {
                                          icon = <Clear />;
                                        }                      
                                        return (
                                          <DndChip
                                            key={GenFunc.MakeId()}
                                            icon={icon}
                                            data={data}
                                            id={GenFunc.MakeId()}
                                            text={data.description}
                                            index={i}                                          
                                            moveCard={this.moveChip}                                          
                                            onClick={() => this.ClickKennis(data)}
                                            onDelete={() => this.DeleteKennis(data)}
                                          />
                                        );
                                      }
                                      else
                                      {
                                        return null;
                                      }
                                    })}    
                                    </DndProvider>                              
                                  </Grid>
                                  }
                                  <div className='div_line' style={{marginBottom: '25px',marginTop: '25px'}}></div>
                                </Grid>
                              </Grid>)
      workflowstorie.footer = (
                  <div style={{textAlign:'center', paddingTop:'25px', width:'100%', display: 'inline-block'}}>
                    <Button style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleFlowSave(index)}>
                     Bewaren
                    </Button>
                    <Button style={{background:'#8898aa',marginLeft:'10px',color: '#fff'}} onClick={() => this.handleFlowDelete(index)}>
                     Verwijderen
                    </Button>
                    <Button style={{background:'#8898aa',marginLeft:'10px',color:'#fff'}} onClick={() => this.handleFlowCancel(index)}>
                     Annuleren
                    </Button>

                  </div>

                )
      let wfstories = this.state.workflowstories;
      wfstories[index] = workflowstorie;
      this.setState({workflowstories:wfstories, changewfindex:index})
    }

  }


  addVeld(index)
  {
    let data = {};
    let arrvelden = workflowstorie.velden;
    let gevonden = null;
    for (let i = 0; i < workflowstorie.velden.length; i++) 
    {
        let veld = workflowstorie.velden[i];
        if (veld.veld_id === this.state.velddata.veld)
        {
            gevonden = i;
        }
    }
    if (gevonden === null)
    {
      data.id= 0;
      data.workflow_id=workflowstorie.id;
      data.veld_id = this.state.velddata.veld;
      data.verplicht = this.state.velddata.verplicht;
      data.description = this.state.velddata.veld_txt;
      arrvelden.push(data);
      workflowstorie.velden = arrvelden;
      this.WorkFlowClickverder(index,true)
    }
  }

  async handleFlowSave(index) {
    let wfdata = this.state.workflowstories[index];
    let data = 
    {id: wfdata.id, jobid: wfdata.jobid, volgorde: wfdata.volgorde, title: wfdata.title,velden: wfdata.velden, status: wfdata.status,
      status_txt:wfdata.status_txt, bestanden: wfdata.bestanden,conditie: wfdata.conditie, description: wfdata.description, titleColor: wfdata.titleColor
    }
    await SqlFunction.DoSql('ChgJobdefWorkflow',data);
    this.getStories()
  }       

  async handleFlowOmhoog(volgorde) {
    let index = volgorde - 1;
    if (index > 0)
    {
      let vwfdata = this.state.workflowstories[index - 1];
      let hwfdata = this.state.workflowstories[index];
      let wfdata = this.state.workflowstories[index - 1];

      let data = 
      {id: wfdata.id, jobid: wfdata.jobid, volgorde: hwfdata.volgorde, title: wfdata.title,velden: wfdata.velden, status: wfdata.status,
        status_txt:wfdata.status_txt,conditie: wfdata.conditie, bestanden: wfdata.bestanden, description: wfdata.description, titleColor: wfdata.titleColor
      }
      await SqlFunction.DoSql('ChgJobdefWorkflow',data);
      wfdata = this.state.workflowstories[index];
      data = 
      {id: wfdata.id, jobid: wfdata.jobid, volgorde: vwfdata.volgorde, title: wfdata.title,velden: wfdata.velden, status: wfdata.status,
        status_txt:wfdata.status_txt, conditie: wfdata.conditie, bestanden: wfdata.bestanden, description: wfdata.description, titleColor: wfdata.titleColor
      }
      await SqlFunction.DoSql('ChgJobdefWorkflow',data);
    }
    this.getStories()
  }       

  async handleFlowOmlaag(volgorde) {
    let index = volgorde - 1;
    if (index + 1 < this.state.workflowstories.length)
    {
      let vwfdata = this.state.workflowstories[index + 1];
      let hwfdata = this.state.workflowstories[index];
      let wfdata = this.state.workflowstories[index + 1];
      let data = 
      {id: wfdata.id, jobid: wfdata.jobid, volgorde: hwfdata.volgorde, title: wfdata.title,velden: wfdata.velden, status: wfdata.status,
        status_txt:wfdata.status_txt, bestanden: wfdata.bestanden, conditie: wfdata.conditie, description: wfdata.description, titleColor: wfdata.titleColor
      }
      await SqlFunction.DoSql('ChgJobdefWorkflow',data);
      wfdata = this.state.workflowstories[index];
      data = 
      {id: wfdata.id, jobid: wfdata.jobid, volgorde: vwfdata.volgorde, title: wfdata.title,velden: wfdata.velden, status: wfdata.status,
        status_txt:wfdata.status_txt, bestanden: wfdata.bestanden, conditie: wfdata.conditie, description: wfdata.description, titleColor: wfdata.titleColor
      }
      await SqlFunction.DoSql('ChgJobdefWorkflow',data);
      this.getStories()
    }
  }       


  async handleFlowDelete(index) {
    let wfdata = this.state.workflowstories[index];
    let data = 
    {id: wfdata.id, jobid: wfdata.jobid, volgorde: wfdata.volgorde, title: wfdata.title,velden: wfdata.velden, status: wfdata.status,
      status_txt:wfdata.status_txt, bestanden: wfdata.bestanden, description: wfdata.description, conditie: wfdata.conditie, titleColor: wfdata.titleColor
    }
    await SqlFunction.DoSql('DelJobdefWorkflow',data);
    this.getStories()
  }       


  async handleFlowCancel(index) {
    this.getStories()
  }       


    
  async ValueWorkFlowCheck(that,waarde,index,colId) {
    let totdata = this.state.workflowstories;
    if (colId === 'status_txt')
    {
      workflowstorie.status_txt = waarde.text;
      workflowstorie.status = waarde.id;
    }
    else
    {
      workflowstorie[colId] = waarde;
    }
    totdata[index] = workflowstorie;
  }       

  async VeldCheck(that,waarde,index,colId) {
    let data = this.state.velddata;
    switch (colId) 
    {
      case 'veld_txt':
        data.veld_txt = waarde.text;
        data.veld = waarde.id;
        break;

      default:    
        data[colId] = waarde;
        break;
    } 
  }       


  async getStories() {
      await this.props.dispatch(GetJobDefWorkflow(this.props.jobs.JobDefMainData.data.id));
      var stories = [];
      var teller = 0;
      let storiedata = {}
      let inverted = false
      if (this.props.jobs.JobDefWorkflowData && this.props.jobs.JobDefWorkflowData.data)
      {
        for (teller = 0; teller < this.props.jobs.JobDefWorkflowData.data.length; teller++) 
        {
          let data = this.props.jobs.JobDefWorkflowData.data[teller];
          storiedata = 
          {
            badgeColor: 'primary',
            inverted:inverted,
            id:data.id,
            jobid:data.jobid,
            badgeIcon: data.volgorde,
            index:teller,
            volgorde:data.volgorde,
            title:data.title,
            velden:data.velden,
            status:data.status,
            status_txt:data.status_txt,
            bestanden:data.bestanden,
            conditie:data.conditie,
            description:data.description,
            badgeClick: this.WorkFlowClick,
            titleColor: "primary",
            body: (
              <div>
                <Grid container>
                  <Grid item xs={8}>          
                    <h4 className="card-title m-t-10">{data.title} </h4>
                  </Grid>
                  <Grid item xs={2}>  
                    <IconButton style={{top:'-15px'}} onClick={() => this.handleFlowOmhoog(data.volgorde)} color="primary"component="span">
                      <ArrowUpwardRounded />
                    </IconButton>                    
                  </Grid>
                  <Grid item xs={2}>  
                    <IconButton style={{top:'-15px'}} onClick={() =>  this.handleFlowOmlaag(data.volgorde)} color="primary"component="span">
                      <ArrowDownwardRounded />
                    </IconButton>                                    
                  </Grid>

                </Grid>

                <div>
                    <small className="text-muted p-t-30 db">Conditie</small>
                    <h6>{data.conditie}</h6>
                </div>

                <h6 style={{marginTop: '25px'}}>{data.description}</h6> 

                <div className='div_line' style={{marginTop: '25px',width:'100%'}}></div>
                <Grid container spacing={1}>
                    <Grid item sm={6} md={6} lg={6} style = {{marginTop:'17px'}}>
                      <small className="text-muted p-t-30 db">Status na afhandeling</small>
                        <h6>{data.status_txt}</h6>
                    </Grid>                
                    <Grid item sm={6} md={6} lg={6}>
                        <small className="text-muted p-t-30 db">Slepen documenten toegelaten: 
                          {(data.bestanden === true || data.bestanden === '1') &&
                            <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" 
                              control={<Checkbox checked={true} />} />
                          }
                          {(data.bestanden === false || data.bestanden === '0') &&
                            <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" 
                              control={<Checkbox checked={false} />} />
                          }

                        </small>              
                    </Grid>
                </Grid>

                <div className='div_line' style={{marginTop: '25px',width:'100%'}}></div>
                <Grid container spacing={1}>
                  <Grid item sm={12} md={12} lg={12}>
                    <h4 tag="h4" className="mt-3">Definitie velden</h4>  
                  </Grid>
                  <Grid item sm={12} md={12} lg={12}>
                    {data.velden.map(datavld => {
                      let icon;
                      if (datavld.verplicht === '1' || datavld.verplicht === true) 
                      {
                        icon = <Clear />;
                      }                      
                      return (
                        <Chip
                          style={{marginTop:'5px',marginRight:'5px'}}
                          variant="outlined"
                          color="primary"
                          size="small"
                          key={GenFunc.MakeId()}
                          icon={icon}
                          label={datavld.description}
                        />
                      );
                    })}                                  
                  </Grid>
                </Grid>
              </div>
            ),
            footer: (
              <div>
              </div>
            )
          }
          stories.push(storiedata);
          inverted = !inverted;
        }
      }

      storiedata = {
            id:0,
            badgeColor: 'primary',
            jobid:this.props.jobs.JobDefMainData.data.id,
            badgeIcon: teller + 1,
            volgorde: teller + 1,
            index:teller,
            inverted:inverted,
            status:'',
            status_txt:'',
            conditie: '',
            bestanden:'0',
            title: "?",
            description:'',
            velden:[],
            badgeClick: this.WorkFlowClick,
            titleColor: "primary",
            body: (
              <div>
              </div>
            ),
            footer: (
              <div>
              </div>
            )
          }
      stories.push(storiedata);
      this.setState({workflowstories:stories,changewfindex:null})   
  }  

  async handleTabChange(event, newValue)  {
//    this.SaveSubJob();
//    this.SaveSubTarief();
    if (newValue === '1')
    {
      await this.props.dispatch(GetsubJobMain(this.props.jobs.JobDefMainData.data.id))
      await this.props.dispatch(ZetListData('SubJobMain',this.props.jobs.JobDefMainData.data.id));
      await this.props.dispatch(zetListMain('SubJobMain',this.props.jobs.JobDefMainData.data.id));
      let subspeedactions = [
        { icon: <SubJobNew />, name: 'NewSubjob',  tooltip:'Nieuwe subjob maken' },
        { icon: <SaveIcon />, name: 'SaveSubjob', tooltip:'Bewaren gegevens subjob'}
      ]
      this.setState({subspeedactions:subspeedactions})
    }
    if (newValue === '2')
    {
      this.getStories()
    }    
    if (newValue === '3')
    {
      await this.props.dispatch(GetsubJobTarief(this.props.jobs.JobDefMainData.data.id))
      await this.props.dispatch(ZetListData('SubJobTarief',this.props.jobs.JobDefMainData.data.id));
      await this.props.dispatch(zetListMain('SubJobTarief',this.props.jobs.JobDefMainData.data.id));
      let subspeedactions = [
        GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','edit') ? { icon: <CashPlus />, name: 'Tarieven', tooltip:'Nieuwe Tarieven'  }:null,
        GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','edit') ? { icon: <SaveIcon />, name: 'SaveSubtarief', tooltip:'Bewaren aangepaste tarieven'}:null,
      ]
      this.setState({subspeedactions:subspeedactions})
    }
    this.setState({tabvalue:newValue});
  };

  handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:true });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
  };

  handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
  };

  handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
  };


  async ClosePopTarief() {
    await this.props.dispatch(GetsubJobTarief(this.props.jobs.JobDefMainData.data.id))
    await this.props.dispatch(ZetListData('SubJobTarief'));
    await this.props.dispatch(zetListMain('SubJobTarief'));
    this.setState({PopUpScreen:null})

  }
  async SubActionClick(name) {
    switch (name) {
      case 'Tarieven':
        let popdata ={data:{id:0,jobid:this.props.jobs.JobDefMainData.data.id, jaar:'', tarief:0, mainid:this.props.jobs.JobDefMainData.data.mainid, nihil:0,mintar:0,country:'',country_txt:0, datum:'', type:'', typetxt:''}}
        this.setState({PopUpScreen:(<PopJobTarieven open={true} data={popdata} close={this.ClosePopTarief} title='Definitie Job Tarieven' />)});
        break;

      case 'SaveSubtarief':
        this.SaveSubTarief();
        break


      case 'NewSubjob':
        this.setState({pinnedSubJobData:[{id:0,mainid:'',country:'',periode:'',code:'',description:'',boekhoud:'',country_txt:'',periode_txt:'',maincode:'',maindescription:'',subjectcode:'',country_code:'',periode_code:'',save:'',tefact:this.props.jobs.JobDefMainData.data.tefact}]})
        break

      case 'SaveSubjob':
        this.SaveSubJob();
        break

      default:
        break;
    }
    this.setState({ speedopen: false, subspeedopen:false });
  }


  async ActionClick(name)
  {
    switch (name) 
    {
      case 'Edit':
        this.setState({edit:true,data:this.props.jobs.JobDefMainData.data,speedopen:false})
        break;

      case 'New':
        await this.props.dispatch(CreateJobMain())
        this.setState({edit:true,data:this.props.jobs.JobDefMainData.data,speedopen:false})
        break;

      case 'Save':
        this.MainSave();
        break;

      default:    
        break;
    }     
    this.setState({ speedopen: false, subspeedopen:false });
  }

  ZetEdit()
  {
     this.setState({edit:true, data:this.props.jobs.JobDefMainData.data, tabvalue: '4'})
  }


  componentDidMount(){
    window.addEventListener("resize", this.resizeFunction);
    this.props.dispatch(setTitleRight('Job definities'))
    this.props.dispatch(setSubTitle('Job definitie'))
    this.props.dispatch(setPanelRight(true))
    let prevtab = this.state.tabvalue
    this.handleTabChange(null,prevtab)

    if (this.props.jobs.JobDefMainData)
    {
      if (this.props.jobs.JobDefMainData.data && this.props.jobs.JobDefMainData.data.id === 0)
      {
        this.setState({edit:true,save:false,subsave:false,data:this.props.jobs.JobDefMainData.data})
      }
    }

  }

  componentWillUnmount() 
  {
    this.checkconfirm(this.props.jobs.JobDefMainData);    
    this.props.dispatch(setPanelRight(false))
    this.props.dispatch(setTitleRight(''))
    window.removeEventListener("resize", this.resizeFunction);
  }

  async componentWillReceiveProps(nextProps, nextState) 
  {
    if (this.props.jobs.JobDefMainData)
    {
      if (this.props.jobs.JobDefMainData.data.id !== nextProps.jobs.JobDefMainData.data.id)
      {
        await this.checkconfirm(this.props.jobs.JobDefMainData);
        if (nextProps.jobs.JobDefMainData.data.id === 0)
        {
          this.setState({edit:true,save:false,subsave:false})
          this.handleTabChange(null,'1')

        }
        else
        {
          await this.props.dispatch(GetsubJobMain(nextProps.jobs.JobDefMainData.data.id))
          await this.props.dispatch(ZetListData('SubJobMain'));
          await this.props.dispatch(zetListMain('SubJobMain'));
          this.setState({edit:false,save:false,subsave:false})
          let prevtab = this.state.tabvalue
          this.handleTabChange(null,prevtab)

        }
      }
    }
  }


  async handleJa(parameters) {
    if (this.state.save === true) {
      await SqlFunction.DoSql('ChgJobDefMain', this.state.data);
    }
    if (this.state.subsave === true) {
    }
    // this.setState({edit:false, save:false,subsave:false})
  }


  checkconfirm (parameters) 
  {

    if (this.state.save === true || this.state.subsave === true)
    {
      confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,        
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Aanpassingen niet doorgevoerd.</h1>
            <p>Wens je de aanpassingen alsnog te bewaren?</p>
            <button
              onClick={() => {
                this.handleJa(parameters);
                onClose();
              }}
            >
              Ja
            </button>
            <button onClick={() => {
                this.setState({edit:false, save:false,subsave:false,checkval:false})
                onClose();
              }}
            >
              Nee
            </button>
          </div>
        );
      }
      });
    }

  }

  resizeFunction() 
  {
    if (window.innerHeight >= 960) 
    {
      this.setState({tableheight: Math.floor(window.innerHeight * .70) + 'px'});
    }
    else
    {
      this.setState({tableheight: '600px'});
    }
  }

  onGridReady(params)
  {
    gridapi = params;
  }

  async MainSave() {
    if (this.state.save === true) {
      let retdata = await SqlFunction.DoSql('ChgJobDefMain', this.state.data);

      this.setState({ edit: false, save: false, data: null, checkval: false })
      if (retdata) {
        await this.props.dispatch(GetJobMain(retdata.id))
      }
    }
  }

  getContextMenuItems(params,sleutel) 
  {
    return null;
  }

  CheckSave(data)
  {
    let taalcodes = this.props.land.AllTaalcodesData.data;
    let terug = true
    terug = Validation.ValidCheck(this.props.settings.security_title,data.subject,'subject',this,null,data) ;
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.security_title,data.code,'code',this,null,data) ;
    }
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.security_title,data.description,'description',this,null,data) ;
    }
    if (terug === false) {
      for (let i = 0; i < taalcodes.length; i++) {
        if (terug === false) {
          terug = Validation.ValidCheck(this.props.settings.security_title, data[(taalcodes[i].omschrijving).toLowerCase()], (taalcodes[i].omschrijving).toLowerCase(), this, null, data, taalcodes);
        }
      }
    }
    return !terug;
  }

  SecValueCheck(params,waarde) {
    if (GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit'))
    {
      params.props.data.save = true;
      this.setState({subsave:true})
    }
  }       

  async SaveSubJob()
  {
    if (gridapi && gridapi.api && gridapi.api.gridCore && gridapi.api.gridCore.rowRenderer &&
      gridapi.api.gridCore.rowRenderer.pinnedRowModel &&
      gridapi.api.gridCore.rowRenderer.pinnedRowModel.pinnedTopRows[0] &&
      gridapi.api.gridCore.rowRenderer.pinnedRowModel.pinnedTopRows[0].data)
    {
        let pindata = gridapi.api.gridCore.rowRenderer.pinnedRowModel.pinnedTopRows[0].data;
        if (pindata.save === true)
        {
          pindata.mainid = this.props.jobs.JobDefMainData.data.id;
          await SqlFunction.DoSql('ChgSubJobMain',pindata);
        }
    }
    if (this.props.jobs.SubJobMainData)
    {
      if (Array.isArray(this.props.jobs.SubJobMainData.data) && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit'))
      {
        var i = 0;
        for (i = 0; i < this.props.jobs.SubJobMainData.data.length; i++) 
        {
          let data = this.props.jobs.SubJobMainData.data[i];
          await SqlFunction.DoSql('ChgSubJobMain',data);
        }
      }
    }
    this.setState({pinnedSubJobData:null,subjobsave:false,subsave:false})
    if (this.state.tabvalue === '1')
    {
      await this.props.dispatch(GetsubJobMain(this.props.jobs.JobDefMainData.data.id))
      await this.props.dispatch(ZetListData('SubJobMain',this.props.jobs.JobDefMainData.data.id));
      await this.props.dispatch(zetListMain('SubJobMain',this.props.jobs.JobDefMainData.data.id));
    }
  }                      

  async SaveSubTarief()
  {
    if (this.props.jobs.SubJobMainData)
    {
      if (Array.isArray(this.props.jobs.SubJobMainData.data) && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','edit'))
      {
        if (this.props.jobs.SubJobTariefData && this.props.jobs.SubJobTariefData.data)
        {
          var i = 0;
          for (i = 0; i < this.props.jobs.SubJobTariefData.data.length; i++) 
          {
            let data = this.props.jobs.SubJobTariefData.data[i];
            await SqlFunction.DoSql('ChgSubJobTarief',data);
          }
        }
      }
    }
    this.setState({pinnedSubJobData:null,subtariefsave:false,subsave:false})
    if (this.state.tabvalue === '3')
    {
      await this.props.dispatch(GetsubJobTarief(this.props.jobs.JobDefMainData.data.id))
      await this.props.dispatch(ZetListData('SubJobTarief',this.props.jobs.JobDefMainData.data.id));
      await this.props.dispatch(zetListMain('SubJobTarief',this.props.jobs.JobDefMainData.data.id));
    }
  }                      


  async SubJobMainCellClick(waarde) {
    var data = waarde.data
    let column = waarde.column.colDef.field;
    let retdata = null;
    if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit'))
    {
      if (data.save === true) 
      {
        data.mainid = this.props.jobs.JobDefMainData.data.id;
        retdata = await SqlFunction.DoSql('ChgSubJobMain',data);
        waarde.data.save='';
        if (data.id === 0) {
          waarde.data.id = retdata.id;
          gridapi.api.stopEditing();
          gridapi.api.updateRowData({
            add: [waarde.data],
            addIndex: 0
          });
          waarde.node.setData(GenFunc.zetdataleeg('SubJobMain'));
        }
        else {
          waarde.node.setData(waarde.data);
        }
        this.setState({subsave:false})
      }
    }
    if (column === 'verwijder' && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit'))
    {
      retdata = await SqlFunction.DoSql('DelsubJobMain',data);
      await this.props.dispatch(GetsubJobMain(this.props.jobs.JobDefMainData.data.id))
      await this.props.dispatch(ZetListData('SubJobMain',this.props.jobs.JobDefMainData.data.id));
      await this.props.dispatch(zetListMain('SubJobMain',this.props.jobs.JobDefMainData.data.id));

    }

    if (column === 'copieer' && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit'))
    {
      retdata = await SqlFunction.DoSql('CopysubJobMain',data);
      await this.props.dispatch(GetsubJobMain(this.props.jobs.JobDefMainData.data.id))
      await this.props.dispatch(ZetListData('SubJobMain',this.props.jobs.JobDefMainData.data.id));
      await this.props.dispatch(zetListMain('SubJobMain',this.props.jobs.JobDefMainData.data.id));

    }



    return;
  }


  async SubJobSaveAll(items)
  {
    if (Array.isArray(items) && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit'))
    {
      var i = 0;
      for (i = 0; i < items.length; i++) 
      {
        let data = items[i];
        await SqlFunction.DoSql('ChgSubJobMain',data);
      }
    }
  }                      

  SubJobMainChanged(params,waarde,that) {
  }                        

  SubJobMainCheck(params,waarde) {
    var colId = params.props.column.getId();
    params.props.data.save = true;
    let error = false;
    let terug = Validation.ValidCheck('SubJobMain',waarde,colId,params,gridapi) ;
    let chgtit = false;
    if (colId === 'country_txt')
    {
      if (params.state && params.state.data)
      {
        let result = params.state.data.filter(o=> o.id === params.props.data.country);
        if (Array.isArray(result))
        {
          if (result.length > 0)
          {
            if (params.props.data.country_code !== result[0].code)
            {

               chgtit = true;
            }
            params.props.data.country_code = result[0].code;
          }
        }
      }

    }
    if (colId === 'periode')
    {
      if (waarde && waarde.code)
      {
        if (params.props.data.periode_code !== waarde.code)
        {
          if (waarde.code && waarde.code.length > 0)
          {
            chgtit = true;
          }
          params.props.data.per1 = '';
          params.props.data.per2 = '';
          params.props.data.per3 = '';
          params.props.data.per4 = '';
          params.props.data.per5 = '';
          params.props.data.per6 = '';
          params.props.data.per7 = '';
          params.props.data.per8 = '';
          params.props.data.per9 = '';
          params.props.data.per10 = '';
          params.props.data.per11 = '';
          params.props.data.per12 = '';

        }

        params.props.data.periode_code = waarde.code;
      }
    }

    if (colId === 'per1' || colId === 'per2'|| colId === 'per3'|| colId === 'per4'|| colId === 'per5'|| colId === 'per6'|| colId === 'per7'|| colId === 'per8'|| colId === 'per9'|| colId === 'per10'|| colId === 'per11'|| colId === 'per12')
    {
        params.props.data[colId] = waarde;
        let jaar = GenFunc.Get_Jaar();
        let datum = waarde+'-'+jaar;
        var utc = moment(datum, "DD-MM-YYYY", true)
        if(!utc.isValid()) {terug = true}
    }

    if (chgtit === true)
    {
      params.props.data.code = this.props.jobs.JobDefMainData.data.subjectcode+'_'+this.props.jobs.JobDefMainData.data.code+'_'+params.props.data.country_code+'_'+params.props.data.periode_code
      params.props.data.description = this.props.jobs.JobDefMainData.data.subjectcode+' '+this.props.jobs.JobDefMainData.data.description+' '+params.props.data.country_code+' '+params.props.data.periode_txt
    }
    if (params.props.data && params.props.data.country_txt && params.props.data.country_txt.length > 2)
    {
      if (error === false)
      {
        params.props.data.save = true; 
      }
      else
      {
        params.props.data.save = ''; 
      }
    }
    else
    {
      params.props.data.save = ''; 
    }
    params.setState({error:terug})
    this.setState({subjobsave:params.props.data.save})
  }    

  async SubJobTariefCellClick(waarde) {
    let column = waarde.column.colDef.field;
    var data = waarde.data

    if (column === 'copieer')
    {
      data = await SqlFunction.DoSql('CopyJobTarief',data);
    }

    if (column === 'verwijder')
    {
      data = await SqlFunction.DoSql('DelJobTarief',data);
      await this.props.dispatch(GetsubJobTarief(this.props.jobs.JobDefMainData.data.id))
      await this.props.dispatch(ZetListData('SubJobTarief',this.props.jobs.JobDefMainData.data.id));
      await this.props.dispatch(zetListMain('SubJobTarief',this.props.jobs.JobDefMainData.data.id));
      return;

    }



    if (data && data.id)
    {
      let popdata = await SqlFunction.DoSql('GetJobTarief',data);
      this.setState({PopUpScreen:(<PopJobTarieven open={true} data={popdata} close={this.ClosePopTarief} title='Definitie Job Tarieven' />)});
    }
    return;
  }

  async TariefSaveAll(items)
  {
    if (Array.isArray(items) && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','edit'))
    {
      var i = 0;
      for (i = 0; i < items.length; i++) 
      {
        let data = items[i];
        await SqlFunction.DoSql('ChgSubJobTarief',data);
      }
    }
  }  

  SubJobTariefChanged(params,waarde,that) {
  }                        

  SubJobTariefCheck(params,waarde) {
    var colId = params.props.column.getId();
    if (GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','edit'))
    {    
      params.props.data.save = true;
      params.props.data[colId] = waarde;
      this.setState({subtariefsave:true})
    }
  }       

  async ValueCheck(that,waarde,type,colId) {

    let data = this.state.data;
    let terug = false;
    let taalcodes = this.props.land.AllTaalcodesData.data;

    switch (colId) 
    {
      case 'subject_txt':
        data.subject_txt = waarde.text;
        data.subject = waarde.id;
        data.subjectcode = waarde.code;
        terug = Validation.ValidCheck(this.props.settings.security_title,waarde.text,colId,that,null,data) ;
        break;




      default:    
        data[colId] = waarde;
        terug = Validation.ValidCheck(this.props.settings.security_title,waarde,colId,that,null,data,taalcodes) ;
        break;
    } 
    that.setState({error:terug})
    terug = await this.CheckSave(data);

    this.setState({save:terug,data:data,checkval:false})
  }       

  getTaalcodes() {
    let taalcodes = [];
    let taalcodesleft = [];
    let taalcodesright = [];

    if (this.props.land.AllTaalcodesData.data.length % 2 === 0) {
      for (let i = 0; i < this.props.land.AllTaalcodesData.data.length; i++) {
        if (i < this.props.land.AllTaalcodesData.data.length / 2) {
          taalcodesleft.push(
            <div key={"eventaalcodesleftdiv_" + i}>
              <small className="text-muted p-t-30 db">{this.props.land.AllTaalcodesData.data[i].text}</small>
              <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.jobs.JobDefMainData.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}</h6>
            </div>
          );
        } else {
          taalcodesright.push(
            <div key={"eventaalcodesrightdiv_" + i}>
              <small className="text-muted p-t-30 db">{this.props.land.AllTaalcodesData.data[i].text}</small>
              <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.jobs.JobDefMainData.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}</h6>
            </div>
          );
        }
      }
    } else {
      for (let i = 0; i < this.props.land.AllTaalcodesData.data.length; i++) {
        if (i <= this.props.land.AllTaalcodesData.data.length / 2) {
          taalcodesleft.push(
            <div key={"oneventaalcodesleftdiv_" + i}>
              <small className="text-muted p-t-30 db">{this.props.land.AllTaalcodesData.data[i].text}</small>
              <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.jobs.JobDefMainData.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}</h6>
            </div>
          );
        } else {
          taalcodesright.push(
            <div key={"oneventaalcodesrightdiv_" + i}>
              <small className="text-muted p-t-30 db">{this.props.land.AllTaalcodesData.data[i].text}</small>
              <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.jobs.JobDefMainData.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}</h6>
            </div>
          );
        }
      }
    }

    taalcodes.push(
      <div style={{ marginTop: "25px" }} key={"taalcodesdiv"}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "35%" }}>
            {taalcodesleft}
          </div>
          <div style={{ width: "15%" }}></div>
          <div style={{ width: "35%" }}>
            {taalcodesright}
          </div>
        </div>
      </div>
    );

    return taalcodes;
  }

  getTaalcodesEdit() {
    let taalcodes = [];
    let taalcodesleft = [];
    let taalcodesright = [];

    if (this.props.land.AllTaalcodesData.data.length % 2 === 0) {
      for (let i = 0; i < this.props.land.AllTaalcodesData.data.length; i++) {
        if (i < this.props.land.AllTaalcodesData.data.length / 2) {
          taalcodesleft.push(
            <EditString className="card-title m-t-10"
              value={this.state.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}
              coltype='STR'
              labelText={this.props.land.AllTaalcodesData.data[i].text}
              ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, (this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase())}
            />
          );
        } else {
          taalcodesright.push(
            <EditString className="card-title m-t-10"
              value={this.state.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}
              coltype='STR'
              labelText={this.props.land.AllTaalcodesData.data[i].text}
              ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, (this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase())}
            />
          );
        }
      }
    } else {
      for (let i = 0; i < this.props.land.AllTaalcodesData.data.length; i++) {
        if (i <= this.props.land.AllTaalcodesData.data.length / 2) {
          taalcodesleft.push(
            <EditString className="card-title m-t-10"
              value={this.state.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}
              coltype='STR'
              labelText={this.props.land.AllTaalcodesData.data[i].text}
              ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, (this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase())}
            />
          );
        } else {
          taalcodesright.push(
            <EditString className="card-title m-t-10"
              value={this.state.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}
              coltype='STR'
              labelText={this.props.land.AllTaalcodesData.data[i].text}
              ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, (this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase())}
            />
          );
        }
      }
    }

    taalcodes.push(
      <div style={{ marginTop: "25px" }} key={"taalcodesdiv"}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ width: "35%" }}>
            {taalcodesleft}
          </div>
          <div style={{ width: "15%" }}></div>
          <div style={{ width: "35%" }}>
            {taalcodesright}
          </div>
        </div>
      </div>
    );

    return taalcodes;
  }

  render() {
    const { classes } = this.props;
    var {tableheight} = this.state;
    return (
      <div >
        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
          <Grid container>
            <Grid item xs={8}>          
              <h4 style={{cursor:'pointer'}} tag="h4" className="mt-3" onClick={() => {this.setState({edit:false})}}>{this.props.settings.sub_title}</h4>  
            </Grid>
            <Grid item xs={4}>  
            </Grid>
          </Grid>
          {this.state.pinnedSubJobData === null &&         
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              (action && action.name  && action.name.length > 2 &&
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipPlacement='bottom'
                  FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit')) ? false :
                                       (action.name === 'Edit' && this.state.edit === true  ) ? true :
                                       (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit')) ? true :
                                       (action.name === 'New' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit')) ? false :
                                       (action.name === 'New' && this.state.edit === true ) ? true :
                                       (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit')) ? true :
                                       (action.name === 'Tarieven' && this.state.edit === false  && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','edit')) ? false :
                                       (action.name === 'Tarieven' && this.state.edit === true ) ? true :
                                       (action.name === 'Tarieven' && !GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','edit')) ? true :
                                       (action.name === 'Save' && this.state.edit === true && this.state.save === true) ? false :
                                       (action.name === 'Save' && this.state.edit === true  && this.state.save === false) ? true :
                                       (action.name === 'Save' && this.state.edit === false ) ? true : 
                                       (action.name === 'Save' && this.state.save === false) ? true : false}}
                  onClick={() => {this.ActionClick(action.name)}}                             
                  tooltipTitle={action.tooltip}
                />
              )
            ))}
          </SpeedDial>  
          }        
          { (this.state.tabvalue === '3' || this.state.tabvalue === '1') && this.state.subspeedzien &&
            <SpeedDial
              ariaLabel="SpeedDial example"
              icon={<SubSpeedDialIcon style={{zIndex:'10'}}/>}
              direction="left"
              classes={{root: classes.subspeedDialToolb, fab: classes.subspeedFabRoot}}           
              onClose={this.handleSubSpeedClose}
              onOpen={this.handleSubSpeedOpen}
              open={this.state.subspeedopen}          
            >
            {this.state.subspeedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                FabProps={{disabled: 
                  (action.name === 'Tarieven' && this.state.subtariefsave === false ) ? false :
                  (action.name === 'SaveSubtarief' && this.state.subtariefsave === true ) ? false :                  
                  (action.name === 'NewSubjob' && this.state.pinnedSubJobData === null) ? false :
                  (action.name === 'NewSubjob' && this.state.pinnedSubJobData !== null) ? true :
                  (action.name === 'SaveSubjob' && this.state.subjobsave === true ) ? false : true}}
                onClick={() => {this.SubActionClick(action.name)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
            </SpeedDial>     
          }     



        </Toolbar>
        {this.props.jobs && this.props.jobs.JobDefMainData && this.props.jobs.JobDefMainData.data &&
          <div>
            <Grid container style={{width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px'}}>
              <Grid container spacing={1}>
                <Grid item xs={this.state.edit === false ? 3 : 5}>
                  <Card className='card_brut'>
                    {this.state.edit === false &&
                      <PerfectScrollbar className="sidebar-nav">
                        <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                          <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} className="card-title m-t-10">{this.props.jobs.JobDefMainData.data.subject_txt} </h4>
                          <h5 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.jobs.JobDefMainData.data.description}</h5> 
                          <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.jobs.JobDefMainData.data.code}</h6> 
                          <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.jobs.JobDefMainData.data.boekhoud}</h6> 
                          {(this.props.jobs.JobDefMainData.data.tefact === '1' || this.props.jobs.JobDefMainData.data.tefact === true) &&
                              <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >TE FACTUREREN</h6> 
                          }
                          {(this.props.jobs.JobDefMainData.data.tefact === '0' || this.props.jobs.JobDefMainData.data.tefact === false) &&
                              <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >NIET TE FACTUREREN</h6> 
                          }
                          <div className='div_line' style={{marginBottom: '25px'}}></div>
                        </CardBody>
                      </PerfectScrollbar>
                    }
                    {this.state.edit === true &&
                      <PerfectScrollbar styleclassName="sidebar-nav">
                        <CardBody style={{textAlign:'left',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                            <Grid container spacing={1}>
                              <Grid item sm={12} md={12} lg={12}>
                                <EditSelect className="card-title m-t-10"
                                  value={this.state.data.subject_txt}
                                  data={this.state.data}
                                  labelText="Subject"
                                  type='AllJobSubj'
                                  colId='subject_txt'
                                  selectcel='subject'
                                  id={this.state.data.subject}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'subject_txt')}
                                />
                              </Grid>
                              <Grid item sm={12} md={12} lg={12}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.description}
                                  coltype = 'STR'                        
                                  labelText="Jobomschrijving"
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'description')}
                                />
                              </Grid>
                              <Grid item sm={12} md={12} lg={12}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.code}
                                  coltype = 'STR'                        
                                  labelText="Code"
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'code')}
                                />
                              </Grid>
                              <Grid item sm={12} md={12} lg={12}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.boekhoud}
                                  coltype = 'STR'                        
                                  labelText="Boekhouding"
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'boekhoud')}
                                />
                              </Grid>

                              <Grid item sm={12} md={12} lg={12}>
                                <div style={{width:'100%',textAlign:'left', marginLeft:'-10px',marginTop: '20px'}}>
                                  <EditCheck className="card-title m-t-10"
                                    value={this.state.data.tefact}
                                    labelPlacement="start"
                                    labelText="Te factureren"
                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'tefact')}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                              <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                            </Grid>
                        </CardBody>
                      </PerfectScrollbar>
                    }
                  </Card>
                </Grid>
                <Grid item xs={this.state.edit === false ? 9 : 7}>
                  <Card className='card_brut'>
                    <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                      <div className='div_line'>
                        <Nav tabs >
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.tabvalue === '1' })}
                              onClick={(event) => { this.handleTabChange(event,'1'); }}
                              style={{ cursor: 'default' }}
                            >
                              Aanverwanten
                            </NavLink>
                          </NavItem>
                          {GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainWorkFlow','zien') &&
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.tabvalue === '2' })}
                              onClick={(event) => { this.handleTabChange(event,'2'); }}
                              style={{ cursor: 'default' }}
                            >
                              Workflows
                            </NavLink>
                          </NavItem>
                          }
                          {GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','zien') &&
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.tabvalue === '3' })}
                              onClick={(event) => { this.handleTabChange(event,'3'); }}
                              style={{ cursor: 'default' }}
                            >
                              Tarieven
                            </NavLink>
                          </NavItem>}
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.tabvalue === '4' })}
                              onClick={(event) => { this.handleTabChange(event,'4'); }}
                              style={{ cursor: 'default' }}
                            >
                              Taalcodes
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.tabvalue}>
                          <TabPane tabId="1">

                            { this.state.tabvalue === '1' && this.props.jobs.SubJobMainData && this.props.jobs.SubJobMainData.data && this.props.jobs.SubJobMainData.kol &&
                              this.props.settings.listmaindata && this.props.settings.listmaindata.mainkey && 
                              this.props.jobs && this.props.jobs.JobDefMainData && this.props.jobs.JobDefMainData.data && this.props.jobs.JobDefMainData.data.id !== 0 &&
                              <TableGrid
                                tableheight={tableheight} 
                                columns={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['kol']}
                                data={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['data']} 
                                CellValueChanged={(params,waarde,object) => this.SubJobMainChanged(params,waarde,object)}
                                CellValueCheck={(params,value,error) => this.SubJobMainCheck(params,value,error)}
                                CellClickedEvent={(column, colDef, value) => this.SubJobMainCellClick(column,colDef, value)}
                                SaveAll={(params) => this.SubJobSaveAll(params)}
                                sortable={this.props.settings.listmaindata.sortable}
                                sizetofit={false}
                                suppressMenu={this.props.settings.listmaindata.suppressMenu}
                                enableRowGroup={this.props.settings.listmaindata.enableRowGroup}
                                headerComponentParams = {this.props.settings.listmaindata.headerComponentParams && this.props.settings.listmaindata.headerComponentParams.length > 2 ?{
                                  template: this.props.settings.listmaindata.headerComponentParams} : null}
                                suppressColumnVirtualisation={this.props.settings.listmaindata.suppressColumnVirtualisation}
                                filter={this.props.settings.listmaindata.filter}
                                editable={this.props.settings.listmaindata.editable}
                                pinnedTopRowData={this.state.pinnedSubJobData}
                                GridReady={this.props.settings.listmaindata.GridReady ? this.onGridReady : null}
                                sleutel='SubJobMain'                     
                              />                                    
                            }
                          </TabPane>
                          <TabPane style={{height:Math.floor((window.innerHeight * .85) - 100) + 'px'}} tabId="2">
                            <PerfectScrollbar className="sidebar-nav">
                              <CardBody style={{textAlign:'left',height:Math.floor( (window.innerHeight * .85) - 120) + 'px'}}>
                                <Timeline style={{overflowY:'auto', overflowX:'hidden', height:Math.floor( (window.innerHeight * .85) - 140) + 'px'}} stories={this.state.workflowstories} />
                              </CardBody>
                            </PerfectScrollbar>

                          </TabPane>
                          <TabPane tabId="3">

                            { this.state.tabvalue === '3' && this.props.jobs.SubJobTariefData && this.props.jobs.SubJobTariefData.data && this.props.jobs.SubJobMainData.kol &&
                              this.props.settings.listmaindata && this.props.settings.listmaindata.mainkey && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','zien') &&
                              this.props.jobs && this.props.jobs.JobDefMainData && this.props.jobs.JobDefMainData.data && this.props.jobs.JobDefMainData.data.id !== 0 &&
                              <TableGrid
                                tableheight={tableheight} 
                                columns={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['kol']}
                                data={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['data']} 
                                CellValueChanged={(params,waarde,object) => this.SubJobTariefChanged(params,waarde,object)}
                                CellValueCheck={(params,value,error) => this.SubJobTariefCheck(params,value,error)}
                                CellClickedEvent={(column, colDef, value) => this.SubJobTariefCellClick(column,colDef, value)}
                                SaveAll={(params) => this.TariefSaveAll(params)}
                                sortable={this.props.settings.listmaindata.sortable}
                                sizetofit={this.props.settings.listmaindata.sizetofit}
                                suppressMenu={this.props.settings.listmaindata.suppressMenu}
                                enableRowGroup={this.props.settings.listmaindata.enableRowGroup}
                                headerComponentParams = {this.props.settings.listmaindata.headerComponentParams && this.props.settings.listmaindata.headerComponentParams.length > 2 ?{
                                  template: this.props.settings.listmaindata.headerComponentParams} : null}
                                suppressColumnVirtualisation={this.props.settings.listmaindata.suppressColumnVirtualisation}
                                filter={this.props.settings.listmaindata.filter}
                                editable={GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMainTar','edit') ? this.props.settings.listmaindata.editable : false}
                                GridReady={this.props.settings.listmaindata.GridReady ? this.onGridReady : null}
                                sleutel={this.props.settings.listmaindata.referentie}                     
                              />                                    
                            }
                          </TabPane>
                        <TabPane tabId="4">
                          <div style={{ marginTop: "25px" }}>
                            {!this.state.edit && this.getTaalcodes()}
                            {this.state.edit && this.getTaalcodesEdit()}
                          </div>
                        </TabPane> 

                        </TabContent>




                      </div>
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
        {this.state.PopUpScreen}

      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Jobdefmainpage));
