
const gdprPopupStyle = {
    blockContent: {
        fontSize: "10pt",
        fontWeight: "400",
        color: "rgba(0, 0, 0, 0.54)"
    },
    list: {
        lineHeight: "15px",
        marginleft: "15px"
    },
  	modalSectionTitle: {
    	marginTop: "30px"
  	},
  radio: {
    color: "#4F5467 !important"
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: '#9e9e9e',
  },  
  radioChecked: {
    width: "16px",
    height: "16px",
    border: "1px solid #4F5467",
    borderRadius: "50%"
  },
  radioUnchecked: {
    width: "0px",
    height: "0px",
    padding: "7px",
    border: "1px solid rgba(0, 0, 0, .54)",
    borderRadius: "50%"
  },

  radioGroup: {
      display: "block",
      flexWrap: "wrap",
      flexDirection: "row",
      width: "100%"
  },

  modallarge: {
    maxWidth: 'calc(100vw - 100px) !important',
    minWidth: 'calc(100vw - 100px) !important',
  },

    radioButton: {
        color: "#4F5467 !important",
        flexGrow: '1',
        flexBasis: "0",
        minWidth: "40%",
    },  
};

export default gdprPopupStyle;