import React from 'react';
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import { Card, CardBody  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import { AllJobDefMain, JobDefListCodes } from '../../redux/settings/jobsActions.js';
import SaveIcon from '@material-ui/icons/Save';
import FormatList from '@material-ui/icons/FormatListBulleted'
import { AllBetalingsvoorwaarden, AllBtwRegimes } from '../../redux/settings/paramActions.js';
import { GetAllFacturen,  GetAccountInvoiceDetails } from '../../redux/settings/accountActions.js';
import { AllLanden } from '../../redux/settings/landActions.js';
import PopListCreaInv from "./PopListCreaInv.jsx";


import LoaderGif from '../../assets/images/loadinggif.gif';


var gridapi = null;
const mapStateToProps = state => ({
    ...state
});

class CreateInvoicepage extends React.Component {

  state = {
    tableheight: Math.floor(window.innerHeight * .75) + 'px',
    drchecked: false,
    checkallinvoices:false,
    accijnschecked: false,
    vatchecked: false,
    invoiceType: "",
    jobsdata: [],
    jobscols: [],
    allfirstfilteredjobs: [],
    alljobsdata: [],
    alljobscols: [],
    factuurtype: [],
    allfacttypes: [],
    groupedOnce: false,
    groupClick: "",
    selectedJobs: [],
    speedopen: false,
    speedactions: [
      { icon: <SaveIcon />, name: 'Save', tooltip: 'Facturen aanmaken & opslaan' },
      { icon: <FormatList />, name: 'Overview', tooltip: 'Facturenoverzicht' },
    ],
    save: false,
    savedetails: "",
    updateCheckboxes: false,
    disabledRowNodes: [],
    saveinprogress: false,
    PopUpScreen:null,
  };

  constructor(props, context) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.handleInvoiceTypeChecked = this.handleInvoiceTypeChecked.bind(this);
    this.handlecheckallinvoices = this.handlecheckallinvoices.bind(this);
    this.onGridReady = this.onGridReady.bind(this);


    this.handleJobTypeChecked = this.handleJobTypeChecked.bind(this);
    this.getInvoiceTypes = this.getInvoiceTypes.bind(this);
    this.jobCellClick = this.jobCellClick.bind(this);
    this.handleRowColors = this.handleRowColors.bind(this);
    this.handleCheckboxes = this.handleCheckboxes.bind(this);
    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.actionClick = this.actionClick.bind(this);
    this.saveInvoices = this.saveInvoices.bind(this);
    this.OpenPopListCreaInv = this.OpenPopListCreaInv.bind(this);
    this.ClosePopListCreaInv = this.ClosePopListCreaInv.bind(this);

  }

  async OpenPopListCreaInv(data,factnr,seljobs,accounts) {
        this.setState({ PopUpScreen: (<PopListCreaInv accdata={accounts} open={true} data={data} factnr={factnr} seljobs={seljobs} invoiceType={this.state.invoiceType} close={this.ClosePopListCreaInv} title='Ter controle facturen die worden aangemaakt' />) });
  }
  async ClosePopListCreaInv() {
    this.setState({ PopUpScreen: null, saveinprogress: false });
    await this.props.dispatch(GetAllFacturen());
    this.props.history.push('/invoices');
  }

  onGridReady(params)
  {
    gridapi = params;
  }



  async componentDidMount() {
    window.addEventListener("resize", this.resizeFunction);
    await this.props.dispatch(AllLanden(this.props));
    await this.props.dispatch(AllJobDefMain(this.props));
    await this.props.dispatch(JobDefListCodes());
    let facttype = this.props.jobs.JobDefListCodesData.data;
    let jobdata = [];
    for (let i = 0; i < this.props.jobs.AllJobsToInvoiceData.data.length; i++) {
      let job = this.props.jobs.AllJobsToInvoiceData.data[i];

      let splittedJobCode = (job.code).split("_");
      let jobCode;
      if (splittedJobCode.length === 1) {
        jobCode = splittedJobCode[0];
      } else {
        jobCode = splittedJobCode[1];
      }

      job['selected'] = false;
      job['jobtype'] = facttype[jobCode]['description']
      job['jobsubject'] = facttype[jobCode]['subject']
      jobdata.push(job);
    }
    let jobcols = this.props.jobs.AllJobsToInvoiceData.kol;

    this.setState({ factuurtype: facttype, alljobsdata: jobdata, alljobscols: jobcols })
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }

  async componentWillReceiveProps(nextProps, nextState) {

  }

  resizeFunction() {
    if (window.innerHeight >= 960) {
      this.setState({ tableheight: Math.floor(window.innerHeight * .75) + 'px' });
    }
    else {
      this.setState({ tableheight: '600px' });
    }
  }

  getContextMenuItems(params, sleutel) {
    return null;
  }

  getInvoiceTypes(){
    let types = [];

    for (let i = 0; i < this.state.allfacttypes.length; i++) {
      types.push(
        <div style={{ width: "80%", margin: "auto" }} key={"type_" + i}>
          <label style={{ width: "5%" }}>
            <Checkbox
              name={this.state.allfacttypes[i].description}
              onClick={this.handleJobTypeChecked}
              checked={this.state.allfacttypes[i].selected}
              style={{ color: "#137cbd" }}
            />
          </label>
          <label style={{ width: "95%" }}>{this.state.allfacttypes[i].description}</label>
        </div>
      );
    }

    return types;
  }

  handleInvoiceTypeChecked(event) {
    let alltypes = [...this.props.jobs.AllJobDefMainData.data];
    let alljobs = [...this.state.alljobsdata];
    let allcols = [...this.state.alljobscols];
    switch (event.target.name) {
      case "dr":
        let alldrtypes = alltypes.filter((type) => {
          if(type.subject === "c401ca14238a0b923820dcc509a6f75849b" || type.subject === "a8017f4f679a2f3e71d9181a67b7542122c") type['selected'] = true;
          return (type.subject === "c401ca14238a0b923820dcc509a6f75849b" || type.subject === "a8017f4f679a2f3e71d9181a67b7542122c")
        })
        
        let drfilteredjobs = alljobs.filter((job) => {
          return (job.jobsubject === "c401ca14238a0b923820dcc509a6f75849b" || job.jobsubject === "a8017f4f679a2f3e71d9181a67b7542122c")
        })
        
        let drcols = allcols.filter((col) => {
          return (col.field !== "vat_facttot" && col.field !== "tot_vat_btw_val" && col.field !== "tot_vat_btw_eur" && col.field !== "refertenum"  ) ;
        })
        this.setState({ drchecked: true, accijnschecked: false, vatchecked: false, invoiceType: "Directe Registratie", allfacttypes: alldrtypes, jobsdata: drfilteredjobs, allfirstfilteredjobs: drfilteredjobs, jobscols: drcols, updateCheckboxes: true });
        break;

      case "accijns":
        let allaccijnstypes = alltypes.filter((type) => {
          if(type.subject === "c8011e2728d9d4c2f636f067f89cc14862c" || type.subject === "a8017f4f679a2f3e71d9181a67b7542122c") type['selected'] = true;
          return (type.subject === "c8011e2728d9d4c2f636f067f89cc14862c" || type.subject === "a8017f4f679a2f3e71d9181a67b7542122c")
        })

        let accijnsfilteredjobs = alljobs.filter((job) => {
          return (job.jobsubject === "c8011e2728d9d4c2f636f067f89cc14862c" || job.jobsubject === "a8017f4f679a2f3e71d9181a67b7542122c")
        })

        let accijnscols = allcols.filter((col) => {
          return col.field !== "";
        })
        this.setState({ drchecked: false, accijnschecked: true, vatchecked: false, invoiceType: "Accijnzen", allfacttypes: allaccijnstypes, jobsdata: accijnsfilteredjobs, allfirstfilteredjobs: accijnsfilteredjobs, jobscols: accijnscols, updateCheckboxes: true });
        break;

      case "vat":
        let allvattypes = alltypes.filter((type) => {
          if(type.subject === "ec01cb3c87e4b5ce2fe28308fd9f2a7baf3" || type.subject === "a8017f4f679a2f3e71d9181a67b7542122c") type['selected'] = true;
          return (type.subject === "ec01cb3c87e4b5ce2fe28308fd9f2a7baf3" || type.subject === "a8017f4f679a2f3e71d9181a67b7542122c")
        })
        
        let vatfilteredjobs = alljobs.filter((job) => {
          return (job.jobsubject === "ec01cb3c87e4b5ce2fe28308fd9f2a7baf3" || job.jobsubject === "a8017f4f679a2f3e71d9181a67b7542122c")
        })

        let vatcols = allcols.filter((col) => {
          return (col.field !== "tarief" && col.field !== "regnummer" && col.field !== "saldotebet"  ) ;

        })
        this.setState({ drchecked: false, accijnschecked: false, vatchecked: true, invoiceType: "VAT-Refund", allfacttypes: allvattypes, jobsdata: vatfilteredjobs, allfirstfilteredjobs: vatfilteredjobs, jobscols: vatcols, updateCheckboxes: true });
        break;

      default:
        return;
    }
  }

  handleJobTypeChecked(event) {
    let jobtype = event.target.name;

    let updTypesData = this.state.allfacttypes;
    let updJobData = [];
    for (let i = 0; i < updTypesData.length; i++) {
      if (jobtype === updTypesData[i].description) {
        if (event.target.checked) {
          updTypesData[i].selected = true;

        } else {
          updTypesData[i].selected = false;
        }
      }
    }
    let checkedtypes = updTypesData.filter(type => type.selected === true);
    let selectedtypes = [...new Set((checkedtypes).map(type => type.description))];


    for (let j = 0; j < this.state.allfirstfilteredjobs.length; j++) {
      let splittedJobCode = (this.state.allfirstfilteredjobs[j].code).split("_");
      let jobCode;

      if (splittedJobCode.length === 1) {
        jobCode = splittedJobCode[0];
      } else {
        jobCode = splittedJobCode[1];
      }
      if ((Object.values(selectedtypes)).includes(this.state.factuurtype[jobCode]['description'])) {
        let jobdata = this.state.allfirstfilteredjobs[j];
        jobdata.selected = false;
        updJobData.push(jobdata);
      }
    }

    this.setState({ allfacttypes: updTypesData, jobsdata: updJobData, selectedJobs: [], save: false })
  }


  async handlecheckallinvoices(event) {
    let valchecked = event.target.checked;
    let alljobs = [...this.state.jobsdata];

    for(let i = 0; i < alljobs.length; i++)
    {
      let rij = alljobs[i];
      rij.selected = valchecked;
      if(!rij.betaalvoorw || !rij.vatreg || !rij.factklant || !rij.taalcode){
        rij.selected = false;
      }
      if(rij.tarief === "0" && rij.vat_facttot === "0.0000"){
        rij.selected = false;
      }
      alljobs[i] = rij
      if (rij.selected)
      {
        if(!(this.state.selectedJobs).includes(rij)){
          this.state.selectedJobs.push(rij);
        }
      }
      else 
      {
        if((this.state.selectedJobs).includes(rij))
        {
          let index = (this.state.selectedJobs).findIndex(job => job === rij);
            this.state.selectedJobs.splice(index, 1);
        }
      }      
    }
    if(!this.props.param.AllBetalingsvoorwaardenData){
      await this.props.dispatch(AllBetalingsvoorwaarden(this.props));
    }
    if(!this.props.param.AllBtwRegimesData){
      await this.props.dispatch(AllBtwRegimes(this.props));
    }

    let save;
    let details;
    if(this.state.selectedJobs.length > 0){
      let jobswithoutinvoicedata = 0;
      let jobswithtariefzero = 0;
      for(let i = 0; i < this.state.selectedJobs.length; i++){
        let job = this.state.selectedJobs[i];
        if(!job.betaalvoorw || !job.vatreg || !job.factklant || !job.taalcode){
          jobswithoutinvoicedata++;
        }
        if(job.tarief === "0" && job.vat_facttot === "0.0000"){
          jobswithtariefzero++;
        }
      }
      if(jobswithoutinvoicedata === 0 && jobswithtariefzero === 0){
        save = true;
        details = "";
      } else {
        if(jobswithoutinvoicedata !== 0 && jobswithtariefzero === 0){
          save = false;
          details = "Job(s) met onvolledige facturatiegegevens klant aangeduid"
        } else if (jobswithoutinvoicedata === 0 && jobswithtariefzero !== 0) {
          save = true;
          details = "Job(s) met een tarief van 0 aangeduid"
        } else {
          save = false;
          details = "Job(s) met onvolledige facturatiegegevens klant en job(s) met een tarief van 0 aangeduid"
        }
      }
    } else {
      details = "";
      save = false;
    }
    await this.setState({ checkallinvoices: valchecked, jobsdata: alljobs,save: save, savedetails: details});
    if (gridapi)
    {
      let params = {force: true,};
      gridapi.api.refreshCells(params)     
    }
    console.log(this.state.selectedJobs)
  }



  async jobCellClick(waarde){
    if(waarde.node.field && !(this.state.disabledRowNodes).includes(waarde.node)){
      let updData = [];
      if(waarde.value){
        updData["selected"] = !waarde.value;
        if(!waarde.value){
          updData["count"] = waarde.node.childrenAfterGroup.length;
        } else {
          updData["count"] = 0;
        }
      } else {
        updData["selected"] = true;
        updData["count"] = waarde.node.childrenAfterGroup.length;
      }
      waarde.node.setData(updData)
      for(let i = 0; i < waarde.node.childrenAfterGroup.length; i++){
        waarde.node.childrenAfterGroup[i].data.selected = updData.selected;
        waarde.node.childrenAfterGroup[i].setData(waarde.node.childrenAfterGroup[i].data)
        if(updData.selected){
          if(!(this.state.selectedJobs).includes(waarde.node.childrenAfterGroup[i].data)){
            (this.state.selectedJobs).push(waarde.node.childrenAfterGroup[i].data);
          }
        } else {
          if((this.state.selectedJobs).includes(waarde.node.childrenAfterGroup[i].data)){
            let index = (this.state.selectedJobs).findIndex(job => job === waarde.node.childrenAfterGroup[i].data);
            (this.state.selectedJobs).splice(index, 1);
          }
        }
      }
    } else if(!(this.state.disabledRowNodes).includes(waarde.node)){
      if(waarde.colDef.field === "selected"){
        for(let i = 0; i < this.state.jobsdata.length; i++){
          if(waarde.data === this.state.jobsdata[i]){
            waarde.data.selected = !waarde.data.selected;
            waarde.node.setData(waarde.data)
            if(waarde.data.selected){
              if(!(this.state.selectedJobs).includes(waarde.data)){
                this.state.selectedJobs.push(waarde.data);
              }
            } else {
              if((this.state.selectedJobs).includes(waarde.data)){
                let index = (this.state.selectedJobs).findIndex(job => job === waarde.data);
                this.state.selectedJobs.splice(index, 1);
              }
            }
          }
        }
        if(waarde.node.parent.data){
          if(waarde.data.selected){
            waarde.node.parent.data.count = waarde.node.parent.data.count + 1;
          } else {
            waarde.node.parent.data.count = waarde.node.parent.data.count - 1;
          }
          if(waarde.node.parent.data.count === waarde.node.parent.childrenAfterGroup.length){
            waarde.node.parent.data.selected = true;
            waarde.node.parent.setData(waarde.node.parent.data)
          } else {
            waarde.node.parent.data.selected = false;
            waarde.node.parent.setData(waarde.node.parent.data)
          }
        } else {
          let updData = [];
          updData["count"] = 1;
          if(updData.count === waarde.node.parent.childrenAfterGroup.length){
            updData["selected"] = true;
            waarde.node.parent.setData(updData)
          } else {
            updData["selected"] = false;
            waarde.node.parent.setData(updData)
          }
        }
      }
    } else {
    }

    if(!this.props.param.AllBetalingsvoorwaardenData){
      await this.props.dispatch(AllBetalingsvoorwaarden(this.props));
    }
    if(!this.props.param.AllBtwRegimesData){
      await this.props.dispatch(AllBtwRegimes(this.props));
    }

    let save;
    let details;
    if(this.state.selectedJobs.length > 0){
      let jobswithoutinvoicedata = 0;
      let jobswithtariefzero = 0;
      for(let i = 0; i < this.state.selectedJobs.length; i++){
        let job = this.state.selectedJobs[i];
        if(!job.betaalvoorw || !job.vatreg || !job.factklant || !job.taalcode){
          jobswithoutinvoicedata++;
        }
        if(job.tarief === "0" && job.vat_facttot === "0.0000"){
          jobswithtariefzero++;
        }
      }
      if(jobswithoutinvoicedata === 0 && jobswithtariefzero === 0){
        save = true;
        details = "";
      } else {
        if(jobswithoutinvoicedata !== 0 && jobswithtariefzero === 0){
          save = false;
          details = "Job(s) met onvolledige facturatiegegevens klant aangeduid"
        } else if (jobswithoutinvoicedata === 0 && jobswithtariefzero !== 0) {
          save = true;
          details = "Job(s) met een tarief van 0 aangeduid"
        } else {
          save = false;
          details = "Job(s) met onvolledige facturatiegegevens klant en job(s) met een tarief van 0 aangeduid"
        }
      }
    } else {
      details = "";
      save = false;
    }
    this.setState({ save: save, savedetails: details })
  }

  handleRowColors(tablegridapi){
    //Make rows red where tarief = 0
    tablegridapi.gridOptionsWrapper.gridOptions.getRowStyle = function(params) {
      if(params.node.data && params.node.data.tarief === "0" && params.node.data.vat_facttot === "0.0000"){
        return { background: "#fb9678", color: "white" }
      }
    }
    let jobdata = this.state.jobsdata;
    this.setState({ jobsdata: [] })
    this.setState({ jobsdata: jobdata })
  }

  handleCheckboxes(status){
    let levels = [...new Set((status).map(rownode => rownode.level))];
    let disabledRowNodes = this.state.disabledRowNodes;
    if(levels.length > 2){
      let disabledlevels = levels.reverse().slice(2).reverse();
      disabledRowNodes = [];
      for(let i = 0; i < status.length; i++){
        if(disabledlevels.includes(status[i].level)){
          disabledRowNodes.push(status[i]);
        }
      }
    } else {
      disabledRowNodes = [];
    }
    this.setState({disabledRowNodes:disabledRowNodes})
  }

  handleSpeedClose() {
    this.setState({ speedopen: false });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true });
  };

  async actionClick(name){
    switch(name){
      case 'Save':
        this.setState({ saveinprogress: true })
        this.saveInvoices();
        break;

      case 'Overview':
        await this.props.dispatch(GetAllFacturen());
        this.props.history.push('/invoices');
        break;

      default:
        return;
    }
    this.setState({ speedopen: false });
  }

  async saveInvoices(){
    let accounts = [...new Set((this.state.selectedJobs).map(job => job.account_name))];
    let invoices = [];

    let year = new Date().getFullYear();
    let date = new Date();
    let today = (date.toISOString().slice(0, 10)).split('-').reverse().join('-');

    let invoiceNumber;
    if (this.props.account.AllFacturenData.data.length !== 0) {
      let splittedInvoiceNumber = (this.props.account.AllFacturenData.data[0].factnr).split("/");
      let lastInvoiceYear = splittedInvoiceNumber[0];
      let lastInvoiceNumber = splittedInvoiceNumber[1];
      if (year.toString() === lastInvoiceYear) {
        invoiceNumber = (Number(lastInvoiceNumber) + 1);
      } else {
        invoiceNumber = 1;
      }

    } else {
      invoiceNumber = 1;
    }
    for(let i = 0; i < accounts.length; i++){
      invoices[accounts[i]] = {
        invoice_data: {
          factnr: year + "/" + (invoiceNumber + i),
          data: ""
        },
        language: "",
        jobs_data: []
      };
    }
    // Laten zien keuze openen factuur
    //Setup each invoice with data
    for(let i = 0; i < this.state.selectedJobs.length; i++){
      date = new Date();      
      if(invoices[this.state.selectedJobs[i].account_name].invoice_data.data === ""){

        await this.props.dispatch(GetAccountInvoiceDetails(this.state.selectedJobs[i].account_id));
        let invoiceDetails = this.props.account.AccountInvoiceDetailsData.data;

        //Set invoice enddate
        let factbetvw = invoiceDetails.factbetvw;

        switch (factbetvw.id) {
          //contante betaling
          case "c401ca14238a0b923820dcc509a6f75849b":
            date.setDate(date.getDate() + parseInt(factbetvw.dagen));
            break;

          //30 dagen factuurdatum
          case "c8011e2728d9d4c2f636f067f89cc14862c":
            date.setDate(date.getDate() + parseInt(factbetvw.dagen));
            break;

          //Domiciliëring
          case "ec01cb3c87e4b5ce2fe28308fd9f2a7baf3":
            date.setDate(date.getDate() + parseInt(factbetvw.dagen));
            break;

          //30 dagen einde maand
          case "a8017f4f679a2f3e71d9181a67b7542122c":
            if (date.getMonth() === 11) {
              date = new Date((date.getFullYear() + 1), 1, 0);
              date.setDate(date.getDate() + 1);
            } else {
              date = new Date(date.getFullYear(), date.getMonth() + 2, 0);
              date.setDate(date.getDate() + 1);
            }
            break;

          default:
            return;
        }
        let enddate = (date.toISOString().slice(0, 10)).split('-').reverse().join('-');
        
        let accdata = {
          id: 0,
          acc_id: this.state.selectedJobs[i].account_id,
          type: this.state.invoiceType,
          factnr: invoices[this.state.selectedJobs[i].account_name].invoice_data.factnr,
          taalcode: invoiceDetails.taalcode,
          factdate: today,
          factenddate: enddate,
          totaalereloon: 0,
          btw: parseInt(invoiceDetails.btwperc),
          facttotaal: 0,
          klant: invoiceDetails.klant,
          factklant_name: invoiceDetails.factklant.name,
          factklant_address: invoiceDetails.factklant.adress,
          factklant_zipcode: invoiceDetails.factklant.zipcode,
          factklant_country: invoiceDetails.factklant.country,
          factklant_vatnumber: invoiceDetails.factklant.vatnumber,
          factklant_number: invoiceDetails.factklant.number,
          factdetails_payment: invoiceDetails.facturatie_acc.betaalvoorw_txt,
          factdetails_vatreg: invoiceDetails.facturatie_acc.vatreg_txt,
          factdetails_sendmethod: invoiceDetails.facturatie_acc.zendwijze_txt,
          factdetails_mail: invoiceDetails.facturatie_acc.emailfakt,
          vermelding: invoiceDetails.vermelding,
          betaalstatus: "Niet betaald",
          zendwijze_status: "Te verzenden",
          factlines: []
        }
        invoices[this.state.selectedJobs[i].account_name].invoice_data = accdata;
        invoices[this.state.selectedJobs[i].account_name].language = invoiceDetails.taal;
      }

      let countryobj = this.props.land.AllData.data.find(country => country.id === this.state.selectedJobs[i].country);
      let ereloon = 0;
      if(this.state.invoiceType === "Directe Registratie") ereloon = this.state.selectedJobs[i].tarief;
      if(this.state.invoiceType === "VAT-Refund") ereloon = this.state.selectedJobs[i].vat_facttot_num;
      let splittedJobcode = (this.state.selectedJobs[i].code).split("_");
      let jobcode = splittedJobcode.length === 1 ? splittedJobcode[0] : splittedJobcode[1];
      let factline = {
        subtype: jobcode,
        jobid: this.state.selectedJobs[i].id,
        land: countryobj ? countryobj[invoices[this.state.selectedJobs[i].account_name].language] : '',
        regnr: this.state.selectedJobs[i].regnummer,
        periode: this.state.selectedJobs[i].periode,
        aangiftebtw: this.state.selectedJobs[i].saldotebet_num,
        munteenheid: countryobj ? countryobj.munt_txt : '',
        teruggave: this.state.selectedJobs[i].vat_basisfact,
        koers: this.state.selectedJobs[i].vat_koers,
        teruggave_euro: this.state.selectedJobs[i].vat_basisfact_val,
        ereloon: ereloon,
        omschrijving: ""
      };
      if (this.state.selectedJobs[i].code === 'ADMK')
      {
        factline.subtype = 'EXTRAL';
        factline.ereloon = this.state.selectedJobs[i].tarief;
        factline.tarief = this.state.selectedJobs[i].tarief;
        factline.omschrijving = this.state.selectedJobs[i].description;
      }

      ereloon = (ereloon && ereloon.constructor === String) ? Number(ereloon) : ereloon;
      let totinvereloon = (invoices[this.state.selectedJobs[i].account_name] && invoices[this.state.selectedJobs[i].account_name].invoice_data.btw && invoices[this.state.selectedJobs[i].account_name].invoice_data.btw.constructor === String) ? 
      Number(invoices[this.state.selectedJobs[i].account_name].invoice_data.totaalereloon) : invoices[this.state.selectedJobs[i].account_name].invoice_data.totaalereloon;

      let ereloonexclbtw = totinvereloon + ereloon;
      let totaalbtw = ereloonexclbtw / 100 * parseInt(invoices[this.state.selectedJobs[i].account_name].invoice_data.btw);
      let erelooninclbtw = ereloonexclbtw + totaalbtw;
      invoices[this.state.selectedJobs[i].account_name].invoice_data.totaalereloon = ereloonexclbtw;
      invoices[this.state.selectedJobs[i].account_name].invoice_data.facttotaal = erelooninclbtw;
      invoices[this.state.selectedJobs[i].account_name].invoice_data.factlines.push(factline);

      invoices[this.state.selectedJobs[i].account_name].jobs_data.push(this.state.selectedJobs[i])
    }
    this.OpenPopListCreaInv(invoices,invoiceNumber,this.state.selectedJobs,accounts);

    return;


    //Save invoices + changes jobsstatus to done
    for(let i = 0; i < accounts.length; i++){
      let factdata = invoices[accounts[i]].invoice_data;
      let retdata = await SqlFunction.DoSql('ChgFactuur', factdata, this.state.selectedJobs)

      for (let j = 0; j < invoices[accounts[i]].jobs_data.length; j++) {
        let sdata = invoices[accounts[i]].jobs_data[j];
        sdata.fact_id = retdata.id;
        sdata['job_done'] = true;
        let overzdata = { data: sdata}
        await SqlFunction.DoSql('ChgJobAfterInvoiceSave', overzdata);
      }
    }

    await this.props.dispatch(GetAllFacturen());
    this.props.history.push('/invoices');
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Toolbar style={{ background: '#fff', padding: '14px 10px', boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px', width: 'calc(100% - 27px)', zIndex: '2', position: 'fixed' }}>
          <Grid container>
            <Grid item xs={8}>
              <h4 tag="h4" className="mt-3">{this.props.settings.sub_title}</h4>
            </Grid>
            <Grid item xs={4}>
            </Grid>
          </Grid>
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                FabProps={{disabled: (action.name === 'Save' && this.state.save === false) ? true : false}}
                onClick={() => {this.actionClick(action.name)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
          </SpeedDial>
        </Toolbar>
        <section className="container" style={{ width: '100%', maxWidth: '100%' }}>
          <Grid container style={{ width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px' }}>
            {!this.state.saveinprogress && <Grid container spacing={1}>
              <Grid item xs={3}>
                <Card className='card_brut'>
                  <PerfectScrollbar className="sidebar-nav">
                    <CardBody style={{ textAlign: 'center', height: Math.floor(window.innerHeight * .85) + 'px' }}>
                      <div style={{ width: '100%', marginTop: "25px", display: "flex", flexDirection: "column" }}>
                        <div style={{ width: '100%', display: "flex", flexDirection: "column", textAlign: "center" }}>
                          <h4 className="card-title m-t-10">Activiteit</h4>
                          <div style={{ width: "60%", margin: "auto" }}>
                            <label style={{ width: "5%" }}>
                              <Checkbox
                                name={"dr"}
                                onClick={this.handleInvoiceTypeChecked}
                                checked={this.state.drchecked}
                                style={{ color: "#137cbd" }}
                              />
                            </label>
                            <label style={{ width: "95%" }}>Directe Registratie</label>
                          </div>
                          <div style={{ width: "60%", margin: "auto" }}>
                            <label style={{ width: "5%" }}>
                              <Checkbox
                                name={"accijns"}
                                onClick={this.handleInvoiceTypeChecked}
                                checked={this.state.accijnschecked}
                                style={{ color: "#137cbd" }}
                              />
                            </label>
                            <label style={{ width: "95%" }}>Accijnzen</label>
                          </div>
                          <div style={{ width: "60%", margin: "auto" }}>
                            <label style={{ width: "5%" }}>
                              <Checkbox
                                name={"vat"}
                                onClick={this.handleInvoiceTypeChecked}
                                checked={this.state.vatchecked}
                                style={{ color: "#137cbd" }}
                              />
                            </label>
                            <label style={{ width: "95%" }}>VAT-Refund</label>
                          </div>
                        </div>
                        <div style={{ width: '100%', display: "flex", flexDirection: "column", textAlign: "center", marginTop: "25px" }}>
                          <h4 className="card-title m-t-10">Jobtype</h4>
                          {this.state.allfacttypes.length > 0 && this.getInvoiceTypes()}
                        </div>
                      </div>
                      <div className='div_line' style={{ marginBottom: '25px' }}></div>
                    </CardBody>
                  </PerfectScrollbar>
                </Card>
              </Grid>
              <Grid item xs={9}>
                <Card className='card_brut'>
                  <CardBody style={{ minHeight: Math.floor(window.innerHeight * .85) + 'px', overflow: "auto" }}>
                    <div name={"body"} style={{ width: '100%', display: "flex", flexDirection: "column" }}>
                      <div style={{ width: "40%"}}>
                        <label style={{ width: "5%" }}>
                          <Checkbox
                            onClick={this.handlecheckallinvoices}
                            checked={this.state.checkallinvoices}
                            style={{ color: "#137cbd" }}
                          />
                        </label>
                        <label style={{ width: "95%", paddingLeft:'15px' }}>Alles selecteren</label>
                      </div>

                    </div>
                    <div name={"body"} style={{ width: '100%', display: "flex", flexDirection: "column" }}>
                      {!this.state.save && this.state.savedetails !== "" && <div style={{ textAlign: "center" }}><label>{this.state.savedetails}</label></div>}

                      <div name={"accounts"}>
                        <TableGrid
                          ref={"jobsgrid"}
                          tableheight={this.state.tableheight}
                          columns={this.state.jobscols}
                          data={this.state.jobsdata}
                          sizetofit={false}
                          sortable={true}
                          suppressMenu={true}
                          enableRowGroup={true}
                          suppressColumnVirtualisation={true}
                          filter={true}
                          sleutel='AllJobsToInvoice'
                          getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllJobsToInvoice')}
                          editable={false}
                          CellClickedEvent={(column, colDef, value) => this.jobCellClick(column, colDef, value)}
                          handleRowColoring={this.handleRowColors}
                          handleCheckboxes={this.handleCheckboxes}
                          updateColoring={true}
                          updateCheckboxes={this.state.updateCheckboxes}
                          GridReady={this.onGridReady}

                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>}
            {this.state.PopUpScreen}
            {this.state.saveinprogress &&
              <div style={{ width: "100%" }}>
                <Card className='card_brut'>
                  <CardBody style={{ minHeight: Math.floor(window.innerHeight * .85) + 'px', overflow: "auto" }}>
                    <div style={{ textAlign: "center", verticalAlign: "center", lineHeight: Math.floor(window.innerHeight * .85) + 'px' }}>
                      <img alt = '' src={LoaderGif}/>
                    </div>
                  </CardBody>
                </Card>
              </div>}
          </Grid>
        </section>
      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(CreateInvoicepage));