import React, { Component } from "react";
//import { AgGridReact } from 'ag-grid-react';
import {AllModules} from 'ag-grid-enterprise';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-enterprise';
//import { InfiniteRowModelModule } from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import Tooltip from "@material-ui/core/Tooltip";
import Save from "@material-ui/icons/Save";
import Delete from "@material-ui/icons/Delete";
import Upload from "@material-ui/icons/Upload";
import Clear from "@material-ui/icons/Exclamation";
import FileCopy from "@material-ui/icons/FileCopy";
import PdfBoxBrutax from '@material-ui/icons/PictureAsPdf';
import PdfBox from '@material-ui/icons/PictureAsPdfOutlined'
import PdfMail from '@material-ui/icons/MailOutlined'

import GridEditSave from "./grideditsave";
import GridEditString from "./grideditstring";
import GridEditCheck from "./grideditcheck";
import GridEditAutoSelect from "./grideditautoselect";
import GridEditSelect from "./grideditselect";
import GridEditDateTime from "./grideditdatetime";

import GridRenderCheck from "./gridrendercheck";
import GridRenderUnCheck from "./gridrenderuncheck";

import Validation from "../../functions/validation.js";
import moment from 'moment'
import GenFunc from "../../functions/gen_functions.js";

import {GetAllJobs,} from "../../redux/settings/jobsActions";
import {AllFiltLanden} from "../../redux/settings/paramActions";

var SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');



var expandedindex = 0;
var expandedmaster = null;

var gridapi = null;
var gridprops = null;


function RenderCheck(data)
{
  let strlengte = data.column.actualWidth.toString()+'px';  
  let object = (<GridRenderUnCheck width={strlengte} ></GridRenderUnCheck>);
  if (data.value === true || data.value === '1' || data.value === 1)
  {
    object = (<GridRenderCheck width={strlengte}></GridRenderCheck>)
  }
  return object;
}

function RenderBar(data)
{
  let obj = null;
  if (data.value)
  {
    let subwidth = (100 / data.value.length).toString()+'%';  
    obj = (    
      <div
         key={GenFunc.MakeId()}
         style={{
          width: '100%',
          top:'15px',
          position:'relative',
          height: '20px',
          backgroundColor: 'transparent',
          borderRadius: '2px'
          }}
      >
        <div  
          style={{
            display:'inline',
            position:'relative',
            height: '20px',
          }}
        >
          {data.value.map(datavld => {
            return (
              <div 
               key={GenFunc.MakeId()}
                style={{
                  width: `${subwidth}`,
                  height: '20px',
                  backgroundColor:`${datavld}`,
                  float:'left',
                  transition: 'all .2s ease-out'
                }}
              />
            )
          })}                                  
        </div>
      </div>
    )
  }
  return obj;
}

function LocNumb(data){
  let prefix = data.column.colDef.prefix ? data.column.colDef.prefix : '';
  let suffix = data.column.colDef.suffix ? data.column.colDef.suffix : '';
  let decimaal = data.column.colDef.decimal ? data.column.colDef.decimal : data.column.colDef.decimaal ? data.column.colDef.decimaal : '2'
  if (prefix === 'EUR') {prefix = '€'}
  if (suffix === 'PERC') {suffix = '%'}
  let object = (<div>{data.value}</div>);  
  let value = parseFloat(data.value)
  if (isNaN(value)) 
  {
     object = (<div></div>);
  }
  else if (suffix !== '')
  {
    object = (<div>{value.toLocaleString('de-DE',{minimumFractionDigits: decimaal, maximumFractionDigits: decimaal})}{suffix}</div>)
  }
  else
  {
     object = (<div>{prefix} {value.toLocaleString('de-DE',{minimumFractionDigits: decimaal, maximumFractionDigits: decimaal})}</div>);
  }
  return object;
}

function LocDate(data){
  let params = {}
  params.props={}
  params.props.data = data.data;
  var datarr = null;
  let terug = Validation.ValidCheck(data.column.colDef.nagrid, data.value, data.column.colDef.field, params, gridapi);
  let object = data.value ? (<div>{data.value}</div>) : (<div></div>);  
  if (data.value && data.value.length > 9)
  {
      datarr = data.value.split("-");      
      if (datarr.length === 3)
      {
        let sd = data.value;
        if(datarr[0].length > 2)
        {

          if (datarr[0].length < 4)
          {
            terug = true;
          }
          sd = datarr[2]+'-'+datarr[1]+'-'+datarr[0];
        }
        else
        {
          if (datarr[2].length < 4)
          {
            terug = true;
          }
          sd = datarr[0]+'-'+datarr[1]+'-'+datarr[2];
        }
        var utc = moment(sd, "DD-MM-YYYY", true)
        if (!utc.isValid()) {terug = true}        
        if (terug)
        {
          object = (<div style={{color:'red'}}>{sd}<div><Clear style={{marginBottom:'7px'}}/></div></div>);  
        } 
        else
        {
          object = (<div>{sd}</div>);  
        }         
      }
      else
      {
        if (data.value)
        {
          datarr = data.value.split("-");      
          if (terug)
          {
            object = (<div style={{color:'red'}}>datarr.length<div><Clear style={{marginBottom:'7px'}}/></div></div>);  
          } 
          else
          {
            object = (<div>datarr.length</div>);  
          }         
        }
        else
        {
          if (terug)
          {
            object = (<div style={{color:'red'}}>#verplicht<div><Clear style={{marginBottom:'7px'}}/></div></div>);  
          } 
          else
          {
            object = (<div></div>);  
          }         
        }

      }
  }
  else
  {
        if (terug)
        {
          object = (<div style={{color:'red'}}>#verplicht<div><Clear style={{marginBottom:'7px'}}/></div></div>);  
        } 
        else
        {
          object = (<div></div>);  
        }         

  }
  return object;
}

function ValidString(data){
  let params = {}
  params.props={}
  params.props.data = data.data;
  let terug = Validation.ValidCheck(data.column.colDef.nagrid, data.value, data.column.colDef.field, params, gridapi);
  let object = data.value && terug ? (<div style={{color:'red'}}>{data.value}<div><Clear style={{marginBottom:'7px'}}/></div></div>) : (<div></div>);  
  if (data.value)
  {
    if (terug)
    {
      object = (<div style={{color:'red'}}>{data.value}<div><Clear style={{marginBottom:'7px'}}/></div></div>);  
    } 
    else
    {
      object = (<div>{data.value}</div>);  
    }         
  }
  else
  {
    if (terug)
    {
      object = (<div style={{color:'red'}}>#verplicht<div><Clear style={{marginBottom:'7px'}}/></div></div>);  
    } 
    else
    {
      object = (<div></div>);  
    }         
  }
  return object;
}


function Bewaren(data)
{
  let object = null;
  if (data.value === true)
  {
      object = (
        <Tooltip
            title="Bewaren gegevens"
            placement="bottom"
          >
            <Save style={{color:'#2fa5c7',fontSize:'18px'}}/>
          </Tooltip> )

  }
  if (data.value === false)
  {
      object = (
        <Tooltip
            title="Bewaren gegevens"
            placement="bottom"
          >
            <Save style={{color:'#e23f78',fontSize:'18px'}}/>
          </Tooltip> )

  }

  return object;
}


function PrintBrut(data)
{
  let object = null;
  if (data.value === false || data.value === '0' || data.value === 0 )
  {
      object = (<Tooltip
            title="Print met briefhoofd"
            placement="bottom"
          >
            <PdfBoxBrutax style={{color:'#2fa5c7',fontSize:'18px'}}/>
          </Tooltip>)
  }
  if (gridprops.editable === true) 
  {
    if (data.value === false || data.value === '0' || data.value === 0 )
    {
        object = (<Tooltip
              title="Print met briefhoofd"
              placement="bottom"
            >
              <PdfBoxBrutax style={{color:'#2fa5c7',fontSize:'18px'}}/>
            </Tooltip>)
    }
  }

  return object;
}

function PrintMail(data)
{
  let object = null;
  if (data.value > 0 )
  {
      object = (<Tooltip
            title="Mail naar klant"
            placement="bottom"
          >
            <PdfMail style={{color:'#2fa5c7',fontSize:'18px'}}/>
          </Tooltip>)
  }

  return object;
}



function PrintPdf(data)
{
  let object = null;
  if (data.value === false || data.value === '0' || data.value === 0 )
  {
      object = (<Tooltip
            title="Print zonder briefhoofd"
            placement="bottom"
          >
            <PdfBox style={{color:'#2fa5c7',fontSize:'18px'}}/>
          </Tooltip>)
  }
  if (gridprops.editable === true) 
  {
    if (data.value === false || data.value === '0' || data.value === 0 )
    {
        object = (<Tooltip
              title="Print zonder briefhoofd"
              placement="bottom"
            >
              <PdfBox style={{color:'#2fa5c7',fontSize:'18px'}}/>
            </Tooltip>)
    }
  }

  return object;
}

function Verwijderen(data)
{
  let object = null;
  if (data.value === true || data.value === '1' || data.value === 1 || data.value === '2' || data.value === 2 || data.value === 'true')
  {
      object = (<Tooltip
            title="Verwijder gegevens"
            placement="bottom"
          >
            <Delete style={{color:'#2fa5c7',fontSize:'18px'}}/>
          </Tooltip>)
  }
  if (gridprops.editable === true) 
  {
    if (data.value === true || data.value === '2' || data.value === 2 || data.value === 'true')
    {
      object = (<Tooltip
            title="Verwijder gegevens"
            placement="bottom"
          >
            <Delete style={{color:'#2fa5c7',fontSize:'18px'}}/>
          </Tooltip>)
    }
  }

  return object;
}

function Copieren(data)
{
  let object = null;
  if (data.value === true || data.value === '1' || data.value === 1 || data.value === 'true')
  {
      object = (<Tooltip
            title="Copieer gegevens"
            placement="bottom"
          >
            <FileCopy style={{color:'#2fa5c7',fontSize:'18px'}}/>
          </Tooltip>)
  }
  return object;
}


function Uploaden(data)
{
  let object = null;
  if (data.value === true || data.value === '1' || data.value === 1 || data.value === 'true')
  {
      object = (<Tooltip
            title="Bestand naar server"
            placement="bottom"
          >
            <Upload style={{color:'#2fa5c7',fontSize:'18px'}}/>
          </Tooltip>)
  }
  return object;
}



function getNestedValue(o,...a){
  var val = o;
  for (var prop of a) val = typeof val === "object" &&
                                   val !== null     &&
                                   val[prop] !== void 0 ? val[prop]
                                                        : undefined;
  return val;
}

function dateComparator(date1, date2) {
  if (!date1 )
  {
    date1 = '00-00-0000';
  }
  if (!date2 )
  {
    date2 = '00-00-0000';
  }

  date1 = date1.split('-').reverse().join('');
  date2 = date2.split('-').reverse().join('');
  return date1.localeCompare(date2);
}


function periodComparator(a, b) {
    let date1 = '01-01-2000';
    let date2 = '01-01-2000';
    let aSplitted = (a) ? a.split("/") : '';
    let bSplitted = (b) ? b.split("/") : '';
    if (aSplitted && aSplitted.length > 0)    
    {
      if (isNaN(aSplitted[0]) === false) 
      {
        if (isNaN(aSplitted[1]) === false) 
        {
           if (aSplitted[0].length < 2)
           {
             aSplitted[0] = '0'+aSplitted[0];
           }
           date1 = '01-'+aSplitted[0]+'-'+aSplitted[1];
        }
      }
    }
    if (bSplitted && bSplitted.length > 0)    
    {
      if (isNaN(bSplitted[0]) === false) 
      {
        if (isNaN(bSplitted[1]) === false) 
        {
           if (bSplitted[0].length < 2)
           {
             bSplitted[0] = '0'+bSplitted[0];
           }

           date2 = '01-'+bSplitted[0]+'-'+bSplitted[1];
        }
      }
    }
    date1 = date1.split('-').reverse().join('');
    date2 = date2.split('-').reverse().join('');
    return date1.localeCompare(date2);

}

function faktComparator(a, b) 
{
    let aSplitted = (a) ? a.split("/") : '';
    let bSplitted = (b) ? b.split("/") : '';
    let snum1 = 1;
    let snum2 = 1;

    if (aSplitted && aSplitted.length > 0)    
    {
      if (isNaN(aSplitted[0]) === false) 
      {
        snum1 = (snum1 + parseInt(aSplitted[0])) * 100;
        if (isNaN(aSplitted[1]) === false) 
        {
          snum1 = snum1 + parseInt(aSplitted[1]);
        }
      }
      else
      {
        if (isNaN(aSplitted[1]) === false) 
        {
          snum1 = snum1 + parseInt(aSplitted[1]);
        }

      }
    }
    if (bSplitted && bSplitted.length > 0)    
    {
      if (isNaN(bSplitted[0]) === false) 
      {
        snum2 = (snum2 + parseInt(bSplitted[0])) * 100;
        if (isNaN(bSplitted[1]) === false) 
        {
          snum2 = snum2 + parseInt(bSplitted[1]);
        }
      }
      else
      {
        if (isNaN(bSplitted[1]) === false) 
        {
          snum2 = snum2 + parseInt(bSplitted[1]);
        }

      }
    }
    return snum1-snum2;
}

function numComparator(snum1, snum2) 
{
    return snum1-snum2;
}


function getRowHeight(params, master) 
{
  if (params.node && params.node.detail) {
    var offset = 200;
    var allDetailRowHeight = params.data.children.length * 28;
    return allDetailRowHeight + offset;
  } else {
    return 60;
  }
}


function ZetMainMenuItems(params,sleutel,that) {
    var MenuItems = [];
    var itemsToExclude = ['resetColumns','valueAggSubMenu','save'];
    params.defaultItems.forEach( function(item) 
    {
      if (itemsToExclude.indexOf(item)<0) 
      {
        MenuItems.push(item);
      }
    });
    var titem = {name: 'Reset Columns', action: function() {that.ColumnChange({type:'Reset'},sleutel);} }
    MenuItems.push(titem);
    return MenuItems;
  }

function DispSelCell(veld)
{
  let value = getNestedValue(veld.node.data, ...veld.colDef.selectcel.split(":"))
  let object = (<div>{value}</div>);
  return object
}


function ZetcolumnsAg(tabel, sleutel, props, gridoptions) {
    let rescol = [];
    var i;
    var subtab = {};
    if (tabel) 
    {
      for (i = 0; i < tabel.length; i++) 
      { 
        let rij = tabel[i];

        if (rij.type !== 'Detail')
        {
          if (rij.cellEditorParams && rij.cellEditorParams.length > 2 && rij.checkvalue && rij.checkvalue.length > 0)
          {
              rij.editable = function(params) 
              {
                    if (params.data[params.colDef.cellEditorParams] === params.colDef.checkvalue)
                    {
                       return true
                    }
                    else
                    {
                       return false
                    }
              } 
          }

          if (rij.comparator === 'DAT')
          {
            rij.comparator = dateComparator;
          }

          if (rij.comparator === 'PER')
          {
            rij.comparator = periodComparator;
          }
          if (rij.comparator === 'FAKT')
          {
            rij.comparator = faktComparator;
          }

          if (rij.comparator === 'NUM')
          {
            rij.comparator = numComparator;
          }
          if (rij.cellEditorParams && rij.cellEditorParams.length > 2)
          {
//            rij.cellEditorParams = ZetCellEditParams(rij.cellEditorParams)
          }
          if (rij.filter === 'agDateColumnFilter')
          {  
            rij.filterParams = {comparator: function(filterLocalDateAtMidnight, cellValue) 
              {
                var dateAsString = cellValue;
                if (dateAsString == null) return -1;
                var dateParts = dateAsString.split("-");
                var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
                if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) 
                {
                  return 0;
                }
                if (cellDate < filterLocalDateAtMidnight) 
                {
                  return -1;
                }
                if (cellDate > filterLocalDateAtMidnight) 
                {
                  return 1;
                }
              },
              browserDatePicker: true
            }
          }
          if (rij.filter === 'agMenuColumnFilter' || (rij.filter ==='agSetColumnFilter' && (rij.filtervalues && rij.filtervalues.length > 2 && typeof rij.filtervalues === 'string') ))
          {  
            var filterParams = {
              values: function (params) {
                setTimeout(function () {
                  let hudata = props.conditie ? props.conditie : {};
                  hudata.secid = JSON.parse(localStorage.getItem('secid'))
                  hudata.clsecid = JSON.parse(localStorage.getItem('clsecid'))
                  hudata.function = params.colDef.filtervalues;
                  let authOptions = {
                    method: 'post',
                    url: ' https://app.brutax.com/server/index.php',            
                    data: JSON.stringify(hudata),
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        json: true,
                  };
                  new Promise(
                    async function (resolve, reject) {
                      await axios(authOptions)
                        .then((response) => {
                          if (response.data)
                          {
                            params.success(response.data);
                          }    
                          else
                          {
                            params.success([]);
                          }    
                        })
                        .catch((error) => {
                          params.success([]);
                        })
                  });
                }, 30000);
              },
            };
            rij.filter = 'agSetColumnFilter';
            rij.filterParams = filterParams
          }   
          if (rij.filter === 'agMenuParFilter' )
          {  
            rij.filter = 'agSetColumnFilter';
            rij.filterParams = {values:rij.filtervalues};
          }                    
          if (props.editable === false) {rij.editable = false}
          if (rij.colvisible === '0')
          {
            rij.suppressColumnsToolPanel = true
          }
          if (rij.sort && rij.sort.length > 1)
          {
              rij.sortable = true;
              rij.initialSort = rij.sort;
              rij.sortIndex = parseInt(rij.sortindex);
              rij.initialSortIndex = parseInt(rij.sortindex);

          }
          let resrij = {}
          resrij.editable = rij.editable;
          resrij.sortable = rij.sortable;
          resrij.sort = rij.sort;
          resrij.cellEditor = rij.cellEditor
          resrij.cellEditorParams = rij.cellEditorParams
          resrij.cellRenderer = rij.cellRenderer
          resrij.cellStyle = rij.cellStyle 
          resrij.comparator = rij.comparator
          resrij.field = rij.field
          resrij.filter = rij.filter
          resrij.filterParams = rij.filterParams
          resrij.floatingFilterComponentParams = rij.floatingFilterComponentParams
          resrij.headerName = rij.headerName
          resrij.hide = rij.hide
          resrij.menuTabs = rij.menuTabs
          resrij.pinned = rij.pinned
          resrij.resizable = rij.resizable
          resrij.rowGroupIndex = rij.rowGroupIndex
          resrij.suppressMenu = rij.suppressMenu
          resrij.type = rij.type
          resrij.width = rij.width

          subtab = rij; 



          if (subtab)
          {
            rescol.push(subtab);
          }
        }
      }
    }

    return rescol;
  }

function GetDetailcolumnsAg(tabel, sleutel) {
    let rescol = [];
    var i;
    var subtab = {};
    if (tabel) 
    {
      for (i = 0; i < tabel.length; i++) 
      { 
        let rij = tabel[i];
        if (rij.type === 'Detail')
        {

             if (rij.comparator === 'DAT')
             {
                rij.comparator = dateComparator;
             }
             if (rij.comparator === 'PER')
             { 
               rij.comparator = periodComparator;
             }
             if (rij.comparator === 'FAKT')
             {
               rij.comparator = faktComparator;
             }

             if (rij.comparator === 'NUM')
             {
                rij.comparator = numComparator;
             }


             if (rij.cellEditorParams && rij.cellEditorParams.length > 2)
             {
//                 rij.cellEditorParams = ZetCellEditParams(rij.cellEditorParams)
             }
             if (rij.filter === 'agDateColumnFilter')
             {
                rij.filterParams = {comparator: function(filterLocalDateAtMidnight, cellValue) 
                  {
                    var dateAsString = cellValue;
                    if (dateAsString == null) return -1;
                    var dateParts = dateAsString.split("-");
                    var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
                    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) 
                    {
                      return 0;
                    }
                    if (cellDate < filterLocalDateAtMidnight) 
                    {
                      return -1;
                    }
                    if (cellDate > filterLocalDateAtMidnight) 
                    {
                      return 1;
                    }
                  },
                  browserDatePicker: true
                }
             }
              if (rij.filter === 'agMenuColumnFilter')
              {  
                var filterParams = {
                  values: function (params) {
                    setTimeout(function () {
                      let hudata = {};
                      hudata.secid = JSON.parse(localStorage.getItem('secid'))
                      hudata.clsecid = JSON.parse(localStorage.getItem('clsecid'))
                      hudata.function = params.colDef.filtervalues;
                      let authOptions = {
                        method: 'post',
                        url: ' https://app.brutax.com/server/index.php',            
                        data: JSON.stringify(hudata),
                            headers: {
                              'Content-Type': 'application/json'
                            },
                            json: true,
                      };
                      new Promise(
                        async function (resolve, reject) {
                          await axios(authOptions)
                            .then((response) => {
                              if (response.data)
                              {
                                params.success(response.data);
                              }    
                              else
                              {
                                params.success([]);
                              }    
                            })
                            .catch((error) => {
                              params.success([]);
                            })
                      });
                    }, 3000);
                  },
                };
                rij.filter = 'agSetColumnFilter';
                rij.filterParams = filterParams
              }             
             rij.suppressColumnsToolPanel = true
             subtab = rij; 
             if (subtab)
             {
                rescol.push(subtab);
             }
        }


      }
    }
    return rescol;
  }

 
export default class TableGrid extends Component {
  state = {
    displayedColumnsChanged:0,
      columnDefs: ZetcolumnsAg(this.props.columns, this.props.sleutel, this.props, this.props.conditie),
      rowBuffer: 0,
      conditie:this.props.conditie,
      rowModelType: 'infinite',
      paginationPageSize: 10,
      cacheOverflowSize: 2,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 50,
      maxBlocksInCache: 10,      

      detailCellRendererParams: {
        detailGridOptions: {
          columnDefs: [
          ],
          onFirstDataRendered: function(params) {
            if (this.props.sizetofit)
            {
              params.api.sizeColumnsToFit();
            }
          }
        },
        getDetailRowData: function(params) {
          params.successCallback(params.data.children);
        }
      },

  };

  constructor(props, context) {
    super(props, context);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.onCellValueCheck = this.onCellValueCheck.bind(this);
    this.addRecord = this.addRecord.bind(this);
    this.getFilter = this.getFilter.bind(this);
    this.printSortStateToConsole = this.printSortStateToConsole.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.preSort = this.preSort.bind(this);
    this.QuickPreSort = this.QuickPreSort.bind(this);

    this.getmenu = this.getmenu.bind(this);
    this.onCellFocusedEvent = this.onCellFocusedEvent.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.purData = this.purData.bind(this);
    this.onBtExport = this.onBtExport.bind(this);


    this.componentDidMount = this.componentDidMount.bind(this);
    this.resizeFunction = this.resizeFunction.bind(this);
    this.sortAndFilter = this.sortAndFilter.bind(this);
    this.sortData = this.sortData.bind(this);
    this.filterData = this.filterData.bind(this);

    this.handleJa = this.handleJa.bind(this);
    this.onRowGroupOpened = this.onRowGroupOpened.bind(this)
    this.sluit = this.sluit.bind(this)
    gridprops = props;
    this.params = null;
    this.totaal = null;
    this.sorteren = false;
    this.prevfiltermodel = null;
    this.bewerking = null;
    this.sortfiltermodel = null;
    this.data = [];


  }

  async componentWillUnmount() 
  {
    window.removeEventListener("resize", this.resizeFunction);    
    if (this.props.editable === true && this.props.SaveAll)
    {
      let items=[];
      if (this.gridApi)
      {
        this.gridApi.forEachNode(function(node) { 
          items.push(node.data);
        });
      }

      if (this.gridApi && Array.isArray(this.gridApi.pinnedRowModel.pinnedTopRows))
      {
          if (this.gridApi.pinnedRowModel.pinnedTopRows.length > 0)
          {
            items.push(this.gridApi.pinnedRowModel.pinnedTopRows[0].data);
          }
      }
      if (items && items.length > 0)      
      {
        if ( (items && Array.isArray(items) && items[0]) && (items[0].save === '' || items[0].save === false || items[0].save === true ))
        {
          let result = items.filter(o=> o.save === true );
          if (Array.isArray(result))
          {
            if (result.length > 0)
            {
              confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,        
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Aanpassingen niet doorgevoerd.</h1>
                    <p>Wens je de aanpassingen alsnog te bewaren?</p>
                    <button onClick={onClose}>Nee</button>
                    <button
                      onClick={() => {
                        this.handleJa(result);
                        onClose();
                      }}
                    >
                      Ja
                    </button>
                  </div>
                );
              }
              });
            }
          }
        }
      }
    }
  }

  handleJa(result)
  {
    if (this.props.SaveAll) {this.props.SaveAll(result)}
  }

  componentDidMount(){
    window.addEventListener("resize", this.resizeFunction);
 
  }


  resizeFunction() 
  {
    if (this.gridApi)
    {
      this.gridApi.doLayout()
      if (this.props.sizetofit)
      {
        this.gridApi.sizeColumnsToFit();
        this.gridApi.redrawRows()
      }         
    }
  }


  sortAndFilter(allOfTheData, sortModel, filterModel) {
    return this.sortData(sortModel, this.filterData(filterModel, allOfTheData));
  }

  sortData(sortModel, data) {
    if (this.sorteren === null)
    {
      return [];
    }
    return data;
  }

  filterData(filterModel, data) {
    if (this.sorteren === null || this.sorteren === true)
    {
      return [];
    }

      return data;
  }  

  createServerSideDatasource(sql,hdata,props,that) {
    return {
      getRows: function (params) {
        if (that.sorteren === true)
        {
          return;
        }
        that.gridApi.suppressNoRowsOverlay = false;
        // get data for request from our fake server
        let hudata = Array.isArray(hdata) ? {data:hdata} : hdata;
        hudata.secid = JSON.parse(localStorage.getItem('secid'))
        hudata.clsecid = JSON.parse(localStorage.getItem('clsecid'))

        hudata.from = params.startRow;
        hudata.end = params.endRow;
        hudata.filter = params.filterModel
        var lastRow = -1;

        if (that.totaal)
        {
            if (params.endRow  > that.totaal)
            {
              if (params.startRow > that.totaal)
              {
                var rowsThisPage = that.data.slice(params.startRow, params.endRow);
                params.successCallback(rowsThisPage);        
                return;
              }
            }
        }
        if (that.lastRow === that.totaal)
        {
          var rowsThisPage = that.data.slice(params.startRow, params.endRow);
          params.successCallback(rowsThisPage);        
          return;
        }
        hudata.function = sql;
        let authOptions = {
          method: 'post',
          url: ' https://app.brutax.com/server/index.php',            
          data: JSON.stringify(hudata),
          headers: {
            'Content-Type': 'application/json'
          },
          json: true,
        };
        that.sorteren = false;
        gridapi.laden = true;
        new Promise(
        function (resolve, reject) {
          axios(authOptions)
            .then((response) => {
              if (response.data && response.data.allowed === false)
              {
                params.failCallback();
                gridapi.laden = false;
                that.gridApi.hideOverlay();
              }        
              else
              {
                if (that.totaal === null)
                {
                  that.totaal = parseInt(response.data.totaal);
                  that.lastRow = -1;
                  that.data = [];
                }
                gridapi.laden = false;
                if (params.endRow  >= that.totaal)
                {
                  that.lastRow = that.totaal
                }
                var newdata = that.data.concat(response.data.data); 
                that.data = newdata
                params.successCallback(response.data.data, that.lastRow);
                that.gridApi.hideOverlay();

              }
            })
            .catch((error) => {
              params.failCallback();
              gridapi.laden = false;
              that.gridApi.hideOverlay();

            })
        });
      },
    };
  }

  updateData(data) {
    let that = this;
    let zdata = that.props.conditie ? that.props.conditie : {}
    this.sorteren = false;
    var dataSource = this.createServerSideDatasource(that.props.funktie,zdata,that.props,that);
    this.gridApi.conditie = zdata;
    this.gridApi.setDatasource(dataSource);
    };



  onGridReady(params) {
    gridapi = params;

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;    
    gridapi.refreshData = this.refreshData;
    gridapi.purData = this.purData;
    gridapi.onBtExport = this.onBtExport;


    let that = this
    if (this.props.infinite === true)
    {
        this.updateData(this.props.data,false);
    }

    if (this.props.sizetofit)
    {
      this.gridApi.sizeColumnsToFit();
      this.gridApi.refreshCells()
    }    
    if (this.props.masterDetail === true)
    {
      params.api.setDomLayout("autoHeight");    
    }
    if (this.props.GridReady) {
      if (this.props.GridReady instanceof Function)      
      {
        this.props.GridReady(params)
      }
    }
    if(this.props.getNodesForJobs){
      let rowdata = [];
      this.gridApi.forEachNode(node => rowdata.push(node))

      this.props.handleNodes(this.gridApi)
    }
  }

  onCellFocusedEvent(rowIndex, column, rowPinned, forceBrowserFocus)
  {

  }

  onCellValueChanged(params,value) {
    if (this.props.CellValueChanged) {this.props.CellValueChanged(params,value,this)}
  }
  onCellValueCheck(params,value,type) {
    this.props.CellValueCheck(params,value,type)
  }

  addRecord(params,value) {
    if (this.props.addRecord)
    {
      this.props.addRecord(params,value)
    }
  }

  getFilter(params) {
    if (this.props.getFilter)
    {
      this.props.getFilter(params)
    }
  }

  async getmenu(params) {
    let terug = [];
    if (this.props.getmenu) 
    {
        terug = await this.props.getmenu(params)
        return terug;
    }
    else
    {
        return terug
    }
  }

  async filterChange(column,sleutel,sql) 
  {
    let that = this;
    if (that.props.infinite === true)
    {
      that.sorteren = true
      that.totaal = null;
      gridapi.sorteren = true;
      that.updateData(null) 
    }
  }  

  async refreshData() 
  {
    if (this.data.length <= 50)
    {
        this.sorteren = true
        this.totaal = null;
        gridapi.sorteren = true;
        this.updateData(null) 
    }
    else
    {
      this.gridApi.refreshInfiniteCache();
    }
  }  

  async purData() 
  {
    if (this.data.length >= this.totaal)
    {
        this.sorteren = true
        this.totaal = null;
        gridapi.sorteren = true;
        this.updateData(null) 
    }
    else
    {
        this.gridApi.purgeInfiniteCache();
    }
  }  


  async printSortStateToConsole(column,sleutel,sql) 
  {

    if (this.props.infinite === true)
    {
      let that = this;
      that.sorteren = true
      that.totaal = null;
      gridapi.sorteren = true;
      if (this.gridApi.sortController.columnController.columnsForQuickFilter)
      {
        await that.QuickPreSort(column,sleutel,sql) 
      }
      else
      {
        await that.preSort(column,sleutel,sql) 
      }
      if (that.props.infinite === true)
      {
        that.updateData(null) 
      }
    }
    else
    {
      var sortState = this.gridApi.getSortModel();
      let coldefs = column.columnApi.getColumnState();
      for (var cdi = 0; cdi < coldefs.length; cdi++) 
      {
          let kol = coldefs[cdi];
          kol.sort = '';
          kol.sortindex = 0;
          for (var i = 0; i < sortState.length; i++) 
          {
              var item = sortState[i];
              if (item.colId === kol.colId)
              {
                  kol.sort = item.sort;
                  kol.sortindex = i;
              }
          }
          let data = {coldef:kol,sleutel:sleutel,colid:kol.colId,type:'columnSort'};
          await SqlFunction.DoSql('UpdKolInstUser',data,this.props);
      }
    }
  }  
  async QuickPreSort(column,sleutel,sql) 
  {
    let that = this;
    var sortState = this.gridApi.getSortModel();
    this.gridApi.showLoadingOverlay();
    let coldefs = this.gridApi.sortController.columnController.columnsForQuickFilter;
    let orderarr = [];
    for (var cdi = 0; cdi < coldefs.length; cdi++) 
    {
        let kol = coldefs[cdi];
        let newkol = kol.colDef;
        newkol.sort = '';
        newkol.sortindex = 0;
        for (var i = 0; i < sortState.length; i++) 
        {
            var item = sortState[i];
            if (item.colId === kol.colId)
            {
                newkol.sort = item.sort;
                newkol.sortindex = i;
            }
        }
        let data = {coldef:newkol,sleutel:sleutel,colid:newkol.field,type:'columnSort'};
        await SqlFunction.DoSql('UpdKolInstUser',data,this.props);
    }
    return
  }  


  async preSort(column,sleutel,sql) 
  {
    let that = this;
    var sortState = this.gridApi.getSortModel();
    let coldefs = that.props.columns;
    let orderarr = [];
    for (var cdi = 0; cdi < coldefs.length; cdi++) 
    {
        let kol = coldefs[cdi];
        kol.sort = '';
        kol.sortindex = 0;
        for (var i = 0; i < sortState.length; i++) 
        {
            var item = sortState[i];
            if (item.colId === kol.colId)
            {
                kol.sort = item.sort;
                kol.sortindex = i;
            }
        }
        let data = {coldef:kol,sleutel:sleutel,colid:kol.colId,type:'columnSort'};
        if (kol.sort.length > 2)
        {
             orderarr.push(kol);
        }
        await SqlFunction.DoSql('UpdKolInstUser',data,this.props);
    }
    return
  }  



  async ColumnChange(waarde,sleutel, sql)
  {
    var i = 0;
    var record = [];
    if (this.props.sizetofit === true) {return}
    if (waarde.finished && waarde.type === 'columnResized')
    {
      if (waarde.column)
      {
        let colDef = waarde.column.colDef;
        colDef.width = waarde.column.actualWidth;
        let data = {coldef:colDef,sleutel:sleutel,colid:colDef.field,type:'columnResized'};
        SqlFunction.DoSql('UpdKolInstUser',data,this.props);
        this.setState({displayedColumnsChanged:0})      
      }
      else
      {
        if (waarde.columns)
        {
          record = [];
          for (i = 0; i < waarde.columns.length; i++) 
          {
            let rij = waarde.columns[i].colDef;
            rij.width = waarde.columns[i].actualWidth;
            record.push(rij);
          }
          let data = {coldef:record,sleutel:sleutel,colid:'geen',type:'AllcolumnResized'};
          SqlFunction.DoSql('UpdKolInstUser',data,this.props);
          this.setState({displayedColumnsChanged:0})      
        }
      }
    }

    if (waarde.type === 'columnMoved' && waarde.column)
    {
      let colDef = waarde.column.colDef;
      colDef.toIndex = waarde.toIndex;
      let gridcolDef = waarde.columnApi.columnController.gridColumns;
      record = [];
      for (i = 0; i < gridcolDef.length; i++) 
      {
        let rij = gridcolDef[i];
        let zetrec = {colId:rij.colDef.field, visible:rij.visible, pinned:rij.pinned}
        record.push(zetrec);
      }
      let data = {coldef:record,sleutel:sleutel,colid:'',type:'columnMoved'};
      SqlFunction.DoSql('UpdKolInstUser',data,this.props);
      this.setState({displayedColumnsChanged:0})      
    }
    if (waarde.type === 'displayedColumnsChanged')
    {
      let updsql = false ;
        let colDef = waarde.columnApi.columnController.gridColumns;
        record = [];
        for (i = 0; i < colDef.length; i++) 
        {
          let rij = colDef[i];
          if (rij.left !== rij.oldLeft && rij.oldLeft !== undefined) {updsql = true}
          let zetrec = {colId:rij.colDef.field, visible:rij.visible, pinned:rij.pinned}
          record.push(zetrec);
        }
        if (updsql === true)
        {
          let data = {coldef:record,sleutel:sleutel,colid:'',type:'displayedColumnsChanged'};
          SqlFunction.DoSql('UpdKolInstUser',data,this.props);   
        } 
    }

    if (waarde.type === 'Reset')
    {
        let data = {coldef:[],sleutel:sleutel,colid:'',type:'Reset'};
        await SqlFunction.DoSql('UpdKolInstUser',data,this.props);
        data = this.props.sql(sleutel);
        this.setState({displayedColumnsChanged:0})      
    }

    if (waarde.type === 'columnRowGroupChanged' && waarde.columns)
    {
        let colDef = waarde.columns;

        record = [];
        for (i = 0; i < colDef.length; i++) 
        {
          let rij = colDef[i];
          let zetrec = {colId:rij.colDef.field, group:i+1}
          record.push(zetrec);
        }
        let data = {coldef:record,sleutel:sleutel,colid:'',type:'RowGroupChanged'};
        SqlFunction.DoSql('UpdKolInstUser',data,this.props);
        this.setState({displayedColumnsChanged:0}) 
        if(this.props.updateCheckboxes){
          let rowdata = [];
          this.gridApi.forEachNode(node => rowdata.push(node))

          this.props.handleCheckboxes(rowdata)
        }
    } 
  }

  async onBtExport() {
    var params = {
      fileName: this.props.fileName
    };
    if (this.props.infinite === true)
    {
        await this.gridApi.setRowData(this.data);
    }
    this.gridApi.exportDataAsExcel(params);
  }  

  componentDidUpdate(prevProps) {
    if (this.props.exportExcel !== prevProps.exportExcel) {
        this.onBtExport();
    } 
  }  

  onRowSelected(event) {
  }
  onSelectionChanged(event) {
  }

  onFirstDataRendered(params) {



      if (this.props.sizetofit)
      {
        params.api.sizeColumnsToFit();
      }
    setTimeout(function() {
      params.api.getDisplayedRowAtIndex(0).setExpanded(true);
    }, 0);
    if(this.props.updateColoring){
      this.props.handleRowColoring(this.gridApi);
    } 
    if(this.props.updateCheckboxes){
      let rowdata = [];
      this.gridApi.forEachNode(node => rowdata.push(node))

      this.props.handleCheckboxes(rowdata)
    }
  }

  async sluit(params, index)
  {
    var someRow = null;
    let totdata = this.gridApi.rowModel.rowsToDisplay;
    for (let i=0; i < totdata.length; i++) 
    {
      let row = totdata[i];
      if (row.data[this.props.masterfield] === expandedmaster)
      {
        if (row.rowIndex !== undefined)
        {
          someRow = this.gridApi.getDisplayedRowAtIndex(i);
          if (someRow) someRow.setExpanded(false);
        }
      }
      if (row.data[this.props.masterfield] === params.data[this.props.masterfield])
      {
        if (row.rowIndex !== undefined)
        {
          someRow = this.gridApi.getDisplayedRowAtIndex(row.rowIndex);
          if (someRow) someRow.setExpanded(true);
        }
      }

    }
    expandedindex = params.rowIndex;
    expandedmaster = params.data[this.props.masterfield];
  }

  async onRowGroupOpened(params, index) { 
    if (params.node.expanded === true && expandedmaster !== params.data[this.props.masterfield])
    {
      this.sluit(params, index)
    }
  }


  render() {
    const {tableheight, columns, data, sleutel, sql, filter, sortable,suppressColumnVirtualisation,enableRowGroup,
      getContextMenuItems, headerComponentParams, groupDefaultExpanded, autoGroupColumnDef,pinnedTopRowData,editable,masterDetail,infinite} = this.props;
    const {conditie} = this.state;

    let dsortable = sortable;
    let that = this;
    let dfilter = filter;
    let dsuppressColumnVirtualisation = suppressColumnVirtualisation;
    let denableRowGroup = enableRowGroup;
    let dgroupDefaultExpanded = groupDefaultExpanded;
    if (sortable !== false) {dsortable = true}
    if (filter !== false) {dfilter = true}
    if (suppressColumnVirtualisation !== true) {dsuppressColumnVirtualisation = false}
    if (enableRowGroup !== false) {denableRowGroup = true}
    if (!groupDefaultExpanded) {dgroupDefaultExpanded = 0}
    let detailcolumns = GetDetailcolumnsAg(columns, sleutel);
    let detailcellrender = detailcolumns.length > 0 ?
                      { detailGridOptions: {
                        columnDefs: detailcolumns,
                        RowClickedEvent: function(column, colDef, value) {that.props.RowClickedEvent(column,colDef, value)},
                        onCellClicked: function(column, colDef, value) {that.props.CellClickedEvent(column,colDef, value)},
                        onColumnResized: function(column) {that.ColumnChange(column,sleutel,sql)}, 
                        onColumnMoved: function(column) {that.ColumnChange(column,sleutel,sql)}, 
                        onDisplayedColumnsChanged:function(column) {that.ColumnChange(column,sleutel,sql)}, 
                        frameworkComponents:{
                          editstring:GridEditString,
                          editdatetime:GridEditDateTime,
                          editautoselect:GridEditAutoSelect,
                          editselect:GridEditSelect,
                          editcheck:GridEditCheck,
                          rendercheck:RenderCheck,
                          renderbar:RenderBar,
                          editsave:GridEditSave,
                          Bewaren:Bewaren,
                          Verwijderen:Verwijderen,
                          PrintPdf:PrintPdf,
                          PrintBrut:PrintBrut,
                          PrintMail:PrintMail,
                          Copieren:Copieren,
                          Uploaden:Uploaden,
                          LocNumb:LocNumb,
                          LocDate:LocDate,
                          ValidString:ValidString,
                          DispSelCell:DispSelCell,
                          },
                        },
                        getDetailRowData: function(params) {
                          params.successCallback(params.data.children);
                        }
                     } : null;
    if (infinite === true)
    {
    return (
              <div
                className="ag-theme-material"
                style={{
                height: tableheight,
                width: '100%' }}
              >
                <AgGridReact

                  rowModelType={'infinite'}
                  paginationPageSize={10}
                  cacheBlockSize={25}
                  cacheOverflowSize={15}
                  maxConcurrentDatasourceRequests={1}
                  infiniteInitialRowCount={1}
                  maxBlocksInCache={3}
                  blockLoadDebounceMillis = {100}
                  overlayLoadingTemplate = {'<img src=" ../server/loading.gif">'}
                  components = {{
                    loadingRenderer: function (params) {
                      if (params.value !== undefined ) {
                        return params.value;
                      } else {
                        if (gridapi.laden === true)
                        {
                          return '<img src=" ../server/loading.gif">';
                        }
                        else
                        {
                           return null;
                        }
                      }
                    },
                  }}

                  
                  conditie={this.state ? this.state.conditie : {}}
                  columnDefs={ZetcolumnsAg(columns,sleutel,this.props,conditie)}
                  onRowGroupOpened={(masterDetail === true) ? (params) => this.onRowGroupOpened(params,expandedindex) : null}
                  animateRows={true}
                  exportExcel = {true}
                  editable={editable}
                  masterDetail={masterDetail}
                  detailCellRendererParams={detailcellrender}                  
                  floatingFilter={dfilter}
                  filter={dfilter}
                  suppressColumnVirtualisation={true}
                  autoGroupColumnDef={autoGroupColumnDef}
                  getMainMenuItems={(denableRowGroup) ? (params) => ZetMainMenuItems(params,sleutel,this) : null}
                  getRowHeight={(masterDetail === true) ? (params) => getRowHeight(params,) : null}
                  onColumnResized = {(column) => this.ColumnChange(column,sleutel,sql)} 
                  onColumnMoved = {(column) => this.ColumnChange(column,sleutel,sql)} 
                  onColumnRowGroupChanged = {(column) => this.ColumnChange(column,sleutel,sql)} 
                  onDisplayedColumnsChanged = {(column) => this.ColumnChange(column,sleutel,sql)} 
                  onSortChanged = {(column) => this.printSortStateToConsole(column,sleutel,sql)} 
                  onFilterChanged = {(column) => this.filterChange(column,sleutel,sql)} 
                  onFilterModified = {(column) => this.filterChange(column,sleutel,sql)} 

                  getContextMenuItems={getContextMenuItems}
                  groupDefaultExpanded = {dgroupDefaultExpanded}
                  pinnedTopRowData={pinnedTopRowData ? pinnedTopRowData : null}    
                  onCellValueChanged = {(params,value) => this.onCellValueChanged(params,value)} 
                  onCellValueCheck = {(params,value,type) => this.onCellValueCheck(params,value,type)}
                  addRecord = {(params,value) => this.addRecord(params,value)}
                  getFilter = {(params) => this.getFilter(params)}
                  onCellFocusedEvent = {(rowIndex,column, rowPinned, forceBrowserFocus) => this.onCellFocusedEvent(rowIndex,column, rowPinned, forceBrowserFocus)}
                  getmenu = {(params) => this.getmenu(params)} 
                  onGridReady={this.onGridReady}
                  onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                  overlayNoRowsTemplate={null}
                  suppressNoRowsOverlay={true}
                  singleClickEdit = {true}
                  defaultColDef= {{
                    enableValue: false,
                    enableRowGroup: enableRowGroup,
                    enablePivot: false,
                    sortable: dsortable,
                    filter: dfilter,
                    singleClickEdit:true,
                    headerComponentParams : headerComponentParams,
                  }}
                  excelStyles = {[
                    {
                      id: "booleanType",
                      dataType: "boolean"
                    },
                    {
                      id: "stringType",
                      dataType: "string"
                    },
                    {
                      id: "dateType",
                      dataType: "dateTime"
                    }
                  ]}
                  frameworkComponents = {{
                    editstring:GridEditString,
                    editdatetime:GridEditDateTime,
                    editautoselect:GridEditAutoSelect,
                    editselect:GridEditSelect,
                    editcheck:GridEditCheck,
                    editsave:GridEditSave,
                    rendercheck:RenderCheck,
                    renderbar:RenderBar,
                    Bewaren:Bewaren,
                    Verwijderen:Verwijderen,
                    PrintPdf:PrintPdf,
                    PrintMail:PrintMail,
                    PrintBrut:PrintBrut,
                    Copieren:Copieren,
                    Uploaden:Uploaden,
                    LocNumb:LocNumb,
                    LocDate:LocDate,
                    ValidString:ValidString,
                    DispSelCell:DispSelCell,
                    }}
                  onRowSelected={this.onRowSelected.bind(this)}
                  onSelectionChanged={this.onSelectionChanged.bind(this)}
                  sideBar={false}
                  onCellClicked={this.props.CellClickedEvent ? (column, colDef, value) => this.props.CellClickedEvent(column,colDef, value) : null}
                  RowClickedEvent={(column, colDef, value) => this.props.RowClickedEvent(column,colDef, value)}
                  context={{ componentParent: this }}
                  >
                </AgGridReact>
                {this.state.PopUpScreen}
              </div>
          );
    }
    else
    {
      return (
                <div
                  className="ag-theme-material"
                  style={{
                  height: tableheight,
                  width: '100%' }}
                >
                  <AgGridReact
                    columnDefs={ZetcolumnsAg(columns,sleutel,this.props,this.gridApi)}
                    onRowGroupOpened={(masterDetail === true) ? (params) => this.onRowGroupOpened(params,expandedindex) : null}
                    animateRows={true}
                    exportExcel = {true}
                    modules={AllModules}
                    editable={editable}
                    masterDetail={masterDetail}
                    detailCellRendererParams={detailcellrender}                  
                    rowData={data}
                    floatingFilter={dfilter}
                    filter={dfilter}
                    suppressColumnVirtualisation={dsuppressColumnVirtualisation}
                    autoGroupColumnDef={autoGroupColumnDef}
                    getMainMenuItems={(denableRowGroup) ? (params) => ZetMainMenuItems(params,sleutel,this) : null}
                    getRowHeight={(masterDetail === true) ? (params) => getRowHeight(params,) : null}
                    onColumnResized = {(column) => this.ColumnChange(column,sleutel,sql)} 
                    onColumnMoved = {(column) => this.ColumnChange(column,sleutel,sql)} 
                    onColumnRowGroupChanged = {(column) => this.ColumnChange(column,sleutel,sql)} 
                    onDisplayedColumnsChanged = {(column) => this.ColumnChange(column,sleutel,sql)} 
                    onSortChanged = {(column) => this.printSortStateToConsole(column,sleutel,sql)} 
                    getContextMenuItems={getContextMenuItems}
                    groupDefaultExpanded = {dgroupDefaultExpanded}
                    pinnedTopRowData={pinnedTopRowData ? pinnedTopRowData : null}    
                    onCellValueChanged = {(params,value) => this.onCellValueChanged(params,value)} 
                    onCellValueCheck = {(params,value,type) => this.onCellValueCheck(params,value,type)}
                    addRecord = {(params,value) => this.addRecord(params,value)}
                    getFilter = {(params) => this.getFilter(params)}
                    onCellFocusedEvent = {(rowIndex,column, rowPinned, forceBrowserFocus) => this.onCellFocusedEvent(rowIndex,column, rowPinned, forceBrowserFocus)}
                    getmenu = {(params) => this.getmenu(params)} 
                    onGridReady={this.onGridReady}
                    onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                    overlayNoRowsTemplate={null}
                    suppressNoRowsOverlay={true}
                    singleClickEdit = {true}
                    defaultColDef= {{
                      enableValue: false,
                      enableRowGroup: enableRowGroup,
                      enablePivot: false,
                      sortable: dsortable,
                      filter: dfilter,
                      singleClickEdit:true,
                      headerComponentParams : headerComponentParams,
                    }}
                    excelStyles = {[
                      {
                        id: "booleanType",
                        dataType: "boolean"
                      },
                      {
                        id: "stringType",
                        dataType: "string"
                      },
                      {
                        id: "dateType",
                        dataType: "dateTime"
                      }
                    ]}
                    frameworkComponents = {{
                      editstring:GridEditString,
                      editdatetime:GridEditDateTime,
                      editautoselect:GridEditAutoSelect,
                      editselect:GridEditSelect,
                      editcheck:GridEditCheck,
                      editsave:GridEditSave,
                      rendercheck:RenderCheck,
                      renderbar:RenderBar,
                      Bewaren:Bewaren,
                      Verwijderen:Verwijderen,
                      PrintPdf:PrintPdf,
                      PrintMail:PrintMail,
                      PrintBrut:PrintBrut,
                      Copieren:Copieren,
                      Uploaden:Uploaden,
                      LocNumb:LocNumb,
                      LocDate:LocDate,
                      ValidString:ValidString,
                      DispSelCell:DispSelCell,
                      }}
                    onRowSelected={this.onRowSelected.bind(this)}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    sideBar={false}
                    onCellClicked={this.props.CellClickedEvent ? (column, colDef, value) => this.props.CellClickedEvent(column,colDef, value) : null}
                    RowClickedEvent={(column, colDef, value) => this.props.RowClickedEvent(column,colDef, value)}
                    context={{ componentParent: this }}
                    >
                  </AgGridReact>
                  {this.state.PopUpScreen}
                </div>
            );
    }
  }
}
