
import React from "react";
class FileDownload extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit(e) {
    e.preventDefault();
  }

  
  render() {
    const { DocData } = this.props;
    let vld_iframe = (<iframe style={{display: 'none',marginTop:'-10000px'}} id={this.props.frameid} title={this.props.frameid} name={this.props.frameid} />);
    let vld_document = (<form id={this.props.formid} action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target={this.props.frameid} style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='getFile' id="function" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={DocData && DocData.id} id="id" name="id"/>
        </form>);  

    return (
      <div style={{display:'none'}}>
        {vld_iframe}
        {vld_document}
      </div>
    );
  }
}
export default FileDownload;
