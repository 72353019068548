import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Validation from "../../functions/validation.js";
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";

var gridapi = null;
var rowpinnend = null;

class PopJobEditDouane extends React.Component {
    state = {
        data: this.props.data,
        kol: this.props.kol,
        filename:'',
        save:false,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        nivo: (this.props.nivo) ? this.props.nivo : 0,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.CellCheck = this.CellCheck.bind(this);
        this.CheckSaveBoetes = this.CheckSaveBoetes.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
    }

    onGridReady(params)
    {
      gridapi = params;
      let rij = [{id:0,job_id:0,nummerplaat:'',datum:'',bedrag:'',save:'',verwijder:''}]        
      gridapi.api.updateRowData({ add: rij})
      var lastrow = gridapi.api.getLastDisplayedRow();
                    gridapi.api.setFocusedCell(lastrow, 'nummerplaat',);  
                    gridapi.api.startEditingCell({
                        rowIndex: lastrow,
                        colKey: 'nummerplaat',

                    });    

    }
    async CellClick(waarde) {
        var data = waarde.data
        let column = waarde.column.colDef.field;
        let voegtoe = false;
        var lastrow =  gridapi.api.getLastDisplayedRow();
        var params = {force: true,};

        if (column === 'save')
        {
            if (data.save === true) 
            {
                if (data.id === 0) {voegtoe = true; rowpinnend = null}

                data.job_id = this.props.jobid;
                data.verwijder = '2';
                await SqlFunction.DoSql('ChgJobDouanes',data);
                waarde.data.save='';
                data.save = '';
                waarde.node.setData(waarde.data);        

                await gridapi.api.forEachNode(function(node) { 
                    if (node.data.save === true)
                    {
                        node.data.verwijder = '2';
                        node.data.save = '';
                        SqlFunction.DoSql('ChgJobDouanes',node.data);
                    }
                });   
                gridapi.api.stopEditing();
                gridapi.api.refreshCells(params)                    
                if (voegtoe === true)
                {
                    let rij = [{id:0,job_id:0,nummerplaat:'',datum:'',bedrag:'',save:'',verwijder:''}]        
                    gridapi.api.updateRowData({ add: rij})
                    lastrow = gridapi.api.getLastDisplayedRow();
                }
                else
                {
                    lastrow = waarde.rowIndex + 1;
                }
                gridapi.api.setFocusedCell(lastrow, 'nummerplaat',);  
                gridapi.api.startEditingCell({
                    rowIndex: lastrow,
                    colKey: 'nummerplaat',
                });    
            }
        }

        if (column === 'verwijder')
        {
            await SqlFunction.DoSql('DelJobDouanes',data);
            gridapi.api.updateRowData({ remove: [waarde.data]})
            if (rowpinnend && rowpinnend.save === true)
            {
                rowpinnend.job_id = this.props.jobid;
                rowpinnend.verwijder = '2';
                await SqlFunction.DoSql('ChgJobDouanes',rowpinnend);
                rowpinnend = null;
            }
            await gridapi.api.forEachNode(function(node) { 
                if (node.data.save === true)
                {
                    node.data.verwijder = '2';
                    node.data.save = '';
                    SqlFunction.DoSql('ChgJobDouanes',node.data);
                }
            });   
            gridapi.api.refreshCells(params)                    
            rowpinnend = null;
        }
        return;
    }

    async CellCheck(params,waarde) {
        var colId = params.props.column.getId();

        let terug = false
        let bedrag = 0
        let wbedrag = 0;
        if (params.props.data.bedrag) 
        {
            if (params.props.data.bedrag.constructor === String)
            {
                bedrag = parseFloat(params.props.data.bedrag);           
                if (isNaN(bedrag)) {bedrag = 0}
            }
            else
            {
                bedrag = params.props.data.bedrag;           
            }
        }
        if (colId === 'bedrag')
        {
            wbedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(wbedrag)) {wbedrag = 0}
        }    
        if (colId === 'datum')
        {
            params.props.data.datum = waarde; 
        }
        if (params.props.data.bedrag > 0 && params.props.data.nummerplaat.length > 1 )
        {
            params.props.data.save = true;
        }
        else
        {
            params.props.data.save = ''; 
        }
        if (params.props.node.rowPinned === 'top')
        {
            rowpinnend = params.props.data;
            rowpinnend.soort = 'BOETES' 
        }
        await params.setState({error:terug})
        await this.CheckSaveBoetes(params);
    }       



    componentDidMount(){
        let onkostenkol = this.props.kol
        for (var i = 0; i < onkostenkol.length; i++) 
        { 
          let rij = onkostenkol[i];
          if (rij.field !== 'verwijder' && rij.field !== 'totaal')
          {
            rij.editable = true
          }
          switch (rij.field) 
            {
              case 'save':
              case 'verwijder':

                rij.width = 65
                break;

              case 'totaal':
              case 'bedrag':
                rij.width = 165
                break;

              case 'datum':
                rij.width = 100
                break;

              case 'nummerplaat':
                rij.width = 200
                break;

              default:    
                break;
            }           
          onkostenkol[i] = rij;
        }
        this.setState({data:this.props.data, kol:onkostenkol})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
        let jobid = this.props.jobid
        await gridapi.api.forEachNode(function(node) { 
            if (node.data.save === true)
            {
                node.data.job_id = jobid;
                node.data.verwijder = '2';
                SqlFunction.DoSql('ChgJobDouanes',node.data);
            }
        });   

        if (this.props.close) 
        {
            this.props.close()
        }
    }

    CheckSaveBoetes(params) {
      let terug = false
      if (params.props.data.bedrag > 0 && params.props.data.nummerplaat.length > 4 && params.props.data.datum.length > 4)
      {
          terug = false;
      }
      else
      {
          terug = true;
      }
      if (terug === false)
      {
          terug = Validation.ValidCheck('JobBoetes', params.props.data.datum, 'datum', params, gridapi);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobBoetes', params.props.data.nummerplaat, 'nummerplaat', params, gridapi);
      }
      if (terug === true)
      {
          params.props.data.save = false;
      }
      else
      {
        params.props.data.save = true;
      }
    }




    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var tableheight = Math.floor(window.innerHeight * .60) + 'px';
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : top;

        return (

            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'absolute'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                  <Grid container style={{width: '100%', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                    <Grid item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight={tableheight}
                        columns={this.state.kol}
                        data={this.state.data} 
                        sortable={false}
                        sizetofit={true}
                        suppressMenu={true}
                        enableRowGroup={false}
                        suppressColumnVirtualisation={true}
                        CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                        CellValueCheck={(params,value,error) => this.CellCheck(params,value,error)}
                        filter={false}
                        headerComponentParams = {null}
                        editable={true}
                        sleutel="JobDouanes"      
                        GridReady={this.onGridReady}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  <Button color="primary"  onClick={() => this.handleSave()}>
                    Bewaren
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopJobEditDouane.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobEditDouane)));

