import React from 'react';
import GenFunc from "../functions/gen_functions.js";
import Datetime from "react-datetime";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import styles from "../assets/components/customInputStyle.jsx";

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Clear from "@material-ui/icons/Exclamation";
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';


interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}
 

class EditDateTime extends React.Component {
    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.toggleCalendar = this.toggleCalendar.bind(this);
        this.renderInputComponent = this.renderInputComponent.bind(this);
        this.NumberFormatCustom = this.NumberFormatCustom.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
         this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);

        this.inputid = GenFunc.MakeId()
        this.state = {
            value:this.props.value,
            inputid:this.inputid,
            focus: false,
            calopen:false,
            display:'none',
            oldValue: '',
            error:false,
            checkval:false,
        }
    }


    

    handleKeyPress(event) {
      if(event.key === 'Tab' || event.key === 'Enter')
      {
        this.props.ValueCheck(this,this.state.value,"ENTER")
      }
    }


  componentDidMount() {

    if (this.props.setError) {
      this.props.setError(this, this.props.value, 'STRING')
    }
  }
 async componentWillReceiveProps(nextProps, nextState) 
    {
      if (this.state.value !== nextProps.value)
      {
        this.setState({value:nextProps.value})
      }
      if (nextProps.checkval === true)
      {
        this.props.ValueCheck(this,this.state.value,'STRING')
      }

    }

    NumberFormatCustom(props: NumberFormatCustomProps) {
      const { inputRef,onChange, ...other } = props;
      return (
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={values => {
            onChange({
              target: {
                value: values.formattedValue,
              },
            });
          }}         
          format='##-##-####'
          showmask = "false"
        />
      );
    }     
    
    

    renderInputComponent(inputProps) 
    {
      const {
        classes,
        formControlProps,
        labelText,
        id,
        labelProps,
        helperText,      
      } = this.props;


      const labelClasses = classNames({
        [" " + classes.labelRootError]: this.state.error,
        [" " + classes.labelRootSuccess]: this.state.success && !this.state.error
      });
      var underlineClasses = classNames({
        [classes.brutUnderline]: !this.state.error,
        [classes.underlineError]: this.state.error,
        [classes.underline]: true,
      });
      if (this.state.error)
      {
          underlineClasses = classNames(
            classes.underlineError);

      }
      const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: this.state.white
      });
      var formControlClasses;
      if (formControlProps !== undefined) {
        formControlClasses = classNames(
          formControlProps.className,
          classes.formControl
        );
      } else {
        formControlClasses = classes.formControl;
      }
      var helpTextClasses = classNames({
        [classes.labelRootError]: this.state.error,
        [classes.labelRootSuccess]: this.state.success && !this.state.error
      });     

      return (
        <FormControl onKeyDown={this.handleKeyPress} style={{position:'relative',marginBottom:'15px', width:'100%'}} {...formControlProps}  className={formControlClasses}>
          {labelText !== undefined ? (
            <InputLabel
              style={{top:'8px'}}
              className={classes.labelRoot + " " + labelClasses}
              htmlFor={id}
              {...labelProps}
            >
              {labelText}
            </InputLabel>
          ) : null}

          <Input
            classes={{
              input: inputClasses,
              disabled: classes.disabled,
              underline: underlineClasses
            }}            
            
            inputComponent={this.NumberFormatCustom}      
            inputProps={{
              ...inputProps,
              id:this.inputid,
            }}        


          />

          {this.state.error ? (
            <Clear className={classes.feedback + " " + classes.labelRootError} />
          ) : this.state.error ? (
            <Clear className={classes.feedback + " " + classes.labelRootError} />
          ) : null}

          {helperText !== undefined ? (
            <FormHelperText id={id + "-text"} className={helpTextClasses}>
              {helperText}
            </FormHelperText>
          ) : null}
        </FormControl>        

      )
    }



    change(event, valid, stateName) 
    {
     let state = this.state;
     state.calopen = false;
      if (event._isAMomentObject)
      {
        let waarde = event.format('DD-MM-YYYY');
        this.setState({value:waarde})
        this.props.ValueCheck(this,waarde,'STRING')
      }
      else
      {
        this.setState({value:event})
        this.props.ValueCheck(this,event,'STRING')
      }
      this.setState({calopen: false});
    }
    handleFocus() {
      this.setState({ focus: true, display:'block' });
    }  
    handleBlur(event) {
        setTimeout(() => {      this.setState({ focus: false, display:'none' });
       }, 0);         

    }
    toggleCalendar() {
      const newCalendarState = !this.state.calopen;
      this.setState({
        calopen: newCalendarState
      });
    }
    render() {
    const [{ id }, { value }] = [this.props, this.state];
      return (
        <div onFocus={this.handleFocus} onBlur={this.handleBlur}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid style={{width: 'calc(100% - 40px)'}} item>
              <Datetime
                timeFormat={false}
                style={this.props.calstyle ? this.props.calstyle : {width:'100px'}}
                defaultValue={value}
                value={value}
                input={true}
                onKeyDown={this.handleKeyPress}
                open={this.state.calopen}
                onChange={this.change}
                renderInput={this.renderInputComponent}
                locale={'nl-be'}
                dateFormat={"DD-MM-YYYY"}
                id={id}
              />
            </Grid>
            <Grid item>
              <IconButton onClick ={this.toggleCalendar} style= {{marginTop: '-40px',marginLeft: '-20px'}} aria-label="toggle calendar selection visibility">
                <CalendarToday/>
              </IconButton>
            </Grid>
          </Grid>
        </div>
      );      
    }
}

export default withStyles(styles)(EditDateTime);
