import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";

class PopJobContrAanv extends React.Component {
    state = {
        data: this.props.data,
        kol: this.props.kol,
        filename:'',
        save:false,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        nivo: (this.props.nivo) ? this.props.nivo : 0,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.CellCheck = this.CellCheck.bind(this);

    }

    async CellClick(waarde) {
      return;
    }
    CellCheck(params,waarde) {
      var colId = params.props.column.getId();
      if (colId === 'checked')
      {
        params.props.data.checked =  waarde
      }
    }       


    componentDidMount(){
        this.setState({data:this.props.data, kol:this.props.kol})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens
      let chgdata = {data:this.state.data, mainid:this.props.mainid}
      await SqlFunction.DoSql('ChgContrAanvr',chgdata);
      if (this.props.close) 
      {
        this.props.close()
      }
    }




    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var tableheight = Math.floor(window.innerHeight * .60) + 'px';
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : top;

        return (

            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'absolute'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                  <Grid container style={{width: '98%', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                    <Grid item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight={tableheight}
                        columns={this.state.kol}
                        data={this.state.data} 
                        sortable={true}
                        sizetofit={false}
                        suppressMenu={false}
                        enableRowGroup={false}
                        suppressColumnVirtualisation={false}
                        CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                        CellValueCheck={(params,value,error) => this.CellCheck(params,value,error)}
                        filter={true}
                        headerComponentParams = {null}
                        editable={true}
                        sleutel='OpenContrAang'      
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  <Button color="primary"  onClick={() => this.handleSave()}>
                    Koppelen
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopJobContrAanv.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobContrAanv)));

