import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import EditCheck from "../../components/editcheck.jsx";
import { Editor } from '@tinymce/tinymce-react';
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import moment from 'moment'
import 'moment/locale/nl-be'

class PopActivity extends React.Component {
    state = {
        data: this.props.activity && this.props.activity.MainData && this.props.activity.MainData.data ? this.props.activity.MainData.data : {}, 
        filename:'',
        editorState:'',
        editorStateRem:'',
        save:false,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleCloseAct = this.handleCloseAct.bind(this);
        this.SaveCheck = this.SaveCheck.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.onEditorStateChangeRem = this.onEditorStateChangeRem.bind(this);
    }
    async componentDidMount(){
        if (this.props.activity && this.props.activity.MainData && this.props.activity.MainData.data)
        {
          let data = this.props.activity.MainData.data;
          let edit = false;
          if (data.id === 0) 
          {
            data.userfrom = this.props.user.userData.id;
            data.userfrom_name = this.props.user.userData.name;
            data.userto_name = '';
            data.crea_date = GenFunc.Get_Vandaag();
            data.due_date = '';
            data.status = '1';
            data.newto = '1'; 
            data.newfrom = '0'; 
            edit = true;
          }
          else
          {
            if (this.props.user.userData.id === data.userto )
            {
              if (!data.viewon)
              {
                data.viewon = GenFunc.Get_Vandaag(); 
                data.newfrom = '0'; 
                data.newto = '0'; 
              }
              else
              {
                if (data.viewon && data.viewon.length < 8)
                {
                  data.viewon = GenFunc.Get_Vandaag(); 
                  data.newfrom = '0'; 
                  data.newto = '0'; 
                }
              }
            }
            if (this.props.user.userData.id === data.userfrom )
            {
              data.newfrom = '0'; 
            }
            await SqlFunction.DoSql('ChgActivity',data);
          }
          let wsave = await this.SaveCheck(data);
          this.setState({data:data, edit:edit, save:wsave,editorState:data.description,editorStateRem:data.remarks})
        }
    }
    async onEditorStateChange(content, editor) {
      let data = this.state.data
      data.description = content 
      let wsave = await this.SaveCheck(data);
      this.setState({editorState:content, save:wsave});
    }

    async onEditorStateChangeRem(content, editor) {
      let data = this.state.data
      data.remarks = content 
      this.setState({editorStateRem:content});
    }


    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens
      let data = this.state.data;
      let terdata = await SqlFunction.DoSql('ChgActivity',data);
      if (this.props.close) 
      {
        this.props.close(terdata)
      }
    }

    async handleCloseAct(){
      // Bewaren gegevens
      let data = this.state.data;
      data.status = '9';
      data.closedon = GenFunc.Get_Vandaag();
      data.newfrom = ''; 
      data.newto = ''; 
      let terdata = await SqlFunction.DoSql('ChgActivity',data);
      if (this.props.close) 
      {
        this.props.close(terdata)
      }
    }


    SaveCheck(data) 
    {
      let chsave = false;
      if (data.userto.length < 4 )
      {
        chsave = true;
      }
      if (data.titel.length < 1 )
      {
        chsave = true;
      }
      if (data.description.length < 4 )
      {
        chsave = true;
      }
      return chsave
    }


    async ValueCheck(that,waarde,type,colId,verplicht) 
    {
      let data = this.state.data;
      switch (colId) 
      {
        case 'userto_name':
        case 'userto':
          if (waarde.id && waarde.value)
          {
            data.userto_name = waarde.value;
            data.userto = waarde.id;
          }
          else
          {
            data.userto_name = ''
            data.userto = ''
          }
          if(waarde && waarde.id && waarde.id.length > 2) 
          {
            that.setState({error:false});
          }
          else
          {
            that.setState({error:true})
          }
          break;
        case 'done':
          if (waarde === true) {
            data.done_date = GenFunc.Get_Vandaag();
            data.newfrom = '1'; 
          }
          if (waarde === false) {
            data.done_date = ''
            data.newfrom = '0'; 
          }

          break;
        case 'due_date':
          var utc = moment(waarde, "DD-MM-YYYY", true)
          if(!utc.isValid()) {that.setState({error:true})} else {that.setState({error:false})}
          data[colId] = waarde;
          break;

        case 'titel':
          if(waarde && waarde.length < 1) 
          {
            that.setState({error:true})
          }
          else
          {
            that.setState({error:false});
          }
          data.titel = waarde;
          break;

        default:    
          data[colId] = waarde;
          break;
      } 
      let wsave = await this.SaveCheck(data);
      this.setState({data:data,save:wsave})
    }       

    async ErrorCheck(that,waarde,type,colId,verplicht) 
    {
      switch (colId) 
      {
        case 'titel':
        case 'userto':
        case 'userto_name':
          if(waarde && waarde.length > 1) 
          {
            that.setState({error:false});
          }
          else
          {
            that.setState({error:true})
          }
          break;

        default:    
          break;
      } 
    }       


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        let tableheight = Math.floor(window.innerHeight * .40)        
        let donecheck = false;
        if (this.state.data && this.state.data.done_date && this.state.data.done_date.length > 5)
        {
            donecheck = true
        }

        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal100
                }}

                open={open}
                fullWidth={true}
                onClose={this.handleClose}
                style={{overflow: "visible",zIndex:'1001',width:'100vw'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                maxWidth='xl'
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px', width: '100%', height: '80vh'}}  className={classes.blockContent}>
                  <Grid container spacing={1}>
                    <Grid container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                        {(this.props.user.userData.id === this.state.data.userfrom) && ((!this.state.data.viewon) || (this.state.data.viewon && this.state.data.viewon.length < 4) ) ? 
                          (
                            <div>
                              <Grid item sm={10} md={10} lg={10}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.titel}
                                  coltype = 'STR'                        
                                  labelText="Titel"
                                  setError={(params,value,type) => this.ErrorCheck(params,value,type,'titel')}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'titel')}
                                />
                              </Grid>
                              <Grid item sm={10} md={10} lg={10}>
                                <EditDateTime className="card-title m-t-10"
                                  value={this.state.data.due_date}
                                  coltype = 'STR'                        
                                  labelText="Deadline uitvoering"
                                  inputProps={{value:this.state.data.due_date}}
                                  formControlProps={{fullWidth: true}}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'due_date')}
                                />
                              </Grid>


                              <Grid item sm={10} md={10} lg={10}>
                                <EditAutoSelect className="card-title m-t-10"
                                  value={this.state.data.userto_name}
                                  labelText="Bestemd voor"
                                  lijst='AllUsers'
                                  colId='userto_name'
                                  selectcel='userto'
                                  id={this.state.data.userto}
                                  setError={(params,value,type) => this.ErrorCheck(params,value,type,'userto_name')}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'userto_name')}
                                />
                              </Grid>
                            </div>

                          ) : 
                          (
                            <div>
                              <Grid item sm={10} md={10} lg={10}>
                                <h4>{this.state.data.titel}</h4> 
                                <small className="text-muted p-t-30 db">Deadline uitvoering</small>                  
                                <h6>{this.state.data.due_date}</h6> 
                                <small className="text-muted p-t-30 db">Bestemd voor</small>                  
                                <h6>{this.state.data.userto_name}</h6> 

                              </Grid>
                            </div>
                          )
                        }
                      </Grid>
                      <Grid item sm={6} md={6} lg={6}>
                        <Grid container spacing={1}>
                          <Grid item sm={6} md={6} lg={6}>
                            <small className="text-muted p-t-30 db">Aangemaakt door</small>                  
                            <h6>{this.state.data.userfrom_name}</h6> 
                          </Grid>
                          <Grid item sm={6} md={6} lg={6}>
                            <small className="text-muted p-t-30 db">Aangemaakt op</small>                  
                            <h6>{this.state.data.crea_date}</h6> 
                            {this.state.data && this.state.data.viewon && this.state.data.viewon.length > 5 &&
                              <div>
                                <small className="text-muted p-t-30 db">Gezien op</small>                  
                                <h6>{this.state.data.viewon}</h6> 
                              </div>
                            }
                            {this.state.data && this.state.data.done_date && this.state.data.done_date.length > 5 &&
                              <div>
                                <small className="text-muted p-t-30 db">Uitgevoerd op</small>                  
                                <h6>{this.state.data.done_date}</h6> 
                              </div>
                            }
                            {this.state.data && this.state.data.closedon && this.state.data.closedon.length > 5 &&
                              <div>
                                <small className="text-muted p-t-30 db">Afgesloten op</small>                  
                                <h6>{this.state.data.closedon}</h6> 
                              </div>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    {(this.props.user.userData.id === this.state.data.userfrom) && ((!this.state.data.viewon) || (this.state.data.viewon && this.state.data.viewon.length < 4) ) 
                     && ((!this.state.data.closedon) || (this.state.data.closedon && this.state.data.closedon.length < 4)) ? 
                    (
                      <div style={{width: '99%'}}>
                        <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                        <h4>Vraag</h4> 
                        <Grid item sm={12} md={12} lg={12}>
                          <Editor
                            onEditorChange={this.onEditorStateChange}      
                            init={{
                              height:tableheight,
                              language: 'nl',
                              plugins: [
                              'advlist autolink lists link image charmap print preview',
                              'searchreplace visualblocks',
                              'insertdatetime table paste'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | removeformat',
                            }}
                            value={this.state.editorState}
                          />                
                        </Grid>
                      </div>
                    ) :
                    (
                      <div style={{width: '99%'}}>
                        <Grid item sm={12} md={12} lg={12}>
                          <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                          <h4>Vraag</h4> 
                          <div
                            dangerouslySetInnerHTML={{ __html: this.state.data.description }}
                          />                
                          <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                        </Grid>
                      </div>
                    )
                    }

                    {(this.props.user.userData.id === this.state.data.userto) && ((!this.state.data.closedon) || (this.state.data.closedon && this.state.data.closedon.length < 4)) 
                      && ((!this.state.data.done_date) || (this.state.data.done_date && this.state.data.done_date.length < 4)) ? 
                    (
                      <div style={{width: '99%'}}>
                        <Grid item sm={12} md={12} lg={12}>
                          <Grid container spacing={1}>
                              <Grid item sm={4} md={4} lg={4}>
                                  <h4>Antwoord</h4> 
                              </Grid>
                              <Grid item sm={8} md={8} lg={8}>
                                 <EditCheck Objstyle={{marginLeft:'50px',marginRight:'0px',width:'100%',marginTop: '-10px'}} className="card-title m-t-10" value={donecheck}
                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'done')}
                                    labelPlacement="start"
                                    labelText='Uitgevoerd'                          
                                  />
                              </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                          <Editor
                            onEditorChange={this.onEditorStateChangeRem}      
                            init={{
                              height:tableheight,
                              language: 'nl',
                              plugins: [
                              'advlist autolink lists link image charmap print preview',
                              'searchreplace visualblocks',
                              'insertdatetime table paste'
                            ],
                            toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | removeformat',
                            }}
                            value={this.state.editorStateRem}
                          />                
                          <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                        </Grid>
                      </div>
                    ) :
                    (
                      <div style={{width: '99%'}}>
                        <Grid item sm={12} md={12} lg={12}>
                          <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                          <h4>Antwoord</h4> 
                          {this.state.data && this.state.data.remarks &&
                          <div
                            dangerouslySetInnerHTML={{ __html: this.state.data.remarks }}
                          />
                          }                
                          <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                        </Grid>
                      </div>
                    )
                    }
                  </Grid>
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  {(this.props.user.userData.id === this.state.data.userfrom && this.state.data && this.state.data.id !== 0) && ((!this.state.data.closedon) || (this.state.data.closedon && this.state.data.closedon.length < 4)) ? 
                  (                
                      <Button color="primary" onClick={() => this.handleCloseAct()}>
                        Afsluiten
                      </Button>
                  ) : null
                  }
                  {((!this.state.data.closedon) || (this.state.data.closedon && this.state.data.closedon.length < 4)) ? 
                  (                
                    <Button color="primary" disabled={this.state.save} onClick={() => this.handleSave()}>
                      Bewaren
                    </Button>
                  ) : null }

                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
  ...state
});


PopActivity.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopActivity)));

