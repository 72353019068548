
const customViewStyle = {
 root: {
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },

  speedDial: {
    zIndex:'15',
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: '10px',
      right: '10px',
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: '10px',
      left: '10px',
    },
  },
  speedDialToolb: {
    zIndex:'15',
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      paddingTop: '10px',
      right: '10px',
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      paddingTop: '10px',
      left: '10px',
    },
  },
  subspeedDialToolb: {
    zIndex:'10',
    position: 'fixed',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      paddingTop: '10px',
      right: '75px',
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      paddingTop: '10px',
      left: '75px',
    },
  },

  speedFabRoot: {
    width: '37px',
    height: '37px',

    '&.MuiFab-primary': {
      color: "#fff",
      backgroundColor: '#8898aa',
    },

  },

  subspeedDial: {
    zIndex:'10',
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: '10px',
      right: '75px',
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: '10px',
      left: '75px',
    },
  },

 stepIcon: {
  '&.MuiStepIcon-root.MuiStepIcon-completed': {
      color: '#4caf50 !important'},
},
  subspeedFabRoot: {
    width: '37px',
    height: '37px',

    '&.MuiFab-primary': {
      color: "#fff",
      backgroundColor: '#fb9678',
    },

  },

  
};

export default customViewStyle;
