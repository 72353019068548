import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar'

import {GetUser} from "../../../redux/settings/userActions";
import {GetAccount} from "../../../redux/settings/accountActions";
import {GetContact} from "../../../redux/settings/contactActions";
import {GetLand, GetOnkostenLand, AllTaalcodes, GetTaalcode, GetFactuuropbouw, AllLanden} from "../../../redux/settings/landActions";
import {GetTemplate} from "../../../redux/settings/templateActions";
import {GetRegistration} from "../../../redux/settings/registrationActions";
import { GetSupplier } from '../../../redux/settings/supplierActions.js';
import {GetMainIvat } from '../../../redux/settings/paramActions.js';


import {GetBetaalFin,GetJobMain, AllJobDefMain, GetVatRefCode} from "../../../redux/settings/jobsActions";
import {setSubTitle,resetview,zetpopup} from "../../../redux/settings/action";
import TableGrid from "../../../components/TableGrid/TableGrid.jsx";
import { GetKantoor, GetKantContact, GetContKantoor } from '../../../redux/settings/kantoorActions';


const mapStateToProps = state => ({
    ...state
});

class Customizer extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.GetCol = this.GetCol.bind(this);
    }
    componentDidMount() {
        window.addEventListener("load", this.defaultSettings);
    }

    async CellClick(waarde) {
        var data = waarde.data
        this.toggle()
        this.props.dispatch(resetview())
        this.props.dispatch(zetpopup(null));
        switch (this.props.settings.right_title) 
        {
            case 'Gebruikers':
                this.props.dispatch(setSubTitle('Gebruiker'))
                await this.props.dispatch(GetUser(data.id))
                break;

            case 'Registraties':
                this.props.dispatch(setSubTitle('Registratie'))
                await this.props.dispatch(GetRegistration(data.id))
                break;


            case 'Klanten':
                this.props.dispatch(setSubTitle('Klantenfiche'))
                await this.props.dispatch(GetAccount(data.id))
                break;

            case 'IvatCode':
                this.props.dispatch(setSubTitle('IvatCode'))
                await this.props.dispatch(GetMainIvat(data.id))
                break;


            case 'Templates':
                this.props.dispatch(setSubTitle('Template'))
                await this.props.dispatch(GetTemplate(data.id))
                break;



            case 'Betaallijst Finanz':
                this.props.dispatch(setSubTitle('Betaallijst Finanz'))
                await this.props.dispatch(GetBetaalFin(data.id))
                break;


            case 'Leveranciers':
                this.props.dispatch(setSubTitle('Leverancierfiche'))
                await this.props.dispatch(GetSupplier(data.id))
                break;


            case 'Contactpersonen':
                this.props.dispatch(setSubTitle('Contactpersoon'))
                await this.props.dispatch(GetContact(data.id))
                break;

           case 'Job definities':
                this.props.dispatch(setSubTitle('Job definitie'))
                await this.props.dispatch(GetJobMain(data.id))
                break;

            case 'Kantoren':
                this.props.dispatch(setSubTitle('Kantoren'))
                await this.props.dispatch(GetKantoor(data.id))
                await this.props.dispatch(GetContKantoor(data.id))
                break;

            case 'Vat refund codes':
                this.props.dispatch(setSubTitle('Vat refund codes'))
                await this.props.dispatch(GetVatRefCode(data.id))
                break;

            case 'Kantoor contactpersonen':
                this.props.dispatch(setSubTitle('Kantoor contactpersoon'))
                await this.props.dispatch(GetKantContact(data.id))
                break;

            case 'Landen':
                this.props.dispatch(setSubTitle('Land'))
                await this.props.dispatch(GetLand(data.id))
                await this.props.dispatch(GetOnkostenLand(data.id))
                await this.props.dispatch(AllTaalcodes(this.props))
                break;

            case 'Taalcodes':
                this.props.dispatch(setSubTitle('Taalcode'));
                await this.props.dispatch(GetTaalcode(data.id));
                await this.props.dispatch(GetFactuuropbouw(data.id));
                await this.props.dispatch(AllLanden(this.props));
                await this.props.dispatch(AllJobDefMain(this.props));
                break;



            default:    
                break;
        }     
        return;
    }
    GetCol(waarde) {
        var columns =   this.props.settings.right_title === 'Gebruikers' && this.props.user.AlluserData && this.props.user.AlluserData.kol ? this.props.user.AlluserData.kol : 
                        this.props.settings.right_title === 'Klanten' && this.props.account.AllData && this.props.account.AllData.kol ? this.props.account.AllData.kol :
                        this.props.settings.right_title === 'IvatCode' && this.props.param.AllMainProductCodesData && this.props.param.AllMainProductCodesData.kol ? this.props.param.AllMainProductCodesData.kol :
                        this.props.settings.right_title === 'Leveranciers' && this.props.supplier.AllData && this.props.supplier.AllData.kol ? this.props.supplier.AllData.kol :
                        this.props.settings.right_title === 'Registraties' && this.props.registration.AllRegData && this.props.registration.AllRegData.kol ? this.props.registration.AllRegData.kol :
                        this.props.settings.right_title === 'Contactpersonen' && this.props.contact.AllData && this.props.contact.AllData.kol ? this.props.contact.AllData.kol : 
                        this.props.settings.right_title === 'Kantoren' && this.props.kantoor.AllData && this.props.kantoor.AllData.kol ? this.props.kantoor.AllData.kol :
                        this.props.settings.right_title === 'Landen' && this.props.land.AllData && this.props.land.AllData.kol ? this.props.land.AllData.kol :
                        this.props.settings.right_title === 'Taalcodes' && this.props.land.AllTaalcodesData && this.props.land.AllTaalcodesData.kol? this.props.land.AllTaalcodesData.kol :
                        this.props.settings.right_title === 'Templates' && this.props.template.AllData && this.props.template.AllData.kol ? this.props.template.AllData.kol :
                        this.props.settings.right_title === 'Betaallijst Finanz' && this.props.jobs.AllBetaalFinData && this.props.jobs.AllBetaalFinData.kol ? this.props.jobs.AllBetaalFinData.kol :
                        this.props.settings.right_title === 'Kantoor contactpersonen' && this.props.kantoor.AllContData && this.props.kantoor.AllContData.kol ? this.props.kantoor.AllContData.kol :
                        this.props.settings.right_title === 'Vat refund codes' && this.props.jobs.AllVatRefCodeData && this.props.jobs.AllVatRefCodeData.kol ? this.props.jobs.AllVatRefCodeData.kol :
                        this.props.settings.right_title === 'Job definities' && this.props.jobs.AllJobDefMainData && this.props.jobs.AllJobDefMainData.kol ? this.props.jobs.AllJobDefMainData.kol : [];

        var terug = [];
        for (var i = 0; i < columns.length; i++) 
        { 
            let rij = columns[i];
            if (rij.field && rij.field === waarde)
            {
                rij.suppressMenu = true;
                rij.filter="agTextColumnFilter"
                rij.width = (this.props.settings.right_title === 'Klanten') ? 145 : 290
                rij.hide = false
                terug.push(rij);
            }
        }
        if (this.props.settings.right_title === 'Klanten')
        {
            for (var exi = 0; exi < columns.length; exi++) 
            { 
                let exrij = columns[exi];
                if (exrij.field && exrij.field === 'number')
                {
                    exrij.suppressMenu = true;
                    exrij.filter="agTextColumnFilter"
                    exrij.width=145
                    exrij.hide = false
                    terug.push(exrij);
                }
            }
        }
        return terug;
    }

    toggle() {
        document.getElementById("customizer").classList.toggle("show-service-panel");
    }
    render() {
       let data =  this.props.settings.right_title === 'Gebruikers' && this.props.user.AlluserData && this.props.user.AlluserData.data ? this.props.user.AlluserData.data : 
                    this.props.settings.right_title === 'Klanten' && this.props.account.AllData && this.props.account.AllData.data ? this.props.account.AllData.data :
                    this.props.settings.right_title === 'Leveranciers' && this.props.supplier.AllData && this.props.supplier.AllData.data ? this.props.supplier.AllData.data :
                    this.props.settings.right_title === 'IvatCode' && this.props.param.AllMainProductCodesData && this.props.param.AllMainProductCodesData.data ? this.props.param.AllMainProductCodesData.data :
                    this.props.settings.right_title === 'Registraties' && this.props.registration.AllRegData && this.props.registration.AllRegData.data ? this.props.registration.AllRegData.data :
                    this.props.settings.right_title === 'Contactpersonen' && this.props.contact.AllData && this.props.contact.AllData.data ? this.props.contact.AllData.data : 
                    this.props.settings.right_title === 'Kantoren' && this.props.kantoor.AllData && this.props.kantoor.AllData.data ? this.props.kantoor.AllData.data : 
                    this.props.settings.right_title === 'Landen' && this.props.land.AllData && this.props.land.AllData.data ? this.props.land.AllData.data : 
                    this.props.settings.right_title === 'Taalcodes' && this.props.land.AllTaalcodesData && this.props.land.AllTaalcodesData.data ? this.props.land.AllTaalcodesData.data :
                    this.props.settings.right_title === 'Templates' && this.props.template.AllData && this.props.template.AllData.data ? this.props.template.AllData.data : 
                    this.props.settings.right_title === 'Betaallijst Finanz' && this.props.jobs.AllBetaalFinData && this.props.jobs.AllBetaalFinData.data ? this.props.jobs.AllBetaalFinData.data :
                    this.props.settings.right_title === 'Kantoor contactpersonen' && this.props.kantoor.AllContData && this.props.kantoor.AllContData.data ? this.props.kantoor.AllContData.data : 
                    this.props.settings.right_title === 'Job definities' && this.props.jobs.AllJobDefMainData && this.props.jobs.AllJobDefMainData.data ? this.props.jobs.AllJobDefMainData.data : 
                    this.props.settings.right_title === 'Vat refund codes' && this.props.jobs.AllVatRefCodeData && this.props.jobs.AllVatRefCodeData.data ? this.props.jobs.AllVatRefCodeData.data :
                    [];

        let kol = this.props.settings.right_title === 'Gebruikers' ? this.GetCol('name') : this.props.settings.right_title === 'Klanten' ? this.GetCol('name') : 
                    this.props.settings.right_title === 'Leveranciers' ? this.GetCol('name') :
                    this.props.settings.right_title === 'IvatCode' ? this.GetCol('description') :
                    this.props.settings.right_title === 'Contactpersonen' ? this.GetCol('cont_name') : this.props.settings.right_title === 'Kantoren' ? this.GetCol('name') : 
                    this.props.settings.right_title === 'Registraties' ? this.GetCol('zoektxt') : this.props.settings.right_title === 'Vat refund codes' ? this.GetCol('zoektxt') :
                    this.props.settings.right_title === 'Kantoor contactpersonen' ? this.GetCol('kantcont_name') : this.props.settings.right_title === 'Job definities' ? this.GetCol('description') : 
                    this.props.settings.right_title === 'Landen' ? this.GetCol('name') : this.props.settings.right_title === 'Taalcodes' ? this.GetCol('omschrijving') :
                    this.props.settings.right_title === 'Templates' ? this.GetCol('description') : this.props.settings.right_title === 'Betaallijst Finanz' ? this.GetCol('referentie') : [];

        let tableheight = Math.floor(window.innerHeight * .78) + 'px';
        return (
            <aside  style = {{zIndex:'2000'}} className="customizer" id="customizer">
                {/*--------------------------------------------------------------------------------*/}
                {/* Toggle Customizer From Here                                                    */}
                {/*--------------------------------------------------------------------------------*/}
                <span className="service-panel-toggle text-white" onClick={() => this.toggle()}><i className="fa fa-bars"></i></span>
                <PerfectScrollbar>
                    <div className="customizer-body">
                        <div style={{textAlign: 'center',height: '50px'}} className="mt-3 border-bottom px-3">                       
                            {/*--------------------------------------------------------------------------------*/}
                            {/* Change LOGO Background                                                         */}
                            {/*--------------------------------------------------------------------------------*/}
                            <h5 className="font-medium m-0">{this.props.settings.right_title}</h5>
                        </div>
                        <div>
                          <TableGrid
                            tableheight={tableheight} 
                            columns={kol}
                            data={data} 
                            CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                            sortable={false}
                            suppressMenu={true}
                            enableRowGroup={false}
                            filter={true}
                          />
                        </div>
                    </div>
                </PerfectScrollbar>
            </aside >
        );
    }
}
export default connect(mapStateToProps)(Customizer)
