import {
    LOGO_BG,
    NAVBAR_BG,
    SIDEBAR_BG,
    THEME,
    DIRECTION,
    SIDEBAR_POSITION,
    HEADER_POSITION,
    LAYOUT,
    SIDEBAR_TYPE,
    SIDEBAR_STATE,
    MAIN_TITLE,
    SUB_TITLE,
    SECURITY_TITLE,
    PANEL_RIGHT,
    RIGHT_TITLE,
    LOAD_VIEW_SUCCESS,
    CLICK_VIEW_SUCCESS,  
    CLICK_VIEW_LEEG_SUCCESS,
    GETLISTMAIN_SUCCESS,  
    ZET_MAINLIST_SUCCESS,
    RESET_VIEW_SUCCESS,
    ZET_POPUP_SUCCESS,
} from '../constants/';

const INIT_STATE = {
    activeDir: "ltr",
    activeThemeLayout: "vertical",
    activeTheme: "light",
    activeSidebarType: "full",
    activeSidebarState: "full",
    activeLogoBg: "skin6",
    activeNavbarBg: "skin5",
    activeSidebarBg: "skin6",
    activeSidebarPos: "fixed",
    activeHeaderPos: "fixed",
    activeLayout: "full",
    main_title:'',
    right_title:'',
    security_title: '',
    popupscreen:null,
    panel_right:false,
    activeSidebarWidth:'235px',
    views:[],
    listmain:[],
    viewscol:null,
    listmaindata:null,
}


export default (state = INIT_STATE, action) => {
    var vi = 0;
    var vid = 0;

    switch (action.type) {
        case ZET_POPUP_SUCCESS:
            let vorm = action.payload;
            return {
                ...state,
                popupscreen: vorm
            };            

        case RESET_VIEW_SUCCESS:
            let viewdata = [];
            if (state.views) 
            {
                viewdata = state.views;
            }
            for (vid = viewdata.length - 1; vid > -1; vid--) 
            { 
                let rowvid = viewdata[vid];
                if (rowvid.object)
                {
                    rowvid.object.setState({ PopUpScreen:null})
                }
                rowvid.object = null;
                viewdata[vid] = rowvid;
            }
            return {
                ...state,
                views: viewdata
            };

        case LOAD_VIEW_SUCCESS:
            let data = action.payload;

            let viewsdata = [];
            let viewcol = [
                {
                    accessor: 'description',
                    style: {cursor:'pointer',textAlign:'left'},    
                    sortable:false,
                    expander:false,
                    filterable:false, // String-based value accessors!
                }, ]
            if (state.views) 
            {
                viewsdata = state.views;
            }
            let found = 0;
            for (var i = 0; i < viewsdata.length; i++) 
            { 
                let row = viewsdata[i];
                if (row.id === data.id && row.vorm === data.vorm)
                {
                    found = 1;
                    i =  viewsdata.length + 5;
                }
            }
            if (found === 0)
            {
                viewsdata.push(data);
            }
          
            if (!data.object)
            {
                for (vid = viewsdata.length - 1; vid > -1; vid--) 
                { 
                    let rowvid = viewsdata[vid];
                    if (rowvid.object)
                    {
                        rowvid.object.setState({ PopUpScreen:null})
                    }
                    rowvid.object = null;
                    viewsdata[vid] = rowvid;
                }
            }

            return {
                ...state,
                views: viewsdata,
                viewscol: viewcol
            };

        case CLICK_VIEW_LEEG_SUCCESS:
            let huvdata = action.payload;
            let hviewsdataleeg = [];
            if (state.views)
            {
                for (vi = 0; vi < state.views.length; vi++) 
                { 
                    let rowvl = state.views[vi];
                    if (rowvl.id === huvdata.id && rowvl.vorm === huvdata.vorm)
                    {
                        if (rowvl.object && rowvl.vorm === 'JOB')
                        {
                            rowvl.object.setState({ PopUpScreen:null})
                        }
                    }
                    else
                    {
                        hviewsdataleeg.push(rowvl);
                    }
                }
            }
            return {
                ...state,
                views: hviewsdataleeg
            };


        case CLICK_VIEW_SUCCESS:
            let hudata = action.payload;
            let hviewsdata = [];
            if (state.views)
            {
                for (vi = 0; vi < state.views.length; vi++) 
                { 
                    let rowv = state.views[vi];
                    if (rowv.id === hudata.id && rowv.vorm === hudata.vorm)
                    {
                        if (rowv.vorm === 'JOB')
                        {
                            hviewsdata.push(rowv);
                        }
                        if (rowv.object && rowv.vorm === 'JOB')
                        {
                            rowv.object.setState({ PopUpScreen:null})
                        }
                    }
                    else
                    {
                        hviewsdata.push(rowv);
                    }
                }
            }
            return {
                ...state,
                views: hviewsdata
            };

        case GETLISTMAIN_SUCCESS:
            return {
                ...state,
                listmain: action.payload.data
            };

        case ZET_MAINLIST_SUCCESS:
            let sleutel = null;
            let path = null;
            if (action && action.payload)
            {
                let tdata = action.payload;
                path = tdata.path;
                sleutel = tdata.sleutel;
            }

            let payload = null;
            let lstmdata = null;
            let maindata = state.listmain
            if (maindata)
            {
                for (vi = 0; vi < maindata.length; vi++) 
                { 
                    let rowv = maindata[vi];
                    if (rowv.referentie ===sleutel)
                    {
                        payload =  rowv;
                    }
                }
                lstmdata = payload;
                if (payload)
                {
                    lstmdata.sizetofit = payload.sizetofit === '0' ? false :  payload.sizetofit === '1' ? true : payload.sizetofit; 
                    lstmdata.sortable = payload.sortable === '0' ? false :  payload.sortable === '1' ? true : payload.sortable;
                    lstmdata.suppressMenu = payload.suppressMenu === '0' ? false : payload.suppressMenu === '1' ? true : payload.suppressMenu;
                    lstmdata.enableRowGroup = payload.enableRowGroup === '0' ? false : payload.enableRowGroup === '1' ? true : payload.enableRowGroup;
                    lstmdata.suppressColumnVirtualisation = payload.suppressColumnVirtualisation === '0' ? false : payload.suppressColumnVirtualisation === '1' ? true : payload.suppressColumnVirtualisation;
                    lstmdata.filter = payload.filter === '0' ? false : payload.filter === '1' ? true : payload.filter;
                    lstmdata.sizetofit = payload.sizetofit === '0' ? false : payload.sizetofit === '1' ? true : payload.sizetofit;
                    lstmdata.pinnedTopRowData = payload.pinnedTopRowData === '0' ? false : payload.pinnedTopRowData === '1' ? true : payload.pinnedTopRowData;
                    lstmdata.editable = payload.editable === '0' ? false : payload.editable === '1' ? true : payload.editable;
                    lstmdata.GridReady = payload.GridReady === '0' ? false : payload.GridReady === '1' ? true : payload.GridReady;
                    lstmdata.infinite = payload.infinite === '1' ? true : payload.infinite;
                    lstmdata.conditie = eval(payload.conditie);
                    lstmdata.dataleeg = payload.pinnedTopRowData === '1' || payload.pinnedTopRowData === true  ? eval(payload.dataleeg) : null;
                    lstmdata.initdataleeg = payload.pinnedTopRowData === '1' || payload.pinnedTopRowData === true  ? eval(payload.dataleeg) : null;
                    if (path === '/dirreg')
                    {
                        lstmdata.sleutel = 'AllRegistratieDir';
                    }
                    if (path === '/accijnz')
                    {

                        lstmdata.sleutel = 'AllRegistratieAcc';
                    }
                    if (path === '/vatref')
                    {
                        lstmdata.sleutel = 'AllRegistratieVat';
                    }

                    if (path === '/allmyactivities')
                    {
                        lstmdata.sleutel = 'AllActivities';
                    }
                    if (path === '/allactivities')
                    {
                        lstmdata.sleutel = 'AllActivities';
                    }

                    if (path === '/jobdirreg')
                    {
                        lstmdata.sleutel = 'GetAllJobsNewDir';
                        lstmdata.conditie = {soort:'1',from:0};
                    }
                    if (path === '/jobdivers')
                    {
                        lstmdata.sleutel = 'GetAllJobsNewDir';
                        lstmdata.conditie = {soort:'4',from:0};
                    }

                    if (path === '/jobaccijnz')
                    {
                        lstmdata.sleutel = 'GetAllJobsNewAcc';
                        lstmdata.conditie = {soort:'2',from:0};
                    }
                    if (path === '/jobvatref')
                    {
                        lstmdata.sleutel = 'GetAllJobsNewVat';
                        lstmdata.conditie = {soort:'3',from:0};
                    }
                }
            }
            return {
                ...state,
                listmaindata:lstmdata,
            };

        case LOGO_BG:
            return {
                ...state,
                activeLogoBg: action.payload
            };

        case MAIN_TITLE:
            return {
                ...state,
                main_title: action.payload
            };

        case SUB_TITLE:
            return {
                ...state,
                sub_title: action.payload
            };

        case SECURITY_TITLE:
            return {
                ...state,
                security_title: action.payload
            };

        case RIGHT_TITLE:
            return {
                ...state,
                right_title: action.payload
            };

        case PANEL_RIGHT:
            return {
                ...state,
                panel_right: action.payload
            };

        case NAVBAR_BG:
            return {
                ...state,
                activeNavbarBg: action.payload
            };

        case SIDEBAR_BG:
            return {
                ...state,
                activeSidebarBg: action.payload
            };

        case THEME:
            return {
                ...state,
                activeTheme: action.payload
            };

        case DIRECTION:
            return {
                ...state,
                activeDir: action.payload
            };

        case SIDEBAR_POSITION:
            return {
                ...state,
                activeSidebarPos: action.payload
            };

        case HEADER_POSITION:
            return {
                ...state,
                activeHeaderPos: action.payload
            };

        case LAYOUT:
            return {
                ...state,
                activeLayout: action.payload
            };

        case SIDEBAR_TYPE:
            return {
                ...state,
                activeSidebarType: action.payload
            };
            
        case SIDEBAR_STATE:
            let width = '80px'
            if (action.payload === 'full')
            {
                width = '235px'
            }    
            return {
                ...state,
                activeSidebarState: action.payload,
                activeSidebarWidth: width
            };

        default:
            return state;

    }
};