import React from 'react'
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import { Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import {setSubTitle} from "../../redux/settings/action";
import {GetBetaalFin, GetsubBetaalFin} from "../../redux/settings/jobsActions";
import {setPanelRight, setTitleRight} from "../../redux/settings/action";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import Upload from '@material-ui/icons/Upload';
import FilePlus from '@material-ui/icons/FilePlus';
import Finish from '@material-ui/icons/Finish';
import PdfBox from '@material-ui/icons/PictureAsPdfOutlined'

import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import SubJobNew from "@material-ui/icons/SubJobNew";
import { Editor, getTinymce } from '@tinymce/tinymce-react';

var gridapi = null;
var tinymce = null;  
moment.locale('nl-be');
var newline = false;

const mapStateToProps = state => ({
  ...state
});


class Betaalmainfinpage extends React.Component {

  state = {
    DownloadData:{id:''},
    velddata:{verplicht:false,veld:'',veld_txt:'',veld_code:''},
    tableheight: Math.floor(window.innerHeight * .70) + 'px',
    tabvalue:'1', 
    editorState: '',
    pinnedSubBetaalFinData:[{id:0,mainid:this.props.jobs.BetaalFinData.data.id,jobid:'',acc_num:'',acc_id:'',acc_naam:'',reg_num:'',bedrag:'',code:'',save:'',verwijder:''}],
    edit:this.props.jobs && this.props.jobs.BetaalFinData && this.props.jobs.BetaalFinData.data && this.props.jobs.BetaalFinData.data.id === 0 ? true : false,
    save:false,
    subjobsave:false,
    subtariefsave: false,
    subsave:false,
    adrestel:0,
    algtotaal:0,
    checkval:false,
    PopUpScreen:null,
    data:this.props.jobs && this.props.jobs.BetaalFinData && this.props.jobs.BetaalFinData.data && this.props.jobs.BetaalFinData.data.id === 0 ? this.props.jobs.BetaalFinData.data : null,
    speedactions:[
      GenFunc.allowed(this.props.user.userData.secrows,'AllBetaalFin','edit') ? { icon: <EditIcon />, name: 'Edit', tooltip:'Aanpassen gegevens' } : null,
      GenFunc.allowed(this.props.user.userData.secrows,'AllBetaalFin','edit') ?{ icon: <SaveIcon />, name: 'Save', tooltip:'Bewaren gegevens'  } : null,
      GenFunc.allowed(this.props.user.userData.secrows,'AllBetaalFin','edit') ?{ icon: <Upload />, name: 'Ophalen', tooltip:'Ophalen extra lijnen'  } : null,
      GenFunc.allowed(this.props.user.userData.secrows,'AllBetaalFin','edit') ?{ icon: <FilePlus />, name: 'Maakdoc', tooltip:'Aanmaken document'  } : null,
      GenFunc.allowed(this.props.user.userData.secrows,'AllBetaalFin','edit') ?{ icon: <PdfBox />, name: 'Pdf', tooltip:'Pdf document'  } : null,
      GenFunc.allowed(this.props.user.userData.secrows,'AllBetaalFin','edit') ?{ icon: <Finish />, name: 'Afgewerkt', tooltip:'Afwerken'  } : null,

    ],
    subspeedactions: [
    ],
    speedopen: false,
    subspeedopen: false,    
    subspeedzien:true,
    workflowstories:[],
    changewfindex:null,
  };


  constructor(props, context) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);    
    this.onGridReady = this.onGridReady.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.ValueCheck = this.ValueCheck.bind(this);
    this.ErrorCheck = this.ErrorCheck.bind(this);
    this.SubBetaalFinChanged = this.SubBetaalFinChanged.bind(this);
    this.SubBetaalFinCheck = this.SubBetaalFinCheck.bind(this);
    this.SubBetaalFinCellClick = this.SubBetaalFinCellClick.bind(this);
    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
    this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);
    this.ZetEdit = this.ZetEdit.bind(this);
    this.ZetEditDoc = this.ZetEditDoc.bind(this);
    this.CheckSave = this.CheckSave.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.MainSave = this.MainSave.bind(this)
    this.checkconfirm = this.checkconfirm.bind(this)
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
    this.handleJa = this.handleJa.bind(this)
    this.ActionClick = this.ActionClick.bind(this)
    this.SubBetaalFinSaveAll = this.SubBetaalFinSaveAll.bind(this)
    this.SubActionClick = this.SubActionClick.bind(this)
    this.onEditorStateChange = this.onEditorStateChange.bind(this);

    this.CreatePdf = this.CreatePdf.bind(this);
    this.getFilter = this.getFilter.bind(this);
    this.zetTotaal = this.zetTotaal.bind(this);

  }

    async CreatePdf() {
        document.getElementById('TEMPPDFFINANZ').submit();
    }

    getFilter(params) 
    {
      var colId = params.props.column.getId();
      var filtar = []
      if (colId === 'reg_num')
      {
        filtar.push({field:'land',value:'4501c498cce2e2d7fbdea1afc51c7c6ad26'})
        if (params.props.data && params.props.data.acc_naam && params.props.data.acc_naam.length > 2)
        {
          filtar.push({field:'account_name',value:params.props.data.acc_naam})
        }
        params.setFilter(filtar);
      }
    }

   onEditorStateChange(content, editor) {
      let data = this.state.data
      if (content && data)
      {
          data.dok = content 
          this.setState({editorState:content, save:true});
      }
    }

  async handleTabChange(event, newValue)  {
    if (newValue === '1')
    {
      await this.props.dispatch(GetsubBetaalFin(this.props.jobs.BetaalFinData.data.id))
      let subspeedactions = [
        { icon: <SubJobNew />, name: 'NewSubjob',  tooltip:'Nieuwe regel toevoegen' },
      ]
      this.setState({subspeedactions:subspeedactions})
    }
    this.setState({tabvalue:newValue});
  };

  handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:true });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
  };

  handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
  };

  handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
  };


  async SubActionClick(name) {
    switch (name) {
      case 'NewSubjob':
        this.setState({pinnedSubBetaalFinData:[{id:0,mainid:this.props.jobs.BetaalFinData.data.id,jobid:'',acc_num:'',acc_id:'',acc_naam:'',reg_num:'',bedrag:'',code:'',save:'',verwijder:''}]})
        break

      default:
        break;
    }
    this.setState({ speedopen: false, subspeedopen:false });
  }


  async ActionClick(name)
  {
    switch (name) 
    {
      case 'Edit':
        this.setState({edit:true,data:this.props.jobs.BetaalFinData.data,speedopen:false})
        break;

      case 'Save':
        this.MainSave();
        break;


      case 'Afgewerkt':
        await SqlFunction.DoSql('AfwBetaalFin',this.props.jobs.BetaalFinData.data);
        await this.props.dispatch(GetBetaalFin(this.props.jobs.BetaalFinData.data.id));
        break;


      case 'Maakdoc':
        await SqlFunction.DoSql('CreaFinanz',this.props.jobs.BetaalFinData.data);
        await this.props.dispatch(GetBetaalFin(this.props.jobs.BetaalFinData.data.id));
        if (this.props.jobs && this.props.jobs.BetaalFinData && this.props.jobs.BetaalFinData.data && this.props.jobs.BetaalFinData.data.dok)
        {
            await this.setState({editorState:this.props.jobs.BetaalFinData.data.dok, save:false});        
        }
        else
        {
            await this.setState({editorState:'', save:false});         
        }

        break;

      case 'Pdf':
        this.CreatePdf();
        break;


      case 'Ophalen':
        await SqlFunction.DoSql('SetNewUpdBetaalFin',this.props.jobs.BetaalFinData.data);
        await this.props.dispatch(GetsubBetaalFin(this.props.jobs.BetaalFinData.data.id))
        break;

      default:    
        break;
    }     
    this.setState({ speedopen: false, subspeedopen:false });
  }

  ZetEdit()
  {
    this.setState({edit:true, data:this.props.jobs.BetaalFinData.data})
  }

  async ZetEditDoc()
  {
    await this.setState({save:true, data:this.props.jobs.BetaalFinData.data,editorState:this.props.jobs.BetaalFinData.data.dok})
        tinymce = getTinymce()  
    if (tinymce) {tinymce.activeEditor.execCommand('mcePrint');}
  }


  componentDidMount(){
    window.addEventListener("resize", this.resizeFunction);
    this.props.dispatch(setTitleRight('Betaallijst Finanz'))
    this.props.dispatch(setSubTitle('Betaallijst Finanz'))
    this.props.dispatch(setPanelRight(true))
    let prevtab = this.state.tabvalue
    this.handleTabChange(null,prevtab)

    if (this.props.jobs.BetaalFinData)
    {
      if (this.props.jobs.BetaalFinData.data && this.props.jobs.BetaalFinData.data.id === 0)
      {
        this.setState({edit:true,save:false,subsave:false,data:this.props.jobs.BetaalFinData.data})
      }
      else
      {
        if (this.props.jobs && this.props.jobs.SubBetaalFinData && this.props.jobs.SubBetaalFinData.data)
        {
            this.zetTotaal(this.props.jobs.SubBetaalFinData.data)
        }
        this.setState({edit:false,save:false,subsave:false,data:this.props.jobs.BetaalFinData.data,editorState:this.props.jobs.BetaalFinData.data.dok})
      }
    }

  }

  componentWillUnmount() 
  {
    this.checkconfirm(this.props.jobs.BetaalFinData);    
    this.props.dispatch(setPanelRight(false))
    this.props.dispatch(setTitleRight(''))
    window.removeEventListener("resize", this.resizeFunction);
  }

  async componentWillReceiveProps(nextProps, nextState) 
  {
    if (this.props.jobs.BetaalFinData)
    {
      if (this.props.jobs.BetaalFinData.data.id !== nextProps.jobs.BetaalFinData.data.id)
      {
        await this.checkconfirm(this.props.jobs.BetaalFinData);
        if (nextProps.jobs.BetaalFinData.data.id === 0)
        {
          this.setState({edit:true,save:false,subsave:false,algtotaal:0})
          this.handleTabChange(null,'1')

        }
        else
        {
          await this.props.dispatch(GetsubBetaalFin(nextProps.jobs.BetaalFinData.data.id))
          if (this.props.jobs && this.props.jobs.SubBetaalFinData && this.props.jobs.SubBetaalFinData.data)
          {
              this.zetTotaal(this.props.jobs.SubBetaalFinData.data)
          }

          this.setState({edit:false,save:false,subsave:false})
          let prevtab = this.state.tabvalue
          this.handleTabChange(null,prevtab)

        }
      }
    }
  }


  async handleJa(parameters)
  {
    if (this.state.save === true)
    {
      await SqlFunction.DoSql('ChgBetaalFin',this.state.data);
    }
    if (this.state.subsave === true)
    {
    }
    this.setState({edit:false, save:false,subsave:false})
  }


  checkconfirm (parameters) 
  {

    if (this.state.save === true || this.state.subsave === true)
    {
      confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,        
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Aanpassingen niet doorgevoerd.</h1>
            <p>Wens je de aanpassingen alsnog te bewaren?</p>
            <button
              onClick={() => {
                this.handleJa(parameters);
                onClose();
              }}
            >
              Ja
            </button>
            <button onClick={() => {
                this.setState({edit:false, save:false,subsave:false,checkval:false})
                onClose();
              }}
            >
              Nee
            </button>
          </div>
        );
      }
      });
    }

  }

  resizeFunction() 
  {
    if (window.innerHeight >= 960) 
    {
      this.setState({tableheight: Math.floor(window.innerHeight * .70) + 'px'});
    }
    else
    {
      this.setState({tableheight: '600px'});
    }
  }

  onGridReady(params)
  {
    gridapi = params;
  }

  async MainSave() {
    if (this.state.save === true) 
    {
      let retdata = await SqlFunction.DoSql('ChgBetaalFin',this.state.data);
      this.setState({edit:false, save:false,data:null,checkval:false})
      await this.props.dispatch(GetBetaalFin(retdata.id))
    }
  }
  getContextMenuItems(params,sleutel) 
  {
    return null;
  }

  CheckSave(data)
  {
    let terug = true
    terug = data.referentie ? (data.referentie.length < 2 ? true : false) : true;      
    if (terug === false)
    {
      if(data.datum && data.datum.length > 0) 
      {
        var utc = moment(data.datum, "DD-MM-YYYY", true)
        if(!utc.isValid()) 
        {
          terug = true
        }
      }
    } 
    return !terug;
  }

  async ErrorCheck(that, waarde, type, colId) {
    let terug = false;
    switch (colId) 
    {
      case 'referentie':
        terug = waarde ? (waarde.length < 2 ? true : false) : true;      
        break;

      case 'datum':
        if(waarde && waarde.length > 0) 
        {
          var utc = moment(waarde, "DD-MM-YYYY", true)
          if(!utc.isValid()) 
          {
            terug = true
          }
        }
        break;
      default:    
        break;        
    } 
    that.setState({ error: terug })
  } 



  async SubBetaalFinCellClick(waarde) {
    if (gridapi && gridapi.api && waarde && waarde.data && waarde.column)
    {
      var data = waarde.data
      let column = waarde.column.colDef.field;
      if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows,'AllBetaalFin','edit'))
      {
        if (data.save === true) 
        {
          data.mainid = this.props.jobs.BetaalFinData.data.id;
          data.save='';
          data.verwijder = '1';
          await SqlFunction.DoSql('ChgSubBetaalFin',data);
          await waarde.node.setDataValue('save','');  
          await this.props.dispatch(GetsubBetaalFin(this.props.jobs.BetaalFinData.data.id))
          if (this.props.jobs && this.props.jobs.SubBetaalFinData && this.props.jobs.SubBetaalFinData.data)
          {
            this.zetTotaal(this.props.jobs.SubBetaalFinData.data)
          }

          if (data.id === 0) {
    //        waarde.node.setData(data);            
//            gridapi.api.stopEditing();
//            await gridapi.api.updateRowData({
//              add: [data],
//              addIndex: 0
//            });
            newline = true;
            await this.setState({pinnedSubBetaalFinData:[{id:0,mainid:this.props.jobs.BetaalFinData.data.id,jobid:'',acc_num:'',acc_id:'',acc_naam:'',reg_num:'',bedrag:'',code:'',save:'',verwijder:''}]})
   
              gridapi.api.setFocusedCell(0, 'acc_num');
              gridapi.api.startEditingCell({
                rowIndex: 0,
                colKey: 'acc_num',
                rowPinned:'top'
               })

          }
          else {
            data.save='';
            data.verwijder = '1';
            waarde.node.setData(data);
          }
          this.setState({subsave:false})
        }
      }
      if (column === 'verwijder')
      {
          gridapi.api.updateRowData({ remove: [waarde.data]})
          let subdata = waarde.data
          await SqlFunction.DoSql('DelSubBetaalFin',subdata);
      }
    }
    return;
  }

  async zetTotaal(items)
  {
    if (Array.isArray(items))
    {
      var i = 0;
      var totaal = 0;
      for (i = 0; i < items.length; i++) 
      {
        let data = items[i];
        totaal = totaal + parseFloat(data.bedrag);
      }
      let stotaal = totaal.toLocaleString('de-DE',{minimumFractionDigits: 2, maximumFractionDigits: 2})
      this.setState({algtotaal:stotaal})
    }
  }                      


  async SubBetaalFinSaveAll(items)
  {
    if (Array.isArray(items) && GenFunc.allowed(this.props.user.userData.secrows,'AllBetaalFin','edit'))
    {
      var i = 0;
      for (i = 0; i < items.length; i++) 
      {
        let data = items[i];
        await SqlFunction.DoSql('ChgSubBetaalFin',data);
      }
    }
  }                      



  SubBetaalFinChanged(params,waarde,that) {
    if (newline === true)
    {
        params.data.save = '';
        params.node.setData(params.data);  
        let tparams = {force: true,};
        gridapi.api.refreshCells(tparams)    

    }
  }                        

  async SubBetaalFinCheck(params,waarde,selwaarde) {
    if (newline === true)
    {
        let newdata = params.props.data;
        newdata.save = '';
        params.props.node.setDataValue('save','');  
    }

    var colId = params.props.column.getId();
    let terug = false;
    let save = true;
    let coldata = null;
    params.props.data[colId] = waarde;
    var firstColAr = gridapi.columnApi.getAllDisplayedColumns();
    for (var i = 0; i < firstColAr.length; i++) 
    {
        coldata = firstColAr[i];
    }


    switch (colId) 
    {
      case 'bedrag':
        if (params && params.props && params.props.data && params.props.data.acc_num && params.props.data.acc_num.length < 2)
        {
            gridapi.api.stopEditing();
            gridapi.api.setFocusedCell(0, 'acc_num');
            gridapi.api.startEditingCell({
              rowIndex: 0,
              colKey: 'acc_num',
              rowPinned:'top'
             })
        }
        break;


      case 'acc_naam':
        terug = waarde ? (waarde.length < 1 ? true : false) : true;      
        if (selwaarde)
        {
          params.props.data.acc_num = selwaarde.number;
          if (selwaarde.regnumdui)
          {
              params.props.data.reg_num = selwaarde.regnumdui; 
          }
          else
          {
              params.props.data.reg_num = ''; 
          }
          if (waarde && waarde.length > 2 )
          {
            gridapi.api.setFocusedCell(0, 'bedrag');
            gridapi.api.startEditingCell({
              rowIndex: 0,
              colKey: 'bedrag',
              rowPinned:'top'
             })
          }

        }
        break;

      case 'acc_num':
        terug = waarde ? (waarde.length < 2 ? true : false) : true;      
        if (selwaarde && selwaarde.name && selwaarde.name.length > 2)
        {
          params.props.data.acc_naam = selwaarde.name;
          if (selwaarde.regnumdui)
          {
              params.props.data.reg_num = selwaarde.regnumdui; 

          }
          else
          {
              params.props.data.reg_num = ''; 
          }
          if (waarde && waarde.length > 1 && newline === false)
          {
            gridapi.api.setFocusedCell(0, 'bedrag');
            gridapi.api.startEditingCell({
              rowIndex: 0,
              colKey: 'bedrag',
              rowPinned:'top'
             })
          }
          if (newline === true)
          {
             newline = false;
          }
        }
        else
        {
          if (newline === true)
          {
            newline = false
            gridapi.api.setFocusedCell(0, 'acc_num');
            gridapi.api.startEditingCell({
              rowIndex: 0,
              colKey: 'acc_num',
              rowPinned:'top'
             })
          }


        }
        break;

      case 'reg_num':
        terug = waarde ? (waarde.length < 2 ? true : false) : true;      
        if (selwaarde)
        {
          params.props.data.acc_naam = selwaarde.account_name;
          params.props.data.acc_num = selwaarde.acc_num;
          if (coldata)
          {
            gridapi.api.ensureColumnVisible(coldata);
            gridapi.api.setFocusedCell(0, 'bedrag');
            gridapi.api.startEditingCell({
              rowIndex: 0,
              colKey: 'bedrag',
              rowPinned:'top'
             })
          }


        }

        break;

      case 'code':
        terug = waarde ? (waarde.length < 2 ? true : false) : true;      
        break;

      default:    
        break;
    } 
    if (newline === false)
    {
        if (params.props.data && !params.props.data.acc_naam  ) {save = false}
        if (params.props.data && !params.props.data.acc_num  ) {save = false}
        if (params.props.data && !params.props.data.reg_num  ) {save = false}
        if (params.props.data && !params.props.data.code   ) {save = false}
        if (params.props.data && params.props.data.acc_naam && params.props.data.acc_naam.length < 2 ) {save = false}
        if (params.props.data && params.props.data.acc_num && params.props.data.acc_num.length < 2 ) {save = false}
        if (params.props.data && params.props.data.reg_num && params.props.data.reg_num.length < 2 ) {save = false}
        if (params.props.data && params.props.data.code && params.props.data.code.length < 2 ) {save = false}
        params.props.data.save = save;
        params.props.node.setData(params.props.data);  
        params.setState({error:terug})
    }
  }       



  async ValueCheck(that,waarde,type,colId) {

    let data = this.state.data;
    let terug = false;
    switch (colId) 
    {
      case 'referentie':
        terug = waarde ? (waarde.length < 2 ? true : false) : true;      
        data.referentie = waarde;
        break;

      case 'datum':
        data.datum = waarde;
        if(waarde && waarde.length > 0) 
        {
          var utc = moment(waarde, "DD-MM-YYYY", true)
          if(!utc.isValid()) 
          {
            data.afgewerkt = '0'            
            terug = true
          }
          else
          {
            data.afgewerkt = '1'            
          }
        }
        else
        {
          data.afgewerkt = '0'            
        }
        break;
      default:    
        data[colId] = waarde;
        break;
    } 
    that.setState({error:terug})
    terug = await this.CheckSave(data);

    this.setState({save:terug,data:data,checkval:false})
  }       

  render() {
    const { classes } = this.props;
    var {tableheight} = this.state;
    let vld_document = (<form id='TEMPPDFFINANZ' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='CreatePdfFinanz' id="function" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.props.jobs.BetaalFinData.data.id} id="id" name="id"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid"/>
        </form>);      
    return (
      <div >
        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
          <Grid container>
            <Grid item xs={8}>          
              <h4 style={{cursor:'pointer'}} tag="h4" className="mt-3" onClick={() => {this.setState({edit:false})}}>{this.props.settings.sub_title}</h4>  
            </Grid>
            <Grid item xs={4}>  
            </Grid>
          </Grid>
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              (action && action.name  && action.name.length > 2 &&
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipPlacement='bottom'
                  FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false  && GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit')) ? false :
                                       (action.name === 'Edit' && (this.state.edit === true)  ) ? true :
                                       (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'AllJobDefMain','edit')) ? true :
                                       (action.name === 'Ophalen' && (this.state.edit === true)) ? true :
                                       (action.name === 'Ophalen' && this.state.edit === false  && this.props.jobs.BetaalFinData.data.id === 0) ? true :
                                       (action.name === 'Ophalen' && this.state.edit === false ) ? false : 
                                       (action.name === 'Afgewerkt' && (this.props.jobs.BetaalFinData.data.id === 0 ) ) ? true :
                                       (action.name === 'Maakdoc' && (this.state.edit === true ) ) ? true :
                                       (action.name === 'Maakdoc' && this.state.edit === false  && this.props.jobs.BetaalFinData.data.id === 0) ? true :
                                       (action.name === 'Maakdoc' && this.state.edit === false ) ? false : 
                                       (action.name === 'Pdf' && (this.state.edit === true ) ) ? true :
                                       (action.name === 'Pdf' && this.state.edit === false  && this.props.jobs.BetaalFinData.data.id === 0) ? true :
                                       (action.name === 'Pdf' && this.state.edit === false ) ? false : 
                                       (action.name === 'Save' && this.state.save === true) ? false :
                                       (action.name === 'Save' && this.state.edit === true  && this.state.save === false) ? true :
                                       (action.name === 'Save' && this.state.save === false) ? true : false}}
                  onClick={() => {this.ActionClick(action.name)}}                             
                  tooltipTitle={action.tooltip}
                />
              )
            ))}
          </SpeedDial>  
        </Toolbar>
        {this.props.jobs && this.props.jobs.BetaalFinData && this.props.jobs.BetaalFinData.data &&
          <div>
            {vld_document}           
            <Grid container style={{width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px'}}>
              <Grid container spacing={1}>
                <Grid item xs={this.state.edit === false ? 3 : 5}>
                  <Card className='card_brut'>
                    {this.state.edit === false &&
                      <PerfectScrollbar className="sidebar-nav">
                        <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                          <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} className="card-title m-t-10">{this.props.jobs.BetaalFinData.data.referentie} </h4>
                          <h5 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.jobs.BetaalFinData.data.datum}</h5> 
                          {(this.props.jobs.BetaalFinData.data.afgewerkt === '1' || this.props.jobs.BetaalFinData.data.afgewerkt === true) &&
                              <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >AFGEWERKT</h6> 
                          }
                          {(this.props.jobs.BetaalFinData.data.afgewerkt === '0' || this.props.jobs.BetaalFinData.data.afgewerkt === false) &&
                              <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >NIET AFGEWERKT</h6> 
                          }
                          <div className='div_line' style={{marginBottom: '25px'}}></div>
                        </CardBody>
                      </PerfectScrollbar>
                    }
                    {this.state.edit === true &&
                      <PerfectScrollbar styleclassName="sidebar-nav">
                        <CardBody style={{textAlign:'left',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                            <Grid style={{marginLeft:'10px'}} container spacing={1}>
                              <Grid item sm={12} md={12} lg={12}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.referentie}
                                  coltype = 'STR'                        
                                  labelText="Referentie"
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'referentie')}
                                  setError={(params,value,type) => this.ErrorCheck(params,value,type,'referentie')}
                                />
                              </Grid>


                              <Grid item sm={12} md={12} lg={12}>
                                <div style={{marginTop: '-4px'}}>
                                    <EditDateTime className="card-title m-t-10"
                                        value={this.state.data.datum}
                                        coltype = 'STR'                        
                                        labelText='Datum'
                                        inputProps={{value:this.state.data.datum}}
                                        formControlProps={{fullWidth: true}}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'datum')}
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'datum')}
                                    />
                                </div>                              
                              </Grid>

                              <Grid item sm={12} md={12} lg={12}>
                                {(this.state.data.afgewerkt === '1' || this.state.data.afgewerkt === true) &&
                                  <h6>AFGEWERKT</h6> 
                                }
                                {(this.state.data.afgewerkt === '0' || this.state.data.afgewerkt === false) &&
                                  <h6>NIET AFGEWERKT</h6> 
                                }
                              </Grid>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                              <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                            </Grid>
                        </CardBody>
                      </PerfectScrollbar>
                    }
                  </Card>
                </Grid>
                <Grid item xs={this.state.edit === false ? 9 : 7}>
                  <Card className='card_brut'>
                    <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                      <div className='div_line'>
                        <Nav tabs >
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.tabvalue === '1' })}
                              onClick={(event) => { this.handleTabChange(event,'1'); }}
                              style={{ cursor: 'default' }}
                            >
                              Lijst betalingen
                            </NavLink>
                          </NavItem>
                         { this.props.jobs && this.props.jobs.BetaalFinData && this.props.jobs.BetaalFinData.data && this.props.jobs.BetaalFinData.data.dok && this.props.jobs.BetaalFinData.data.dok.length > 4 &&
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.tabvalue === '2' })}
                                onClick={(event) => { this.handleTabChange(event,'2'); }}
                                style={{ cursor: 'default' }}
                              >
                                Document
                              </NavLink>
                            </NavItem>
                          }

                        </Nav>
                        <TabContent activeTab={this.state.tabvalue}>
                          <TabPane tabId="1">

                            { this.state.tabvalue === '1' && this.props.jobs.SubBetaalFinData && this.props.jobs.SubBetaalFinData.data && this.props.jobs.SubBetaalFinData.kol &&
                              this.props.settings.listmaindata && this.props.settings.listmaindata.mainkey && 
                              this.props.jobs && this.props.jobs.BetaalFinData && this.props.jobs.BetaalFinData.data && this.props.jobs.BetaalFinData.data.id !== 0 &&
                              <Grid style={{marginLeft:'10px'}} container spacing={1}>
                                <Grid item sm={12} md={12} lg={12}>
                                  <TableGrid
                                    tableheight={tableheight} 
                                    columns={this.props.jobs.SubBetaalFinData.kol}
                                    data={this.props.jobs.SubBetaalFinData.data} 
                                    CellValueChanged={(params,waarde,object) => this.SubBetaalFinChanged(params,waarde,object)}
                                    CellValueCheck={(params,value,error) => this.SubBetaalFinCheck(params,value,error)}
                                    CellClickedEvent={(column, colDef, value) => this.SubBetaalFinCellClick(column,colDef, value)}
                                    SaveAll={(params) => this.SubBetaalFinSaveAll(params)}
                                    getFilter={(params) => this.getFilter(params)}
                                    sortable={true}
                                    sizetofit={false}
                                    suppressMenu={true}
                                    enableRowGroup={false}
                                    headerComponentParams = {null}
                                    suppressColumnVirtualisation={true}
                                    filter={false}
                                    editable={true}
                                    pinnedTopRowData={this.state.pinnedSubBetaalFinData}
                                    GridReady={this.onGridReady}
                                    sleutel='SubBetaalFin'                     
                                  /> 
                              </Grid>                                   
                              <Grid item sm={4} md={4} lg={4}>
                              </Grid>                                   
                              <Grid item sm={8} md={8} lg={8}>
                                  <h4>Totaal: {this.state.algtotaal}</h4> 
                              </Grid>                                   


                            </Grid>
                            }
                          </TabPane>
                          <TabPane tabId="2">
                            {this.state.editorState && this.state.editorState.length > 0 &&
                            <Editor
                              onEditorChange={this.onEditorStateChange}      
                              init={{
                                height:tableheight,
                                language: 'nl',
                                plugins: [
                                'advlist autolink lists link charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime table paste code'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                                'bold italic backcolor | alignleft aligncenter ' +
                                'alignright alignjustify | bullist numlist outdent indent |  image | removeformat ',
                              }}
                              value={this.state.editorState}
                            />
                            }                
                          </TabPane>
                        </TabContent>
                      </div>
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
        {this.state.PopUpScreen}

      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Betaalmainfinpage));
