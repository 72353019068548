import { LOG_IN_SUCCESS, LOG_IN_ERROR, AllUSERS_SUCCESS, GETUSER_SUCCESS, USERACCOUNTS_SUCCESS, GETJOBSUSER_SUCCESS } from '../constants/';

import emptyuser from '../../assets/images/users/emptyuser.jpg';

const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const logInSuccess = (payload) => {
  return {
    type: LOG_IN_SUCCESS,
    payload: payload.data
  }
}

export const AllUsersSuccess = (payload) => {
  return {
    type: AllUSERS_SUCCESS,
    payload: payload.response.data
  }
}

export const GetUserAccountSuccess = (payload) => {
  return {
    type: USERACCOUNTS_SUCCESS,
    payload: payload.response.data
  }
}

export const GetUserSuccess = (payload) => {
  return {
    type: GETUSER_SUCCESS,
    payload: payload.response.data
  }
}

export const GetJobsUserSuccess = (payload) => {
  return {
    type: GETJOBSUSER_SUCCESS,
    payload: payload.response.data
  }
}

export function loginFail(payload) {
  return { type: LOG_IN_ERROR, payload };
}

export function logIn(username, password) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('Login', { login: username, password: password });
    const request = axios(authOptions);
    return request.then(
      response => dispatch(logInSuccess(response)),
      err => dispatch(loginFail(err))
    );
  }
}

export function UpdFoto(data) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('UsrUpdFoto', data);
    const request = axios(authOptions);
    return request.then(
      () => dispatch(logInSuccess({ data: data })),
      err => dispatch(loginFail(err))
    );
  }
}

export function AllUsers(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllUsers', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllUsersSuccess({ response }))
        }
      },
      err => dispatch(loginFail(err))
    );
  }
}

export function GetUserAccounts(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('UserAccounts', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetUserAccountSuccess({ response })) },
      () => { dispatch(GetUserAccountSuccess(null)) }
    );
  }
}

export function GetUser(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetUser', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetUserSuccess({ response })) },
      err => dispatch(loginFail(err))
    );
  }
}

export function GetJobsUser(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetJobsUser', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetJobsUserSuccess({ response })) },
      () => { dispatch(GetJobsUserSuccess(null)) }
    );
  }
}

export function CreateUser() {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, login: '', nw_login: '', paswoord: '', code:'', email2:'',email3:'',email4:'',email5:'',foto: emptyuser, first_name: '', last_name: '', email: '', job_title: '', mobile_phone: '', main_phone: '', street: '', housnum: '', active: 'Y', zipcode: '', zipcode_txt: '' }
    response.data = { data: data }
    dispatch(GetUserSuccess({ response }));
  }
}
