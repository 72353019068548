import React, {createRef } from 'react';
import moment from 'moment'
import 'moment/locale/nl-be'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Validation from "../../functions/validation.js";
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import {clickview} from "../../redux/settings/action";
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import EditCheck from "../../components/editcheck.jsx";
import FileDownload from "../../components/FileDownload.jsx";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import PopAddSupplier from "../suppliers/PopAddSupplier.jsx";
import Dropzone from 'react-dropzone';

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point 
  if (dropzoneRef.current) {
    dropzoneRef.current.open()
  }
};
moment.locale('nl-be');
const accdropid = GenFunc.MakeId()
const accframeid = GenFunc.MakeId()
const accformid = GenFunc.MakeId()

var gridapi = null;
var rowpinnend = null;

class PopJobEditSubVatRefund extends React.Component {
    state = {
        data: this.props.data,
        kol: this.props.kol,
        tot_btwnor:0,
        tot_mvh:0,
        tot_btwbed:0,
        DownloadData: { id: '' },
        filename:'',
        save: this.props.data.id === 0 ? true : false,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        nivo: (this.props.nivo) ? this.props.nivo : 0,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleSaveSub = this.handleSaveSub.bind(this);
        this.handleDoc = this.handleDoc.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.CellCheck = this.CellCheck.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);

        this.CheckSaveOnkost = this.CheckSaveOnkost.bind(this);
        this.ClosePopUp = this.ClosePopUp.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);
        this.getFilter = this.getFilter.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.ClickDocument = this.ClickDocument.bind(this);

        this.zetTotaal = this.zetTotaal.bind(this);

    }

    async ClosePopUp() 
    {
      this.setState({PopUpScreen:null})
      this.props.dispatch(clickview({ id: this.props.jobid, vorm: 'JOB' }));
    }

    async ClickDocument(data)
    {
      await this.setState({DownloadData:data});
      document.getElementById(accformid).submit();
    }


    onGridReady(params)
    {
        gridapi = params;
        let rij = [{id:0,job_id:0,supplier_id:0,vatcode_id:0,vatsubcode_id:0,mainid:0,save:'',verwijder:'',vatcode_txt:'',vatsubcode_txt:'',vatsubcode2_txt:'',factnum:'',factdatum:'', mvh:'',
                        btwper:this.props.dropdata.defbtw,btwbed:'',aftrekper:'',scan:'',pdf:'',opmerking:'',supplier_txt:'',main_description:'',sub_description:'',btwnor:''}]        
        gridapi.api.updateRowData({ add: rij})
        this.zetTotaal();
        if (this.props.data.id === 0) {return}
//        var lastrow = gridapi.api.getLastDisplayedRow();
        var lastrow = this.state.griddata.length;

                    gridapi.api.setFocusedCell(lastrow, 'vatcode_txt',);  
                    gridapi.api.startEditingCell({
                        rowIndex: lastrow,
                        colKey: 'vatcode_txt',

                    });    
    }

    addRecord(params, waarde) 
    {
      this.setState({PopUpScreen:(<PopAddSupplier open={true} waarde={waarde} toevobject={params} hoofdvorm={this} close={this.ClosePopUp} title='Toevoegen leverancier' />)});
    }

    getFilter(params) 
    {
      var colId = params.props.column.getId();
      var filtar = []
      if (colId === 'vatcode_txt')
      {
        filtar.push({field:'country',value:this.props.dropdata.country})
        params.setFilter(filtar);
      }

      if (colId === 'vatsubcode_txt')
      {
        filtar.push({field:'country',value:this.props.dropdata.country})
        if (params.props.data.vatcode_id && params.props.data.vatcode_id.length > 1)
        {
            filtar.push({field:'maincode',value:params.props.data.vatcode_id})
        }
        params.setFilter(filtar);
      }
    }

    async onDrop(files) {
        if (this.state.data.id === 0 || this.state.data.id === '0') {return}
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          formData.append('file', file);

        }

        formData.set('accountid', this.props.dropdata.account_id);
        formData.set('reg_id', this.props.dropdata.registration_id);
        formData.set('kant_id', this.props.dropdata.kantoor_id);
        formData.set('job_id', this.props.dropdata.id);
        formData.set('workflow_id', this.props.dropworkflow.id);
        formData.set('type', 'workflow');    
        formData.set('vatref_id', this.state.data.id);    

        await SqlFunction.DoSqlBody('saveFile', formData);
        let retdata = await SqlFunction.DoSql('GetJobMainVatRef',this.state.data);
        if (retdata && retdata.subonkkol && retdata.data && retdata.subonkosten) 
        {
            let tot_btwnor = 0;
            let tot_mvh = 0;
            let tot_btwbed = 0;
            if (retdata.subonkosten)
            {
              for (var teller = 0; teller < retdata.subonkosten.length; teller++) 
              {
                let item = retdata.subonkosten[teller];
                let it_mvh = parseFloat(item.mvh);           
                if (isNaN(it_mvh)) {it_mvh = 0}
                let it_btwbed = parseFloat(item.btwbed);           
                if (isNaN(it_btwbed)) {it_btwbed = 0}
                let it_btwnor = parseFloat(item.btwnor);           
                if (isNaN(it_btwnor)) {it_btwnor = 0}
                tot_mvh = tot_mvh + it_mvh;
                tot_btwbed = tot_btwbed + it_btwbed;
                tot_btwnor = tot_btwnor + it_btwnor;
              }
            }
            this.setState({data:retdata.data, griddata:retdata.subonkosten, tot_btwnor:tot_btwnor, tot_mvh:tot_mvh, tot_btwbed:tot_btwbed})            
        }

    };


    async ErrorCheck(that, waarde, type, colId) {
        let terug = false;
        let bedrag = 0;
        switch (colId) 
        {
            case 'btw':
            case 'mvh':
            case 'totaal':
                if (waarde.constructor === String)
                {
                    bedrag = parseFloat(waarde);           
                    if (isNaN(bedrag)) {bedrag = 0}
                }
                else
                {
                    bedrag = waarde;           
                }
                if (bedrag < 0.5)
                {
                    terug = true
                }
                break

            default:    
                terug = Validation.ValidCheck('VatRefund', waarde, colId, that,null, this.state.data, this.props.listdata);

                break;
        }
        that.setState({ error: terug})
    }      

    CheckSave() {
      let terug = false
      terug = Validation.ValidCheck('VatRefund', this.state.data.factnum, 'factnum', null,null, this.state.data, this.props.listdata);
      if (terug === false)
      {
        terug = Validation.ValidCheck('VatRefund', this.state.data.factdat, 'factdat', null,null, this.state.data, this.props.listdata);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('VatRefund', this.state.data.supplier_txt, 'supplier_txt', null,null, this.state.data, this.props.listdata);
      }
      this.setState({save:terug})
      return terug;
    }
    async handleDoc()
    {
        if (this.state.data.id === 0)
        {
            let savedata = await SqlFunction.DoSql('ChgVatRefundOnkosten',{data:this.state.data});
            let retdata = await SqlFunction.DoSql('GetJobMainVatRef',savedata);
            if (retdata && retdata.subonkkol && retdata.data && retdata.subonkosten) 
            {
                let tot_btwnor = 0;
                let tot_mvh = 0;
                let tot_btwbed = 0;
                if (retdata.subonkosten)
                {
                  for (var teller = 0; teller < retdata.subonkosten.length; teller++) 
                  {
                    let item = retdata.subonkosten[teller];
                    let it_mvh = parseFloat(item.mvh);           
                    if (isNaN(it_mvh)) {it_mvh = 0}
                    let it_btwbed = parseFloat(item.btwbed);           
                    if (isNaN(it_btwbed)) {it_btwbed = 0}
                    let it_btwnor = parseFloat(item.btwnor);           
                    if (isNaN(it_btwnor)) {it_btwnor = 0}
                    tot_mvh = tot_mvh + it_mvh;
                    tot_btwbed = tot_btwbed + it_btwbed;
                    tot_btwnor = tot_btwnor + it_btwnor;
                  }
                }
                this.setState({data:retdata.data, griddata:retdata.subonkosten, tot_btwnor:tot_btwnor, tot_mvh:tot_mvh, tot_btwbed:tot_btwbed})                
            }
        }
        let element = document.getElementById(accdropid);
        element.click()
    }

    async ValueCheck(that,waarde,type,colId,verplicht,overzdata) 
    {
        let data = this.state.data;
        let terug = false;
        let teller = 0;
        switch (colId) 
        {


            case 'supplier_txt':
                if (waarde.id === 0)
                {
                    this.setState({PopUpScreen:(<PopAddSupplier open={true} waarde={waarde.value} toevobject={that} close={this.ClosePopUp} title='Toevoegen leverancier' />)});
                }
                data.supplier_txt = waarde.value;
                data.supplier_id = waarde.id;
                if (overzdata)
                {
                    data.sup_vatnumber = overzdata.vatnumber
                    data.sup_adres = overzdata.adress
                    data.sup_zipcode = overzdata.zipcode_txt
                    data.sup_country_txt = overzdata.country_txt
                    data.munt_eenheid = overzdata.munt_eenheid
                }
                else
                {
                    data.sup_vatnumber = ''
                    data.sup_adres = ''
                    data.sup_zipcode = ''
                    data.sup_country_txt = ''
                    data.munt_eenheid = ''
                }
                terug = Validation.ValidCheck('VatRefund', waarde.value, colId, that, null, data, this.props.listdata);                
                break;

            case 'scan':
                if (waarde === true)
                {
                    data.scan = '1';
                    if (data.pdf)
                    {
                        if (data.pdf.length < 4)
                        {
                            if (data.id === 0)
                            {
                                let savedata = await SqlFunction.DoSql('ChgVatRefundOnkosten',{data:data});
                                let retdata = await SqlFunction.DoSql('GetJobMainVatRef',savedata);
                                if (retdata && retdata.subonkkol && retdata.data && retdata.subonkosten) 
                                {
                                    let tot_btwnor = 0;
                                    let tot_mvh = 0;
                                    let tot_btwbed = 0;
                                    if (retdata.subonkosten)
                                    {
                                      for (teller = 0; teller < retdata.subonkosten.length; teller++) 
                                      {
                                        let item = retdata.subonkosten[teller];
                                        let it_mvh = parseFloat(item.mvh);           
                                        if (isNaN(it_mvh)) {it_mvh = 0}
                                        let it_btwbed = parseFloat(item.btwbed);           
                                        if (isNaN(it_btwbed)) {it_btwbed = 0}
                                        let it_btwnor = parseFloat(item.btwnor);           
                                        if (isNaN(it_btwnor)) {it_btwnor = 0}
                                        tot_mvh = tot_mvh + it_mvh;
                                        tot_btwbed = tot_btwbed + it_btwbed;
                                        tot_btwnor = tot_btwnor + it_btwnor;
                                      }
                                    }
                                    this.setState({data:retdata.data, griddata:retdata.subonkosten, tot_btwnor:tot_btwnor, tot_mvh:tot_mvh, tot_btwbed:tot_btwbed})                                    
                                }
                            }
                            let element = document.getElementById(accdropid);
                            element.click()
                            return;
                        }
                    }
                    else
                    {

                        if (data.id === 0)
                        {
                            let savedata = await SqlFunction.DoSql('ChgVatRefundOnkosten',{data:data});
                            let retdata = await SqlFunction.DoSql('GetJobMainVatRef',savedata);
                            if (retdata && retdata.subonkkol && retdata.data && retdata.subonkosten) 
                            {
                                let tot_btwnor = 0;
                                let tot_mvh = 0;
                                let tot_btwbed = 0;
                                if (retdata.subonkosten)
                                {
                                  for (teller = 0; teller < retdata.subonkosten.length; teller++) 
                                  {
                                    let item = retdata.subonkosten[teller];
                                    let it_mvh = parseFloat(item.mvh);           
                                    if (isNaN(it_mvh)) {it_mvh = 0}
                                    let it_btwbed = parseFloat(item.btwbed);           
                                    if (isNaN(it_btwbed)) {it_btwbed = 0}
                                    let it_btwnor = parseFloat(item.btwnor);           
                                    if (isNaN(it_btwnor)) {it_btwnor = 0}
                                    tot_mvh = tot_mvh + it_mvh;
                                    tot_btwbed = tot_btwbed + it_btwbed;
                                    tot_btwnor = tot_btwnor + it_btwnor;
                                  }
                                }
                                this.setState({data:retdata.data, griddata:retdata.subonkosten, tot_btwnor:tot_btwnor, tot_mvh:tot_mvh, tot_btwbed:tot_btwbed})                                
                            }
                        }
                        let element = document.getElementById(accdropid);
                        element.click()
                        return;
                    }
                }
                else
                {
                    data.scan = '0';
                }
                break;

            default:    
                data[colId] = waarde;
                terug = Validation.ValidCheck('VatRefund', waarde, colId, that,null, data, this.props.listdata);
                break;
        }
        if (colId === 'factdatum')
        {
            if (type === 'ENTER')
            {
                var lastrow = gridapi.api.getLastDisplayedRow();
                    gridapi.api.setFocusedCell(lastrow, 'vatcode_txt',);  
                    gridapi.api.startEditingCell({
                        rowIndex: lastrow,
                        colKey: 'vatcode_txt',

                    });    

            }                    
        }

        that.setState({ error: terug })
        this.CheckSave();
        return;
    }       
    async CellClick(waarde) {
        if (this.state.save === true) {return}
        var data = waarde.data
        let column = ''
        column = waarde.column.colDef.field;
        if (column === 'save')
        {
            if (data.save === true) 
            {
                await this.handleSaveSub(data);                
            }
        }

        if (column === 'verwijder')
        {
            gridapi.api.updateRowData({ remove: [waarde.data]})
            let subdata = waarde.data
            await SqlFunction.DoSql('DelVatRefundOnkosten',subdata);
           
            if (rowpinnend && rowpinnend.save === true)
            {
                rowpinnend = null;
            }
            rowpinnend = null;
        }
        if (column === 'verwijder' || column === 'save')
        {
            this.zetTotaal();
        }



        return;
    }

    async CellCheck(params,waarde,datacol) {

        if (!waarde) {return}
        var colId = params.props.column.getId();
        params.props.data.save = true;
        let terug = false
        let btw = 0
        let mvh = 0
        let aftrek = 0;
        let btwper = this.props.dropdata.defbtw;
        let aanpassen = false;
        if (this.props.dropdata.defbtw.constructor === String)
        {
            btwper = parseFloat(this.props.dropdata.defbtw);           
            if (isNaN(btwper)) {btwper = 0}
        }

        let btwdefper = this.props.dropdata.defbtw;
        if (this.props.dropdata.defbtw.constructor === String)
        {
            btwdefper = parseFloat(this.props.dropdata.defbtw);           
            if (isNaN(btwdefper)) {btwdefper = 0}
        }

        let aftrekper = 100;

        if (colId === 'vatcode_txt')
        {
            if (!waarde) 
            {
                params.props.data.main_description = '';     
                params.props.data.vatcode_id = '';      
                params.props.data.vatcode_txt = '';      
                terug = true;
            }
            if (datacol )
            {
                if (datacol.main_description)
                {
                    if (params.props.data.main_description !== datacol.main_description) 
                    {
                        aanpassen = true;
                        params.props.data.main_description = datacol.main_description;     
                        params.props.data.vatcode_id = datacol.id;   
                        params.props.data.vatcode_txt =  datacol.text;  
                        params.props.data.vatsubcode_txt = '';       
                        params.props.data.vatsubcode_id = '';       
                        if (params.props.data.vatcode_txt === '10' || params.props.data.vatcode_txt === 10)
                        {
                            if (datacol.text && datacol.text !== '10')
                            {
                                params.props.data.sub_description = '';       
                            }
                        }
                        else
                        {
                            params.props.data.sub_description = '';       
                        }


                    }
                    if (aanpassen === true)
                    {
                        if (datacol.aftrek)
                        {
                            if (datacol.aftrek.constructor === String)
                            {
                                aftrek = parseFloat(datacol.aftrek);           
                                if (isNaN(aftrek)) {aftrek = 100}
                                if (aftrek === 0)  {aftrek = 100}
                            }
                        }
                        params.props.data.aftrekper = aftrek;       
                        if (datacol.btw)
                        {
                            if (datacol.btw.constructor === String)
                            {
                                btwper = parseFloat(datacol.btw);           
                                if (isNaN(btwper)) {btwper = btwdefper}
                                if (btwper === 0)  {btwper = btwdefper}
                            }
                        }
                        params.props.data.btwper = btwper;       
                        if (params.props.data.mvh.constructor === String)
                        {
                            mvh = parseFloat(params.props.data.mvh);           
                            if (isNaN(mvh)) {mvh = 0}
                        }
                        else
                        {
                            mvh = params.props.data.mvh;           
                        }
                        if (mvh > 0 || mvh < 0)
                        {
                            if (btwper > 0)
                            {
                                btw = mvh * (btwper/100)
                                params.props.data.btwnor = btw;       
                                if (aftrekper > 0)
                                {
                                    btw = btw * (aftrekper/100)
                                }
                            }
                        }
                        params.props.data.btwbed = btw;       
                    }
                }
            }

        }
        if (colId === 'vatsubcode_txt')
        {
            aanpassen = false;
            if (!waarde) 
            {
                if (params.props.data.vatcode_txt !== '10' || params.props.data.vatcode_txt !== 10)
                {
                    params.props.data.sub_description = '';       
                }    
                params.props.data.vatsubcode_id = '';       
                params.props.data.vatsubcode_txt = '';       

            }
            if (datacol )
            {

                if (datacol.main_description)
                {
                    if (params.props.data.vatcode_id !== datacol.maincode) 
                    {
                        aanpassen = true;
                        params.props.data.main_description = datacol.main_description;     
                        params.props.data.vatcode_id = datacol.maincode;      
                        params.props.data.vatcode_txt =  datacol.main_description;  
                    }
                    if (params.props.data.vatsubcode_id !== datacol.id) 
                    {
                        aanpassen = true;

                    }


                    if (params.props.data.vatcode_txt !== '10')
                    {
                        if (params.props.data.sub_description !== datacol.sub_description) 
                        {
                            aanpassen = true;
                            params.props.data.vatsubcode_id = datacol.id;   
                            params.props.data.sub_description = datacol.sub_description;
                        }                    
                    }    
                    if (aanpassen === true)
                    {
                        if (datacol.aftrek)
                        {
                            if (datacol.aftrek.constructor === String)
                            {
                                aftrek = parseFloat(datacol.aftrek);           
                                if (isNaN(aftrek)) {aftrek = 100}
                                if (aftrek === 0)  {aftrek = 100}
                            }
                        }
                        params.props.data.aftrekper = aftrek;       
                        if (datacol.btw)
                        {
                            if (datacol.btw.constructor === String)
                            {
                                btwper = parseFloat(datacol.btw);           
                                if (isNaN(btwper)) {btwper = btwdefper}
                                if (btwper === 0)  {btwper = btwdefper}
                            }
                        }
                        params.props.data.btwper = btwper;       
                        if (params.props.data.mvh.constructor === String)
                        {
                            mvh = parseFloat(params.props.data.mvh);           
                            if (isNaN(mvh)) {mvh = 0}
                        }
                        else
                        {
                            mvh = params.props.data.mvh;           
                        }
                        if (mvh > 0 || mvh < 0)
                        {
                            if (btwper > 0)
                            {
                                btw = mvh * (btwper/100)
                                params.props.data.btwnor = btw;       
                                if (aftrekper > 0)
                                {
                                    btw = btw * (aftrekper/100)
                                }
                            }
                        }
                        params.props.data.btwbed = btw;       
                    }
                }

            }
        }
        if (colId === 'btwper')
        {
            btwper = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(btwper)) {btwper = 0}

            if (params.props.data.mvh.constructor === String)
            {
                mvh = parseFloat(params.props.data.mvh);           
                if (isNaN(mvh)) {mvh = 0}
            }
            else
            {
                mvh = params.props.data.mvh;           
            }
            if (params.props.data.aftrekper.constructor === String)
            {
                aftrekper = parseFloat(params.props.data.aftrekper);           
                if (isNaN(aftrekper)) {aftrekper = 0}
            }
            else
            {
                aftrekper = params.props.data.aftrekper;           
            }
            if (mvh > 0 || mvh < 0)
            {
                if (btwper > 0)
                {
                    btw = mvh * (btwper/100)
                    params.props.data.btwnor = btw;       
                    if (aftrekper > 0)
                    {
                        btw = btw * (aftrekper/100)
                    }
                }
            }
            params.props.data.btwbed = btw;       
        }    

        if (colId === 'aftrekper')
        {
            aftrekper = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(aftrekper)) {aftrekper = 100}

            if (params.props.data.mvh.constructor === String)
            {
                mvh = parseFloat(params.props.data.mvh);           
                if (isNaN(mvh)) {mvh = 0}
            }
            else
            {
                mvh = params.props.data.mvh;           
            }

            if (params.props.data.btwper.constructor === String)
            {
                btwper = parseFloat(params.props.data.btwper);           
                if (isNaN(btwper)) {btwper = 0}
            }
            else
            {
                btwper = params.props.data.btwper;           
            }
            if (mvh > 0 || mvh < 0)
            {
                if (btwper > 0)
                {
                    btw = mvh * (btwper/100)
                    params.props.data.btwnor = btw;       
                    if (aftrekper > 0)
                    {
                        btw = btw * (aftrekper/100)
                    }
                }
            }
            params.props.data.btwbed = btw;       
        }    


        if (colId === 'mvh')
        {
            mvh = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(mvh)) {mvh = 0}
            if (params.props.data.btwper.constructor === String)
            {
                btwper = parseFloat(params.props.data.btwper);           
                if (isNaN(btwper)) {btwper = 0}
            }
            else
            {
                btwper = params.props.data.btwper;           
            }
            if (params.props.data.aftrekper.constructor === String)
            {
                aftrekper = parseFloat(params.props.data.aftrekper);           
                if (isNaN(aftrekper)) {aftrekper = 0}
            }
            else
            {
                aftrekper = params.props.data.aftrekper;           
            }
            if (mvh > 0 || mvh < 0)
            {
                if (btwper > 0)
                {
                    btw = mvh * (btwper/100)
                    params.props.data.btwnor = btw;       
                    if (aftrekper > 0)
                    {
                        btw = btw * (aftrekper/100)
                    }
                }
            }
            params.props.data.btwbed = btw;       
        }    
 
        if (colId === 'btwnor')
        {
            btw = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(btw)) {btw = 0}
            if (params.props.data.btwper.constructor === String)
            {
                btwper = parseFloat(params.props.data.btwper);           
                if (isNaN(btwper)) {btwper = 0}
            }
            else
            {
                btwper = params.props.data.btwper;           
            }
            if (params.props.data.aftrekper.constructor === String)
            {
                aftrekper = parseFloat(params.props.data.aftrekper);           
                if (isNaN(aftrekper)) {aftrekper = 0}
            }
            else
            {
                aftrekper = params.props.data.aftrekper;           
            }
            if (btw > 0 || btw < 0)
            {
                if (btwper > 0)
                {
                    params.props.data.btwnor = btw;       
                    if (aftrekper > 0)
                    {
                        btw = btw * (aftrekper/100)
                    }
                }
            }
            params.props.data.btwbed = btw;       
        }    

        if (colId === 'btwbed')
        {
            btw = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            params.props.data.btwbed = btw;       

            if (isNaN(btw)) {btw = 0}
            if (params.props.data.btwper.constructor === String)
            {
                btwper = parseFloat(params.props.data.btwper);           
                if (isNaN(btwper)) {btwper = 0}
            }
            else
            {
                btwper = params.props.data.btwper;           
            }
            if (params.props.data.aftrekper.constructor === String)
            {
                aftrekper = parseFloat(params.props.data.aftrekper);           
                if (isNaN(aftrekper)) {aftrekper = 0}
            }
            else
            {
                aftrekper = params.props.data.aftrekper;           
            }
            if (btw > 0 || btw < 0)
            {
                if (btwper > 0)
                {
                    if (aftrekper > 0)
                    {
                        btw = btw / (aftrekper/100)
                    }
                    params.props.data.btwnor = btw;       

                }
            }
        }    
 

        if (params.props.node.rowPinned === 'top')
        {
            rowpinnend = params.props.data;
            rowpinnend.soort = 'ONKOSTEN' 
        }
        params.props.node.setData(params.props.data);        
        this.zetTotaal();
        await params.setState({error:terug})
        await this.CheckSaveOnkost(params);
    }       



    componentDidMount(){
        this.setState({data:this.props.data, kol:this.props.kol, griddata:this.props.subonkosten})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };
    async zetTotaal()
    {
        let tot_btwnor = 0;
        let tot_mvh = 0;
        let tot_btwbed = 0;

        gridapi.api.forEachNode(function(node) { 
             

            let it_mvh = node.data.mvh && typeof(node.data.mvh) === 'number' ? node.data.mvh : parseFloat(node.data.mvh);           
            if (isNaN(it_mvh)) {it_mvh = 0}
            let it_btwbed = node.data.btwbed && typeof(node.data.btwbed) === 'number' ? node.data.btwbed : parseFloat(node.data.btwbed);
            if (isNaN(it_btwbed)) {it_btwbed = 0}
            let it_btwnor = node.data.btwnor && typeof(node.data.btwnor) === 'number' ? node.data.btwnor : parseFloat(node.data.btwnor);
            if (isNaN(it_btwnor)) {it_btwnor = 0}
            tot_mvh = tot_mvh + it_mvh;
            tot_btwbed = tot_btwbed + it_btwbed;
            tot_btwnor = tot_btwnor + it_btwnor;
        });   
        tot_mvh = tot_mvh && typeof(tot_mvh) === 'number' ? tot_mvh.toFixed(2) : tot_mvh;
        tot_btwbed = tot_btwbed && typeof(tot_btwbed) === 'number' ? tot_btwbed.toFixed(2) : tot_btwbed;
        tot_btwnor = tot_btwnor && typeof(tot_btwnor) === 'number' ? tot_btwnor.toFixed(2) : tot_btwnor;
        this.setState({tot_btwnor:tot_btwnor, tot_mvh:tot_mvh, tot_btwbed:tot_btwbed})
    }

    async handleSaveSub(waarde){
        let save_subdata = [];
        let that = this;
        gridapi.api.forEachNode(function(node) { 
            node.data.job_id = that.props.jobid;
            save_subdata.push(node.data)            
        });   
        let savemain = this.state.data
        savemain.job_id = this.props.jobid;
        savemain.verwijder = '2';
        savemain.country = this.props.country;
        let savedata = {data:savemain, subonkosten:save_subdata}
        savedata = await SqlFunction.DoSql('ChgVatRefundOnkosten',savedata);
        let retdata = await SqlFunction.DoSql('GetJobMainVatRef',savedata);
        await this.setState({data:retdata.data, griddata:retdata.subonkosten})
        
        let rij = [{id:0,job_id:0,supplier_id:0,vatcode_id:0,vatsubcode_id:0,mainid:0,save:'',verwijder:'',vatcode_txt:'',vatsubcode_txt:'',vatsubcode2_txt:'',factnum:'',factdatum:'', mvh:'',
                        btwper:this.props.dropdata.defbtw,btwbed:'',aftrekper:'',scan:'',pdf:'',opmerking:'',supplier_txt:'',main_description:'',sub_description:'',btwnor:''}]        
        gridapi.api.updateRowData({ add: rij})
        this.zetTotaal();
        var lastrow = this.state.griddata.length;
        gridapi.api.setFocusedCell(lastrow, 'vatcode_txt',);  
        gridapi.api.startEditingCell({
            rowIndex: lastrow,
            colKey: 'vatcode_txt',
        });    
        var params = {
          force: true,
        };
//      gridapi.api.refreshCells(params)          
    }


    async handleSave(){
        let save_subdata = [];

        if (gridapi && gridapi.api && gridapi.api.gridCore && gridapi.api.gridCore.rowRenderer &&
          gridapi.api.gridCore.rowRenderer.pinnedRowModel &&
          gridapi.api.gridCore.rowRenderer.pinnedRowModel.pinnedTopRows[0] &&
          gridapi.api.gridCore.rowRenderer.pinnedRowModel.pinnedTopRows[0].data)
        {
            let pindata = gridapi.api.gridCore.rowRenderer.pinnedRowModel.pinnedTopRows[0].data;

            if (pindata.save === true)
            {
                pindata.job_id = this.props.jobid;
                pindata.verwijder = '2';
                save_subdata.push(pindata)
            }
        }
        gridapi.api.forEachNode(function(node) { 
            save_subdata.push(node.data)            
        });   


        let savemain = this.state.data
        savemain.job_id = this.props.jobid;
        savemain.verwijder = '2';
        savemain.country = this.props.country;
        let savedata = {data:savemain, subonkosten:save_subdata}
        await SqlFunction.DoSql('ChgVatRefundOnkosten',savedata);

        if (this.props.close) 
        {
            this.props.close()
        }
    }
    CheckSaveOnkost(params) {
      let terug = false
      let mvh = 0;
      if (!params.props.data.vatcode_id)
      {
          terug = true;
      }
      else
      {
         if (params.props.data.vatcode_id.length < 4)
         {
            terug = true;
         }
      }
      if (!params.props.data.mvh)
      {
          terug = true;
      }
      else
      {
        if (params.props.data.mvh.constructor === String)
        {
            mvh = parseFloat(params.props.data.mvh);           
            if (isNaN(mvh)) {terug = true}
        }
      }

      if (terug === true)
      {
          params.props.data.save = false;
      }
      else
      {
        params.props.data.save = true;
      }
    }

    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var tableheight = Math.floor(window.innerHeight * .30) + 'px';
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : top;
        return (

            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'400px',position: 'absolute'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'scroll',height:'60vh'}}  className={classes.blockContent}>
                  <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick ={true} noKeyboard={true}>
                    {({getRootProps, getInputProps}) => (
                      <section className="container" style={{width:'100%',maxWidth:'100%'}}>
                        <div {...getRootProps({className: 'dropzone'})}>
                          <input {...getInputProps()} />
                          <button style={{display: 'none'}} id={accdropid} type="button" onClick={openDialog}></button>         
                          <FileDownload formid={accformid} frameid={accframeid} DocData={this.state.DownloadData}/>

                          <Grid container style={{width: '100%', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                            <Grid container>
                                <Grid style={{paddingLeft: '20px', paddingRight: '20px', display: 'flex'}} item sm={6} md={6} lg={6}>
                                    <Grid style={{paddingLeft: '20px', paddingRight: '20px'}} item sm={6} md={6} lg={6}>
                                        <Grid item sm={12} md={12} lg={12}>
                                          <EditAutoSelect className="card-title m-t-10"
                                            value={this.state.data.supplier_txt}
                                            labelText="Leverancier"
                                            lijst='AllSuppliers'
                                            colId="supplier_txt"
                                            selectcel='supplier_id'
                                            addRecord={this.addRecord}
                                            id={this.state.data.supplier_id}
                                            ValueCheck={(params,value,type,overzdata) => this.ValueCheck(params,value,type,"supplier_txt",true,overzdata)}
                                            setError={(params,value,type) => this.ErrorCheck(params,value,type,'supplier_txt')}
                                          />
                                          <h6>{this.state.data.sup_vatnumber}</h6>
                                          <h6>{this.state.data.supplier_txt}</h6>
                                          <h6>{this.state.data.sup_adres}</h6>
                                          <h6>{this.state.data.sup_zipcode}</h6>
                                          <h6>{this.state.data.sup_country_txt}</h6>
                                        </Grid>

                                        <Grid item sm={12} md={12} lg={12}>
                                            <div style={{marginLeft: '-22px'}}>
                                                <EditCheck className="card-title m-t-10"
                                                  value={this.state.data.scan}
                                                  disabled={this.state.save}
                                                  labelPlacement="start"
                                                  labelText="Scan"
                                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'scan')}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <Button  disabled={this.state.save} style={{background:'#8898aa',color: '#fff'}} onClick={() => this.handleDoc()}>
                                               Ophalen Document
                                            </Button>
                                        </Grid>



                                        {this.state.data.pdf && this.state.data.pdf.length > 2 &&
                                            <Grid item sm={12} md={12} lg={12}>
                                                <small className="text-muted p-t-30 db">Pdf</small>                  
                                                <h6 style={{cursor:'pointer'}} onClick={() => this.ClickDocument({id:this.state.data.pdf_id})}>{this.state.data.pdf}</h6> 
                                            </Grid>
                                        }


                                    </Grid>
                                    <Grid style={{paddingLeft: '20px', paddingRight: '20px'}} item sm={6} md={6} lg={6}>                                        
                                        <Grid item sm={12} md={12} lg={12}>
                                            <EditString className="card-title m-t-10"
                                                value={this.state.data.factnum}
                                                coltype = 'STR'                        
                                                labelText="Factuurnummer"
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'factnum')}
                                                setError={(params,value,type) => this.ErrorCheck(params,value,type,'factnum')}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <EditDateTime className="card-title m-t-10"
                                                value={this.state.data.factdatum}
                                                coltype = 'STR'                        
                                                labelText="Factuur datum"
                                                inputProps={{value:this.state.data.factdatum}}
                                                calstyle={{width:'50%', paddingLeft: '20px', paddingRight: '20px'}}
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'factdatum',false)}
                                                setError={(params,value,type) => this.ErrorCheck(params,value,type,'factdatum')}
                                            />
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <small>Munteenheid</small>
                                            <h6>{this.state.data.munt_eenheid}</h6>
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <small>Totaal MVH</small>
                                            <h6>{this.state.tot_mvh && typeof(this.state.tot_mvh) === 'number' ? 
                                                new Intl.NumberFormat('nl-BE',{ minimumFractionDigits: 2 }).format(this.state.tot_mvh.toFixed(2)) : new Intl.NumberFormat('nl-BE',{ minimumFractionDigits: 2 }).format(parseFloat(this.state.tot_mvh).toFixed(2))}</h6>
                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <small>Totaal Btw bedrag</small>
                                            <h6>{this.state.tot_btwnor && typeof(this.state.tot_btwnor) === 'number' ? 
                                                new Intl.NumberFormat('nl-BE',{ minimumFractionDigits: 2 }).format(this.state.tot_btwnor.toFixed(2)) : new Intl.NumberFormat('nl-BE',{ minimumFractionDigits: 2 }).format(parseFloat(this.state.tot_btwnor).toFixed(2))}</h6>

                                        </Grid>
                                        <Grid item sm={12} md={12} lg={12}>
                                            <small>Totaal Recup Btw</small>
                                            <h6>{this.state.tot_btwbed && typeof(this.state.tot_btwbed) === 'number' ? 
                                                new Intl.NumberFormat('nl-BE',{ minimumFractionDigits: 2 }).format(this.state.tot_btwbed.toFixed(2)) : new Intl.NumberFormat('nl-BE',{ minimumFractionDigits: 2 }).format(parseFloat(this.state.tot_btwbed).toFixed(2))}</h6>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid style={{paddingLeft: '20px', paddingRight: '20px'}} item sm={6} md={6} lg={6}>
                                    <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-4px'}}>
                                            <EditString className="card-title m-t-10"
                                                value={this.state.data.opmerking}
                                                coltype = 'STR'                        
                                                labelText="Opmerking"
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'opmerking')}
                                                setError={(params,value,type) => this.ErrorCheck(params,value,type,'opmerking')}
                                                multiline={true}   
                                            />
                                        </div>
                                    </Grid>                                
                                </Grid>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                  <TableGrid
                                    tableheight={tableheight}
                                    columns={this.state.kol}
                                    data={this.state.griddata} 
                                    sortable={true}
                                    sizetofit={true}
                                    suppressMenu={true}
                                    enableRowGroup={false}
                                    suppressColumnVirtualisation={true}
                                    CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                                    CellValueCheck={(params,value,error) => this.CellCheck(params,value,error)}
                                    filter={false}
                                    getFilter={(params) => this.getFilter(params)}
                                    headerComponentParams = {null}
                                    editable={true}
                                    sleutel="JobSubVatref"      
                                    GridReady={this.onGridReady}
                                  />

                            </Grid>
                          </Grid>
                        </div>
                      </section>
                    )}
                  </Dropzone>

                  {this.state.PopUpScreen}
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  <Button disabled={this.state.save} style={{marginBottom: '30px'}} color="primary"  onClick={() => this.handleSave()}>
                    Bewaren
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopJobEditSubVatRefund.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobEditSubVatRefund)));

