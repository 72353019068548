import { AllCONTACTS_SUCCESS, GETCONTACT_SUCCESS, GETDOCCONTACT_SUCCESS,GETCORCONTACT_SUCCESS, CONT_TAB } from '../constants/';

const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const AllContactsSuccess = (payload) => {
  return {
    type: AllCONTACTS_SUCCESS,
    payload: payload.response.data
  }
}

export const setTab = (payload) => {
    return {
        type: CONT_TAB,
        payload
    }
}


export const GetContactSuccess = (payload) => {

  return {
    type: GETCONTACT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetDocContactSuccess = (payload) => {
  return {
    type: GETDOCCONTACT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetCorContactSuccess = (payload) => {
  return {
    type: GETCORCONTACT_SUCCESS,
    payload: payload.response.data
  }
}

export function AllContacts() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllContacts', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllContactsSuccess({ response })) },
      () => { dispatch(AllContactsSuccess(null)) },
    );
  }
}

export function GetContact(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetContact', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetContactSuccess({ response })) },
      () => { dispatch(GetContactSuccess(null)) }
    );
  }
}

export function GetDocContact(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetDocContact', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetDocContactSuccess({ response })) },
      () => { dispatch(GetDocContactSuccess(null)) }
    );
  }
}

export function GetCorContact(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetCorContact', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetCorContactSuccess({ response })) },
      () => { dispatch(GetCorContactSuccess(null)) }
    );
  }
}


export function CreateContact(creavalues) {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, parent_account: '', zipcode: '', extern:'' , zipcode_txt: '', vervaldagid:'',account_name: '', main_phone: '', last_name: '', street: '', first_name: '', salutation: '', jobtitle: '', mobile_phone: '', e_mail: '', info: '' }
    if (creavalues) {
      if (creavalues.accountid) {
        data.parent_account = creavalues.accountid;
        data.account_name = creavalues.account_name;
      }
    }
    response.data = { data: data }
    dispatch(GetContactSuccess({ response }));
  }
}
