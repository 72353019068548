import React, {createRef } from 'react';
import moment from 'moment'
import 'moment/locale/nl-be'

import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditSelect from "../../components/editselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import FileDownload from "../../components/FileDownload.jsx";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Dropzone from 'react-dropzone';
import {AllLandenAcc} from "../../redux/settings/landActions";

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point 
  if (dropzoneRef.current) {
    dropzoneRef.current.open()
  }
};
moment.locale('nl-be');
const accdropid = GenFunc.MakeId()
const accframeid = GenFunc.MakeId()
const accformid = GenFunc.MakeId()

const seeformid = GenFunc.MakeId()
const docformid = GenFunc.MakeId()
var countryobj = null;
class PopDetailVolm extends React.Component {
    state = {
        data: this.props.data,
        filename:'',
        opgehaald:null,
        save:false,
        DownloadData: { id: '' },
        StatFileUpload: 'ok',
        nivo:0,
        refid: GenFunc.MakeId(),
        editorState: '',
        velden:null,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);

        this.handleSave = this.handleSave.bind(this);
        this.SaveCheck = this.SaveCheck.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.handleDoc = this.handleDoc.bind(this);
        this.opHalen = this.opHalen.bind(this);
        this.SetObject = this.SetObject.bind(this)

    }

    async componentDidMount(){
        await this.setState({data: this.props.data, opgehaald:false})
        await this.opHalen();
    }
    async opHalen(){
        if (document.getElementById(docformid))
        {
          if (this.state.data && this.state.data.doc_id && this.state.data.doc_id.length > 4)
          {
            document.getElementById(docformid).submit();
          }
        }
    }

    async onDrop(files) {
        if (this.state.data.id === 0 || this.state.data.id === '0') {return}
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          formData.append('file', file);

        }
        formData.set('accountid', this.state.data.account_id);
        formData.set('type', 'account');    
        formData.set('volm_id', this.state.data.id);    
        await SqlFunction.DoSqlBody('saveFile', formData);
        let retdata = await SqlFunction.DoSql('GetVolm',this.state.data);
        if (retdata && retdata.data) 
        {
          await this.setState({data:retdata.data})
          await this.opHalen();
        }

    };
    async handleDoc()
    {
      let savedata = await SqlFunction.DoSql('ChgVolm',this.state.data);
      let retdata = await SqlFunction.DoSql('GetVolm',savedata);
      this.setState({data:retdata.data})
      let element = document.getElementById(accdropid);
      element.click()
    }



    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens

      let data = this.state.data;
      let terdata = null;
      terdata = await SqlFunction.DoSql('ChgVolm',data);
      if (this.props.close) 
      {
        this.props.close(terdata)
      }
    }

    SaveCheck(data) 
    {
      let chsave = false;
      if (data.omschrijving.length < 4 )
      {
        chsave = true;
      }
      if (chsave === false)
      {
        if (data.type.length < 4 )
        {
          chsave = true;
        }

      }
      if (chsave === false)
      {
        if (data.account_id.length < 4 )
        {
          chsave = true;
        }

      }
      if (chsave === false)
      {
        if (data.country.length < 4 )
        {
          chsave = true;
        }

      }

      return chsave
    }

    async ErrorCheck(that,waarde,type,colId,verplicht) 
    {
      let terug = false;
      let data = this.state.data;
      if (verplicht === '1' || verplicht === true)
      {
        if(waarde.length < 1) 
        {
          terug = true;            
        }
      }
      that.setState({error:terug})
      let wsave = await this.SaveCheck(data);
      this.setState({save:wsave})
    }       

    SetObject(object,colid)
    {
        switch (colid) 
        {
          case 'country_txt':
            countryobj = object;
            break;

          default:    
            break;
        } 
    }


    async ValueCheck(that,waarde,type,colId,verplicht) 
    {
      let terug = false;
      let data = this.state.data;
      switch (colId) 
      {
        case 'type_txt':
        case 'type':
          if (!waarde.id)
          {
            terug = true;
            data.type_txt = '';
            data.type = '';            
          }
          else
          {
            if (waarde.id && waarde.id.length < 4)
            {
              terug = true
              data.type_txt = '';
              data.type = '';            
            }
            else
            {
              data.type_txt = waarde.text;
              data.type = waarde.id;
              if (this.state.data && this.state.data.account_id)
              {
                await this.props.dispatch(AllLandenAcc(this.props,this.state.data.account_id,waarde.id));
                if (countryobj)
                {
                    countryobj.setState({data: this.props.land.AllDataAcc && this.props.land.AllDataAcc.data ? this.props.land.AllDataAcc.data : []})
                }
              }
            }
          }
          break;

        case 'country':
        case 'country_txt':

          if (!waarde.id)
          {
            terug = true;
            data.country_txt = '';
            data.country = '';            

          }
          else
          {
            if (waarde.id && waarde.id.length < 4)
            {
              terug = true
              data.country_txt = '';
              data.country = '';            
            }
            else
            {
              data.country_txt = waarde.value;
              data.country = waarde.id;
            }
          }
          break;

        case 'account_id':
        case 'account_name':

          if (!waarde.id)
          {
            terug = true;
            data.account_name = '';
            data.account_id = '';            
          }
          else
          {
            if (waarde.id && waarde.id.length < 4)
            {
              terug = true
              data.account_name = '';
              data.account_id = '';            
            }
            else
            {
              data.account_name = waarde.value;
              data.account_id = waarde.id;
            }
          }
          break;

        default:    
          data[colId] = waarde;
          if (verplicht === '1' || verplicht === true)
          {
            if(waarde.length < 1) 
            {
              terug = true;            
            }
          }
          break;
      } 
      that.setState({error:terug})
      let wsave = await this.SaveCheck(data);
      this.setState({data:data,save:wsave})
    }       

    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : '10px';

        let vld_documentif = (<form id={docformid} action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target={seeformid}  style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='GetPdfVolm' id="function" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.state.data && this.state.data.id} id="id" name="id"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid"/>
        </form>);  


        return (
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <PerfectScrollbar className="sidebar-nav">
                  <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                    <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick ={true} noKeyboard={true}>
                      {({getRootProps, getInputProps}) => (
                        <section className="container" style={{width:'100%',maxWidth:'100%'}}>
                          <div {...getRootProps({className: 'dropzone'})}>
                            <input {...getInputProps()} />
                            <button style={{display: 'none'}} id={accdropid} type="button" onClick={openDialog}></button>         
                            <FileDownload formid={accformid} frameid={accframeid} DocData={this.state.DownloadData}/>

                            <Grid container spacing={1}>
                              <Grid item sm={6} md={6} lg={6}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.omschrijving}
                                  labelText="Omschrijving "
                                  coltype = 'STR'                        
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'omschrijving',true)}
                                  setError={(params,value,type) => this.ValueCheck(params,value,type,'omschrijving',true)}
                                />
                              </Grid>
                              <Grid item sm={6} md={6} lg={6}>
                                <div style={{marginTop: '-4px'}}>
                                  <EditDateTime className="card-title m-t-10"
                                      value={this.state.data.datum}
                                      coltype = 'STR'                        
                                      labelText='Datum'
                                      inputProps={{value:this.state.data.datum}}
                                      formControlProps={{fullWidth: true}}
                                      ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'datum',true)}
                                      setError={(params,value,type) => this.ErrorCheck(params,this.state.data.datum,type,'datum',true)}
                                  />
                                </div>
                              </Grid>                      
                              <Grid item sm={6} md={6} lg={6}>
                                <EditAutoSelect className="card-title m-t-10"
                                    value={this.state.data.account_name}
                                    labelText='Klant'
                                    lijst='AllAccounts'
                                    colId={this.state.data.account_id}
                                    selectcel='account_id'
                                    id={this.state.data.account_id}
                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'account_id',true)}
                                    setError={(params,value,type) => this.ErrorCheck(params,this.state.data.account_id,type,'account_id',true)}
                                />
                              </Grid>                      
                              <Grid item sm={6} md={6} lg={6}>
                                <EditSelect className="card-title m-t-10"
                                  value={this.state.data.type_txt}
                                  data={this.state.data}
                                  labelText="Soort volmacht"
                                  type='AllRegType'
                                  colId='type_txt'
                                  selectcel='type'
                                  id={this.state.data.type}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'type_txt',true)}
                                  setError={(params,value,type) => this.ErrorCheck(params,value,type,'type_txt',true)}
                                />
                              </Grid>
                              <Grid item sm={6} md={6} lg={6}>
                                <EditAutoSelect className="card-title m-t-10"
                                    value={this.state.data.country_txt}
                                    labelText='Land'
                                    lijst='AllLandenAcc'
                                    colId={this.state.data.country}
                                    SetObject = {(object,colid) => this.SetObject(object,"country_txt")} 
                                    selectcel='country'
                                    id={this.state.data.country}
                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'country',true)}
                                    setError={(params,value,type) => this.ErrorCheck(params,this.state.data.country,type,'country',true)}
                                />
                              </Grid>                      
                              <Grid item sm={6} md={6} lg={6}>
                                  <Button  disabled={this.state.save} style={{marginTop:'17px', background:'#8898aa',color: '#fff'}} onClick={() => this.handleDoc()}>
                                    Ophalen Document
                                  </Button>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                              <Grid item sm={12} md={12} lg={12} style={{background: 'white',height: '53vh', overflow: 'auto',paddingLeft: '10px',paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px'}}>
                                <iframe name={seeformid} id={seeformid} title={seeformid} style={{width: '100%', height:'100%'}} frameborder="0"     > </iframe>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                            </Grid>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {vld_documentif}
                  </DialogContent>
                </PerfectScrollbar>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  <Button color="primary" disabled={this.state.save} onClick={() => this.handleSave()}>
                    Bewaren
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
  ...state
});

PopDetailVolm.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopDetailVolm)));

