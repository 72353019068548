import { AllACTIVITIES_SUCCESS, GETACTIVITY_SUCCESS, MYOPENACTIVITIES_SUCCESS, ALLMYACTIVITIES_SUCCESS } from '../constants/';

const INIT_STATE = {
    AllData: null,
    MainData: null,
    MyOpenData: null,
    AllMyData:null,
    MyNewCount:0,

}

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        
        case MYOPENACTIVITIES_SUCCESS:
            let teller = 0;
            action.payload.map((notification, index) => {
                    if (notification.eigenaar === notification.userfrom)
                    {
                        if (notification.newfrom === 1 || notification.newfrom === '1')
                        {
                            teller = teller + 1
                        }
                    }
                    if (notification.eigenaar === notification.userto)
                    {
                        if (notification.newto === 1 || notification.newto === '1')
                        {
                            teller = teller + 1
                        }
                    }
                    return teller;
            })


            return {
                ...state,
                MyOpenData: action.payload,
                MyNewCount: teller
            };

        case ALLMYACTIVITIES_SUCCESS:
            return {
                ...state,
                AllMyData: action.payload
            };


        case AllACTIVITIES_SUCCESS:
            return {
                ...state,
                AllData: action.payload
            };

        case GETACTIVITY_SUCCESS:
            return {
                ...state,
                MainData: action.payload
            };


        default:
            return {
                ...state
            };


    }
};
