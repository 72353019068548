import { AllACCOUNTS_SUCCESS, GETACCOUNT_SUCCESS, GETDOCACCOUNT_SUCCESS, GETCONTACCOUNT_SUCCESS, GETREGACCOUNT_SUCCESS, GETTARACCOUNT_SUCCESS, GETJOBSACCOUNT_SUCCESS, 
    GETACCIJNZENACCOUNT_SUCCESS, GETVATREFUNDACCOUNT_SUCCESS, GETDIRREGACCOUNT_SUCCESS, GETACCCHANGES_SUCCESS, GETCORACCOUNT_SUCCESS,  GETALLFACTURENACCOUNT_SUCCESS, 
    GETFACTUURACCOUNT_SUCCESS, GETFACTURATIEACCOUNT_SUCCESS, GETALLFACTUREN_SUCCESS, ACC_TAB, GETVOLMACCOUNT_SUCCESS, GETACCOUNTINVOICEDETAILS_SUCCESS } from '../constants/';

const INIT_STATE = {
    AllData: null,
    MainData: null,
    DocData: null,
    VolmData: null,
    CorData: null,
    ContData: null,
    RegData: null,
    TarData: null,
    JobsData: null,
    AccijnzenData: null,
    VatrefundData: null,
    DirregData: null,
    AccChangesData: null,
    AllFacturenData: null,
    AllFactuurAccountData: null,
    MainFactuurData: null,
    FacturatieAccountData: null,
    LastTab: '1',
    AccountInvoiceDetailsData: null,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case AllACCOUNTS_SUCCESS:
            return {
                ...state,
                AllData: action.payload
            };
          

        case ACC_TAB:
            return {
                ...state,
                LastTab: action.payload
            };


        case GETACCOUNT_SUCCESS:
            return {
                ...state,
                MainData: action.payload
            };

        case GETDOCACCOUNT_SUCCESS:
            return {
                ...state,
                DocData: action.payload
            };

        case GETVOLMACCOUNT_SUCCESS:
            return {
                ...state,
                VolmData: action.payload
            };

        case GETCORACCOUNT_SUCCESS:
            return {
                ...state,
                CorData: action.payload
            };

        case GETTARACCOUNT_SUCCESS:
            return {
                ...state,
                TarData: action.payload
            };

        case GETREGACCOUNT_SUCCESS:
            return {
                ...state,
                RegData: action.payload
            };

        case GETCONTACCOUNT_SUCCESS:
            return {
                ...state,
                ContData: action.payload
            };

        case GETJOBSACCOUNT_SUCCESS:
            return {
                ...state,
                JobsData: action.payload
            };

        case GETACCIJNZENACCOUNT_SUCCESS:
            return {
                ...state,
                AccijnzenData: action.payload
            };   
            
        case GETVATREFUNDACCOUNT_SUCCESS:
            return {
                ...state,
                VatrefundData: action.payload
            };   

        case GETDIRREGACCOUNT_SUCCESS:
            return {
                ...state,
                DirregData: action.payload
            }; 
            
        case GETACCCHANGES_SUCCESS:
            return {
                ...state,
                AccChangesData: action.payload
            }; 

        case GETALLFACTURENACCOUNT_SUCCESS:
            return {
                ...state,
                AllFactuurAccountData: action.payload
            }; 

        case GETFACTUURACCOUNT_SUCCESS:
            return {
                ...state,
                MainFactuurData: action.payload
            }; 

        case GETALLFACTUREN_SUCCESS:
            return {
                ...state,
                AllFacturenData: action.payload
            };

        case GETFACTURATIEACCOUNT_SUCCESS:
            return {
                ...state,
                FacturatieAccountData: action.payload
            };

        case GETACCOUNTINVOICEDETAILS_SUCCESS:
            return {
                ...state,
                AccountInvoiceDetailsData: action.payload
            };

        default:
            return state;
    }
};
