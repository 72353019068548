import { ALLLANDEN_SUCCESS, GETLAND_SUCCESS, ALLONKOSTEN_SUCCESS, GETONKOSTENLAND_SUCCESS, GETKANTORENLAND_SUCCESS, GETKLANTENLAND_SUCCESS, GETREGLAND_SUCCESS, GETTAALCODE_SUCCESS, 
    ALLTAALCODES_SUCCESS, GETFACTUUROPBOUW_SUCCESS, GETVOLMACHTENLANDEN_SUCCESS,GETVATREFCODELAND_SUCCESS,ALLLANDENACC_SUCCESS,ALLPERIODESACC_SUCCESS } from '../constants/';

const INIT_STATE = {
    AllData: null,
    AllDataAcc: null,
    MainData: null,
    AllDataPeriodeAcc: null,
    AllOnkostenData: null,
    MainOnkostenData: null,
    KantorenData: null,
    KlantenData: null,
    VatRefData:null,
    RegData: null,
    AllTaalcodesData: null,
    MainTaalcodeData: null,
    FactuuropbouwData: null,
    VolmachtenData: null,
}


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ALLLANDEN_SUCCESS:
            return {
                ...state,
                AllData: action.payload
            };


        case ALLPERIODESACC_SUCCESS:
            return {
                ...state,
                AllDataPeriodeAcc: action.payload
            };

        case ALLLANDENACC_SUCCESS:
            return {
                ...state,
                AllDataAcc: action.payload
            };

        case GETLAND_SUCCESS:
            return {
                ...state,
                MainData: action.payload
            }

        case ALLONKOSTEN_SUCCESS:
            return {
                ...state,
                AllOnkostenData: action.payload
            }


        case GETONKOSTENLAND_SUCCESS:
            return {
                ...state,
                MainOnkostenData: action.payload
            }

        case GETKANTORENLAND_SUCCESS:
            return {
                ...state,
                KantorenData: action.payload
            }


        case GETVATREFCODELAND_SUCCESS:
            return {
                ...state,
                VatRefData: action.payload
            }

        case GETKLANTENLAND_SUCCESS:
            return {
                ...state,
                KlantenData: action.payload
            }

        case GETREGLAND_SUCCESS:
            return {
                ...state,
                RegData: action.payload
            };

        case ALLTAALCODES_SUCCESS:
            return {
                ...state,
                AllTaalcodesData: action.payload
            };

        case GETTAALCODE_SUCCESS:
            return {
                ...state,
                MainTaalcodeData: action.payload
            };

        case GETFACTUUROPBOUW_SUCCESS:
            return {
                ...state,
                FactuuropbouwData: action.payload
            };

        case GETVOLMACHTENLANDEN_SUCCESS:
            return {
                ...state,
                VolmachtenData: action.payload
            };

        default:
            return state;


    }
};