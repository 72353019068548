import React from 'react';
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SqlFunction from "../../functions/sql_functions";
import { GetAllFacturenAccount, GetAllFacturen, GetFactuurAccount, GetAccountInvoiceDetails } from '../../redux/settings/accountActions.js';
import Validation from "../../functions/validation.js";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import EditSelect from "../../components/editselect.jsx";
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import IconButton from '@material-ui/core/IconButton';
import Delete from "@material-ui/icons/Delete";
import { AllJobsToInvoiceAccount, JobDefMainAllCodes, JobDefListCodes } from '../../redux/settings/jobsActions.js';
import { GetFactuuropbouw, AllLanden } from '../../redux/settings/landActions.js';
import GenFunc from "../../functions/gen_functions";
import { AllBetalingsvoorwaarden } from '../../redux/settings/paramActions.js';


const mapStateToProps = state => ({
    ...state
  });

var ereloontotaalexclbtw = 0;
var totaalbtw = 0;
var ereloontotaalinclbtw = 0;


class Factuurview extends React.Component {
    state = {
        tableheight: Math.floor(window.innerHeight * .70) + 'px',
        data: this.props.account,
        factlines: [],
        facttype: "",
        makefactuur: false,
        openfactuur: false,
        countjobchecked: 0,
        alljobschecked: false,
        invoicejoblist: [],
        ereloontotaalexclbtw: 0,
        btwpercentage: 0,
        totaalbtw: 0,
        ereloontotaalinclbtw: 0,
        factsave: false,
        overview: false,
        factuurnr: "",
        factuurdate: "",
        factuurenddate: "",
        taal: "",
        klantland: "",
        save: false,
        error: false,
        extralinesdata: [],
        dbfactuur: false,
        factuurbetvw: "",
        klant: "",
        facturatie_acc: "",
        factuurklant: "",
        factuurtype: [],
        alljobsdata: [],
        jobsdata: [],
        alljobcols: [],
        jobcols: [],
        pdftext: "",
        getNodes: false,
        invoiceId: 0,
        betaalvw: false,
        invoicedescription: "",
        invoicetype: { id:0, text:"" },
    }

    constructor(props, context) {
        super(props);
        this.handleInvoiceType = this.handleInvoiceType.bind(this);
        this.getjobsdata = this.getjobsdata.bind(this);
        this.getDirreg = this.getDirreg.bind(this);
        this.getAccijnzen = this.getAccijnzen.bind(this);
        this.getVatrefund = this.getVatrefund.bind(this);
        this.getExtraLines = this.getExtraLines.bind(this);
        this.factClick = this.factClick.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.checkSave = this.checkSave.bind(this);
        this.addExtraLine = this.addExtraLine.bind(this);
        this.onlyNumbers = this.onlyNumbers.bind(this);
        this.extraLinesToFactLines = this.extraLinesToFactLines.bind(this);
        this.removeExtraLine = this.removeExtraLine.bind(this);
        this.jobCellClick = this.jobCellClick.bind(this);
        this.handleRowColors = this.handleRowColors.bind(this);
        this.handleNodes = this.handleNodes.bind(this);
        this.makeInvoice = this.makeInvoice.bind(this);
        this.saveInvoice = this.saveInvoice.bind(this);
        this.sortInvoiceJobs = this.sortInvoiceJobs.bind(this);

        this.ValueCheckDetail = this.ValueCheckDetail.bind(this);

    }

    async componentDidMount(){
        this.setState({ data: this.props.account })
        await this.props.dispatch(AllLanden(this.props));
        await this.props.dispatch(AllBetalingsvoorwaarden(this.props));
        await this.props.dispatch(JobDefMainAllCodes(this.props));
        
        this.setState({ overview: true })
    }

    ValueCheckDetail(that,waarde,type,colId,index,status) {
      let detail_data_list = this.state.invoicejoblist;
      let detail_data = detail_data_list[index];

      switch(colId){
        case 'saldotebet':
          detail_data[colId] = waarde;
          detail_data['aangiftebtw'] = waarde;

          break;

        case 'regnummer':
          detail_data[colId] = waarde;
          detail_data['regnr'] = waarde;

          break;

        case 'tarief':
          detail_data[colId] = waarde;
          detail_data['ereloon'] = waarde;
          detail_data['vat_facttot'] = waarde;
          break;

        case 'vat_basisfact':
          detail_data[colId] = waarde;
          detail_data['teruggave'] = waarde;
          if (status === true)
          {
            detail_data['vat_basisfact_val'] = waarde * detail_data['vat_koers'];
            detail_data['teruggave_euro'] = detail_data['vat_basisfact_val'];
          }
          break;


        case 'vat_basisfact_val':
          detail_data[colId] = waarde;
          detail_data['teruggave_euro'] = waarde;
          if (status === true)
          {
            detail_data['vat_basisfact'] = waarde / detail_data['vat_koers'];
            detail_data['teruggave'] = detail_data['vat_basisfact'];
          }
          break;

        case 'vat_koers':
          detail_data[colId] = waarde;
          if (status === true)
          {
            detail_data['vat_basisfact'] = detail_data['vat_basisfact_val'] /waarde;
            detail_data['teruggave'] = detail_data['vat_basisfact'];
          }
          break;

        default:
          detail_data[colId] = waarde;
          break;
      }
      detail_data['upd'] = true;
      detail_data_list[index] = detail_data;
      this.setState({invoicejoblist:detail_data_list})
      return;
    }     

    async componentDidUpdate(prevProps, prevState){
        if(prevProps.status !== this.props.status){
            switch(this.props.status){
                case "newInvoice":
                    await this.props.dispatch(AllJobsToInvoiceAccount(this.props.account.MainData.data.id));
                    await this.props.dispatch(GetAccountInvoiceDetails(this.props.account.MainData.data.id));
                    let invoiceDetails = this.props.account.AccountInvoiceDetailsData.data;

                    await this.props.dispatch(GetFactuuropbouw(invoiceDetails.taalcode));

                    let betvw = false;
                    if(invoiceDetails.vermelding !== "") betvw = true;

                    await this.props.dispatch(JobDefListCodes(invoiceDetails.taal));
                    let facttype = this.props.jobs.JobDefListCodesData.data;

                    let jobdata = [];
                    for (let i = 0; i < this.props.jobs.AllJobsToInvoiceAccountData.data.length; i++) {
                        let job = this.props.jobs.AllJobsToInvoiceAccountData.data[i];

                        let splittedJobCode = (job.code).split("_");
                        let jobCode = splittedJobCode.length === 1 ? splittedJobCode[0] : splittedJobCode[1];

                        job['selected'] = false;
                        job['jobtype'] = facttype[jobCode]['description'];
                        job['jobsubject'] = facttype[jobCode]['subject'];
                        job['jobtypecode'] = jobCode;
                        jobdata.push(job);
                    }
                    let cols = this.props.jobs.AllJobsToInvoiceAccountData.kol;

                    this.setState({ makefactuur: true, openfactuur: false, overview: false, factsave: false, extralinesdata: [], ereloontotaalexclbtw: 0, invoicejoblist: [], alljobsdata: jobdata, 
                        alljobcols: cols, taal: invoiceDetails.taal, factuurklant: invoiceDetails.factklant, facturatie_acc: invoiceDetails.facturatie_acc, factuurbetvw: invoiceDetails.factbetvw, betaalvw: betvw, btwpercentage: invoiceDetails.btwperc, klant: invoiceDetails.klant, 
                        factuurtype: facttype, klantland: invoiceDetails.land, invoicedescription: invoiceDetails.vermelding, invoicetype: { id:0, text:"" } });
                    this.props.canMakePdf(false);
                    break;

                case "overview":
                    await this.props.dispatch(GetAllFacturenAccount(this.props.account.MainData.data.id));
                    this.setState({ makefactuur: false, openfactuur: false, overview: true, factsave: false, extralinesdata: [], ereloontotaalexclbtw: 0, invoicejoblist: [] })
                    this.props.canMakePdf(false);
                    break;

                case "pdf":
                    document.getElementById('INVOICEPDF').submit();
                    this.props.handleUpdates("");
                    break;

                case "pdfBrutax":
                    document.getElementById('INVOICEPDFBRUTAX').submit();
                    this.props.handleUpdates("");
                    break;

                case "makeInvoice":
                    this.makeInvoice();
                    break;

                case "saveInvoice":
                    this.saveInvoice();
                    break;

                case "back":
                    this.setState({ makefactuur: true, openfactuur: false, overview: false, dbfactuur: false });
                    this.props.canMakePdf(false);
                    break;

                default:
                    return;
            }
        }
    }

    componentWillUnmount(){
        this.props.canMakePdf(false);
    }

    handleInvoiceType(type){
        let jobs = [...this.state.alljobsdata];
        let cols = [...this.state.alljobcols];
        switch(type){
            case "Directe Registratie":
                let drjobs = jobs.filter((job) => {
                    job.selected = false;
                    return (job.jobsubject === "c401ca14238a0b923820dcc509a6f75849b" || job.jobsubject === "a8017f4f679a2f3e71d9181a67b7542122c")

                })
                let drcols = cols.filter((col) => {
                    return col.field !== "vat_facttot";
                })
                this.setState({ jobsdata: drjobs, jobcols: drcols, getNodes: true, 
                    invoicejoblist: [], countjobchecked: 0, alljobschecked: false });
                break;

            case "Accijnzen":
                let accjobs = jobs.filter((job) => {
                    job.selected = false;
                    return (job.jobsubject === "c8011e2728d9d4c2f636f067f89cc14862c" || job.jobsubject === "a8017f4f679a2f3e71d9181a67b7542122c")
                })
                let accijnscols = cols.filter((col) => {
                    return col.field !== "";
                })
                this.setState({ jobsdata: accjobs, jobcols: accijnscols, getNodes: true, 
                    invoicejoblist: [], countjobchecked: 0, alljobschecked: false });
                break;

            case "VAT-Refund":
                let vatjobs = jobs.filter((job) => {
                    job.selected = false;
                    return (job.jobsubject === "ec01cb3c87e4b5ce2fe28308fd9f2a7baf3" || job.jobsubject === "a8017f4f679a2f3e71d9181a67b7542122c")

                })
                let vatcols = cols.filter((col) => {
                    return col.field !== "tarief";
                })
                this.setState({ jobsdata: vatjobs, jobcols: vatcols, getNodes: true, 
                    invoicejoblist: [], countjobchecked: 0, alljobschecked: false });
                break;

            default:
                return;
        }
    }

    async makeInvoice() {
        await this.props.dispatch(GetAllFacturen());
        let year = new Date().getFullYear();
        let date = new Date();
        let extralinesdata = [];
        let today = (date.toISOString().slice(0, 10)).split('-').reverse().join('-');
        if (this.props.account.AllFacturenData.data.length !== 0) {
            let splittedInvoiceNumber = (this.props.account.AllFacturenData.data[0].factnr).split("/");
            let lastInvoiceYear = splittedInvoiceNumber[0];
            let lastInvoiceNumber = splittedInvoiceNumber[1];
            if(year.toString() === lastInvoiceYear){
                this.state.factuurnr = year + "/" + (Number(lastInvoiceNumber) + 1);
            } else {
                this.state.factuurnr = year + "/1"
            }
            
        } else {
            this.state.factuurnr = year + "/1";
        }
        this.state.factuurdate = today;
        
        switch(this.state.factuurbetvw.id){
            //contante betaling
            case "c401ca14238a0b923820dcc509a6f75849b":
                date.setDate(date.getDate() + parseInt(this.state.factuurbetvw.dagen));
                break;

            //30 dagen factuurdatum
            case "c8011e2728d9d4c2f636f067f89cc14862c":
                date.setDate(date.getDate() + parseInt(this.state.factuurbetvw.dagen));
                break;
            
            //Domiciliëring
            case "ec01cb3c87e4b5ce2fe28308fd9f2a7baf3":
                date.setDate(date.getDate() + parseInt(this.state.factuurbetvw.dagen));
                break;

            //30 dagen einde maand
            case "a8017f4f679a2f3e71d9181a67b7542122c":
                if (date.getMonth() === 11) {
                    date = new Date((date.getFullYear() + 1), 1, 0);
                    date.setDate(date.getDate() + 1);
                } else {
                    date = new Date(date.getFullYear(), date.getMonth() + 2, 0);
                    date.setDate(date.getDate() + 1);
                }
                break;

            default:
                return;
        }
        this.state.factuurenddate = (date.toISOString().slice(0, 10)).split('-').reverse().join('-')

        this.state.extralinesdata = [];
        ereloontotaalexclbtw = 0;
        totaalbtw = 0;
        ereloontotaalinclbtw = 0;
        let extralijnen = false
        let save;
        console.log('invoice lijst')
        console.log(this.state.invoicejoblist)
        if (this.state.invoicejoblist.length !== 0) {
            let newfactlines = [];
            for (let i = 0; i < this.state.invoicejoblist.length; i++) {
                let obj = this.props.land.AllData.data.find(country => country.id === this.state.invoicejoblist[i].country);
                let ereloon = 0;
                if(this.state.invoicetype.text === "Directe Registratie") ereloon = this.state.invoicejoblist[i].tarief;
                if(this.state.invoicetype.text === "VAT-Refund") ereloon = this.state.invoicejoblist[i].vat_facttot;
                let splittedJobcode = (this.state.invoicejoblist[i].code).split("_");
                let jobcode = splittedJobcode.length === 1 ? splittedJobcode[0] : splittedJobcode[1];
                let factline = {
                    subtype: jobcode,
                    land: obj ? obj[this.state.taal] : '',
                    regnr: this.state.invoicejoblist[i].regnummer,
                    periode: this.state.invoicejoblist[i].periode,
                    aangiftebtw: this.state.invoicejoblist[i].saldotebet,
                    munteenheid: obj ? obj.munt_txt : 'EUR',
                    teruggave: this.state.invoicejoblist[i].vat_basisfact,
                    koers: this.state.invoicejoblist[i].vat_koers,
                    teruggave_euro: this.state.invoicejoblist[i].vat_basisfact_val,
                    ereloon: ereloon,
                    omschrijving: ""
                };

                if (this.state.invoicejoblist[i].code === 'ADMK')
                {
                    factline.subtype = 'EXTRAL';
                    factline.ereloon = this.state.invoicejoblist[i].tarief;
                    factline.tarief = this.state.invoicejoblist[i].tarief;
                    factline.omschrijving = this.state.invoicejoblist[i].description;
                    this.state.invoicejoblist[i]['countryobj'] = obj;
                    extralinesdata.push(factline);     
                    extralijnen = true
                }
                else
                {
                    if (this.state.invoicejoblist[i].code !== 'ADMK')
                    {
                        this.state.invoicejoblist[i]['countryobj'] = obj;
                        newfactlines.push(factline);
                    }
                }

                if (parseInt(this.state.invoicejoblist[i].vat_factextra) > 0)
                {
                    factline = {
                        subtype: 'EXTRAL',
                        land: obj ? obj[this.state.taal] : '',
                        regnr: '',
                        periode: this.state.invoicejoblist[i].periode,
                        aangiftebtw: '0',
                        munteenheid: obj ? obj.munt_txt : 'EUR',
                        teruggave: '0',
                        koers: '',
                        teruggave_euro: '0',
                        tarief: this.state.invoicejoblist[i].vat_factextra,
                        omschrijving: "Extra Facturatie"
                    };

                    this.state.invoicejoblist[i]['countryobj'] = obj;
                    extralinesdata.push(factline);     
                    extralijnen = true
                }
            }

            save = true;
            console.log(newfactlines)
            console.log(extralinesdata)
            this.setState({ makefactuur: false, openfactuur: true, extralinesdata:extralinesdata, extralines: extralijnen, facttype: this.state.invoicetype.text, factlines: newfactlines, dbfactuur: false, save: save });
        } else {
            save = false;
            this.setState({ makefactuur: false, openfactuur: true, facttype: "Extra jobs", factlines: [], dbfactuur: false, save: save });
        }
        
        this.props.canSaveInvoice(save)
        this.props.canMakePdf(false);
    }

    async saveInvoice() {
        if (this.state.invoicejoblist.length !== 0) {
            let newfactlines = [];
            for (let i = 0; i < this.state.invoicejoblist.length; i++) {
                let obj = this.props.land.AllData.data.find(country => country.id === this.state.invoicejoblist[i].country);
                let ereloon = 0;
                if(this.state.invoicetype.text === "Directe Registratie") ereloon = this.state.invoicejoblist[i].tarief;
                if(this.state.invoicetype.text === "VAT-Refund") ereloon = this.state.invoicejoblist[i].vat_facttot;
                let splittedJobcode = (this.state.invoicejoblist[i].code).split("_");
                let jobcode = splittedJobcode.length === 1 ? splittedJobcode[0] : splittedJobcode[1];
                if (this.state.invoicejoblist[i].code !== 'ADMK')
                {
                    let factline = {
                        subtype: jobcode,
                        land: obj ? obj[this.state.taal]:'',
                        regnr: this.state.invoicejoblist[i].regnummer,
                        periode: this.state.invoicejoblist[i].periode,
                        aangiftebtw: this.state.invoicejoblist[i].saldotebet,
                        munteenheid: obj ? obj.munt_txt:'EUR',
                        teruggave: this.state.invoicejoblist[i].vat_basisfact,
                        koers: this.state.invoicejoblist[i].vat_koers,
                        teruggave_euro: this.state.invoicejoblist[i].vat_basisfact_val,
                        ereloon: ereloon,
                        omschrijving: ""
                    };
                    this.state.invoicejoblist[i]['countryobj'] = obj;
                    newfactlines.push(factline);
                }
            }
            this.state.factlines = newfactlines;
        } 

        let extrafactlines = this.extraLinesToFactLines(this.state.factlines, this.state.extralinesdata);
        this.state.factlines = extrafactlines;

        let factdata = {
            id: 0,
            acc_id: this.state.data.MainData.data.id,
            type: this.state.facttype,
            factnr: this.state.factuurnr,
            taalcode: this.state.facturatie_acc.taalcode,
            factdate: (this.state.factuurdate),
            factenddate: this.state.factuurenddate,
            totaalereloon: ereloontotaalexclbtw,
            btw: parseInt(this.state.btwpercentage),
            facttotaal: ereloontotaalinclbtw,
            klant: this.state.klant,
            factklant_name: this.state.factuurklant.name,
            factklant_address: this.state.factuurklant.adress,
            factklant_zipcode: this.state.factuurklant.zipcode,
            factklant_country: this.state.factuurklant.country,
            factklant_vatnumber: this.state.factuurklant.vatnumber,
            factklant_number: this.state.factuurklant.number,
            factdetails_payment: this.state.facturatie_acc.betaalvoorw_txt,
            factdetails_vatreg: this.state.facturatie_acc.vatreg_txt,
            factdetails_sendmethod: this.state.facturatie_acc.zendwijze_txt,
            factdetails_mail: this.state.facturatie_acc.emailfakt,
            vermelding: this.state.invoicedescription,
            betaalstatus: "Niet betaald",
            zendwijze_status: "Te verzenden",
            factlines: this.state.factlines
        }

        let retdata = await SqlFunction.DoSql('ChgFactuur', factdata)

        for (let i = 0; i < this.state.invoicejoblist.length; i++) {
            let sdata = this.state.invoicejoblist[i];
            sdata.fact_id = retdata.id;
            sdata['job_done'] = true;
            let overzdata = { data: sdata}
            await SqlFunction.DoSql('ChgJobAfterInvoiceSave', overzdata);
        }

        await this.props.dispatch(GetFactuurAccount(retdata.id));

        this.setState({ factsave: true, dbfactuur: true, invoiceId: retdata.id })
        this.props.canMakePdf(true);
    }

    getjobsdata(){
        let data = [];

        data.push(
            <TableGrid
                key={"jobgrid"}
                tableheight={this.state.tableheight}
                columns={this.state.jobcols}
                data={this.state.jobsdata}
                sizetofit={false}
                sortable={true}
                suppressMenu={true}
                enableRowGroup={false}
                suppressColumnVirtualisation={true}
                filter={true}
                sleutel='AllJobsToInvoiceAccount'
                getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllJobsToInvoiceAccount')}
                editable={false}
                CellClickedEvent={(column, colDef, value) => this.jobCellClick(column, colDef, value)}
                handleRowColoring={this.handleRowColors}
                handleNodes={this.handleNodes}
                updateColoring={true}
                getNodesForJobs={this.state.getNodes}
            />
        );
        
        return data;
    }

    handleRowColors(tablegridapi) {
        //Make rows red where tarief = 0
        tablegridapi.gridOptionsWrapper.gridOptions.getRowStyle = function (params) {
            if (params.node.data && params.node.data.tarief === "0" && params.node.data.vat_facttot === "0.0000") {
                return { background: "#fb9678", color: "white" }
            }
        }
        let jobdata = this.state.jobsdata;
        this.setState({ jobsdata: [] })
        this.setState({ jobsdata: jobdata })
    }

    handleNodes(status){
        this.setState({ pinnedTopRow: status.pinnedRowModel.pinnedTopRows[0] })
    }

    jobCellClick(waarde){
        let invoicejoblist = this.state.invoicejoblist;
        let countjobchecked = this.state.countjobchecked;
        if(waarde.target && waarde.target.name === "select-all"){
            let updJobs = [...this.state.jobsdata];
            invoicejoblist = [];
            if(waarde.target.checked){
                for(let i = 0; i < updJobs.length; i++){
                    updJobs[i].selected = true;
                    invoicejoblist.push(updJobs[i]);
                }
                this.setState(
                    { jobsdata: [], countjobchecked: updJobs.length, alljobschecked: true },
                    () => { this.setState({ jobsdata: updJobs }) } 
                )
            } else {
                for(let i = 0; i < updJobs.length; i++){
                    updJobs[i].selected = false;
                }
                this.setState(
                    { jobsdata: [], countjobchecked: 0, alljobschecked: false },
                    () => { this.setState({ jobsdata: updJobs }) }
                )
            }
        } else {
            if(waarde.colDef.field === "selected"){
                waarde.node.data.selected = !waarde.node.data.selected;
                waarde.node.setData(waarde.node.data);
                if (waarde.data.selected) {
                    if (!(invoicejoblist).includes(waarde.data)) {
                        invoicejoblist.push(waarde.data);
                        countjobchecked++;
                    }
                } else {
                    if ((invoicejoblist).includes(waarde.data)) {
                        let index = (invoicejoblist).findIndex(job => job === waarde.data);
                        invoicejoblist.splice(index, 1);
                        countjobchecked--;
                    }
                }
                if(!waarde.node.data.selected && this.state.alljobschecked){
                    this.setState({ alljobschecked: false })
                }
                if(countjobchecked === this.state.jobsdata.length){
                    this.setState({ alljobschecked: true });
                }
            }
        }
        this.setState({invoicejoblist:invoicejoblist, countjobchecked:countjobchecked})
        // if(invoicejoblist.length > 0 && (invoicejoblist).some(job => job.tarief === "0" && job.vat_facttot === "0.0000")){
        //     this.props.canMakeInvoice(false)
        // } else {
        //     this.props.canMakeInvoice(true)
        // }
    }

    sortInvoiceJobs(jobs){
        let sortedArray = [];
        let countryFilter = "country_txt";

        for(let i = 0; i < jobs.length; i++){
            if(sortedArray.filter(obj => obj.code === this.state.factuurtype[jobs[i].jobtypecode]['code']).length > 0){
                let codeIndex = sortedArray.map(function(obj) { return obj.code }).indexOf(this.state.factuurtype[jobs[i].jobtypecode]['code']);

                if(sortedArray[codeIndex].countries.filter(country => country.countryName === jobs[i][countryFilter]).length > 0){
                    let countryIndex = sortedArray[codeIndex].countries.map(function(country) { return country.countryName }).indexOf(jobs[i][countryFilter]);
                    sortedArray[codeIndex].countries[countryIndex].jobs.push(jobs[i]);
                } else {
                    let jobsArray = [];
                    jobsArray.push(jobs[i])
                    let country = {
                        countryName: jobs[i][countryFilter],
                        jobs: jobsArray
                    };
                    sortedArray[codeIndex].countries.push(country);
                }
            } else {
                let jobsArray = [];
                jobsArray.push(jobs[i])
                let countriesArray = [];
                countriesArray.push({
                    countryName: jobs[i][countryFilter],
                    jobs: jobsArray
                })
                sortedArray.push({
                    code: this.state.factuurtype[jobs[i].jobtypecode]['code'],
                    countries: countriesArray
                });
            }
        }

        let sortedInvoiceJobList = [];
        for(let i = 0; i < sortedArray.length; i++){
            for(let j = 0; j < sortedArray[i].countries.length; j++){
                let sortedOnPeriod = sortedArray[i].countries[j].jobs.sort(function(a, b) {
                    let aSplitted = a.periode.split("/");
                    let bSplitted = b.periode.split("/");
                    let aMonth = Number(aSplitted[0]);
                    let bMonth = Number(bSplitted[0]);
                    let aYear = Number(aSplitted[1]);
                    let bYear = Number(bSplitted[1]);
                    
                    if(aYear === bYear){
                        return aMonth - bMonth;
                    } else {
                        return aYear - bYear;
                    }
                })
                sortedInvoiceJobList = sortedInvoiceJobList.concat(sortedOnPeriod);
            }
        }

        return sortedInvoiceJobList;
    }

    getDirreg(){
        let dirreg = [];

        let regs = [];
        let regstype = [];
        let regstotaal = 0;
        ereloontotaalexclbtw = 0;

        let drjobs = this.sortInvoiceJobs(this.state.invoicejoblist);
        this.state.invoicejoblist = drjobs        

        for(let i = 0; i < drjobs.length; i++){
            if(!regstype.includes(this.state.factuurtype[drjobs[i].jobtypecode]['code'])){
                regstype[drjobs[i].jobtypecode] = this.state.factuurtype[drjobs[i].jobtypecode]['code'];
            }
        }
        
        for(let i = 0; i < Object.keys(regstype).length; i++){
            let type = [];
            let layout = [];
            let totaal = 0;

            if (regstype[i] !== "Administratieve kosten")
            {
                for(let j = 0; j < drjobs.length; j++){
                    if (drjobs[j].jobtypecode !== 'ADMK')
                    {
                        if(Object.values(regstype)[i] === this.state.factuurtype[drjobs[j].jobtypecode]['code']){
                            regstotaal += Number(drjobs[j].tarief);
                            totaal += Number(drjobs[j].tarief);

                            if(this.state.dbfactuur){                    
                                layout.push(
                                <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                                    <div style={{ width: "20%", textAlign: "center" }}><label>{drjobs[j].countryobj ? drjobs[j].countryobj[this.state.taal] : drjobs[j].country_txt}</label></div>
                                    <div style={{ width: "20%", textAlign: "center" }}><label>{drjobs[j].regnummer}</label></div>
                                    <div style={{ width: "20%", textAlign: "center" }}><label>{Object.keys(regstype)[i] !== "AANVR" ? drjobs[j].periode : ""}</label></div>
                                    <div style={{ width: "20%", textAlign: "center" }}><label>{Object.keys(regstype)[i] !== "AANVR" ? (new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((drjobs[j].saldotebet) * 100) / 100)).replace("€", "") : ""}</label></div>
                                    <div style={{ width: "20%", textAlign: "center" }}><label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((drjobs[j].tarief) * 100) / 100)}</label></div>
                                </div>
                                );
                            }
                            else
                            {
                                layout.push(
                                    <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                                        <div style={{ width: "20%", textAlign: "center" }}><label style={{ marginTop: "20px"}}>{drjobs[j].countryobj ? drjobs[j].countryobj[this.state.taal] : drjobs[j].country_txt}</label></div>

                                        <div style={{ width: "20%", marginTop: "9px",textAlign: "center" }}>
                                          <div style={{ marginTop: "-20px",width:'98%'}}>
                                            <EditString  className="card-title m-t-10"
                                              coltype='STR'
                                              labelText={""}                                                  
                                              value={drjobs[j].regnummer}
                                              ValueCheck={(params,value,type) => this.ValueCheckDetail(params,value,type,'regnummer',j)}
                                            />
                                          </div>
                                        </div>
                                        <div style={{ marginTop: "9px", width: "20%", textAlign: "center" }}>
                                          <div style={{ marginTop: "-20px",width:'98%'}}>
                                            <EditString  className="card-title m-t-10"
                                              value={drjobs[j].periode}
                                              coltype='STR'
                                              labelText={""}                                                  
                                              ValueCheck={(params,value,type) => this.ValueCheckDetail(params,value,type,'periode',j)}
                                            />
                                          </div>
                                        </div>
                                        <div style={{ width: "20%", textAlign: "center" }}>
                                          <div style={{ marginTop: "-20px"}}>
                                            <EditString  className="card-title m-t-10"
                                              value={drjobs[j].saldotebet}
                                              labelText={""}                                                  
                                              coltype = 'NUM'                        
                                              decimal={2}
                                              ValueCheck={(params,value,type) => this.ValueCheckDetail(params,value,type,'saldotebet',j)}
                                            />
                                          </div>
                                        </div>
                                        <div style={{ width: "20%", textAlign: "center" }}>
                                          <div style={{ marginTop: "-20px"}}>
                                            <EditString style={{ marginTop: "-20px"}} className="card-title m-t-10"
                                              value={drjobs[j].tarief}
                                              labelText={""}                                                  
                                              coltype = 'NUM'                        
                                              decimal={2}
                                              ValueCheck={(params,value,type) => this.ValueCheckDetail(params,value,type,'tarief',j)}
                                            />
                                          </div>
                                        </div>
                                    </div>
                                );                        
                            }
                        }
                    }
                }
                type['layout'] = layout;
                type['ereloon'] = totaal;
                type['code'] = Object.keys(regstype)[i];
                regs[Object.values(regstype)[i]] = type;
            }
        }
        ereloontotaalexclbtw = regstotaal;
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;

        for(let i = 0; i < Object.keys(regs).length; i++){
            let hulpreg = Object.values(regs)[i];
            if (hulpreg.code !== 'ADMK')
            {
                dirreg.push(
                    <div name={"dr" + i} key={"dr" + i}>
                        <div name={"regs"} style={{ marginTop: "10px" }}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                <div style={{ width: "85%" }}>
                                    <label>{Object.keys(regs)[i]}</label>
                                </div>
                                <div style={{ width: "15%", textAlign: "center" }}>
                                    <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((Object.values(regs)[i].ereloon) * 100) / 100)}</label>
                                </div>
                            </div>
                            <div name={"dirregs"} style={{ width: "90%", margin: "auto", display: "flex", flexDirection: "column" }}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.land}</label></div>
                                    <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.regnr}</label></div>
                                    <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{Object.values(regs)[i].code !== "AANVR" ? this.props.land.FactuuropbouwData.data.periode : ""}</label></div>
                                    <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{Object.values(regs)[i].code !== "AANVR" ? this.props.land.FactuuropbouwData.data.aangiftebtw : ""}</label></div>
                                    <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.ereloon}</label></div>
                                </div>
                                {Object.values(regs)[i].layout}
                            </div>
                        </div>
                    </div>
                );
            }
        }

        return dirreg;
    }

    getAccijnzen() {
        let accijnzen = [];

        return accijnzen;
    }

    getVatrefund() {
        let vatrefund = [];

        let vats = [];
        let vatstype = [];
        let vatstotaal = 0;
        ereloontotaalexclbtw = 0;

        let vatjobs = this.sortInvoiceJobs(this.state.invoicejoblist);
        this.state.invoicejoblist = vatjobs         
        
        for(let i = 0; i < vatjobs.length; i++){
            if(!vatstype.includes(this.state.factuurtype[vatjobs[i].jobtypecode]['code'])){
                vatstype.push(this.state.factuurtype[vatjobs[i].jobtypecode]['code']);
            }
        }

        for(let i = 0; i < vatstype.length; i++){
            let type = [];
            let layout = [];
            let totaal = 0;

            if (vatstype[i] !== "Administratieve kosten")
            {
                for(let j = 0; j < vatjobs.length; j++){
                    if (vatjobs[j].jobtypecode !== 'ADMK')
                    {

                        if(vatstype[i] === this.state.factuurtype[vatjobs[j].jobtypecode]['code']){
                            vatstotaal += Number(vatjobs[j].vat_facttot);
                            totaal += Number(vatjobs[j].vat_facttot);
                            if(this.state.dbfactuur){                    
                                layout.push(
                                <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                                    <div style={{ width: "16.5%", textAlign: "center" }}><label>{vatjobs[j].countryobj ? vatjobs[j].countryobj[this.state.taal] : vatjobs[j].country_txt}</label></div>
                                    <div style={{ width: "16.5%", textAlign: "center" }}><label>{vatjobs[j].periode}</label></div>
                                    <div style={{ width: "16.5%", textAlign: "center" }}><label>{Number(vatjobs[j].vat_basisfact_val).toLocaleString("es-ES", {minimumFractionDigits: 2})} {vatjobs[j].munt ? vatjobs[j].munt : vatjobs[j].countryobj.munt_txt}</label></div>
                                    <div style={{ width: "16.5%", textAlign: "center" }}><label>{Number(vatjobs[j].vat_koers).toLocaleString("es-ES", {minimumFractionDigits: 2})}</label></div>
                                    <div style={{ width: "16.5%", textAlign: "center" }}><label>{Number(vatjobs[j].vat_basisfact).toLocaleString("es-ES", {minimumFractionDigits: 2})}</label></div>
                                    <div style={{ width: "16.5%", textAlign: "center" }}><label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((vatjobs[j].vat_facttot) * 100) / 100)}</label></div>
                                </div>
                                );
                            }
                            else
                            {
                              layout.push(
                                  <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                                      <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ marginTop: "20px"}}>{vatjobs[j].countryobj ? vatjobs[j].countryobj[this.state.taal] : vatjobs[j].country_txt}</label></div>
                                      <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ marginTop: "20px"}}>{vatjobs[j].periode}</label></div>


                                      <div style={{ width: "16.5%", textAlign: "center" }}>
                                        <div style={{ marginTop: "-20px"}}>
                                          <EditString  className="card-title m-t-10"
                                            value={vatjobs[j].vat_basisfact_val}
                                            coltype = 'NUM'                        
                                            decimal={2}
                                            checkval={false}
                                            prefix={vatjobs[j].munt ? vatjobs[j].munt : vatjobs[j].countryobj.munt_txt}
                                            ValueCheck={(params,value,type,status) => this.ValueCheckDetail(params,value,type,'vat_basisfact_val',j,status)}
                                          />
                                        </div>
                                      </div>
                                      <div style={{ width: "16.5%", textAlign: "center" }}>
                                        <div style={{ marginTop: "-20px"}}>
                                          <EditString  className="card-title m-t-10"
                                            value={vatjobs[j].vat_koers}
                                            coltype = 'NUM'                        
                                            checkval={false}
                                            decimal={2}
                                            ValueCheck={(params,value,type,status) => this.ValueCheckDetail(params,value,type,'vat_koers',j,status)}
                                          />
                                        </div>
                                      </div>
                                      <div style={{ width: "16.5%", textAlign: "center" }}>
                                        <div style={{ marginTop: "-20px"}}>
                                          <EditString  className="card-title m-t-10"
                                            value={vatjobs[j].vat_basisfact}
                                            coltype = 'NUM'                        
                                            checkval={false}
                                            decimal={2}
                                            ValueCheck={(params,value,type,status) => this.ValueCheckDetail(params,value,type,'vat_basisfact',j,status)}
                                          />
                                        </div>
                                      </div>

                                      <div style={{ width: "16.5%", textAlign: "center" }}>
                                        <div style={{ marginTop: "-20px"}}>
                                          <EditString  className="card-title m-t-10"
                                            value={vatjobs[j].vat_facttot}
                                            coltype = 'NUM'                        
                                            decimal={2}
                                            checkval={false}
                                            prefix='EUR'
                                            ValueCheck={(params,value,type,status) => this.ValueCheckDetail(params,value,type,'vat_facttot',j,status)}
                                          />
                                        </div>
                                      </div>
                                  </div>
                              );
                            }
                        }
                    }
                }
                type['layout'] = layout;
                type['ereloon'] = totaal;
                vats[vatstype[i]] = type;
            }
        }

        ereloontotaalexclbtw = vatstotaal;
        // check if belgie
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;

        for(let i = 0; i < Object.keys(vats).length; i++){
            let hulpreg = Object.values(vats)[i];

            vatrefund.push(
                <div name={"vat" + i} key={"vat" + i}>
                    <div name={"vats"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{Object.keys(vats)[i]}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((Object.values(vats)[i].ereloon) * 100) / 100)}</label>
                            </div>
                        </div>
                        <div style={{ width: "80%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.land}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.periode}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.teruggave}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.koers}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{(this.props.land.FactuuropbouwData.data.bedrageuro).replace("euro", "€")}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.ereloon}</label></div>
                            </div>
                            {Object.values(vats)[i].layout}
                        </div>
                    </div>
                </div>
            );
            
        }

        return vatrefund;
    }

    getExtraLines() {
        let extralines = [];
        let extradata = [];
        let extratotaal = 0;

        for(let i = 0; i < this.state.extralinesdata.length; i++){
            extratotaal += Number(this.state.extralinesdata[i].tarief);
            if(this.state.dbfactuur){
                extradata.push(
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "-10px" }} key={"extraline_" + i}>
                        <div style={{ width: "82.5%" }}>
                            <label>{this.state.extralinesdata[i].omschrijving}</label>
                        </div>
                        <div style={{ width: "8%" }}></div>
                        <div style={{ width: "6.5%" }}>
                            <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((this.state.extralinesdata[i].tarief) * 100) / 100)}</label>
                        </div>
                        <div style={{ width: "2%" }}></div>
                    </div>
                );
            } else {
                extradata.push(
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "-10px" }}>
                        <div style={{ width: "80%" }}>
                            <EditString className="card-title m-t-10"
                                value={this.state.extralinesdata[i].omschrijving}
                                coltype='STR'
                                labelText={""}
                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "omschrijving_" + i)}
                            />
                        </div>
                        <div style={{ width: "8%" }}></div>
                        <div style={{ width: "10%" }}>
                            <EditString className="card-title m-t-10"
                                value={this.state.extralinesdata[i].tarief}
                                coltype='NUM'
                                labelText={""}
                                decimal={2}
                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "tarief_" + i)}

                            />
                        </div>
                        <div style={{ width: "1%" }}></div>
                        <div style={{ width: "1%", marginTop: "30px" }}>
                            <Delete name={i} style={{ fontSize: 20, border: "none", borderRadius: "50%", color: "#2fa5c7", cursor: "pointer" }} onClick={this.removeExtraLine}/>
                        </div>
                    </div>
                );
            }
        }

        if(this.state.facttype === "Directe Registratie" || this.state.facttype === "Accijnzen" || this.state.facttype === "VAT-Refund" || this.state.facttype === "Diversen"){
            ereloontotaalexclbtw += extratotaal;
        } else {
            ereloontotaalexclbtw = extratotaal;
        }
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;

        extralines.push(
            <div name={"extralines"} key={"allextralines"}>
                {this.state.extralinesdata.length !== 0 &&
                    <div name={"extra"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{this.props.land.FactuuropbouwData.data.extra}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(extratotaal * 100) / 100)}</label>
                            </div>
                        </div>
                        <div style={{ width: "80%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                <div style={{ width: "82.5%" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.description}</label></div>
                                <div style={{ width: "8%" }}></div>
                                <div style={{ width: "6.5%" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.ereloon}</label></div>
                                <div style={{ width: "2%" }}></div>
                            </div>
                            {extradata}
                        </div>
                    </div>
                }
            </div>
        );

        return extralines;
    }

    onlyNumbers(event){
        if(event.target.value === "0"){
            if(event.key !== "Backspace" && event.key !== "Tab" && event.key !== "."){
                event.preventDefault();
            }
        }
        if(event.key !== "0" && event.key !== "1" && event.key !== "2" && event.key !== "3" && event.key !== "4" && event.key !== "5" && event.key !== "6" && event.key !== "7" && event.key !== "8" && event.key !== "9" &&
            event.key !== "." && event.key !== "Backspace" && event.key !== "Tab"){
                event.preventDefault();
        }
    }

    async ValueCheck(that, waarde, type, colId) {
        let extradata = this.state.extralinesdata;
        let invoicetype = this.state.invoicetype;
        let terug = false;

        let split = colId.split("_");
        let col = split[0];
        let index = split[1];

        switch(col){
            case "omschrijving":
                extradata[index].omschrijving = waarde;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, col, that);
                break;

            case "tarief":
                extradata[index].tarief = waarde;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, col, that);
                break;

            case "invoicetype":
                invoicetype = { id: waarde.id, text: waarde.text };
                this.handleInvoiceType(invoicetype.text);
                break;

            default:
                return;
        }
        
        that.setState({ error: terug })
        terug = this.checkSave(extradata);
        this.setState({ save: terug, extralinesdata: extradata, invoicetype: invoicetype })
        this.props.canSaveInvoice(terug);
    } 

    checkSave(data){
        let terug = false;
        for(let i = 0; i < data.length; i++){
            if(terug === false){
                terug = Validation.ValidCheck(this.props.settings.sub_title, data[i].omschrijving, "omschrijving", this);
                if(terug === false){
                    terug = Validation.ValidCheck(this.props.settings.sub_title, data[i].tarief, "tarief", this);
                }
            }
        }

        if(this.state.invoicejoblist.length === 0 && this.state.extralinesdata.length === 0){
            terug = true;
        }

        return !terug;
    }

    async factClick(waarde){
        await this.props.dispatch(GetFactuurAccount(waarde.data.id));
        await this.props.dispatch(GetFactuuropbouw(waarde.data.taalcode));
        let extralinesdata = [];
        let invoicejoblist = [];
        let maindata = this.props.account.MainFactuurData.data;

        if(maindata.type === "Extra jobs"){
            for (let i = 0; i < maindata.factuurlines.length; i++){
                if(maindata.factuurlines[i].omschrijving !== ""){
                    let row = {
                        "omschrijving": maindata.factuurlines[i].omschrijving,
                        "tarief": maindata.factuurlines[i].ereloon
                    }
                    extralinesdata.push(row);
                }
            }
        } else {
            invoicejoblist = [];
            for (let i = 0; i < maindata.factuurlines.length; i++) {
                if (maindata.factuurlines[i].omschrijving === "") {

                    let row = {
                        "tarief": Number(maindata.factuurlines[i].ereloon),
                        "vat_facttot": Number(maindata.factuurlines[i].ereloon),
                        "country_txt": maindata.factuurlines[i].land,
                        "periode": maindata.factuurlines[i].periode,
                        "regnummer": maindata.factuurlines[i].regnr,
                        "jobtypecode": maindata.factuurlines[i].subtype,
                        "saldotebet": maindata.factuurlines[i].aangiftebtw,
                        "vat_basisfact": maindata.factuurlines[i].teruggave,
                        "vat_basisfact_val": maindata.factuurlines[i].teruggave_euro,
                        "vat_koers": maindata.factuurlines[i].koers,
                        "munt": maindata.factuurlines[i].munteenheid
                    }
                    invoicejoblist.push(row);
                } else {
                    let row = {
                        "omschrijving": maindata.factuurlines[i].omschrijving,
                        "tarief": maindata.factuurlines[i].ereloon
                    }
                    extralinesdata.push(row);
                }

            }
        }

        let factdate = (maindata.factdate).split('-').reverse().join('-');
        let factenddate = (maindata.factenddate).split('-').reverse().join('-');
        let btwperc = maindata.btw;
        let klant = maindata.klant;
        let taal = (waarde.data.taalcode_txt).toLowerCase();
        let factklant = {
            name: maindata.factklant_name,
            adress: maindata.factklant_address,
            zipcode: maindata.factklant_zipcode,
            country: maindata.factklant_country,
            vatnumber: maindata.factklant_vatnumber,
            number: maindata.factklant_number
        };
        await this.props.dispatch(JobDefListCodes(taal));
        let facttype = this.props.jobs.JobDefListCodesData.data;
        this.setState({ openfactuur: true, makefactuur: false, factsave: true, overview: false, factuurnr: maindata.factnr, factuurdate: factdate, factuurenddate: factenddate, facttype: maindata.type,
            dbfactuur: true, btwpercentage: btwperc, klant: klant, taal: taal, factuurklant: factklant, factuurtype: facttype, invoiceId: maindata.id, invoicedescription: maindata.vermelding, extralinesdata:extralinesdata, invoicejoblist:invoicejoblist })
        this.props.canMakePdf(true);
        this.props.handleUpdates("");
    } 

    addExtraLine(){
        let lines = this.state.extralinesdata;
        lines.push({
            omschrijving: "",
            tarief: "",
        });
        this.setState({ extralinesdata: lines, save: false })
        this.props.canSaveInvoice(false);
    }

    removeExtraLine(event){
        let lines = this.state.extralinesdata;
        let index = parseInt(event.currentTarget.getAttribute("name"))
        lines.splice(index, 1)
        if(lines.length === 0 && this.state.invoicejoblist.length === 0){
            ereloontotaalexclbtw =  0
            totaalbtw = 0
            ereloontotaalinclbtw = 0
            this.setState({ extralinesdata: [], ereloontotaalexclbtw: 0, totaalbtw: 0, ereloontotaalinclbtw: 0, save: false })
            this.props.canSaveInvoice(false)
        } else {
            let save = this.checkSave(lines)
            this.setState({ extralinesdata: lines, save: save })
            this.props.canSaveInvoice(save)
        }
    }

    extraLinesToFactLines(factlines, data){
        if(data.length !== 0){
            for(let i = 0; i < data.length; i++){
                let factline = {
                    subtype: "",
                    land: "",
                    regnr: "",
                    periode: "",
                    aangiftebtw: "",
                    munteenheid: "",
                    teruggave: "",
                    koers: "",
                    teruggave_euro: "",
                    ereloon: data[i].tarief,
                    omschrijving: data[i].omschrijving
                };
                factlines.push(factline);
            }

            return factlines;
        } else {
            return factlines;
        }
    }

    render() {
        return (
            <div style={{ marginTop: "25px", width: "100%", height: "100%" }}> 
                {this.state.makefactuur &&
                    <div name={"nieuwe_factuur"} style={{ marginTop: "25px", display: "flex", flexDirection: "column", height: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "30%", marginTop: "-20px" }}>
                                <EditSelect className="card-title m-t-10"
                                    value={this.state.invoicetype.text}
                                    data={this.state.data}
                                    labelText="Activiteit"
                                    type='AllInvoiceTypes'
                                    colId='invoicetype'
                                    selectcel='invoicetype'
                                    id={this.state.invoicetype.id}
                                    ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'invoicetype')}
                                />
                            </div>
                            <div style={{ width: "30%", textAlign: "center" }}>
                                <FormControlLabel
                                    aria-label="select-all"
                                    onClick={(event) => event.stopPropagation()}
                                    onFocus={(event) => event.stopPropagation()}
                                    label={"Alle jobs selecteren"}
                                    control={<Checkbox
                                        name={"select-all"}
                                        onClick={this.jobCellClick}
                                        checked={this.state.alljobschecked}
                                        style={{ color: "#137cbd" }}
                                        disabled={this.state.invoicetype.text === "" ? true : false}
                                    />}
                                />
                            </div>
                        </div>
                        <div className='div_line' style={{ marginBottom: '25px' }}></div>
                        {this.state.invoicetype.text !== "" &&
                            <div name={"jobsdata"} style={{ width: "100%", margin: "auto", display: "flex", flexDirection: "column", marginTop: "15px" }}>
                                {this.getjobsdata()}
                            </div>
                        }
                    </div>
                }
                {this.state.openfactuur &&
                    <div id={"invoicepdf"}>
                        <div id={"innerHtmlToSave"}>
                            <div id={"invoicepdfdiv"}>
                                <div style={{ marginTop: "25px", marginLeft: "20px", marginRight: "20px" }}>
                                    <div name={"Factuur_top"}>
                                        <div name={"Klantgeg"} style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                            <div style={{ width: "70%" }}></div>
                                            <div style={{ widht: "30%", display: "flex", flexDirection: "column" }}>
                                                <label>{this.state.factuurklant.name}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.adress}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.zipcode}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.country}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.vatnumber}</label>
                                            </div>
                                        </div>
                                        <label style={{ fontSize: "11px", fontStyle: "italic", marginBottom: "-5px" }}>{"*" + this.props.land.FactuuropbouwData.data.vermelding}</label>
                                        <div name={"Factuurdatums"} style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "10px" }}>
                                            <div style={{ width: "15%", display: "flex", flexDirection: "column" }}>
                                                <label>{this.props.land.FactuuropbouwData.data.factnr + "*"}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.klantnr + "*"}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.factdate}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.factenddate}</label>
                                            </div>
                                            <div style={{ width: "85%", display: "flex", flexDirection: "column" }}>
                                                <label>{this.state.factuurnr}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.number}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurdate}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurenddate}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div name={"Factuur_center"}>
                                        <div className='div_line' style={{ marginBottom: "5px" }} />
                                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                            <div style={{ width: "85%" }}>
                                                <label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.description}</label>
                                            </div>
                                            <div style={{ width: "15%", textAlign: "center" }}>
                                                <label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.totaalexcl}</label>
                                            </div>
                                        </div>
                                        <div className='div_line' style={{ marginBottom: "10px" }} />
                                        {this.state.data.MainData.data.id !== this.state.data.MainData.data.factklant &&
                                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                <label>{this.props.land.FactuuropbouwData.data.prestaties}</label>
                                                <div style={{ marginTop: "-10px" }}>
                                                    <label>{this.props.land.FactuuropbouwData.data.klant}</label>
                                                    <label style={{ marginLeft: "25px" }}>{this.state.klant}</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div name={"Factuur_bottom"} style={{ marginTop: "15px" }}>
                                        {this.state.facttype === "Directe Registratie" &&
                                            this.getDirreg()
                                        }
                                        {this.state.facttype === "Accijnzen" &&
                                            this.getAccijnzen()
                                        }
                                        {this.state.facttype === "VAT-Refund" &&
                                            this.getVatrefund()
                                        }
                                        {this.state.extralinesdata.length !== 0 &&
                                            this.getExtraLines()
                                        }
                                        {!this.state.dbfactuur &&
                                            <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
                                                <IconButton onClick={() => this.addExtraLine()} color="primary" component="span">
                                                    <AddCircleIcon style={{ fontSize: "30px" }} />
                                                </IconButton>
                                            </div>
                                        }
                                        <div className='div_line' style={{ marginBottom: "5px", marginTop: "25px" }} />
                                        <div name={"KostenTotaal"} style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "25px" }}>
                                            <div style={{ width: "65%" }} />
                                            <div style={{ display: "flex", flexDirection: "column", width: "20%" }}>
                                                <label>{this.props.land.FactuuropbouwData.data.totaalereloon}</label>
                                                <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.btw + " (" + this.state.btwpercentage + "%)"}</label>
                                                <label style={{ marginTop: "10px", fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.betalen}</label>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", width: "15%", textAlign: "center" }}>
                                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(ereloontotaalexclbtw * 100) / 100)}</label>
                                                <label style={{ marginTop: "-10px" }}>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(totaalbtw * 100) / 100)}</label>
                                                <label style={{ marginTop: "10px", fontWeight: "bold" }}>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(ereloontotaalinclbtw * 100) / 100)}</label>
                                            </div>
                                        </div>
                                        <div className='div_line' style={{ marginBottom: "5px", marginTop: "25px" }} />
                                        <div name={"Vermelding"} style={{ display: "flex", flexDirection: "column", width: "100%"}}>
                                            {this.state.invoicedescription && <label>{this.state.invoicedescription}</label>}
                                        </div>
                                        {this.state.dbfactuur && <div>
                                            <form id='INVOICEPDF' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{ width: '100%' }} >
                                                <input style={{ display: 'none' }} type="text" defaultValue='CreateInvoicePdf' id="function" name="function" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={this.state.invoiceId} id="id" name="id" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid" />
                                            </form>
                                            <form id='INVOICEPDFBRUTAX' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{ width: '100%' }} >
                                                <input style={{ display: 'none' }} type="text" defaultValue='CreateInvoicePdfBrutax' id="function" name="function" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={this.state.invoiceId} id="id" name="id" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid" />
                                            </form>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {this.state.overview && this.props.account.AllFactuurAccountData && this.props.account.AllFactuurAccountData.data && this.props.account.AllFactuurAccountData.kol &&
                    <TableGrid
                        tableheight={this.state.tableheight}
                        columns={this.props.account.AllFactuurAccountData.kol}
                        data={this.props.account.AllFactuurAccountData.data}
                        sizetofit={false}
                        sortable={true}
                        suppressMenu={true}
                        enableRowGroup={false}
                        suppressColumnVirtualisation={true}
                        filter={true}
                        sleutel='AllFacturen'
                        getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllFacturen')}
                        editable={false}
                        sql={() => this.props.dispatch(GetFactuurAccount(this.props.account.MainData.data.id))}
                        CellClickedEvent={(column, colDef, value) => this.factClick(column, colDef, value)}
                    />
                }
            </div>
        );
    }
}
export default connect(mapStateToProps)(withStyles(styles)(Factuurview));