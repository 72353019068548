import React from 'react';
import GenFunc from "../functions/gen_functions.js";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux'
import styles from "../assets/components/customInputStyle.jsx";
import {AllPeriodes, AllBtwPercentages, AllStartdatums,AllFactZendwijzen, AllBetalingsvoorwaarden, AllBtwRegimes, AllAansprekingen, AllRegType, AllAccType, AllRekeningen} from "../redux/settings/paramActions";
import {AllJobSubj, AllJobLayout, AllVelden,AllJobStatus, AllJobDefMain} from "../redux/settings/jobsActions";
import { withStyles } from '@material-ui/core/styles';
import { AllTaalcodes } from '../redux/settings/landActions.js';

const mapStateToProps = state => ({
  ...state
});


class EditSelect extends React.Component {


    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
        this.GetMenItems = this.GetMenItems.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);        
        this.componentDidMount = this.componentDidMount.bind(this);        
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);        
        this.inputid = GenFunc.MakeId()
        this.SetObject = this.SetObject.bind(this);

        this.state = {
            value:this.props.value,
            error:false,
            menuitems:[],
            menudata:[],
        }
    }

    async componentDidMount() {
      await this.GetMenItems();
        this.SetObject();
        if (this.props.value)
        {
          this.setState({value:this.props.id, id:this.props.id})
        }
        if (this.props.setError)
        {
          this.props.setError(this,this.props.value,'STRING')
        }

    }

    SetObject()
    {
        if (this.props.SetObject) 
        {
            this.props.SetObject(this,'')          
        }
    }



   async componentWillReceiveProps(nextProps, nextState) 
    {

      if (this.state.value !== nextProps.value && this.state.id !== nextProps.id)
      {
        this.setState({value:nextProps.id, id:nextProps.id})
      }
      if (nextProps.checkval === true)
      {
        this.props.ValueCheck(this,{id:this.props.id,value:this.props.value},'SELECTIE')
      }

    }    

    async GetMenItems() {
        let lijst = [];
        let slkey = '';
        let listitem = null;
        let data=[];
        if (this.props.type)
        {
          switch (this.props.type) 
          {
            case 'AllTaalcodes':
              if (!this.props.param.AllTaalcodesData) {
                await this.props.dispatch(AllTaalcodes());
              }
              if (this.props.land.AllTaalcodesData && this.props.land.AllTaalcodesData.data)
              {
                data = this.props.land.AllTaalcodesData.data
              }
              break;

            case 'AllRekeningen':
              await this.props.dispatch(AllRekeningen());
              if (this.props.param.AllRekeningenData && this.props.param.AllRekeningenData.data)
              {
                data = this.props.param.AllRekeningenData.data
              }
              break;


            case 'AllVelden':
              if (!this.props.jobs.AllVeldenData) {
                await this.props.dispatch(AllVelden());
              }
              if (this.props.jobs.AllVeldenData && this.props.jobs.AllVeldenData.data)
              {
                data = this.props.jobs.AllVeldenData.data
              }
              break;

            case 'AllJobStatus':
              if (!this.props.jobs.AllJobStatusData) {
                await this.props.dispatch(AllJobStatus());
              }
              if (this.props.jobs.AllJobStatusData && this.props.jobs.AllJobStatusData.data)
              {
                data = this.props.jobs.AllJobStatusData.data
              }
              break;

            case 'AllJobDefMain':
              if (!this.props.jobs.AllJobDefMainData) {
                await this.props.dispatch(AllJobDefMain());
              }
              if (this.props.jobs.AllJobDefMainData && this.props.jobs.AllJobDefMainData.data)
              {
                data = this.props.jobs.AllJobDefMainData.data
              }
              break;


            case 'AllJobDefMainAcc':
              if (this.props.jobs.AllJobDefMainAccData && this.props.jobs.AllJobDefMainAccData.data)
              {
                data = this.props.jobs.AllJobDefMainAccData.data
              }
              break;



            case 'AllJobSubj':
              if (!this.props.jobs.AllJobSubjData) {
                await this.props.dispatch(AllJobSubj());
              }
              if (this.props.jobs.AllJobSubjData && this.props.jobs.AllJobSubjData.data)
              {
                data = this.props.jobs.AllJobSubjData.data
              }
              break;

            case 'AllJobLayout':
              if (!this.props.jobs.AllJobLayoutData) {
                await this.props.dispatch(AllJobLayout());
              }
              if (this.props.jobs.AllJobLayoutData && this.props.jobs.AllJobLayoutData.data)
              {
                data = this.props.jobs.AllJobLayoutData.data
              }
              break;

              

            case 'AllRegType':
              if (!this.props.param.AllRegTypeData) {
                await this.props.dispatch(AllRegType());
              }
              if (this.props.param.AllRegTypeData && this.props.param.AllRegTypeData.data)
              {
                data = this.props.param.AllRegTypeData.data
              }
              break;

            case 'AllBtwRegimes':
              if (!this.props.param.AllBtwRegimesData) {
                await this.props.dispatch(AllBtwRegimes());
              }
              if (this.props.param.AllBtwRegimesData && this.props.param.AllBtwRegimesData.data)
              {
                data = this.props.param.AllBtwRegimesData.data
              }
              break;

            case 'AllAccType':
              if (!this.props.param.AllAccTypeData) {
                await this.props.dispatch(AllAccType());
              }
              if (this.props.param.AllAccTypeData && this.props.param.AllAccTypeData.data)
              {
                data = this.props.param.AllAccTypeData.data
              }
              break;


            case 'AllBetalingsvoorwaarden':
              if (!this.props.param.AllBetalingsvoorwaardenData) {
                await this.props.dispatch(AllBetalingsvoorwaarden());
              }
              if (this.props.param.AllBetalingsvoorwaardenData && this.props.param.AllBetalingsvoorwaardenData.data)
              {
                data = this.props.param.AllBetalingsvoorwaardenData.data
              }
              break;

           case 'AllAansprekingen':
              if (!this.props.param.AllAansprekingenData) {
                await this.props.dispatch(AllAansprekingen());
              }
              if (this.props.param.AllAansprekingenData && this.props.param.AllAansprekingenData.data)
              {
                data = this.props.param.AllAansprekingenData.data
              }
              break;


            case 'AllFactZendwijzen':
              if (!this.props.param.AllFactZendwijzenData) {
                await this.props.dispatch(AllFactZendwijzen());
              }
              if (this.props.param.AllFactZendwijzenData && this.props.param.AllFactZendwijzenData.data)
              {
                data = this.props.param.AllFactZendwijzenData.data
              }
              break;

          case 'AllPeriodes':
            if (!this.props.param.AllPeriodesData) {
              await this.props.dispatch(AllPeriodes());
            }
            if (this.props.param.AllPeriodesData && this.props.param.AllPeriodesData.data)
            {
              data = this.props.param.AllPeriodesData.data
            }
            break;
          case 'AllStartdatums':
            if (!this.props.param.AllStartdatumsData) {
              await this.props.dispatch(AllStartdatums());
            }
            if (this.props.param.AllStartdatumsData && this.props.param.AllStartdatumsData.data) {
              data = this.props.param.AllStartdatumsData.data
            }
            break;
          case 'AllJaren':
            data = [{id:2020,text:'2020'},{id:2021,text:'2021'},{id:2022,text:'2022'},{id:2023,text:'2023'},{id:2024,text:'2024'},{id:2025,text:'2025'},{id:2026,text:'2026'},{id:2027,text:'2027'},]
            break;        

          case 'AllTerugActie':
            data = [{id:'1',text:'Afsluiten job zonder facturatie'},{id:'2',text:'Afsluiten job met facturatie'},{id:'3',text:'Bezwaar'},{id:'4',text:'Verbeterde aangifte'},]
            break;

          case 'AllTarType':
            data = [{id:'PER',text:'Percentage'},{id:'FIX',text:'Vast bedrag'},{id:'AAN',text:'Aantal'}]
            break;

          case 'AllInvoiceTypes':
            data = [{ id: '1', text: 'Directe Registratie' }, { id: '2', text: 'Accijnzen' }, { id: '3', text: 'VAT-Refund' },]
            break;

          case 'TimeRegAcitivities':
            data = [{ id: '1', text: 'Directe Registratie' }, { id: '2', text: 'Accijnzen' }, { id: '3', text: 'VAT-Refund' }, { id:4, text: "Divers" }]
            break;

          case 'AllBtwPercentages':
            if (!this.props.param.AllBtwPercentagesData) {
              await this.props.dispatch(AllBtwPercentages());
            }
            if (this.props.param.AllBtwPercentagesData && this.props.param.AllBtwPercentagesData.data) {
              data = this.props.param.AllBtwPercentagesData.data
            }
            break;
          
          default:    
            break;
        }  
        if (this.props.extramenu)
        {
          for (let i=0; i < this.props.extramenu.length; i++) 
          {
            data.push(this.props.extramenu[i])
          }
        }
        for (let i=0; i < data.length; i++) 
        {
          slkey = 'APC'+GenFunc.MakeId();
          listitem =   (<MenuItem key={slkey} classes={{root: this.props.classes.selectMenuItem, selected: this.props.classes.selectMenuItemSelected}} value={data[i].id}>{data[i].text}</MenuItem>);
          lijst.push(listitem);  
        }
      }
      this.setState({menuitems:lijst,menudata:data})
    }
    handleFocus() {
      this.setState({ focus: true });
    }  
    handleBlur() {
      this.setState({ focus: false });
    }  

    getValue() {
        return this.state.value;
    }
    isPopup() {
        return false;
    }
    change(event, valid, stateName) 
    {
      let result = this.state.menudata.filter(o=> o.id === event.target.value);
      if (Array.isArray(result))
      {
        if (result.length > 0)
        {
          this.setState({value:result[0].id})
          this.props.ValueCheck(this,result[0])
        }
      }
    }    

    render() {
      const {
        classes,
        labelText,
        id,
        labelProps,
        inputProps,
        inputRootCustomClasses,
      } = this.props;
      const formControlProps={fullWidth: true}
      const marginTop = classNames({
          [inputRootCustomClasses]: inputRootCustomClasses !== undefined
        });      
      const labelClasses = classNames({
        [" " + classes.labelRootError]: this.state.error,
        [" " + classes.labelRootSuccess]: this.state.success && !this.state.error
      });
      const underlineClasses = classNames({
        [classes.brutUnderline]: !this.state.error,
        [classes.underlineError]: this.state.error,
        [classes.underline]: true,
      });
      const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: this.state.white
      });
      var formControlClasses;
      if (formControlProps !== undefined) {
        formControlClasses = classNames(
          formControlProps.className,
          classes.formControl
        );
      } else {
        formControlClasses = classes.formControl;
      }
      return (
        <FormControl style={{top:'-2px',position:'relative', width:'100%'}} {...formControlProps}  className={formControlClasses}>
          {labelText !== undefined ? (
            <InputLabel
              className={classes.labelRoot + " " + labelClasses}
              htmlFor={id}
              {...labelProps}
            >
              {labelText}
            </InputLabel>
          ) : null}
          <Select
            classes={{select:classes.select,
              root: this.props.classes.selectinputbrut,
              selectMenu:classes.selectMenu,
              disabled:classes.disabled,
              icon:classes.icon
            }}
            style={{position:'relative',marginBottom:'15px', width:'100%',paddingRight:'5px'}}
            value={this.state.menuitems && this.state.menuitems.length > 0 ? this.state.value : ''}
            input={
              <Input
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                classes={{
                  input: inputClasses,
                  root: marginTop,
                  disabled: classes.disabled,
                  underline: underlineClasses,
                }}
                disabled={this.props.disabled}
                style={{paddingBottom:'15px',fontSize:'small'}}
                onChange ={this.change}
                {...inputProps}
                id={this.inputid}
              />
            }
          >
          {this.state.menuitems}

          </Select>          
        </FormControl>          
      );
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EditSelect));
