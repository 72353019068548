import {
  ALLSECPARAM_SUCCESS, ALLMUNTEN_SUCCESS, ALLPOSTCODES_SUCCESS, ALLPERIODES_SUCCESS, ALLTAALCODES_SUCCESS,
  ALLFACTZENDWIJZEN_SUCCESS, ALLBETALINGSVOORWAARDEN_SUCCESS, ALLBTWREGIMES_SUCCESS, ALLBTWPERCENTAGES_SUCCESS, ALLSTARTDATUMS_SUCCESS,
  ALLAANSPREKINGEN_SUCCESS, ALLREGTYPE_SUCCESS, ALLJOBS_SUCCESS,ALLACCTYPE_SUCCESS, ALLFUNCTIES_SUCCESS, ALLREKENINGEN_SUCCESS, 
  ALLFILTLANDEN_SUCCESS,
  ALLSUBPRODUCTCODES_SUCCESS,ALLMAINPRODUCTCODES_SUCCESS,ALLMAINVATREFCODES_SUCCESS,ALLSUBVATREFCODES_SUCCESS,GETMAINIVAT_SUCCESS
} from '../constants/';

const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const secParamSuccess = (payload) => {
  return {
    type: ALLSECPARAM_SUCCESS,
    payload: payload.response.data
  }
}


export const GetMainIvatSuccess = (payload) => {
    return {
        type: GETMAINIVAT_SUCCESS,
        payload: payload.response.data
    }
}


export const AllMuntenSuccess = (payload) => {
  return {
    type: ALLMUNTEN_SUCCESS,
    payload: payload.response.data
  }
}

export const AllFiltLandenSuccess = (payload) => {
  return {
    type: ALLFILTLANDEN_SUCCESS,
    payload: payload.response.data
  }
}

export const AllSubVatRefCodesSuccess = (payload) => {
  return {
    type: ALLSUBVATREFCODES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllMainVatRefCodesSuccess = (payload) => {
  return {
    type: ALLMAINVATREFCODES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllSubProductCodesSuccess = (payload) => {
  return {
    type: ALLSUBPRODUCTCODES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllMainProductCodesSuccess = (payload) => {
  return {
    type: ALLMAINPRODUCTCODES_SUCCESS,
    payload: payload.response.data
  }
}


export const AllAccTypeSuccess = (payload) => {
  return {
    type: ALLACCTYPE_SUCCESS,
    payload: payload.response.data
  }
}

export const AllJobsSuccess = (payload) => {
  return {
    type: ALLJOBS_SUCCESS,
    payload: payload.response.data
  }
}

export const AllRegTypeSuccess = (payload) => {
  return {
    type: ALLREGTYPE_SUCCESS,
    payload: payload.response.data
  }
}

export const AllTaalcodesSuccess = (payload) => {
  return {
    type: ALLTAALCODES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllFactZendwijzenSuccess = (payload) => {
  return {
    type: ALLFACTZENDWIJZEN_SUCCESS,
    payload: payload.response.data
  }
}

export const AllBetalingsvoorwaardenSuccess = (payload) => {
  return {
    type: ALLBETALINGSVOORWAARDEN_SUCCESS,
    payload: payload.response.data
  }
}


export const AllBtwRegimesSuccess = (payload) => {
  return {
    type: ALLBTWREGIMES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllPostcodesSuccess = (payload) => {
  return {
    type: ALLPOSTCODES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllRekeningenSuccess = (payload) => {
  return {
    type: ALLREKENINGEN_SUCCESS,
    payload: payload.response.data
  }
}



export const AllPeriodesSuccess = (payload) => {
  return {
    type: ALLPERIODES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllStartdatumsSuccess = (payload) => {
  return {
    type: ALLSTARTDATUMS_SUCCESS,
    payload: payload.response.data
  }
}

export const AllBtwPercentagesSuccess = (payload) => {
  return {
    type: ALLBTWPERCENTAGES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllAansprekingenSuccess = (payload) => {
  return {
    type: ALLAANSPREKINGEN_SUCCESS,
    payload: payload.response.data
  }
} 

export const AllFunctiesSuccess = (payload) => {
  return {
    type: ALLFUNCTIES_SUCCESS,
    payload: payload.response.data
  }
}

export const secParamFail = () => {
  return {
    type: ALLSECPARAM_SUCCESS,
    payload: null
  }
}

export function AllSecParam(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllSecParam', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(secParamSuccess({ response }))
        }
      },
      err => dispatch(secParamFail(err))
    );
  }
}

export function AllSubVatRefCodes(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllSubVatRefCodes', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllSubVatRefCodesSuccess({ response }))
        }
      },
      () => dispatch(AllSubVatRefCodesSuccess(null))
    );
  }
}

export function AllMainVatRefCodes(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllMainVatRefCodes', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllMainVatRefCodesSuccess({ response }))
        }
      },
      () => dispatch(AllMainVatRefCodesSuccess(null))
    );
  }
}


export function AllMainProductCodes(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllMainProductCodes', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllMainProductCodesSuccess({ response }))
        }
      },
      () => dispatch(AllMainProductCodesSuccess(null))
    );
  }
}

export function AllSubProductCodes(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllSubProductCodes', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllSubProductCodesSuccess({ response }))
        }
      },
      () => dispatch(AllSubProductCodesSuccess(null))
    );
  }
}


export function AllMunten(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllMunten', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllMuntenSuccess({ response }))
        }
      },
      () => dispatch(AllMuntenSuccess(null))
    );
  }
}

export function AllFiltLanden(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetFiltLanden', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllFiltLandenSuccess({ response }))
        }
      },
      () => dispatch(AllFiltLandenSuccess(null))
    );
  }
}


export function AllAccType(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllAccType', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllAccTypeSuccess({ response }))
        }
      },
      () => dispatch(AllAccTypeSuccess(null))
    );
  }
}

export function GetMainIvat(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetMainIvat', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetMainIvatSuccess({ response })) },
            err => { dispatch(GetMainIvatSuccess(null)) }
        );
    }
}


export function AllJobs(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetAllJobs', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllJobsSuccess({ response }))
        }
      },
      () => dispatch(AllJobsSuccess(null))
    );
  }
}
export function AllRegType(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllRegType', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllRegTypeSuccess({ response }))
        }
      },
      () => dispatch(AllRegTypeSuccess(null))
    );
  }
}

export function AllPeriodes(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllPeriodes', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllPeriodesSuccess({ response }))
        }
      },
      () => dispatch(AllPeriodesSuccess(null))
    );
  }
}

export function AllStartdatums(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllStartdatums', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllStartdatumsSuccess({ response }))
        }
      },
      () => dispatch(AllStartdatumsSuccess(null))
    );
  }
}

export function AllBtwPercentages(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllBtwPercentages', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllBtwPercentagesSuccess({ response }))
        }
      },
      () => dispatch(AllBtwPercentagesSuccess(null))
    );
  }
}

export function AllTaalcodes(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllTaalcodes', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login')
        }
        else {
          dispatch(AllTaalcodesSuccess({ response }))
        }
      },
      () => dispatch(AllTaalcodesSuccess(null))
    );
  }
}

export function AllBetalingsvoorwaarden(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllBetalingsvoorwaarden', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login')
        }
        else {
          dispatch(AllBetalingsvoorwaardenSuccess({ response }))
        }
      },
      () => dispatch(AllBetalingsvoorwaardenSuccess(null))
    );
  }
}

export function AllBtwRegimes(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllBtwRegimes', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login')
        }
        else {
          dispatch(AllBtwRegimesSuccess({ response }))
        }
      },
      () => dispatch(AllBtwRegimesSuccess(null))
    );
  }
}

export function AllFactZendwijzen(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllFactZendwijzen', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllFactZendwijzenSuccess({ response }))
        }
      },
      () => dispatch(AllFactZendwijzenSuccess(null))
    );
  }
}

export function AllPostcodes(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllZipcode', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllPostcodesSuccess({ response }))
        }
      },
      () => dispatch(AllPostcodesSuccess(null))
    );
  }
}

export function AllRekeningen(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllRekeningen', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllRekeningenSuccess({ response }))
        }
      },
      () => dispatch(AllRekeningenSuccess(null))
    );
  }
}


export function AllAansprekingen(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllAansprekingen', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllAansprekingenSuccess({ response }))
        }
      },
      () => dispatch(AllAansprekingenSuccess(null))
    );
  }
} 

export function AllFuncties(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllFuncties', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllFunctiesSuccess({ response }))
        }
      },
      () => dispatch(AllFunctiesSuccess(null))
    );
  }
} 
