
export default {
	MakeId: function ()  
	{
	  return '_' + Math.random().toString(36).substr(2, 9);
	},
	HandleChkMail: function(data)   
	{
	    if (data) 
	    { 
	      if (data === '')
	      {
	        return true;
	      }
	      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	      let restest = re.test(data);
	      if (!restest) {
	          return true;
	      }
	      else
	      {
	          return false;
	      }
	    }
	    return true
	},
  
  GetMuntPrefix(munt) {
      let prefix = ''
      switch (munt) 
      {

        case 'EUR':
          prefix = '€'
          break;

        case 'BGN':
          prefix = 'лв';
          break;    
        case 'CHF':
          prefix = 'CHF';
          break;    
        case 'CZK':
          prefix = 'Kč';
          break;    
        case 'DKK':
          prefix = 'kr';
          break;    
        case 'GBP':
          prefix = '£';
          break;    
        case 'HRK':
          prefix = 'kn';
          break;    
        case 'GEL':
          prefix = '₾';
          break;    
        case 'HUF':
          prefix = 'ft';
          break;    
        case 'NOK':
          prefix = 'kr';
          break;    
        case 'PLN':
          prefix = 'zł';
          break;    
        case 'RUB':
          prefix = '₽';
          break;    
        case 'RON':
          prefix = 'lei';
          break;    
        case 'SEK':
          prefix = 'kr';
          break;    
        case 'TRY':
          prefix = '₺';
          break;    
        case 'UAH':
          prefix = '₴';
          break;    

        default:    
          prefix = '';
          break;        
      }
      return prefix
  },      


  Get_Vandaag: function() 
  {
    let d = new Date();
    let maand = d.getMonth() + 1;
    let dag = d.getDate();
    let jaar = d.getFullYear();
    let sjaar = jaar.toString();
    let sdag = dag.toString();
    let smaand = maand.toString();
    if (dag < 10)
    {
      sdag = '0'+sdag;
    }
    if (maand < 10)
    {
      smaand = '0'+smaand;
    }

    let datum = sdag+"-"+smaand+"-"+sjaar;
    return datum;
  },

  Get_Jaar: function() 
  {
    let d = new Date();
    let jaar = d.getFullYear();
    let sjaar = jaar.toString();
    return sjaar;
  },

  allowed: function(data,waarde,type) 
  {
    let field = 'zien';
    if (type) {field = type}
        let result = data.filter(o=> (o.security === waarde || o.name === waarde)  && o[field] === '1' );
        let terug = false;
        if (Array.isArray(result))
        {
           if (result.length > 0)
           {
                terug = true;
           }
        }
        return terug;
    },
  zetdataleeg: function(sleutel) 
	{
		let terug = null;
        switch (sleutel) 
        {
  
          case 'Job':
            terug = {id:0,account_id:'',job_id:'',user_id:'',registration_id:'',kantoor_id:'',country:'',fact_id:'',status:'c401ca14238a0b923820dcc509a6f75849b',start_date:'',end_date:'',description:'',
            status_txt:'Open',registratie_txt:'',job_txt:'',code:'',layout:'',facturatie:'1',info1:'',info2:'',account_name:'',country_txt:'',kantoor_txt:'', user_name:'',
            tot_onkosten: 0,aang_periode_code: "",tot_douane: 0,per1: "0",per2: "0",per3: "0",per4: "0",per5: "0",per6: "0",per7: "0",per8: "0",per9: "0",per10: "0",
            per11: "0",per12: "0",tot_boetes: 0,algtot_onkosten: 0,kz_nihil: "",tarief: "0",create_date: "",forfait: "0",jaar: "",periode: "",dat_ontv_geg: "",aang_optie: "",dat_aang: "",omzetbel: "",
            voorbel: "",saldotebet: "",dat_betaling: "",dfv_periodes:'12',verwijlintrest: "",aang_vperiode: "",aang_periode_txt: "",aang_periode:'',v_jobid:'',corr_aang:'',corr_controle:'',contr_inhoud:'',contr_start_date:'',contr_end_date:'',
            contr_afhand:'',contr_uren:'',corr_id:0,contr_id:0,contr_bijk_aang:0,link_jobid:'',nihil_datum:'',corr_dat_correctie:'',corr_dat_ind_correctie:'',
            corr_dat_bet:'',corr_door:'',corr_omzbel:'',corr_voorbel:'',corr_tebet:'',corr_verwijl:'',vat_tarief:0,vat_basisfact:0,vat_factextra:0,vat_facttot:0,vat_factopm:0};
            break;

          case 'Cor':
            terug = {id:0,account_id:'',job_id:'',user_id:'',registration_id:'',kantoor_id:'',fact_id:'',datum:'',description:'',registratie_txt:'',job_txt:'',temp_text:'',template_txt:'',
            temp_id:'',account_name:'',country_txt:'',kantoor_txt:'', user_name:'',contact_id:'',contact_name:''};
            break;


          case 'AllSecPar':
            terug = {id:0,name:'',zien:'1',edit:'1',verwijder:'1',save:''};
            break;
          case 'AllMunten':
            terug = {id:0,code:'',save:''};
            break;
          case 'AllRegTar':
            terug = {id:0,tarief:'',type:'',periode:'',land_txt:'',land:'',registratie_txt:'',registratie:'',save:''};
            break;
          case 'AllRegOms':
            terug = {id:0,description:'',save:''};
            break;

          case 'AllFactZendwijzen':
            terug = {id:0,code:'',save:''};
            break;

          case 'AllBetalingsvoorwaarden':
            terug = {id:0,omschrijving:'',startdatum:'',dagen:'',save:''};
            break;

          case 'AllBtwRegimes':
            terug = {id:0,omschrijving:'',code:'',btw_percentage:'',vermelding:'',save:''};
            break;

          case 'AllPostcodes':
            terug = {id:0,zipcode:'',place:'',country_txt:'',country:'',save:''};
            break;

          case 'AllRekeningen':
            terug = {id:0,bank:'',bic:'',reknummer:'',save:''};
            break;

          case 'AllAansprekingen':
            terug = {id:0,code:'',omschrijving:'',save:''};
            break;

          case 'AllFuncties':
            terug = { id: 0, omschrijving: '', save: '' };
            break;

         case 'SubJobMain':
            terug = {id:0,mainid:'',country:'',periode:'',code:'',description:'',boekhoud:'',country_txt:'',periode_txt:'',maincode:'',maindescription:'',subjectcode:'',country_code:'',periode_code:'',save:''};
            break;

         case 'SubBetaalFin':
            terug = {id:0,mainid:'',jobid:'',acc_num:'',code:'',acc_name:'',reg_num:'',bedrag:'',verwijder:'',save:''};
            break;

          case 'Onkosten':
            terug = {id:0,description:'',dirreg:'',vereenv:'',save:''};
            break;

         case 'AccTarief':
            terug = {id:0,jobid:'',jaar:'',description:'',accountid:'',tarief:'',save:'',verwijder:''};
            break;

          case 'VolmachtenLand':
            terug = {id:0, country:'', omschrijving:'', save:''};
            break;

          case 'AllJobStatus':
            terug = {id:0,code:'',description:'',save:''};
            break;


          default:    
            break;
        }    
        return terug;
  	}

};
