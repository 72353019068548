import React from 'react';
import classnames from 'classnames';
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/nl-be'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import { Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import PictureUpload from "../../components/PictureUpload.jsx";
import EditString from "../../components/editstring.jsx";
import EditCheck from "../../components/editcheck.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import {AllPostcodes} from "../../redux/settings/paramActions";
import {GetUser,GetUserAccounts, GetJobsUser, CreateUser } from "../../redux/settings/userActions";
import {AllAccounts,GetAccount} from "../../redux/settings/accountActions";
import {setPanelRight, setTitleRight, setSubTitle,zetview,clickview, ZetListData, zetListMain} from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import PopJobAanvrNum from "../jobs/PopJobAanvrNum.jsx";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SubSpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SubSpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import AccountMultiPlus from '@material-ui/icons/AccountMultiPlus';
import SaveIcon from '@material-ui/icons/Save';
import ChangedAccData from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import BookPlus from '@material-ui/icons/BookPlus';

moment.locale('nl-be')

const localizer = BigCalendar.momentLocalizer(moment)
const mapStateToProps = state => ({
  ...state
});


class Userpage extends React.Component {

  state = {
    tableheight: Math.floor(window.innerHeight * .75) + 'px',
    tabvalue:'1', 
    foto:this.props.user && this.props.user.MainData && this.props.user.MainData.data ? this.props.user.MainData.data.foto : null,
    edit:this.props.user && this.props.user.MainData && this.props.user.MainData.data && this.props.user.MainData.data.id === 0 ? true : false,
    save:false,
    PopUpScreen: null,
    subsave:false,
    data:this.props.user && this.props.user.MainData && this.props.user.MainData.data && this.props.user.MainData.data.id === 0 ? this.props.user.MainData.data : null,
    subspeedzien:false,
    speedactions: [
      { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
      { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe gebruiker maken' },
      { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
    ],
    subspeedactions: [
      { icon: <AccountMultiPlus />, name: 'Contact', tooltip: 'Nieuwe contactpersoon' },
      { icon: <BookPlus />, name: 'NewRegistratie', tooltip: 'Nieuwe registratie' },
      { icon: <ChangedAccData />, name: 'ChangedAccData', tooltip: 'Gewijzigde klantgegevens' },
    ],
    speedopen: false,
    subspeedopen: false,  

  };



  constructor(props, context) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);    
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.SetPhoto = this.SetPhoto.bind(this);
    this.ValueCheck = this.ValueCheck.bind(this);
    this.SecValueCheck = this.SecValueCheck.bind(this);
    this.SecCellClick = this.SecCellClick.bind(this);

    this.SecSaveAll = this.SecSaveAll.bind(this);
    this.CheckSave = this.CheckSave.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.MainSave = this.MainSave.bind(this)
    this.checkconfirm = this.checkconfirm.bind(this)
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
    this.handleJa = this.handleJa.bind(this)
    this.AccDetClick = this.AccDetClick.bind(this)
    this.JobCellClick = this.JobCellClick.bind(this);
    this.ClosePopJobs = this.ClosePopJobs.bind(this);
    this.CreateJob = this.CreateJob.bind(this);
    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
    this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);
    this.ActionClick = this.ActionClick.bind(this);
    this.SubActionClick = this.SubActionClick.bind(this);
    this.ZetEdit = this.ZetEdit.bind(this);

  }


  async handleTabChange(event, newValue)  {
    if (newValue === '4')
    {
      // Klanten
      await this.props.dispatch(GetUserAccounts(this.props.user.MainData.data.id))
    }
    if (newValue === '5') {
      // Jobs
      await this.props.dispatch(GetJobsUser(this.props.user.MainData.data.id))
      await this.props.dispatch(ZetListData('GetJobsUser', this.props.user.MainData.data.id));
      await this.props.dispatch(zetListMain('GetJobsUser', this.props.user.MainData.data.id));

  }

    this.setState({tabvalue:newValue});

  };
  handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:false });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
  };

  handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
  };

  handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
  };


  ZetEdit() {
    if (GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit') === true)
    {
      this.setState({ edit: true, data: this.props.user.MainData.data, speedopen: false })
    }
  }


  async ActionClick(name) {
    switch (name) {
      case 'Edit':
        this.setState({ edit: true, data: this.props.user.MainData.data, speedopen: false })
        break;

      case 'New':
        await this.props.dispatch(CreateUser())
        this.setState({ edit: true, data: this.props.user.MainData.data, speedopen: false })
        break;

      case 'Save':
        this.MainSave();
        break;

      default:
        break;
    }
    this.setState({ speedopen: false });
  }

  async SubActionClick(name) {
    this.setState({ speedopen: false });
  }

  componentDidMount(){
    window.addEventListener("resize", this.resizeFunction);
    this.props.dispatch(setTitleRight('Gebruikers'))
    this.props.dispatch(setPanelRight(true))
  }

  componentWillUnmount() 
  {
    this.checkconfirm(this.props.user.MainData);    
    this.props.dispatch(setPanelRight(false))
    this.props.dispatch(setTitleRight(''))
    window.removeEventListener("resize", this.resizeFunction);
  }

  async componentWillReceiveProps(nextProps, nextState) 
  {
    if (this.props.user.MainData.data.id !== nextProps.user.MainData.data.id)
    {
      this.checkconfirm(this.props.user.MainData);
    }
  }

  async AccDetClick(waarde) {
    this.props.dispatch(zetview(this.props.user.MainData.data.last_name+' '+this.props.user.MainData.data.first_name,this.props.user.MainData.data.id,'USR'));
    this.props.dispatch(clickview({id:waarde.data.id, vorm:'ACC'}));
    await this.props.dispatch(AllAccounts(this.props))
    this.props.dispatch(setSubTitle('Klantenfiche'))
    await this.props.dispatch(GetAccount(waarde.data.id))
    this.props.history.push('/account');
    return;
  }



  async handleJa(parameters)
  {
    if (this.state.save === true)
    {
      let retdata = await SqlFunction.DoSql('ChgUser',this.state.data);
      if (retdata.nwzip === true)
      {
        this.props.dispatch(AllPostcodes())
      }

    }
    if (this.state.subsave === true)
    {
       this.SecSaveAll(parameters.paramdata.data);
    }
    this.setState({edit:false,save:false,subsave:false})
  }


  checkconfirm (parameters) 
  {

    if (this.state.save === true || this.state.subsave === true)
    {
      confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,        
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Aanpassingen niet doorgevoerd.</h1>
            <p>Wens je de aanpassingen alsnog te bewaren?</p>
            <button
              onClick={() => {
                this.handleJa(parameters);
                onClose();
              }}
            >
              Ja
            </button>
            <button onClick={() => {
                this.setState({edit:false,save:false,subsave:false})
                onClose();
              }}
            >
              Nee
            </button>
          </div>
        );
      }
      });
    }

  }
  async SetPhoto(fileName) 
  {
    if (this.state.data && this.state.edit === true)
    {
      let data = this.state.data;
      data.foto = fileName;
      this.setState({data:data});
    }
  }

  resizeFunction() 
  {
    if (window.innerHeight >= 960) 
    {
      this.setState({tableheight: Math.floor(window.innerHeight * .70) + 'px'});
    }
    else
    {
      this.setState({tableheight: '600px'});
    }
  }


  async MainSave() {
    if (this.state.save === true) 
    {
      let retdata = await SqlFunction.DoSql('ChgUser',this.state.data);
      if (retdata.nwzip === true)
      {
        this.props.dispatch(AllPostcodes())
      }

      await this.props.dispatch(GetUser(retdata.id))
      this.setState({edit:false,save:false,data:null})
    }
  }

  async SecCellClick(waarde) {
    var data = waarde.data
    let column = waarde.column.colDef.field;
    let retdata = null;
    if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit'))
    {
      if (data.save === true) 
      {
        retdata = await SqlFunction.DoSql('ChgSecUserParam',data);
        if (retdata.nwzip === true)
        {
          this.props.dispatch(AllPostcodes())
        }
        waarde.data.save='';
        waarde.node.setData(waarde.data); 
        this.setState({subsave:false})
      }
    }
    return;
  }

  getContextMenuItems(params,sleutel) 
  {
    return null;
  }

  async SecSaveAll(items)
  {
    if (Array.isArray(items) && GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit'))
    {
      var i = 0;
      for (i = 0; i < items.length; i++) 
      {
        let data = items[i];
        await SqlFunction.DoSql('ChgSecUserParam',data);
      }
    }
  }                      


  CheckSave(data)
  {
    let terug = true
    terug = Validation.ValidCheck(this.props.settings.sub_title,data.first_name,'first_name',this,this.props.user.AlluserData.data,data) ;
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.sub_title,data.last_name,'last_name',this,this.props.user.AlluserData.data,data) ;
    }
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.sub_title,data.job_title,'job_title',this,this.props.user.AlluserData.data,data) ;
    }
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.sub_title,data.email,'email',this,this.props.user.AlluserData.data,data) ;
    }
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.sub_title,data.login,'login',this,this.props.user.AlluserData.data,data) ;
    }
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.sub_title,data.paswoord,'paswoord',this,this.props.user.AlluserData.data,data) ;
    }
    return !terug;
  }

  SecValueCheck(params,waarde) {
    if (GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit'))
    {
      params.props.data.save = true;
      this.setState({subsave:true})
    }
  }       


  async ValueCheck(that,waarde,type,colId) {
    let data = this.state.data;
    let terug = false;
    switch (colId) 
    {
      case 'active':
        data.active = 'N'
        if (waarde === true) 
        {
          data.active = 'Y'
        }
        break;

      case 'zipcode_txt':
        data.zipcode_txt = waarde.value;
        data.zipcode = waarde.id;
        break;

      default:    
        data[colId] = waarde;
        break;
    } 
    terug = Validation.ValidCheck(this.props.settings.sub_title,waarde,colId,that,this.props.user.AlluserData.data,data) ;
    that.setState({error:terug})
    terug = await this.CheckSave(data);
    this.setState({save:terug,data:data})
  }  
  
  async JobCellClick(waarde) {
    let jobdata = await SqlFunction.DoSql('GetJob', waarde.data);
    let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
    this.setState({ PopUpScreen: (<PopJobAanvrNum open={true} data={jobdata} close={this.ClosePopJobs} title={title} />) });
    return;
  }

  async ClosePopJobs() {
    this.setState({ PopUpScreen: null });
  }

  async CreateJob() {
    let data = await GenFunc.zetdataleeg('Job');
    data.user_id = this.props.user.MainData.data.id;
    data.start_date = GenFunc.Get_Vandaag();
    data.user_name = this.props.user.MainData.data.first_name + ' ' + this.props.user.MainData.data.last_name;
    let jobdata = { data: data, workflows: [] }
    this.setState({ PopUpScreen: (<PopJobAanvrNum open={true} data={jobdata} close={this.ClosePopJobs} title='Aanmaken job' />) });
  }

  render() {
    const { classes } = this.props;
    var {tableheight} = this.state;
    return (
      <div >

        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
          <Grid container>
            <Grid item xs={8}>          
              <h4 tag="h4" className="mt-3" onClick={() => {this.setState({edit:false})}} >{this.props.settings.sub_title}</h4>  
            </Grid>
            <Grid item xs={4}>  
            </Grid>
          </Grid>
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit')) ? false :
                                     (action.name === 'Edit' && (this.state.edit === true ) ) ? true :
                                     (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit')) ? true :
                                     (action.name === 'New' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit')) ? false :
                                     (action.name === 'New' && (this.state.edit === true )) ? true :
                                     (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit')) ? true :
                                     (action.name === 'Save' && (this.state.edit === true ) && this.state.save === true) ? false :
                                     (action.name === 'Save' && (this.state.edit === true ) && this.state.save === false) ? true :
                                     (action.name === 'Save' && this.state.edit === false ) ? true : 
                                     (action.name === 'Save' && this.state.save === false) ? true : false}}
                onClick={() => {this.ActionClick(action.name)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
          </SpeedDial>          
          {this.state.subspeedzien &&
            <SpeedDial
              ariaLabel="SpeedDial example"
              icon={<SubSpeedDialIcon style={{zIndex:'10'}}/>}
              direction="left"
              classes={{root: classes.subspeedDialToolb, fab: classes.subspeedFabRoot}}           
              onClose={this.handleSubSpeedClose}
              onOpen={this.handleSubSpeedOpen}
              open={this.state.subspeedopen}          
            >
              {this.state.subspeedactions.map(action => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipPlacement='bottom'
                  FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'Edit' && (this.state.edit === true || this.state.editfact === true) ) ? true :
                                       (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'New' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'New' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'NewRegistratie' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'NewRegistratie' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'NewRegistratie' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'Contact' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? false :
                                       (action.name === 'Contact' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'Contact' && !GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? true :
                                       (action.name === 'Document' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'Document' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'Document' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'ChangedAccData' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'ChangedAccData' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'ChangedAccData' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === true) ? false :
                                       (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === false) ? true :
                                       (action.name === 'Save' && this.state.edit === false && this.state.editfact === false) ? true : 
                                       (action.name === 'Save' && this.state.save === false) ? true : false}}
                  onClick={() => {this.SubActionClick(action.name)}}                             
                  tooltipTitle={action.tooltip}
                />
              ))}
            </SpeedDial>     
          }     
        </Toolbar>
        {this.props.user && this.props.user.MainData && this.props.user.MainData.data &&
          <Grid container style={{width: 'auto', padding: '54px 0px', paddingRight: '14px', paddingLeft: '14px', margin: '-15px -41px 35px'}}>
            <Grid container spacing={1}>
              <Grid item xs={this.state.edit === false ? 3 : 5}>
                <Card className='card_brut'>
                  {this.state.edit === false &&
                    <PerfectScrollbar className="sidebar-nav">
                      <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                        <div onClick={() => {this.ZetEdit()}} style={{width:'100%',cursor:'pointer'}}>
                          <PictureUpload stylemain={{textAlign:'center',width:'100%'}} inputstyle={{width:'100%',height:'160px'}}  style={{width:'150px',alignItems: 'center',  textAlign: 'center', borderRadius: '50%'}} SetFileName={this.SetPhoto} DefaultPhoto = {this.props.user.MainData.data.foto} />
                          <h4 style={{marginTop: '25px'}} className="card-title m-t-10">{this.props.user.MainData.data.first_name} {this.props.user.MainData.data.last_name}</h4>
                          <h4 style={{marginTop: '25px'}} className="card-title m-t-10">{this.props.user.MainData.data.code}</h4>
                          <h6 style={{marginBottom: '25px'}} className="card-subtitle">{this.props.user.MainData.data.job_title}</h6>                  
                          {this.props.user.MainData.data.active === 'Y' &&
                            <h4 style={{color:'green',marginBottom: '25px'}}>AKTIEF</h4>         
                          }
                          {this.props.user.MainData.data.active === 'N' &&
                            <h4 style={{color:'red',marginBottom: '25px'}}>NIET AKTIEF</h4>         
                          }
                          <div className='div_line' style={{marginBottom: '25px'}}></div>
                          <small className="text-muted p-t-30 db">Email Adres</small>                  
                          <h6>{this.props.user.MainData.data.email}</h6> 
                          <h6>{this.props.user.MainData.data.email2}</h6> 
                          <h6>{this.props.user.MainData.data.email3}</h6> 
                          <h6>{this.props.user.MainData.data.email4}</h6> 
                          <small className="text-muted p-t-30 db">Email Adres Beschikking</small>                  
                          <h6>{this.props.user.MainData.data.email5}</h6> 
                          <small className="text-muted p-t-30 db">Gsm Nummer</small>
                          <h6>{this.props.user.MainData.data.mobile_phone}</h6> 
                          <small className="text-muted p-t-30 db">Telefoon Nummer</small>
                          <h6>{this.props.user.MainData.data.main_phone}</h6> 
                          <small className="text-muted p-t-30 db">Addres</small>
                          <h6>{this.props.user.MainData.data.street} {this.props.user.MainData.data.housnum}</h6>                  
                          <h6>{this.props.user.MainData.data.zipcode_txt}</h6>         
                          <div className='div_line' style={{marginBottom: '25px'}}></div>
                        </div>
                      </CardBody>
                    </PerfectScrollbar>
                  }
                  {this.state.edit === true &&
                    <PerfectScrollbar styleclassName="sidebar-nav">
                      <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                          <PictureUpload stylemain={{textAlign:'center',width:'100%'}}  inputstyle={{width:'100%',height:'160px'}} style={{width:'150px',height:'150px',alignItems: 'center',  textAlign: 'center', borderRadius: '50%'}} SetFileName={this.SetPhoto} DefaultPhoto = {this.state.data.foto} />
                          <EditString className="card-title m-t-10"
                            value={this.state.data.last_name}
                            coltype = 'STR'                        
                            labelText="Naam"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'last_name')}
                          />
                          <EditString className="card-title m-t-10"
                            value={this.state.data.first_name}
                            coltype = 'STR'                        
                            labelText="Voornaam"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'first_name')}
                          />
                          <EditString className="card-title m-t-10"
                            value={this.state.data.code}
                            coltype = 'STR'                        
                            labelText="Initialen"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'code')}
                          />


                          <EditString className="card-title m-t-10"
                            value={this.state.data.job_title}
                            coltype = 'STR'                        
                            labelText="Funktie"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'job_title')}
                          />
                          <div className='div_line' style={{marginBottom: '25px',marginTop: '25px',borderColor:'#fb9678',borderWidth:'2px'}}></div>
                          <EditString className="card-title m-t-10"
                            value={this.state.data.email}
                            coltype = 'STR'                        
                            labelText="E-Mail"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'email')}
                          />
                          <EditString className="card-title m-t-10"
                            value={this.state.data.email2}
                            coltype = 'STR'                        
                            labelText="E-Mail"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'email2')}
                          />
                          <EditString className="card-title m-t-10"
                            value={this.state.data.email3}
                            coltype = 'STR'                        
                            labelText="E-Mail"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'email3')}
                          />
                          <EditString className="card-title m-t-10"
                            value={this.state.data.email4}
                            coltype = 'STR'                        
                            labelText="E-Mail"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'email4')}
                          />
                          <EditString className="card-title m-t-10"
                            value={this.state.data.email5}
                            coltype = 'STR'                        
                            labelText="E-Mail-Beschikking"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'email5')}
                          />

                          <EditString className="card-title m-t-10"
                            value={this.state.data.mobile_phone}
                            coltype = 'STR'                        
                            labelText="Gsm"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'mobile_phone')}
                          />
                          <EditString className="card-title m-t-10"
                            value={this.state.data.main_phone}
                            coltype = 'STR'                        
                            labelText="Telefoon"
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'main_phone')}
                          />
                          <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#fb9678',borderWidth:'2px'}}></div>
                          <Grid container spacing={1}>
                            <Grid item xs={8}>
                              <EditString className="card-title m-t-10"
                                value={this.state.data.street}
                                coltype = 'STR'                        
                                labelText="Straat"
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'street')}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <EditString className="card-title m-t-10"
                                value={this.state.data.housnum}
                                coltype = 'STR'                        
                                labelText="Huisnummer"
                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'housnum')}
                              />
                            </Grid>
                          </Grid>
                          <div style={{width:'100%',textAlign:'center'}}>
                            <EditAutoSelect className="card-title m-t-10"
                              value={this.state.data.zipcode_txt}
                              GoogleZoek={true}
                              labelText="Woonplaats"
                              lijst='AllPostcodes'
                              colId='zipcode_txt'
                              selectcel='zipcode'
                              id={this.state.data.zipcode}
                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'zipcode_txt')}
                            />
                          </div>
                          <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#fb9678',borderWidth:'2px'}}></div>
                          <div style={{width:'100%',textAlign:'center'}}>
                            <EditCheck className="card-title m-t-10"
                              value={this.state.data.active}
                              labelText="Actief"
                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'active')}
                            />
                          </div>
                          {this.state.data.active === 'Y' &&
                            <EditString className="card-title m-t-10"
                              value={this.state.data.login}
                              coltype = 'STR'                        
                              labelText="Login"
                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'login')}
                            />
                          }
                          {this.state.data.active === 'Y' &&
                            <EditString className="card-title m-t-10"
                              value={this.state.data.paswoord}
                              coltype = 'STR'                        
                              labelText="Paswoord"
                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'paswoord')}
                            />
                          }
                          <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#fb9678',borderWidth:'2px'}}></div>
                      </CardBody>
                    </PerfectScrollbar>
                  }
                </Card>
              </Grid>
              <Grid item xs={this.state.edit === false ? 9 : 7}>
                <Card className='card_brut'>
                  <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                    <div className='div_line'>
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.tabvalue === '1' })}
                            onClick={(event) => { this.handleTabChange(event,'1'); }}
                            style={{ cursor: 'pointer' }}
                          >
                            Activiteiten
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.tabvalue === '2' })}
                            onClick={(event) => { this.handleTabChange(event,'2'); }}
                            style={{ cursor: 'pointer' }}
                          >
                            Dossierbeheer
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.tabvalue === '3' })}
                            onClick={(event) => { this.handleTabChange(event,'3'); }}
                            style={{ cursor: 'pointer' }}
                          >
                            Beveiliging
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.tabvalue === '4' })}
                            onClick={(event) => { this.handleTabChange(event,'4'); }}
                            style={{ cursor: 'pointer' }}
                          >
                            Klanten
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.tabvalue === '5' })}
                            onClick={(event) => { this.handleTabChange(event,'5'); }}
                            style={{ cursor: 'pointer' }}
                          >
                            Jobs
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={this.state.tabvalue}>
                        <TabPane tabId="1">
                          { this.state.tabvalue === '1' &&this.props.user && this.props.user.MainData && this.props.user.MainData.data && this.props.user.MainData.data.id !== 0 &&                        
                            <div style={{marginTop:'20px', height:Math.floor(window.innerHeight * .65) + 'px'}}>
                             <BigCalendar
                                localizer={localizer}
                                events={[]}
                                startAccessor="start"
                                endAccessor="end"
                              />                      
                            </div>
                          }
                        </TabPane>
                        <TabPane tabId="2">
                        </TabPane>
                        <TabPane tabId="3">
                        
                          { this.state.tabvalue === '3' && this.props.user.MainData && this.props.user.MainData.paramdata && this.props.user.MainData.paramdata.data && this.props.user.MainData.paramdata.kol &&
                            this.props.user && this.props.user.MainData && this.props.user.MainData.data && this.props.user.MainData.data.id !== 0 &&
                            <TableGrid
                              tableheight={tableheight} 
                              columns={this.props.user.MainData.paramdata.kol}
                              data={this.props.user.MainData.paramdata.data} 
                              sizetofit={true}
                              sortable={false}
                              suppressMenu={true}
                              enableRowGroup={false}
                              headerComponentParams = {{
                              template:
                              '<div class="ag-cell-label-container" role="presentation">' +
                              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                              '    <span style="text-align:center;width:100%" ref="eText" class="ag-header-cell-text" role="columnheader"></span>' +
                              '  </div>' +
                              '</div>'
                              }}
                              suppressColumnVirtualisation={true}                                
                              filter={false}
                              sleutel='AllSecParUser'
                              CellValueCheck={(params,value,error) => this.SecValueCheck(params,value,error)}
                              SaveAll={(params) => this.SecSaveAll(params)}
                              getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                              editable={true}
                              sql={() => this.props.dispatch(GetUser(this.props.user.MainData.data.id))}
                              CellClickedEvent={(column, colDef, value) => this.SecCellClick(column,colDef, value)}
                            />
                          }                      
                        </TabPane>
                        <TabPane tabId="4">
                        
                          { this.state.tabvalue === '4' && this.props.user.AccountData && this.props.user.AccountData.data && this.props.user.AccountData.kol &&
                            this.props.user && this.props.user.MainData && this.props.user.MainData.data && this.props.user.MainData.data.id !== 0 &&
                            <TableGrid
                              tableheight={tableheight} 
                              columns={this.props.user.AccountData.kol}
                              data={this.props.user.AccountData.data} 
                              sizetofit={false}
                              sortable={false}
                              suppressMenu={true}
                              enableRowGroup={false}
                              suppressColumnVirtualisation={true}                                
                              filter={false}
                              sleutel='AllUserAccounts'
                              getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                              editable={false}
                              sql={() => this.props.dispatch(GetUser(this.props.user.MainData.data.id))}
                              CellClickedEvent={(column, colDef, value) => this.AccDetClick(column,colDef, value)}
                            />
                          }                      
                        </TabPane>
                        <TabPane tabId="5">
                          {this.state.tabvalue === '5' && this.props.user.JobsData && this.props.user.JobsData.data && this.props.user.JobsData.kol &&
                            this.props.settings.listmaindata && this.props.settings.listmaindata.mainkey &&
                            this.props.user && this.props.user.MainData && this.props.user.MainData.data && this.props.user.MainData.data.id !== 0 &&
                            <TableGrid
                              tableheight={tableheight}
                              columns={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['kol']}
                              data={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['data']}
                              CellClickedEvent={(column, colDef, value) => this.JobCellClick(column, colDef, value)}
                              sortable={this.props.settings.listmaindata.sortable}
                              sizetofit={this.props.settings.listmaindata.sizetofit}
                              suppressMenu={this.props.settings.listmaindata.suppressMenu}
                              enableRowGroup={this.props.settings.listmaindata.enableRowGroup}
                              headerComponentParams={null}
                              suppressColumnVirtualisation={this.props.settings.listmaindata.suppressColumnVirtualisation}
                              filter={this.props.settings.listmaindata.filter}
                              editable={false}
                              sleutel={this.props.settings.listmaindata.referentie}
                            />
                          }

                        </TabPane>
                      </TabContent>
                    </div>
                  </CardBody>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        }
        {this.state.PopUpScreen}
      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Userpage));

