import React from 'react';
import GenFunc from "../functions/gen_functions.js";
import {AllUsers} from "../redux/settings/userActions";
import {AllRegOms,AllRegistratie} from "../redux/settings/registrationActions";
import {AllMunten, AllPostcodes,AllJobs,AllFuncties} from "../redux/settings/paramActions";
import {AllAccounts} from "../redux/settings/accountActions";
import {AllContacts} from "../redux/settings/contactActions";
import {AllKantoren} from "../redux/settings/kantoorActions";
import {AllLanden,AllLandenAcc, AllPeriodesAcc} from "../redux/settings/landActions";
import {AllTemplates, GetTemplateFields} from "../redux/settings/templateActions";
import {AllSuppliers} from "../redux/settings/supplierActions";
import {AllJobSubj, AllJobDefMain} from "../redux/settings/jobsActions";


import Grid from '@material-ui/core/Grid';
import classNames from "classnames";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';
import { withStyles } from '@material-ui/core/styles';
import styles from "../assets/components/customInputStyle.jsx";
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  ...state
});


function googletodata(googledata,statedata,that) {
  if (!googledata) {
    return [];
  }
  let result = [];
  for (var i = 0; i < googledata.length; i++) 
  {
    let data = googledata[i];
    let primtext = data.structured_formatting.main_text + ' '+data.structured_formatting.secondary_text;

    for (var ti = 0; ti < data.terms.length; ti++) 
    {
       if (ti === 0)
       {
           primtext = unescape(decodeURI(data.terms[0].value))

       }
       if (ti === 1)
       {
           primtext = primtext + '-'+unescape(decodeURI(data.terms[1].value))
       }
       if (ti === 2)
       {
           primtext = primtext + '-'+unescape(decodeURI(data.terms[2].value))
       }

    }
    let datacol = {}
    datacol[that.state.data_code] = primtext;
    datacol[that.state.data_id] = 0;    
    let filterresult = statedata.filter(o=> o[that.state.data_code] === primtext);
    let verder = 1;
    if (Array.isArray(filterresult) && filterresult.length > 0)
    {
      verder = 0;
    }
    if (verder === 1)
    {
      result.push(datacol)
    }
  }
  return result;
}



class EditAutoSelect extends React.Component {
    constructor(props) {
      super(props);
      this.change = this.change.bind(this);

      this.loaddata = this.loaddata.bind(this);
      this.SetFilter = this.SetFilter.bind(this);
      this.SetWaarde = this.SetWaarde.bind(this);
      this.addRecord = this.addRecord.bind(this);

      this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);


        
      this.handleFocus = this.handleFocus.bind(this);
      this.handleBlur = this.handleBlur.bind(this);        

      this.SetObject = this.SetObject.bind(this);
      this.inputid = GenFunc.MakeId()
      this.state = {
          value:'',
          valueoverzet:'',
          id:'',
          zetwaarde:false,
          clear:false,
          error:false,
          openmenu:true,
          reason:'reset',
          autoSelect:true,
          checkval:false,
          data:[],
          datacol:null,
      }
      if (props.GoogleZoek) {
        this.service = new window.google.maps.places.AutocompleteService();        
      }
      this.inputvalue = this.props.value
    }
    async handleFocus() {
      if (this.inputid)
      { 
        let elem = document.getElementById(this.inputid)
        if (elem)
        {
            elem.focus();
        }

      }

      await this.setState({ autoSelect: true, openmenu:true });
    }  
    async handleBlur() {
      await this.setState({ autoSelect: false, openmenu:false });
    }  

    async componentWillReceiveProps(nextProps, nextState) 
    {
      if (this.state.value !== nextProps.value && this.state.id !== nextProps.id)
      {
        let datacol = {}
        datacol[this.state.data_code] = nextProps.value;
        datacol[this.state.data_id] = nextProps.id;
        this.setState({datacol:datacol,value:nextProps.value, id:nextProps.id})
//        this.props.ValueCheck(this,{id:nextProps.id,value:nextProps.value},'AUTOSEL')
      }

      if (nextProps.checkval === true)
      {
        this.props.ValueCheck(this,{id:this.props.id,value:this.props.value},'AUTOSEL',null)
      }
    }


    async loaddata() {
      let data = [];
      let data_id = '';
      let data_code = '';
      switch (this.props.lijst) 
      {
        case 'AllMunten':
          data_id = 'id';
          data_code = 'code';
          if (!this.props.param.AllMuntenData) 
          {
            await this.props.dispatch(AllMunten());
          }
          data = this.props.param.AllMuntenData && this.props.param.AllMuntenData.data ? this.props.param.AllMuntenData.data : []
          break;

        case 'AllSuppliers':
          data_id = 'id';
          data_code = 'zoektxt';
          if (!this.props.supplier.AllData) {
            await this.props.dispatch(AllSuppliers());
          }
          data = this.props.supplier.AllData && this.props.supplier.AllData.data ? this.props.supplier.AllData.data : []
        break;



        case 'AllContacts':
          data_id = 'id';
          data_code = 'cont_name';
          if (!this.props.contact.AllData) {
            await this.props.dispatch(AllContacts());
          }
          data =  this.props.contact.AllData && this.props.contact.AllData.data ? this.props.contact.AllData.data : []
          break;

        case 'TemplateFields':
          data_id = 'id';
          data_code = 'text';

          if (!this.props.template.FieldData.data) {
            await this.props.dispatch(GetTemplateFields());
          }
          data =  this.props.template.FieldData && this.props.template.FieldData.data ? this.props.template.FieldData.data : []
          break;


        case 'AllRegistratie':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.registration.AllRegData) {
            await this.props.dispatch(AllRegistratie());
          }
          data = this.props.registration.AllRegData && this.props.registration.AllRegData.data ? this.props.registration.AllRegData.data : []
          break;

        case 'AllTemplates':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.template.AllData) {
            await this.props.dispatch(AllTemplates());
          }
          data = this.props.template.AllData && this.props.template.AllData.data ? this.props.template.AllData.data : []
          break;




        case 'AllRegOms':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.registration.AllRegOmsData) {
            await this.props.dispatch(AllRegOms());
          }
          data = this.props.registration.AllRegOmsData && this.props.registration.AllRegOmsData.data ? this.props.registration.AllRegOmsData.data : []
          break;

        case 'AllUsers':
          data_id = 'id';
          data_code = 'name';
          if (!this.props.user.AlluserData) {
            await this.props.dispatch(AllUsers());
          }
          data = this.props.user.AlluserData && this.props.user.AlluserData.data ? this.props.user.AlluserData.data : []
          break;

        case 'AllAccounts':
          data_id = 'id';
          data_code = 'name';
          if (!this.props.account.AllData) {
            await this.props.dispatch(AllAccounts());
          }
          data =  this.props.account.AllData && this.props.account.AllData.data ? this.props.account.AllData.data : []
          break;

        case 'AllAccountNumbers':
          data_id = 'id';
          data_code = 'number';
          if (!this.props.account.AllData) {
            await this.props.dispatch(AllAccounts());
          }
          data =  this.props.account.AllData && this.props.account.AllData.data ? this.props.account.AllData.data : []
          break;

        case 'TimeRegActivities':
          data_id = 'id';
          data_code = 'text';
          data = [{ id: '1', text: 'Directe Registratie' }, { id: '2', text: 'Accijnzen' }, { id: '3', text: 'VAT-Refund' }, { id:'4', text: "Divers" }];
          break;

        case 'AllPostcodes':
          data_id = 'id';
          data_code = 'zipcode_txt';
          if (!this.props.param.AllPostcodesData) {
            await this.props.dispatch(AllPostcodes());
          }
          data = this.props.param.AllPostcodesData && this.props.param.AllPostcodesData.data ? this.props.param.AllPostcodesData.data : []
          break;

        case 'AllFuncties':
          data_id = 'id';
          data_code = 'omschrijving';
          await this.props.dispatch(AllFuncties());
          data = this.props.param.AllFunctiesData && this.props.param.AllFunctiesData.data ? this.props.param.AllFunctiesData.data : []
          break;


        case 'AllJobs':
          data_id = 'id';
          data_code = 'description';
          await this.props.dispatch(AllJobs());
          data = this.props.param.AllJobsData ? this.props.param.AllJobsData : []

          break;

        case 'AllJobSubj':
          data_id = 'id';
          data_code = 'text';
          if (!this.props.jobs.AllJobSubjData) {
            await this.props.dispatch(AllJobSubj());
          }
          data = this.props.jobs.AllJobSubjData ? this.props.jobs.AllJobSubjData.data : []
          break;


        case 'AllJobSubjTime':
          data_id = 'id';
          data_code = 'text';
          if (!this.props.jobs.AllJobSubjData) {
            await this.props.dispatch(AllJobSubj());
          }
          data = this.props.jobs.AllJobSubjData ? this.props.jobs.AllJobSubjData.data : []

          let findindex = data.findIndex(x => x.id === "99");
          if (findindex < 0)
          {
            data.push({ id: '99', text: 'Afwezig' })
          }
          break;


        case 'AllLanden':
          data_id = 'id';
          data_code = 'nametxt';
          if (!this.props.land.AllData) {
            await this.props.dispatch(AllLanden());
          }
          data = this.props.land.AllData && this.props.land.AllData.data ? this.props.land.AllData.data : []

          break;


        case 'AllPeriodesAcc':
          data_id = 'id';
          data_code = 'text';

          if (!this.props.land.AllDataPeriodeAcc && this.props.account.MainData) {
            await this.props.dispatch(AllPeriodesAcc(this.props,this.props.account.MainData.data.id));
          }
          if (!this.props.land.AllDataPeriodeAcc && this.props.registration.MainData) {
            await this.props.dispatch(AllPeriodesAcc(this.props,this.props.registration.MainData.data.accountid));
          }

          data = this.props.land.AllDataPeriodeAcc && this.props.land.AllDataPeriodeAcc.data ? this.props.land.AllDataPeriodeAcc.data : []
          break;


        case 'AllLandenAcc':
          data_id = 'id';
          data_code = 'text';

          if (!this.props.land.AllDataAcc && this.props.account.MainData) {
            await this.props.dispatch(AllLandenAcc(this.props,this.props.account.MainData.data.id));
          }
          if (!this.props.land.AllDataAcc && this.props.registration.MainData) {
            await this.props.dispatch(AllLandenAcc(this.props,this.props.registration.MainData.data.accountid));
          }

          data = this.props.land.AllDataAcc && this.props.land.AllDataAcc.data ? this.props.land.AllDataAcc.data : []
          break;

        case 'AllJobDefMainAcc':
          data_id = 'id';
          data_code = 'text';
          if (this.props.jobs.AllJobDefMainAccData && this.props.jobs.AllJobDefMainAccData.data)
          {
            data = this.props.jobs.AllJobDefMainAccData.data
          }
          break;


        case 'AllJobDefMain':
          data_id = 'id';
          data_code = 'text';
          if (!this.props.jobs.AllJobDefMainData) {
            await this.props.dispatch(AllJobDefMain());
          }
          if (this.props.jobs.AllJobDefMainData && this.props.jobs.AllJobDefMainData.data)
          {
            data = this.props.jobs.AllJobDefMainData.data
          }
          break;


        case 'AllRegistratieAcc':
          data_id = 'id';
          data_code = 'text';
          if (this.props.jobs.AllRegistratieAccData && this.props.jobs.AllRegistratieAccData.data)
          {
            data = this.props.jobs.AllRegistratieAccData.data
          }
          break;

        case 'AllJobRegNumAcc':
          data_id = 'id';
          data_code = 'text';
          if (this.props.jobs.AllJobRegNumAccData && this.props.jobs.AllJobRegNumAccData.data)
          {
            data = this.props.jobs.AllJobRegNumAccData.data
          }
          break;

          


        case 'AllKantoren':
          data_id = 'id';
          data_code = 'name';
          await this.props.dispatch(AllKantoren());
          data = this.props.kantoor.AllData && this.props.kantoor.AllData.data ? this.props.kantoor.AllData.data :[]
          break;

        default:    
          break;
      }   
      if (this.props.filter && this.props.filter.length > 0)
      {
        console.log(this.props)
        let datfilt = data;
        if (this.props && this.props.colId === "corr_aang_txt")
        {
          datfilt = datfilt.filter(o=> (o['job_id'] === 'c8011e2728d9d4c2f636f067f89cc14862c' || o['job_id'] === '3c025921dc048e8850243be8079a5c74d079') );
        }

        console.log(datfilt)




        for (var ti = 0; ti < this.props.filter.length; ti++) 
        {
          let rowfilt = this.props.filter[ti];
          let filtfield = rowfilt['field'];
          let filtvalue = rowfilt['value'];
          datfilt = datfilt.filter(o=> o[filtfield] === filtvalue);
        }         
        data = datfilt;
      }
      this.setState({data:data, data_code:data_code, data_id:data_id})
    }
    async SetFilter(filter) {
      let data = this.state.data;
      let data_id = '';
      let data_code = '';
      switch (this.props.lijst) 
      {
        case 'AllJobs':
          data_id = 'id';
          data_code = 'description';
          await this.props.dispatch(AllJobs());
          data = this.props.param.AllJobsData ? this.props.param.AllJobsData : []
          break;

        case 'AllMunten':
          data_id = 'id';
          data_code = 'code';
          await this.props.dispatch(AllMunten());
          if (this.props.param.AllMuntenData && this.props.param.AllMuntenData.data) 
          {
            data = this.props.param.AllMuntenData.data
          }
          break;

        case 'AllSuppliers':
          data_id = 'id';
          data_code = 'name';
          if (!this.props.supplier.AllData) {
            await this.props.dispatch(AllSuppliers());
          }
          data = this.props.supplier.AllData && this.props.supplier.AllData.data ? this.props.supplier.AllData.data : []
        break;


        case 'AllContacts':
          data_id = 'id';
          data_code = 'cont_name';
          await this.props.dispatch(AllContacts());
          if (this.props.contact.AllData && this.props.contact.AllData.data) 
          {
            data =  this.props.contact.AllData.data
          }
          break;

        case 'TemplateFields':
          data_id = 'id';
          data_code = 'text';
          await this.props.dispatch(GetTemplateFields());
          if (this.props.template.FieldData && this.props.template.FieldData.data) 
          {
            data =  this.props.template.FieldData && this.props.template.FieldData.data ? this.props.template.FieldData.data : []
          }
          break;


        case 'AllTemplates':
          data_id = 'id';
          data_code = 'description';
          await this.props.dispatch(AllTemplates());
          if (this.props.template.AllData && this.props.template.AllData.data) 
          {
              data = this.props.template.AllData && this.props.template.AllData.data ? this.props.template.AllData.data : []
          }
          break;          

        case 'AllRegistratie':
          data_id = 'id';
          data_code = 'description';
          await this.props.dispatch(AllRegistratie());
          if (this.props.registration.AllRegData && this.props.registration.AllRegData.data) 
          {
            data = this.props.registration.AllRegData.data
          }
          break;


        case 'AllRegOms':
          data_id = 'id';
          data_code = 'description';
          await this.props.dispatch(AllRegOms());
          if (this.props.registration.AllRegOmsData && this.props.registration.AllRegOmsData.data) 
          {
            data = this.props.registration.AllRegOmsData.data
          }
          break;

        case 'AllUsers':
          data_id = 'id';
          data_code = 'name';
          await this.props.dispatch(AllUsers());
          if (this.props.user.AlluserData && this.props.user.AlluserData.data) 
          {
            data = this.props.user.AlluserData.data
          }
          break;

        case 'AllAccounts':
          data_id = 'id';
          data_code = 'name';
          await this.props.dispatch(AllAccounts());
          if (this.props.account.AllData && this.props.account.AllData.data) 
          {
            data =  this.props.account.AllData.data
          }
          break;


        case 'AllPostcodes':
          data_id = 'id';
          data_code = 'zipcode_txt';
          await this.props.dispatch(AllPostcodes());
          if (this.props.param.AllPostcodesData && this.props.param.AllPostcodesData.data) 
          {
            data = this.props.param.AllPostcodesData.data
          }
          break;

        case 'AllLanden':
          data_id = 'id';
          data_code = 'nametxt';
          await this.props.dispatch(AllLanden());
          if (this.props.land.AllLandenData && this.props.land.AllLandenData.data) 
          {
            data = this.props.land.AllLandenData.data
          }
          break;



        case 'AllKantoren':
          data_id = 'id';
          data_code = 'name';
          await this.props.dispatch(AllKantoren());
          if (this.props.kantoor.AllData && this.props.kantoor.AllData.data) 
          {
            data = this.props.kantoor.AllData.data
          }
          break;

        default:    
          break;
      }   

      if (filter && filter.length > 0 && data)
      {
        let datfilt = data;
        for (var ti = 0; ti < filter.length; ti++) 
        {
          let rowfilt = filter[ti];
          let filtfield = rowfilt.field;
          let filtvalue = rowfilt.value;
          if (filtfield && filtvalue)
          {
            datfilt = datfilt.filter(o=> o[filtfield] === filtvalue);
          }
        }         
        data = datfilt;
      }
      this.setState({data:data, data_code:data_code, data_id:data_id})
    }
    async SetWaarde(id,text) {
       let datacol = {}
       datacol[this.state.data_code] = text;
       datacol[this.state.data_id] = id;
       this.setState({datacol:datacol,value:text, id:id, zetwaarde:true})
    }


    async componentDidMount() {
      await this.loaddata();      
        if (this.props.value)
        {
           let datacol = {}
    
           datacol[this.state.data_code] = this.props.value;
           datacol[this.state.data_id] = this.props.id;
           this.setState({datacol:datacol,valueoverzet:this.props.value, value:this.props.value, id:this.props.id, clear:false})
        }
        this.overzdata = null;
        this.SetObject();
        if (this.props.setError)
        {
          this.props.setError(this,this.props.value,'STRING')
        }
    }

    addRecord(event, waarde, stateName)
    {
       if (this.props.addRecord)
       {
          this.props.addRecord(this,this.state.valueoverzet)
       }
    }

    SetObject()
    {
        if (this.props.SetObject) 
        {
            this.props.SetObject(this,this.props.selectcel)          
        }
    }


    change(event, waarde, stateName) 
    {
      if (this.state.openmenu === false)
      {
          return
      }
      if (waarde && waarde.length > 1)
      {
        this.setState({valueoverzet:waarde,autoSelect:false})
      }
      const { types = ['(regions)'] } = this.props;  
      let status = this.state;    
      if (this.props.GoogleZoek)
      {
        if (event && event.target && event.target.value) 
        {
          let result = this.state.data.filter(o=> o[this.state.data_code].indexOf(event.target.value) === false);
          let verder = 1;
          if (Array.isArray(result) && result.length > 0)
          {
             verder = 0;
          }
          if (verder === 1)
          {
            this.service.getPlacePredictions(
              { input: event.target.value, types },
              (predictions, status) => 
              {
                if (status === 'OK' && predictions && predictions.length > 0) 
                {
                  let hdata = googletodata(predictions,this.state.data,this);
                  let statdata = this.state.data;
                  Array.prototype.push.apply(statdata, hdata);
                  this.setState({data:statdata});
                }
              }
            )
          }        
        }
      }
      if (waarde && waarde[this.state.data_code] !== undefined && waarde[this.state.data_code].length > 0)
      {
        this.overzdata = waarde;
        status.clear = false;
        this.setState({value:waarde[this.state.data_code],id:waarde[this.state.data_id], autoSelect:true,datacol:waarde,clear:false})
        this.props.ValueCheck(this,{id:waarde[this.state.data_id],value:waarde[this.state.data_code],text:waarde[this.state.data_code]},'AUTOSEL',waarde)
      }
      else
      {
         if (event && !event.target && stateName === 'input')
         {
            status.clear = false;
            this.setState({value:event,autoSelect:true,clear:false})
         }
         if (stateName === 'clear')
         {
            this.overzdata = null;
            status.clear = true;
//            this.setState({value:'',id:'',datacol:null,autoSelect:false,clear:true})
            this.props.ValueCheck(this,{id:'',value:'',text:''},'AUTOSEL',null);
         }
      }
      this.setState({zetwaarde:false})
    }

    render() {
      const {
        classes,
      } = this.props;
      const formControlProps={}


      var formControlClasses;
      if (formControlProps !== undefined) {
        formControlClasses = classNames(
          formControlProps.className,
          classes.formControl
        );
      } else {
        formControlClasses = classes.formControl;
      }

      let startend = this.props.addRecord ? (<IconButton onClick ={this.addRecord} style= {{marginTop: '-40px',marginLeft: '-20px'}} aria-label="toggle password visibility">
                        <AddCircle/>
                      </IconButton>) : null;

      return (
        <Grid   style={{marginTop:'5px'}} onMouseLeave={this.handleBlur} onMouseEnter={this.handleFocus} container spacing={1} alignItems="flex-end">
          <Grid  style={this.props.addRecord ? {width: 'calc(100% - 40px)'} : {width: '100%'}} item>
 


            <Autocomplete   {...formControlProps}  className={formControlClasses}
              id={GenFunc.MakeId()}
              autoComplete={true}
              disableOpenOnFocus={true}
              selectOnFocus = {true}
              blurOnSelect={true}
              autoHighlight={true}
              autoSelect={this.state.autoSelect}      
              style={{top:'-2px',position:'relative',width:'100%',paddingRight:'5px'}}
              options={this.state.data}
              value={this.state.value}
              classes={{option: this.props.classes.AutocompleteOption, listbox:this.props.classes.AutocompleteListbox}}
              getOptionLabel={option => (typeof option === 'string' ? option ? option : '' : option[this.state.data_code])}
              onChange ={this.change}
              onInputChange={this.change}
              renderInput={params => (
                <TextField
                  {...params}
                  style={{top:'-22px'}}
                  error={this.state.error}
                  label={this.props.labelText}
                  classes={this.state.error ? {root: this.props.classes.selectinputbruterror}: {root: this.props.classes.selectinputbrut}}          
                  fullWidth
                    inputProps={{
                      ...params.inputProps,
                      id:this.inputid,
                    }}        
                />
              )}
            />
          </Grid>
          {this.props.addRecord &&
            <Grid item>
              {startend}
            </Grid>
          }

        </Grid>

      );
    }
}
export default connect(mapStateToProps)(withStyles(styles)(EditAutoSelect));
