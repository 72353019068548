
import { ALLKANTOREN_SUCCESS, GETKANTOOR_SUCCESS, GETCONTKANTOOR_SUCCESS, GETDOCKANTOOR_SUCCESS, ALLKANTCONTACTS_SUCCESS, GETKANTCONTACT_SUCCESS, 
  GETDOCKANTCONTACT_SUCCESS, GETREGKANTOOR_SUCCESS, GETJOBSKANTOOR_SUCCESS, KANT_TAB } from '../constants/';

const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const AllKantorenSuccess = (payload) => {
  return {
    type: ALLKANTOREN_SUCCESS,
    payload: payload.response.data
  }
}

export const setTab = (payload) => {
    return {
        type: KANT_TAB,
        payload
    }
}

export const GetKantoorSuccess = (payload) => {

  return {
    type: GETKANTOOR_SUCCESS,
    payload: payload.response.data
  }
}

export const GetContKantoorSuccess = (payload) => {
  return {
    type: GETCONTKANTOOR_SUCCESS,
    payload: payload.response.data
  }
}

export const GetDocKantoorSuccess = (payload) => {
  return {
    type: GETDOCKANTOOR_SUCCESS,
    payload: payload.response.data
  }
}

export const AllKantContactsSuccess = (payload) => {
  return {
    type: ALLKANTCONTACTS_SUCCESS,
    payload: payload.response.data
  }
}

export const GetKantContactSuccess = (payload) => {

  return {
    type: GETKANTCONTACT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetDocKantContactSuccess = (payload) => {
  return {
    type: GETDOCKANTCONTACT_SUCCESS,
    payload: payload.response.data
  }
}

export const GetRegKantoorSuccess = (payload) => {
  return {
    type: GETREGKANTOOR_SUCCESS,
    payload: payload.response.data
  }
}

export const GetJobsKantoorSuccess = (payload) => {
  return {
    type: GETJOBSKANTOOR_SUCCESS,
    payload: payload.response.data
  }
}

//Alle kantoren
export function AllKantoren(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllKantoren', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllKantorenSuccess({ response }))
        }
      },
      () => dispatch(AllKantorenSuccess(null))
    );
  }
}

//Kantoor met id
export function GetKantoor(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetKantoor', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetKantoorSuccess({ response })) },
      () => { dispatch(GetKantoorSuccess(null)) }
    );
  }
}

//Alle contacts van kantoor met id
export function GetContKantoor(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetContKantoor', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetContKantoorSuccess({ response })) },
      () => { dispatch(GetContKantoorSuccess(null)) }
    );
  }
}

//Alle documenten van kantoor met id
export function GetDocKantoor(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetDocKantoor', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetDocKantoorSuccess({ response })) },
      () => { dispatch(GetDocKantoorSuccess(null)) }
    );
  }
}

//Alle jobs van kantoor met id
export function GetJobsKantoor(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetJobsKantoor', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetJobsKantoorSuccess({ response })) },
      () => { dispatch(GetJobsKantoorSuccess(null)) }
    );
  }
}

//Nieuw kantoor
export function CreateKantoor() {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, name: '', e_mail: '', main_phone: '', type_reg:0,type_reg_txt:'',adres_1:'',adres_2:'',adres_3:'',huisnum:'',email2:'',adress: '', zipcode: "", zipcode_txt: "", country: "", country_txt: "", iban: '', bic: '', info: '' }
    response.data = { data: data }
    dispatch(GetKantoorSuccess({ response }));
  }
}

//Nieuw kantoor vanuit landen parameter
export function CreateLandKantoor(creavalues) {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, name: '', e_mail: '', main_phone: '', type_reg:0,type_reg_txt:'',adres_1:'',adres_2:'',adres_3:'',huisnum:'',email2:'',adress: '', zipcode: "", zipcode_txt: "", country: "", country_txt: "", iban: '', bic: '', info: '' }
    if (creavalues) {
      if (creavalues.countryid) {
        data.country = creavalues.countryid;
        data.country_txt = creavalues.country_name;
      }
    }
    response.data = { data: data }
    dispatch(GetKantoorSuccess({ response }));
  }
}

//Alle contacts
export function AllKantContacts() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllKantContacts', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllKantContactsSuccess({ response })) },
      () => { dispatch(AllKantContactsSuccess(null)) },
    );
  }
}

//Contact met id
export function GetKantContact(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetKantContact', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetKantContactSuccess({ response })) },
      () => { dispatch(GetKantContactSuccess(null)) }
    );
  }
}

//Alle documenten van contact met id
export function GetDocKantContact(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetDocKantContact', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetDocKantContactSuccess({ response })) },
      () => { dispatch(GetDocKantContactSuccess(null)) }
    );
  }
}

//Nieuw contact
export function CreateKantContact(creavalues) {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, parent_account: '', zipcode: '', zipcode_txt: '', kantoor_name: '', main_phone: '', last_name: '', street: '', first_name: '', salutation: '', jobtitle: '', mobile_phone: '', e_mail: '', info: '' }
    if (creavalues) {
      if (creavalues.kantoorid) {
        data.parent_account = creavalues.kantoorid;
        data.kantoor_name = creavalues.kantoor_name;
      }
    }
    response.data = { data: data }
    dispatch(GetKantContactSuccess({ response }));
  }
}

export function GetRegKantoor(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetRegKantoor', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetRegKantoorSuccess({ response })) },
      () => { dispatch(GetRegKantoorSuccess(null)) }
    );
  }
}