import { ALLTEMPLATES_SUCCESS,GETTEMPLATE_SUCCESS,GETTEMPLATEFIELDS_SUCCESS } from '../constants/';

const INIT_STATE = {
    AllData: null,
    MainData: null,
    FieldData: [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ALLTEMPLATES_SUCCESS:
            return {
                ...state,
                AllData: action.payload
            };
        case GETTEMPLATE_SUCCESS:
            return {
                ...state,
                MainData: action.payload
            }

        case GETTEMPLATEFIELDS_SUCCESS:
            return {
                ...state,
                FieldData: action.payload
            }

        default:
            return state;

        
    }
};