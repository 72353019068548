import {
    LOGO_BG,
    NAVBAR_BG,
    SIDEBAR_BG,
    THEME,
    DIRECTION,
    SIDEBAR_POSITION,
    HEADER_POSITION,
    LAYOUT,
    SIDEBAR_TYPE,
    MAIN_TITLE,
    SUB_TITLE,
    SECURITY_TITLE,
    SIDEBAR_STATE,
    PANEL_RIGHT,
    RIGHT_TITLE,
    LOAD_VIEW_SUCCESS,
    CLICK_VIEW_SUCCESS,
    CLICK_VIEW_LEEG_SUCCESS,
    GETLISTMAIN_SUCCESS,
    ZET_MAINLIST_SUCCESS,
    RESET_VIEW_SUCCESS,
    ZET_POPUP_SUCCESS,
} from '../constants/';

const Jobs = require('./jobsActions');
const Users = require('./userActions');
const Contacts = require('./contactActions');
const Param = require('./paramActions');
const Accounts = require('./accountActions');
const Registrations = require('./registrationActions');
const Kantoren = require('./kantoorActions');
const Landen = require('./landActions');
const Templates = require('./templateActions');
const Suppliers = require('./supplierActions');
const Timereg = require('./timeregistrationActions');

const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const GetListmainSuccess = (payload) => {
    return {
        type: GETLISTMAIN_SUCCESS,
        payload: payload.response.data
    }
}

export const setLogoBg = (payload) => {
    return {
        type: LOGO_BG,
        payload
    }
}

export const setMainTitle = (payload) => {
    return {
        type: MAIN_TITLE,
        payload
    }
}

export const setSubTitle = (payload) => {
    return {
        type: SUB_TITLE,
        payload
    }
}

export const setSecurityTitle = (payload) => {
    return {
        type: SECURITY_TITLE,
        payload
    }
}


export const setNavbarBg = (payload) => {
    return {
        type: NAVBAR_BG,
        payload
    }
}

export const setSidebarBg = (payload) => {
    return {
        type: SIDEBAR_BG,
        payload
    }
}

export const setTheme = (payload) => {
    return {
        type: THEME,
        payload
    }
}

export const setDir = (payload) => {
    return {
        type: DIRECTION,
        payload
    }
}

export const setSidebarPos = (payload) => {
    return {
        type: SIDEBAR_POSITION,
        payload
    }
}

export const setHeaderPos = (payload) => {
    return {
        type: HEADER_POSITION,
        payload
    }
}

export const setLayout = (payload) => {
    return {
        type: LAYOUT,
        payload
    }
}

export const setSidebarType = (payload) => {
    return {
        type: SIDEBAR_TYPE,
        payload
    }
}

export const setSidebarState = (payload) => {
    return {
        type: SIDEBAR_STATE,
        payload
    }
}

export const setPanelRight = (payload) => {
    return {
        type: PANEL_RIGHT,
        payload
    }
}

export const setTitleRight = (payload) => {
    return {
        type: RIGHT_TITLE,
        payload
    }
}

export function zetListMain(sleutel, path) {
    let payload = {sleutel:sleutel, path:path};
    return {
        type: ZET_MAINLIST_SUCCESS,
        payload: payload
    }
}

export function zetview(description, id, vorm, object) {
    let payload = { id: id, description: description, vorm: vorm, object:object };
    return {
        type: LOAD_VIEW_SUCCESS,
        payload: payload
    }
}

export function resetview() {
    let payload = {};
    return {
        type: RESET_VIEW_SUCCESS,
        payload: payload
    }
}

export function zetpopup(vorm) {
    let payload = vorm;
    return {
        type: ZET_POPUP_SUCCESS,
        payload: payload
    }
}


export function zetviewleeg(description, id, vorm, object) {
    let payload = { id: id, description: description, vorm: vorm, object:object };    
    return {
        type: CLICK_VIEW_LEEG_SUCCESS,
        payload: payload
    }
}



export function clickview(data) {
    let payload = data;
    return {
        type: CLICK_VIEW_SUCCESS,
        payload: payload
    }
}

export function GetListmain() {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetListmain', {});
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetListmainSuccess({ response })) },
            () => { dispatch(GetListmainSuccess(null)) },
        );
    }
}


export function ZetListData(props,path) {
    switch (props) {
        case 'AllUsers':
            return function action(dispatch) {
                dispatch(Users.AllUsers(props))
            }

        case 'AllMainProductCodes':
            return function action(dispatch) {
                dispatch(Param.AllMainProductCodes(props))
            }
          
        case 'AllTimereg':
            return function action(dispatch) {
                dispatch(Timereg.GetAllTimeRegistration(props))
            }


        case 'AllContacts':
            return function action(dispatch) {
                dispatch(Contacts.AllContacts(props))
            }

        case 'AllSecPar':
            return function action(dispatch) {
                dispatch(Param.AllSecParam(props))
            }

        case 'AllMunten':
            return function action(dispatch) {
                dispatch(Param.AllMunten(props))
            }

        case 'AllLanden':
            return function action(dispatch) {
                dispatch(Landen.AllLanden(props))
            }

        case 'AllTemplates':
            return function action(dispatch) {
                dispatch(Templates.AllTemplates(props))
            }

        case 'AllTaalcodes':
            return function action(dispatch) {
                dispatch(Landen.AllTaalcodes(props))
            }

        case 'AllFactZendwijzen':
            return function action(dispatch) {
                dispatch(Param.AllFactZendwijzen(props))
            }

        case 'AllBetalingsvoorwaarden':
            return function action(dispatch) {
                dispatch(Param.AllBetalingsvoorwaarden(props))
            }

        case 'AllBtwRegimes':
            return function action(dispatch) {
                dispatch(Param.AllBtwRegimes(props))
            }

        case 'AllPostcodes':
            return function action(dispatch) {
                dispatch(Param.AllPostcodes(props))
            }

        case 'AllRekeningen':
            return function action(dispatch) {
                dispatch(Param.AllRekeningen(props))
            }


        case 'AllJobDefMain':
            return function action(dispatch) {
                dispatch(Jobs.AllJobDefMain(props))
            }

        case 'AllAansprekingen':
            return function action(dispatch) {
                dispatch(Param.AllAansprekingen(props))
            }

        case 'AllRegTar':
            return function action(dispatch) {
                dispatch(Registrations.AllRegTar(props))
            }

        case 'AllRegistratie':
            return function action(dispatch) {
                dispatch(Registrations.AllRegistratie(props,path))
            }

        case 'AllJobs':
            return function action(dispatch) {
                dispatch(Jobs.GetAllJobs(props,path))
            }

        case 'AllRegOms':
            return function action(dispatch) {
                dispatch(Registrations.AllRegOms(props))
            }

        case 'AllAccounts':
            return function action(dispatch) {
                dispatch(Accounts.AllAccounts(props))
            }

        case 'AllFacturen':
            return function action(dispatch) {
                dispatch(Accounts.GetAllFacturen(props))
            }

        case 'AllKantoren':
            return function action(dispatch) {
                dispatch(Kantoren.AllKantoren(props))
            }

        case 'AllKantContacts':
            return function action(dispatch) {
                dispatch(Kantoren.AllKantContacts(props))
            }

        case 'AllSuppliers':
            return function action(dispatch) {
                dispatch(Suppliers.AllSuppliers(props))
            }

        default:
            return {
                type: ZET_MAINLIST_SUCCESS,
                payload: null
            }
    }
}
