import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Editor, getTinymce } from '@tinymce/tinymce-react';
import Print from '@material-ui/icons/Print';
import Refresh from '@material-ui/icons/Refresh';

var tinymce = null;
var titleid = null;


class PopDetailCor extends React.Component {
    state = {
        data: this.props.data,
        filename:'',
        save:false,
        StatFileUpload: 'ok',
        nivo:0,
        refid: GenFunc.MakeId(),
        editorState: '',
        velden:null,
    }

    constructor(props, context) {
        titleid = GenFunc.MakeId()
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.SaveCheck = this.SaveCheck.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);        
        this.CreatePdf = this.CreatePdf.bind(this);
        this.setmenuitems = this.setmenuitems.bind(this);
        this.PrintTiny = this.PrintTiny.bind(this);
        this.RefreshEdit = this.RefreshEdit.bind(this);
    }

    async RefreshEdit(){
      // Bewaren gegevens

      let data = this.state.data;
      let terdata = null;
      if (data.description.length > 3 )
      {
        terdata = await SqlFunction.DoSql('ChgCor',data);            
        if (terdata.id)
        {
            this.setState({editorState:terdata.temp_text, data:terdata })
        }
      }
    }

    async PrintTiny() {
        if (tinymce)
        {
            await document.getElementById(titleid).focus() 
            await document.getElementById(titleid).click() 
            tinymce.execCommand('mcePrint');
        }
    }

    async componentDidMount(){
        let retdata = await this.setmenuitems();
        this.setState({data: this.props.data,editorState:this.props.data.temp_text, velden:retdata})
    }

    async setmenuitems() {
        var items = [];
        var item
        let retdata = await SqlFunction.DoSql('AllTemplFields', {});

        if (retdata && retdata.data) 
        {
          var i = 0;
          var len = retdata.data.length;
          for (; i < len; i++) 
          {
            item = retdata.data[i];
            item.type = 'menuitem';
            item.text = item.description;
            item.value = item.code;
            let itemCopy = Object.assign({}, item);                
            items.push(itemCopy);
          }
        }
        return items;
    }

    onEditorStateChange(content, editor) {
      let data = this.state.data
      data.temp_text = content 
      this.setState({editorState:content});
    }
    async CreatePdf() {
      let data = this.state.data;      
      if (data.id !== 0)
      {
       
        let terdata = await SqlFunction.DoSql('ChgCor',data);
        if (terdata)
        {
          if (terdata.id)
          {
              this.setState({editorState:terdata.temp_text })
          }
        }
        document.getElementById('TEMPPDFUPL001COR').submit();
      }
    }


    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens

      let data = this.state.data;
      let terdata = null;
      if (data.description.length > 3 )
      {
        terdata = await SqlFunction.DoSql('ChgCor',data);            
        if (this.props.close) 
        {
          this.props.close(terdata)
        }
      }
    }

    SaveCheck(data) 
    {
      let chsave = false;
      if (data.description.length < 4 )
      {
        chsave = true;
      }

      return chsave
    }


    async ValueCheck(that,waarde,type,colId,verplicht) 
    {
      let terug = false;
      let data = this.state.data;
      switch (colId) 
      {
        case 'temp_txt':
        case 'temp_id':
          if (waarde.id && waarde.id.length > 3)
          {

            data.template_txt = waarde.value;
            data.temp_id = waarde.id;
            if (that.overzdata)
            {
              if (that.overzdata.id)
              {
                  this.setState({editorState:that.overzdata.temp_text })
              }
            }
            if (data.description.length < 3)
            {
              data.description = waarde.value;
            }

          }
          break;

        default:    
          data[colId] = waarde;
          if (verplicht === '1' || verplicht === true)
          {
            if(waarde.length < 1) 
            {
              terug = true;            
            }
          }
          break;
      } 
      that.setState({error:terug})
      let wsave = await this.SaveCheck(data);
      this.setState({data:data,save:wsave})
    }       

    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var tableheight = Math.floor(window.innerHeight * .65);
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : '10px';
        let that =this;
        let vld_document = (<form id='TEMPPDFUPL001COR' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='CreatePdfCor' id="function" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.state.data && this.state.data.id} id="id" name="id"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid"/>
        </form>);  

        return (
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography id={titleid} style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <PerfectScrollbar className="sidebar-nav">
                  <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                    <Grid container spacing={1}>
                      <Grid item sm={6} md={6} lg={6}>
                        <EditString className="card-title m-t-10"
                          value={this.state.data.description}
                          labelText="Omschrijving (titel)"
                          coltype = 'STR'                        
                          style={{width:'100%'}}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'description',true)}
                          setError={(params,value,type) => this.ValueCheck(params,value,type,'description',true)}
                        />
                      </Grid>
                      <Grid item sm={6} md={6} lg={6} style={{marginTop:'27px'}} >
                        <IconButton aria-label="close" style={{padding:'0px', float:'left'}} onClick={() => this.RefreshEdit()}>
                          <Refresh />
                        </IconButton>                
                        <IconButton aria-label="close" style={{padding:'0px', float:'left'}}  onClick={() => this.PrintTiny()}>
                          <Print />
                        </IconButton>                
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>                      
                      <Grid item sm={12} md={12} lg={12}>
                        <EditAutoSelect className="card-title m-t-10"
                          value={this.state.data.template_txt}
                          labelText="Template"
                          lijst='AllTemplates'
                          colId="template_txt"
                          selectcel='temp_id'
                          id={this.state.data.temp_id}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,"temp_id",true)}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                     {this.state.velden &&
                      <Grid item xs={12} style={{background: 'white',height: '73vh', overflow: 'auto',paddingLeft: '10px',paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px'}}>
                        <Editor
                          
                          onEditorChange={this.onEditorStateChange}      
                          init={{
                            height:tableheight,
                            language: 'nl',
                            plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime table paste code'
                          ],
                          toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent |  image | removeformat | customInsertButton | myvelden',
                          init_instance_callback: function (editor) {
                            editor.on('BeforeExecCommand', function (e) {
                              if (e.command === 'mcePrint') {
                                document.getElementById(titleid).focus() 
                                editor.selection.select(editor.dom.select('div')[0]);
                                editor.selection.setCursorLocation();

                              }
                            });
                          },                        
                      
                          images_upload_handler: function (blobInfo, success, failure) {
                            var fileName = '';
                            if( typeof(blobInfo.blob().name) !== undefined )
                            {
                                fileName = blobInfo.blob().name;
                            }
                            else
                            {   fileName = blobInfo.filename();}
                            if (fileName.length > 2)
                            {
                                const formData = new FormData();
                                formData.append('file', blobInfo.blob(), fileName);
                                SqlFunction.DoSqlBody('saveFileTemp', formData);
                                let datalink = " ../server/files/templates/"+fileName
                                success(datalink)
                            }
                          },
                          setup: function (editor) {
                            tinymce = editor
                            editor.ui.registry.addAutocompleter('specialchars', {
                                ch: '{',
                                minChars: 1,
                                columns: 1,
                                fetch: function (pattern) {

                                  var matchedChars = that.state.velden.filter(function (char) {
                                    return char.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
                                  });

                                  var tinymce = getTinymce()                                

                                  return new tinymce.util.Promise(function (resolve) {
                                    var results = matchedChars.map(function (char) {
                                      return {
                                        value: char.value,
                                        text: char.text,
                                      }
                                    });
                                    resolve(results);
                                  });
                                },
                                onAction: function (autocompleteApi, rng, value) {
                                  editor.selection.setRng(rng);
                                  editor.insertContent(value);
                                  autocompleteApi.hide();
                                }
                            });

                            editor.ui.registry.addMenuButton('myvelden', {
                              text: 'Velden',
                              fetch: function (callback) {
                                  var items = [];
                                  var item
                                  var i = 0;
                                  var len = that.state.velden.length;
                                  for (; i < len; i++) 
                                  {
                                    item = that.state.velden[i];
                                    item.type = 'menuitem';
                                    let code = item.code
                                    item.onAction =  function () {
                                      editor.insertContent(code);
                                    }               
                                    let itemCopy = Object.assign({}, item);                
                                    items.push(itemCopy);
                                  };
                                callback(items);
                              }
                            });
                          },
                          }}
                          value={this.state.editorState}
                        />

                      </Grid>
                      }
                    </Grid>
                    {vld_document} 
                  </DialogContent>
                </PerfectScrollbar>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  <Button color="primary" disabled={this.state.save} onClick={() => this.handleSave()}>
                    Bewaren
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopDetailCor.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopDetailCor)));

