import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import Validation from "../../functions/validation.js";
import {AllSuppliers} from "../../redux/settings/supplierActions";
import {AllPostcodes} from "../../redux/settings/paramActions";
import PerfectScrollbar from 'react-perfect-scrollbar';
import PopAddZipCode from "../zipcode/PopAddZipcode.jsx";



class PopAddSupplier extends React.Component {
    state = {
        data: { id: 0, name: '', e_mail: '', main_phone: '', adress: '', zipcode: "", zipcode_txt: "", country: "", country_txt: "", info: '', vatnumber:'' },
        waarde:this.props.waarde,
        toevobject:this.props.toevobject,
        save:true,
        StatFileUpload: 'ok',
        PopUpScreen:null,
        refid: GenFunc.MakeId(),
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);
        this.ClosePopZipcode = this.ClosePopZipcode.bind(this);

    }
    async componentDidMount(){
      if (!this.props.supplier.AllData) {
        await this.props.dispatch(AllSuppliers());
      }
      let hdata = this.state.data;
      hdata.name = this.props.waarde;
      this.CheckSave(hdata)      
      this.setState({data:hdata})
    }

    async ClosePopZipcode(retdata) {
      let data = this.state.data;
      this.setState({ PopUpScreen: null });
      if (retdata) 
      {
          data.zipcode_txt = retdata.zipcode + ' - '+retdata.place;
          data.zipcode = retdata.id;
          data.country_txt = retdata.country_txt;
          data.country = retdata.country;
          this.setState({data:data})
      }
      await this.props.dispatch(AllPostcodes());
    }


    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async ErrorCheck(that, waarde, type, colId) {
      let data = this.state.data;
      let terug = Validation.ValidCheck('Leverancier', waarde, colId, that, this.props.supplier.AllData.data, data);

      that.setState({ error: terug })
    }      


    async handleSave(){
      // Bewaren gegevens
      let retdata = await SqlFunction.DoSql('ChgSupplier', this.state.data);
      if (retdata) 
      {
        await this.props.dispatch(AllSuppliers())
        if (this.props.toevobject) {
          this.props.toevobject.loaddata()
          this.props.toevobject.SetWaarde(retdata.id,retdata.zoektxt)
          this.props.toevobject.setState({ error: false })

        }
        if (this.props.hoofdvorm) 
        {
          let data = this.props.hoofdvorm.state.data;
          data.supplier_id =  retdata.id
          data.supplier_txt =  retdata.zoektxt;
          this.props.hoofdvorm.setState({data:data});

        }
      }

      if (this.props.close) 
      {
        this.props.close()
      }
    }
    CheckSave(data) {
        let terug = true
        terug = Validation.ValidCheck('Leverancier', data.name, 'name', this, this.props.supplier.AllData.data, data);
        if (terug === false) {
            terug = Validation.ValidCheck('Leverancier', data.e_mail, 'e-mail', this, this.props.supplier.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck('Leverancier', data.vatnumber, 'vatnumber', this, this.props.supplier.AllData.data, data);
        }
        this.setState({ save:!terug})
    }

    async ValueCheck(that, waarde, type, colId) {

        let data = this.state.data;
        let terug = false;
        switch (colId) {
            case 'country_txt':
                data.country_txt = waarde.value;
                data.country = waarde.id;
                terug = Validation.ValidCheck('Leverancier', waarde.value, colId, that, this.props.supplier.AllData.data, data);
                break;

            case 'zipcode_txt':
              if (waarde.id === 0)
              {
                this.setState({PopUpScreen:(<PopAddZipCode open={true}  toevobject={that}  waarde={waarde.value} close={this.ClosePopZipcode} title='Toevoegen postode aan lijst' />)});
              }

                data.zipcode_txt = waarde.value;
                data.zipcode = waarde.id;
                terug = Validation.ValidCheck('Leverancier', waarde.value, colId, that, this.props.supplier.AllData.data, data);
                if (that.overzdata) {
                    data.country_txt = that.overzdata.country_txt;
                    data.country = that.overzdata.country;
                }
                break;

            case 'vatnumber':
                data[colId] = waarde.toUpperCase();
                terug = Validation.ValidCheck('Leverancier', waarde.toUpperCase(), colId, that, this.props.supplier.AllData.data, data);

                break;


            default:
                data[colId] = waarde;
                terug = Validation.ValidCheck('Leverancier', waarde, colId, that, this.props.supplier.AllData.data, data);
                break;
        }
        that.setState({ error: terug })
        await this.CheckSave(data);
        this.setState({data: data, checkval: false })
    }   


    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {fullScreen,classes, open} = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal80
                }}

                fullScreen={fullScreen}
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible",zIndex:'1021',width:'100%'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                maxWidth='md'
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',paddingLeft:'20px'}}  className={classes.blockContent}>
                  <PerfectScrollbar styleclassName="sidebar-nav">
                    <Grid style={{marginLeft:'10px', marginRight: '10px',width: '99%'}} container spacing={1}>
                      <Grid item sm={12} md={12} lg={12}>
                          <EditString className="card-title m-t-10"
                              value={this.state.data.name}
                              coltype='STR'
                              labelText="Naam"
                              ValueCheck={(params, value, type) => this.ValueCheck(params, value,type, 'name')}
                              setError={(params,value,type) => this.ErrorCheck(params,value,type,'name')}
                          />
                      </Grid>
                      <Grid item sm={12} md={12} lg={12}>
                          <EditString className="card-title m-t-10"
                            value={this.state.data.vatnumber}
                            coltype = 'STR'                        
                            labelText="BTW nummer"
                            format = {[/[a-z, A-Z]/, /[a-z, A-Z]/,'-',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/, /[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/, /[a-z, A-Z, 0-9]/, /[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/]}
                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'vatnumber')}
                            setError={(params,value,type) => this.ErrorCheck(params,value,type,'vatnumber')}

                          />
                      </Grid>

                      <Grid item sm={12} md={12} lg={12}>
                          <EditString className="card-title m-t-10"
                              value={this.state.data.e_mail}
                              coltype='STR'
                              labelText="E-mail"
                              ValueCheck={(params, value, type) => this.ValueCheck(params, value,type, 'e_mail')}
                          />
                      </Grid>
                      <Grid item sm={12} md={12} lg={12}>
                          <EditString className="card-title m-t-10"
                              value={this.state.data.main_phone}
                              coltype='STR'
                              labelText="Telefoonnummer"
                              ValueCheck={(params, value, type) => this.ValueCheck(params, value,type, 'main_phone')}
                          />
                      </Grid>
                      <Grid item sm={12} md={12} lg={12}>
                          <EditString className="card-title m-t-10"
                              value={this.state.data.adress}
                              coltype='STR'
                              labelText="Adres"
                              ValueCheck={(params, value, type) => this.ValueCheck(params, value,type, 'adress')}
                          />
                      </Grid>
                      <div style={{ width: '100%', textAlign: 'center' }}>
                          <EditAutoSelect className="card-title m-t-10"
                              checkval={this.state.checkval}
                              value={this.state.data.zipcode_txt}
                              GoogleZoek={true}
                              labelText="Gemeente"
                              lijst='AllPostcodes'
                              colId='zipcode_txt'
                              selectcel='zipcode'
                              id={this.state.data.zipcode}
                              ValueCheck={(params, value, type) => this.ValueCheck(params, value,type, 'zipcode_txt')}
                          />
                      </div>
                      <div style={{ width: '100%', textAlign: 'center' }}>
                          <EditAutoSelect className="card-title m-t-10"
                              checkval={this.state.checkval}
                              value={this.state.data.country_txt}
                              GoogleZoek={true}
                              labelText="Land"
                              lijst='AllLanden'
                              colId='country_txt'
                              selectcel='country'
                              id={this.state.data.country}
                              ValueCheck={(params, value, type) => this.ValueCheck(params, value,type, 'country_txt')}
                          />
                      </div>
                      <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                      <Grid container spacing={1}>
                          <Grid item sm={12} md={12} lg={12}>
                              <small className="text-muted p-t-30 db">Informatie</small>
                          </Grid>

                          <Grid item sm={12} md={12} lg={12}>
                              <EditString className="card-title m-t-10"
                                  value={this.state.data.info}
                                  coltype='STR'
                                  multiline={true}
                                  ValueCheck={(params, value, type) => this.ValueCheck(params, value,type, 'info')}
                              />
                          </Grid>
                      </Grid>
                      <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                    </Grid>
                  </PerfectScrollbar>
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  {this.state.save &&
                    <Button color="primary"  onClick={() => this.handleSave()}>
                      Opslaan
                    </Button>
                  }
                </DialogActions>
                {this.state.PopUpScreen}
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
  ...state
});


export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopAddSupplier)));

