import React from 'react';
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'

import TableGrid from "../../components/TableGrid/TableGrid.jsx";

import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';

import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import EditSelect from "../../components/editselect.jsx";

import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";

import { GetFactuuropbouw, AllLanden } from '../../redux/settings/landActions.js';
import { AllJobsToInvoiceAccount, JobDefMainAllCodes, JobDefListCodes } from '../../redux/settings/jobsActions.js';
import Delete from "@material-ui/icons/Delete";
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';

var gridapi = null;
var rowpinnend = null;
var ereloontotaalexclbtw = 0;
var totaalbtw = 0;
var ereloontotaalinclbtw = 0;

class PopViewProforma extends React.Component {
    state = {
        data: null,
        kol:null,
        maindata:this.props.data,
        seljobs:this.props.seljobs,
        save:true,
        tabvalue:'1',
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        taal:'nederlands',
        extralinesdata: [],
        deletedata:[],
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getDirreg = this.getDirreg.bind(this);
        this.getAccijnzen = this.getAccijnzen.bind(this);
        this.getVatrefund = this.getVatrefund.bind(this);
        this.getExtraLines = this.getExtraLines.bind(this);
        this.sortInvoiceJobs = this.sortInvoiceJobs.bind(this);
        this.ValueCheckDetail = this.ValueCheckDetail.bind(this);
        this.ValueCheckExtra = this.ValueCheckExtra.bind(this);
        this.addExtraLine = this.addExtraLine.bind(this);
        this.removeExtraLine = this.removeExtraLine.bind(this);


    }

    async componentDidMount(){
        if (this.props.data)
        {
          await this.props.dispatch(GetFactuuropbouw(this.props.data.taalcode));

          let extralinesdata = [];
          let invoicejoblist = [];
          let maindata = this.props.data;
          if(maindata.type === "Extra jobs"){
            for (let i = 0; i < maindata.factlines.length; i++){
                if(maindata.factlines[i].omschrijving !== ""){
                    let row = {
                        "omschrijving": maindata.factlines[i].omschrijving,
                        "tarief": maindata.factlines[i].ereloon
                    }
                    extralinesdata.push(row);
                }
            }
          } else {
            invoicejoblist = [];
            for (let i = 0; i < maindata.factlines.length; i++) {
                let row = maindata.factlines[i];
                if (maindata.factlines[i].omschrijving === "") {
                    let ereloon = maindata.factlines[i].ereloon;
                    let saldotebet = maindata.factlines[i].aangiftebtw;     
                    let teruggave = maindata.factlines[i].teruggave;
                    let teruggave_euro = maindata.factlines[i].teruggave_euro;

                    let koers = maindata.factlines[i].koers;                
                    if (ereloon.constructor === String)
                    {
                      ereloon = Number(ereloon);
                    }
                    if (saldotebet.constructor === String)
                    {
                      saldotebet = Number(saldotebet);
                    }


                    if (teruggave.constructor === String)
                    {
                      teruggave = Number(teruggave);
                    }

                    if (teruggave_euro.constructor === String)
                    {
                      teruggave_euro = Number(teruggave_euro);
                    }


                    if (koers.constructor === String)
                    {
                      koers = Number(koers);
                    }
                    row["tarief"] = ereloon;
                    row["vat_facttot"] =  ereloon;
                    row["country_txt"] =  maindata.factlines[i].land;
                    row["regnummer"] =  maindata.factlines[i].regnr;
                    row["jobtypecode"] =  maindata.factlines[i].subtype;
                    row["saldotebet"] =  saldotebet;
                    row["vat_basisfact"] =  teruggave;
                    row["vat_basisfact_val"] =  teruggave_euro;

                    row["vat_koers"] =  koers;
                    row["munt"] =  maindata.factlines[i].munteenheid;
                    invoicejoblist.push(row);
                } else {
                    row["omschrijving"] = maindata.factlines[i].omschrijving;
                    row["tarief"] = maindata.factlines[i].ereloon;
                    extralinesdata.push(row);
                }
            }
          }

          let factdate = (maindata.factdate).split('-').reverse().join('-');
          let factenddate = (maindata.factenddate).split('-').reverse().join('-');
          let btwperc = maindata.btw;
          let klant = maindata.klant;
          let taaldata = await SqlFunction.DoSql('GetTaalcode',{id:maindata.taalcode});
          let taal = 'nederlands';
          if (taaldata && taaldata.data && taaldata.data.length > 0)
          {
             taal = taaldata.data[0].omschrijving.toLowerCase();
          }
          let factklant = {
            name: maindata.factklant_name,
            adress: maindata.factklant_address,
            zipcode: maindata.factklant_zipcode,
            country: maindata.factklant_country,
            vatnumber: maindata.factklant_vatnumber,
            number: maindata.factklant_number
        };

        await this.props.dispatch(JobDefListCodes(taal));
        let facttype = this.props.jobs.JobDefListCodesData.data;
        let facttyprow = {code:"EXTRAL", description:'Extra Lijn',subject:''};
        facttype.EXTRAL = facttyprow;
        this.setState({ data:this.props.data, seljobs:this.props.seljobs, maindata:this.props.data, openfactuur: true, makefactuur: false, factsave: true, overview: false, factuurnr: maindata.factnr, factuurdate: factdate, factuurenddate: factenddate, facttype: maindata.type,
            dbfactuur: true, btwpercentage: btwperc, klant: klant, taal: taal, factuurklant: factklant, factuurtype: facttype, invoiceId: maindata.id, invoicedescription: maindata.vermelding, extralinesdata:extralinesdata, invoicejoblist:invoicejoblist })
        }
    }

    sortInvoiceJobs(jobs){
        let sortedArray = [];
        let countryFilter = "country_txt";

        for(let i = 0; i < jobs.length; i++){
            if(sortedArray && sortedArray.filter(obj => obj.code === this.state.factuurtype[jobs[i].jobtypecode]['code']).length > 0){
                let codeIndex = sortedArray.map(function(obj) { return obj.code }).indexOf(this.state.factuurtype[jobs[i].jobtypecode]['code']);

                if(sortedArray[codeIndex].countries.filter(country => country.countryName === jobs[i][countryFilter]).length > 0){
                    let countryIndex = sortedArray[codeIndex].countries.map(function(country) { return country.countryName }).indexOf(jobs[i][countryFilter]);
                    sortedArray[codeIndex].countries[countryIndex].jobs.push(jobs[i]);
                } else {
                    let jobsArray = [];
                    jobsArray.push(jobs[i])
                    let country = {
                        countryName: jobs[i][countryFilter],
                        jobs: jobsArray
                    };
                    sortedArray[codeIndex].countries.push(country);
                }
            } else {
                let jobsArray = [];
                jobsArray.push(jobs[i])
                let countriesArray = [];
                countriesArray.push({
                    countryName: jobs[i][countryFilter],
                    jobs: jobsArray
                })
                sortedArray.push({
                    code: this.state.factuurtype[jobs[i].jobtypecode]['code'],
                    countries: countriesArray
                });
            }
        }

        let sortedInvoiceJobList = [];
        for(let i = 0; i < sortedArray.length; i++){
            for(let j = 0; j < sortedArray[i].countries.length; j++){
                let sortedOnPeriod = sortedArray[i].countries[j].jobs.sort(function(a, b) {
                    let aSplitted = a.periode.split("/");
                    let bSplitted = b.periode.split("/");
                    let aMonth = Number(aSplitted[0]);
                    let bMonth = Number(bSplitted[0]);
                    let aYear = Number(aSplitted[1]);
                    let bYear = Number(bSplitted[1]);
                    
                    if(aYear === bYear){
                        return aMonth - bMonth;
                    } else {
                        return aYear - bYear;
                    }
                })
                sortedInvoiceJobList = sortedInvoiceJobList.concat(sortedOnPeriod);
            }
        }
        return sortedInvoiceJobList;
    }


    ValueCheckDetail(that,waarde,type,colId,index,status) {
      let detail_data_list = this.state.invoicejoblist;
      let detail_data = detail_data_list[index];

      switch(colId){
        case 'saldotebet':
          detail_data[colId] = waarde;
          detail_data['aangiftebtw'] = waarde;

          break;

        case 'regnummer':
          detail_data[colId] = waarde;
          detail_data['regnr'] = waarde;

          break;

        case 'tarief':
          detail_data[colId] = waarde;
          detail_data['ereloon'] = waarde;
          detail_data['vat_facttot'] = waarde;
          break;

        case 'vat_basisfact':
          detail_data[colId] = waarde;
          detail_data['teruggave'] = waarde;
          if (status === true)
          {
            detail_data['vat_basisfact_val'] = waarde * detail_data['vat_koers'];
            detail_data['teruggave_euro'] = detail_data['vat_basisfact_val'];
          }
          break;


        case 'vat_basisfact_val':
          detail_data[colId] = waarde;
          detail_data['teruggave_euro'] = waarde;
          if (status === true)
          {
            detail_data['vat_basisfact'] = waarde / detail_data['vat_koers'];
            detail_data['teruggave'] = detail_data['vat_basisfact'];
          }
          break;

        case 'vat_koers':
          detail_data[colId] = waarde;
          if (status === true)
          {
            detail_data['vat_basisfact'] = detail_data['vat_basisfact_val'] /waarde;
            detail_data['teruggave'] = detail_data['vat_basisfact'];
          }
          break;

        default:
          detail_data[colId] = waarde;
          break;
      }
      detail_data['upd'] = true;
      detail_data_list[index] = detail_data;
      this.setState({invoicejoblist:detail_data_list})
      return;
    }       

    ValueCheckExtra(that,waarde,type,colId,index) {
      let detail_data_list = this.state.extralinesdata;
      let detail_data = detail_data_list[index];

      switch(colId){
        case 'saldotebet':
          detail_data[colId] = waarde;
          detail_data['aangiftebtw'] = waarde;

          break;

        case 'tarief':
          detail_data[colId] = waarde;
          detail_data['ereloon'] = waarde;
          detail_data['vat_facttot'] = waarde;
          break;

        default:
          detail_data[colId] = waarde;
          break;
      }
      detail_data['upd'] = true;
      detail_data_list[index] = detail_data;
      this.setState({extralinesdata:detail_data_list})
      return;
    }           


    getDirreg(){
        let dirreg = [];
        let regs = [];
        let regstype = [];
        let regstotaal = 0;
        ereloontotaalexclbtw = 0;
        let drjobs = this.sortInvoiceJobs(this.state.invoicejoblist);
        this.state.invoicejoblist = drjobs        
        for(let i = 0; i < drjobs.length; i++){
            if(!regstype.includes(this.state.factuurtype[drjobs[i].jobtypecode]['code'])){
                regstype[drjobs[i].jobtypecode] = this.state.factuurtype[drjobs[i].jobtypecode]['code'];
            }
        }
        
        for(let i = 0; i < Object.keys(regstype).length; i++){
            let type = [];
            let layout = [];
            let totaal = 0;
            for(let j = 0; j < drjobs.length; j++){

                if(Object.values(regstype)[i] === this.state.factuurtype[drjobs[j].jobtypecode]['code']){
                  if (drjobs[j].subtype !== 'EXTRAL')
                  {
                    regstotaal += Number(drjobs[j].tarief);
                    totaal += Number(drjobs[j].tarief);
                    layout.push(
                        <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                            <div style={{ width: "20%", textAlign: "center" }}><label style={{ marginTop: "20px"}}>{drjobs[j].countryobj ? drjobs[j].countryobj[this.state.taal] : drjobs[j].country_txt}</label></div>

                            <div style={{ width: "20%", marginTop: "9px",textAlign: "center" }}>
                              <div style={{ marginTop: "-20px",width:'98%'}}>
                                <EditString  className="card-title m-t-10"
                                  coltype='STR'
                                  labelText={""}                                                  
                                  value={drjobs[j].regnummer}
                                  ValueCheck={(params,value,type) => this.ValueCheckDetail(params,value,type,'regnummer',j)}
                                />
                              </div>
                            </div>
                            <div style={{ marginTop: "9px", width: "20%", textAlign: "center" }}>
                              <div style={{ marginTop: "-20px",width:'98%'}}>
                                <EditString  className="card-title m-t-10"
                                  value={drjobs[j].periode}
                                  coltype='STR'
                                  labelText={""}                                                  
                                  ValueCheck={(params,value,type) => this.ValueCheckDetail(params,value,type,'periode',j)}
                                />
                              </div>
                            </div>
                            <div style={{ width: "20%", textAlign: "center" }}>
                              <div style={{ marginTop: "-20px"}}>
                                <EditString  className="card-title m-t-10"
                                  value={drjobs[j].saldotebet}
                                  labelText={""}                                                  
                                  coltype = 'NUM'                        
                                  decimal={2}
                                  ValueCheck={(params,value,type) => this.ValueCheckDetail(params,value,type,'saldotebet',j)}
                                />
                              </div>
                            </div>
                            <div style={{ width: "20%", textAlign: "center" }}>
                              <div style={{ marginTop: "-20px"}}>
                                <EditString style={{ marginTop: "-20px"}} className="card-title m-t-10"
                                  value={drjobs[j].tarief}
                                  labelText={""}                                                  
                                  coltype = 'NUM'                        
                                  decimal={2}
                                  ValueCheck={(params,value,type) => this.ValueCheckDetail(params,value,type,'tarief',j)}
                                />
                              </div>
                            </div>
                        </div>
                    );
                  }
                }
            }
            type['layout'] = layout;
            type['ereloon'] = totaal;
            type['code'] = Object.keys(regstype)[i];
            regs[Object.values(regstype)[i]] = type;
        }

        ereloontotaalexclbtw = regstotaal;
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;
        for(let i = 0; i < Object.keys(regs).length; i++){
          if (Object.values(regs)[i].code.length > 1)
          {
            dirreg.push(
                <div name={"dr" + i} key={"dr" + i}>
                    <div name={"regs"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{Object.keys(regs)[i] !== null ? Object.keys(regs)[i] : ''}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((Object.values(regs)[i].ereloon) * 100) / 100)}</label>
                            </div>
                        </div>
                        <div name={"dirregs"} style={{ width: "90%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.land}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.regnr}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{Object.values(regs)[i].code !== "AANVR" ? this.props.land.FactuuropbouwData.data.periode : ""}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{Object.values(regs)[i].code !== "AANVR" ? this.props.land.FactuuropbouwData.data.aangiftebtw : ""}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.ereloon}</label></div>
                            </div>
                            {Object.values(regs)[i].layout}
                        </div>
                    </div>
                </div>
            );
          }
        }

        return dirreg;
    }



    getVatrefund() {
        let vatrefund = [];

        let vats = [];
        let vatstype = [];
        let vatstotaal = 0;
        ereloontotaalexclbtw = 0;

        let vatjobs = this.sortInvoiceJobs(this.state.invoicejoblist);
        this.state.invoicejoblist = vatjobs        
        
        for(let i = 0; i < vatjobs.length; i++){
            if(!vatstype.includes(this.state.factuurtype[vatjobs[i].jobtypecode]['code'])){
                vatstype.push(this.state.factuurtype[vatjobs[i].jobtypecode]['code']);
            }
        }

        for(let i = 0; i < vatstype.length; i++){
            let type = [];
            let layout = [];
            let totaal = 0;
            for(let j = 0; j < vatjobs.length; j++){
                if (vatjobs[j].subtype !== 'EXTRAL')
                {              
                  if(vatstype[i] === this.state.factuurtype[vatjobs[j].jobtypecode]['code']){
                      vatstotaal +=  (vatjobs[j].vat_facttot.constructor === String ) ? Number(vatjobs[j].vat_facttot) : vatjobs[j].vat_facttot;
                      totaal += (vatjobs[j].vat_facttot.constructor === String ) ? Number(vatjobs[j].vat_facttot) : vatjobs[j].vat_facttot;
                      layout.push(
                          <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                              <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ marginTop: "20px"}}>{vatjobs[j].countryobj ? vatjobs[j].countryobj[this.state.taal] : vatjobs[j].country_txt}</label></div>
                              <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ marginTop: "20px"}}>{vatjobs[j].periode}</label></div>


                              <div style={{ width: "16.5%", textAlign: "center" }}>
                                <div style={{ marginTop: "-20px"}}>
                                  <EditString  className="card-title m-t-10"
                                    value={vatjobs[j].vat_basisfact_val}
                                    coltype = 'NUM'                        
                                    decimal={2}
                                    checkval={false}
                                    prefix={vatjobs[j].munt ? vatjobs[j].munt : vatjobs[j].countryobj.munt_txt}
                                    ValueCheck={(params,value,type,status) => this.ValueCheckDetail(params,value,type,'vat_basisfact_val',j,status)}
                                  />
                                </div>
                              </div>
                              <div style={{ width: "16.5%", textAlign: "center" }}>
                                <div style={{ marginTop: "-20px"}}>
                                  <EditString  className="card-title m-t-10"
                                    value={vatjobs[j].vat_koers}
                                    coltype = 'NUM'                        
                                    checkval={false}
                                    decimal={2}
                                    ValueCheck={(params,value,type,status) => this.ValueCheckDetail(params,value,type,'vat_koers',j,status)}
                                  />
                                </div>
                              </div>
                              <div style={{ width: "16.5%", textAlign: "center" }}>
                                <div style={{ marginTop: "-20px"}}>
                                  <EditString  className="card-title m-t-10"
                                    value={vatjobs[j].vat_basisfact}
                                    coltype = 'NUM'                        
                                    checkval={false}
                                    decimal={2}
                                    ValueCheck={(params,value,type,status) => this.ValueCheckDetail(params,value,type,'vat_basisfact',j,status)}
                                  />
                                </div>
                              </div>

                              <div style={{ width: "16.5%", textAlign: "center" }}>
                                <div style={{ marginTop: "-20px"}}>
                                  <EditString  className="card-title m-t-10"
                                    value={vatjobs[j].vat_facttot}
                                    coltype = 'NUM'                        
                                    decimal={2}
                                    checkval={false}
                                    prefix='EUR'
                                    ValueCheck={(params,value,type,status) => this.ValueCheckDetail(params,value,type,'vat_facttot',j,status)}
                                  />
                                </div>
                              </div>
                          </div>
                      );
                  }
                }
                
            }
            type['layout'] = layout;
            type['ereloon'] = totaal;
            vats[vatstype[i]] = type;
        }

        ereloontotaalexclbtw = vatstotaal;
        // check if belgie
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;

        for(let i = 0; i < Object.keys(vats).length; i++){
            vatrefund.push(
                <div name={"vat" + i} key={"vat" + i}>
                    <div name={"vats"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{Object.keys(vats)[i]}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((Object.values(vats)[i].ereloon) * 100) / 100)}</label>
                            </div>
                        </div>
                        <div style={{ width: "80%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.land}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.periode}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.teruggave}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.koers}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{(this.props.land.FactuuropbouwData.data.bedrageuro).replace("euro", "€")}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.ereloon}</label></div>
                            </div>
                            {Object.values(vats)[i].layout}
                        </div>
                    </div>
                </div>
            );
        }

        return vatrefund;
    }
    getAccijnzen() {
        let accijnzen = [];

        return accijnzen;
    }
    getExtraLines() {
        let extralines = [];
        let extradata = [];
        let extratotaal = 0;

         for(let i = 0; i < this.state.extralinesdata.length; i++){
            if (this.state.extralinesdata[i].subtype === 'EXTRAL')
            {
              extratotaal += Number(this.state.extralinesdata[i].tarief);
              extradata.push(
                    <div style={{ display: "flex", flexDirection: "row", marginTop: "-10px" }}>
                        <div style={{ width: "80%" }}>
                            <EditString className="card-title m-t-10"
                                value={this.state.extralinesdata[i].omschrijving}
                                coltype='STR'
                                labelText={""}
                                ValueCheck={(params, value, type) => this.ValueCheckExtra(params, value, type, "omschrijving", i)}
                            />
                        </div>
                        <div style={{ width: "8%" }}></div>
                        <div style={{ width: "10%" }} onKeyDown={this.onlyNumbers}>
                            <EditString className="card-title m-t-10"
                                value={this.state.extralinesdata[i].tarief}
                                coltype='STR'
                                labelText={""}
                                ValueCheck={(params, value, type) => this.ValueCheckExtra(params, value, type, "tarief", i)}
                            />
                        </div>

                       <div style={{ width: "1%" }}></div>
                        <div style={{ width: "1%", marginTop: "30px" }}>
                          <Delete style={{ fontSize: '1.5rem', border: "none", borderRadius: "50%", color: "#495057", cursor: "pointer" }} onClick= {(event) => this.removeExtraLine(event,i)} />
                        </div>
                    </div>
                );
            }
        }

        if(this.state.facttype === "Directe Registratie" || this.state.facttype === "Accijnzen" || this.state.facttype === "VAT-Refund" || this.state.facttype === "Diversen"){
            ereloontotaalexclbtw += extratotaal;
        } else {
            ereloontotaalexclbtw = extratotaal;
        }
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;
        if (extradata && extradata.length > 0)
        {
          extralines.push(
            <div name={"extralines"} key={"allextralines"}>
              <div name={"extra"} style={{ marginTop: "10px" }}>
                  <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                      <div style={{ width: "85%" }}>
                          <label>{this.props.land.FactuuropbouwData.data.extra}</label>
                      </div>
                      <div style={{ width: "15%", textAlign: "center" }}>
                          <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(extratotaal * 100) / 100)}</label>
                      </div>
                  </div>
                  <div style={{ width: "80%", margin: "auto", display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                          <div style={{ width: "82.5%" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.description}</label></div>
                          <div style={{ width: "8%" }}></div>
                          <div style={{ width: "6.5%" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.ereloon}</label></div>
                          <div style={{ width: "2%" }}></div>
                      </div>
                      {extradata}
                  </div>
              </div>
            </div>
          );
          return extralines;
        }
        else
        {
          return null;
        }
    }
    addExtraLine(){
        let lines = this.state.extralinesdata;
        lines.push({
          aangiftebtw: "0",
          country_txt: 'EXTRAL',
          ereloon: "",
          jobid: "",
          jobtypecode: 'EXTRAL',
          koers: "1",
          land: 'EXTRAL',
          munt: "",
          munteenheid: "",
          omschrijving: "",
          periode: "",
          regnr: "",
          regnummer: "",
          saldotebet: "0",
          subtype: 'EXTRAL',
          tarief: "",
          teruggave: "0",
          teruggave_euro: "0",
          vat_basisfact: "0",
          vat_facttot: "0",
          vat_koers: "1",          
        });
        this.setState({ extralinesdata: lines })
    }

    removeExtraLine(event, index){
        let lines = this.state.extralinesdata;
        lines.splice(index, 1)
        this.setState({ extralinesdata: lines})
    }




    handleClose = () => {
      let maindata = this.state.data;
      for(let i = 0; i < this.state.invoicejoblist.length; i++)
      {
          if (this.state.invoicejoblist[i].subtype === 'EXTRAL')
          {
            this.state.invoicejoblist.splice(i,1);
          }
      }

      let newInvoiceJobList = this.state.invoicejoblist.concat(this.state.extralinesdata);


      maindata.factlines = newInvoiceJobList;
      maindata.facttotaal = ereloontotaalinclbtw;
      maindata.totaalereloon = ereloontotaalexclbtw;

        if (this.props.close) {
            this.props.close(maindata,this.state.deletedata)
        }
    };


    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var top = '50px';
        var left = '0px';
        var bottom = '10px';


        return (
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'scroll'}}  className={classes.blockContent}>
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12} lg={12}>
                      { this.state.data && 
                        <div id={"invoicepdf"}>
                            <div id={"innerHtmlToSave"}>
                                <div id={"invoicepdfdiv"}>
                                    <div style={{ marginTop: "25px", marginLeft: "20px", marginRight: "20px" }}>
                                      <div name={"Factuur_top"}>
                                          <div name={"Klantgeg"} style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                              <div style={{ width: "70%" }}></div>
                                              <div style={{ widht: "30%", display: "flex", flexDirection: "column" }}>
                                                  <label>{this.state.data.factklant_name}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.state.data.factklant_adress}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.state.data.factklant_zipcode}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.state.data.factklant_country}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.state.data.factklant_vatnumber}</label>
                                              </div>
                                          </div>
                                          <label style={{ fontSize: "11px", fontStyle: "italic", marginBottom: "-5px" }}>{"*" + this.props.land.FactuuropbouwData.data.vermelding}</label>
                                          <div name={"Factuurdatums"} style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "10px" }}>
                                              <div style={{ width: "15%", display: "flex", flexDirection: "column" }}>
                                                  <label>{this.props.land.FactuuropbouwData.data.factnr + "*"}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.klantnr + "*"}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.factdate}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.factenddate}</label>
                                              </div>
                                              <div style={{ width: "85%", display: "flex", flexDirection: "column" }}>
                                                  <label>{this.state.data.factnr}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.state.data.factklant_number}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.state.data.factdate}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.state.data.factenddate}</label>
                                              </div>
                                          </div>
                                      </div>
                                      <div name={"Factuur_center"}>
                                          <div className='div_line' style={{ marginBottom: "5px" }} />
                                          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                              <div style={{ width: "85%" }}>
                                                  <label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.description}</label>
                                              </div>
                                              <div style={{ width: "15%", textAlign: "center" }}>
                                                  <label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.totaalexcl}</label>
                                              </div>
                                          </div>
                                          <div className='div_line' style={{ marginBottom: "10px" }} />
                                          {this.state.data.klant !== this.state.data.factklant_name &&
                                              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                  <label>{this.props.land.FactuuropbouwData.data.prestaties}</label>
                                                  <div style={{ marginTop: "-10px" }}>
                                                      <label>{this.props.land.FactuuropbouwData.data.klant}</label>
                                                      <label style={{ marginLeft: "25px" }}>{this.state.data.klant}</label>
                                                  </div>
                                              </div>
                                          }
                                      </div>
                                      <div name={"Factuur_bottom"} style={{ marginTop: "15px" }}>
                                          {this.state.facttype === "Directe Registratie" &&
                                              this.getDirreg()
                                          }
                                          {this.state.facttype === "Accijnzen" &&
                                              this.getAccijnzen()
                                          }
                                          {this.state.facttype === "VAT-Refund" &&
                                              this.getVatrefund()
                                          }
                                          {this.getExtraLines()}
                                          <div style={{ width: "100%", textAlign: "center", marginTop: "20px" }}>
                                              <IconButton onClick={() => this.addExtraLine()} color="primary" component="span">
                                                  <AddCircleIcon style={{ fontSize: "30px" }} />
                                              </IconButton>
                                          </div>

                                          <div className='div_line' style={{ marginBottom: "5px", marginTop: "25px" }} />
                                          <div name={"KostenTotaal"} style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "25px" }}>
                                              <div style={{ width: "65%" }} />
                                              <div style={{ display: "flex", flexDirection: "column", width: "20%" }}>
                                                  <label>{this.props.land.FactuuropbouwData.data.totaalereloon}</label>
                                                  <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.btw + " (" + this.state.btwpercentage + "%)"}</label>
                                                  <label style={{ marginTop: "10px", fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.betalen}</label>
                                              </div>
                                              <div style={{ display: "flex", flexDirection: "column", width: "15%", textAlign: "center" }}>
                                                  <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(ereloontotaalexclbtw * 100) / 100)}</label>
                                                  <label style={{ marginTop: "-10px" }}>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(totaalbtw * 100) / 100)}</label>
                                                  <label style={{ marginTop: "10px", fontWeight: "bold" }}>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(ereloontotaalinclbtw * 100) / 100)}</label>
                                              </div>
                                          </div>
                                          <div className='div_line' style={{ marginBottom: "5px", marginTop: "25px" }} />
                                          <div name={"Vermelding"} style={{ display: "flex", flexDirection: "column", width: "100%"}}>
                                              {this.state.invoicedescription && <label>{this.state.invoicedescription}</label>}
                                          </div>
                                          {this.state.dbfactuur && <div>
                                              <form id='INVOICEPDF' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{ width: '100%' }} >
                                                  <input style={{ display: 'none' }} type="text" defaultValue='CreateInvoicePdf' id="function" name="function" />
                                                  <input style={{ display: 'none' }} type="text" defaultValue={this.state.invoiceId} id="id" name="id" />
                                                  <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid" />
                                                  <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid" />
                                              </form>
                                              <form id='INVOICEPDFBRUTAX' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{ width: '100%' }} >
                                                  <input style={{ display: 'none' }} type="text" defaultValue='CreateInvoicePdfBrutax' id="function" name="function" />
                                                  <input style={{ display: 'none' }} type="text" defaultValue={this.state.invoiceId} id="id" name="id" />
                                                  <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid" />
                                                  <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid" />
                                              </form>
                                          </div>}
                                      </div>


                                    </div>
                                </div>
                            </div>
                        </div>




                      }
                    </Grid>
                  </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
  ...state
});


PopViewProforma.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopViewProforma)));

