import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux'
import {Button} from 'reactstrap';
import PopJobAanvrNum from "../jobs/PopJobAanvrNum.jsx";
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import { resetview, ZetListData, zetListMain, zetpopup } from '../../redux/settings/action.js';
import { GetJobsAccount } from '../../redux/settings/accountActions.js';
import { JobDefListCodes } from '../../redux/settings/jobsActions.js';

var ereloontotaalexclbtw = 0;
var totaalbtw = 0;
var ereloontotaalinclbtw = 0;

class PopInvoice extends React.Component {
    state = {
        invoicedata: "",
        languagedata: "",
        jobdata: "",
        invoicejoblist: [],
        extralinesdata: [],
        makedr: false,
        makeaccijnzen: false,
        makevatrefund: false,
        factuurnr: "",
        factuurdate: "",
        factuurenddate: "",
        factuurtype: [],
        btwpercentage: "",
        klant: "",
        taal: "",
        factuurklant: "",
        ereloontotaalexclbtw: 0,
        totaalbtw: 0,
        ereloontotaalinclbtw: 0,
        invoicedescription: "",
    }

    constructor(props, context){
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this); 
        this.closePopJobs = this.closePopJobs.bind(this);
        this.getDirreg = this.getDirreg.bind(this);
        this.getAccijnzen = this.getAccijnzen.bind(this);
        this.getVatrefund = this.getVatrefund.bind(this);
        this.getExtraLines = this.getExtraLines.bind(this);
        this.pdfWithBackground = this.pdfWithBackground.bind(this);
        this.pdfWithoutBackground = this.pdfWithoutBackground.bind(this);
        this.sortInvoiceJobs = this.sortInvoiceJobs.bind(this);
    }

    async componentDidMount()
    {
        let maindata = this.props.data.invoicedata;

        let makedr;
        let makeaccijnzen;
        let makevatrefund;

        if(maindata.type === "Extra jobs"){
            for (let i = 0; i < maindata.factuurlines.length; i++){
                if(maindata.factuurlines[i].omschrijving !== ""){
                    let row = {
                        "omschrijving": maindata.factuurlines[i].omschrijving,
                        "tarief": maindata.factuurlines[i].ereloon
                    }
                    this.state.extralinesdata.push(row);
                }
            }
            makedr = false;
            makeaccijnzen = false;
            makevatrefund = false;
        } else {
            this.state.invoicejoblist = [];
            for (let i = 0; i < maindata.factuurlines.length; i++) {
                if (maindata.factuurlines[i].omschrijving === "") {
                    let row = {
                        "tarief": Number(maindata.factuurlines[i].ereloon),
                        "vat_facttot": Number(maindata.factuurlines[i].ereloon),
                        "country_txt": maindata.factuurlines[i].land,
                        "periode": maindata.factuurlines[i].periode,
                        "regnummer": maindata.factuurlines[i].regnr,
                        "code": maindata.factuurlines[i].subtype,
                        "saldotebet": maindata.factuurlines[i].aangiftebtw,
                        "vat_basisfact": maindata.factuurlines[i].teruggave,
                        "vat_koers": maindata.factuurlines[i].koers,
                        "munt": maindata.factuurlines[i].munteenheid
                    }
                    this.state.invoicejoblist.push(row);
                } else {
                    let row = {
                        "omschrijving": maindata.factuurlines[i].omschrijving,
                        "tarief": maindata.factuurlines[i].ereloon
                    }
                    this.state.extralinesdata.push(row);
                }
            }

            switch (maindata.type) {
                case "Directe Registratie":
                    makedr = true;
                    makeaccijnzen = false;
                    makevatrefund = false;
                    break;

                case "Accijnzen":
                    makedr = false;
                    makeaccijnzen = true;
                    makevatrefund = false;
                    break;

                case "VAT-Refund":
                    makedr = false;
                    makeaccijnzen = false;
                    makevatrefund = true;
                    break;

                default:
                    return;
            }
        }

        let factdate = (maindata.factdate).split('-').reverse().join('-');
        let factenddate = (maindata.factenddate).split('-').reverse().join('-');
        let btwperc = maindata.btw;
        let klant = maindata.klant;
        let taal = (maindata.taalcode_txt).toLowerCase();
        let factklant = {
            name: maindata.factklant_name,
            adress: maindata.factklant_address,
            zipcode: maindata.factklant_zipcode,
            country: maindata.factklant_country,
            vatnumber: maindata.factklant_vatnumber,
            number: maindata.factklant_number
        };
        await this.props.dispatch(JobDefListCodes(taal));
        let facttype = this.props.jobs.JobDefListCodesData.data;

        this.setState({ factuurnr: maindata.factnr, factuurdate: factdate, factuurenddate: factenddate, btwpercentage: btwperc, klant: klant, taal: taal, factuurklant: factklant, 
            factuurtype: facttype, invoicedata: maindata, languagedata: this.props.data.invoicelanguagedata, jobdata: this.props.data.jobdata, makedr: makedr, makeaccijnzen: makeaccijnzen, 
            makevatrefund: makevatrefund, invoicedescription: maindata.vermelding })

    }

    sortInvoiceJobs(jobs){
        let sortedArray = [];
        let countryFilter = "country_txt";

        for(let i = 0; i < jobs.length; i++){
            if(sortedArray.filter(obj => obj.code === this.state.factuurtype[this.state.invoicejoblist[i].code]['code']).length > 0){
                let codeIndex = sortedArray.map(function(obj) { return obj.code }).indexOf(this.state.factuurtype[this.state.invoicejoblist[i].code]['code']);

                if(sortedArray[codeIndex].countries.filter(country => country.countryName === jobs[i][countryFilter]).length > 0){
                    let countryIndex = sortedArray[codeIndex].countries.map(function(country) { return country.countryName }).indexOf(jobs[i][countryFilter]);
                    sortedArray[codeIndex].countries[countryIndex].jobs.push(jobs[i]);
                } else {
                    let jobsArray = [];
                    jobsArray.push(jobs[i])
                    let country = {
                        countryName: jobs[i][countryFilter],
                        jobs: jobsArray
                    };
                    sortedArray[codeIndex].countries.push(country);
                }
            } else {
                let jobsArray = [];
                jobsArray.push(jobs[i])
                let countriesArray = [];
                countriesArray.push({
                    countryName: jobs[i][countryFilter],
                    jobs: jobsArray
                })
                sortedArray.push({
                    code: this.state.factuurtype[this.state.invoicejoblist[i].code]['code'],
                    countries: countriesArray
                });
            }
        }

        let sortedInvoiceJobList = [];
        for(let i = 0; i < sortedArray.length; i++){
            for(let j = 0; j < sortedArray[i].countries.length; j++){
                let sortedOnPeriod = sortedArray[i].countries[j].jobs.sort(function(a, b) {
                    let aSplitted = a.periode.split("/");
                    let bSplitted = b.periode.split("/");
                    let aMonth = Number(aSplitted[0]);
                    let bMonth = Number(bSplitted[0]);
                    let aYear = Number(aSplitted[1]);
                    let bYear = Number(bSplitted[1]);
                    
                    if(aYear === bYear){
                        return aMonth - bMonth;
                    } else {
                        return aYear - bYear;
                    }
                })
                sortedInvoiceJobList = sortedInvoiceJobList.concat(sortedOnPeriod);
            }
        }

        return sortedInvoiceJobList;
    }

    getDirreg(){
        let dirreg = [];

        let regs = [];
        let regstype = [];
        let regstotaal = 0;
        ereloontotaalexclbtw = 0;

        let drjobs = this.sortInvoiceJobs(this.state.invoicejoblist);
        
        for(let i = 0; i < drjobs.length; i++){
            if(!regstype.includes(this.state.factuurtype[drjobs[i].code]['code'])){
                regstype[drjobs[i].code] = this.state.factuurtype[drjobs[i].code]['code'];
            }
        }

        for(let i = 0; i < Object.keys(regstype).length; i++){
            let type = [];
            let layout = [];
            let totaal = 0;
            for(let j = 0; j < drjobs.length; j++){
                if(Object.values(regstype)[i] === this.state.factuurtype[drjobs[j].code]['code']){
                    regstotaal += Number(drjobs[j].tarief);
                    totaal += Number(drjobs[j].tarief);
                    layout.push(
                        <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{drjobs[j].countryobj ? drjobs[j].countryobj[this.state.taal] : drjobs[j].country_txt}</label></div>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{drjobs[j].regnummer}</label></div>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{Object.keys(regstype)[i] !== "AANVR" ? drjobs[j].periode : ""}</label></div>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{Object.keys(regstype)[i] !== "AANVR" ? (new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((drjobs[j].saldotebet) * 100) / 100)).replace("€", "") : ""}</label></div>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((drjobs[j].tarief) * 100) / 100)}</label></div>
                        </div>
                    );
                }
                
            }
            type['layout'] = layout;
            type['ereloon'] = totaal;
            type['code'] = Object.keys(regstype)[i];
            regs[Object.values(regstype)[i]] = type;
        }

        ereloontotaalexclbtw = regstotaal;
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;

        for(let i = 0; i < Object.keys(regs).length; i++){
            dirreg.push(
                <div name={"dr" + i} key={"dr" + i}>
                    <div name={"regs"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{Object.keys(regs)[i]}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((Object.values(regs)[i].ereloon) * 100) / 100)}</label>
                            </div>
                        </div>
                        <div name={"dirregs"} style={{ width: "90%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.land}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.regnr}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{Object.values(regs)[i].code !== "AANVR" ? this.props.land.FactuuropbouwData.data.periode : ""}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{Object.values(regs)[i].code !== "AANVR" ? this.props.land.FactuuropbouwData.data.aangiftebtw : ""}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.ereloon}</label></div>
                            </div>
                            {Object.values(regs)[i].layout}
                        </div>
                    </div>
                </div>
            );
        }
        

        return dirreg;
    }

    getAccijnzen() {
        let accijnzen = [];

        return accijnzen;
    }

    getVatrefund() {
        let vatrefund = [];

        let vats = [];
        let vatstype = [];
        let vatstotaal = 0;


        ereloontotaalexclbtw = 0;

        let vatjobs = this.sortInvoiceJobs(this.state.invoicejoblist);
        
        for(let i = 0; i < vatjobs.length; i++){
            if(!vatstype.includes(this.state.factuurtype[vatjobs[i].code]['code'])){
                vatstype.push(this.state.factuurtype[vatjobs[i].code]['code']);
            }
        }

        for(let i = 0; i < vatstype.length; i++){
            let type = [];
            let layout = [];
            let totaal = 0;
            for(let j = 0; j < vatjobs.length; j++){
                if(vatstype[i] === this.state.factuurtype[vatjobs[j].code]['code']){
                    vatstotaal += Number(vatjobs[j].vat_facttot);
                    totaal += Number(vatjobs[j].vat_facttot);
                    let amountInEur = Number(vatjobs[j].vat_basisfact) * Number(vatjobs[j].vat_koers);
                    layout.push(
                        <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{vatjobs[j].countryobj ? vatjobs[j].countryobj[this.state.taal] : vatjobs[j].country_txt}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{vatjobs[j].periode}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{vatjobs[j].inEur ? Number(vatjobs[j].inEur).toLocaleString("es-ES", {minimumFractionDigits: 2}) : amountInEur.toLocaleString("es-ES", {minimumFractionDigits: 2})} {vatjobs[j].munt ? vatjobs[j].munt : vatjobs[j].countryobj.munt_txt}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{Number(vatjobs[j].vat_koers).toLocaleString("es-ES", {minimumFractionDigits: 2})}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{Number(vatjobs[j].vat_basisfact).toLocaleString("es-ES", {minimumFractionDigits: 2})}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((vatjobs[j].vat_facttot) * 100) / 100)}</label></div>
                        </div>
                    );
                }
                
            }
            type['layout'] = layout;
            type['ereloon'] = totaal;
            vats[vatstype[i]] = type;
        }

        ereloontotaalexclbtw = vatstotaal;
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;

        for(let i = 0; i < Object.keys(vats).length; i++){
            vatrefund.push(
                <div name={"vat" + i} key={"vat" + i}>
                    <div name={"vats"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{Object.keys(vats)[i]}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((Object.values(vats)[i].ereloon) * 100) / 100)}</label>
                            </div>
                        </div>
                        <div style={{ width: "80%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.land}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.periode}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.teruggave}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.koers}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{(this.state.languagedata.bedrageuro).replace("euro", "€")}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.ereloon}</label></div>
                            </div>
                            {Object.values(vats)[i].layout}
                        </div>
                    </div>
                </div>
            );
        }

        return vatrefund;
    }

    getExtraLines() {
        let extralines = [];
        let extradata = [];
        let extratotaal = 0;
        for (let i = 0; i < this.state.extralinesdata.length; i++) {
            extratotaal += Number(this.state.extralinesdata[i].tarief);
            extradata.push(
                <div style={{ display: "flex", flexDirection: "row", marginTop: "-10px" }} key={"extraline_" + i}>
                    <div style={{ width: "82.5%" }}>
                        <label>{this.state.extralinesdata[i].omschrijving}</label>
                    </div>
                    <div style={{ width: "8%" }}></div>
                    <div style={{ width: "6.5%" }}>
                        <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((this.state.extralinesdata[i].tarief) * 100) / 100)}</label>
                    </div>
                    <div style={{ width: "2%" }}></div>
                </div>
            );
        }

        ereloontotaalexclbtw += extratotaal;
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;

        extralines.push(
            <div name={"extralines"} key={"allextralines"}>
                {this.state.extralinesdata.length !== 0 &&
                    <div name={"extra"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{this.state.languagedata.extra}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(extratotaal * 100) / 100)}</label>
                            </div>
                        </div>
                        <div style={{ width: "80%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                <div style={{ width: "82.5%" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.description}</label></div>
                                <div style={{ width: "8%" }}></div>
                                <div style={{ width: "6.5%" }}><label style={{ fontWeight: "bold" }}>{this.state.languagedata.ereloon}</label></div>
                                <div style={{ width: "2%" }}></div>
                            </div>
                            {extradata}
                        </div>
                    </div>
                }
            </div>
        );
        
        extralines.push(
            
        );

        return extralines;
    }

    handleClose() {
        let title = 'Jobdetail - ' + this.state.jobdata.data.description + ' - ' + this.state.jobdata.data.account_name
        window.scrollTo(0, 0);
        this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} data={this.state.jobdata} close={this.closePopJobs} title={title} />)));
    }

    async closePopJobs() {
        this.setState({ PopUpScreen: null });
        this.props.dispatch(resetview())
        this.props.dispatch(zetpopup(null));
        await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id))
        await this.props.dispatch(ZetListData('GetJobsAccount', this.props.account.MainData.data.id));
        await this.props.dispatch(zetListMain('GetJobsAccount', this.props.account.MainData.data.id));
    }

    pdfWithBackground(){
        document.getElementById('INVOICEPDFBRUTAX').submit();
    }

    pdfWithoutBackground(){
        document.getElementById('INVOICEPDF').submit();
    }

    render() {
        const {fullScreen,classes, open} = this.props;
        return(
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal80
                }}

                fullScreen={fullScreen}
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible",zIndex:'1001',width:'100%'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                maxWidth='md'
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>              
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px'}}  className={classes.blockContent}>
                    <div style={{ width: "220mm" }}>
                        <div id={"invoicepdfdiv"}>
                            <div>
                                <div name={"Factuur_top"}>
                                    <div name={"Klantgeg"} style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                        <div style={{ width: "70%" }}></div>
                                        <div style={{ widht: "30%", display: "flex", flexDirection: "column" }}>
                                            <label>{this.state.factuurklant.name}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.adress}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.zipcode}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.country}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.vatnumber}</label>
                                        </div>
                                    </div>
                                    <label style={{ fontSize: "11px", fontStyle: "italic", marginBottom: "-5px" }}>{"*" + this.state.languagedata.vermelding}</label>
                                    <div name={"Factuurdatums"} style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "10px" }}>
                                        <div style={{ width: "20%", display: "flex", flexDirection: "column" }}>
                                            <label>{this.state.languagedata.factnr + "*"}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.languagedata.klantnr + "*"}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.languagedata.factdate}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.languagedata.factenddate}</label>
                                        </div>
                                        <div style={{ width: "80%", display: "flex", flexDirection: "column" }}>
                                            <label>{this.state.factuurnr}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.number}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.factuurdate}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.factuurenddate}</label>
                                        </div>
                                    </div>
                                </div>
                                <div name={"Factuur_center"}>
                                    <div className='div_line' style={{ marginBottom: "5px" }} />
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                        <div style={{ width: "85%" }}>
                                            <label style={{ fontWeight: "bold" }}>{this.state.languagedata.description}</label>
                                        </div>
                                        <div style={{ width: "15%", textAlign: "center" }}>
                                            <label style={{ fontWeight: "bold" }}>{this.state.languagedata.totaalexcl}</label>
                                        </div>
                                    </div>
                                    <div className='div_line' style={{ marginBottom: "10px" }} />
                                    {this.state.klant !== this.state.factuurklant.name &&
                                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                            <label>{this.state.languagedata.prestaties}</label>
                                            <div style={{ marginTop: "-10px" }}>
                                                <label>{this.state.languagedata.klant}</label>
                                                <label style={{ marginLeft: "25px" }}>{this.state.klant}</label>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div name={"Factuur_bottom"} style={{ marginTop: "15px" }}>
                                    {this.state.makedr &&
                                        this.getDirreg()
                                    }
                                    {this.state.makeaccijnzen &&
                                        this.getAccijnzen()
                                    }
                                    {this.state.makevatrefund &&
                                        this.getVatrefund()
                                    }
                                    {this.state.extralinesdata.length !== 0 &&
                                        this.getExtraLines()
                                    }
                                    <div className='div_line' style={{ marginBottom: "5px", marginTop: "25px" }} />
                                    <div name={"KostenTotaal"} style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "25px" }}>
                                        <div style={{ width: "65%" }} />
                                        <div style={{ display: "flex", flexDirection: "column", width: "20%" }}>
                                            <label>{this.state.languagedata.totaalereloon}</label>
                                            <label style={{ marginTop: "-10px" }}>{this.state.languagedata.btw +  " (" + this.state.btwpercentage + "%)"}</label>
                                            <label style={{ marginTop: "10px", fontWeight: "bold" }}>{this.state.languagedata.betalen}</label>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", width: "15%", textAlign: "center" }}>
                                            <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(ereloontotaalexclbtw * 100) / 100)}</label>
                                            <label style={{ marginTop: "-10px" }}>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(totaalbtw * 100) / 100)}</label>
                                            <label style={{ marginTop: "10px", fontWeight: "bold" }}>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(ereloontotaalinclbtw * 100) / 100)}</label>
                                        </div>
                                    </div>
                                    <div className='div_line' style={{ marginBottom: "5px", marginTop: "25px" }} />
                                    <div name={"Vermelding"} style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                        {this.state.invoicedescription && <label>{this.state.invoicedescription}</label>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <form id='INVOICEPDF' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{ width: '100%' }} >
                            <input style={{ display: 'none' }} type="text" defaultValue='CreateInvoicePdf' id="function" name="function" />
                            <input style={{ display: 'none' }} type="text" defaultValue={this.state.invoicedata.id} id="id" name="id" />
                            <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid" />
                            <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid" />
                        </form>
                        <form id='INVOICEPDFBRUTAX' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{ width: '100%' }} >
                            <input style={{ display: 'none' }} type="text" defaultValue='CreateInvoicePdfBrutax' id="function" name="function" />
                            <input style={{ display: 'none' }} type="text" defaultValue={this.state.invoicedata.id} id="id" name="id" />
                            <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid" />
                            <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid" />
                        </form>
                    </div>
                </DialogContent>
                <DialogActions id={"DiaAct" + this.state.refid}>
                    <Button style={{ background: "#fb9678", color: "white" }} onClick={() => this.pdfWithBackground()}>
                        PDF met briefhoofd
                    </Button>
                    <Button style={{ background: "#fb9678", color: "white" }} onClick={() => this.pdfWithoutBackground()}>
                        PDF zonder briefhoofd
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => ({
    ...state
});

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopInvoice)));