import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Editor } from '@tinymce/tinymce-react';



class PopDetailDok extends React.Component {
    state = {
        data: this.props.data,
        filename:'',
        save:false,
        StatFileUpload: 'ok',
        nivo:0,
        refid: GenFunc.MakeId(),
        editorState: '',
        velden:null,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.SaveCheck = this.SaveCheck.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);        
        this.setmenuitems = this.setmenuitems.bind(this);

    }

    async componentDidMount(){
        let retdata = await this.setmenuitems();
        this.setState({data: this.props.data,editorState:this.props.data.dok, velden:retdata})
    }

    async setmenuitems() {
        var items = [];
        var item
        let retdata = await SqlFunction.DoSql('AllTemplFields', {});

        if (retdata && retdata.data) 
        {
          var i = 0;
          var len = retdata.data.length;
          for (; i < len; i++) 
          {
            item = retdata.data[i];
            item.type = 'menuitem';
            item.text = item.description;
            item.value = item.code;
            let itemCopy = Object.assign({}, item);                
            items.push(itemCopy);
          }
        }
        return items;
    }

    onEditorStateChange(content, editor) {
      let data = this.state.data
      data.dok = content 
      this.setState({editorState:content});
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens

      let data = this.state.data;
      let terdata = null;
      if (data.description.length > 3 )
      {
        terdata = await SqlFunction.DoSql('ChgJob',data);
        if (this.props.close) 
        {
          this.props.close(terdata)
        }
      }
    }

    SaveCheck(data) 
    {
      let chsave = true;
      return chsave
    }



    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var tableheight = Math.floor(window.innerHeight * .65);
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : '10px';

        return (
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <PerfectScrollbar className="sidebar-nav">
                  <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                    <Grid container spacing={1}>
                     {this.state.velden &&
                      <Grid item xs={12} style={{background: 'white',height: '73vh', overflow: 'auto',paddingLeft: '10px',paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px'}}>
                        <Editor
                          
                          onEditorChange={this.onEditorStateChange}      
                          init={{
                            height:tableheight,
                            language: 'nl',
                            plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime table paste code'
                          ],
                          toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent |  image | removeformat',
                          }}
                          value={this.state.editorState}
                        />

                      </Grid>
                      }
                    </Grid>
                  </DialogContent>
                </PerfectScrollbar>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  <Button color="primary" disabled={this.state.save} onClick={() => this.handleSave()}>
                    Bewaren
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopDetailDok.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopDetailDok)));

