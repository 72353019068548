
import React, {createRef } from 'react';
import { connect } from 'react-redux'
import { Card, CardBody } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import { GetAllFacturen, GetAccount, CreateAccount, GetContAccount, GetFactuurAccount } from "../../redux/settings/accountActions";

import { CreateContact, GetContact } from "../../redux/settings/contactActions";
import { GetJobMain, CreateJobMain, CreateBetaalfin, GetBetaalFin, GetsubJobMain,GetsubBetaalFin, AllJobsToInvoice, AllJobDefMain, CreateVatrefcode, GetVatRefCode, GetSubVatRefCode } from "../../redux/settings/jobsActions";
import { GetKantoor, GetContKantoor, CreateKantoor,GetKantContact, CreateKantContact } from "../../redux/settings/kantoorActions";
import {CreateRegistration,GetRegistration} from "../../redux/settings/registrationActions";
import {GetActivity,AllActivities,AllMyActivities } from "../../redux/settings/activityActions";
import { GetOnkostenLand, AllTaalcodes, GetTaalcode, GetFactuuropbouw, AllLanden, CreateTaalcode, CreateFactuuropbouw } from "../../redux/settings/landActions";
import { GetUser, CreateUser } from "../../redux/settings/userActions";
import {setSubTitle,zetListMain,ZetListData,zetpopup,resetview} from "../../redux/settings/action";
import {GetAllJobs,} from "../../redux/settings/jobsActions";
import { GetLand, CreateLand } from '../../redux/settings/landActions.js';
import { GetTemplate,CreateTemplate } from '../../redux/settings/templateActions.js';
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import { GetDocSupplier, GetSupplier, CreateSupplier } from '../../redux/settings/supplierActions.js';
import { GetMainIvat } from '../../redux/settings/paramActions.js';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import FileExcel from "@material-ui/icons/FileExcel";
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import PopActivity from "../../views/activities/PopActivity.jsx";
import PopJobAanvrNum from "../jobs/PopJobAanvrNum.jsx";
import PopCreateJob from "../jobs/PopCreateJob.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import LoaderGif from '../../assets/images/loadinggif.gif';
import Upload from '@material-ui/icons/Upload';
import Dropzone from 'react-dropzone';

import print from 'print-js'

var gridapi = null;
var dropdoc = null;
const seeformid = GenFunc.MakeId()
const docformid = GenFunc.MakeId()


const docdropidcontr = GenFunc.MakeId()
const dropzoneRefContr = createRef();

const openDialogContr = () => {
  // Note that the ref is set async,
  // so it might be null at some point 
  if (dropzoneRefContr.current) {
    dropzoneRefContr.current.open()
  }
};

const mapStateToProps = state => ({
  ...state
});

class LstMain extends React.Component {

  state = {
    tableheight: Math.floor(window.innerHeight * .75) + 'px',
    temp_id:'',
    template_txt:'',
    temp_txt: null,
    speedactions: (this.props.match && this.props.match.path && this.props.match.path === '/jobdivers') ? [
      { icon: <Upload />, name: 'Ophalen', tooltip:'Laden admin kosten' },
      { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe gegevens invoeren' },
      { icon: <FileExcel />, name: 'Export', tooltip: 'Exporteer gegevens naar Excell' },
    ] : 
    [
      { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe gegevens invoeren' },
      { icon: <FileExcel />, name: 'Export', tooltip: 'Exporteer gegevens naar Excell' },
    ],
    speedactionsexc: [
      { icon: <FileExcel />, name: 'Export', tooltip: 'Exporteer gegevens naar Excell' },

    ],

    speedopen: false,
    subspeedzien:true,
    showLoadingGif: false,
  };

  constructor(props) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);    
    this.onGridReady = this.onGridReady.bind(this);
    this.CellClick = this.CellClick.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.ValueCheck = this.ValueCheck.bind(this);
    this.ValueTempCheck = this.ValueTempCheck.bind(this);

    this.ValueChanged = this.ValueChanged.bind(this);
    this.SaveAll = this.SaveAll.bind(this);
    this.CheckSave = this.CheckSave.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.createRecord = this.createRecord.bind(this)
    this.saveDataClicked = this.saveDataClicked.bind(this);

    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.ActionClick = this.ActionClick.bind(this);

    this.ClickActivity = this.ClickActivity.bind(this);
    this.ClosePopActivity = this.ClosePopActivity.bind(this);
    this.ClosePopJobs = this.ClosePopJobs.bind(this);
    this.ClosePopCreaJobs = this.ClosePopCreaJobs.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.closeprintwindow = this.closeprintwindow.bind(this);
    this.AddDocument = this.AddDocument.bind(this);        
    this.onDropContr = this.onDropContr.bind(this);        
    this.ZetAfs = this.ZetAfs.bind(this);


  }

  componentDidMount(){
    console.log(this.props.match.path)
    window.addEventListener("resize", this.resizeFunction);
  }

  AddDocument(veld)
  {
    dropdoc = null;
    let element = document.getElementById(docdropidcontr);
    if (element)
    {
      element.click()
    }
  }

  async onDropContr(files) {
    let that = this;
    let file = files[0];
    let reader = new FileReader();
    let content = null;
    
    reader.onloadend = () => {
      content = reader.result;
      that.ZetAfs(content);
    };
    reader.readAsText(file);

  };

  async ZetAfs(content)
  {
      var scontent = [];
      var result = [];
      var bank = '';
      var bankid = 0;   
      var lineSplit = content.split(/\r?\n/g);
      var fourColumnsData = '';
      var ColumnsData = '';
      var res = '';
      var klantnr = '';
      var klantnaam = '';
      var tarief = 0;
      if (lineSplit.length < 2)
      {
        lineSplit = content.split(/\r?\n/g);
        for (var j = 0; j < lineSplit.length; j++) 
        {
          fourColumnsData = lineSplit[j].split(';').slice(0, 40).join("';'");
          scontent.push(fourColumnsData);
        }  
      }
      else
      {
        for (var j = 1; j < lineSplit.length; j++) 
        {
          fourColumnsData = lineSplit[j].split(';').slice(0, 40).join("';'");

          scontent.push(fourColumnsData);
        }  
      }

      for (j = 0; j < scontent.length; j++) 
      {
          ColumnsData = scontent[j];
          res = ColumnsData.split("';'");
          if (res.length > 2)
          {
              klantnr = res[0].replace("'",'');
              klantnr = klantnr.replace('"','');
              klantnaam = res[1].replace("'",'');
              klantnaam = klantnaam.replace('"','');
              tarief = res[2].replace("'",'');
              tarief = tarief.replace('"','');
              let htarief = (tarief.constructor === String) ? parseFloat(tarief) : tarief;

              if (htarief > 0)
              {
                  let tempdata = await SqlFunction.DoSql('CreaAdmKostAut', {account_id:klantnr, tarief:tarief});
              }
          }
      }
      this.props.dispatch(resetview())
      this.props.dispatch(zetpopup(null));
      if (gridapi) {gridapi.refreshData()}
  }



  async closeprintwindow() 
  {
    await this.props.dispatch(GetAllFacturen());
    window.removeEventListener("focus", this.closeprintwindow);

  }

  componentWillUnmount() 
  {
    window.removeEventListener("resize", this.resizeFunction);
  }

  handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:true });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
  };

  async ClosePopJobs() {

    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    if (gridapi) {gridapi.refreshData()}
  }

  async ClosePopCreaJobs(terdata) {

    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    if (gridapi) {gridapi.refreshData()}
    if (terdata.id)
    {
      let jobdata = await SqlFunction.DoSql('GetJob', terdata);
      let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
      window.scrollTo(0, 0);
      this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} data={jobdata} close={this.ClosePopJobs} title={title} />)));
    }
  }


  async ClickActivity(data) {
        await this.props.dispatch(GetActivity(data.id));
        window.scrollTo(0, 0);
        this.props.dispatch(zetpopup((<PopActivity open={true} close={this.ClosePopActivity} title="Post IT"/>)));

  }

  async ClosePopActivity() {
   switch (this.props.settings.security_title) 
    {
      case 'AllActivities' :
        await this.props.dispatch(AllActivities());
        break;

      case 'AllMyActivities' :
        await this.props.dispatch(AllMyActivities(this.props.user.userData.id));
        break;

      default:    
        break;

    }

    this.props.dispatch(zetpopup(null));
  } 


  async ActionClick(name,event) {

    this.setState({ speedopen: false });
    switch (name) 
    {


      case 'New':
        switch (this.props.settings.security_title) 
        {
          case 'AllUsers' :
            this.props.dispatch(setSubTitle('Gebruiker'))
            await this.props.dispatch(CreateUser())
            this.props.history.push('/user');
            break;

          case 'AllAccounts'  :
            this.props.dispatch(setSubTitle('Klant'))
            await this.props.dispatch(CreateAccount())
            this.props.history.push('/account');
            break;

          case 'AllFacturen':
            if (event.shiftKey === true)          
            {
              this.setState({ showLoadingGif: true })

              let hulpdata = this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['data'];
              let printfakt = [];
              for(let i = 0; i < hulpdata.length; i++)
              {
                let factdata = hulpdata[i];
                if (factdata['mailpdf'] !== '0' )
                {
                  printfakt.push(factdata);
                }
              }
              for (let j = 0; j < printfakt.length; j++) 
              {
                let maildata = printfakt[j];
                await SqlFunction.DoSql('MailPdfFile', maildata);
              }

              for (let j = 0; j < printfakt.length; j++) 
              {
                let sdata = printfakt[j];
                await SqlFunction.DoSql('MakePdfFile', sdata);
              }
              let printdata = await SqlFunction.DoSql('MakeTotFactuur', { data: printfakt});
              if (printdata)
              {
                let secid = JSON.parse(localStorage.getItem('secid'));
                let clsecid = JSON.parse(localStorage.getItem('clsecid'));
                let printhtml = "https://app.brutax.com/server/index.php?function=OpenPrintBatch&secid="+secid+" &clsecid="+clsecid;
                print(printhtml)
              }
              this.setState({ showLoadingGif: false })


            }
            else
            {
              this.setState({ showLoadingGif: true })
              this.props.dispatch(setSubTitle('Facturatie'))
              await this.props.dispatch(AllJobsToInvoice(this.props));
              this.props.history.push('/billing');
            }
            break;


          case 'AllRegistratie'  :
            this.props.dispatch(setSubTitle('Registratie'))
            await this.props.dispatch(CreateRegistration())
            this.props.history.push('/registration');

            break;

          case 'AllContacts'  :
            this.props.dispatch(setSubTitle('Contactpersoon'))
            await this.props.dispatch(CreateContact())
            this.props.history.push('/contact');
            break;

          case 'AllJobDefMain'  :
            this.props.dispatch(setSubTitle('Job definitie'))
            await this.props.dispatch(AllTaalcodes(this.props));
            await this.props.dispatch(CreateJobMain())
            this.props.history.push('/jobmaindef');
            break;

          case 'AllVatRefCode'  :
            this.props.dispatch(setSubTitle('Vat refund codes'))
            await this.props.dispatch(CreateVatrefcode())
            this.props.history.push('/vatrefcodepage');
            break;

         case 'AllJobs':

            let creadata = await GenFunc.zetdataleeg('Job');
            creadata.account_id = '';
            creadata.user_id = '';
            creadata.country = '';
            creadata.create_date = GenFunc.Get_Vandaag();
            creadata.jaar = GenFunc.Get_Jaar();
            creadata.dagen = null;
            creadata.account_name = '';
            creadata.country_txt = '';
            creadata.user_name = '';
            creadata.tarief = 0;
            window.scrollTo(0, 0);

            this.props.dispatch(zetpopup((<PopCreateJob open={true} path={this.props.match.path} data={creadata} close={this.ClosePopCreaJobs} title='Aanmaken job' />)));
            break;



          case 'AllBetaalFin'  :
            this.props.dispatch(setSubTitle('Betaallijst Finanz'))
            await this.props.dispatch(CreateBetaalfin())
            this.props.history.push('/betaalmainpage');
            break;


          case 'AllKantoren':
            this.props.dispatch(setSubTitle('Kantoor'))
            await this.props.dispatch(CreateKantoor())
            this.props.history.push('/kantoor');
            break;

          case 'AllKantContacts'  :
            this.props.dispatch(setSubTitle('Contactpersoon kantoor'));
            await this.props.dispatch(CreateKantContact())
            await this.props.dispatch(AllLanden(this.props));
            this.props.history.push('/kantcontact');
            return;

          case 'AllSuppliers':
            this.props.dispatch(setSubTitle('Leverancier'))
            await this.props.dispatch(CreateSupplier())
            this.props.history.push('/leverancier');
            break;

          case 'AllLanden':
            this.props.dispatch(setSubTitle('Land'))
            await this.props.dispatch(AllTaalcodes(this.props));
            await this.props.dispatch(CreateLand())
            this.props.history.push('/land');
            break;
          
          case 'AllTaalcodes':
            this.props.dispatch(setSubTitle('Taalcode'))
            await this.props.dispatch(CreateTaalcode())
            await this.props.dispatch(CreateFactuuropbouw())
            await this.props.dispatch(AllLanden(this.props));
            await this.props.dispatch(AllJobDefMain(this.props));
            this.props.history.push('/taalcode');
            break;

          case 'AllTemplates':
            this.props.dispatch(setSubTitle('Template'))
            await this.props.dispatch(CreateTemplate())
            this.props.history.push('/template');
            break;



          default:    
            break;
        }       



      break;

      case 'Export':
        if (gridapi)
        {
          if (this.props.settings && this.props.settings.listmaindata && this.props.settings.listmaindata.funktie && this.props.settings.listmaindata.infinite === true )
          {
            let sqlcom = this.props.settings.listmaindata.funktie+'ExFile';
            let hudata = gridapi.api.conditie ? JSON.stringify(gridapi.api.conditie) : {};
            await this.setState({exesql:sqlcom, conditie:hudata});
            if (document.getElementById(docformid))
            {
              console.log(this.state.conditie)
              document.getElementById(docformid).submit();
            }
          }
          else
          {
              gridapi.onBtExport();
          }
        }
        break;

      case 'Ophalen':
        this.AddDocument();
        break;


      default:    
        break;      
    }

  }

  async createRecord()
  {
    switch (this.props.settings.security_title) 
    {
      case 'AllUsers'	:
        this.props.dispatch(setSubTitle('Gebruiker'))
        await this.props.dispatch(CreateUser())
        this.props.history.push('/user');
        break;

      case 'AllAccounts'	:
        this.props.dispatch(setSubTitle('Klant'))
        await this.props.dispatch(CreateAccount())
        this.props.history.push('/account');
        break;

      case 'AllRegistratie'  :
        this.props.dispatch(setSubTitle('Registratie'))
        await this.props.dispatch(CreateRegistration())
        this.props.history.push('/registration');
        break;

      case 'AllContacts'	:
        this.props.dispatch(setSubTitle('Contactpersoon'))
        await this.props.dispatch(CreateContact())
        this.props.history.push('/contact');
        break;

      case 'AllJobDefMain'  :
        this.props.dispatch(setSubTitle('Job definitie'))
        await this.props.dispatch(AllTaalcodes(this.props));
        await this.props.dispatch(CreateJobMain())
        this.props.history.push('/jobmaindef');
        break;

      case 'AllVatRefCode'  :
        this.props.dispatch(setSubTitle('Vat refund codes'))
        await this.props.dispatch(CreateVatrefcode())
        this.props.history.push('/vatrefcodepage');
        break;

      case 'AllBetaalFin'  :
        this.props.dispatch(setSubTitle('Betaallijst Finanz'))
        await this.props.dispatch(CreateBetaalfin())
        this.props.history.push('/betaalmainpage');
        break;

      case 'AllJobs':

        let creadata = await GenFunc.zetdataleeg('Job');
        creadata.account_id = '';
        creadata.user_id = '';
        creadata.country = '';
        creadata.create_date = GenFunc.Get_Vandaag();
        creadata.jaar = GenFunc.Get_Jaar();
        creadata.dagen = null;
        creadata.account_name = '';
        creadata.country_txt = '';
        creadata.user_name = '';
        window.scrollTo(0, 0);
        this.props.dispatch(zetpopup((<PopCreateJob open={true} data={creadata} close={this.ClosePopCreaJobs} title='Aanmaken job' />)));
        break;

      case 'AllKantoren':
        this.props.dispatch(setSubTitle('Kantoor'))
        await this.props.dispatch(CreateKantoor())
        this.props.history.push('/kantoor');
        break;

      case 'AllSuppliers':
        this.props.dispatch(setSubTitle('Leverancier'))
        await this.props.dispatch(CreateSupplier())
        this.props.history.push('/leverancier');
        break;

      case 'AllLanden':
        this.props.dispatch(setSubTitle('Land'))
        await this.props.dispatch(AllTaalcodes(this.props));
        await this.props.dispatch(CreateLand())
        this.props.history.push('/land');
        break;
      
      case 'AllTaalcodes':
        this.props.dispatch(setSubTitle('Taalcode'))
        await this.props.dispatch(CreateTaalcode())
        await this.props.dispatch(CreateFactuuropbouw())
        await this.props.dispatch(AllLanden(this.props));
        await this.props.dispatch(AllJobDefMain(this.props));
        this.props.history.push('/taalcode');
        break;

      case 'AllTemplates':
        this.props.dispatch(setSubTitle('Template'))
        await this.props.dispatch(CreateTemplate())
        this.props.history.push('/template');
        break;

      case 'AllFacturen':
        this.props.dispatch(setSubTitle('Facturatie'))
        await this.props.dispatch(AllJobsToInvoice(this.props));
        this.props.history.push('/billing');
        break;


      default:    
        break;
    }       
  }
  resizeFunction() 
  {
    if (window.innerHeight >= 960) 
    {
      this.setState({tableheight: Math.floor(window.innerHeight * .75) + 'px'});
    }
    else
    {
      this.setState({tableheight: '600px'});

    }
  }

  onGridReady(params)
  {
    gridapi = params;
  }

  async saveDataClicked(waarde, chgField)
  {
    var data = waarde.data
    let column = waarde.column.colDef.field;
    let retdata = null;
      if (column === 'save') {
        if (data.save === true) {
          retdata = await SqlFunction.DoSql(chgField, data);
          waarde.data.save = '';
          if (data.id === 0) {
            waarde.data.id = retdata.id;
            gridapi.api.stopEditing();
            gridapi.api.updateRowData({
              add: [waarde.data],
              addIndex: 0
            });
            waarde.node.setData(GenFunc.zetdataleeg(this.props.settings.listmaindata.referentie));
          }
          else {
            waarde.node.setData(waarde.data);
          }
        }
      }
    }

  async sendMail(data) {


    if (this.props.settings.security_title === 'AllJobs' && this.state.temp_id && this.state.temp_id.length > 4)
    {
        data.temp_id = this.state.temp_id;
        data.job_id = data.id;
        let tempdata = await SqlFunction.DoSql('GetTempList', data);
        if (tempdata && tempdata.data)
        {
            var maildata = {}
            maildata.recepient = data.email;
            maildata.attach = null;
            maildata.subject = tempdata.data.description;
            maildata.context = tempdata.data.temp_text;
//            SqlFunction.ConnectMail('', {},null);
            await SqlFunction.DoSql('SendJobMail', maildata);
        }
    }
  }    

  async CellClick(waarde) {

    var data = waarde.data;
    switch (this.props.settings.security_title) 
    {

      case 'AllSecPar'	:
        await this.saveDataClicked(waarde,'ChgSecParam');
        break;

      case 'AllMunten'	:
        await this.saveDataClicked(waarde,'ChgMunten');
        break;

      case 'AllRegOms'	:
        await this.saveDataClicked(waarde,'ChgRegOms');
        break;

      case 'AllRegTar'	:
        await this.saveDataClicked(waarde,'ChgRegTar');
        break;

      case 'AllBetalingsvoorwaarden':
        await this.saveDataClicked(waarde,'ChgBetalingsvoorwaarden');
        break;

      case 'AllBtwRegimes':
        await this.saveDataClicked(waarde,'ChgBtwRegimes');
        break;

      case 'AllFactZendwijzen':
        await this.saveDataClicked(waarde,'ChgFactZendwijzen');
        break;

      case 'AllFuncties':
        await this.saveDataClicked(waarde,'ChgFuncties');
        break;

      case 'AllAansprekingen':
        await this.saveDataClicked(waarde,'ChgAansprekingen');
        break; 

      case 'AllPostcodes'	:
        await this.saveDataClicked(waarde,'ChgZipcode');
        break;

      case 'AllRekeningen' :
        await this.saveDataClicked(waarde,'ChgRekening');
        break;

      case 'AllActivities' :
      case 'AllMyActivities' :
        await this.ClickActivity(data);
        break;



      case 'AllUsers':
        this.props.dispatch(setSubTitle('Gebruiker'))
        await this.props.dispatch(GetUser(data.id))
        this.props.history.push('/user');
        break;

      case 'AllJobStatus':
        await this.saveDataClicked(waarde,'ChgJobStatus');
        break;

      case 'AllJobDefMain':
        this.props.dispatch(setSubTitle('Job definitie'))
        await this.props.dispatch(GetJobMain(data.id))
        await this.props.dispatch(GetsubJobMain(data.id))
        await this.props.dispatch(AllTaalcodes(this.props));
        await this.props.dispatch(ZetListData('SubJobMain'));
        await this.props.dispatch(zetListMain('SubJobMain'));
        this.props.history.push('/jobmaindef');
        break;

      case 'AllVatRefCode'  :
        this.props.dispatch(setSubTitle('Vat refund codes'))
        await this.props.dispatch(GetVatRefCode(data.id))
        await this.props.dispatch(GetSubVatRefCode(data.id))
        await this.props.dispatch(ZetListData('AllVatRefCode'));
        await this.props.dispatch(zetListMain('AllVatRefCode'));
        this.props.history.push('/vatrefcodepage');
        break;


      case 'AllBetaalFin'  :
        this.props.dispatch(setSubTitle('Betaallijst Finanz'))
        await this.props.dispatch(GetBetaalFin(data.id))
        await this.props.dispatch(GetsubBetaalFin(data.id))
        await this.props.dispatch(ZetListData('SubJobMain'));
        await this.props.dispatch(zetListMain('SubJobMain'));
        this.props.history.push('/betaalmainpage');
        break;


      case 'AllAccounts'	:
        this.props.dispatch(setSubTitle('Klantenfiche'))
        await this.props.dispatch(GetAccount(data.id))
        await this.props.dispatch(GetContAccount(data.id))
        this.props.history.push('/account');
        break;


      case 'AllJobs'  :
        let column = waarde.column.colDef.field;
        if (column === 'verwijder')
        {
          if (data.verwijder && (data.verwijder === '1' || data.verwijder === 1 || data.verwijder === true))
          {
              await SqlFunction.DoSql('DeleteJob', data);
              if (gridapi) {gridapi.purData();}
          }
          return;
        }

        if (column === 'email')
        {
          this.sendMail(data)
          return;
        }

        let jobdata = await SqlFunction.DoSql('GetJob', data);
        let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name;
        window.scrollTo(0, 0);
        this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} history={this.props.history} data={jobdata} close={this.ClosePopJobs} title={title} />)));
        break;


      case 'AllFacturen':
        let columnfact = waarde.column.colDef.field;
        let secid = JSON.parse(localStorage.getItem('secid'));
        let clsecid = JSON.parse(localStorage.getItem('clsecid'));
        let whoog = Math.floor(document.body.innerHeight) - 600;      
        let wbreed = Math.floor(document.body.innerWidth) - 600;      
        var top = window.screenY + 200
        var left = window.screenX + 200
        let windowprint = null;
        let thatwin = this
        let printhtml = ''
        if (columnfact === 'printbrut')
        {
          if (data.printbrut && (data.printbrut === '0' || data.printbrut === 0))
          {
            printhtml = "https://app.brutax.com/server/index.php?function=CreateInvoicePdfBrutax&id="+data.id+" &secid="+secid+" &clsecid="+clsecid;
            print(printhtml)

//            windowprint = window.open("https://app.brutax.com/server/index.php?function=CreateInvoicePdfBrutax&id="+data.id+" &secid="+secid+" &clsecid="+clsecid+ " ","_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top="+top+",left="+left+",width="+wbreed+",height="+whoog);
//            if (windowprint)
//            {
//              window.addEventListener("focus", this.closeprintwindow)
//              windowprint.print();
//            }
          }
          return;
        }

        if (columnfact === 'printpdf')
        {
          if (data.printpdf && (data.printpdf === '0' || data.printpdf === 0))
          {
            printhtml = "https://app.brutax.com/server/index.php?function=CreateInvoicePdf&id="+data.id+" &secid="+secid+" &clsecid="+clsecid;
            print(printhtml)

//            windowprint = window.open("https://app.brutax.com/server/index.php?function=CreateInvoicePdf&id="+data.id+" &secid="+secid+" &clsecid="+clsecid+ " ","_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top="+top+",left="+left+",width="+wbreed+",height="+whoog);
//            if (windowprint)
//            {
//              window.addEventListener("focus", this.closeprintwindow)
//              windowprint.print();
//
//            }

          }
          return;
        }

        if (columnfact === 'mailpdf')
        {
          if (data.mailpdf && data.mailpdf > 0)
          {
            await SqlFunction.DoSql('MailFactuur', data)
          }
          return;
        }

        this.props.dispatch(setSubTitle('Factuurdetails'))
        await this.props.dispatch(GetFactuurAccount(data.id));
        await this.props.dispatch(GetFactuuropbouw(data.taalcode));
        this.props.history.push('/invoice');
        break;




      case 'AllRegistratie':
        this.props.dispatch(setSubTitle('Registratie'))
        await this.props.dispatch(GetRegistration(data.id))
        this.props.history.push('/registration');
        break;

      case 'AllKantContacts':
        this.props.dispatch(setSubTitle('Contactpersoon kantoor'));
        await this.props.dispatch(GetKantContact(data.id))
        await this.props.dispatch(AllLanden(this.props));
        this.props.history.push('/kantcontact');
        return;

      case 'AllContacts'	:
        this.props.dispatch(setSubTitle('Contactpersoon'))
        await this.props.dispatch(GetContact(data.id))
        this.props.history.push('/contact');
        break;
        
      case 'AllLanden':
        this.props.dispatch(setSubTitle('Land'));
        await this.props.dispatch(GetLand(data.id));
        await this.props.dispatch(GetOnkostenLand(data.id));
        await this.props.dispatch(AllTaalcodes(this.props));
        this.props.history.push('/land');
        break;

      case 'AllMainProductCodes':
        this.props.dispatch(setSubTitle('Ivat code'));
        await this.props.dispatch(GetMainIvat(data.id));
        this.props.history.push('/ivatcode');
        break;


      case 'AllTaalcodes':
        this.props.dispatch(setSubTitle('Taalcode'));
        await this.props.dispatch(GetTaalcode(data.id));
        await this.props.dispatch(GetFactuuropbouw(data.id));
        await this.props.dispatch(AllLanden(this.props));
        await this.props.dispatch(AllJobDefMain(this.props));
        this.props.history.push('/taalcode');
        break;

      case 'AllTemplates'  :
        this.props.dispatch(setSubTitle('Template'))
        await this.props.dispatch(GetTemplate(data.id))
        this.props.history.push('/template');
        break;


      case 'AllKantoren' : 
        this.props.dispatch(setSubTitle('BTW-Kantoor'))
        await this.props.dispatch(GetKantoor(data.id))
        await this.props.dispatch(GetContKantoor(data.id))
        this.props.history.push('/kantoor');
        break;

      case 'AllSuppliers':
        this.props.dispatch(setSubTitle('Leverancier'))
        await this.props.dispatch(GetSupplier(data.id))
        await this.props.dispatch(GetDocSupplier(data.id))
        this.props.history.push('/leverancier');
        break;

      default:    
        break;
    }     
    return;
  }

  getContextMenuItems(params,sleutel) 
  {
    if (sleutel === 'Leeg')
    {
      return null;
    }
    var result = [
      {
        name: 'Copieer bijlage',
        action: function() {
          
        },
      },
    ];
    return result;
  }

  async SaveAll(items)
  {
      if (Array.isArray(items))
      {
        var i = 0;
        for (i = 0; i < items.length; i++) 
        {
          let data = items[i];
          switch (this.props.settings.security_title) 
          {
            case 'AllSecPar'	:
              await SqlFunction.DoSql('ChgSecParam',data);
              break;

            case 'AllMunten'	:
              await SqlFunction.DoSql('ChgMunten',data);
              break;

            case 'AllLanden'	:
              await SqlFunction.DoSql('ChgLanden',data);
              break;

            case 'AllBetalingsvoorwaarden':
              await SqlFunction.DoSql('ChgBetalingsvoorwaarden',data);
              break;

            case 'AllFuncties':
              await SqlFunction.DoSql('ChgFuncties',data);
              break;

            case 'AllBtwRegimes':
              await SqlFunction.DoSql('ChgBtwRegimes',data);
              break;

            case 'AllFactZendwijzen':
              await SqlFunction.DoSql('ChgFactZendwijzen',data);
              break;  

            case 'AllRegOms'	:
              await SqlFunction.DoSql('ChgRegOms',data);
              break;

            case 'AllRegTar'	:
              await SqlFunction.DoSql('ChgRegTar',data);
              break;

            case 'AllPostcodes'	:
              await SqlFunction.DoSql('ChgZipcode',data);
              break;

            case 'AllRekeningen' :
              await SqlFunction.DoSql('ChgRekening',data);
              break;


            case 'AllAansprekingen':
              await SqlFunction.DoSql('ChgAansprekingen', data);
              break;

            case 'AllJobStatus':
              await SqlFunction.DoSql('ChgJobStatus', data);
              break;


            default:    
              break;
          }               

        }
      }
  }                      


  CheckSave(params,value)
  {
    let items = []
    let terug = true
    let waarde = value;
    let colId = null
    if (gridapi)
    {
      gridapi.api.forEachNode(function(node) { 
        items.push(node.data);
      });
    }

    switch (this.props.settings.security_title) 
    {
      case 'AllSecPar'	:
        waarde = params.props.data['name']
        colId = 'name'
        terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;
        break;
      case 'AllMunten'	:
        if (!value)
        {
          waarde = params.props.data['code']
        }
        colId = 'code'
        terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;
        break;

      case 'AllFuncties':
        waarde = params.props.data['omschrijving']
        colId = 'omschrijving'
        terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        break;

      case 'AllRegOms'	:
        waarde = params.props.data['description']
        colId = 'description'
        terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;
        break;

      case 'AllTaalcodes'	:
        waarde = params.props.data['code']
        colId = 'code'
        terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;
        if (terug === true)
        {
          waarde = params.props.data['omschrijving']
          colId = 'omschrijving'
          terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;
        }  
        break;

      case 'AllJobStatus':
        waarde = params.props.data['code']
        colId = 'code'
        terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        if (terug === true) {
          waarde = params.props.data['description']
          colId = 'description'
          terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        }
        break;

      case 'AllFactZendwijzen':
        waarde = params.props.data['code']
        colId = 'code'
        terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;
        break;

      case 'AllBetalingsvoorwaarden':
        waarde = params.props.data['omschrijving']
        colId = 'omschrijving'
        terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        if (terug === true) {
          waarde = params.props.data['startdatum']
          colId = 'startdatum'
          terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        }
        if (terug === true) {
          waarde = params.props.data['dagen']
          colId = 'dagen'
          terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        }

        break;

      case 'AllBtwRegimes':
        waarde = params.props.data['omschrijving']
        colId = 'omschrijving'
        terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        if (terug === true) {
          waarde = params.props.data['code']
          colId = 'code'
          terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        }
        if (terug === true) {
          waarde = params.props.data['btw_percentage']
          colId = 'btw_percentage'
          terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        }
        if (terug === true) {
          waarde = params.props.data['vermelding']
          colId = 'vermelding'
          terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        }
        break;

      case 'AllAansprekingen':
        waarde = params.props.data['code']
        colId = 'code'
        terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        if (terug === true) {
          waarde = params.props.data['omschrijving']
          colId = 'omschrijving'
          terug = !Validation.ValidCheck(this.props.settings.security_title, waarde, colId, params, gridapi);
        }

        break;

      case 'AllRegTar'	:
        waarde = params.props.data['registratie']
        colId = 'registratie'
        terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;
        if (terug === true)
        {
          waarde = params.props.data['land']
          colId = 'land'
          terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;        
        }
        if (terug === true)
        {
          waarde = params.props.data['periode']
          colId = 'periode'
          terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;        
        }

        break;
       

      case 'AllPostcodes'	:
        waarde = params.props.data['country']
        colId = 'country'
        terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;
        if (terug === true)
        {
          waarde = params.props.data['zipcode']
          colId = 'zipcode'
          terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;        
        }
        if (terug === true)
        {
          waarde = params.props.data['place']
          colId = 'place'
          terug = !Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;        
        }
        break;

      default:    
        break;
    }        
    return terug;
  }

  ValueChanged(params) {
  }                        
  async ValueTempCheck(that,waarde,type,colId,verplicht) 
  {
    let template_txt = this.state.template_txt;
    let temp_id = this.state.temp_id;
    if (waarde.id && waarde.id.length > 3)
    {
      template_txt = waarde.value;
      temp_id = waarde.id;
    }
    else
    {
      template_txt = ''
      temp_id = ''
    }
    this.setState({template_txt:template_txt, temp_id:temp_id})
  }       

  ValueCheck(params,waarde) {
    var colId = params.props.column.getId();
    params.props.data.save = true;
    let terug = Validation.ValidCheck(this.props.settings.security_title,waarde,colId,params,gridapi) ;

    params.props.data.save = this.CheckSave(params,waarde);

    params.setState({error:terug})
  }       

  render() {
    const { classes } = this.props;

    var {tableheight} = this.state;
    var toevoegen = false;


    let vld_exportex = (<form id={docformid} action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target={seeformid} style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue={this.state.exesql} id="function" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.state.conditie} id="conditie" name="conditie"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid"/>
        </form>);  


   if (this.props.settings && (this.props.settings.security_title === 'AllUsers'   || this.props.settings.security_title === 'AllAccounts'   || this.props.settings.security_title === 'AllContacts' ||    
    this.props.settings.security_title === 'AllKantContacts' || this.props.settings.security_title === 'AllJobDefMain' || this.props.settings.security_title === 'AllVatRefCode' ||  this.props.settings.security_title === 'AllBetaalFin' || 
    this.props.settings.security_title === 'AllKantoren' || this.props.settings.security_title === 'AllLanden' || this.props.settings.security_title === 'AllSuppliers' || 
    this.props.settings.security_title === 'AllTaalcodes' || this.props.settings.security_title === 'AllTemplates'|| this.props.settings.security_title === 'AllFacturen'
     || this.props.settings.security_title === 'AllRegistratie'  || this.props.settings.security_title === 'AllJobs'))
    {
      toevoegen = true
    }     
    return (
      <div>
        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
          <Grid container>
            <Grid item xs={6}>          
              <h4 tag="h4" className="mt-3" >{this.props.settings.main_title}</h4>  
            </Grid>
            <Grid item xs={6}>  
              {this.props.settings.security_title === 'AllJobs' && this.props.match && this.props.match.path && this.props.match.path !== '/jobdivers' &&
              <div style = {{width:'60%',marginTop:'-20px'}}>
                <EditAutoSelect className="card-title m-t-10"
                  value={this.state.template_txt}
                  labelText="Template"
                  lijst='AllTemplates'
                  colId="template_txt"
                  selectcel='temp_id'
                  id={this.state.temp_id}
                  ValueCheck={(params,value,type) => this.ValueTempCheck(params,value,type,"temp_id",true)}
                />
              </div>
              }

            </Grid>
          </Grid>
        </Toolbar>      
        {toevoegen && GenFunc.allowed(this.props.user.userData.secrows,this.props.settings.security_title,'edit') &&
          <SpeedDial
            style ={{right: '20px', top: '65px'}}
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                onClick={(event) => {this.ActionClick(action.name,event)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
          </SpeedDial>          
        }

        {this.props.settings.security_title === 'AllTimereg' &&
          <SpeedDial
            style ={{right: '20px', top: '65px'}}
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactionsexc.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                onClick={(event) => {this.ActionClick(action.name,event)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
          </SpeedDial>          
        }



        <Grid container style={{width: 'calc(100% - 27px)', padding: '54px 0px', marginLeft: '-27px'}}>
            <Card className='card_brut' style={{width: 'calc(100% - 10px)', position:'absolute'}}>
              <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                <Dropzone ref={dropzoneRefContr} onDrop={this.onDropContr} noClick ={true} noKeyboard={true}>
                  {({getRootProps, getInputProps}) => (
                    <section className="container" style={{width:'100%',maxWidth:'100%'}}>
                      <div {...getRootProps({className: 'dropzone'})}>
                        <input {...getInputProps()} />
                        <button style={{display: 'none'}} id={docdropidcontr} type="button" onClick={openDialogContr}></button>         
                      </div>
                    </section>
                  )}
                </Dropzone>                      

                <Grid container>
                  <Grid item xs={12}>          
                    { this.props.settings && this.props.settings.listmaindata && this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey] && 
                      this.props.settings.security_title && this.props.settings.listmaindata.infinite === true && !this.state.showLoadingGif &&                     
                      <TableGrid
                        exportExcel = {false}
                        tableheight={tableheight} 
                        columns={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['kol']}
                        data={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['data']} 
                        CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                        CellValueChanged={(params,waarde,object) => this.ValueChanged(params)}
                        CellValueCheck={(params,value,error) => this.ValueCheck(params,value,error)}
                        SaveAll={(params) => this.SaveAll(params)}
                        sortable={this.props.settings.listmaindata.sortable}
                        sizetofit={this.props.settings.listmaindata.sizetofit}
                        suppressMenu={this.props.settings.listmaindata.suppressMenu}
                        enableRowGroup={this.props.settings.listmaindata.enableRowGroup}
                        headerComponentParams = {this.props.settings.listmaindata.headerComponentParams && this.props.settings.listmaindata.headerComponentParams.length > 2 ?{
                          template: this.props.settings.listmaindata.headerComponentParams} : null}
                        suppressColumnVirtualisation={this.props.settings.listmaindata.suppressColumnVirtualisation}
                        filter={this.props.settings.listmaindata.filter}
                        editable={this.props.settings.listmaindata.editable}
                        pinnedTopRowData={this.props.settings.listmaindata.pinnedTopRowData ? [GenFunc.zetdataleeg(this.props.settings.listmaindata.referentie)] : null }
                        GridReady={this.props.settings.listmaindata.GridReady ? this.onGridReady : this.onGridReady}
                        sleutel={this.props.settings.listmaindata.sleutel ? this.props.settings.listmaindata.sleutel : this.props.settings.listmaindata.referentie}    
                        mainprops={this}       
                        infinite= {true}          
                        conditie= {this.props.settings.listmaindata.conditie}          
                        funktie= {this.props.settings.listmaindata.funktie}          

                      />
                    }
                    { this.props.settings && this.props.settings.listmaindata && this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey] && 
                      this.props.settings.security_title && !this.props.settings.listmaindata.infinite === true && !this.state.showLoadingGif &&                     
                      <TableGrid
                        exportExcel = {true}
                        tableheight={tableheight} 
                        columns={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['kol']}
                        data={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['data']} 
                        CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                        CellValueChanged={(params,waarde,object) => this.ValueChanged(params)}
                        CellValueCheck={(params,value,error) => this.ValueCheck(params,value,error)}
                        SaveAll={(params) => this.SaveAll(params)}
                        sortable={this.props.settings.listmaindata.sortable}
                        sizetofit={this.props.settings.listmaindata.sizetofit}
                        suppressMenu={this.props.settings.listmaindata.suppressMenu}
                        enableRowGroup={this.props.settings.listmaindata.enableRowGroup}
                        headerComponentParams = {this.props.settings.listmaindata.headerComponentParams && this.props.settings.listmaindata.headerComponentParams.length > 2 ?{
                          template: this.props.settings.listmaindata.headerComponentParams} : null}
                        suppressColumnVirtualisation={this.props.settings.listmaindata.suppressColumnVirtualisation}
                        filter={this.props.settings.listmaindata.filter}
                        editable={this.props.settings.listmaindata.editable}
                        pinnedTopRowData={this.props.settings.listmaindata.pinnedTopRowData ? [GenFunc.zetdataleeg(this.props.settings.listmaindata.referentie)] : null }
                        GridReady={this.props.settings.listmaindata.GridReady ? this.onGridReady : this.onGridReady}
                        sleutel={this.props.settings.listmaindata.sleutel ? this.props.settings.listmaindata.sleutel : this.props.settings.listmaindata.referentie}    
                        mainprops={this}       
                      />
                    }

                    {this.state.showLoadingGif &&
                      <div style={{ textAlign: "center", verticalAlign: "center", lineHeight: Math.floor(window.innerHeight * .85) + 'px' }}>
                        <img alt='' src={LoaderGif} />
                      </div>
                    }
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
            <Grid container spacing={1}>
              <Grid item sm={12} md={12} lg={12} style={{background: 'white',height: '53vh', overflow: 'auto',paddingLeft: '10px',paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px'}}>
                <iframe name={seeformid} id={seeformid} title={seeformid} style={{width: '100%', height:'100%'}} frameborder="0"     > </iframe>
              </Grid>
            </Grid>

        </Grid>
        {vld_exportex}

      </div>
    );
  }
}


export default connect(mapStateToProps)(withStyles(styles)(LstMain));
