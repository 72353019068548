import {
  ALLJOBSUBJ_SUCCESS, ALLJOBS_SUCCESS, ALLJOBDEFMAIN_SUCCESS,ALLJOBDEFMAININDEX_SUCCESS,ALLJOBDEFMAINACC_SUCCESS, JOBMAIN_SUCCESS, GETSUBJOBMAIN_SUCCESS, GETSUBJOBTARIEF_SUCCESS,
  GETJOBDEFWORKFLOW_SUCCESS, ALLJOBSTATUS_SUCCESS, ALLJOBLAYOUT_SUCCESS, ALLVELDEN_SUCCESS, ALLJOBSTOINVOICE_SUCCESS,ALLJOBSTOINVOICEACCOUNT_SUCCESS, ALLREGISTRATIEACC_SUCCESS,
  JOBDEFMAINALLCODES_SUCCESS, ALLBETAALFIN_SUCCESS, BETAALFIN_SUCCESS,GETSUBBETAALFIN_SUCCESS, ALLVATREFCODE_SUCCESS,ALLVATREFCODELAND_SUCCESS,VATREFCODE_SUCCESS, 
  GETSUBVATREFCODE_SUCCESS, ALLJOBREGNUMACC_SUCCESS, ALLJOBSNEW_SUCCESS, JOBDEFLISTCODES_SUCCESS} from '../constants/';

const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');
const redux = require('redux');
const thunk = require('redux-thunk').default;



export const AllJobsNewSuccess = (payload) => {
  if (payload)
  {
    if (payload.response)
    {
      return {
        type: ALLJOBSNEW_SUCCESS,
        payload: payload.response.data
      }
    }
    else
    {
      return {
        type: ALLJOBSNEW_SUCCESS,
        payload: payload.data
      }

    }
  }
  else
  {
     return {}
  }
}



export const AllBetaalFinSuccess = (payload) => {
  return {
    type: ALLBETAALFIN_SUCCESS,
    payload: payload.response.data
  }
}


export const AllJobDefMainSuccess = (payload) => {
  return {
    type: ALLJOBDEFMAIN_SUCCESS,
    payload: payload.response.data
  }
}

export const AllJobDefMainIndexSuccess = (payload) => {
  return {
    type: ALLJOBDEFMAININDEX_SUCCESS,
    payload: payload.response.data
  }
}


export const AllJobDefMainAccSuccess = (payload) => {
  return {
    type: ALLJOBDEFMAINACC_SUCCESS,
    payload: payload.response.data
  }
}

export const AllRegistratieAccSuccess = (payload) => {
  return {
    type: ALLREGISTRATIEACC_SUCCESS,
    payload: payload.response.data
  }
}

export const AllJobRegNumAccSuccess = (payload) => {
  return {
    type: ALLJOBREGNUMACC_SUCCESS,
    payload: payload.response.data
  }
}




export const AllVatRefCodeSuccess = (payload) => {
  return {
    type: ALLVATREFCODE_SUCCESS,
    payload: payload.response.data
  }
}

export const AllVatRefCodeLandSuccess = (payload) => {
  return {
    type: ALLVATREFCODELAND_SUCCESS,
    payload: payload.response.data
  }
}



export const JobDefMainAllCodesSuccess = (payload) => {
  return {
    type: JOBDEFMAINALLCODES_SUCCESS,
    payload: payload.response.data
  }
}

export const JobDefListCodesSuccess = (payload) => {
  return {
    type: JOBDEFLISTCODES_SUCCESS,
    payload: payload.response.data
  }
}

export const AllJobsSuccess = (payload) => {
  return {
    type: ALLJOBS_SUCCESS,
    payload: payload.response.data
  }
}

export const GetsubJobMainSuccess = (payload) => {
  return {
    type: GETSUBJOBMAIN_SUCCESS,
    payload: payload.response.data
  }
}

export const GetSubVatRefCodeSuccess = (payload) => {
  return {
    type: GETSUBVATREFCODE_SUCCESS,
    payload: payload.response.data
  }
}



export const GetsubBetaalFinSuccess = (payload) => {
  return {
    type: GETSUBBETAALFIN_SUCCESS,
    payload: payload.response.data
  }
}


export const GetsubJobTariefSuccess = (payload) => {
  return {
    type: GETSUBJOBTARIEF_SUCCESS,
    payload: payload.response.data
  }
}

export const GetJobDefWorkflowSuccess = (payload) => {
  return {
    type: GETJOBDEFWORKFLOW_SUCCESS,
    payload: payload.response.data
  }
}

export const GetBetaalFinSuccess = (payload) => {

  return {
    type: BETAALFIN_SUCCESS,
    payload: payload.response.data
  }
}

export const GetJobMainSuccess = (payload) => {

  return {
    type: JOBMAIN_SUCCESS,
    payload: payload.response.data
  }
}

export const GetVatrefCodeSuccess = (payload) => {

  return {
    type: VATREFCODE_SUCCESS,
    payload: payload.response.data
  }
}


export const AllJobSubjSuccess = (payload) => {
  return {
    type: ALLJOBSUBJ_SUCCESS,
    payload: payload.response.data
  }
}

export const AllJobStatusSuccess = (payload) => {
  return {
    type: ALLJOBSTATUS_SUCCESS,
    payload: payload.response.data
  }
}

export const AllVeldenSuccess = (payload) => {
  return {
    type: ALLVELDEN_SUCCESS,
    payload: payload.response.data
  }
}

export const AllJobLayoutSuccess = (payload) => {
  return {
    type: ALLJOBLAYOUT_SUCCESS,
    payload: payload.response.data
  }
}

export const AllJobsToInvoiceAccountSuccess = (payload) => {
  return {
    type: ALLJOBSTOINVOICEACCOUNT_SUCCESS,
    payload: payload.response.data
  }
}

export const AllJobsToInvoiceSuccess = (payload) => {
  return {
    type: ALLJOBSTOINVOICE_SUCCESS,
    payload: payload.response.data
  }
}


export function AllBetaalFin(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllBetaalFin', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllBetaalFinSuccess({ response }))
        }
      },
      () => dispatch(AllBetaalFinSuccess(null))
    );
  }
}

export function CreateBetaalfin() {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, referentie: '', datum: '', afgewerkt: '0'}
    response.data = { data: data }
    dispatch(GetBetaalFinSuccess({ response }));
  }
}

export function GetBetaalFin(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetBetaalFin', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetBetaalFinSuccess({ response })) },
      () => { dispatch(GetBetaalFinSuccess(null)) }
    );
  }
}



export function AllJobDefMain(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobDefMain', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllJobDefMainSuccess({ response }))
        }
      },
      () => dispatch(AllJobDefMainSuccess(null))
    );
  }
}

export function AllJobDefMainIndex(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobDefMainIndex', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllJobDefMainIndexSuccess({ response }))
        }
      },
      () => dispatch(AllJobDefMainIndexSuccess(null))
    );
  }
}


export function AllJobDefMainAcc(props,id, country, registration_id) {
  return function action(dispatch) {
    let regid = registration_id ? registration_id : '0';
    let authOptions = SqlFunction.SetAuth('AllJobDefMainAcc', {id:id,country:country, registration_id:regid});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllJobDefMainAccSuccess({ response }))
        }
      },
      () => dispatch(AllJobDefMainAccSuccess(null))
    );
  }
}

export function AllJobDefMainDiv(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobDefMainDiv', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllJobDefMainAccSuccess({ response }))
        }
      },
      () => dispatch(AllJobDefMainAccSuccess(null))
    );
  }
}



export function AllJobDefMainType(props,id, country) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobDefMainType', {id:id,country:country});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllJobDefMainAccSuccess({ response }))
        }
      },
      () => dispatch(AllJobDefMainAccSuccess(null))
    );
  }
}

export function AllJobRegNumAcc(props,id, country) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobRegNumAcc', {id:id,country:country});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllJobRegNumAccSuccess({ response }))
        }
      },
      () => dispatch(AllJobRegNumAccSuccess(null))
    );
  }
}






export function AllRegistratieAcc(props,id, country, type) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllRegistratieAcc', {id:id,country:country,type:type});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllRegistratieAccSuccess({ response }))
        }
      },
      () => dispatch(AllRegistratieAccSuccess(null))
    );
  }
}





export function JobDefMainAllCodes(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('JobDefMainAllCodes', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(JobDefMainAllCodesSuccess({ response }))
        }
      },
      () => dispatch(JobDefMainAllCodesSuccess(null))
    );
  }
}

export function JobDefListCodes(language) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('JobDefList', { language: language });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(JobDefListCodesSuccess({ response })) },
      () => { dispatch(JobDefListCodesSuccess(null)) }
    );
  }
}

export function AllVatRefCode(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllVatRefCode', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllVatRefCodeSuccess({ response }))
        }
      },
      () => dispatch(AllVatRefCodeSuccess(null))
    );
  }
}





export function AllJobSubj(props) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobSubj', {});
    const request = axios(authOptions);
    return request.then(
      response => {
        if (response.data && response.data.allowed === false) {
          props.history.push('/authentication/login');
        }
        else {
          dispatch(AllJobSubjSuccess({ response }))
        }
      },
      () => dispatch(AllJobSubjSuccess(null))
    );
  }
}

export function AllVatRefCodeLand(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllVatRefCodeLand', {country:id});
    const request = axios(authOptions);
    return request.then(
      response => {
          dispatch(AllVatRefCodeLandSuccess({ response }))
      },
      () => dispatch(AllVatRefCodeLandSuccess(null))
    );
  }
}

export function GetJobMain(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetJobDefMain', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetJobMainSuccess({ response })) },
      () => { dispatch(GetJobMainSuccess(null)) }
    );
  }
}

export function AllJobsToInvoiceAccount(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobsToInvoiceAccount', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllJobsToInvoiceAccountSuccess({ response })) },
      () => { dispatch(AllJobsToInvoiceAccountSuccess(null)) }
    );
  }
}

export function AllJobsToInvoice() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobsToInvoice', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllJobsToInvoiceSuccess({ response })) },
      () => { dispatch(AllJobsToInvoiceSuccess(null)) },
    );
  }
}

export function AllJobs() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobs', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllJobsSuccess({ response })) },
      () => { dispatch(AllJobsSuccess(null)) },
    );
  }
}

export function GetAllJobs(props,path,van,data,filter) {
  return function action(dispatch) {
    let zdata = {}
    if (path === '/jobdirreg')
    {
      zdata = {soort:'1',from:1, end:1};
    }
    if (path === '/jobaccijnz')
    {
      zdata = {soort:'2',from:1, end:1};
    }
    if (path === '/jobvatref')
    {
      zdata = {soort:'3',from:1, end:1};
    }
    if (path === '/jobdivers')
    {
      zdata = {soort:'4',from:1, end:1};
    }



    if (van)
    {
      zdata.from = van;
    }
    else
    {
      van = 0;
    }
    zdata.filter = filter;
    let maindata = [];
    if (data) {maindata = data}
    if (van === 0) {maindata = []}

    let authOptions = SqlFunction.SetAuth('GetAllJobsNew', zdata);
    const request = axios(authOptions);
    return request.then(
      response => { 
        dispatch(AllJobsNewSuccess({ response }))

    },

      () => { dispatch(AllJobsNewSuccess(null)) },
    );
  }
}

export const GetAllJobsPrev = (props,path,response) => async (dispatch) => {
  let zdata = {}
  if (path === '/jobdirreg')
  {
    zdata = {soort:'1'};
  }
  if (path === '/jobaccijnz')
  {
    zdata = {soort:'2'};
  }
  if (path === '/jobvatref')
  {
    zdata = {soort:'3'};
  }
    if (path === '/jobdivers')
    {
      zdata = {soort:'4'};
    }

  var mintelid = 1000
  var maxid = 0
  let maindata = await SqlFunction.DoSql('GetAllJobsNew', zdata);
  let response = {data:maindata}
  dispatch(AllJobsNewSuccess({ response }))
  if (maindata && maindata.data)
  {
      zdata.mintelid = maindata.mintelid;
      zdata.maxid = maindata.maxid;
      maxid = parseInt(zdata.maxid)
  }

  let newdata = maindata.data;
  for (mintelid; mintelid < maxid; mintelid += 1000) 
  { 
    zdata.mintelid = mintelid;
    zdata.maxid = maxid;
    let retdata = await SqlFunction.DoSql('GetAllJobsMax', zdata);
    if (retdata && retdata.data)
    {
      for (var i = 0; i < retdata.data.length; i++) 
      { 
        let rij = retdata.data[i];      
        newdata.push(rij);
      }
    }
  }
  maindata.data = newdata;
  response = {data:maindata}
  dispatch(AllJobsNewSuccess(response));
}


export function CreateVatrefcode() {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, country: '', code: '', country_txt: ''}
    response.data = { data: data }
    dispatch(GetVatrefCodeSuccess({ response }));
  }
}

export function GetVatRefCode(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetVatRefCode', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetVatrefCodeSuccess({ response })) },
      () => { dispatch(GetVatrefCodeSuccess(null)) }
    );
  }
}




export function CreateJobMain() {
  return function action(dispatch) {
    let response = { data: null }
    let data = { id: 0, subject: '', code: '', description: '', boekhoud: '', subjectcode: '', subject_txt: '' }
    response.data = { data: data }
    dispatch(GetJobMainSuccess({ response }));
  }
}

export function GetsubJobMain(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetsubJobMain', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetsubJobMainSuccess({ response })) },
      () => { dispatch(GetsubJobMainSuccess(null)) }
    );
  }
}

export function GetSubVatRefCode(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetSubVatRefCode', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetSubVatRefCodeSuccess({ response })) },
      () => { dispatch(GetSubVatRefCodeSuccess(null)) }
    );
  }
}




export function GetsubBetaalFin(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetSubBetaalFin', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetsubBetaalFinSuccess({ response })) },
      () => { dispatch(GetsubBetaalFinSuccess(null)) }
    );
  }
}



export function GetsubJobTarief(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetJobTarieven', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetsubJobTariefSuccess({ response })) },
      () => { dispatch(GetsubJobTariefSuccess(null)) }
    );
  }
}

export function GetJobDefWorkflow(id) {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('GetJobDefWorkflow', { id: id });
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(GetJobDefWorkflowSuccess({ response })) },
      () => { dispatch(GetJobDefWorkflowSuccess(null)) }
    );
  }
}

export function AllJobStatus() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobStatus', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllJobStatusSuccess({ response })) },
      () => { dispatch(AllJobStatusSuccess(null)) },
    );
  }
}

export function AllJobLayout() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllJobsLayout', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllJobLayoutSuccess({ response })) },
      () => { dispatch(AllJobLayoutSuccess(null)) },
    );
  }
}

export function AllVelden() {
  return function action(dispatch) {
    let authOptions = SqlFunction.SetAuth('AllVelden', {});
    const request = axios(authOptions);
    return request.then(
      response => { dispatch(AllVeldenSuccess({ response })) },
      () => { dispatch(AllVeldenSuccess(null)) },
    );
  }
}
