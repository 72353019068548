import GenFunc from "./gen_functions.js";
import moment from 'moment'

export default {
  StrReplace: function (name,archar)  
  {
     var str_torplace = name;
     if (archar && archar.length > 0)
     {
        for (var i = 0; i < archar.length; i++) 
        { 
          var repl_char = archar[i];
          var newar = str_torplace.split(archar[i]);  
          var newstring = '';        

          for (var ni = 0; ni < newar.length; ni++) 
          { 
            newstring = newstring + newar[ni];
          }
          str_torplace = newstring
        }

     }
     return str_torplace

  },
  StrBevat: function (name,archar)  
  {
    let vname = this.StrReplace(name.toUpperCase(),['-','.',' ']);
    let vchar = this.StrReplace(archar.toUpperCase(),['-','.',' ']);
    if (vname.includes(vchar))
    {
      return true;  
    }
  },


  ValidCheck: function (title,waarde,colId,params,gridapi,data,extra)  
  {

    let items = []
    let terug = false;
    let result = [];
    let code = null;
    var utc = null;
    var i = 0;
    var datarr = null;
    let gevonden = false;
    switch (title) 
    {
      case 'AllSecPar':
        switch (colId) 
        {
          case 'name':
            if (gridapi)
            {
              gridapi.api.forEachNode(function(node) { 
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.name === waarde);
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (params.props.data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    if (params.props.data.id !== result[0].id)
                    {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          default:    
            break;
        }    
        break;

      case 'AllZipcode':
        switch (colId) 
        {
          case 'place':
          case 'zipcode':
          case 'country_txt':
          case 'country':

            if (gridapi)
            {
              items = gridapi;
            }
            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.zipcode === data.zipcode && o.place === data.place && o.country === data.country && o.id !== 0 );
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {

                  terug = true;
                }
              }
            }
            break;
          default:    
            break;
        }    
        break;


      case 'AllVatRefCode':
        switch (colId) 
        {
          case 'country_txt':
          case 'country':
          case 'code':

            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (gridapi)
            {
              items = gridapi;
            }
            if (terug === false)
            {
              result = 0;
              if (data)
              {
                result = items.filter(o=> o.code === data.code && o.country === data.country );
              }
              if (params.props && params.props.data)
              {
                result = items.filter(o=> o.code === params.props.data.code && o.country === params.props.data.country );
              }

              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (data)
                  {
                    if (data.id === 0) 
                    {
                      terug = true;
                    }
                    else
                    {
                      if (data.id !== result[0].id)
                      {
                        terug = true
                        data.save = false;
                      }
                    }

                  }
                  if (params.props && params.props.data)
                  {
                    if (params.props.data.id === 0) 
                    {
                      terug = true;
                    }
                    else
                    {
                      if (params.props.data.id !== result[0].id)
                      {
                        terug = true
                        params.props.data.save = false;
                      }
                    }
                  }
                }
              }
            }
            break;
          default:    
            break;
        }    
        break;

   
      case 'AllJobDefMain':
        switch (colId) 
        {
          case 'subject_txt':
          case 'subject':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          case 'code':
          case 'description':
            if (gridapi)
            {
              gridapi.api.forEachNode(function(node) { 
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.code === params.props.data.code || o.description === params.props.data.description );
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (params.props.data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    if (params.props.data.id !== result[0].id)
                    {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          default:   
            for (let i = 0; i < extra.length; i++) {
              if (colId === (extra[i].omschrijving).toLowerCase()) {
                terug = waarde ? (waarde.length < 2 ? true : false) : true;
              }
            }
            break;
        }    
        break;

        case 'AllJobStatus':
          switch (colId) 
          {
            case 'code':
            case 'description':
              if (gridapi)
              {
                gridapi.api.forEachNode(function(node) { 
                  items.push(node.data);
                });
              }
              terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
              if (terug === false)
              {
                result = items.filter(o=> o.code === params.props.data.code || o.description === params.props.data.description );
                if (Array.isArray(result))
                {
                  if (result.length > 0)
                  {
                    if (params.props.data.id === 0) 
                    {
                      terug = true;
                    }
                    else
                    {
                      if (params.props.data.id !== result[0].id)
                      {
                        terug = true
                        params.props.data.save = false;
                      }
                    }
                  }
                }
              }
              break;
            default:    
              break;
          }    
          break;  

      case 'AccountTarief':
        switch (colId) 
        {
          case 'jaar':
            if (waarde < 2010 || waarde > 2100)
            {
              terug = true
            }
            break;

          case 'description':
            code = params.props.data.jobid
            terug = code ? (code.length < 2 ? true : false) : true;
            break;

          case 'tarief':
            terug = waarde ? (waarde.length < 1 ? true : false) : true;
            break;

          default:    
            break;
        }    
        break;
      

      case 'SubJobMain':
        switch (colId) 
        {

          case 'country_txt':
          case 'country':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          default:    
            break;
        }    
        break;

      case 'Volmachten':
        switch(colId) {
          case 'omschrijving':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          default:
            return;
        }
        break;
      
      case 'Onkosten':
        switch (colId) {

          case 'description':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          case 'vereenv':
          case 'dirreg':

            if (waarde)
            {
              if (waarde > 100)
              {
                terug = true;
              }
              else if (waarde < 0)
              {
                terug = true;
              }
              else{
                terug = false;
              }
            }
            else if (waarde === 0)
              {
                terug = false;
              }
            else{
              terug = true;
            }
            break;
            
          default:
            break;
        }
        break;

      case 'LandenTaalcodes':
          terug = waarde ? (waarde.length < 2 ? true : false) : true;
        break;

      case 'AllTemplates':
        switch (colId) {
          case 'description':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = gridapi.filter(o => o.description.toUpperCase() === data.description.toUpperCase());
            if (Array.isArray(result)) {
              if (result.length > 0) {
                if (data.id === 0) {
                  terug = true;
                }
                else {
                  if (result.length > 1) {
                    terug = true
                  }
                  else {
                    if (data.id !== result[0].id) {
                      terug = true
                    }
                  }
                }
              }
            }
            break;

          default:
            break;
        }
        break;
      

      case 'IvatCode':
        switch (colId) {
          case 'code':
            terug = waarde ? (waarde.length < 1 ? true : false) : true;
            result = gridapi.filter(o => o.code.toUpperCase() === data.code.toUpperCase());
            if (Array.isArray(result)) {
              if (result.length > 0) {
                if (data.id === 0) {
                  terug = true;
                }
                else {
                  if (result.length > 1) {
                    terug = true
                  }
                  else {
                    if (data.id !== result[0].id) {
                      terug = true
                    }
                  }
                }
              }
            }
            break;

          default:
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;
        }
        break;


      case 'Land':
        switch (colId) {
          case 'name':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = gridapi.filter(o => o.name.toUpperCase() === data.name.toUpperCase());
            if (Array.isArray(result)) {
              if (result.length > 0) {
                if (data.id === 0) {
                  terug = true;
                }
                else {
                  if (result.length > 1) {
                    terug = true
                  }
                  else {
                    if (data.id !== result[0].id) {
                      terug = true
                    }
                  }
                }
              }
            }
            break;

          default:
            for(let i = 0; i < extra.length; i++){
              if(colId === (extra[i].omschrijving).toLowerCase()){
                terug = waarde ? (waarde.length < 2 ? true : false) : true; 
              }
            }
            break;
        }
        break;
      
      case 'Taalcode':
        let split = colId.split("_");
        let datatype = split[0];
        let col = split[1];
        let index = split[2];
        switch(datatype){
          case 'taalcode':
            if(col === 'omschrijving'){
              terug = waarde ? (waarde.length < 2 ? true : false) : true; 
            }
            break;

          case 'factuuropbouw':
            let factarray = Object.keys(data)
            for(let i = 0; i < factarray.length; i++){
              if(col === factarray[i]){
                terug = waarde ? (waarde.length < 2 ? true : false) : true;
              }
            }
            break;

          case 'landen':
            if(col === data[parseInt(index)]["name"]){
              terug = waarde ? (waarde.length < 2 ? true : false) : true;
            }
            break;

          case 'jobdefs':
            if (col === data[parseInt(index)]["code"]) {
              terug = waarde ? (waarde.length < 2 ? true : false) : true;
            }
            break;

          default:
            return;
        }
        break;

      case 'AllBetalingsvoorwaarden':
        switch (colId)
        {
          case 'omschrijving':
            if (gridapi)
            {
              gridapi.api.forEachNode(function(node) { 
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.omschrijving === waarde);
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (params.props.data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    if (params.props.data.id !== result[0].id)
                    {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;

          case 'startdatum_txt':
          case 'startdatum':
            code = params.props.data.startdatum
            terug = code ? (code.length < 2 ? true : false) : true;
            break;

          default:
            break;
        } 
        break; 

      case 'AllBtwRegimes':
        switch (colId) {
          case 'omschrijving':
            if (gridapi) {
              gridapi.api.forEachNode(function (node) {
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (terug === false) {
              result = items.filter(o => o.omschrijving === waarde);
              if (Array.isArray(result)) {
                if (result.length > 0) {
                  if (params.props.data.id === 0) {
                    terug = true;
                  }
                  else {
                    if (params.props.data.id !== result[0].id) {
                      terug = true;
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          case 'code':
            if (gridapi) {
              gridapi.api.forEachNode(function (node) {
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (terug === false) {
              result = items.filter(o => o.code === code);
              if (Array.isArray(result)) {
                if (result.length > 0) {
                  if (params.props.data.id === 0) {
                    terug = true;
                  }
                  else {
                    if (params.props.data.id !== result[0].id) {
                      terug = true;
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;

          case 'btw_percentages_txt':
          case 'btw_precentage':
            code = params.props.data.btw_percentage
            terug = code ? (code.length < 2 ? true : false) : true;
            break;

          default:
            break;
        }
        break; 

      case 'AllRegOms':
        switch (colId) 
        {
          case 'description':
            if (gridapi)
            {
              gridapi.api.forEachNode(function(node) { 
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.description === waarde);
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (params.props.data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    if (params.props.data.id !== result[0].id)
                    {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          default:    
            break;
        }    
        break;


      case 'VatRefund':

        switch (colId) 
        {
          case 'supplier_id':
          case 'supplier_txt':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;


          case 'factdatum':
            if (waarde)
            {
              code = waarde;
              terug = code ? (code.length < 10 ? true : false) : true;
              datarr = code.split("-");      
              let sd = code;
              if (datarr.length === 3)
              {
                if(datarr[0].length > 2)
                {
                  sd = datarr[2]+'-'+datarr[1]+'-'+datarr[0];
                }
                else
                {
                  sd = datarr[0]+'-'+datarr[1]+'-'+datarr[2];
                }
                utc = moment(sd, "DD-MM-YYYY", true)
                if (!utc.isValid()) {terug = true}        
              }
            }
            else
            {
                terug = true;
            }
            break;


          case 'factnum':
            if (extra)
            {
              items = extra
            }

            code = waarde
            terug = code ? (code.length < 1 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.factnum === waarde && o.supplier_id === data.supplier_id);
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    for (i = 0; i < result.length; i++) 
                    {
                      let resultdata = result[i];
                      if (data.id !== resultdata.mainid)
                      {
                        terug = true
                      }
                    }
                  }
                }
              }
            }
            break;
          default:    
            break;
        }    
        break;



      case 'AllJobOnkosten':
      case 'JobOnkost':

        switch (colId) 
        {
          case 'supplier_id':
          case 'supplier_txt':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          case 'type_onkost':
          case 'type_onkost_txt':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          case 'factdat':
            if (waarde)
            {
              code = waarde;
              terug = code ? (code.length < 10 ? true : false) : true;
              datarr = code.split("-");      
              let sd = code;
              if (datarr.length === 3)
              {
                if(datarr[0].length > 2)
                {
                  sd = datarr[2]+'-'+datarr[1]+'-'+datarr[0];
                }
                else
                {
                  sd = datarr[0]+'-'+datarr[1]+'-'+datarr[2];
                }
                utc = moment(sd, "DD-MM-YYYY", true)
                if (!utc.isValid()) {terug = true}        
              }
            }
            else
            {
                terug = true;
            }
            break;


          case 'factnum':
            if (extra)
            {
              items = extra
            }


            code = waarde
            terug = code ? (code.length < 1 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.factnum === waarde && o.supplier_id === data.supplier_id);
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    for (i = 0; i < result.length; i++) 
                    {
                      let resultdata = result[i];
                      if (data.id !== resultdata.id)
                      {
                        terug = true
                      }
                    }
                  }
                }
              }

            }
            break;
          default:    
            break;
        }    
        break;

      case 'JobBoetes':
      case 'JobDouanes':

        switch (colId) 
        {
          case 'nummerplaat':
            code = params.props.data.nummerplaat;
            terug = code ? (code.length < 2 ? true : false) : true;
            break;

          case 'datum':
            code = params.props.data.datum;
            terug = code ? (code.length < 10 ? true : false) : true;
            datarr = code.split("-");      
            let sd = code;
            if (datarr.length === 3)
            {
              if(datarr[0].length > 2)
              {
                sd = datarr[2]+'-'+datarr[1]+'-'+datarr[0];
              }
              else
              {
                sd = datarr[0]+'-'+datarr[1]+'-'+datarr[2];
              }
              utc = moment(sd, "DD-MM-YYYY", true)
              if (!utc.isValid()) {terug = true}        
            }
            break;

          default:    
            break;
        }    
        break;

 


      case 'AllRegTar':
        switch (colId) 
        {
          case 'registratie_txt':
          case 'registratie':
            code = params.props.data.registratie
            terug = code ? (code.length < 2 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.registratie === params.props.data.registratie && o.land === params.props.data.land);
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (params.props.data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    if (params.props.data.id !== result[0].id)
                    {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          case 'periode_txt':
          case 'periode':
            code = params.props.data.periode
            terug = code ? (code.length < 2 ? true : false) : true;
            break;


          case 'land_txt':
          case 'land':
            code = params.props.data.land
            terug = code ? (code.length < 2 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.registratie === params.props.data.registratie && o.land === params.props.data.land);
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (params.props.data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    if (params.props.data.id !== result[0].id)
                    {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          default:    
            break;
        }    
        break;

 
      case 'AllPostcodes':
        switch (colId) 
        {
          case 'country_txt':
            code = params.props.data.country
            terug = code ? (code.length < 2 ? true : false) : true;
            break;
          case 'country':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          case 'zipcode':
            if (gridapi)
            {
              gridapi.api.forEachNode(function(node) { 
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 3 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.zipcode === waarde && o.place === params.props.data.place);
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (params.props.data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    if (params.props.data.id !== result[0].id)
                    {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          case 'place':
            if (gridapi)
            {
              gridapi.api.forEachNode(function(node) { 
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 2 ? true : false) : true;
            if (terug === false)
            {
              result = items.filter(o=> o.place === waarde && o.zipcode === params.props.data.zipcode);
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (params.props.data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    if (params.props.data.id !== result[0].id)
                    {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;

          default:    
            break;
        }  
        params.props.data.save = !terug;
        break;


      case 'Gebruiker':
        switch (colId) 
        {
          case 'job_title':
            terug = waarde ? (waarde.length < 4 ? true : false) : true;
            break;

          case 'first_name':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = gridapi.filter(o=> o.first_name === data.first_name && o.last_name === data.last_name);
            if (Array.isArray(result))
            {
              if (result.length > 0)
              {
                if (data.id === 0) 
                {
                  terug = true;
                }
                else
                {
                  if (data.id !== result[0].id)
                  {
                    terug = true
                  }
                }
              }
            }
            break;

          case 'last_name':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = gridapi.filter(o=> o.first_name === data.first_name && o.last_name === data.last_name);
            if (Array.isArray(result))
            {
              if (result.length > 0)
              {
                if (data.id === 0) 
                {
                  terug = true;
                }
                else
                {
                  if (data.id !== result[0].id)
                  {
                    terug = true
                  }
                }
              }
            }
            break;

          case 'email':
            terug = waarde ? (waarde.length < 1 ? false : false) : false;
            if (waarde.length > 0)
            {
              terug = GenFunc.HandleChkMail(waarde);
            }
            break;

          case 'login':
            if (data.active === 'Y')
            {
              terug = waarde ? (waarde.length < 4 ? true : false) : true;
            }
            break;

          case 'paswoord':
            if (data.active === 'Y' && data.id === 0)
            {
              terug = waarde ? (waarde.length < 5 ? true : false) : true;
            }
            else
            {
              if (waarde && waarde.length > 0 )
              {
                terug = waarde ? (waarde.length < 5 ? true : false) : true;
              }
            }
            break;


          default:    
            break;
        }  
        break;


      case 'Klant':
      case 'Klantenfiche':


        switch (colId) 
        {
          case 'name':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = gridapi.filter(o=> o.name.toUpperCase() === data.name.toUpperCase());
            if (Array.isArray(result))
            {
              if (result.length > 0)
              {
                if (data.id === 0) 
                {
                  terug = true;
                }
                else
                {
                  if (result.length > 1)
                  {
                    terug = true
                  }
                  else
                  {
                    if (data.id !== result[0].id)
                    {
                      terug = true
                    }
                  }
                }
              }
            }
            break;

          case 'vatnumber':
            if(waarde.length > 0) 
            {
              result = gridapi.filter(o=> o.vatnumber.toUpperCase() === data.vatnumber.toUpperCase());
              if (Array.isArray(result))
              {
                if (result.length > 0)
                {
                  if (data.id === 0) 
                  {
                    terug = true;
                  }
                  else
                  {
                    if (data.id !== result[0].id)
                    {
                      terug = true
                    }
                  }
                }
              }
            }
            break;



          case 'number':
            terug = waarde ? (waarde.length < 1 ? true : false) : true;
            result = gridapi.filter(o=> o.number.toUpperCase() === data.number.toUpperCase());
            if (Array.isArray(result))
            {
              if (result.length > 0)
              {
                if (data.id === 0) 
                {
                  terug = true;
                }
                else
                {
                  if (data.id !== result[0].id)
                  {
                    terug = true
                  }
                }
              }
            }
            break;

          case 'email':
            terug = waarde ? (waarde.length < 1 ? false : false) : false;
            if (waarde.length > 0)
            {
              terug = GenFunc.HandleChkMail(waarde);
            }
            break;

          case 'enddate':
          case 'startdate':
            if(waarde.length > 0) 
            {
              utc = moment(waarde, "DD-MM-YYYY", true)
              if(!utc.isValid()) {terug = true}
            }
            break;
          
          case 'factenddate':
            if(waarde.length > 0) 
            {
              utc = moment(waarde, "DD-MM-YYYY", true)
              if(!utc.isValid()){
                terug = true
              } else {
                let pickeddate = new Date(waarde.split('-').reverse().join('-'));
                let todaysdate = new Date(extra.split('-').reverse().join('-'));
                if(pickeddate < todaysdate){
                  return true
                }
              }
            } else {
              terug = true;
            }
            break;
          
          case 'omschrijving':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          case 'tarief':
            terug = waarde ? (waarde.length < 1 ? true : false) : true;
            break;

          case 'user_name':
          case 'user':
          case 'vatreg':
          case 'vatreg_txt':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          case 'zipcode_txt':
          case 'zipcode':
          case 'country_txt':          
          case 'country':          
          case 'adres1':          
          case 'housenum':      
            if (extra === 0)
            {
              terug = waarde ? (waarde.length < 1 ? true : false) : true;
            }
            break;
          default:    
            break;
        }  
        break;


      case 'Registratie':
        switch (colId) 
        {
          case 'type':
          case 'type_txt':
          case 'accountid':
          case 'account_name':
          case 'land':
          case 'land_txt':
          case 'periodes':
          case 'periodes_txt':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          default:    
            break;
        }  
        break;



      case 'Contactpersoon kantoor':
        switch (colId) 
        {
          case 'first_name':
          case 'last_name':          
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = gridapi.filter(o=> o.first_name.toUpperCase() === data.first_name.toUpperCase() && o.last_name.toUpperCase() === data.last_name.toUpperCase() && o.parent_account === data.parent_account);
            if (Array.isArray(result))
            {
              if (result.length > 0)
              {
                if (data.id === 0) 
                {
                  terug = true;
                }
                else
                {
                  if (data.id !== result[0].id)
                  {
                    terug = true
                  }
                }
              }
            }
            break;
          case 'kantoor_name':
          case 'parent_account':
            terug = waarde ? (waarde.length < 4 ? true : false) : true;
            break;

          case 'e_mail':
            terug = waarde ? (waarde.length < 1 ? false : false) : false;
            if (waarde.length > 0)
            {
              terug = GenFunc.HandleChkMail(waarde);
            }
            break;

          default:    
            break;
        }  
        break;


      case 'Contactpersoon':
        switch (colId) 
        {
          case 'first_name':
          case 'last_name':          
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = gridapi.filter(o=> o.first_name.toUpperCase() === data.first_name.toUpperCase() && o.last_name.toUpperCase() === data.last_name.toUpperCase() && o.parent_account === data.parent_account);
            if (Array.isArray(result))
            {
              if (result.length > 0)
              {
                if (data.id === 0) 
                {
                  terug = true;
                }
                else
                {
                  if (data.id !== result[0].id)
                  {
                    terug = true
                  }
                }
              }
            }
            break;
          case 'account_name':
          case 'parent_account':
            terug = waarde ? (waarde.length < 4 ? true : false) : true;
            break;

          case 'e_mail':
            terug = waarde ? (waarde.length < 1 ? false : false) : false;
            if (waarde.length > 0)
            {
              terug = GenFunc.HandleChkMail(waarde);
            }
            break;

          default:    
            break;
        }  
        break;

      case 'Kantoor':
        switch (colId) {
          case 'name':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = gridapi.filter(o => o.name.toUpperCase() === data.name.toUpperCase());
            if (Array.isArray(result)) {
              if (result.length > 0) {
                if (data.id === 0) {
                  terug = true;
                }
                else {
                  if (result.length > 1) {
                    terug = true
                  }
                  else {
                    if (data.id !== result[0].id) {
                      terug = true
                    }
                  }
                }
              }
            }
            break;

          case 'type_reg_txt':
          case 'type_reg':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            break;

          case 'e_mail':
          case 'email2':
            terug = waarde ? (waarde.length < 1 ? false : false) : false;
            if (waarde.length > 0) {
              terug = GenFunc.HandleChkMail(waarde);
            }
            break;

          default:
            break;
        }
        break;

      case 'Leverancier':
        switch (colId) {
          case 'name':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = gridapi.filter(o => o.name.toUpperCase() === data.name.toUpperCase());
            if (Array.isArray(result)) {
              if (result.length > 0) {
                if (data.id === 0) {
                  terug = true;
                }
                else {
                  if (result.length > 1) {
                    terug = true
                  }
                  else {
                    if (data.id !== result[0].id) {
                      terug = true
                    }
                  }
                }
              }
            }
            break;

          case 'vatnumber':
            terug = waarde ? (waarde.length < 2 ? true : false) : true;
            result = [];
            gevonden = false;
            let vchar = this.StrReplace(data.vatnumber.toUpperCase(),['-','.',' ']).trim();
            for (var gi = 0; gi < gridapi.length; gi++) 
            { 
              let gridrow = gridapi[gi];
              let vname = this.StrReplace(gridrow.vatnumber.toUpperCase(),['-','.',' ']).trim();
              if (vname.includes(vchar) === true)
              {
                if (data.id === 0 || data.id !== gridrow.id)
                {
                  result =  gridrow;  
                  gevonden = true;
                  gi = gridapi.length + 100;
                }
              }
            }
            if (gevonden === true) {
              if (data.id === 0) 
              {
                terug = true;
              }
              else
              {
                if (data.id !== result.id) 
                {
                  terug = true
                }
              }
            }
            break;


          case 'e_mail':
            terug = waarde ? (waarde.length < 1 ? false : false) : false;
            if (waarde.length > 0) {
              terug = GenFunc.HandleChkMail(waarde);
            }
            break;

          default:
            break;
        }
        break; 

      case 'AllMunten':
        switch (colId) {
          case 'code':
            if (gridapi) {
              gridapi.api.forEachNode(function (node) {
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 3 ? true : false) : true;
            if (terug === false) {
              result = items.filter(o => o.code === waarde);
              if (Array.isArray(result)) {
                if (result.length > 0) {
                  if (params.props.data.id === 0) {
                    terug = true;
                  }
                  else {
                    if (params.props.data.id !== result[0].id) {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          default:
            break;
        }
        break;

      case 'AllFuncties':
        switch (colId) {
          case 'omschrijving':
            if (gridapi) {
              gridapi.api.forEachNode(function (node) {
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (terug === false) {
              result = items.filter(o => o.code === waarde);
              if (Array.isArray(result)) {
                if (result.length > 0) {
                  if (params.props.data.id === 0) {
                    terug = true;
                  }
                  else {
                    if (params.props.data.id !== result[0].id) {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          default:
            break;
        }
        break;

      case 'AllAansprekingen':
        switch (colId) {
          case 'code':
            if (gridapi) {
              gridapi.api.forEachNode(function (node) {
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (terug === false) {
              result = items.filter(o => o.code === waarde);
              if (Array.isArray(result)) {
                if (result.length > 0) {
                  if (params.props.data.id === 0) {
                    terug = true;
                  }
                  else {
                    if (params.props.data.id !== result[0].id) {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          case 'omschrijving':
            if (gridapi) {
              gridapi.api.forEachNode(function (node) {
                items.push(node.data);
              });
            }
            terug = waarde ? (waarde.trim().length < 1 ? true : false) : true;
            if (terug === false) {
              result = items.filter(o => o.omschrijving === waarde);
              if (Array.isArray(result)) {
                if (result.length > 0) {
                  if (params.props.data.id === 0) {
                    terug = true;
                  }
                  else {
                    if (params.props.data.id !== result[0].id) {
                      terug = true
                      params.props.data.save = false;
                    }
                  }
                }
              }
            }
            break;
          default:
            break;
        }
        break;

      default:    
          break;
    }    
    return terug;
  },
};

