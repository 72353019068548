import React from 'react';
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/nl-be'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Maplegende from '@material-ui/icons/Maplegende';
import AccountMultiPlus from '@material-ui/icons/AccountMultiPlus';
import BookPlus from '@material-ui/icons/BookPlus';
import GraphicEq from '@material-ui/icons/GraphicEq';
import WindowMaximize from '@material-ui/icons/WindowMaximize';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import Grow from '@material-ui/core/Grow';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Card, CardHeader,  CardBody } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import {AllPostcodes} from "../../redux/settings/paramActions";
import {AllAccounts,GetAccount,GetDocAccount,GetRegAccount,GetContAccount,CreateAccount,GetTarAccount, GetJobsAccount, GetAccijnzenAccount, GetVatrefundAccount, GetDirregAccount} from "../../redux/settings/accountActions";
import {CreateContact,GetContact,AllContacts} from "../../redux/settings/contactActions";
import {setSubTitle,zetview,clickview,zetListMain,ZetListData,zetpopup,resetview} from "../../redux/settings/action";
import {AllUsers,GetUser} from "../../redux/settings/userActions";
import {CreateRegistration,GetRegistration} from "../../redux/settings/registrationActions";
import {setPanelRight, setTitleRight} from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import SaveIcon from '@material-ui/icons/Save';
import FilePlus from '@material-ui/icons/FilePlus';
import Expences from '@material-ui/icons/Expences';
import ChangedAccData from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import CalendarPlus from '@material-ui/icons/CalendarPlus';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import PopJobAanvrNum from "../jobs/PopJobAanvrNum.jsx";
import PopCreateJob from "../jobs/PopCreateJob.jsx";
import PopKlantGegevens from '../accounts/PopKlantGegevens.jsx';
import PopAddZipCode from "../zipcode/PopAddZipcode.jsx";
import Slider from '@material-ui/core/Slider';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Grow in="true" ref={ref} {...props} />;
});

const COLORS = ['#E1F5FE','#B3E5FC','#81D4FA','#4FC3F7','#29B6F6','#03A9F4','#039BE5','#0288D1','#0277BD','#01579B',
'#80D8FF','#40C4FF','#00B0FF','#0091EA','#E0F7FA','#B2EBF2','#80DEEA','#4DD0E1','#26C6DA','#00BCD4',
'#00ACC1','#0097A7','#00838F','#006064','#84FFFF','#18FFFF','#00E5FF','#00B8D4','#E0F2F1','#B2DFDB',
'#80CBC4','#4DB6AC','#26A69A','#009688','#00897B','#00796B','#00695C','#004D40','#A7FFEB','#64FFDA',
'#52be80','#FFC300','#FF5733','#C70039','#900C3F','#BBDEFB','#90CAF9','#64B5F6',
'#42A5F5','#2196F3','#1E88E5','#1976D2','#1565C0','#0D47A1','#82B1FF','#448AFF','#2979FF','#2962FF',
'#1DE9B6','#00BFA5','#0088FE', '#00C49F', '#FFBB28', '#FF8042','#0088FE', '#00C49F', '#FFBB28', '#FF8042'];


const data01 = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const data02 = [
  { name: 'A1', value: 100 },
  { name: 'A2', value: 300 },
  { name: 'B1', value: 100 },
  { name: 'B2', value: 80 },
  { name: 'B3', value: 40 },
  { name: 'B4', value: 30 },
  { name: 'B5', value: 50 },
  { name: 'C1', value: 100 },
  { name: 'C2', value: 200 },
  { name: 'D1', value: 150 },
  { name: 'D2', value: 50 },
];


const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const {
    cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,payload, percent, value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 6;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill="#333">{payload.name}</text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={payload.color}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={payload.color}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={payload.color} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={payload.color} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 6} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 6} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};


var gridapi = null;
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

moment.locale('nl-be');
const accdropid = GenFunc.MakeId()
const accformid = GenFunc.MakeId()

var dropdoc = null;

const localizer = BigCalendar.momentLocalizer(moment)
const mapStateToProps = state => ({
  ...state
});


function Event({ event }) {
  if (event.status === 'c8011e2728d9d4c2f636f067f89cc14862c' || event.status === 'e401da53b7fbbce2345d7772b0674a318d5')
  {
    return (<div></div>)
  }
  else
  {
    if (event.kleiner === true)
    {
      if (event.code.includes('VOI')  === true)
      {
        if (event.einde)
        {
          return (
          <div style={{backgroundColor: 'deepskyblue',overflow:'hidden'}}>
            <div>
              <div dir="ltr" style={{marginBottom:'10px', color:'black',fontSize: '1.35em',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
            </div>
            <div>
              { event.account_name && (<div dir="ltr" style={{fontSize: '1.25em', color:'black',textAlign:'center'}}>{event.account_name}</div>)}
              { event.code && (<div dir="ltr" style={{fontSize: '1.05em', color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
              <div dir="ltr" style={{marginBottom:'10px',fontSize: '1.05em', color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
            </div>
          </div>
          )

        }
        else
        {
          return (
          <div style={{backgroundColor: 'mediumturquoise'}}>
            <div>
              <div dir="ltr" style={{marginBottom:'10px', color:'black',fontSize: '1.35em',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
            </div>
            <div>
              { event.account_name && (<div dir="ltr" style={{fontSize: '1.25em', color:'black',textAlign:'center'}}>{event.account_name}</div>)}
              { event.code && (<div dir="ltr" style={{fontSize: '1.05em', color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
              <div dir="ltr" style={{marginBottom:'10px',fontSize: '1.05em', color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
            </div>
          </div>
          )
        }

      }
      else
      {
        return (
        <div style={{backgroundColor: '#fb9678'}}>
          <div>
            <div dir="ltr" style={{marginBottom:'10px', color:'black',fontSize: '1.35em',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
          </div>
          <div>
            { event.account_name && (<div dir="ltr" style={{fontSize: '1.25em', color:'black',textAlign:'center'}}>{event.account_name}</div>)}
            { event.code && (<div dir="ltr" style={{fontSize: '1.05em', color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
            <div dir="ltr" style={{marginBottom:'10px',fontSize: '1.05em', color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
          </div>
        </div>
        )
      }



    }
    else
    {
      if (event.code.includes('VOI')  === true)
      {
        if (event.einde)
        {
          return (
          <div style={{backgroundColor: 'deepskyblue',overflow:'hidden'}}>
            <div>
              <div dir="ltr" style={{marginBottom:'10px', color:'black',fontSize: '1.35em',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
            </div>
            <div>
              { event.account_name && (<div dir="ltr" style={{fontSize: '1.25em', color:'black',textAlign:'center'}}>{event.account_name}</div>)}
              { event.code && (<div dir="ltr" style={{fontSize: '1.05em', color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
              <div dir="ltr" style={{marginBottom:'10px',fontSize: '1.05em', color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
            </div>
          </div>
          )

        }
        else
        {
          return (
          <div style={{backgroundColor: 'mediumturquoise'}}>
            <div>
              <div dir="ltr" style={{marginBottom:'10px', color:'black',fontSize: '1.35em',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
            </div>
            <div>
              { event.account_name && (<div dir="ltr" style={{fontSize: '1.25em', color:'black',textAlign:'center'}}>{event.account_name}</div>)}
              { event.code && (<div dir="ltr" style={{fontSize: '1.05em', color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
              <div dir="ltr" style={{marginBottom:'10px',fontSize: '1.05em', color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
            </div>
          </div>
          )
        }

      }
      else
      {
        return (
        <div style={{backgroundColor: 'lightgrey', overflow:'hidden'}}>
          <div>
            <div dir="ltr" style={{marginBottom:'10px', color:'black',fontSize: '1.35em',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
          </div>
          <div>
            { event.account_name && (<div dir="ltr" style={{fontSize: '1.25em', color:'black',textAlign:'center'}}>{event.account_name}</div>)}
            { event.code && (<div dir="ltr" style={{fontSize: '1.05em', color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
            <div dir="ltr" style={{marginBottom:'10px',fontSize: '1.05em', color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
          </div>
        </div>
        )
      }
    }
  }
}

function EventSmall({ event }) {
  if (event.status === 'c8011e2728d9d4c2f636f067f89cc14862c' || event.status === 'e401da53b7fbbce2345d7772b0674a318d5')
  {
    return (<div></div>)
  }
  else
  {
    if (event.kleiner === true)
    {
      if (event.code.includes('VOI')  === true)
      {
        if (event.einde)
        {
          return (
          <div style={{backgroundColor: 'deepskyblue',overflow:'hidden'}}>
            <div>
              <div dir="ltr" style={{color:'black',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
            </div>
            <div>
              { event.account_name && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.account_name}</div>)}
              { event.code && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
              <div dir="ltr" style={{color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
            </div>
          </div>
          )

        }
        else
        {
          return (
          <div style={{backgroundColor: 'mediumturquoise'}}>
            <div>
              <div dir="ltr" style={{color:'black',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
            </div>
            <div>
              { event.account_name && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.account_name}</div>)}
              { event.code && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
              <div dir="ltr" style={{color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
            </div>
          </div>
          )
        }
      }
      else
      {


        return (
        <div style={{backgroundColor: '#fb9678', overflow:'hidden'}}>
          <div>
            <div dir="ltr" style={{color:'black',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
          </div>
          <div>
            { event.account_name && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.account_name}</div>)}
            { event.code && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
            <div dir="ltr" style={{color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
          </div>
        </div>
        )
      }


    }
    else
    {
      if (event.code.includes('VOI')  === true)
      {
        if (event.einde)
        {
          return (
          <div style={{backgroundColor: 'deepskyblue',overflow:'hidden'}}>
            <div>
              <div dir="ltr" style={{color:'black',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
            </div>
            <div>
              { event.account_name && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.account_name}</div>)}
              { event.code && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
              <div dir="ltr" style={{color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
            </div>
          </div>
          )

        }
        else
        {
          return (
          <div style={{backgroundColor: 'mediumturquoise'}}>
            <div>
              <div dir="ltr" style={{color:'black',textAlign:'center', overflow:'hidden'}}>{event.title}</div>
            </div>
            <div>
              { event.account_name && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.account_name}</div>)}
              { event.code && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
              <div dir="ltr" style={{color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
            </div>
          </div>
          )
        }
      }

      else
      {
        return (
        <div style={{backgroundColor: 'lightgrey', overflow:'hidden'}}>
          <div>
            <div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.title}</div>
          </div>
          <div>
            { event.account_name && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.account_name}</div>)}
            { event.code && (<div dir="ltr" style={{color:'black',textAlign:'center'}}>{event.code} - {event.periode}</div>)}
            <div dir="ltr" style={{color:'black',textAlign:'center'}}>Status: {event.status_txt}</div>
          </div>
        </div>
        )
      }
    }
  }
}

class UserDashBoard extends React.Component {

  state = {
    AccountData:[],
    AccountCol:[],
    JobsData:[],
    JobsCol:[],
    grafjobstatus: [],    
    jobtitle:'Mijn jobs',
    DownloadData: { id: '' },
    PieJobStatIndex:0,
    tableheight: Math.floor(window.innerHeight * .70) + 'px',
    tabvalue: '1',
    edit: this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id === 0 ? true : false,
    editfact: this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id === 0 ? true : false,
    save: false,
    subsave: false,
    adrestel: 0,
    AgendaOpen:false,
    AccountOpen:false,
    JobOpen:false,
    PopUpScreen:null,
    checkval: false,
    jobgraph:false,
    workflowstories:null,
    lst_jobs:[],
    jaar:new Date().getFullYear(),
    data: this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id === 0 ? this.props.account.MainData.data : null,
    speedactions: [
      { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
      { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe klant maken' },
      { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
      { icon: <Maplegende />, name: 'Fgov', tooltip: 'Naar site Fgov' },
    ],
    subspeedactions: [
      { icon: <AccountMultiPlus />, name: 'Contact', tooltip: 'Nieuwe contactpersoon' },
      { icon: <BookPlus />, name: 'NewRegistratie', tooltip: 'Nieuwe registratie' },
      { icon: <ChangedAccData />, name: 'ChangedAccData', tooltip: 'Gewijzigde klantgegevens' },
    ],
    speedopen: false,
    subspeedopen: false,    
  };

  constructor(props, context) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.ValueCheck = this.ValueCheck.bind(this);
    this.adrchange = this.adrchange.bind(this);
    this.Fgov = this.Fgov.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.SecValueCheck = this.SecValueCheck.bind(this);
    this.SecCellClick = this.SecCellClick.bind(this);
    this.DocCellClick = this.DocCellClick.bind(this);
    this.RegCellClick = this.RegCellClick.bind(this);

    this.AccCellClick = this.AccCellClick.bind(this);
    this.JobCellClick = this.JobCellClick.bind(this);

    this.ContCellClick = this.ContCellClick.bind(this);
    this.ContDetClick = this.ContDetClick.bind(this);
    this.KlantFactClick = this.KlantFactClick.bind(this);
    this.UserDetClick = this.UserDetClick.bind(this);

    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
    this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);

    this.ZetEdit = this.ZetEdit.bind(this);
    this.ZetEditFact = this.ZetEditFact.bind(this);
    this.CreaAcount = this.CreaAcount.bind(this);
    this.SecSaveAll = this.SecSaveAll.bind(this);
    this.CheckSave = this.CheckSave.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.MainSave = this.MainSave.bind(this);
    this.checkconfirm = this.checkconfirm.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
    this.handleJa = this.handleJa.bind(this);
    this.ActionClick = this.ActionClick.bind(this);
    this.SubActionClick = this.SubActionClick.bind(this);
    this.TariefCellClick = this.TariefCellClick.bind(this);
    this.ClosePopJobs = this.ClosePopJobs.bind(this);
    this.ClosePopCreaJobs = this.ClosePopCreaJobs.bind(this);
    this.ClosePopZipcode = this.ClosePopZipcode.bind(this);

    this.valuejaar = this.valuejaar.bind(this);
    this.JaarChange = this.JaarChange.bind(this);
    this.GetGrafJob = this.GetGrafJob.bind(this);
    this.getGrafJobStories = this.getGrafJobStories.bind(this);
    this.zetCalendar = this.zetCalendar.bind(this);
    this.zetGrafJobStatus = this.zetGrafJobStatus.bind(this);
    this.zetJobs = this.zetJobs.bind(this);

    this.ClosePopChangedAccData = this.ClosePopChangedAccData.bind(this);
    this.handleAgenda = this.handleAgenda.bind(this);
    this.handleAgendaClose = this.handleAgendaClose.bind(this);
    this.handleAccount = this.handleAccount.bind(this);
    this.handleAccountClose = this.handleAccountClose.bind(this);

    this.handleJob = this.handleJob.bind(this);
    this.handleJobClose = this.handleJobClose.bind(this);


    this.SelectDatum = this.SelectDatum.bind(this);
    this.onPieJobStatEnter = this.onPieJobStatEnter.bind(this);
    this.onPieJobStatClick = this.onPieJobStatClick.bind(this);
  }
  onPieJobStatEnter = (data, index) => 
  {
    this.setState({PieJobStatIndex: index,});
  }  
  onPieJobStatClick = (data, index) => 
  {
    this.zetJobs({jaar:this.state.jaar,status:data.afk})
    let jobtitle = 'Mijn jobs van '+this.state.jaar+' met status '+data.name;
    this.setState({jobtitle:jobtitle})
  }

  handleAgenda()
  {
    this.setState({AgendaOpen:true})
  }
  handleAgendaClose()
  {
    this.setState({AgendaOpen:false})
  }
  handleAccount()
  {
    this.setState({AccountOpen:true})
  }
  handleAccountClose()
  {
    this.setState({AccountOpen:false})
  }
  handleJob()
  {
    this.setState({JobOpen:true})
  }
  handleJobClose()
  {
    this.setState({JobOpen:false})
  }


  async SelectDatum(data)
  {
    return
    let jobdata = await SqlFunction.DoSql('GetJob', data);
    let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
    window.scrollTo(0, 0);
    this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} nivo={0} data={jobdata} history={this.props.history} close={this.ClosePopJobs} title={title} />)));
  }
  async JobCellClick(waarde) {
    let jobdata = await SqlFunction.DoSql('GetJob', waarde.data);
    let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
    window.scrollTo(0, 0);
    this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} nivo={0} data={jobdata} history={this.props.history} close={this.ClosePopJobs} title={title} />)));
    return;
  }


  valuejaar(value: number) {
    return `${value}`;
  }

  async GetGrafJob(jaar) {
    let data = {id:this.props.account.MainData.data.id,jaar:jaar}
    let retdata = await SqlFunction.DoSql('GetGrafAccJob', data);
    this.getGrafJobStories(retdata)
  }

  async getGrafJobStories(dataworkflows) 
  {
    var stories = [];
    const { classes } = this.props;

    var teller = 0;
    let storiedata = {}
    let inverted = false
    if (dataworkflows)
    {
      for (teller = 0; teller < dataworkflows.length; teller++) 
      {
        let data = dataworkflows[teller];
        data.index = teller;
        storiedata = 
        {
          badgeColor:data.status === 'c8011e2728d9d4c2f636f067f89cc14862c' ? 'success' : 'primary',
          inverted:inverted,
          id:data.id,
          jobid:data.job_id,
          badgeIcon: data.start_date.substring(0, 5),
          index:teller,
          title:data.description,
          description:data.description,
          badgeClick: () =>this.JobCellClick({data:data}),
          titleColor: "primary",
          body: (
            <div>
              <Grid container spacing={1}>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Code</small>
                  <h6>{data.code}</h6>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Status</small>
                  <h6>{data.status_txt}</h6>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Periode</small>
                  <h6>{data.periode}</h6>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Land</small>
                  <h6>{data.country_txt}</h6>
                </Grid>
              </Grid>
            </div>
          ),
          footer: (
            <div style={{overflow:'auto'}}>
              <Stepper nonLinear activeStep={0} connector={<QontoConnector />}>
                {data.workflows.map(datavld => {
                  return (
                    <Step key={GenFunc.MakeId()}>
                      <StepButton
                        completed={datavld.completed === '1' ? true : false}
                        active={false}
                        classes={{root: classes.stepIcon}}           
  
                      >
                        {datavld.title}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>  
            </div>
          )
        }
        stories.push(storiedata);
        inverted = !inverted;
      }
    }
    this.setState({workflowstories:stories})   
  }  

  TariefChanged(params, waarde, that) {
  }                        

  TariefCheck(params, waarde) {
    var colId = params.props.column.getId();
    params.props.data.save = true;
    let terug = Validation.ValidCheck('AccountTarief', waarde, colId, params, gridapi);
    if (colId === 'jaar') {
      if (waarde < 2010 || waarde > 2100) {
        params.props.data.save = ''
        terug = true
      }
      else {
        terug = ''
      }
    }
    if (params.props.data.jaar < 2010 || params.props.data.jaar > 2100) {
      params.props.data.save = ''
    }
    if (params.props.data && params.props.data.jobid && params.props.data.jobid.length < 2) {
      params.props.data.save = '';
    }
    if (params.props.data && params.props.data.tarief && params.props.data.tarief.length < 1) {
      params.props.data.save = '';
    }
    if (!params.props.data || !params.props.data.jobid || !params.props.data.description || !params.props.data.tarief) {
      params.props.data.save = '';
    }
    if (params.props && params.props.agGridReact && params.props.agGridReact.props && params.props.agGridReact.props.rowData) {
      let items = params.props.agGridReact.props.rowData
      let result = items.filter(o => o.accountid === params.props.data.accountid && o.jobid === params.props.data.jobid && o.jaar === params.props.data.jaar);
      if (Array.isArray(result)) {
        if (result.length > 0) {
          if (params.props.data.id === 0) {
            params.props.data.save = '';
          }
          else {
            if (params.props.data.id !== result[0].id) {
              params.props.data.save = '';
            }
          }
        }
      }
    }
    params.setState({ error: terug })
  }       

  async TariefCellClick(waarde) {
    var data = waarde.data
    let column = waarde.column.colDef.field;
    let retdata = null;
    if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows, 'AllJobDefMain', 'edit')) {
      if (data.save === true) {
        data.accountid = this.props.account.MainData.data.id;
        retdata = await SqlFunction.DoSql('ChgTarAccount', data);
        waarde.data.save = '';
        if (data.id === 0) {
          waarde.data.id = retdata.id;
          gridapi.api.stopEditing();
          gridapi.api.updateRowData({
            add: [waarde.data],
            addIndex: 0
          });
          waarde.node.setData(GenFunc.zetdataleeg('AccTarief'));
        }
        else {
          waarde.node.setData(waarde.data);
        }
        this.setState({ subsave: false })
      }
    }
    if (column === 'verwijder' && GenFunc.allowed(this.props.user.userData.secrows, 'AccTarief', 'verwijder')) {
      await SqlFunction.DoSql('DeleteTarAccount', waarde.data);
      await this.props.dispatch(GetTarAccount(this.props.account.MainData.data.id))
      return;
    }

    return;
  }

  async handleTabChange(event, newValue) {
    if (newValue === '1') {
      // Contactpersonen
      let subspeedactions = [
        { icon: <AccountMultiPlus />, name: 'Contact', tooltip: 'Nieuwe contactpersoon' },
      ]
      this.setState({subspeedactions:subspeedactions})
      await this.props.dispatch(GetContAccount(this.props.account.MainData.data.id))
    }

    if (newValue === '3') {
      // Registraties
      let subspeedactions = [
        { icon: <BookPlus />, name: 'NewRegistratie', tooltip: 'Nieuwe registratie' },
      ]
      this.setState({subspeedactions:subspeedactions})
      await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id))
    }

    if (newValue === '4') {
      // Tarieven
      await this.props.dispatch(GetTarAccount(this.props.account.MainData.data.id))
      await this.props.dispatch(ZetListData('AccountTarief'));
      await this.props.dispatch(zetListMain('AccountTarief'));

    }

    if (newValue === '5')
    {
      // Registraties
      let subspeedactions = [
      { icon: <CalendarPlus />, name: 'Job', tooltip:'Nieuwe job maken'  },
      { icon: <Expences />, name: 'Onkostennota', tooltip: 'Onkostennota' },
      { icon: <GraphicEq />, name: 'Grafische voorstelling', tooltip: 'Grafische voorstelling' },

      ]
      this.setState({subspeedactions:subspeedactions})

      await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id))
      await this.props.dispatch(ZetListData('GetJobsAccount',this.props.account.MainData.data.id));
      await this.props.dispatch(zetListMain('GetJobsAccount',this.props.account.MainData.data.id));


    }

    if (newValue === '6') {
      let subspeedactions = [
      { icon: <FilePlus />, name: 'Document', tooltip: 'Document toevoegen' },
      ]
      this.setState({subspeedactions:subspeedactions})

      // Documenten
      await this.props.dispatch(GetDocAccount(this.props.account.MainData.data.id))
    }
    if (this.state.edit === true || this.state.editfact === true)
    {
      await this.MainSave();
    }
    this.setState({ tabvalue: newValue });

  };

  handleSpeedClose() {
    this.setState({ speedopen: false });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true });
  };

  handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
  };

  handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
  };


  async ActionClick(name) {
    let hval = 'http://';
    let data = null;
    switch (name) {
      case 'Edit':
        this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data, speedopen: false })
        break;

      case 'New':
        await this.props.dispatch(CreateAccount())
        this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data, speedopen: false })
        break;

      case 'NewRegistratie':
        this.props.dispatch(zetview(this.props.account.MainData.data.name, this.props.account.MainData.data.id, 'ACC'));
        this.props.dispatch(clickview({ id: '', vorm: 'REG' }));
        //        await this.props.dispatch(AllContacts(this.props))
        data = { accountid: this.props.account.MainData.data.id, account_name: this.props.account.MainData.data.name }
        this.props.dispatch(setSubTitle('Registratie'))
        await this.props.dispatch(CreateRegistration(data))
        this.props.history.push('/registration');
        break;

      case 'Save':
        this.MainSave();
        break;

      case 'Job':
        data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.account.MainData.data.id;
        data.user_id = this.props.account.MainData.data.user;
        data.country = this.props.account.MainData.data.adressen[0].country;
        data.create_date = GenFunc.Get_Vandaag();
        data.jaar = GenFunc.Get_Jaar();
        data.dagen = null;
        data.account_name = this.props.account.MainData.data.name
        data.country_txt = this.props.account.MainData.data.adressen[0].country_txt;
        data.user_name = this.props.account.MainData.data.user_name;
        this.setState({ PopUpScreen: (<PopCreateJob open={true} data={data} close={this.ClosePopCreaJobs} title='Aanmaken job' />) });
        break;

      case 'Document':
        dropdoc = null;
        let element = document.getElementById(accdropid);
        element.click()
        break;

      case 'Contact':
        this.props.dispatch(zetview(this.props.account.MainData.data.name, this.props.account.MainData.data.id, 'ACC'));
        this.props.dispatch(clickview({ id: '', vorm: 'CONT' }));

        await this.props.dispatch(AllContacts(this.props))
        data = { accountid: this.props.account.MainData.data.id, account_name: this.props.account.MainData.data.name }
        this.props.dispatch(setSubTitle('Contactpersoon'))
        await this.props.dispatch(CreateContact(data))
        this.props.history.push('/contact');
        break;

      case 'Fgov':
        if (this.state && this.state.data && this.state.data.name && this.state.data.name.length > 2) {
          hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.state.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
          window.open(hval, "_blank");
        }
        else {
          if (this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.name && this.props.account.MainData.data.name.length > 2) {
            hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.props.account.MainData.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
            window.open(hval, "_blank");
          }
          else {
            hval = 'https://kbopub.economie.fgov.be'
            window.open(hval, "_blank");
          }
        }

        break;

      case 'ChangedAccData':
        await this.props.dispatch(GetAccijnzenAccount(this.props.account.MainData.data.id))
        await this.props.dispatch(GetVatrefundAccount(this.props.account.MainData.data.id))
        await this.props.dispatch(GetDirregAccount(this.props.account.MainData.data.id))
        let popdata = this.props.account;    
        this.setState({PopUpScreen:(<PopKlantGegevens open={true} data={popdata} close={this.ClosePopChangedAccData} title='Wijziging klantengegevens' />)});
        break;

      default:
        break;
    }
    this.setState({ speedopen: false });
  }

  async SubActionClick(name) {
    let hval = 'http://';
    let data = null;
    switch (name) {
      case 'Edit':
        this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data, speedopen: false })
        break;

      case 'New':
        await this.props.dispatch(CreateAccount())
        this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data, speedopen: false })
        break;

      case 'NewRegistratie':
        this.props.dispatch(zetview(this.props.account.MainData.data.name, this.props.account.MainData.data.id, 'ACC'));
        this.props.dispatch(clickview({ id: '', vorm: 'REG' }));
        //        await this.props.dispatch(AllContacts(this.props))
        data = { accountid: this.props.account.MainData.data.id, account_name: this.props.account.MainData.data.name }
        this.props.dispatch(setSubTitle('Registratie'))
        await this.props.dispatch(CreateRegistration(data))
        this.props.history.push('/registration');
        break;

      case 'Save':
        this.MainSave();
        break;

      case 'Job':
        data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.account.MainData.data.id;
        data.user_id = this.props.account.MainData.data.user;
        data.country = this.props.account.MainData.data.adressen[0].country;
        data.create_date = GenFunc.Get_Vandaag();
        data.jaar = GenFunc.Get_Jaar();
        data.dagen = 0;
        data.account_name = this.props.account.MainData.data.name
        data.country_txt = this.props.account.MainData.data.adressen[0].country_txt;
        data.user_name = this.props.account.MainData.data.user_name;
        this.setState({ PopUpScreen: (<PopCreateJob open={true} data={data} close={this.ClosePopCreaJobs} title='Aanmaken job' />) });
        break;
      case 'Grafische voorstelling':
        let jobgraph = !this.state.jobgraph;
        this.GetGrafJob(this.state.jaar);
        this.setState({jobgraph:jobgraph})

        break;
      case 'Onkostennota':
        data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.account.MainData.data.id;
        data.user_id = this.props.account.MainData.data.user;
        data.country = this.props.account.MainData.data.adressen[0].country;
        data.create_date = GenFunc.Get_Vandaag();
        data.jaar = GenFunc.Get_Jaar();
        data.dagen = 0;
        data.job_id='c901f08f895fb98ab9159f51fd0297e236d';
        data.job_txt = 'Boeken onkosten';
        data.account_name = this.props.account.MainData.data.name
        data.country_txt = this.props.account.MainData.data.adressen[0].country_txt;
        data.user_name = this.props.account.MainData.data.user_name;
        this.setState({ PopUpScreen: (<PopCreateJob open={true} data={data} close={this.ClosePopCreaJobs} title='Aanmaken job' />) });
        break;

      case 'Document':
        dropdoc = null;
        let element = document.getElementById(accdropid);
        element.click()
        break;

      case 'Contact':
        this.props.dispatch(zetview(this.props.account.MainData.data.name, this.props.account.MainData.data.id, 'ACC'));
        this.props.dispatch(clickview({ id: '', vorm: 'CONT' }));

        await this.props.dispatch(AllContacts(this.props))
        data = { accountid: this.props.account.MainData.data.id, account_name: this.props.account.MainData.data.name }
        this.props.dispatch(setSubTitle('Contactpersoon'))
        await this.props.dispatch(CreateContact(data))
        this.props.history.push('/contact');
        break;

      case 'Fgov':
        if (this.state && this.state.data && this.state.data.name && this.state.data.name.length > 2) {
          hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.state.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
          window.open(hval, "_blank");
        }
        else {
          if (this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.name && this.props.account.MainData.data.name.length > 2) {
            hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.props.account.MainData.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
            window.open(hval, "_blank");
          }
          else {
            hval = 'https://kbopub.economie.fgov.be'
            window.open(hval, "_blank");
          }
        }

        break;

      default:
        break;
    }
    this.setState({ speedopen: false });
  }


  Fgov(website) {
    let hval = 'http://' + website;
    if (website === 'FGOV') {
      hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.props.account.MainData.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
    }
    window.open(hval, "_blank");
  };

  async ClosePopChangedAccData() {
    this.setState({PopUpScreen:null})
  }

  async CreaAcount() {
    await this.props.dispatch(CreateAccount())
    this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data })
  }

  ZetEdit() {
    this.setState({ edit: true, data: this.props.account.MainData.data })
  }

  ZetEditFact() {
    this.setState({ editfact: true, data: this.props.account.MainData.data })
  }

  async componentDidMount() {
    window.addEventListener("resize", this.resizeFunction);
    this.props.dispatch(setPanelRight(false))
    this.props.dispatch(setTitleRight(''))
    await this.zetCalendar();
    await this.zetGrafJobStatus(this.state.jaar);
    await this.zetJobs({jaar:this.state.jaar})
    let jobtitle = 'Mijn jobs van '+this.state.jaar;
    let retdata = await SqlFunction.DoSql('AllAccountsUser', {});
    this.setState({AccountData:retdata ? retdata.data : [],AccountCol:retdata ? retdata.kol: [],jobtitle:jobtitle})
  }


  async JaarChange(event,value) {
    this.setState({jaar:value})
    this.zetGrafJobStatus(value);
    await this.zetJobs({jaar:value})
    let jobtitle = 'Mijn jobs van '+value;
    this.setState({jobtitle:jobtitle})
  }


  async zetGrafJobStatus(jaar)
  {
    let hdata = {jaar:jaar}
    let retdata = await SqlFunction.DoSql('GetGrafUserJobStatus', hdata);
    let grafdata = [];                        
    let subres = null;
    if (retdata)
    {
      for(var i = 0; i < retdata.length; i++) 
      {
        if (retdata[i].name.length > 2)
        {
          subres = retdata[i];
          subres.value = parseFloat(retdata[i].aantal); 
          subres.afk = retdata[i].status; 
          subres.color = COLORS[i];
          grafdata.push(subres);
        }
      }
    }
    this.setState({grafjobstatus:grafdata})
  }



  async zetJobs(zkdata)
  {
    let retdata = await SqlFunction.DoSql('GetGrafJobsUser', zkdata);
    let JobsData = null;
    let JobsCol = null;
    if (retdata)
    {
      JobsData = retdata.data
      JobsCol = retdata.kol
    }
    this.setState({JobsData:JobsData,JobsCol:JobsCol})
  }



  async zetCalendar()
  {
    let retdata = await SqlFunction.DoSql('GetAllJobsUser', {});
    let lst_activ = [];
    var vandaag = new Date();
    let kleiner = true;
    if (retdata)
    {
      for (var i=0; i < retdata.data.length; i++) 
      {
        let rij = retdata.data[i];
        let datum = new Date(rij.dat_start)
        if (datum < vandaag)
        {
           kleiner = true
        }
        else
        {
          kleiner = false
        }
        let sdatum = rij.dat_start;
        if (sdatum)
        {
          let y=sdatum.split("-")[0];
          let m=sdatum.split("-")[1];
          let nm = parseFloat(m) - 1;
          m = nm.toString();
          let d=sdatum.split("-")[2]; 
          datum = new Date(y, m, d, 0, 0 , 0);
        }
        let agendarij = 
          {
            'id':rij.id,
            'KlikJob':this.KlikAct,
            'KlikAcc':this.KlikAcc,
            'account_name':rij.account_name,
            'account_id':rij.account_id,
            'code': rij.code,
            'country': rij.country,
            'country_txt':rij.country_txt,
            'periode':rij.periode,
            'start_date':rij.start_date,
            'status':rij.status,
            'status_txt':rij.status_txt,
            'color':  (rij.status === 'c8011e2728d9d4c2f636f067f89cc14862c') ?  '#fb9678' : '#fb9678',
            'title': rij.description,
            'start': datum,
            'end': datum,
            'kleiner': kleiner,
          }
        lst_activ.push(agendarij);
        if (rij.code.includes('VOI')  === true )
        {
          let edatum = new Date(rij.dat_end)
          if (edatum < vandaag)
          {
             kleiner = true
          }
          else
          {
            kleiner = false
          }
          sdatum = rij.dat_end;
          agendarij = 
            {
              'id':rij.id,
              'KlikJob':this.KlikAct,
              'KlikAcc':this.KlikAcc,
              'account_name':rij.account_name,
              'account_id':rij.account_id,
              'code': rij.code,
              'country': rij.country,
              'country_txt':rij.country_txt,
              'periode':rij.periode,
              'start_date':rij.end_date,
              'status':rij.status,
              'status_txt':rij.status_txt,
              'color':  (rij.status === 'c8011e2728d9d4c2f636f067f89cc14862c') ?  '#fb9678' : '#fb9678',
              'title': rij.description,
              'start': edatum,
              'end': edatum,
              'kleiner': kleiner,
              'einde':true,
            }
          lst_activ.push(agendarij);
        }
      }      
    }
    this.setState({lst_jobs:lst_activ})
  }
    

  componentWillUnmount() {
    this.checkconfirm(this.props.account.MainData);
    this.props.dispatch(setPanelRight(false))
    this.props.dispatch(setTitleRight(''))
    window.removeEventListener("resize", this.resizeFunction);
  }

  async componentWillReceiveProps(nextProps, nextState) {
    if (this.props.account.MainData) {
      if (this.props.account.MainData.data.id !== nextProps.account.MainData.data.id) {
        await this.checkconfirm(this.props.account.MainData);
        this.props.dispatch(resetview())
        this.props.dispatch(zetpopup(null));

        if (nextProps.account.MainData.data.id === 0) {

          await this.setState({ edit: true, editfact: true, save: false, subsave: false, PopUpScreen:null })
          this.handleTabChange(null,'1')
        }
        else {
          await this.props.dispatch(GetDocAccount(nextProps.account.MainData.data.id))
          await this.props.dispatch(GetContAccount(nextProps.account.MainData.data.id))
          await this.props.dispatch(GetRegAccount(nextProps.account.MainData.data.id))
          await this.setState({ edit: false, editfact: false, save: false, subsave: false, PopUpScreen:null })
          let prevtab = this.state.tabvalue
          this.handleTabChange(null,prevtab)

        }
      }
    }
  }

  async adrchange(teller) {
    await this.setState({ adrestel: teller, checkval: true })
  }

  async handleJa(parameters) {
    if (this.state.save === true) {
      let retdata = await SqlFunction.DoSql('ChgAccount', this.state.data);
      if (retdata.nwzip === true) {
        this.props.dispatch(AllPostcodes())
      }

    }
    if (this.state.subsave === true) {
    }
    this.setState({ edit: false, editfact: false, save: false, subsave: false })
  }

  checkconfirm(parameters) {
    if (this.state.save === true || this.state.subsave === true) {
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Aanpassingen niet doorgevoerd.</h1>
              <p>Wens je de aanpassingen alsnog te bewaren?</p>
              <button
                onClick={() => {
                  this.handleJa(parameters);
                  onClose();
                }}
              >
                Ja
            </button>
              <button onClick={() => {
                this.setState({ edit: false, editfact: false, save: false, subsave: false, checkval: false })
                onClose();
              }}
              >
                Nee
            </button>
            </div>
          );
        }
      });
    }

  }

  resizeFunction() {
    if (window.innerHeight >= 960) {
      this.setState({ tableheight: Math.floor(window.innerHeight * .70) + 'px' });
    }
    else {
      this.setState({ tableheight: '600px' });
    }
  }

  onGridReady(params) {
    gridapi = params;
  }

  async MainSave() {
    if (this.state.save === true) {
      let retdata = await SqlFunction.DoSql('ChgAccount', this.state.data);
      if (retdata.nwzip === true) {
        this.props.dispatch(AllPostcodes())
      }
      this.setState({ edit: false, editfact: false, save: false, data: null, checkval: false })
      await this.props.dispatch(GetAccount(retdata.id))
    }
  }

  async SecCellClick(waarde) {
    var data = waarde.data
    let column = waarde.column.colDef.field;
    let retdata = null;
    if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows, 'Gebruikers', 'edit')) {
      if (data.save === true) {
        retdata = await SqlFunction.DoSql('ChgSecUserParam', data);
        if (retdata.nwzip === true) {
          this.props.dispatch(AllPostcodes())
        }
        waarde.data.save = '';
        waarde.node.setData(waarde.data);
        this.setState({ subsave: false })
      }
    }
    return;
  }

  async ContCellClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: waarde.data.id, vorm: 'CONT' }));

    await this.props.dispatch(AllContacts(this.props))
    this.props.dispatch(setSubTitle('Contactpersoon'))
    await this.props.dispatch(GetContact(waarde.data.id))
    this.props.history.push('/contact');
    return;
  }

  async AccCellClick(waarde) {
    this.props.dispatch(clickview({ id: waarde.data.id, vorm: 'ACC' }));
    await this.props.dispatch(AllAccounts(this.props))
    this.props.dispatch(setSubTitle('Klantenfiche'))
    await this.props.dispatch(GetAccount(waarde.data.id))
    this.props.history.push('/account');
    return;
  }


  async RegCellClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: waarde.data.id, vorm: 'REG' }));
    //    await this.props.dispatch(AllContacts(this.props))
    this.props.dispatch(setSubTitle('Registratie'))
    await this.props.dispatch(GetRegistration(waarde.data.id))
    this.props.history.push('/registration');
    return;
  }


  async KlantFactClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: this.props.account.MainData.data.factklant, vorm: 'ACC' }));
    await this.props.dispatch(GetAccount(this.props.account.MainData.data.factklant))
    return;
  }

  async ContDetClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: this.props.account.MainData.data.contact, vorm: 'CONT' }));

    await this.props.dispatch(AllContacts(this.props))
    this.props.dispatch(setSubTitle('Contactpersoon'))
    await this.props.dispatch(GetContact(this.props.account.MainData.data.contact))
    this.props.history.push('/contact');
    return;
  }

  async UserDetClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: this.props.account.MainData.data.user, vorm: 'USR' }));
    await this.props.dispatch(AllUsers(this.props))
    this.props.dispatch(setSubTitle('Gebruiker'))
    await this.props.dispatch(GetUser(this.props.account.MainData.data.user))
    this.props.history.push('/user');
    return;
  }

  async DocCellClick(waarde) {
    let column = waarde.column.colDef.field;
    if (column === 'upload') {
      dropdoc = waarde.data;
      let element = document.getElementById(accdropid);
      element.click()
      return;
    }
    if (column === 'verwijder') {
      await SqlFunction.DoSql('DeleteFile', waarde.data);
      await this.props.dispatch(GetDocAccount(this.props.account.MainData.data.id))
      return;
    }
    await this.setState({ DownloadData: waarde.data });
    document.getElementById(accformid).submit();
    return;
  }

  getContextMenuItems(params, sleutel) {
    return null;
  }

  async SecSaveAll(items) {
    if (Array.isArray(items) && GenFunc.allowed(this.props.user.userData.secrows, 'Gebruikers', 'edit')) {
      var i = 0;
      for (i = 0; i < items.length; i++) {
        let data = items[i];
        await SqlFunction.DoSql('ChgSecUserParam', data);
      }
    }
  }                      

  CheckSave(data) {
    let terug = true
    terug = Validation.ValidCheck(this.props.settings.sub_title, data.number, 'number', this, this.props.account.AllData.data, data);
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.name, 'name', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.email, 'email', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.enddate, 'enddate', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.startdate, 'startdate', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.user_name, 'user_name', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.adressen[0].adres1, 'adres1', this, this.props.account.AllData.data, data, 0);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.adressen[0].housenum, 'housenum', this, this.props.account.AllData.data, data, 0);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.adressen[0].country_txt, 'country_txt', this, this.props.account.AllData.data, data, 0);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.adressen[0].zipcode_txt, 'zipcode_txt', this, this.props.account.AllData.data, data, 0);
    }
    return !terug;
  }

  SecValueCheck(params, waarde) {
    if (GenFunc.allowed(this.props.user.userData.secrows, 'Gebruikers', 'edit')) {
      params.props.data.save = true;
      this.setState({ subsave: true })
    }
  }       

  async onDrop(files) {
    const formData = new FormData();
    this.handleTabChange(null, '0');
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append('file', file);

    }
    if (dropdoc) {
      formData.set('accountid', dropdoc.account_id);
      formData.set('type', dropdoc.type);
      formData.set('docid', dropdoc.id);
      formData.set('docdata', dropdoc);
    }
    else {
      formData.set('accountid', this.props.account.MainData.data.id);
      formData.set('type', 'account');
    }

    await SqlFunction.DoSqlBody('saveFile', formData);
    dropdoc = null;
    this.handleTabChange(null, '6');
  };

  async ValueCheck(that, waarde, type, colId) {
    let data = this.state.data;
    let terug = false;
    switch (colId) {
      case 'active':
        if (waarde === true) {
          data.active = '1'
        }
        if (waarde === false) {
          data.active = '0'
        }
        break;

      case 'user_name':
        data.user_name = waarde.value;
        data.user = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'cont_name':
        data.cont_name = waarde.value;
        data.contact = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'factklant_txt':
        data.factklant_txt = waarde.value;
        data.factklant = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'zipcode_txt':
        if (waarde.id === 0)
        {
           // openen aanmaken nieuwe postcode
          this.setState({PopUpScreen:(<PopAddZipCode open={true}  toevobject={that}  waarde={waarde.value} close={this.ClosePopZipcode} title='Toevoegen postode aan lijst' />)});
           // Einde aanmaken nieuwe postcode
        }
        data.adressen[this.state.adrestel].zipcode_txt = waarde.value;
        data.adressen[this.state.adrestel].zipcode = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        if (that.overzdata) {
          data.adressen[this.state.adrestel].country_txt = that.overzdata.country_txt;
          data.adressen[this.state.adrestel].country = that.overzdata.country;
        }
        break;

      case 'country_txt':
        data.adressen[this.state.adrestel].country_txt = waarde.value;
        data.adressen[this.state.adrestel].country = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      case 'taalcode_txt':
        data.taalcode_txt = waarde.text;
        data.taalcode = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'betaalvoorw_txt':
        data.betaalvoorw_txt = waarde.text;
        data.betaalvoorw = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'zendwijze_txt':
        data.zendwijze_txt = waarde.text;
        data.zendwijze = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'adres1':
        data.adressen[this.state.adrestel].adres1 = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      case 'adres2':
        data.adressen[this.state.adrestel].adres2 = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      case 'housenum':
        data.adressen[this.state.adrestel].housenum = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      case 'vatnumber':
        data[colId] = waarde.toUpperCase();
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.toUpperCase(), colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      default:
        data[colId] = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;
    }
    that.setState({ error: terug })
    terug = await this.CheckSave(data);
    this.setState({ save: terug, data: data, checkval: false })
  }      
  

  async ClosePopJobs() {

    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    await this.zetCalendar()    
  }
  async ClosePopZipcode(retdata) {
    let data = this.state.data;
    this.setState({ PopUpScreen: null });
    if (retdata) 
    {
        data.adressen[this.state.adrestel].zipcode_txt = retdata.zipcode + ' - '+retdata.place;
        data.adressen[this.state.adrestel].zipcode = retdata.id;
        data.adressen[this.state.adrestel].country_txt = retdata.country_txt;
        data.adressen[this.state.adrestel].country = retdata.country;
        this.setState({data:data})
    }

    await this.props.dispatch(AllPostcodes());
  }


  async ClosePopCreaJobs(terdata) {

    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id))
    await this.props.dispatch(ZetListData('GetJobsAccount',this.props.account.MainData.data.id));
    await this.props.dispatch(zetListMain('GetJobsAccount',this.props.account.MainData.data.id));
    this.setState({ tabvalue: '5' });
    if (terdata.id)
    {
      let jobdata = await SqlFunction.DoSql('GetJob', terdata);
      let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
      window.scrollTo(0, 0);
      this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} history={this.props.history} data={jobdata} close={this.ClosePopJobs} title={title} />)));

    }

  }






  render() {
    const { classes } = this.props;
    let cx = Math.floor(window.innerWidth) > 1700 ? 200 : (200/1700) * Math.floor(window.innerWidth);
    let cy =  Math.floor(window.innerWidth) > 1700 ? 100 : (100/1700) * Math.floor(window.innerWidth);
    let inner =  Math.floor(window.innerWidth) > 1700 ? 80 : (80/1700) * Math.floor(window.innerWidth);
    let outer =  Math.floor(window.innerWidth) > 1700 ? 120 : (120/1700) * Math.floor(window.innerWidth);

    return (
      <div >
        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px'}}>
          <Grid container>
            <Grid item xs={8}>          
              <h4 style={{cursor:'pointer'}} tag="h4" className="mt-3" onClick={() => {this.setState({edit:false, editfact:false})}}>Welkom {this.props.user.userData.first_name}</h4>  
            </Grid>
            <Grid item xs={4}>  
            </Grid>
          </Grid>
        </Toolbar>
        <Grid container style={{width: 'auto', padding: '14px 10px', margin: '-15px -27px 35px'}}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Card style={{height:'450px'}} className='card_brut'>
                <CardHeader style={{background: 'transparent'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <h4 tag="h6" className="mt-3" >
                        Mijn klanten
                      </h4>  
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleAccount()}>
                        <WindowMaximize />
                      </IconButton>                      
                    </Grid>
                  </Grid>
                </CardHeader>

                <Dialog
                  open={this.state.AccountOpen}
                  style={{zIndex:'901',width:'99vw'}}
                  maxWidth='xl'
                  onClose={this.handleAccountClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent style={{width:'95vw'}}>
                    <DialogContentText id="alert-dialog-slide-description">
                      {this.state.AccountData  && this.state.AccountCol  &&
                        <TableGrid
                          tableheight="85vh"
                          columns={this.state.AccountCol}
                          data={this.state.AccountData} 
                          sizetofit={false}
                          sortable={true}
                          suppressMenu={true}
                          enableRowGroup={false}
                          suppressColumnVirtualisation={false}                                
                          filter={true}
                          sleutel='AllAccounts'
                          getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                          editable={false}
                          GridReady={this.onGridReady}
                          CellClickedEvent={(column, colDef, value) => this.AccCellClick(column,colDef, value)}
                        />
                      }    
                    </DialogContentText>
                  </DialogContent>
                </Dialog>

                <CardBody style={{textAlign:'center'}}>
                  {this.state.AccountData  && this.state.AccountCol  &&
                    <TableGrid
                      tableheight="350px"
                      columns={this.state.AccountCol}
                      data={this.state.AccountData} 
                      sizetofit={false}
                      sortable={true}
                      suppressMenu={true}
                      enableRowGroup={false}
                      suppressColumnVirtualisation={false}                                
                      filter={true}
                      sleutel='AllAccounts'
                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                      editable={false}
                      GridReady={this.onGridReady}
                      CellClickedEvent={(column, colDef, value) => this.AccCellClick(column,colDef, value)}
                    />
                  }             

                </CardBody>


              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card style={{height:'450px'}}  className='card_brut'>
                <CardHeader style={{background: 'transparent'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleAgenda()}>
                        <WindowMaximize />
                      </IconButton>                      
                    </Grid>
                  </Grid>

                </CardHeader>

                <Dialog
                  open={this.state.AgendaOpen}
                  style={{zIndex:'901'}}
                  maxWidth='xl'
                  onClose={this.handleAgendaClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                       <BigCalendar
                        popup
                        style={{minWidth: '80vw',minHeight:'80vh',fontSize:'0.75em',overflow: 'auto'}}
                        onSelectEvent={event => this.SelectDatum(event)}
                        selectable={true}
                        onSelectSlot={event => this.SelectDatum(event)}

                        localizer={localizer}
                        events={this.state.lst_jobs}
                        components={{event: Event,}} 
                        startAccessor="start"
                        endAccessor="end"
                    />
                    </DialogContentText>
                  </DialogContent>
                </Dialog>

                <CardBody style={{textAlign:'center'}}>
                  {this.state.lst_jobs  &&
                  <BigCalendar

                      style={{minHeight: '350px',maxHeight:'350px',fontSize:'0.75em',overflow: 'auto'}}
                      localizer={localizer}
                      views={['month', 'day', 'week']}
                      onSelectEvent={event => this.SelectDatum(event)}
                      events={this.state.lst_jobs}
                      components={{event: EventSmall,}} 
                      startAccessor="start"
                      endAccessor="end"
                  />
                  }             

                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid container style={{width: 'auto', padding: '14px 10px', margin: '-15px -27px 35px', marginTop:'-70px'}}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Card style={{height:'450px'}}  className='card_brut'>
                <CardHeader style={{background: 'transparent'}}>
                  <Grid container spacing={1}>
                    <Grid style={{paddingTop:'50px'}} item xs={12}>
                      <Slider
                        defaultValue={this.state.jaar}
                        getAriaValueText={this.valuejaar}
                        onChangeCommitted={(event,value) => this.JaarChange(event,value)}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="on"
                        step={1}
                        marks
                        min={2015}
                        max={2050}
                      />                                        
                    </Grid>

                  </Grid>

                </CardHeader>
                <CardBody style={{textAlign:'center'}}>
                  {this.state.grafjobstatus  &&
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <div style={{ width: '100%', height: '400px', paddingLeft:'10%',paddingTop:'20px' }}>
                            <ResponsiveContainer width="100%" height="100%">
                              <PieChart>
                                <Pie
                                  activeShape={renderActiveShape}
                                  activeIndex={this.state.PieJobStatIndex}                          
                                  data={this.state.grafjobstatus}
                                  cx={cx}
                                  cy={cy}
                                  innerRadius={inner}
                                  outerRadius={outer}
                                  fill="#8884d8"
                                  dataKey="value"
                                  onMouseEnter={this.onPieJobStatEnter}
                                  onClick={this.onPieJobStatClick}

                                >
                                  {
                                    this.state.grafjobstatus.map((entry, index) => 
                                      <Cell key={`cell-${index}`} fill={this.state.grafjobstatus[index].color} />)
                                  }                        
                                </Pie>
                              </PieChart>
                            </ResponsiveContainer>

                        </div>
                      </Grid>
                    </Grid>
                  }
                </CardBody>
              </Card>
            </Grid>

            <Grid item xs={8}>
              <Card style={{height:'450px'}} className='card_brut'>
                <CardHeader style={{background: 'transparent'}}>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <h4 tag="h6" className="mt-3" >
                        {this.state.jobtitle}
                      </h4>  
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleJob()}>
                        <WindowMaximize />
                      </IconButton>                      
                    </Grid>
                  </Grid>
                </CardHeader>

                <Dialog
                  open={this.state.JobOpen}
                  style={{zIndex:'901',width:'99vw'}}
                  maxWidth='xl'
                  onClose={this.handleJobClose}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent style={{width:'95vw'}}>
                    <DialogContentText id="alert-dialog-slide-description">
                      {this.state.JobsData  && this.state.JobsCol  &&
                        <TableGrid
                          tableheight="85vh"
                          columns={this.state.JobsCol}
                          data={this.state.JobsData} 
                          sizetofit={false}
                          sortable={true}
                          suppressMenu={true}
                          enableRowGroup={false}
                          suppressColumnVirtualisation={false}                                
                          filter={true}
                          sleutel='AllJobsUser'
                          getContextMenuItems={(param) => this.getContextMenuItems(param,'AllJobsUser')}                     
                          editable={false}
                          GridReady={this.onGridReady}
                          CellClickedEvent={(column, colDef, value) => this.JobCellClick(column,colDef, value)}
                        />
                      }    
                    </DialogContentText>
                  </DialogContent>
                </Dialog>

                <CardBody style={{textAlign:'center'}}>
                  {this.state.JobsData  && this.state.JobsCol  &&
                    <TableGrid
                      tableheight="350px"
                      columns={this.state.JobsCol}
                      data={this.state.JobsData} 
                      sizetofit={false}
                      sortable={true}
                      suppressMenu={true}
                      enableRowGroup={false}
                      suppressColumnVirtualisation={false}                                
                      filter={true}
                      sleutel='AllJobsUser'
                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllJobsUser')}                     
                      editable={false}
                      GridReady={this.onGridReady}
                      CellClickedEvent={(column, colDef, value) => this.JobCellClick(column,colDef, value)}
                    />
                  }             

                </CardBody>


              </Card>
            </Grid>
          </Grid>
        </Grid>
        {this.state.PopUpScreen}
      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(UserDashBoard));
