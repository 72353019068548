import {
  ALLJOBSUBJ_SUCCESS, ALLJOBS_SUCCESS, ALLJOBDEFMAIN_SUCCESS,ALLJOBDEFMAININDEX_SUCCESS,ALLJOBDEFMAINACC_SUCCESS, JOBMAIN_SUCCESS, GETSUBJOBMAIN_SUCCESS, GETSUBJOBTARIEF_SUCCESS,
  GETJOBDEFWORKFLOW_SUCCESS, ALLJOBSTATUS_SUCCESS, ALLJOBLAYOUT_SUCCESS, ALLVELDEN_SUCCESS, ALLJOBSTOINVOICE_SUCCESS,ALLJOBSTOINVOICEACCOUNT_SUCCESS, ALLREGISTRATIEACC_SUCCESS,
  JOBDEFMAINALLCODES_SUCCESS, ALLBETAALFIN_SUCCESS, BETAALFIN_SUCCESS,GETSUBBETAALFIN_SUCCESS, ALLVATREFCODE_SUCCESS,ALLVATREFCODELAND_SUCCESS,
  VATREFCODE_SUCCESS, GETSUBVATREFCODE_SUCCESS,ALLJOBREGNUMACC_SUCCESS, ALLJOBSNEW_SUCCESS, JOBDEFLISTCODES_SUCCESS} from '../constants/';



const INIT_STATE = {
    AllJobSubjData: null,
    AllJobDefMainData: null,
    AllJobDefMainIndexData: null,
    AllJobDefMainAccData: null,
    AllRegistratieAccData: null,
    AllJobRegNumAccData:null,
    JobDefMainAllCodesData: null,
    JobDefListCodesData: null,
    JobDefMainData: null,
    BetaalFinData:null,
    ALLBetaalFinData:null,
    SubJobMainData: null,
    SubJobTariefData: null,
    SubBetaalFinData: null,
    JobDefWorkflowData: null,
    AllJobsData: null,
    AllJobsNewData: null,
    AllJobStatusData: null,
    AllJobLayoutData: null,
    AllVeldenData: null,
    AllJobsToInvoiceAccountData: null,
    AllJobsToInvoiceData: null,
    AllJobsAccountData: null,
    AllVatRefCodeData:null,
    AllVatRefCodeLandData:null,
    SubVatRefCodeData:null,
    VatRefCodeData:null,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ALLJOBSUBJ_SUCCESS:
            return {
                ...state,
                AllJobSubjData: action.payload
            };

        case ALLVATREFCODE_SUCCESS:
            return {
                ...state,
                AllVatRefCodeData: action.payload
            };

        case ALLVATREFCODELAND_SUCCESS:
            return {
                ...state,
                AllVatRefCodeLandData: action.payload
            };

        case GETSUBVATREFCODE_SUCCESS:
            return {
                ...state,
                SubVatRefCodeData: action.payload
            };

        case JOBDEFLISTCODES_SUCCESS:
            return {
                ...state,
                JobDefListCodesData: action.payload
            };

        case VATREFCODE_SUCCESS:
            return {
                ...state,
                VatRefCodeData: action.payload
            };

        case ALLJOBS_SUCCESS:
            return {
                ...state,
                AllJobsData: action.payload
            };

        case ALLJOBSNEW_SUCCESS:
            return {
                ...state,
                AllJobsNewData: action.payload
            };

        case ALLVELDEN_SUCCESS:
            return {
                ...state,
                AllVeldenData: action.payload
            };

        case GETJOBDEFWORKFLOW_SUCCESS:
            return {
                ...state,
                JobDefWorkflowData: action.payload
            };

        case GETSUBJOBTARIEF_SUCCESS:
            return {
                ...state,
                SubJobTariefData: action.payload
            };

        case ALLJOBDEFMAIN_SUCCESS:
            return {
                ...state,
                AllJobDefMainData: action.payload
            };

        case ALLJOBDEFMAININDEX_SUCCESS:
            return {
                ...state,
                AllJobDefMainIndexData: action.payload
            };

        case ALLJOBDEFMAINACC_SUCCESS:
            return {
                ...state,
                AllJobDefMainAccData: action.payload
            };

        case ALLREGISTRATIEACC_SUCCESS:
            return {
                ...state,
                AllRegistratieAccData: action.payload
            };

        case ALLJOBREGNUMACC_SUCCESS:
            return {
                ...state,
                AllJobRegNumAccData: action.payload
            };
 

        case ALLBETAALFIN_SUCCESS:
            return {
                ...state,
                AllBetaalFinData : action.payload
            };

        case BETAALFIN_SUCCESS:
            return {
                ...state,
                BetaalFinData : action.payload
            };

        case GETSUBJOBMAIN_SUCCESS:
            return {
                ...state,
                SubJobMainData: action.payload
            };

        case GETSUBBETAALFIN_SUCCESS:
            return {
                ...state,
                SubBetaalFinData: action.payload
            };


        case JOBMAIN_SUCCESS:
            return {
                ...state,
                JobDefMainData: action.payload
            };

        case ALLJOBSTATUS_SUCCESS:
            return {
                ...state,
                AllJobStatusData: action.payload
            };

        case ALLJOBLAYOUT_SUCCESS:
            return {
                ...state,
                AllJobLayoutData: action.payload
            };

        case ALLJOBSTOINVOICEACCOUNT_SUCCESS:
            return {
                ...state,
                AllJobsToInvoiceAccountData: action.payload
            };

        case ALLJOBSTOINVOICE_SUCCESS:
            return {
                ...state,
                AllJobsToInvoiceData: action.payload
            };

        case JOBDEFMAINALLCODES_SUCCESS:
            return {
                ...state,
                JobDefMainAllCodesData: action.payload
            };

        default:
            return state;

        
    }
};
