import React from 'react';
import moment from 'moment'
import 'moment/locale/nl-be'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";

const mapStateToProps = state => ({
  ...state
});

class PopJobCreaJaar extends React.Component {
    state = {
        jaar: '',
        account_name:null, 
        land_txt:null, 
        type_txt:null,
        data: this.props.data.data,
        staffel: this.props.jobs.AllJobDefMainIndexData,
        filename:'',
        save:false,
        tabvalue:'1',
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.CellCheck = this.CellCheck.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);


    }
    async handleTabChange(event, newValue) {
    }
    componentDidMount(){
        this.setState({data:this.props.data.data,staffel: this.props.jobs.AllJobDefMainIndexData})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave()
    {
      // Bewaren gegevens
      let data = this.props.registration.AllRegData && this.props.registration.AllRegData.data ? this.props.registration.AllRegData.data : [];
      for (var teller = 0; teller < data.length; teller++) 
      {
          let stdata = data[teller];
          if (stdata.aan_jaar !== this.state.data.jaar && stdata.dat_stop.length < 8)
          {
            await this.setState({account_name:stdata.account_name, land_txt:stdata.land_txt, type_txt:stdata.type_txt})
            if (stdata.typenum === '1')
            {
              stdata.registration_id = stdata.id;
              stdata.jaar = this.state.data.jaar;
              await SqlFunction.DoSql('CreaJobAanReg',stdata);
            }
            if (stdata.typenum === '3')
            {
              stdata.registration_id = stdata.id;
              stdata.jaar = this.state.data.jaar;
              await SqlFunction.DoSql('CreaJobAanVat',stdata);
            }

          }
      }
      if (this.props.close) 
      {
          this.props.close()
      }
    }

    async ErrorCheck(that, waarde, type, colId) {
      let terug = false;
      if (colId === 'datum')
      {
        var utc = moment(waarde, "DD-MM-YYYY", true)
        if(!utc.isValid()) {terug = true}
      }
      that.setState({ error: terug })
    } 


    ValueCheck(that,waarde,type,colId) {
      let data = this.state.data;
      let save = false;
      data[colId] = waarde;
      if (data.jaar && data.jaar > 2015) {save = true}
      this.setState({data:data,save:save})
    }       

    async CellClick(waarde) {
        return;
    }

    async CellCheck(params,waarde,datacol) {
        var colId = params.props.column.getId();
        params.props.data.save = true;
        if (colId === 'chk_job')
        {
          if (params.props.data.chk_job === '1')
          {
             params.props.data.chk_job = '0';
          }
          else
          {
            params.props.data.chk_job = '1';   
          }
        }

        if (colId === 'chk_account')
        {
          if (params.props.data.chk_account === '1')
          {
             params.props.data.chk_account = '0';
          }
          else
          {
            params.props.data.chk_account = '1';   
          }
        }
    }       





    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var top = '50px';
        var left = '0px';
        var bottom = '10px';


        return (
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">Initialisatie Jobs voor gans jaar</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} md={4} lg={4}>
                      <EditString className="card-title m-t-10"
                        value={this.state.data.jaar}
                        coltype = 'STR' 
                        format = {[/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}
                        labelText="Jaar"
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'jaar')}
                      />
                    </Grid>
                      {this.state.account_name &&
                      <Grid item sm={8} md={8} lg={8}>
                        <small className="text-muted p-t-30 db">Klant</small>
                        <h6>{this.state.account_name}</h6>
                        <small className="text-muted p-t-30 db">Activiteit</small>
                        <h6>{this.state.type_txt}</h6>
                        <small className="text-muted p-t-30 db">Land</small>
                        <h6>{this.state.land_txt}</h6>
                      </Grid>
                      }
                  </Grid>
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  {this.state.save &&
                    <Button color="primary"  onClick={() => this.handleSave()}>
                      Opstarten verwerking
                    </Button>
                  }
                </DialogActions>
            </Dialog>
        );
    }
}


PopJobCreaJaar.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobCreaJaar)));

