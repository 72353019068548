import React, {createRef } from 'react';
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import Maplegende from '@material-ui/icons/Maplegende';
import AccountMultiPlus from '@material-ui/icons/AccountMultiPlus';
import BookPlus from '@material-ui/icons/BookPlus';
import GraphicEq from '@material-ui/icons/GraphicEq';
import NoteAdd from '@material-ui/icons/NoteAddOutlined';
import FormatList from '@material-ui/icons/FormatListBulleted'
import Cashplus from '@material-ui/icons/Cashplus'
import Tooltip from "@material-ui/core/Tooltip";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import { Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import EditCheck from "../../components/editcheck.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import FileDownload from "../../components/FileDownload.jsx";
import EditSelect from "../../components/editselect.jsx";
import Timeline from "../../components/TimeLine.jsx";
import {AllPostcodes, AllBtwRegimes} from "../../redux/settings/paramActions";
import {setTab,GetAccount,GetDocAccount,GetVolmAccount,GetRegAccount,GetContAccount,CreateAccount,GetTarAccount, GetJobsAccount, GetAccijnzenAccount, GetVatrefundAccount, GetDirregAccount, GetAccChanges, GetAllFacturenAccount, GetCorAccount, GetFacturatieAccount} from "../../redux/settings/accountActions";
import {CreateContact,GetContact,AllContacts} from "../../redux/settings/contactActions";
import {setSubTitle,zetview,clickview,zetListMain,ZetListData,zetpopup,resetview} from "../../redux/settings/action";
import {AllUsers,GetUser} from "../../redux/settings/userActions";
import {CreateRegistration,GetRegistration} from "../../redux/settings/registrationActions";
import {setPanelRight, setTitleRight} from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import Dropzone from 'react-dropzone';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SubSpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SubSpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import SaveIcon from '@material-ui/icons/Save';
import FilePlus from '@material-ui/icons/FilePlus';
import Expences from '@material-ui/icons/Expences';
import ChangedAccData from '@material-ui/icons/BorderColor';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import CalendarPlus from '@material-ui/icons/CalendarPlus';

import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";

import PopJobAanvrNum from "../jobs/PopJobAanvrNum.jsx";
import PopCreateJob from "../jobs/PopCreateJob.jsx";
import PopJobKlantVatInfo from "../jobs/PopJobKlantVatInfo.jsx";
import PopJobKlantDirInfo from "../jobs/PopJobKlantDirInfo.jsx";

import PopDetailCor from "../templates/PopDetailCor.jsx";
import PopKlantGegevens from './PopKlantGegevens.jsx';
import PopAddZipCode from "../zipcode/PopAddZipcode.jsx";
import PopDetailVolm from "./PopDetailVolm.jsx";
import PopAccTarieven from "./PopAccTarieven.jsx";



import Slider from '@material-ui/core/Slider';

import Factuurview from './Factuurview.jsx';
import { GetFactuuropbouw } from '../../redux/settings/landActions.js';
import PdfBoxBrutax from '@material-ui/icons/PictureAsPdf';
import PdfBox from '@material-ui/icons/PictureAsPdfOutlined'
import Next from '@material-ui/icons/Redo';
import Previous from '@material-ui/icons/Undo';


let factuurklantemail = ''
var gridapi = null;
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);


const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point 
  if (dropzoneRef.current) {
    dropzoneRef.current.open()
  }
};
moment.locale('nl-be');
const accdropid = GenFunc.MakeId()
const accframeid = GenFunc.MakeId()
const accformid = GenFunc.MakeId()

var dropdoc = null;

const mapStateToProps = state => ({
  ...state
});


class Accountpage extends React.Component {

  state = {
    DownloadData: { id: '' },
    tableheight: Math.floor(window.innerHeight * .75) + 'px',
    tabvalue: this.props.account && this.props.account.LastTab ? this.props.account.LastTab : '1',
    regtabvalue:'DIR',
    jobtabvalue:'DIR',
    edit: this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id === 0 ? true : false,
    editfact: this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id === 0 ? true : false,
    save: false,
    subsave: false,
    subspeedzien:true,
    adrestel: 0,
    sleutelreg:'RegAccounts',
    sleuteljob:'GetJobsAccount',

    PopUpScreen:null,
    checkval: false,
    jobgraph:false,
    workflowstories:null,
    jaar:new Date().getFullYear(),
    data: this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id === 0 ? this.props.account.MainData.data : null,
    speedactions: [
      { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
      { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe klant maken' },
      { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
      { icon: <Maplegende />, name: 'Fgov', tooltip: 'Naar site Fgov' },
    ],
    subspeedactions: [
      { icon: <AccountMultiPlus />, name: 'Contact', tooltip: 'Nieuwe contactpersoon' },
      { icon: <BookPlus />, name: 'NewRegistratie', tooltip: 'Nieuwe activiteit' },
      { icon: <ChangedAccData />, name: 'ChangedAccData', tooltip: 'Gewijzigde klantgegevens' },
    ],
    speedopen: false,
    subspeedopen: false, 
    tabsubspeedzien:true,
    invoicestatus: "overview",
    canMakeInvoicePdf: false,
    canMakeInvoice: true,
    canSaveInvoice: false,
    factuurkey: 1,
    invoicePayed: false,
    invoiceMailed: false,
    invoicePrinted: false,
    invoiceData: "",
    factuurklantemail: "",
  };

  constructor(props, context) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);
    this.onGridReady = this.onGridReady.bind(this);
    this.onGridReadyJobs = this.onGridReadyJobs.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.ValueCheck = this.ValueCheck.bind(this);
    this.ErrorCheck = this.ErrorCheck.bind(this);
    this.adrchange = this.adrchange.bind(this);
    this.Fgov = this.Fgov.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.SecValueCheck = this.SecValueCheck.bind(this);
    this.SecCellClick = this.SecCellClick.bind(this);
    this.DocCellClick = this.DocCellClick.bind(this);
    this.VolmCellClick = this.VolmCellClick.bind(this);

    this.CorCellClick = this.CorCellClick.bind(this);

    this.RegCellClick = this.RegCellClick.bind(this);

    this.ContCellClick = this.ContCellClick.bind(this);
    this.ContDetClick = this.ContDetClick.bind(this);
    this.KlantFactClick = this.KlantFactClick.bind(this);
    this.UserDetClick = this.UserDetClick.bind(this);

    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
    this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);

    this.ZetEdit = this.ZetEdit.bind(this);
    this.ZetEditFact = this.ZetEditFact.bind(this);
    this.CreaAcount = this.CreaAcount.bind(this);
    this.SecSaveAll = this.SecSaveAll.bind(this);
    this.CheckSave = this.CheckSave.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleTabRegChange = this.handleTabRegChange.bind(this);
    this.handleTabJobChange = this.handleTabJobChange.bind(this);

    this.MainSave = this.MainSave.bind(this);
    this.checkconfirm = this.checkconfirm.bind(this);
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);

    this.handleJa = this.handleJa.bind(this);
    this.ActionClick = this.ActionClick.bind(this);
    this.SubActionClick = this.SubActionClick.bind(this);
    this.TariefCellClick = this.TariefCellClick.bind(this);
    this.JobCellClick = this.JobCellClick.bind(this);
    this.ClosePopJobs = this.ClosePopJobs.bind(this);
    this.ClosePopCreaJobs = this.ClosePopCreaJobs.bind(this);
    this.ClosePopCreaTarief = this.ClosePopCreaTarief.bind(this);
    this.ClosePopCreaTariefIng = this.ClosePopCreaTariefIng.bind(this);
    this.ClosePopDetailCor = this.ClosePopDetailCor.bind(this);
    this.ClosePopDetailVol = this.ClosePopDetailVol.bind(this);

    this.CheckOnhold = this.CheckOnhold.bind(this)

    this.ClosePopZipcode = this.ClosePopZipcode.bind(this);

    this.ClosePopJobKlantVatInfo = this.ClosePopJobKlantVatInfo.bind(this);
    this.OpenPopJobKlantVatInfo = this.OpenPopJobKlantVatInfo.bind(this);

    this.ClosePopJobKlantDirInfo = this.ClosePopJobKlantDirInfo.bind(this);
    this.OpenPopJobKlantDirInfo = this.OpenPopJobKlantDirInfo.bind(this);


    this.valuejaar = this.valuejaar.bind(this);
    this.JaarChange = this.JaarChange.bind(this);
    this.GetGrafJob = this.GetGrafJob.bind(this);
    this.getGrafJobStories = this.getGrafJobStories.bind(this);
    this.ClosePopChangedAccData = this.ClosePopChangedAccData.bind(this);
    this.handleFactuurviewUpdates = this.handleFactuurviewUpdates.bind(this);
    this.makeInvoicePdf = this.makeInvoicePdf.bind(this);
    this.makeInvoice = this.makeInvoice.bind(this);
    this.getInvoiceDetails = this.getInvoiceDetails.bind(this);
    this.handleInvoicePayed = this.handleInvoicePayed.bind(this);
    this.handleInvoiceSend = this.handleInvoiceSend.bind(this);
    this.handleInvoiceSave = this.handleInvoiceSave.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.TitleClick = this.TitleClick.bind(this)

  }

  handleFactuurviewUpdates(status){
    this.setState({ invoicestatus: status })
  }

  makeInvoicePdf(canMakeInvoicePdf){
    let payed;
    let printed;
    let mailed;
    let invoicedata;
    if (canMakeInvoicePdf && this.props.account.MainFactuurData) {
      invoicedata = this.props.account.MainFactuurData.data;
      payed = this.props.account.MainFactuurData.data.betaalstatus === "Betaald" ? true : false;
      switch (this.props.account.MainFactuurData.data.zendwijze_status) {
        case "Mail&Print":
          printed = true;
          mailed = true;
          break;

        case "Print":
          printed = true;
          mailed = false;
          break;

        case "Mail":
          printed = false;
          mailed = true;
          break;

        case "Te verzenden":
          printed = false;
          mailed = false;
          break;

        default:
          return;
      }
    }
    this.setState({ canMakeInvoicePdf: canMakeInvoicePdf, invoicePayed: payed, invoiceMailed: mailed, invoicePrinted: printed, invoiceData: invoicedata })
  }

  makeInvoice(canMakeInvoice){
    this.setState({ canMakeInvoice: canMakeInvoice })
  }

  sendMail() {
    window.location = "mailto:" + factuurklantemail;
  }

 async componentWillReceiveProps(nextProps, nextState) {
    if (this.props.account.MainData) {

      if (this.props.account.MainData.data.id !== nextProps.account.MainData.data.id) {
        let prevtab = this.state.tabvalue
        await this.checkconfirm(this.props.account.MainData);
        this.props.dispatch(resetview())
        this.props.dispatch(zetpopup(null));
        let tabjob = this.state.jobtabvalue;
        if (prevtab === '6')
        {
           await this.setState({ jobtabvalue: '' })
        }

        if (nextProps.account.MainData.data.id === 0) 
        {
          await this.setState({ data:nextProps.account.MainData.data, edit:true, editfact:true, save:false, subsave:false, PopUpScreen:null })
          this.handleTabChange(null,'2')
        }
        else {

          await this.props.dispatch(GetDocAccount(nextProps.account.MainData.data.id))
          await this.setState({ edit: false, editfact: false, save: false, subsave: false, PopUpScreen:null })
          this.handleTabChange(null,prevtab)

          if (prevtab === '6')
          {
              let sleuteljob = 'GetJobsAccount';
            if (tabjob === 'DIR') {
              await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,1))
              sleuteljob = 'GetJobsAccountDir';
            }
            if (tabjob === 'ACC') {
                await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,2))
                sleuteljob = 'GetJobsAccountAcc';
            }
            if (tabjob === 'VAT') {
                await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,3))
                sleuteljob = 'GetJobsAccountVat';
            }
            if (tabjob === 'DIVA') {
                await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,4))
                sleuteljob = 'GetJobsAccountDiv';
            }

            this.setState({ sleuteljob:sleuteljob});
            if (this.state.jobgraph)
            {
              this.GetGrafJob(this.state.jaar);
            }
            await this.handleTabJobChange(null, tabjob)    
            await this.setState({ jobtabvalue: tabjob })
          }
        }
      }
    }
  }



  getInvoiceDetails() {
    let details = [];

    details.push(
      <div style={{ width: '100%', marginTop: "25px", display: "flex", flexDirection: "column" }} key={"invoiceDetails"}>
        <h4 style={{ marginTop: '25px' }} className="card-title m-t-10">{this.state.klant}</h4>
        <h6 style={{ marginTop: '15px', marginBottom: "15px" }} className="card-title m-t-10">Facturatiegegevens</h6>
        <small className="text-muted p-t-30 db">Factuurtype</small>
        <h6>{this.props.account.MainFactuurData.data.type}</h6>
        <small className="text-muted p-t-30 db">Taalcode</small>
        <h6>{this.props.account.MainFactuurData.data.taalcode_txt}</h6>
        <small className="text-muted p-t-30 db">Betalingsvoorwaarden</small>
        <h6>{this.props.account.MainFactuurData.data.factdetails_payment}</h6>
        <small className="text-muted p-t-30 db">Btw regime</small>
        <h6>{this.props.account.MainFactuurData.data.factdetails_vatreg}</h6>
        <small className="text-muted p-t-30 db">Zendwijze factuur</small>
        <h6>{this.props.account.MainFactuurData.data.factdetails_sendmethod}</h6>
        <small className="text-muted p-t-30 db">Facturatieklant</small>
        <h6>{this.props.account.MainFactuurData.data.factklant_name}</h6>
        <small className="text-muted p-t-30 db">Email facturatieklant</small>
        <h6 onClick={this.sendMail} style={{ cursor: "pointer", textDecoration: "underline" }}>{this.props.account.MainFactuurData.data.factdetails_mail}</h6>
        <div className='div_line' style={{ marginTop: '25px', marginBottom: '25px' }}></div>
        <h6 style={{ marginTop: '15px', marginBottom: "15px" }} className="card-title m-t-10">Betaalstatus</h6>
        <div style={{ width: "100%", textAlign: "center" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <h6 className="text-muted p-t-30 db" style={{ marginLeft: "10px" }}>Betaald</h6>
            <Checkbox
              name={"betaald"}
              onClick={this.handleInvoicePayed}
              checked={this.state.invoicePayed}
              style={{ color: "#137cbd", marginTop: "-15px" }}
            />
          </div>
        </div>
        <h6 style={{ marginTop: '15px', marginBottom: "15px" }} className="card-title m-t-10">Zendwijze status</h6>
        {!this.state.invoicePayed && <div id={"zendwijzeCheck"} style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <h6 className="text-muted p-t-30 db">Mail</h6>
            <Checkbox
              name={"mail"}
              onClick={this.handleInvoiceSend}
              checked={this.state.invoiceMailed}
              style={{ color: "#137cbd", marginTop: "-15px" }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Checkbox
              name={"print"}
              onClick={this.handleInvoiceSend}
              checked={this.state.invoicePrinted}
              style={{ color: "#137cbd", marginTop: "-15px" }}
            />
            <h6 className="text-muted p-t-30 db">Print</h6>
          </div>
        </div>}
        {this.state.invoicePayed && <div id={"zendwijzeCheck"} style={{ display: "flex", flexDirection: "row", justifyContent: "center", pointerEvents: "none", opacity: "0.5" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <h6 className="text-muted p-t-30 db">Mail</h6>
            <Checkbox
              name={"mail"}
              onClick={this.handleInvoiceSend}
              checked={this.state.invoiceMailed}
              style={{ color: "#137cbd", marginTop: "-15px" }}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Checkbox
              name={"print"}
              onClick={this.handleInvoiceSend}
              checked={this.state.invoicePrinted}
              style={{ color: "#137cbd", marginTop: "-15px" }}
            />
            <h6 className="text-muted p-t-30 db">Print</h6>
          </div>
        </div>}
        <div className='div_line' style={{ marginTop: '25px', marginBottom: '25px' }}></div>
      </div>
    );

    return details;
  }

  async handleInvoicePayed(event) {
    let status;
    if (event.target.checked) {
      status = "Betaald";
    } else {
      status = "Niet betaald";
    }
    let updInvoiceData = { ...this.state.invoiceData }
    updInvoiceData.betaalstatus = status;
    this.setState({ invoiceData: updInvoiceData, invoicePayed: event.target.checked })
    await SqlFunction.DoSql('ChgFactuur', updInvoiceData)

    if (status === "Betaald") {
      let check = document.getElementById("zendwijzeCheck");
      check.style.pointerEvents = "none";
      check.style.opacity = "0.5";
    } else {
      let check = document.getElementById("zendwijzeCheck");
      check.style.pointerEvents = "auto";
      check.style.opacity = "1";
    }
  }

  handleInvoiceSend(event) {
    let printed = this.state.invoicePrinted;
    let mailed = this.state.invoiceMailed;

    switch (event.target.name) {
      case "print":
        printed = event.target.checked;
        break;

      case "mail":
        mailed = event.target.checked;
        break;

      default:
        return;
    }

    this.setState({ invoicePrinted: printed, invoiceMailed: mailed }, async function () {
      let status;
      if (this.state.invoicePrinted && this.state.invoiceMailed) {
        status = "Mail&Print"
      } else if (this.state.invoicePrinted && !this.state.invoiceMailed) {
        status = "Print"
      } else if (!this.state.invoicePrinted && this.state.invoiceMailed) {
        status = "Mail";
      } else {
        status = "Te verzenden"
      }
      let updInvoiceData = { ...this.state.invoiceData }
      updInvoiceData.zendwijze_status = status;
      this.setState({invoiceData:updInvoiceData})
      await SqlFunction.DoSql('ChgFactuur', updInvoiceData)
    });

  }

  handleInvoiceSave(canSave) {
    this.setState({ canSaveInvoice: canSave })
  }

  valuejaar(value: number) {
    return `${value}`;
  }

  JaarChange(event,value) {
    this.GetGrafJob(value);
    this.setState({jaar:value})
  }

  async GetGrafJob(jaar) {
    let data = {id:this.props.account.MainData.data.id,jaar:jaar}
    switch (this.state.jobtabvalue) 
    {

        case 'DIR':
          data.soort = 1;
          break
        case 'ACC':
          data.soort = 2;
          break
        case 'VAT':
          data.soort = 3;
          break

        default:    
          break;
    }

    let retdata = await SqlFunction.DoSql('GetGrafAccJob', data);
    this.getGrafJobStories(retdata)
  }

  async ClosePopCreaJobs(terdata) {

    this.setState({ PopUpScreen: null });
    if (this.jobsgridapi) {this.jobsgridapi.refreshData()}
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    if (terdata && terdata.id)
    {
      let jobdata = await SqlFunction.DoSql('GetJob', terdata);
      if (jobdata)
      {
          let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
          window.scrollTo(0, 0);
          this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} data={jobdata} close={this.ClosePopJobs} title={title} />)));
      }
    }
  }

  async ClosePopCreaTarief(terdata,jaar) {
    this.setState({ PopUpScreen: null });
    await this.props.dispatch(GetTarAccount(this.props.account.MainData.data.id))

  }

  async ClosePopCreaTariefIng() {
    this.setState({ PopUpScreen: null });
    await this.props.dispatch(GetTarAccount(this.props.account.MainData.data.id))
  }

  async CheckOnhold()
  {
      if (this.props.account.MainData && this.props.account.MainData.data && (this.props.account.MainData.data.onhold === '1' || this.props.account.MainData.data.onhold === true))
      {
        // Is nummer in aanvraag ? en geen nummer
          confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,        
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Deze klant staat onhold, geen verwerkingen toegelaten</h1>
                  <h1>{this.props.account.MainData.data.infohold}</h1>
                  <button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Ok
                  </button>
                </div>
              );
              }
          });            
      }

  }

  async getGrafJobStories(dataworkflows) 
  {
    var stories = [];
    const { classes } = this.props;

    var teller = 0;
    let storiedata = {}
    let inverted = false
    if (dataworkflows)
    {
      for (teller = 0; teller < dataworkflows.length; teller++) 
      {
        let data = dataworkflows[teller];
        data.index = teller;
        storiedata = 
        {
          badgeColor:data.status === 'c8011e2728d9d4c2f636f067f89cc14862c' ? 'success' : 'primary',
          inverted:inverted,
          id:data.id,
          jobid:data.job_id,
          badgeIcon: data.start_date.substring(0, 5),
          index:teller,
          title:data.description,
          description:data.description,
          badgeClick: () =>this.JobCellClick({data:data}),
          titleColor: "primary",
          body: (
            <div>
              <Grid container spacing={1}>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Code</small>
                  <h6>{data.code}</h6>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Status</small>
                  <h6>{data.status_txt}</h6>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Periode</small>
                  <h6>{data.periode}</h6>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Land</small>
                  <h6>{data.country_txt}</h6>
                </Grid>
              </Grid>
            </div>
          ),
          footer: (
            <div style={{overflow:'auto'}}>
              <Stepper nonLinear activeStep={0} connector={<QontoConnector />}>
                {data.workflows.map(datavld => {
                  return (
                    <Step key={GenFunc.MakeId()}>
                      <StepButton
                        completed={datavld.completed === '1' ? true : false}
                        active={false}
                        classes={{root: classes.stepIcon}}           
  
                      >
                        {datavld.title}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>  
            </div>
          )
        }
        stories.push(storiedata);
        inverted = !inverted;
      }
    }
    this.setState({workflowstories:stories})   
  }  

  TariefChanged(params, waarde, that) {
  }                        

  TariefCheck(params, waarde) {
    var colId = params.props.column.getId();
    params.props.data.save = true;
    let terug = Validation.ValidCheck('AccountTarief', waarde, colId, params, gridapi);
    if (colId === 'jaar') {
      if (waarde < 2010 || waarde > 2100) {
        params.props.data.save = ''
        terug = true
      }
      else {
        terug = ''
      }
    }
    if (params.props.data.jaar < 2010 || params.props.data.jaar > 2100) {
      params.props.data.save = ''
    }
    if (params.props.data && params.props.data.jobid && params.props.data.jobid.length < 2) {
      params.props.data.save = '';
    }
    if (params.props.data && params.props.data.tarief && params.props.data.tarief.length < 1) {
      params.props.data.save = '';
    }
    if (!params.props.data || !params.props.data.jobid || !params.props.data.description || !params.props.data.tarief) {
      params.props.data.save = '';
    }
    if (params.props && params.props.agGridReact && params.props.agGridReact.props && params.props.agGridReact.props.rowData) {
      let items = params.props.agGridReact.props.rowData
      let result = items.filter(o => o.accountid === params.props.data.accountid && o.jobid === params.props.data.jobid && o.jaar === params.props.data.jaar);
      if (Array.isArray(result)) {
        if (result.length > 0) {
          if (params.props.data.id === 0) {
            params.props.data.save = '';
          }
          else {
            if (params.props.data.id !== result[0].id) {
              params.props.data.save = '';
            }
          }
        }
      }
    }
    params.setState({ error: terug })
  }       

  async OpenPopJobKlantDirInfo() {

        let data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.account.MainData.data.id;
        data.id = this.props.account.MainData.data.id;
        data.jaar = GenFunc.Get_Jaar();
        data.country = '';
        data.country_txt = '';
        data.periode = '';
        data.periode_txt = '';
        data.activiteit = '';
        data.activiteit_txt = '';
        data.jaaropgave = '0';

        this.setState({ PopUpScreen: (<PopJobKlantDirInfo open={true} data={data} jaar={data.jaar} close={this.ClosePopJobKlantDirInfo} title='Overzicht lijst directe reigstratie' />) });
  }
  async ClosePopJobKlantDirInfo() {
    this.setState({ PopUpScreen: null });
  }



  async OpenPopJobKlantVatInfo() {

        let data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.account.MainData.data.id;
        data.id = this.props.account.MainData.data.id;
        data.jaar = GenFunc.Get_Jaar();
        data.country = '';
        data.country_txt = '';
        data.periode = '';
        data.periode_txt = '';
        data.activiteit = '';
        data.activiteit_txt = '';

        this.setState({ PopUpScreen: (<PopJobKlantVatInfo open={true} data={data} jaar={data.jaar} close={this.ClosePopJobKlantVatInfo} title='Aanmaken job' />) });
  }
  async ClosePopJobKlantVatInfo() {
    this.setState({ PopUpScreen: null });
  }

  async TariefCellClick(waarde) {
    let column = waarde.column.colDef.field;
    var data = waarde.data

    if (column === 'copieer')
    {
      data = await SqlFunction.DoSql('CopyAccTarief',data);
    }
    if (column === 'verwijder')
    {
      data = await SqlFunction.DoSql('DeleteTarAccount',data);
      await this.props.dispatch(GetTarAccount(this.props.account.MainData.data.id))
      return;
    }
    if (data && data.id)
    {
      let popdata = await SqlFunction.DoSql('GetAccTarief',data);
      this.setState({PopUpScreen:(<PopAccTarieven open={true} data={popdata} close={this.ClosePopCreaTarief} title='Definitie Klant Tarieven' />)});
    }
    return;
  }




  async handleTabChange(event, newValue) {

    if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
    {
      if (event)
      {
        await this.checkconfirm(this.props.account.MainData);
      }
    }
    else
    {
      if (event)
      {
       this.setState({edit:false, editfact:false})
      }
    }

    let subspeedzien = false;


    if (newValue === '1') {
      // Contactpersonen
      let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'AllContacts','edit') === true) ? 
      [
        { icon: <AccountMultiPlus />, name: 'Contact', tooltip: 'Nieuwe contactpersoon' },
      ] : [];
      subspeedzien = true;
      this.setState({subspeedactions:subspeedactions})
      await this.props.dispatch(GetContAccount(this.props.account.MainData.data.id));
    }
    if (newValue === '3') {
      //Factureren
      let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows, 'Invoices', 'edit') === true) ?
        [
          { icon: <NoteAdd />, name: 'NewInvoice', tooltip: 'Nieuwe factuur' },
          { icon: <FormatList />, name: 'Overview', tooltip: 'Factuuroverzicht' },
          { icon: <PdfBox />, name: 'InvoicePdf', tooltip: 'PDF zonder briefhoofd' },
          { icon: <PdfBoxBrutax />, name: 'InvoicePdfBrutax', tooltip: 'PDF met briefhoofd' }
        ] : [];
      subspeedzien = true;
      await this.props.dispatch(GetFactuuropbouw(this.props.account.MainData.data.taalcode));
      await this.props.dispatch(GetAllFacturenAccount(this.props.account.MainData.data.id));
      await this.props.dispatch(GetFacturatieAccount(this.props.account.MainData.data.factklant));
      await this.props.dispatch(AllBtwRegimes(this.props));

      let newkey = this.state.factuurkey + 1;
      this.setState({ factuurkey: newkey, subspeedactions: subspeedactions, invoicestatus: "overview" })
    }
    if (newValue === '4') {
      let sleutelreg = 'RegAccounts';
      // Registraties
      if (this.props.account.MainData && this.props.account.MainData.data && (this.props.account.MainData.data.onhold === '1' || this.props.account.MainData.data.onhold === true))
      {
        subspeedzien = false;
        this.setState({subspeedactions:[]})
        switch (this.state.regtabvalue) 
        {

            case 'DIR':
              await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id,1))
              sleutelreg = 'RegAccountsDir';
              break
            case 'ACC':
              await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id,2))
              sleutelreg = 'RegAccountsAcc';
              break
            case 'VAT':
              await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id,3))
              sleutelreg = 'RegAccountsVat';
              break

            default:    
              await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id))
              sleutelreg = 'RegAccountsDir';
              break;
        }
        this.setState({sleutelreg:sleutelreg});
      }
      else
      {
          let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'Registration','edit') === true) ? [
            { icon: <BookPlus />, name: 'NewRegistratie', tooltip: 'Nieuwe activiteit' },
          ] : [];
          subspeedzien = true;
          switch (this.state.regtabvalue) 
          {

              case 'DIR':
                await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id,1))
                sleutelreg = 'RegAccountsDir';
                break
              case 'ACC':
                await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id,2))
                sleutelreg = 'RegAccountsAcc';
                break
              case 'VAT':
                await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id,3))
                sleutelreg = 'RegAccountsVat';
                break

              default:    
                await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id))
                sleutelreg = 'RegAccountsDir';
                break;
          }
          this.setState({sleutelreg:sleutelreg, subspeedactions:subspeedactions});
      }
    }
    if (newValue === '5') {
      // Tarieven
      let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'AccTarief','edit') === true) ? [
        { icon: <Cashplus />, name: 'NewTarief', tooltip: 'Nieuwe ingave tarieven' },
      ] : [];
      subspeedzien = true;
      this.setState({subspeedactions:subspeedactions})

      await this.props.dispatch(GetTarAccount(this.props.account.MainData.data.id))
      await this.props.dispatch(ZetListData('AccountTarief'));
      await this.props.dispatch(zetListMain('AccountTarief'));
    }

    if (newValue === '6')
    {
      let sleuteljob = 'GetJobsAccount'
      if (this.props.account.MainData && this.props.account.MainData.data && (this.props.account.MainData.data.onhold === '1' || this.props.account.MainData.data.onhold === true))
      {
        subspeedzien = false;
        this.setState({subspeedactions:null})
      }
      else
      {
          let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'Jobs','edit') === true) ? [
              { icon: <CalendarPlus />, name: 'Job', tooltip:'Nieuwe job maken'  },
              { icon: <Expences />, name: 'Onkostennota', tooltip: 'Onkostennota' },
              { icon: <GraphicEq />, name: 'Grafische voorstelling', tooltip: 'Grafische voorstelling' },
              ] : [];
          this.setState({subspeedactions:subspeedactions})
          subspeedzien = true;
      }


      switch (this.state.jobtabvalue) 
      {

          case 'DIR':
            await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,1))
            sleuteljob = 'GetJobsAccountDir';

            break
          case 'ACC':
            await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,2))
            sleuteljob = 'GetJobsAccountAcc';

            break
          case 'VAT':
            await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,3))
            sleuteljob = 'GetJobsAccountVat';

            break

          case 'DIVA':
            await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,4))
            sleuteljob = 'GetJobsAccountDiv';

            break

          default:    
            await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id))
            sleuteljob = 'GetJobsAccount';
            break;
      }
      this.setState({sleuteljob:sleuteljob});      
      await this.props.dispatch(ZetListData('GetJobsAccount',this.props.account.MainData.data.id));
      await this.props.dispatch(zetListMain('GetJobsAccount',this.props.account.MainData.data.id));
    }

    if (newValue === '7')
    {
      // Registraties
      let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'Docu','edit') === true) ? [
      { icon: <FilePlus />, name: 'Document', tooltip: 'Document toevoegen' },
      ]: null;
      this.setState({subspeedactions:subspeedactions})
      subspeedzien = true;
      // Documenten
      await this.props.dispatch(GetDocAccount(this.props.account.MainData.data.id))


    }

    if (newValue === 'volm')
    {
      // Registraties
      let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'Docu','edit') === true) ? [
      { icon: <FilePlus />, name: 'Volmacht', tooltip: 'Volmacht toevoegen' },
      ]: null;
      this.setState({subspeedactions:subspeedactions})
      subspeedzien = true;
      // Documenten
      await this.props.dispatch(GetVolmAccount(this.props.account.MainData.data.id))


    }


    if (newValue === 'cor') {
      let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'Coresp','edit') === true) ? [
      { icon: <FilePlus />, name: 'Correspondentie', tooltip: 'Document toevoegen' },
      ] : [];
      this.setState({subspeedactions:subspeedactions})
      subspeedzien = true;
      // Documenten
      await this.props.dispatch(GetCorAccount(this.props.account.MainData.data.id))
    }




    if (this.state.edit === true || this.state.editfact === true)
    {
      if (event)
      {
        await this.MainSave();
      }
    }
    this.props.dispatch(setTab(newValue))

    this.setState({ tabvalue: newValue, subspeedzien:subspeedzien, tabsubspeedzien:subspeedzien });

  };

  async handleTabRegChange(event, newValue) {

    let sleutelreg = 'RegAccounts';

    if (newValue === 'DIR') {
        await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id,1))
        sleutelreg = 'RegAccountsDir';
    }
    if (newValue === 'ACC') {
        await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id,2))
        sleutelreg = 'RegAccountsAcc';
    }
    if (newValue === 'VAT') {
        await this.props.dispatch(GetRegAccount(this.props.account.MainData.data.id,3))
        sleutelreg = 'RegAccountsVat';
    }
    this.setState({ regtabvalue: newValue, sleutelreg:sleutelreg});

  };

async handleTabJobChange(event, newValue) {

  let sleuteljob = 'GetJobsAccount';
    if (newValue === 'DIR') {
        await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,1))
        sleuteljob = 'GetJobsAccountDir';
    }
    if (newValue === 'ACC') {
        await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,2))
        sleuteljob = 'GetJobsAccountAcc';
    }
    if (newValue === 'VAT') {
        await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,3))
        sleuteljob = 'GetJobsAccountVat';
    }
    if (newValue === 'DIVA') {
        await this.props.dispatch(GetJobsAccount(this.props.account.MainData.data.id,4))
        sleuteljob = 'GetJobsAccountDiv';
    }

    this.setState({ jobtabvalue: newValue, sleuteljob:sleuteljob});
    if (this.state.jobgraph)
    {
      this.GetGrafJob(this.state.jaar);
    }
  };


  handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:this.state.tabsubspeedzien });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
  };

  handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
  };

  handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
  };

  async ActionClick(name) {
    let hval = 'http://';
    let data = null;
    switch (name) {
      case 'Edit':
        this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data, speedopen: false })
        break;

      case 'New':
        await this.props.dispatch(CreateAccount())
        this.setState({data: this.props.account.MainData.data,editfact: true, edit: true, })
        this.handleTabChange(null,'2')


        break;

      case 'NewRegistratie':
        this.props.dispatch(zetview(this.props.account.MainData.data.name+'-'+this.props.account.MainData.data.number, this.props.account.MainData.data.id, 'ACC'));

        this.props.dispatch(clickview({ id: '', vorm: 'REG' }));
        //        await this.props.dispatch(AllContacts(this.props))
        data = { accountid: this.props.account.MainData.data.id, account_name: this.props.account.MainData.data.name }
        this.props.dispatch(setSubTitle('Registratie'))
        await this.props.dispatch(CreateRegistration(data))
        this.props.history.push('/registration');
        break;

      case 'Save':
        this.MainSave();
        break;

      case 'Job':
        data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.account.MainData.data.id;
        data.user_id = this.props.account.MainData.data.user;
        data.country = '';
        data.create_date = GenFunc.Get_Vandaag();
        data.jaar = GenFunc.Get_Jaar();
        data.dagen = null;
        data.account_name = this.props.account.MainData.data.name
        data.country_txt = '';
        data.user_name = this.props.account.MainData.data.user_name;
        let jobpath = null;
        if (this.state.sleuteljob === 'GetJobsAccountDiv')
        {
           jobpath = '/jobdivers';
        }
        this.setState({ PopUpScreen: (<PopCreateJob open={true} path={jobpath} data={data} close={this.ClosePopCreaJobs} title='Aanmaken job' />) });
        break;

      case 'Document':
        dropdoc = null;
        let element = document.getElementById(accdropid);
        element.click()
        break;

      case 'Contact':
        this.props.dispatch(zetview(this.props.account.MainData.data.name+'-'+this.props.account.MainData.data.number, this.props.account.MainData.data.id, 'ACC'));
        this.props.dispatch(clickview({ id: '', vorm: 'CONT' }));

        await this.props.dispatch(AllContacts(this.props))
        data = { accountid: this.props.account.MainData.data.id, account_name: this.props.account.MainData.data.name }
        this.props.dispatch(setSubTitle('Contactpersoon'))
        await this.props.dispatch(CreateContact(data))
        this.props.history.push('/contact');
        break;

      case 'Fgov':
        if (this.props.account && this.props.account.MainData && this.props.account.MainData.data.vatnumber && this.props.account.MainData.data.vatnumber.length > 2) 
        {
          let hnummer = this.props.account.MainData.data.vatnumber
          let nummer = ''
          for (var i = 0; i < hnummer.length; i++) 
          {
            let kar = hnummer.substr(i, 1);
            if (kar === '0' || kar === '1' ||kar === '2' ||kar === '3' ||kar === '4' ||kar === '5' ||kar === '6' ||kar === '7' ||kar === '8' ||kar === '9')
            {
               nummer += kar;
            }
          }          
          hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=' + nummer + "&actionLu=Zoek"
          window.open(hval, "_blank");
          return;
        }


        if (this.state && this.state.data && this.state.data.name && this.state.data.name.length > 2) {
          hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.state.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
          window.open(hval, "_blank");
        }
        else {
          if (this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.name && this.props.account.MainData.data.name.length > 2) {
            hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.props.account.MainData.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
            window.open(hval, "_blank");
          }
          else {
            hval = 'https://kbopub.economie.fgov.be'
            window.open(hval, "_blank");
          }
        }

        break;

      case 'ChangedAccData':
        await this.props.dispatch(GetAccijnzenAccount(this.props.account.MainData.data.id))
        await this.props.dispatch(GetVatrefundAccount(this.props.account.MainData.data.id))
        await this.props.dispatch(GetDirregAccount(this.props.account.MainData.data.id))
        await this.props.dispatch(GetAccChanges(this.props.account.MainData.data.id))
        let popdata = this.props.account;    
        this.setState({PopUpScreen:(<PopKlantGegevens open={true} data={popdata} close={this.ClosePopChangedAccData} title='Wijziging klantengegevens' />)});
        break;

      default:
        break;
    }
    this.setState({ speedopen: false });
  }

  async SubActionClick(name) {
    let hval = 'http://';
    let data = null;
    switch (name) {
      case 'Edit':
        this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data, speedopen: false })
        break;

      case 'New':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        await this.props.dispatch(CreateAccount())
        this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data, speedopen: false })
        break;

      case 'NewRegistratie':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        this.props.dispatch(zetview(this.props.account.MainData.data.name+'-'+this.props.account.MainData.data.number, this.props.account.MainData.data.id, 'ACC'));
        this.props.dispatch(clickview({ id: '', vorm: 'REG' }));
        //        await this.props.dispatch(AllContacts(this.props))
        data = { accountid: this.props.account.MainData.data.id, account_name: this.props.account.MainData.data.name }
        this.props.dispatch(setSubTitle('Registratie'))
        await this.props.dispatch(CreateRegistration(data))
        this.props.history.push('/registration');
        break;

      case 'Save':
        this.MainSave();
        break;

      case 'Job':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.account.MainData.data.id;
        data.user_id = this.props.account.MainData.data.user;
        data.country = '';
        data.create_date = GenFunc.Get_Vandaag();
        data.jaar = GenFunc.Get_Jaar();
        data.dagen = 0;
        data.account_name = this.props.account.MainData.data.name
        data.country_txt = '';
        data.user_name = this.props.account.MainData.data.user_name;
        let jobpath = null;
        if (this.state.sleuteljob === 'GetJobsAccountDiv')
        {
           jobpath = '/jobdivers';
        }

        this.setState({ PopUpScreen: (<PopCreateJob open={true} path={jobpath} data={data} close={this.ClosePopCreaJobs} title='Aanmaken job' />) });
        break;

      case 'Correspondentie':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        data = await GenFunc.zetdataleeg('Cor');
        data.account_id = this.props.account.MainData.data.id;
        data.user_id = this.props.account.MainData.data.user;
        data.country = this.props.account.MainData.data.adressen[0].country;
        data.datum = GenFunc.Get_Vandaag();
        data.account_name = this.props.account.MainData.data.name
        data.country_txt = this.props.account.MainData.data.adressen[0].country_txt;
        data.user_name = this.props.account.MainData.data.user_name;
        data.contact_id = this.props.account.MainData.data.contact;
        this.setState({ PopUpScreen: (<PopDetailCor open={true} data={data} close={this.ClosePopDetailCor} title='Aanmaken document' />) });
        break;

      case 'Volmacht':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }
        data = {id:0, omschrijving:'',country:'',account_id:'',doc_id:'',type:'',doc_txt:'',country_txt:'',datum:'',type_txt:'',account_name:''};
        data.account_id = this.props.account.MainData.data.id;
        data.datum = GenFunc.Get_Vandaag();
        data.account_name = this.props.account.MainData.data.name
        this.setState({ PopUpScreen: (<PopDetailVolm open={true} data={data} close={this.ClosePopDetailVol} title='Nieuwe volmacht' />) });
        break;


      case 'NewTarief':
        data = {accountid: '', country: '', country_txt: "", datum: "", id: 0, jaar: "", job_txt: "", jobid: '', mainid: '', mintar: "", nihil: "", tarief: "", type: "", type_txt: ""}
        data.accountid = this.props.account.MainData.data.id;
        data.account_name = this.props.account.MainData.data.name
        data.datum = GenFunc.Get_Vandaag();
        let tariefdata = {data:data, staffel: {data: [], kol: []}}
        this.setState({PopUpScreen:(<PopAccTarieven open={true} data={tariefdata} close={this.ClosePopCreaTarief} title='Definitie Klant Tarieven' />)});
        break;


      case 'Grafische voorstelling':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        let jobgraph = !this.state.jobgraph;
        this.GetGrafJob(this.state.jaar);
        this.setState({jobgraph:jobgraph})

        break;
      case 'Onkostennota':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.account.MainData.data.id;
        data.user_id = this.props.account.MainData.data.user;
        data.country = '';
        data.create_date = GenFunc.Get_Vandaag();
        data.jaar = GenFunc.Get_Jaar();
        data.dagen = 0;
        data.job_id='c901f08f895fb98ab9159f51fd0297e236d';
        data.job_txt = 'Boeken onkosten';
        data.account_name = this.props.account.MainData.data.name
        data.country_txt = '';
        data.user_name = this.props.account.MainData.data.user_name;
        this.setState({ PopUpScreen: (<PopCreateJob open={true} data={data} close={this.ClosePopCreaJobs} title='Aanmaken job' />) });
        break;

      case 'Document':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        dropdoc = null;
        let element = document.getElementById(accdropid);
        element.click()
        break;

      case 'Contact':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        this.props.dispatch(zetview(this.props.account.MainData.data.name+'-'+this.props.account.MainData.data.number, this.props.account.MainData.data.id, 'ACC'));
        this.props.dispatch(clickview({ id: '', vorm: 'CONT' }));

        await this.props.dispatch(AllContacts(this.props))
        data = { accountid: this.props.account.MainData.data.id, account_name: this.props.account.MainData.data.name }
        this.props.dispatch(setSubTitle('Contactpersoon'))
        await this.props.dispatch(CreateContact(data))
        this.props.history.push('/contact');
        break;

      case 'Fgov':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }
        if (this.props.account && this.props.account.MainData && this.props.account.MainData.data.vatnumber && this.props.account.MainData.data.vatnumber.length > 2) 
        {
          let hnummer = this.props.account.MainData.data.vatnumber
          let nummer = ''
          for (var i = 0; i < hnummer.length; i++) 
          {
            let kar = hnummer.substr(i, 1);
            if (kar === '0' || kar === '1' ||kar === '2' ||kar === '3' ||kar === '4' ||kar === '5' ||kar === '6' ||kar === '7' ||kar === '8' ||kar === '9')
            {
               nummer += kar;
            }
          }          
          hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer=' + nummer + "&actionLu=Zoek"
          window.open(hval, "_blank");
          return;
        }



        if (this.state && this.state.data && this.state.data.name && this.state.data.name.length > 2) {
          hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.state.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
          window.open(hval, "_blank");
        }
        else {
          if (this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.name && this.props.account.MainData.data.name.length > 2) {
            hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.props.account.MainData.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
            window.open(hval, "_blank");
          }
          else {
            hval = 'https://kbopub.economie.fgov.be'
            window.open(hval, "_blank");
          }
        }

        break;

      case 'ChangedAccData':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        await this.props.dispatch(GetAccijnzenAccount(this.props.account.MainData.data.id))
        await this.props.dispatch(GetVatrefundAccount(this.props.account.MainData.data.id))
        await this.props.dispatch(GetDirregAccount(this.props.account.MainData.data.id))
        await this.props.dispatch(GetAccChanges(this.props.account.MainData.data.id))
        let popdata = this.props.account;
        if(popdata.AccijnzenData.data.length !== 0 && popdata.VatrefundData.data.length !== 0 && popdata.DirregData.data.length !== 0 && popdata.AccChangesData.data.length !== 0) {
          this.setState({ PopUpScreen: (<PopKlantGegevens open={true} data={popdata} close={this.ClosePopChangedAccData} title='Wijziging klantengegevens' />) });
        }
        break;

      case 'NewInvoice':
        let newsubspeedactions = (GenFunc.allowed(this.props.user.userData.secrows, 'Invoices', 'edit') === true) ?
          [
            { icon: <Next />, name: 'MakeInvoice', tooltip: 'Factuur aanmaken' },
            { icon: <FormatList />, name: 'Overview', tooltip: 'Factuuroverzicht' },
            { icon: <PdfBox />, name: 'InvoicePdf', tooltip: 'PDF zonder briefhoofd' },
            { icon: <PdfBoxBrutax />, name: 'InvoicePdfBrutax', tooltip: 'PDF met briefhoofd' }
          ] : [];

        this.setState({ invoicestatus: "newInvoice", subspeedopen: false, subspeedactions: newsubspeedactions, subspeedzien: true })
        break;

      case 'Overview':
        let overviewsubspeedactions = (GenFunc.allowed(this.props.user.userData.secrows, 'Invoices', 'edit') === true) ?
          [
            { icon: <NoteAdd />, name: 'NewInvoice', tooltip: 'Nieuwe factuur' },
            { icon: <FormatList />, name: 'Overview', tooltip: 'Factuuroverzicht' },
            { icon: <PdfBox />, name: 'InvoicePdf', tooltip: 'PDF zonder briefhoofd' },
            { icon: <PdfBoxBrutax />, name: 'InvoicePdfBrutax', tooltip: 'PDF met briefhoofd' }
          ] : [];

        this.setState({ invoicestatus: "overview", subspeedopen: false, subspeedactions: overviewsubspeedactions, subspeedzien: true })
        break;

      case 'InvoicePdf':
        this.setState({ invoicestatus: "pdf" })
        break;
      
      case 'InvoicePdfBrutax':
        this.setState({ invoicestatus: "pdfBrutax" })
        break;

      case 'MakeInvoice':
        let makesubspeedactions = (GenFunc.allowed(this.props.user.userData.secrows, 'Invoices', 'edit') === true) ?
          [
            { icon: <SaveIcon />, name: 'SaveInvoice', tooltip: 'Factuur opslaan' },
            { icon: <Previous />, name: 'BackInvoice', tooltip: 'Terug naar jobs' },
            { icon: <FormatList />, name: 'Overview', tooltip: 'Factuuroverzicht' },
            { icon: <PdfBox />, name: 'InvoicePdf', tooltip: 'PDF zonder briefhoofd' },
            { icon: <PdfBoxBrutax />, name: 'InvoicePdfBrutax', tooltip: 'PDF met briefhoofd' }
          ] : [];

        this.setState({ invoicestatus: "makeInvoice", subspeedopen: false, subspeedactions: makesubspeedactions, subspeedzien: true })
        break;

      case 'BackInvoice':
        let backsubspeedactions = (GenFunc.allowed(this.props.user.userData.secrows, 'Invoices', 'edit') === true) ?
          [
            { icon: <Next />, name: 'MakeInvoice', tooltip: 'Factuur aanmaken' },
            { icon: <FormatList />, name: 'Overview', tooltip: 'Factuuroverzicht' },
            { icon: <PdfBox />, name: 'InvoicePdf', tooltip: 'PDF zonder briefhoofd' },
            { icon: <PdfBoxBrutax />, name: 'InvoicePdfBrutax', tooltip: 'PDF met briefhoofd' }
          ] : [];

        this.setState({ invoicestatus: "back", subspeedopen: false, subspeedactions: backsubspeedactions, subspeedzien: true })
        break;

      case 'SaveInvoice':
        let savesubspeedactions = (GenFunc.allowed(this.props.user.userData.secrows, 'Invoices', 'edit') === true) ?
          [
            { icon: <FormatList />, name: 'Overview', tooltip: 'Factuuroverzicht' },
            { icon: <PdfBox />, name: 'InvoicePdf', tooltip: 'PDF zonder briefhoofd' },
            { icon: <PdfBoxBrutax />, name: 'InvoicePdfBrutax', tooltip: 'PDF met briefhoofd' }
          ] : [];

        this.setState({ invoicestatus: "saveInvoice", subspeedopen: false, subspeedactions: savesubspeedactions, subspeedzien: true })
        break;

      default:
        break;
    }
    this.setState({ speedopen: false });
  }

  Fgov(website) {
    let hval = 'http://' + website;
    if (website === 'FGOV') {
      hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord=' + this.props.account.MainData.data.name + "&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
    }
    window.open(hval, "_blank");
  };

  async ClosePopChangedAccData() {
    this.setState({PopUpScreen:null})
  }

  async CreaAcount() {
    await this.props.dispatch(CreateAccount())
    this.setState({editfact: true, data: this.props.account.MainData.data,edit: true, })
  }

  ZetEdit() {
    if (GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit') === true)
    {
      this.setState({ data: this.props.account.MainData.data,edit: true })
    }
  }

  ZetEditFact() {
    if (GenFunc.allowed(this.props.user.userData.secrows,'Invoice','edit') === true)
    {
      this.setState({ data: this.props.account.MainData.data,editfact: true })
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeFunction);
    this.props.dispatch(setTitleRight('Klanten'))
    this.props.dispatch(setSubTitle('Klantenfiche'))
    this.props.dispatch(setPanelRight(true))
    let prevtab = this.state.tabvalue
    if (this.props.account && this.props.account.MainData && this.props.account.MainData.data.id === 0 ) 
    {
      this.CreaAcount();
      prevtab = '2';
    }    
    this.handleTabChange(null,prevtab)
  }

  componentWillUnmount() {
    this.checkconfirm(this.props.account.MainData);
    this.props.dispatch(setPanelRight(false))
    this.props.dispatch(setTitleRight(''))
    window.removeEventListener("resize", this.resizeFunction);
  }


  async adrchange(teller) {
    await this.setState({ adrestel: teller, checkval: true })
  }

  async handleJa(parameters) {
    let sdata = this.state.data
    if (this.state.save === true) {
      sdata.vatnumber = this.state.data.vatnumber.replace(".   ", "");
      sdata.vatnumber = this.state.data.vatnumber.replace("?", "");

      let retdata = await SqlFunction.DoSql('ChgAccount', sdata);
      if (retdata.nwzip === true) {
        this.props.dispatch(AllPostcodes())
      }

    }
    if (this.state.subsave === true) {
    }
    this.setState({ edit: false, editfact: false, save: false, subsave: false })
  }

  checkconfirm(parameters) {
    if (this.state.save === true || this.state.subsave === true) {
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Aanpassingen niet doorgevoerd.</h1>
              <p>Wens je de aanpassingen alsnog te bewaren?</p>
              <button
                onClick={() => {
                  this.handleJa(parameters);
                  onClose();
                }}
              >
                Ja
            </button>
              <button onClick={() => {
                this.setState({ edit: false, editfact: false, save: false, subsave: false, checkval: false })
                onClose();
              }}
              >
                Nee
            </button>
            </div>
          );
        }
      });
    }

  }

  resizeFunction() {
    if (window.innerHeight >= 960) {
      this.setState({ tableheight: Math.floor(window.innerHeight * .75) + 'px' });
    }
    else {
      this.setState({ tableheight: '600px' });
    }
  }

  onGridReady(params) {
    gridapi = params;
  }

  onGridReadyJobs(params) {
    this.jobsgridapi = params;
    gridapi = params;
  }

  async MainSave() {
    if (this.state.save === true) {
      this.state.data.vatnumber = this.state.data.vatnumber.replace(".   ", "");
      this.state.data.vatnumber = this.state.data.vatnumber.replace("?", "");

      let retdata = await SqlFunction.DoSql('ChgAccount', this.state.data);
      if (retdata.nwzip === true) {
        this.props.dispatch(AllPostcodes())
      }
      this.setState({ edit: false, editfact: false, save: false, data: null, checkval: false })
      await this.props.dispatch(GetAccount(retdata.id))
    }
  }

  async TitleClick() {
    if (this.state.save === true) 
    {
      this.state.data.vatnumber = this.state.data.vatnumber.replace(".   ", "");
      this.state.data.vatnumber = this.state.data.vatnumber.replace("?", "");
      
      let retdata = await SqlFunction.DoSql('ChgAccount', this.state.data);
      if (retdata && retdata.nwzip === true)
      {
        this.props.dispatch(AllPostcodes())
      }
      if (retdata)
      {
        await this.props.dispatch(GetAccount(retdata.id))
      }
    }
    if (this.state.edit === true) {
      this.setState({ edit: false, editfact: false, save: false, data: null, checkval: false })
    } else 
    {
      this.ZetEdit()
    }

  }



  async SecCellClick(waarde) {
    var data = waarde.data
    let column = waarde.column.colDef.field;
    let retdata = null;
    if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows, 'Gebruikers', 'edit')) {
      if (data.save === true) {
        retdata = await SqlFunction.DoSql('ChgSecUserParam', data);
        if (retdata.nwzip === true) {
          this.props.dispatch(AllPostcodes())
        }
        waarde.data.save = '';
        waarde.node.setData(waarde.data);
        this.setState({ subsave: false })
      }
    }
    return;
  }

  async ContCellClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name+'-'+this.props.account.MainData.data.number, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: waarde.data.id, vorm: 'CONT' }));

    await this.props.dispatch(AllContacts(this.props))
    this.props.dispatch(setSubTitle('Contactpersoon'))
    await this.props.dispatch(GetContact(waarde.data.id))
    this.props.history.push('/contact');
    return;
  }

  async RegCellClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name+'-'+this.props.account.MainData.data.number, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: waarde.data.id, vorm: 'REG' }));
    //    await this.props.dispatch(AllContacts(this.props))
    this.props.dispatch(setSubTitle('Registratie'))
    await this.props.dispatch(GetRegistration(waarde.data.id))
    this.props.history.push('/registration');
    return;
  }

  async KlantFactClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name+'-'+this.props.account.MainData.data.number, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: this.props.account.MainData.data.factklant, vorm: 'ACC' }));
    await this.props.dispatch(GetAccount(this.props.account.MainData.data.factklant))
    return;
  }

  async ContDetClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name+'-'+this.props.account.MainData.data.number, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: this.props.account.MainData.data.contact, vorm: 'CONT' }));

    await this.props.dispatch(AllContacts(this.props))
    this.props.dispatch(setSubTitle('Contactpersoon'))
    await this.props.dispatch(GetContact(this.props.account.MainData.data.contact))
    this.props.history.push('/contact');
    return;
  }

  async UserDetClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name+'-'+this.props.account.MainData.data.number, this.props.account.MainData.data.id, 'ACC'));
    this.props.dispatch(clickview({ id: this.props.account.MainData.data.user, vorm: 'USR' }));
    await this.props.dispatch(AllUsers(this.props))
    this.props.dispatch(setSubTitle('Gebruiker'))
    await this.props.dispatch(GetUser(this.props.account.MainData.data.user))
    this.props.history.push('/user');
    return;
  }

  async DocCellClick(waarde) {
    let column = waarde.column.colDef.field;
    if (column === 'upload') {
      dropdoc = waarde.data;
      let element = document.getElementById(accdropid);
      element.click()
      return;
    }
    if (column === 'verwijder') {
      await SqlFunction.DoSql('DeleteFile', waarde.data);
      await this.props.dispatch(GetDocAccount(this.props.account.MainData.data.id))
      return;
    }
    await this.setState({ DownloadData: waarde.data });
    document.getElementById(accformid).submit();
    return;
  }

  async VolmCellClick(waarde) {
    let column = waarde.column.colDef.field;
    if (column === 'upload') {
      let dropdocdata = waarde.data;
      dropdocdata.id = dropdocdata.doc_id;
      dropdoc = dropdocdata;
      let element = document.getElementById(accdropid);
      element.click()
      return;
    }
    let volmdata = await SqlFunction.DoSql('GetVolm', waarde.data);
    let title = 'Volmacht detail'
    window.scrollTo(0, 0);
    this.props.dispatch(zetpopup((<PopDetailVolm open={true} data={volmdata.data} close={this.ClosePopDetailVol} title={title} />)));
    return;
  }


  async CorCellClick(waarde) {
    if (waarde.data && waarde.data.id)
    {
      let cordata = await SqlFunction.DoSql('GetCor', waarde.data);
      let title = 'Document correspondentie - ' + cordata.data.description
      window.scrollTo(0, 0);
      this.props.dispatch(zetpopup((<PopDetailCor open={true} data={cordata.data} close={this.ClosePopDetailCor} title={title} />)));
    }
    return;
  }

  getContextMenuItems(params, sleutel) {
    return null;
  }

  async SecSaveAll(items) {
    if (Array.isArray(items) && GenFunc.allowed(this.props.user.userData.secrows, 'Gebruikers', 'edit')) {
      var i = 0;
      for (i = 0; i < items.length; i++) {
        let data = items[i];
        await SqlFunction.DoSql('ChgSecUserParam', data);
      }
    }
  }                      

  CheckSave(data) {
    let terug = true
    terug = Validation.ValidCheck(this.props.settings.sub_title, data.number, 'number', this, this.props.account.AllData.data, data);
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.name, 'name', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.email, 'email', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.enddate, 'enddate', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.startdate, 'startdate', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.user_name, 'user_name', this, this.props.account.AllData.data, data);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.adressen[0].adres1, 'adres1', this, this.props.account.AllData.data, data, 0);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.adressen[0].housenum, 'housenum', this, this.props.account.AllData.data, data, 0);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.adressen[0].country_txt, 'country_txt', this, this.props.account.AllData.data, data, 0);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.adressen[0].zipcode_txt, 'zipcode_txt', this, this.props.account.AllData.data, data, 0);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.vatnumber, 'vatnumber', this, this.props.account.AllData.data, data, 0);
    }
    if (terug === false) {
      terug = Validation.ValidCheck(this.props.settings.sub_title, data.factklant, 'factklant', this, this.props.account.AllData.data, data, 0);
    }
    if (this.state.editfact === true)
    {
        if (terug === false) 
        {
          terug = Validation.ValidCheck(this.props.settings.sub_title, data.vatreg_txt, 'vatreg_txt', this, this.props.account.AllData.data, data, 0);
        }
    }

    return !terug;
  }

  SecValueCheck(params, waarde) {
    if (GenFunc.allowed(this.props.user.userData.secrows, 'Gebruikers', 'edit')) {
      params.props.data.save = true;
      this.setState({ subsave: true })
    }
  } 

  async onDrop(files) {
    const formData = new FormData();
    this.handleTabChange(null, '0');
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      formData.append('file', file);

    }
    if (dropdoc) {
      formData.set('accountid', dropdoc.account_id);
      formData.set('type', dropdoc.type);
      formData.set('docid', dropdoc.id);
      formData.set('docdata', dropdoc);
    }
    else {
      formData.set('accountid', this.props.account.MainData.data.id);
      formData.set('type', 'account');
    }

    await SqlFunction.DoSqlBody('saveFile', formData);
    dropdoc = null;
    this.handleTabChange(null, '7');
  };

  async ErrorCheck(that, waarde, type, colId) {
    let data = this.state.data;
    let terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.account.AllData.data, data);
    that.setState({ error: terug })
  } 

  async ValueCheck(that, waarde, type, colId) {
    let data = this.state.data;
    let terug = false;
    switch (colId) {
      case 'active':
        if (waarde === true) {
          data.active = '1'
        }
        if (waarde === false) {
          data.active = '0'
        }
        break;

      case 'user_name':
      case 'user':
        data.user_name = waarde.value;
        data.user = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'cont_name':
        data.cont_name = waarde.value;
        data.contact = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'factklant_txt':
        data.factklant_txt = waarde.value;
        data.factklant = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'zipcode_txt':
        if (waarde.id === 0)
        {
           // openen aanmaken nieuwe postcode
          this.setState({PopUpScreen:(<PopAddZipCode open={true}  toevobject={that}  waarde={waarde.value} close={this.ClosePopZipcode} title='Toevoegen postcode aan lijst' />)});
           // Einde aanmaken nieuwe postcode
        }
        data.adressen[this.state.adrestel].zipcode_txt = waarde.value;
        data.adressen[this.state.adrestel].zipcode = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        if (that.overzdata) {
          data.adressen[this.state.adrestel].country_txt = that.overzdata.country_txt;
          data.adressen[this.state.adrestel].country = that.overzdata.country;
        }
        break;

      case 'country_txt':
        data.adressen[this.state.adrestel].country_txt = waarde.value;
        data.adressen[this.state.adrestel].country = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      case 'taalcode_txt':
        data.taalcode_txt = waarde.text;
        data.taalcode = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.text, colId, that, this.props.account.AllData.data, data);
        break;

      case 'vatreg_txt':
      case 'vatreg':

        data.vatreg_txt = waarde.text;
        data.vatreg = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.text, colId, that, this.props.account.AllData.data, data);
        break;

      case 'betaalvoorw_txt':
        data.betaalvoorw_txt = waarde.text;
        data.betaalvoorw = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'acctype_txt':
        data.acctype_txt = waarde.text;
        data.acctype = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'zendwijze_txt':
        data.zendwijze_txt = waarde.text;
        data.zendwijze = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.account.AllData.data, data);
        break;

      case 'adres1':
        data.adressen[this.state.adrestel].adres1 = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      case 'adres2':
        data.adressen[this.state.adrestel].adres2 = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      case 'housenum':
        data.adressen[this.state.adrestel].housenum = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      case 'vatnumber':
        data[colId] = waarde.toUpperCase();
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.toUpperCase(), colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;

      default:
        data[colId] = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.account.AllData.data, data, this.state.adrestel);
        break;
    }
    that.setState({ error: terug })
    terug = await this.CheckSave(data);
    this.setState({ save: terug, data: data, checkval: false })
  }      
  
  async JobCellClick(waarde) {
    let column = waarde.column.colDef.field;
    var data = waarde.data;    
    if (column === 'verwijder')
    {
      if (data.verwijder && (data.verwijder === '1' || data.verwijder === 1 || data.verwijder === true))
      {
          await SqlFunction.DoSql('DeleteJob', data);
          if (this.jobsgridapi) {this.jobsgridapi.purData()}
      }      
      return;
    }

    let jobdata = await SqlFunction.DoSql('GetJob', waarde.data);
    let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name;
    window.scrollTo(0, 0);
    this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} history={this.props.history} data={jobdata} close={this.ClosePopJobs} title={title} />)));

//    this.setState({ PopUpScreen: (<PopJobAanvrNum open={true} data={jobdata} close={this.ClosePopJobs} title={title} />) });
    return;
  }

  async ClosePopJobs() {

    let jobtabvalue = this.state.jobtabvalue;
   
    if (this.jobsgridapi) {this.jobsgridapi.refreshData()}
    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));

  }

  async ClosePopZipcode(retdata) {
    let data = this.state.data;
    this.setState({ PopUpScreen: null });
    if (retdata) 
    {
        data.adressen[this.state.adrestel].zipcode_txt = retdata.zipcode + ' - '+retdata.place;
        data.adressen[this.state.adrestel].zipcode = retdata.id;
        data.adressen[this.state.adrestel].country_txt = retdata.country_txt;
        data.adressen[this.state.adrestel].country = retdata.country;
        this.setState({data:data})
    }

    await this.props.dispatch(AllPostcodes());
  }

  async ClosePopDetailCor(terdata) {
    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    await this.props.dispatch(GetCorAccount(this.props.account.MainData.data.id))
  }

  async ClosePopDetailVol(terdata) {
    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    await this.props.dispatch(GetVolmAccount(this.props.account.MainData.data.id))
  }


  render() {
    const { classes } = this.props;
    var {tableheight} = this.state;
    let mailref = (this.props.account && this.props.account.MainData  && this.props.account.MainData.data && this.props.account.MainData.data.email.length > 3) ? "mailto:"+this.props.account.MainData.data.email : null;
    return (
      <div >
        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
          <Grid container>
            <Grid item xs={8}>          
              <h4 style={{cursor:'pointer'}} tag="h4" className="mt-3" onClick={() => {this.TitleClick()}}>{this.props.settings.sub_title} {this.props.account.MainData.data.name} {this.props.account.MainData.data.number}</h4>  
            </Grid>
            <Grid item xs={4}>  
            </Grid>
          </Grid>
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'Edit' && (this.state.edit === true || this.state.editfact === true) ) ? true :
                                     (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'New' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'New' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'NewRegistratie' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'NewRegistratie' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'NewRegistratie' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'Contact' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? false :
                                     (action.name === 'Contact' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'Contact' && !GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? true :
                                     (action.name === 'Document' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'Document' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'Document' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'ChangedAccData' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'ChangedAccData' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'ChangedAccData' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'NewInvoice' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'NewInvoice' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'NewInvoice' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === true) ? false :
                                     (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === false) ? true :
                                     (action.name === 'Save' && this.state.edit === false && this.state.editfact === false) ? true : 
                                     (action.name === 'Save' && this.state.save === false) ? true : false}}
                onClick={() => {this.ActionClick(action.name)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
          </SpeedDial>          

          {this.state.subspeedactions && this.state.subspeedzien && this.state.edit === false && 
            <SpeedDial
              ariaLabel="SpeedDial example"
              icon={<SubSpeedDialIcon style={{zIndex:'10'}}/>}
              direction="left"
              classes={{root: classes.subspeedDialToolb, fab: classes.subspeedFabRoot}}           
              onClose={this.handleSubSpeedClose}
              onOpen={this.handleSubSpeedOpen}
              open={this.state.subspeedopen}          
            >
              {this.state.subspeedactions.map(action => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipPlacement='bottom'
                  FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'Edit' && (this.state.edit === true || this.state.editfact === true) ) ? true :
                                       (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'New' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'New' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'NewRegistratie' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'NewRegistratie' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'NewRegistratie' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'Contact' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? false :
                                       (action.name === 'Contact' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'Contact' && !GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? true :
                                       (action.name === 'Document' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'Document' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'Document' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'ChangedAccData' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                       (action.name === 'ChangedAccData' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                       (action.name === 'ChangedAccData' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                       (action.name === 'InvoicePdf' && this.state.canMakeInvoicePdf) ? false :
                                       (action.name === 'InvoicePdf' && !this.state.canMakeInvoicePdf) ? true :
                                       (action.name === 'InvoicePdfBrutax' && this.state.canMakeInvoicePdf) ? false :
                                       (action.name === 'InvoicePdfBrutax' && !this.state.canMakeInvoicePdf) ? true :
                                       (action.name === 'MakeInvoice' && this.state.canMakeInvoice) ? false :
                                       (action.name === 'MakeInvoice' && !this.state.canMakeInvoice) ? true :
                                       (action.name === 'SaveInvoice' && this.state.canSaveInvoice) ? false :
                                       (action.name === 'SaveInvoice' && !this.state.canSaveInvoice) ? true :
                                       (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === true) ? false :
                                       (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === false) ? true :
                                       (action.name === 'Save' && this.state.edit === false && this.state.editfact === false) ? true : 
                                       (action.name === 'Save' && this.state.save === false) ? true : false}}
                  onClick={() => {this.SubActionClick(action.name)}}                             
                  tooltipTitle={action.tooltip}
                />
              ))}
            </SpeedDial>     
          }     
        </Toolbar>
        
        {this.props.account && this.props.account.MainData && this.props.account.MainData.data &&
          <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick ={true} noKeyboard={true}>
            {({getRootProps, getInputProps}) => (
              <section className="container" style={{width:'100%',maxWidth:'100%'}}>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <button style={{display: 'none'}} id={accdropid} type="button" onClick={openDialog}></button>         
                  <FileDownload formid={accformid} frameid={accframeid} DocData={this.state.DownloadData}/>

                  <Grid container style={{width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px', height: 'calc(100vh - 180px)'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={this.state.edit === false ? 3 : 5}>
                        <Card className='card_brut'>
                          {this.state.edit === false &&
                            <PerfectScrollbar className="sidebar-nav">
                              {!this.state.canMakeInvoicePdf && <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} className="card-title m-t-10">{this.props.account.MainData.data.name} </h4>
                                <h4 style={{marginTop: '15px',cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} className="card-title m-t-10">{this.props.account.MainData.data.number} </h4>
                                <h6 style={{cursor: 'pointer'}}  onClick={() => {this.Fgov(this.props.account.MainData.data.website)}}>{this.props.account.MainData.data.website}</h6> 
                                <h6 style={{cursor: 'pointer'}}> <a href={mailref}>{this.props.account.MainData.data.email}</a></h6> 
                                <div onClick={() => {this.ZetEdit()}} style={{width:'100%'}}>
                                  <h6>{this.props.account.MainData.data.phone}</h6> 
                                  <h4>{this.props.account.MainData.data.acctype_txt}</h4> 
                                  <small className="text-muted p-t-30 db">Zaakvoerder</small>                  
                                  <h6 style={{cursor: 'pointer'}} onClick={() => {this.ContDetClick()}}>{this.props.account.MainData.data.cont_name}</h6> 
                                  <small className="text-muted p-t-30 db">Dossierbeheerder</small>                  
                                  <h6 style={{cursor: 'pointer'}} onClick={() => {this.UserDetClick()}}>{this.props.account.MainData.data.user_name}</h6> 
                                  <small className="text-muted p-t-30 db">Btw nummer</small>                  
                                  <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.vatnumber}</h6> 
                                  <small className="text-muted p-t-30 db">NACE code</small>                  
                                  <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.nace_code}</h6> 

                                </div>
                                <div className='div_line' style={{marginBottom: '25px'}}></div>
                                <h6 >Directe registratie
                                  <Tooltip
                                    title="Lijst overzicht directe registratie"
                                    placement="bottom"
                                  >
                                    <Maplegende style={{marginLeft: '10px', cursor:'pointer'}} onClick={(event) => { this.OpenPopJobKlantDirInfo() }}/>
                                  </Tooltip>                                
                                </h6> 
                                <div className='div_line' style={{marginBottom: '15px'}}></div>
                                {this.props.account.MainData && this.props.account.MainData.data.dirreg &&
                                  <Grid style={{marginBottom:'20px'}} item sm={12} md={12} lg={12}>
                                    {this.props.account.MainData.data.dirreg.map(onkdata => {
                                      return (
                                        <Chip
                                          style={{marginTop:'5px',marginRight:'5px'}}
                                          variant="outlined"
                                          color="primary"
                                          size="small"
                                          key={GenFunc.MakeId()}
                                          label={onkdata.name}

                                        />
                                      );
                                    })}                                  
                                  </Grid>
                                }
                                <div className='div_line' style={{marginBottom: '10px'}}></div>
                                <h6 >Accijnzen</h6> 
                                <div className='div_line' style={{marginBottom: '15px'}}></div>
                                {this.props.account.MainData && this.props.account.MainData.data.accijn &&
                                  <Grid style={{marginBottom:'20px'}} item sm={12} md={12} lg={12}>
                                    {this.props.account.MainData.data.accijn.map(onkdata => {
                                      return (
                                        <Chip
                                          style={{marginTop:'5px',marginRight:'5px'}}
                                          variant="outlined"
                                          color="primary"
                                          size="small"
                                          key={GenFunc.MakeId()}
                                          label={onkdata.name}
                                        />
                                      );
                                    })}                                  
                                  </Grid>
                                }
                                
                                <div className='div_line' style={{marginBottom: '10px'}}></div>
                                <h6 >VAT Refund
                                  <Tooltip
                                    title="Klanteninformatie"
                                    placement="bottom"
                                  >
                                    <Maplegende style={{marginLeft: '10px', cursor:'pointer'}} onClick={(event) => { this.OpenPopJobKlantVatInfo() }}/>
                                  </Tooltip>                                
                                </h6> 

                                <div className='div_line' style={{marginBottom: '15px'}}></div>
                                {this.props.account.MainData && this.props.account.MainData.data.vatregreg &&
                                  <Grid style={{marginBottom:'20px'}} item sm={12} md={12} lg={12}>
                                    {this.props.account.MainData.data.vatregreg.map(onkdata => {
                                      return (
                                        <Chip
                                          style={{marginTop:'5px',marginRight:'5px'}}
                                          variant="outlined"
                                          color="primary"
                                          size="small"
                                          key={GenFunc.MakeId()}
                                          label={onkdata.name}
                                        />
                                      );
                                    })}                                  
                                  </Grid>
                                }
                                <div className='div_line' style={{marginBottom: '25px'}}></div>
                                <div >
                                  <Nav style={{justifyContent: 'center'}} tabs>
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.adrestel === 0 })}
                                        onClick={(event) => { this.adrchange(0) }}
                                        style={{ cursor: 'default' }}
                                      >
                                        Maatschappelijke zetel
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.adrestel === 1 })}
                                        onClick={(event) => { this.adrchange(1) }}
                                        style={{ cursor: 'default' }}
                                      >
                                        Correspondentieadres
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                  <TabContent style={{textAlign:'center',padding:'20px'}} activeTab={this.state.adrestel.toString()}>
                                    <TabPane tabId="0">
                                      { this.state.adrestel === 0 &&
                                        <div onClick={() => {this.ZetEdit()}}>
                                          <small style={{marginTop:'25px'}} className="text-muted p-t-30 db">Adres</small>                  
                                          <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.adressen[this.state.adrestel].adres1} {this.props.account.MainData.data.adressen[this.state.adrestel].housenum}</h6> 
                                          <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.adressen[this.state.adrestel].adres2}</h6> 
                                          <small className="text-muted p-t-30 db">Gemeente</small>                  
                                          <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.adressen[this.state.adrestel].zipcode_txt}</h6> 
                                          <small className="text-muted p-t-30 db">Land</small>                  
                                          <h6 onClick={() => {this.ZetEdit()}} style={{marginBottom: '25px'}} >{this.props.account.MainData.data.adressen[this.state.adrestel].country_txt}</h6> 
                                        </div>
                                      }
                                    </TabPane>
                                    <TabPane tabId="1">
                                      { this.state.adrestel === 1 &&
                                        <div onClick={() => {this.ZetEdit()}}>
                                          <small style={{marginTop:'25px'}} className="text-muted p-t-30 db">Adres</small>                  
                                          <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.adressen[this.state.adrestel].adres1} {this.props.account.MainData.data.adressen[this.state.adrestel].housenum}</h6> 
                                          <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.adressen[this.state.adrestel].adres2}</h6> 
                                          <small className="text-muted p-t-30 db">Gemeente</small>                  
                                          <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.adressen[this.state.adrestel].zipcode_txt}</h6> 
                                          <small className="text-muted p-t-30 db">Land</small>                  
                                          <h6 onClick={() => {this.ZetEdit()}} style={{marginBottom: '25px'}} >{this.props.account.MainData.data.adressen[this.state.adrestel].country_txt}</h6> 
                                        </div>
                                      }
                                    </TabPane>
                                  </TabContent>
                                </div>
                                <div className='div_line' style={{marginBottom: '10px'}}></div>
                                <div onClick={() => {this.ZetEdit()}} style={{width:'100%'}}>
                                  <small className="text-muted p-t-30 db">Aanvang mandaat</small>                  
                                  <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.startdate}</h6> 
                                  <small className="text-muted p-t-30 db">Mandaat stopzetting</small>                  
                                  <h6 onClick={() => {this.ZetEdit()}}>{this.props.account.MainData.data.enddate}</h6> 
                                </div>
                                <div className='div_line' style={{marginBottom: '25px'}}></div>
                              </CardBody>}
                              {this.state.canMakeInvoicePdf && 
                              <CardBody style={{ textAlign: 'center', height: Math.floor(window.innerHeight * .85) + 'px' }}>
                                {this.getInvoiceDetails()}
                              </CardBody>}
                            </PerfectScrollbar>
                          }
                          {this.state.data && this.state.edit === true &&
                            <PerfectScrollbar styleclassName="sidebar-nav">
                              <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.name}
                                        coltype = 'STR'                        
                                        labelText="Naam"
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'name')}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'name')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.number}
                                        coltype = 'STR'                        
                                        labelText="Klantnummer"
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'number')}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'number')}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid style={{textAlign:'left'}} item sm={12} md={12} lg={12}>
                                    <EditSelect className="card-title m-t-10"
                                      value={this.state.data.acctype}
                                      data={this.state.data}
                                      labelText="Type Klant"
                                      type='AllAccType'
                                      colId='acctype_txt'
                                      selectcel='acctype'
                                      id={this.state.data.acctype}
                                      ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'acctype_txt')}
                                    />
                                  </Grid>

                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.email}
                                        coltype = 'STR'                        
                                        labelText="E-Mail"
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'email')}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'email')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.phone}
                                        coltype = 'STR'                        
                                        labelText="Telefoon"
                                        format = {['+',/[0-9]/,/\d/,' ','(',/[0-9]/,')',/[0-9]/,/\d/,' ',/\d/, /\d/,' ',/\d/,/\d/,' ',/\d/,/\d/,' ',/\d/,/\d/,' ',/[0-9]/,/[0-9]/,' ',/[0-9]/,/[0-9]/]}
                                        
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'phone')}
                                      />
                                    </Grid>
                                  </Grid>
                                  <EditString className="card-title m-t-10"
                                    value={this.state.data.website}
                                    coltype = 'STR'                        
                                    labelText="Website"
                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'website')}
                                  />
                                  {this.state.data && this.state.data.id !== 0 &&
                                    <EditAutoSelect className="card-title m-t-10"
                                      value={this.state.data.cont_name}
                                      filter={[{field:'parent_account',value:this.state.data.id}]}
                                      labelText="Zaakvoerder"
                                      lijst='AllContacts'
                                      colId='cont_name'
                                      selectcel='contact'
                                      id={this.state.data.contact}
                                      ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'cont_name')}
                                    />
                                  }
                                  <div style={{width:'100%',textAlign:'center'}}>
                                    <EditAutoSelect className="card-title m-t-10"
                                      value={this.state.data.user_name}
                                      labelText="Dossierbeheerder"
                                      lijst='AllUsers'
                                      colId='user_name'
                                      selectcel='user'
                                      id={this.state.data.user}
                                      setError={(params,value,type) => this.ErrorCheck(params,value,type,'user_name')}
                                      ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'user_name')}
                                    />
                                  </div>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.vatnumber}
                                        coltype = 'STR'                        
                                        labelText="BTW nummer"
                                        format = {[/[a-z, A-Z]/, /[a-z, A-Z]/,'-',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/, /[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/, /[a-z, A-Z, 0-9]/, /[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/]}
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'vatnumber')}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'vatnumber')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditString className="card-title m-t-10"
                                        value={this.state.data.nace_code}
                                        coltype = 'STR'                        
                                        labelText="NACE Code"
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'nace_code')}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'nace_code')}
                                      />
                                    </Grid>

                                  </Grid>

                                  <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                  <Nav style={{justifyContent: 'center'}} tabs>                                  
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.adrestel === 0 })}
                                        onClick={(event) => { this.adrchange(0) }}
                                      >
                                        Maatschappelijke zetel
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.adrestel === 1 })}
                                        onClick={(event) => { this.adrchange(1) }}
                                      >
                                        Correspondentieadres
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                  <TabContent style={{textAlign:'left'}} activeTab={this.state.adrestel.toString()}>
                                    <TabPane tabId="0">
                                      { this.state.adrestel === 0 &&
                                        <div>
                                          <Grid container spacing={1}>
                                            <Grid item xs={8}>
                                              <EditString className="card-title m-t-10"
                                                checkval={this.state.checkval}
                                                value={this.state.data.adressen[this.state.adrestel].adres1}
                                                coltype = 'STR'                        
                                                labelText="Straat"
                                                setError={(params,value,type) => this.ErrorCheck(params,value,type,'adres1')}
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'adres1')}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              <EditString className="card-title m-t-10"
                                                checkval={this.state.checkval}
                                                value={this.state.data.adressen[this.state.adrestel].housenum}
                                                coltype = 'STR'                        
                                                labelText="Huisnummer"
                                                setError={(params,value,type) => this.ErrorCheck(params,value,type,'name')}
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'housenum')}
                                              />
                                            </Grid>
                                          </Grid>
                                          <EditString className="card-title m-t-10"
                                            checkval={this.state.checkval}
                                            value={this.state.data.adressen[this.state.adrestel].adres2}
                                            coltype = 'STR'                        
                                            labelText="Adresregel 2"
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'adres2')}
                                          />
                                          <div style={{width:'100%',textAlign:'center'}}>
                                            <EditAutoSelect className="card-title m-t-10"
                                              checkval={this.state.checkval}
                                              value={this.state.data.adressen[this.state.adrestel].zipcode_txt}
                                              GoogleZoek={true}
                                              labelText="Gemeente"
                                              lijst='AllPostcodes'
                                              colId='zipcode_txt'
                                              selectcel='zipcode'
                                              id={this.state.data.adressen[this.state.adrestel].zipcode}
                                              setError={(params,value,type) => this.ErrorCheck(params,value,type,'zipcode_txt')}
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'zipcode_txt')}
                                            />
                                          </div>
                                          <div style={{width:'100%',textAlign:'center'}}>
                                            <EditAutoSelect className="card-title m-t-10"
                                              checkval={this.state.checkval}
                                              value={this.state.data.adressen[this.state.adrestel].country_txt}
                                              GoogleZoek={true}
                                              labelText="Land"
                                              lijst='AllLanden'
                                              colId='country_txt'
                                              selectcel='country'
                                              id={this.state.data.adressen[this.state.adrestel].country}
                                              setError={(params,value,type) => this.ErrorCheck(params,value,type,'country_txt')}
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'country_txt')}
                                            />
                                          </div>

                                        </div>
                                      }
                                    </TabPane>

                                    <TabPane tabId="1">
                                      { this.state.adrestel === 1 &&
                                        <div>
                                          <Grid container spacing={1}>
                                            <Grid item xs={8}>
                                              <EditString className="card-title m-t-10"
                                                checkval={this.state.checkval}
                                                value={this.state.data.adressen[this.state.adrestel].adres1}
                                                coltype = 'STR'                        
                                                labelText="Straat"
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'adres1')}
                                              />
                                            </Grid>
                                            <Grid item xs={4}>
                                              <EditString className="card-title m-t-10"
                                                checkval={this.state.checkval}
                                                value={this.state.data.adressen[this.state.adrestel].housenum}
                                                coltype = 'STR'                        
                                                labelText="Huisnummer"
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'housenum')}
                                              />
                                            </Grid>
                                          </Grid>
                                          <EditString className="card-title m-t-10"
                                            checkval={this.state.checkval}
                                            value={this.state.data.adressen[this.state.adrestel].adres2}
                                            coltype = 'STR'                        
                                            labelText="Adresregel 2"
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'adres2')}
                                          />
                                          <div style={{width:'100%',textAlign:'center'}}>
                                            <EditAutoSelect className="card-title m-t-10"
                                              checkval={this.state.checkval}
                                              value={this.state.data.adressen[this.state.adrestel].zipcode_txt}
                                              GoogleZoek={true}
                                              labelText="Gemeente"
                                              lijst='AllPostcodes'
                                              colId='zipcode_txt'
                                              selectcel='zipcode'
                                              id={this.state.data.adressen[this.state.adrestel].zipcode}
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'zipcode_txt')}
                                            />
                                          </div>
                                          <div style={{width:'100%',textAlign:'center'}}>
                                            <EditAutoSelect className="card-title m-t-10"
                                              checkval={this.state.checkval}
                                              value={this.state.data.adressen[this.state.adrestel].country_txt}
                                              GoogleZoek={true}
                                              labelText="Land"
                                              lijst='AllLanden'
                                              colId='country_txt'
                                              selectcel='country'
                                              id={this.state.data.adressen[this.state.adrestel].country}
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'country_txt')}
                                            />
                                          </div>

                                        </div>
                                      }
                                    </TabPane>

                                  </TabContent>


           
                                  <div className='div_line' style={{marginBottom: '25px',marginTop: '25px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditDateTime className="card-title m-t-10"
                                        value={this.state.data.startdate}
                                        coltype = 'STR'                        
                                        labelText="Aanvang mandaat"
                                        inputProps={{value:this.state.data.startdate}}
                                        formControlProps={{fullWidth: true}}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'startdate')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditDateTime className="card-title m-t-10"
                                        value={this.state.data.enddate}
                                        coltype = 'STR'                        
                                        labelText="Mandaat stopzetting"
                                        inputProps={{value:this.state.data.enddate}}
                                        formControlProps={{fullWidth: true}}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'enddate')}
                                      />
                                    </Grid>
                                  </Grid>
                                  <div className='div_line' style={{marginBottom: '25px',marginTop: '25px',borderColor:'#4F5467',borderWidth:'2px'}}></div>
                              </CardBody>
                            </PerfectScrollbar>
                          }
                        </Card>
                      </Grid>
                      <Grid item xs={this.state.edit === false ? 9 : 7}>
                        <Card className='card_brut'>
                          <CardBody style={{minHeight: Math.floor(window.innerHeight * .85) + "px", width: "100%"}}>
                            <div className='div_line'>
                              <Nav tabs >
                                { GenFunc.allowed(this.props.user.userData.secrows,'AllContacts','zien') &&
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '1' })}
                                    onClick={(event) => { this.handleTabChange(event,'1'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Contactpersonen
                                  </NavLink>
                                </NavItem>
                                }
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === 'info' })}
                                    onClick={(event) => { this.handleTabChange(event,'info'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Info
                                  </NavLink>
                                </NavItem>

                                { GenFunc.allowed(this.props.user.userData.secrows,'Invoice','zien') &&
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '2' })}
                                    onClick={(event) => { this.handleTabChange(event,'2'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Facturatie
                                  </NavLink>
                                </NavItem>
                                }
                                { GenFunc.allowed(this.props.user.userData.secrows,'Invoice','zien') &&
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '3' })}
                                    onClick={(event) => { this.handleTabChange(event,'3'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Facturen
                                  </NavLink>
                                </NavItem>
                                }

                                { GenFunc.allowed(this.props.user.userData.secrows,'Registration','zien') &&
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '4' })}
                                    onClick={(event) => { this.handleTabChange(event,'4'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Activiteit
                                  </NavLink>
                                </NavItem>
                                }
                                { GenFunc.allowed(this.props.user.userData.secrows,'AccTarief','zien') &&
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '5' })}
                                    onClick={(event) => { this.handleTabChange(event,'5'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Tarieven
                                  </NavLink>
                                </NavItem>
                                }
                                { GenFunc.allowed(this.props.user.userData.secrows,'Jobs','zien') &&
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '6' })}
                                    onClick={(event) => { this.handleTabChange(event,'6'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Jobs
                                  </NavLink>
                                </NavItem>
                                }
                                { GenFunc.allowed(this.props.user.userData.secrows,'Docu','zien') &&
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '7' })}
                                    onClick={(event) => { this.handleTabChange(event,'7'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Documenten
                                  </NavLink>
                                </NavItem>
                                }
                                { GenFunc.allowed(this.props.user.userData.secrows,'Coresp','zien') &&
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === 'cor' })}
                                    onClick={(event) => { this.handleTabChange(event,'cor'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Correspondentie
                                  </NavLink>
                                </NavItem>
                                }
                                { GenFunc.allowed(this.props.user.userData.secrows,'Docu','zien') &&
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === 'volm' })}
                                    onClick={(event) => { this.handleTabChange(event,'volm'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Volmachten
                                  </NavLink>
                                </NavItem>
                                }

                              </Nav>
                              <TabContent activeTab={this.state.tabvalue}>
                                <TabPane tabId="1">
                                  { this.state.tabvalue === '1' && this.props.account.ContData && this.props.account.ContData.data && this.props.account.ContData.kol &&
                                    this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                    <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.account.ContData.kol}
                                      data={this.props.account.ContData.data} 
                                      sizetofit={false}
                                      sortable={false}
                                      suppressMenu={true}
                                      enableRowGroup={false}
                                      suppressColumnVirtualisation={true}                                
                                      filter={true}
                                      sleutel='ContAccounts'
                                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                      editable={false}
                                      GridReady={this.onGridReady}
                                      sql={() => this.props.dispatch(GetContAccount(this.props.user.MainData.data.id))}
                                      CellClickedEvent={(column, colDef, value) => this.ContCellClick(column,colDef, value)}
                                    />
                                  }
                                </TabPane>
                                <TabPane tabId="info">
                                  <PerfectScrollbar className="sidebar-nav">
                                    <CardBody style={{textAlign:'left',height:Math.floor( (window.innerHeight * .85) - 80) + 'px'}}>
                                      {this.state.edit === false &&
                                        <Grid container spacing={1} onClick={() => {this.ZetEdit()}} >
                                          <Grid item sm={12} md={12} lg={12}>
                                            <EditString onClick={() => {this.ZetEdit()}} className="card-title m-t-10"
                                              value={this.props.account.MainData.data.info}
                                              coltype = 'STR'  
                                              multiline={true}    
                                              height={tableheight}                   
                                              disabled
                                              readonly = {true}
                                            />

                                          </Grid>
                                        </Grid>
                                      }
                                      {this.state.edit === true && this.state.data &&
                                        <Grid container spacing={1}>
                                          <Grid item sm={12} md={12} lg={12}>
                                              <EditString className="card-title m-t-10"
                                                value={this.state.data.info}
                                                coltype = 'STR'  
                                                height={tableheight}                   
                                                multiline={true}                      
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'info')}
                                              />
                                          </Grid>
                                        </Grid>
                                      }
                                    </CardBody>
                                  </PerfectScrollbar>
                                </TabPane>

                                <TabPane tabId="2">
                                  <PerfectScrollbar className="sidebar-nav">
                                    <CardBody style={{textAlign:'left',height:Math.floor( (window.innerHeight * .85) - 80) + 'px'}}>
                                      <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.ZetEditFact()}} className="card-title m-t-10">Facturatie </h4>
                                      {this.state.editfact === false &&
                                        <Grid container spacing={1} onClick={() => {this.ZetEditFact()}} >
                                          <Grid item sm={6} md={6} lg={6}>
                                            <small className="text-muted p-t-30 db">Taalcode</small>                  
                                            <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.taalcode_txt}</h6> 
                                            <small className="text-muted p-t-30 db">Betalingsvoorwaarde</small>                  
                                            <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.betaalvoorw_txt}</h6> 
                                            <small className="text-muted p-t-30 db">Btw regime</small>                  
                                            <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.vatreg_txt}</h6> 

                                            <small className="text-muted p-t-30 db">Zendwijze factuur</small>                  
                                            <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.zendwijze_txt}</h6>

                                          </Grid>
                                          <Grid item sm={6} md={6} lg={6}>
                                            <small className="text-muted p-t-30 db">Email facturatie</small>                  
                                            <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.emailfakt}</h6> 
                                            <small className="text-muted p-t-30 db">Facturatieklant</small>                  
                                            <h6 style={{cursor:'pointer'}} onClick={() => {this.KlantFactClick()}}>{this.props.account.MainData.data.factklant_txt}</h6> 
                                            <small className="text-muted p-t-30 db">Vervaldag certificaat</small>                  
                                            <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.vervaldag}</h6> 
                                          </Grid>
                                        </Grid>
                                      }
                                      {this.state.editfact === true && this.state.data &&
                                        <Grid container spacing={1}>
                                          <Grid item sm={6} md={6} lg={6}>
                                            <EditSelect className="card-title m-t-10"
                                              value={this.state.data.taalcode_txt}
                                              data={this.state.data}
                                              labelText="Taalcode"
                                              type='AllTaalcodes'
                                              colId='taalcode_txt'
                                              selectcel='taalcode'
                                              id={this.state.data.taalcode}
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'taalcode_txt')}
                                            />
                                            <EditSelect className="card-title m-t-10"
                                              value={this.state.data.betaalvoorw_txt}
                                              data={this.state.data}
                                              labelText="Betalingsvoorwaarden"
                                              type='AllBetalingsvoorwaarden'
                                              colId='betaalvoorw_txt'
                                              selectcel='betaalvoorw'
                                              id={this.state.data.betaalvoorw}
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'betaalvoorw_txt')}
                                            />
                                            <EditSelect className="card-title m-t-10"
                                              value={this.state.data.vatreg_txt}
                                              data={this.state.data}
                                              labelText="BTW Regime"
                                              type='AllBtwRegimes'
                                              colId='vatreg_txt'
                                              selectcel='vatreg'
                                              id={this.state.data.vatreg}
                                              setError={(params,value,type) => this.ErrorCheck(params,value,type,'vatreg_txt')}
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'vatreg_txt')}
                                            />
                                            <EditSelect className="card-title m-t-10"
                                              value={this.state.data.zendwijze_txt}
                                              data={this.state.data}
                                              labelText="Zendwijze factuur"
                                              type='AllFactZendwijzen'
                                              colId='zendwijze_txt'
                                              selectcel='zendwijze'
                                              id={this.state.data.zendwijze}
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'zendwijze_txt')}
                                            />
                                          </Grid>
                                          <Grid item sm={6} md={6} lg={6}>
                                             <EditString className="card-title m-t-10"
                                              value={this.state.data.emailfakt}
                                              coltype = 'STR'                        
                                              labelText="E-Mail facturatie"
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'emailfakt')}
                                            />
                                            <EditAutoSelect className="card-title m-t-10"
                                              value={this.state.data.factklant_txt}
                                              labelText="Facturatie Klant"
                                              lijst='AllAccounts'
                                              colId='factklant_txt'
                                              selectcel='factklant'
                                              id={this.state.data.factklant}
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'factklant_txt')}
                                            />
                                            <div style={{marginTop: '-14px'}}>
                                              <EditDateTime className="card-title m-t-10"
                                                value={this.state.data.vervaldag}
                                                coltype = 'STR'                        
                                                labelText="Vervaldag certificaat"
                                                inputProps={{value:this.state.data.vervaldag}}
                                                formControlProps={{fullWidth: true}}
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'vervaldag')}
                                              />
                                            </div>
                                          </Grid>
                                        </Grid>
                                      }

                                      <div className='div_line' style={{marginTop: '25px', marginBottom: '25px'}}></div>
                                      <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.ZetEditFact()}} className="card-title m-t-10">Betalingsgedrag </h4>
                                      {this.state.editfact === false &&
                                        <div onClick={() => {this.ZetEditFact()}} >
                                            <Grid container spacing={1}>
                                              <Grid item sm={12} md={12} lg={12}>
                                                <small className="text-muted p-t-30 db">Werkzaamheden on hold:    <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} labelPlacement="start" disabled control={<Checkbox checked={this.props.account.MainData.data.onhold} />} /></small>    
                                              </Grid>
                                            </Grid>
                                            <small className="text-muted p-t-30 db">Infovak</small>                  
                                            <EditString onClick={() => {this.ZetEditFact()}} className="card-title m-t-10"
                                              value={this.props.account.MainData.data.infohold}
                                              coltype = 'STR'  
                                              multiline={true}                      
                                              disabled
                                              readonly = {true}
                                            />

                                        </div>
                                      }
                                      {this.state.editfact === true && this.state.data &&
                                        <div>
                                          <div style={{width:'100%',textAlign:'left', marginLeft:'-20px',marginTop: '10px'}}>
                                            <EditCheck className="card-title m-t-10"
                                              value={this.state.data.onhold}
                                              labelPlacement="start"
                                              labelText="Werkzaamheden on hold"
                                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'onhold')}
                                            />
                                          </div>
                                          <Grid container spacing={1}>
                                            <Grid item sm={12} md={12} lg={12}>
                                              <small className="text-muted p-t-30 db">Infovak</small>                  
                                            </Grid>

                                            <Grid item sm={12} md={12} lg={12}>
                                                <EditString className="card-title m-t-10"
                                                  value={this.state.data.infohold}
                                                  coltype = 'STR'  
                                                  multiline={true}                      
                                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'infohold')}
                                                />
                                            </Grid>
                                          </Grid>
                                        </div>
                                      }

                                      <div className='div_line' style={{marginTop: '25px', marginBottom: '25px'}}></div>
                                      <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.ZetEditFact()}} className="card-title m-t-10">Bank </h4>
                                      {this.state.editfact === false &&
                                        <div onClick={() => {this.ZetEditFact()}} >
                                            <Grid container spacing={1}>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <small className="text-muted p-t-30 db">Bank</small>                  
                                                </Grid>                                        

                                                <Grid item sm={4} md={4} lg={4}>
                                                    <small className="text-muted p-t-30 db">Naam bank</small>                  
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <small className="text-muted p-t-30 db">BIC</small>                  
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <small className="text-muted p-t-30 db">IBAN</small>                  
                                                </Grid>                                        

                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>Directe Registratie</h6> 
                                                </Grid>                                        

                                                <Grid item sm={4} md={4} lg={4}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.rekomsdir}</h6> 
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.rekbicdir}</h6> 
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.rekibandir}</h6> 
                                                </Grid>                                        

                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>Accijnzen</h6> 
                                                </Grid>                                        

                                                <Grid item sm={4} md={4} lg={4}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.rekomsacc}</h6> 
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.rekbicacc}</h6> 
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.rekibanacc}</h6> 
                                                </Grid>                                        

                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>Refund</h6> 
                                                </Grid>                                        

                                                <Grid item sm={4} md={4} lg={4}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.rekomsref}</h6> 
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.rekbicref}</h6> 
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <h6 onClick={() => {this.ZetEditFact()}}>{this.props.account.MainData.data.rekibanref}</h6> 
                                                </Grid>                                        

                                            </Grid>
                                        </div>
                                      }
                                      {this.state.editfact === true && this.state.data &&
                                        <div>
                                            <Grid container spacing={1}>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <small className="text-muted p-t-30 db">Bank</small>                  
                                                </Grid>                                        

                                                <Grid item sm={4} md={4} lg={4}>
                                                    <small className="text-muted p-t-30 db">Naam bank</small>                  
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <small className="text-muted p-t-30 db">BIC</small>                  
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                    <small className="text-muted p-t-30 db">IBAN</small>                  
                                                </Grid>                                        

                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <h6 style={{paddingTop: '35px'}}>Activitiet</h6> 
                                                </Grid>                                        
                                                <Grid item sm={4} md={4} lg={4}>
                                                  <EditString className="card-title m-t-10"
                                                    value={this.state.data.rekomsdir}
                                                    coltype = 'STR'                        
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'rekomsdir')}
                                                  />
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                  <EditString className="card-title m-t-10"
                                                    value={this.state.data.rekbicdir}
                                                    coltype = 'STR'                        
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'rekbicdir')}
                                                  />
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                  <EditString className="card-title m-t-10"
                                                    value={this.state.data.rekibandir}
                                                    coltype = 'STR'                        
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'rekibandir')}
                                                  />
                                                </Grid>                                        
                                            </Grid>

                                            <Grid container spacing={1}>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <h6 style={{paddingTop: '35px'}}>Accijnzen</h6> 
                                                </Grid>                                        
                                                <Grid item sm={4} md={4} lg={4}>
                                                  <EditString className="card-title m-t-10"
                                                    value={this.state.data.rekomsacc}
                                                    coltype = 'STR'                        
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'rekomsacc')}
                                                  />
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                  <EditString className="card-title m-t-10"
                                                    value={this.state.data.rekbicacc}
                                                    coltype = 'STR'                        
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'rekbicacc')}
                                                  />
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                  <EditString className="card-title m-t-10"
                                                    value={this.state.data.rekibanacc}
                                                    coltype = 'STR'                        
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'rekibanacc')}
                                                  />
                                                </Grid>                                        

                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item sm={2} md={2} lg={2}>
                                                    <h6 style={{paddingTop: '35px'}}>Refund</h6> 
                                                </Grid>                                        
                                                <Grid item sm={4} md={4} lg={4}>
                                                  <EditString className="card-title m-t-10"
                                                    value={this.state.data.rekomsref}
                                                    coltype = 'STR'                        
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'rekomsref')}
                                                  />
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                  <EditString className="card-title m-t-10"
                                                    value={this.state.data.rekbicref}
                                                    coltype = 'STR'                        
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'rekbicref')}
                                                  />
                                                </Grid>                                        
                                                <Grid item sm={3} md={3} lg={3}>
                                                  <EditString className="card-title m-t-10"
                                                    value={this.state.data.rekibanref}
                                                    coltype = 'STR'                        
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'rekibanref')}
                                                  />
                                                </Grid>                                        
                                            </Grid>
                                        </div>
                                      }

                                      <div className='div_line' style={{marginBottom: '25px'}}></div>

                                    </CardBody>
                                  </PerfectScrollbar>

                                </TabPane>
                                <TabPane tabId="3">
                                  { this.state.tabvalue === '3' && this.props.account.MainData.data.taalcode && this.props.account.MainData.data.betaalvoorw && this.props.account.MainData.data.vatreg
                                    && this.props.account.MainData.data.zendwijze && this.props.account.MainData.data.factklant &&
                                    <PerfectScrollbar className="sidebar-nav">
                                      <CardBody style={{textAlign:'left',height:Math.floor( (window.innerHeight * .85) - 80) + 'px'}}>
                                        <Factuurview handleUpdates={this.handleFactuurviewUpdates} canMakePdf={this.makeInvoicePdf} status={this.state.invoicestatus} canSaveInvoice={this.handleInvoiceSave} canMakeInvoice={this.makeInvoice}/>
                                      </CardBody>
                                    </PerfectScrollbar>
                                  }        
                                  { this.state.tabvalue === '3' && (!this.props.account.MainData.data.taalcode || !this.props.account.MainData.data.betaalvoorw || !this.props.account.MainData.data.vatreg
                                    || !this.props.account.MainData.data.zendwijze || !this.props.account.MainData.data.factklant) &&
                                    <div style={{ width: "100%", textAlign: "center", marginTop: "25px", marginBottom: "25px" }}>
                                      <label style={{ margin: "auto" }} className="text-muted p-t-30 db">Onvolledige facturatiegegevens voor deze klant</label>
                                    </div>
                                  }              
                                </TabPane>
                                <TabPane tabId="4">
                                  <Nav tabs >
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.regtabvalue === 'DIR' })}
                                        onClick={(event) => { this.handleTabRegChange(event,'DIR'); }}
                                        style={{ cursor: 'default' }}
                                      >
                                        Directe Registratie
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.regtabvalue === 'ACC' })}
                                        onClick={(event) => { this.handleTabRegChange(event,'ACC'); }}
                                        style={{ cursor: 'default' }}
                                      >
                                        Accijnzen
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.regtabvalue === 'VAT' })}
                                        onClick={(event) => { this.handleTabRegChange(event,'VAT'); }}
                                        style={{ cursor: 'default' }}
                                      >
                                        VAT Refund
                                      </NavLink>
                                    </NavItem>
                                  </Nav>
                                   <TabPane tabId="DIR">
                                    { this.state.tabvalue === '4' && this.state.regtabvalue === 'DIR' && this.props.account.RegData && this.props.account.RegData.data && this.props.account.RegData.kol &&
                                      this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                      <TableGrid
                                        tableheight={tableheight} 
                                        columns={this.props.account.RegData.kol}
                                        data={this.props.account.RegData.data} 
                                        sizetofit={false}
                                        sortable={false}
                                        suppressMenu={true}
                                        enableRowGroup={false}
                                        suppressColumnVirtualisation={true}                                
                                        filter={true}
                                        sleutel='RegAccountsDir'
                                        getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                        editable={false}
                                        GridReady={this.onGridReady}
                                        sql={() => this.props.dispatch(GetRegAccount(this.props.user.MainData.data.id))}
                                        CellClickedEvent={(column, colDef, value) => this.RegCellClick(column,colDef, value)}
                                      />
                                    }
                                  </TabPane>
                                  <TabPane tabId="ACC">
                                    { this.state.tabvalue === '4' && this.state.regtabvalue === 'ACC' && this.props.account.RegData && this.props.account.RegData.data && this.props.account.RegData.kol &&
                                      this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                      <TableGrid
                                        tableheight={tableheight} 
                                        columns={this.props.account.RegData.kol}
                                        data={this.props.account.RegData.data} 
                                        sizetofit={false}
                                        sortable={false}
                                        suppressMenu={true}
                                        enableRowGroup={false}
                                        suppressColumnVirtualisation={true}                                
                                        filter={true}
                                        sleutel='RegAccountsAcc'
                                        getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                        editable={false}
                                        GridReady={this.onGridReady}
                                        sql={() => this.props.dispatch(GetRegAccount(this.props.user.MainData.data.id))}
                                        CellClickedEvent={(column, colDef, value) => this.RegCellClick(column,colDef, value)}
                                      />
                                    }
                                  </TabPane>
                                  <TabPane tabId="VAT">
                                    { this.state.tabvalue === '4' && this.state.regtabvalue === 'VAT' && this.props.account.RegData && this.props.account.RegData.data && this.props.account.RegData.kol &&
                                      this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                      <TableGrid
                                        tableheight={tableheight} 
                                        columns={this.props.account.RegData.kol}
                                        data={this.props.account.RegData.data} 
                                        sizetofit={false}
                                        sortable={false}
                                        suppressMenu={true}
                                        enableRowGroup={false}
                                        suppressColumnVirtualisation={true}                                
                                        filter={true}
                                        sleutel='RegAccountsVat'
                                        getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                        editable={false}
                                        GridReady={this.onGridReady}
                                        sql={() => this.props.dispatch(GetRegAccount(this.props.user.MainData.data.id))}
                                        CellClickedEvent={(column, colDef, value) => this.RegCellClick(column,colDef, value)}
                                      />
                                    }
                                  </TabPane>

                                </TabPane>
                                <TabPane tabId="5">
                                  { this.state.tabvalue === '5' && this.props.account.TarData && this.props.account.TarData.data && this.props.account.TarData.kol &&
                                    GenFunc.allowed(this.props.user.userData.secrows,'AccTarief','zien') &&
                                    this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                    <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.account.TarData.kol}
                                      data={this.props.account.TarData.data} 
                                      CellValueChanged={(params,waarde,object) => this.TariefChanged(params,waarde,object)}
                                      CellValueCheck={(params,value,error) => this.TariefCheck(params,value,error)}
                                      CellClickedEvent={(column, colDef, value) => this.TariefCellClick(column,colDef, value)}
                                      SaveAll={(params) => this.SubJobSaveAll(params)}
                                      sortable={true}
                                      sizetofit={false}
                                      suppressMenu={true}
                                      enableRowGroup={false}
                                      headerComponentParams = {null}
                                      suppressColumnVirtualisation={false}
                                      filter={true}
                                      editable={false}
                                      pinnedTopRowData={null }
                                      GridReady={this.onGridReady}
                                      sleutel='AccountTarief'                     
                                    />                                    
                                  }
                                </TabPane>
                                <TabPane tabId="6">
                                  <Nav tabs >
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.jobtabvalue === 'DIR' })}
                                        onClick={(event) => { this.handleTabJobChange(event,'DIR'); }}
                                      >
                                        Directe Registratie
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.jobtabvalue === 'ACC' })}
                                        onClick={(event) => { this.handleTabJobChange(event,'ACC'); }}
                                      >
                                        Accijnzen
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.jobtabvalue === 'VAT' })}
                                        onClick={(event) => { this.handleTabJobChange(event,'VAT'); }}
                                      >
                                        VAT Refund
                                      </NavLink>
                                    </NavItem>
                                    <NavItem>
                                      <NavLink
                                        className={classnames({ active: this.state.jobtabvalue === 'DIVA' })}
                                        onClick={(event) => { this.handleTabJobChange(event,'DIVA'); }}
                                      >
                                        Diversen
                                      </NavLink>
                                    </NavItem>

                                  </Nav>
                                   <TabPane tabId="DIR">
                                    { this.state.jobgraph === false && this.state.tabvalue === '6' && this.state.jobtabvalue === 'DIR' && this.props.account.JobsData && this.props.account.JobsData.data && this.props.account.JobsData.kol &&
                                      this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                      <TableGrid
                                        tableheight={tableheight} 
                                        columns={this.props.account.JobsData.kol}
                                        data={this.props.account.JobsData.data} 
                                        sizetofit={false}
                                        sortable={false}
                                        suppressMenu={true}
                                        enableRowGroup={false}
                                        suppressColumnVirtualisation={true}                                
                                        filter={true}
                                        sleutel='GetJobsAccountDir'
                                        getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}   
                                        editable={false}
                                        GridReady={this.onGridReadyJobs}
                                        sql={() => this.props.dispatch(GetJobsAccount(this.props.user.MainData.data.id))}
                                        CellClickedEvent={(column, colDef, value) => this.JobCellClick(column,colDef, value)}
                                        infinite= {true}          
                                        conditie= {this.props.account.JobsData.conditie}          
                                        funktie= "GetJobsAccount"          

                                      />
                                    }
                                  </TabPane>
                                   <TabPane tabId="DIVA">
                                    { this.state.jobgraph === false && this.state.tabvalue === '6' && this.state.jobtabvalue === 'DIVA' && this.props.account.JobsData && this.props.account.JobsData.data && this.props.account.JobsData.kol &&
                                      this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                      <TableGrid
                                        tableheight={tableheight} 
                                        columns={this.props.account.JobsData.kol}
                                        data={this.props.account.JobsData.data} 
                                        sizetofit={false}
                                        sortable={false}
                                        suppressMenu={true}
                                        enableRowGroup={false}
                                        suppressColumnVirtualisation={true}                                
                                        filter={true}
                                        sleutel='GetJobsAccountDiv'
                                        getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}   
                                        editable={false}
                                        GridReady={this.onGridReadyJobs}
                                        sql={() => this.props.dispatch(GetJobsAccount(this.props.user.MainData.data.id))}
                                        CellClickedEvent={(column, colDef, value) => this.JobCellClick(column,colDef, value)}
                                        infinite= {true}          
                                        conditie= {this.props.account.JobsData.conditie}          
                                        funktie= "GetJobsAccount"          

                                      />
                                    }
                                  </TabPane>

                                  <TabPane tabId="ACC">
                                    { this.state.jobgraph === false && this.state.tabvalue === '6' && this.state.jobtabvalue === 'ACC' && this.props.account.JobsData && this.props.account.JobsData.data && this.props.account.JobsData.kol &&
                                      this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                      <TableGrid
                                        tableheight={tableheight} 
                                        columns={this.props.account.JobsData.kol}
                                        data={this.props.account.JobsData.data} 
                                        sizetofit={false}
                                        sortable={false}
                                        suppressMenu={true}
                                        enableRowGroup={false}
                                        suppressColumnVirtualisation={true}                                
                                        filter={true}
                                        sleutel='GetJobsAccountAcc'
                                        getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                        editable={false}
                                        GridReady={this.onGridReadyJobs}
                                        sql={() => this.props.dispatch(GetJobsAccount(this.props.user.MainData.data.id))}
                                        CellClickedEvent={(column, colDef, value) => this.JobCellClick(column,colDef, value)}
                                        infinite= {true}          
                                        conditie= {this.props.account.JobsData.conditie}          
                                        funktie= "GetJobsAccount"          

                                      />
                                    }
                                  </TabPane>
                                  <TabPane tabId="VAT">
                                    { this.state.jobgraph === false && this.state.tabvalue === '6' && this.state.jobtabvalue === 'VAT' && this.props.account.JobsData && this.props.account.JobsData.data && this.props.account.JobsData.kol &&
                                      this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                      <TableGrid
                                        tableheight={tableheight} 
                                        columns={this.props.account.JobsData.kol}
                                        data={this.props.account.JobsData.data} 
                                        sizetofit={false}
                                        sortable={false}
                                        suppressMenu={true}
                                        enableRowGroup={false}
                                        suppressColumnVirtualisation={true}                                
                                        filter={true}
                                        sleutel='GetJobsAccountVat'
                                        getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                        editable={false}
                                        GridReady={this.onGridReadyJobs}
                                        sql={() => this.props.dispatch(GetJobsAccount(this.props.user.MainData.data.id))}
                                        CellClickedEvent={(column, colDef, value) => this.JobCellClick(column,colDef, value)}
                                        infinite= {true}          
                                        conditie= {this.props.account.JobsData.conditie}          
                                        funktie= "GetJobsAccount"          

                                      />
                                    }
                                  </TabPane>


                                  { this.state.tabvalue === '6' && this.props.account.JobsData && this.props.account.JobsData.data && this.props.account.JobsData.kol 
                                   && this.state.jobgraph === true && this.state.workflowstories &&
                                    this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                    <div>
                                      <Grid container spacing={1}>
                                        <Grid style={{paddingTop:'50px'}} item xs={12}>
                                          <Slider
                                            defaultValue={this.state.jaar}
                                            getAriaValueText={this.valuejaar}
                                            onChangeCommitted={(event,value) => this.JaarChange(event,value)}
                                            aria-labelledby="discrete-slider"
                                            valueLabelDisplay="on"
                                            step={1}
                                            marks
                                            min={2015}
                                            max={2050}
                                          />                                        
                                        </Grid>
                                        <Grid style={{paddingTop:'50px',overflowY:'auto', overflowX:'hidden', height:tableheight}} item xs={12}>
                                          <Timeline style={{overflowY:'auto', overflowX:'hidden', height:tableheight}} stories={this.state.workflowstories} />
                                        </Grid>
                                      </Grid>


                                    </div>
                                  }

                                </TabPane>
                                <TabPane tabId="7">
                                
                                  { this.state.tabvalue === '7' && this.props.account.DocData && this.props.account.DocData.data && this.props.account.DocData.kol &&
                                    this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                    <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.account.DocData.kol}
                                      data={this.props.account.DocData.data} 
                                      sizetofit={true}
                                      sortable={false}
                                      suppressMenu={true}
                                      enableRowGroup={false}
                                      suppressColumnVirtualisation={true}                                
                                      filter={true}
                                      sleutel='DocAccounts'
                                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                      editable={false}
                                      GridReady={this.onGridReady}
                                      sql={() => this.props.dispatch(GetDocAccount(this.props.user.MainData.data.id))}
                                      CellClickedEvent={(column, colDef, value) => this.DocCellClick(column,colDef, value)}
                                    />
                                  }                      
                                </TabPane>

                                <TabPane tabId="volm">
                                
                                  { this.state.tabvalue === 'volm' && this.props.account.VolmData && this.props.account.VolmData.data && this.props.account.VolmData.kol &&
                                    this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                    <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.account.VolmData.kol}
                                      data={this.props.account.VolmData.data} 
                                      sizetofit={true}
                                      sortable={false}
                                      suppressMenu={true}
                                      enableRowGroup={false}
                                      suppressColumnVirtualisation={true}                                
                                      filter={true}
                                      sleutel='VolmAccounts'
                                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                      editable={false}
                                      GridReady={this.onGridReady}
                                      sql={() => this.props.dispatch(GetVolmAccount(this.props.user.MainData.data.id))}
                                      CellClickedEvent={(column, colDef, value) => this.VolmCellClick(column,colDef, value)}
                                    />
                                  }                      
                                </TabPane>

                                <TabPane tabId="cor">
                                
                                  { this.state.tabvalue === 'cor' && this.props.account.CorData && this.props.account.CorData.data && this.props.account.CorData.kol &&
                                    this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.id !== 0 &&
                                    <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.account.CorData.kol}
                                      data={this.props.account.CorData.data} 
                                      sizetofit={true}
                                      sortable={false}
                                      suppressMenu={true}
                                      enableRowGroup={false}
                                      suppressColumnVirtualisation={true}                                
                                      filter={true}
                                      sleutel='CorAccounts'
                                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                      editable={false}
                                      GridReady={this.onGridReady}
                                      sql={() => this.props.dispatch(GetCorAccount(this.props.user.MainData.data.id))}
                                      CellClickedEvent={(column, colDef, value) => this.CorCellClick(column,colDef, value)}
                                    />
                                  }                      
                                </TabPane>

                              </TabContent>

                            </div>
                          </CardBody>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </section>
            )}
          </Dropzone>
        }
        {this.state.PopUpScreen}
      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Accountpage));
