import { ALLSUPPLIERS_SUCCESS, GETSUPPLIER_SUCCESS, GETDOCSUPPLIER_SUCCESS, GETREGSUPPLIER_SUCCESS,SUP_TAB } from '../constants/';

const INIT_STATE = {
    AllData:null,
    MainData:null,
    DocData:null,
    RegData:null,
    LastTab: '1',    
}

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case ALLSUPPLIERS_SUCCESS:
            return {
                ...state,
                AllData: action.payload
            };

       case SUP_TAB:
            return {
                ...state,
                LastTab: action.payload
            };            

        case GETSUPPLIER_SUCCESS:
            return {
                ...state,
                MainData: action.payload
            };

        case GETDOCSUPPLIER_SUCCESS:
            return {
                ...state,
                DocData: action.payload
            };

        case GETREGSUPPLIER_SUCCESS:
            return {
                ...state,
                RegData: action.payload
            };

        default:
            return state;


    }
};
