import { ALLREGOMS_SUCCESS, ALLREGTAR_SUCCESS, REGISTRATIE_SUCCESS, GETDOCREGISTRATIE_SUCCESS, GETJOBSREGISTRATIE_SUCCESS, ALLREGISTRATIE_SUCCESS, REG_TAB, GETCORREGISTRATIE_SUCCESS } from '../constants/';

const INIT_STATE = {
    AllRegOmsData: null,
    AllRegTarData: null,
    AllRegData: null,
    MainData: null,
    DocData: null,
    CorData: null,
    JobsData: null,
    LastTab: '1',
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ALLREGOMS_SUCCESS:
            return {
                ...state,
                AllRegOmsData: action.payload
            };

        case REG_TAB:
            return {
                ...state,
                LastTab: action.payload
            };

        case ALLREGISTRATIE_SUCCESS:
            return {
                ...state,
                AllRegData: action.payload
            };

        case GETDOCREGISTRATIE_SUCCESS:
            return {
                ...state,
                DocData: action.payload
            };

        case GETCORREGISTRATIE_SUCCESS:
            return {
                ...state,
                CorData: action.payload
            };

        case GETJOBSREGISTRATIE_SUCCESS:
            return {
                ...state,
                JobsData: action.payload
            };

        case REGISTRATIE_SUCCESS:
            return {
                ...state,
                MainData: action.payload
            };

        case ALLREGTAR_SUCCESS:
            return {
                ...state,
                AllRegTarData: action.payload
            };

        default:
            return state;

        
    }
};