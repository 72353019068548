import { AllCONTACTS_SUCCESS, GETCONTACT_SUCCESS, GETDOCCONTACT_SUCCESS, GETCORCONTACT_SUCCESS, CONT_TAB } from '../constants/';

const INIT_STATE = {
    AllData: null,
    MainData: null,
    DocData: null,
    CorData: null,
    LastTab: '1',
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case AllCONTACTS_SUCCESS:
            return {
                ...state,
                AllData: action.payload
            };

        case CONT_TAB:
            return {
                ...state,
                LastTab: action.payload
            };

        case GETCONTACT_SUCCESS:
            return {
                ...state,
                MainData: action.payload
            };

        case GETCORCONTACT_SUCCESS:
            return {
                ...state,
                CorData: action.payload
            };


        case GETDOCCONTACT_SUCCESS:
            return {
                ...state,
                DocData: action.payload
            };

        default:
            return state;

        
    }
};