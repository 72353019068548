import React, {
  Component,
} from 'react';
import GenFunc from "../../functions/gen_functions.js";
import Save from "@material-ui/icons/Save";
import IconButton from '@material-ui/core/IconButton';
export default class GridEditCheck extends Component {
    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
        this.inputid = GenFunc.MakeId()
        this.myInput = React.createRef();
        this.state = {
            value: (this.props.value === '0' || this.props.value === 0 ) ? false : true,
            error:false,
        }
    }
    componentDidMount() {
        setTimeout(() => {
          document.activeElement.blur();
          let element = document.getElementById(this.inputid);
          if (element) {element.focus()}
       }, 10);    
    }
    getValue() {
        return this.state.value;
    }
    isPopup() {
        return false;
    }
    change(event, valid, stateName) 
    {
      if (event.shiftKey === false && (event.key === 'Enter' || event.key === 'Tab'))
      {
        if (this.props && this.props.value === true)
        {
          this.setState({value:true})
          event.preventDefault();          
          this.props.agGridReact.props.onCellClicked(this.props)
        }
      }
    }
    render() {
        return (
          <div tabIndex = "0" style={{width: '100%'}}>
          {this.props.value === true &&
            <IconButton id={this.inputid} onKeyDown = {this.change}
                aria-label="toggle password visibility">
                <Save  onKeyDown = {this.change} style={{color:'#2fa5c7',fontSize:'18px'}}/>
            </IconButton>
          }
          {this.props.value === false &&
            <IconButton id={this.inputid} 
                aria-label="toggle password visibility">
                <Save style={{color:'#e23f78',fontSize:'18px'}}/>
            </IconButton>
          }
          </div>
        );
    }
}