import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import Chip from '@material-ui/core/Chip';

const DndChip = ({ id, text, index, moveCard, data, icon, onDelete, onClick }) => {
  const ref = useRef(null)
  const [, drop] = useDrop({
    accept: 'DndChip',
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      moveCard(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'DndChip', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
      <Chip
        ref={ref}
        style={{marginTop:'10px',marginRight:'8px',opacity:opacity}}
        variant="outlined"
        color="primary"
        size="small"
        id={id}
        key={data.id}
        icon={icon}
        label={data.description}
        index={data.id}
        onClick={onClick}
        onDelete={onDelete}
      />
  )
}
export default DndChip
