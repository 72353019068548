import React from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar'
import {GetUser} from "../../../redux/settings/userActions";
import {GetAccount,GetContAccount} from "../../../redux/settings/accountActions";
import {GetContact} from "../../../redux/settings/contactActions";
import {GetKantoor, GetKantContact, GetContKantoor} from "../../../redux/settings/kantoorActions";
import {GetRegistration} from "../../../redux/settings/registrationActions";
import {setSubTitle,clickview,zetpopup} from "../../../redux/settings/action";
import TableGrid from "../../../components/TableGrid/TableGrid.jsx";
import PopJobAanvrNum from "../../../views/jobs/PopJobAanvrNum.jsx";
import SqlFunction from "../../../functions/sql_functions";


var gridapiviews = null;
const mapStateToProps = state => ({
    ...state
});

class CustomizerViews extends React.Component {
    state = {
        data:this.props.settings && this.props.settings.views ? this.props.settings.views : [],
        tablegrid1:null,
        tablegrid:( <TableGrid
                        style={{background: '#fff'}}
                        tableheight={Math.floor(window.innerHeight * .78) + 'px'} 
                        GridReady={this.onGridReady}
                        columns={[{"sortable":true,"headerName":"","field":"description","width":300,"cellRenderer":"","type":"","coltype":"STR","decimaal":"0","format":"","prefix":"","colvisible":"0","rowGroupIndex":"","hide":false,"cellStyle":{"border":"none"},
                                "suppressMenu":true,"cellEditor":"","cellEditorParams":"","selectcel":"","pinned":"","editable":false,"resizable":true,"filter":"agTextColumnFilter",
                                "floatingFilterComponentParams":"{suppressFilterButton: true}","comparator":"","menuTabs":["generalMenuTab","columnsMenuTab"],
                                "filterParams":""}]}
                        data={this.props.settings.views} 
                        CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                        sortable={false}
                        suppressMenu={true}
                        enableRowGroup={false}
                        filter={true}
                    />)
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
        this.onGridReady = this.onGridReady.bind(this);
        this.ClosePopJobs = this.ClosePopJobs.bind(this);


    }

    componentDidMount() {
        window.addEventListener("load", this.defaultSettings);
    }
    ClosePopJobs() {
        this.props.dispatch(zetpopup(null));
    }

    async CellClick(waarde) {
        var data = waarde.data
        this.toggle()
        switch (data.vorm) 
        {
            case 'JOB':
                this.props.dispatch(clickview({id:data.id, vorm:'JOB'}));
//                if (data.object)
//                {
//                   data.object.setState({PopUpScreen:null})
//                }
//                else
//                {
                    this.props.dispatch(zetpopup(null));

                    let jobdata = await SqlFunction.DoSql('GetJob', waarde.data);
                    let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
                    window.scrollTo(0, 0);
                    this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} data={jobdata} close={this.ClosePopJobs} title={title} />)));
//                }
                break;

            case 'ACC':
                this.props.dispatch(setSubTitle('Klantenfiche'))
                this.props.dispatch(clickview({id:data.id, vorm:'ACC'}));
                await this.props.dispatch(GetAccount(data.id))
                await this.props.dispatch(GetContAccount(data.id))
                this.props.history.push('/account');
                break;

            case 'CONT':
                this.props.dispatch(setSubTitle('Contactpersoon'))
                this.props.dispatch(clickview({id:data.id, vorm:'CONT'}));
                await this.props.dispatch(GetContact(data.id))
                this.props.history.push('/contact');
                break;

            case 'USR':
                this.props.dispatch(setSubTitle('Gebruiker'))
                this.props.dispatch(clickview({id:data.id, vorm:'USR'}));
                await this.props.dispatch(GetUser(data.id))
                this.props.history.push('/user');
                break;

            case 'KANT':
                this.props.dispatch(setSubTitle('Kantoor'))
                this.props.dispatch(clickview({ id: data.id, vorm: 'KANT' }));
                await this.props.dispatch(GetKantoor(data.id))
                await this.props.dispatch(GetContKantoor(data.id))
                this.props.history.push('/kantoor');
                break;  


            case 'REG':
                this.props.dispatch(setSubTitle('Registratie'))
                this.props.dispatch(clickview({ id: data.id, vorm: 'REG' }));
                await this.props.dispatch(GetRegistration(data.id))
                this.props.history.push('/registration');
                break;  


            case 'KANTCONT':
                this.props.dispatch(setSubTitle('Kantoor contactpersoon'))
                this.props.dispatch(clickview({ id: data.id, vorm: 'KANTCONT' }));
                await this.props.dispatch(GetKantContact(data.id))
                this.props.history.push('/kantcontact');
                break;  

            default:    
                break;
        }     
        return;
    }

    async componentWillReceiveProps(nextProps, nextState) 
    {
      if (nextProps.settings && nextProps.settings.views)
      {
        if (nextProps.settings.views)
        {
            if (this.state.tablegrid1)
            {
                    this.setState({data:this.props.settings.views,  tablegrid1:null,    tablegrid:(                          <TableGrid
                                style={{background: '#fff'}}
                                tableheight={Math.floor(window.innerHeight * .78) + 'px'} 
                                GridReady={this.onGridReady}
                                columns={[{"sortable":true,"headerName":"","field":"description","width":300,"cellRenderer":"","type":"","coltype":"STR","decimaal":"0","format":"","prefix":"","colvisible":"0","rowGroupIndex":"","hide":false,"cellStyle":{"border":"none"},
                    "suppressMenu":true,"cellEditor":"","cellEditorParams":"","selectcel":"","pinned":"","editable":false,"resizable":true,"filter":"agTextColumnFilter",
                    "floatingFilterComponentParams":"{suppressFilterButton: true}","comparator":"","menuTabs":["generalMenuTab","columnsMenuTab"],
                    "filterParams":""}]}
                                data={this.props.settings.views} 
                                CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                                sortable={false}
                                suppressMenu={true}
                                enableRowGroup={false}
                                filter={true}
                              />
                            )})

            }
            else
            {
                    this.setState({data:this.props.settings.views,  tablegrid:null,    tablegrid1:(                          <TableGrid
                                style={{background: '#fff'}}
                                tableheight={Math.floor(window.innerHeight * .78) + 'px'} 
                                GridReady={this.onGridReady}
                                columns={[{"sortable":true,"headerName":"","field":"description","width":300,"cellRenderer":"","type":"","coltype":"STR","decimaal":"0","format":"","prefix":"","colvisible":"0","rowGroupIndex":"","hide":false,"cellStyle":{"border":"none"},
                    "suppressMenu":true,"cellEditor":"","cellEditorParams":"","selectcel":"","pinned":"","editable":false,"resizable":true,"filter":"agTextColumnFilter",
                    "floatingFilterComponentParams":"{suppressFilterButton: true}","comparator":"","menuTabs":["generalMenuTab","columnsMenuTab"],
                    "filterParams":""}]}
                                data={this.props.settings.views} 
                                CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                                sortable={false}
                                suppressMenu={true}
                                enableRowGroup={false}
                                filter={true}
                              />
                            )})
            }


        }
        else
        {
            this.setState({data:[]})
        }
        if (gridapiviews)
        {
            gridapiviews.refreshCells()
        }
      }

    }
    onGridReady(params)
    {
        gridapiviews = params.api;
    }

    toggle() {
        document.getElementById("customizerview").classList.toggle("show-service-panel");
    }
    render() {
        return (
            <aside   style = {{zIndex:'2000', background: '#4F5467'}} className="customizerview" id="customizerview">
                {/*--------------------------------------------------------------------------------*/}
                {/* Toggle Customizer From Here                                                    */}
                {/*--------------------------------------------------------------------------------*/}
                <span className="service-panel-toggle text-white" onClick={() => this.toggle()}><i style={{fontSize:'large'}} className="fa fa-clone"></i></span>
                <PerfectScrollbar>
                    <div style={{background: '#fff'}} className="customizer-body">
                        <div style={{background: '#4F5467', color:'#fff', textAlign: 'center',height: '50px'}} className="mt-3 border-bottom px-3">                       
                            {/*--------------------------------------------------------------------------------*/}
                            {/* Change LOGO Background                                                         */}
                            {/*--------------------------------------------------------------------------------*/}
                            <h5 className="font-medium m-0">Flow tabel</h5>
                        </div>
                        <div style={{background: '#fff'}}>
                          {this.state.tablegrid}
                          {this.state.tablegrid1}
                        </div>
                    </div>
                </PerfectScrollbar>
            </aside >
        );
    }
}
export default connect(mapStateToProps)(CustomizerViews)
