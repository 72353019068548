import { ALLSUPPLIERS_SUCCESS, GETSUPPLIER_SUCCESS, GETDOCSUPPLIER_SUCCESS, GETREGSUPPLIER_SUCCESS, SUP_TAB } from '../constants/';


const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const AllSuppliersSuccess = (payload) => {
    return {
      type: ALLSUPPLIERS_SUCCESS,
      payload: payload.response.data
    }
  }
  
  export const setTab = (payload) => {
      return {
          type: SUP_TAB,
          payload
      }
  }  
  export const GetSupplierSuccess = (payload) => {
  
    return {
      type: GETSUPPLIER_SUCCESS,
      payload: payload.response.data
    }
  }

  export const GetDocSupplierSuccess = (payload) => {
    return {
      type: GETDOCSUPPLIER_SUCCESS,
      payload: payload.response.data
    }
  }

  export const GetRegSupplierSuccess = (payload) => {
    return {
      type: GETREGSUPPLIER_SUCCESS,
      payload: payload.response.data
    }
  }

export function AllSuppliers(props) {
    return function action(dispatch) {
      let authOptions = SqlFunction.SetAuth('AllSuppliers', {});
      const request = axios(authOptions);
      return request.then(
        response => {
          if (response.data && response.data.allowed === false) {
            props.history.push('/authentication/login');
          }
          else {
            dispatch(AllSuppliersSuccess({ response }))
          }
        },
        () => dispatch(AllSuppliersSuccess(null))
      );
    }
  }
  
  export function GetSupplier(id) {
    return function action(dispatch) {
      let authOptions = SqlFunction.SetAuth('GetSupplier', { id: id });
      const request = axios(authOptions);
      return request.then(
        response => { dispatch(GetSupplierSuccess({ response })) },
        () => { dispatch(GetSupplierSuccess(null)) }
      );
    }
  }

export function GetDocSupplier(id) {
    return function action(dispatch) {
      let authOptions = SqlFunction.SetAuth('GetDocSupplier', { id: id });
      const request = axios(authOptions);
      return request.then(
        response => { dispatch(GetDocSupplierSuccess({ response })) },
        () => { dispatch(GetDocSupplierSuccess(null)) }
      );
    }
  }

  export function GetRegSupplier(id) {
    return function action(dispatch) {
      let authOptions = SqlFunction.SetAuth('GetRegSupplier', { id: id });
      const request = axios(authOptions);
      return request.then(
        response => { dispatch(GetRegSupplierSuccess({ response })) },
        () => { dispatch(GetRegSupplierSuccess(null)) }
      );
    }
  }

export function CreateSupplier() {
    return function action(dispatch) {
      let response = { data: null }
      let data = { id: 0, name: '', e_mail: '', main_phone: '', adress: '', zipcode: "", zipcode_txt: "", country: "", country_txt: "", info: '', vatnumber:'' }
      response.data = { data: data }
      dispatch(GetSupplierSuccess({ response }));
    }
  }
