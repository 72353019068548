import {LOG_IN_SUCCESS, AllUSERS_SUCCESS, GETUSER_SUCCESS, USERACCOUNTS_SUCCESS, GETJOBSUSER_SUCCESS} from '../constants/';

const INIT_STATE = {
    userData: null,
    AlluserData:null,
    MainData:null,
    AccountData:null,
    JobsData:null,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOG_IN_SUCCESS:
            return {
                ...state,
                userData: action.payload
            };
            
        case USERACCOUNTS_SUCCESS:
            return {
                ...state,
                AccountData: action.payload
            };

        case AllUSERS_SUCCESS:
            return {
                ...state,
                AlluserData: action.payload
            };

        case GETUSER_SUCCESS:
            return {
                ...state,
                MainData: action.payload
            };

        case GETJOBSUSER_SUCCESS:
            return {
                ...state,
                JobsData: action.payload
            };

        default:
            return state;
    }
};