
import React from "react";

class PictureUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: this.props.DefaultPhoto
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if (nextProps.DefaultPhoto)
    {
      this.setState({
          file: null,
          importagePreviewUrl: null,
        });
    }
  }  
  



  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file)
    {
      reader.onloadend = () => {
        this.setState({
          file: file,
          imagePreviewUrl: reader.result
        });
        if (this.props.SetFileName) {this.props.SetFileName(reader.result)}

      };
      reader.readAsDataURL(file);
    }
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  render() {
    const {
      style,
      stylemain,
    } = this.props;    
    let Foto = (this.state.importagePreviewUrl) ? this.state.importagePreviewUrl : this.props.DefaultPhoto;
    return (
      <div className="picture-container" >
        <div className="picture" style={stylemain}>
          <img
            src={Foto}
            className="picture-src"
            alt="..."
            style={style}
          />
          <input type="file" style={this.props.inputstyle} onChange={e => this.handleImageChange(e)} />
        </div>
      </div>
    );
  }
}

export default PictureUpload;
