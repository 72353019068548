import React from 'react'
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 


import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import { Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import {setSubTitle,zetListMain,ZetListData} from "../../redux/settings/action";
import {GetVatRefCode, CreateVatrefcode,GetSubVatRefCode} from "../../redux/settings/jobsActions";
import {setPanelRight, setTitleRight} from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";



var gridapi = null;
moment.locale('nl-be');


const mapStateToProps = state => ({
  ...state
});


class Vatrefcodepage extends React.Component {

  state = {
    DownloadData:{id:''},
    velddata:{verplicht:false,veld:'',veld_txt:'',veld_code:''},
    tableheight: Math.floor(window.innerHeight * .70) + 'px',
    tabvalue:'1', 
    pinnedSubVatRefCode:[{id:0,code_id:this.props.jobs.VatRefCodeData.data.id,code:'',rep_code:'',btw:'',aftrek:'',save:'',verwijder:''}],
    edit:this.props.jobs && this.props.jobs.VatRefCodeData && this.props.jobs.VatRefCodeData.data && this.props.jobs.VatRefCodeData.data.id === 0 ? true : false,
    save:false,
    subjobsave:false,
    subtariefsave: false,
    subsave:false,
    adrestel:0,
    checkval:false,
    PopUpScreen:null,
    data:this.props.jobs && this.props.jobs.VatRefCodeData && this.props.jobs.VatRefCodeData.data && this.props.jobs.VatRefCodeData.data.id === 0 ? this.props.jobs.VatRefCodeData.data : null,
    speedactions:[
      GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit') ? { icon: <EditIcon />, name: 'Edit', tooltip:'Aanpassen gegevens' } : null,
      GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit') ? { icon: <MdiNewBox />, name: 'New',  tooltip:'Nieuwe code maken' }:null,
      GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit') ?{ icon: <SaveIcon />, name: 'Save', tooltip:'Bewaren gegevens'  } : null,
    ],
    speedopen: false,
    subspeedopen: false,    
    subspeedzien:true,
    workflowstories:[],
    changewfindex:null,
  };



  constructor(props, context) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);    
    this.onGridReady = this.onGridReady.bind(this);
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.ValueCheck = this.ValueCheck.bind(this);
    this.SecValueCheck = this.SecValueCheck.bind(this);
    this.SubVatRefCodeChanged = this.SubVatRefCodeChanged.bind(this);
    this.SubVatRefCodeCheck = this.SubVatRefCodeCheck.bind(this);
    this.SubVatRefCodeCellClick = this.SubVatRefCodeCellClick.bind(this);

    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
    this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);


    this.ZetEdit = this.ZetEdit.bind(this);
    this.CheckSave = this.CheckSave.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.MainSave = this.MainSave.bind(this)
    this.checkconfirm = this.checkconfirm.bind(this)
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
    this.handleJa = this.handleJa.bind(this)
    this.ActionClick = this.ActionClick.bind(this)
    this.ErrorCheck = this.ErrorCheck.bind(this);

  }


  async ErrorCheck(that, waarde, type, colId) {
    let data = this.state.data;
    let terug = Validation.ValidCheck('AllVatRefCode', waarde, colId, that, this.props.jobs.AllVatRefCodeData.data, data);
    that.setState({ error: terug })
  }      

  async handleTabChange(event, newValue)  {
    if (newValue === '1')
    {
      await this.props.dispatch(GetSubVatRefCode(this.props.jobs.VatRefCodeData.data.id))
      await this.props.dispatch(ZetListData('SubVatRefCode',this.props.jobs.VatRefCodeData.data.id));
      await this.props.dispatch(zetListMain('SubVatRefCode',this.props.jobs.VatRefCodeData.data.id));
    }
    this.setState({tabvalue:newValue});
  };

  handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:true });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
  };

  handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
  };

  handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
  };

  async ActionClick(name)
  {
    switch (name) 
    {
      case 'Edit':
        this.setState({edit:true,data:this.props.jobs.VatRefCodeData.data,speedopen:false})
        break;

      case 'New':
        await this.props.dispatch(CreateVatrefcode())
        this.setState({edit:true,data:this.props.jobs.VatRefCodeData.data,speedopen:false})
        break;

      case 'Save':
        this.MainSave();
        break;

      default:    
        break;
    }     
    this.setState({ speedopen: false, subspeedopen:false });
  }

  ZetEdit()
  {
     this.setState({edit:true, data:this.props.jobs.VatRefCodeData.data})
  }


  componentDidMount(){
    window.addEventListener("resize", this.resizeFunction);
    this.props.dispatch(setTitleRight('Vat refund codes'))
    this.props.dispatch(setSubTitle('Vat refund codes'))
    this.props.dispatch(setPanelRight(true))
    this.handleTabChange(null,'1')

    if (this.props.jobs.VatRefCodeData)
    {
      if (this.props.jobs.VatRefCodeData.data && this.props.jobs.VatRefCodeData.data.id === 0)
      {
        this.setState({edit:true,save:false,subsave:false,data:this.props.jobs.VatRefCodeData.data})
      }
    }

  }

  componentWillUnmount() 
  {
    this.checkconfirm(this.props.jobs.VatRefCodeData);    
    this.props.dispatch(setPanelRight(false))
    this.props.dispatch(setTitleRight(''))
    window.removeEventListener("resize", this.resizeFunction);
  }

  async componentWillReceiveProps(nextProps, nextState) 
  {
    if (this.props.jobs.VatRefCodeData)
    {
      if (this.props.jobs.VatRefCodeData.data.id !== nextProps.jobs.VatRefCodeData.data.id)
      {
        await this.checkconfirm(this.props.jobs.VatRefCodeData);
        if (nextProps.jobs.VatRefCodeData.data.id === 0)
        {
          this.setState({edit:true,save:false,subsave:false})
          this.handleTabChange(null,'1')

        }
        else
        {
          await this.props.dispatch(GetSubVatRefCode(nextProps.jobs.VatRefCodeData.data.id))
          await this.props.dispatch(ZetListData('SubVatRefCode',nextProps.jobs.VatRefCodeData.data.id));
          await this.props.dispatch(zetListMain('SubVatRefCode',nextProps.jobs.VatRefCodeData.data.id));
          this.setState({edit:false,save:false,subsave:false})
          this.handleTabChange(null,'1')

        }
      }
    }
  }


  async handleJa(parameters)
  {
    if (this.state.save === true)
    {
      await SqlFunction.DoSql('ChgVatRefCode',this.state.data);
    }
    if (this.state.subsave === true)
    {
    }
    this.setState({edit:false, save:false,subsave:false})
  }


  checkconfirm (parameters) 
  {

    if (this.state.save === true)
    {
      confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,        
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Aanpassingen niet doorgevoerd.</h1>
            <p>Wens je de aanpassingen alsnog te bewaren?</p>
            <button
              onClick={() => {
                this.handleJa(parameters);
                onClose();
              }}
            >
              Ja
            </button>
            <button onClick={() => {
                this.setState({edit:false, save:false,subsave:false,checkval:false})
                onClose();
              }}
            >
              Nee
            </button>
          </div>
        );
      }
      });
    }

  }

  resizeFunction() 
  {
    if (window.innerHeight >= 960) 
    {
      this.setState({tableheight: Math.floor(window.innerHeight * .70) + 'px'});
    }
    else
    {
      this.setState({tableheight: '600px'});
    }
  }

  onGridReady(params)
  {
    gridapi = params;
  }

  async MainSave() {
    if (this.state.save === true) 
    {
      let retdata = await SqlFunction.DoSql('ChgVatRefCode',this.state.data);
      this.setState({edit:false, save:false,data:null,checkval:false})
      await this.props.dispatch(GetVatRefCode(retdata.id))
    }
  }
  getContextMenuItems(params,sleutel) 
  {
    return null;
  }

  CheckSave(data)
  {
    let terug = true
    terug = Validation.ValidCheck('AllVatRefCode',data.code,'code',this,this.props.jobs.AllVatRefCodeData.data,data) ;
    if (terug === false)
    {
      terug = Validation.ValidCheck('AllVatRefCode',data.country,'country',this,this.props.jobs.AllVatRefCodeData.data,data) ;
    }
    return !terug;
  }

  SecValueCheck(params,waarde) {
    if (GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit'))
    {
      params.props.data.save = true;
      this.setState({subsave:true})
    }
  }       


  SubVatRefCodeChanged(params,waarde,that) {
  }                        

  SubVatRefCodeCheck(params,waarde) {
    var colId = params.props.column.getId();
    params.props.data.save = true;
    let error = false;
    if (colId === 'code')
    {
      if (waarde && waarde.length > 1) 
      {
        error = false
        params.props.data.save = true;
      } else 
      { 
        error = true
        params.props.data.save = '';
      }
      params.props.data[colId] = waarde;
    }
    else
    {
      if (params.props.data.code && params.props.data.code.length > 1) 
      {
        params.props.data.save = true;
      } else 
      { 
        params.props.data.save = '';
      }
      params.props.data[colId] = waarde;

    }
    params.setState({error:error})
  }       

  async SubVatRefCodeCellClick(waarde) {
    var data = waarde.data
    let column = waarde.column.colDef.field;
    let retdata = null;
    if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit'))
    {
      if (data.save === true) 
      {
        data.code_id = this.props.jobs.VatRefCodeData.data.id;
        retdata = await SqlFunction.DoSql('ChgSubVatRefCode',data);
        waarde.data.save='';
        if (data.id === 0) {
          waarde.data.id = retdata.id;
          gridapi.api.stopEditing();
          gridapi.api.updateRowData({
            add: [waarde.data],
            addIndex: 0
          });
          waarde.node.setData({id:0,code_id:this.props.jobs.VatRefCodeData.data.id,code:'',rep_code:'',btw:'',aftrek:'',save:'',verwijder:''});
        }
        else {
          waarde.node.setData(waarde.data);
        }
        this.setState({subsave:false})
        await this.props.dispatch(GetSubVatRefCode(this.props.jobs.VatRefCodeData.data.id))

      }
    }
    if (column === 'verwijder' && GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit'))
    {
      if (data.verwijder === true || data.verwijder === '1') 
      {
        retdata = await SqlFunction.DoSql('DeleteSubVatRefCode',data);
        await this.props.dispatch(GetSubVatRefCode(this.props.jobs.VatRefCodeData.data.id))
      }
    }

    

    return;
  }


  async ValueCheck(that,waarde,type,colId) {

    let data = this.state.data;
    let terug = false;
    switch (colId) 
    {
      case 'country_txt':
        data.country_txt = waarde.value;
        data.country = waarde.id;
        terug = Validation.ValidCheck('AllVatRefCode', waarde.value, colId, that, this.props.jobs.AllVatRefCodeData.data, data);
        break;


      case 'code':
        data.code = waarde;
        terug = Validation.ValidCheck('AllVatRefCode', waarde, colId, that, this.props.jobs.AllVatRefCodeData.data, data);
        break;

      default:    
        data[colId] = waarde;
        break;
    } 
    that.setState({error:terug})
    terug = await this.CheckSave(data);

    this.setState({save:terug,data:data,checkval:false})
  }       

  render() {
    const { classes } = this.props;
    var {tableheight} = this.state;
    return (
      <div >
        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
          <Grid container>
            <Grid item xs={8}>          
              <h4 style={{cursor:'pointer'}} tag="h4" className="mt-3" onClick={() => {this.setState({edit:false})}}>{this.props.settings.sub_title}</h4>  
            </Grid>
            <Grid item xs={4}>  
            </Grid>
          </Grid>
          {this.state.pinnedSubJobData === null &&         
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              (action && action.name  && action.name.length > 2 &&
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipPlacement='bottom'
                  FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit')) ? false :
                                       (action.name === 'Edit' && this.state.edit === true  ) ? true :
                                       (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit')) ? true :
                                       (action.name === 'New' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit')) ? false :
                                       (action.name === 'New' && this.state.edit === true ) ? true :
                                       (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit')) ? true :
                                       (action.name === 'Save' && this.state.edit === true && this.state.save === true) ? false :
                                       (action.name === 'Save' && this.state.edit === true  && this.state.save === false) ? true :
                                       (action.name === 'Save' && this.state.edit === false ) ? true : 
                                       (action.name === 'Save' && this.state.save === false) ? true : false}}
                  onClick={() => {this.ActionClick(action.name)}}                             
                  tooltipTitle={action.tooltip}
                />
              )
            ))}
          </SpeedDial>  
          }        


        </Toolbar>
        {this.props.jobs && this.props.jobs.VatRefCodeData && this.props.jobs.VatRefCodeData.data &&
          <div>
            <Grid container style={{width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px'}}>
              <Grid container spacing={1}>
                <Grid item xs={this.state.edit === false ? 3 : 5}>
                  <Card className='card_brut'>
                    {this.state.edit === false &&
                      <PerfectScrollbar className="sidebar-nav">
                        <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                          <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} className="card-title m-t-10">{this.props.jobs.VatRefCodeData.data.code} </h4>
                          <h5 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.jobs.VatRefCodeData.data.country_txt}</h5> 
                          <div className='div_line' style={{marginBottom: '25px'}}></div>
                        </CardBody>
                      </PerfectScrollbar>
                    }
                    {this.state.edit === true &&
                      <PerfectScrollbar styleclassName="sidebar-nav">
                        <CardBody style={{textAlign:'left',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                            <Grid container spacing={1}>
                              <Grid item sm={12} md={12} lg={12}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.code}
                                  coltype = 'STR'                        
                                  labelText="Code"
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'code')}
                                  setError={(params,value,type) => this.ErrorCheck(params,value,type,'code')}
                                />
                              </Grid>

                              <Grid item sm={12} md={12} lg={12}>
                                <EditAutoSelect className="card-title m-t-10"
                                  value={this.state.data.country_txt}
                                  labelText="Land"
                                  lijst='AllLanden'
                                  colId='country_txt'
                                  selectcel='country'
                                  id={this.state.data.country}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'country_txt')}
                                  setError={(params,value,type) => this.ErrorCheck(params,value,type,'country_txt')}
                                />
                              </Grid>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                              <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                            </Grid>
                        </CardBody>
                      </PerfectScrollbar>
                    }
                  </Card>
                </Grid>
                <Grid item xs={this.state.edit === false ? 9 : 7}>
                  <Card className='card_brut'>
                    <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                      <div className='div_line'>
                        <Nav tabs >
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.tabvalue === '1' })}
                              onClick={(event) => { this.handleTabChange(event,'1'); }}
                            >
                              Subcodes
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.tabvalue}>
                          <TabPane tabId="1">

                            { this.state.tabvalue === '1' && this.props.jobs.VatRefCodeData && this.props.jobs.SubVatRefCodeData.data && this.props.jobs.SubVatRefCodeData.kol &&
                              this.props.jobs && this.props.jobs.VatRefCodeData && this.props.jobs.VatRefCodeData.data && this.props.jobs.VatRefCodeData.data.id !== 0 &&
                              <TableGrid
                                tableheight={tableheight} 
                                columns={this.props.jobs.SubVatRefCodeData.kol}
                                data={this.props.jobs.SubVatRefCodeData.data} 
                                CellValueChanged={(params,waarde,object) => this.SubVatRefCodeChanged(params,waarde,object)}
                                CellValueCheck={(params,value,error) => this.SubVatRefCodeCheck(params,value,error)}
                                CellClickedEvent={(column, colDef, value) => this.SubVatRefCodeCellClick(column,colDef, value)}
                                SaveAll={(params) => this.SubJobSaveAll(params)}
                                sortable={true}
                                sizetofit={false}
                                suppressMenu={true}
                                enableRowGroup={true}
                                headerComponentParams = {null}
                                suppressColumnVirtualisation={true}
                                filter={false}
                                editable={true}
                                pinnedTopRowData={this.state.pinnedSubVatRefCode}
                                GridReady={this.onGridReady}
                                sleutel='SubVatRefCode'                     
                              />                                    
                            }
                          </TabPane>
                        </TabContent>
                      </div>
                    </CardBody>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </div>
        }
        {this.state.PopUpScreen}

      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Vatrefcodepage));
