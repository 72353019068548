import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';


class PopKlantGegevens extends React.Component {
    state = {
        data: this.props.data,
        filename:'',
        save:false,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        accijnzenChecked: 0,
        vatrefundChecked: 0,
        maxVatrefundChecks: 3,
        descriptionsChecked: [],
        dirregChecked: 0,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.getAccijnzen = this.getAccijnzen.bind(this);
        this.getVatrefund = this.getVatrefund.bind(this);
        this.getDirreg = this.getDirreg.bind(this);
        this.updateCheckboxes = this.updateCheckboxes.bind(this);
    }
    componentDidMount(){
        this.setState({data:this.props.data})
        //#accijnzen checked
        let vatrefundChecked = this.state.vatrefundChecked;
        let accijnzenChecked = this.state.accijnzenChecked;
        let descriptionsChecked = this.state.descriptionsChecked;
        let dirregChecked =  this.state.dirregChecked;

        for(let i = 0; i < this.state.data.AccijnzenData.data.length; i++){
          if(this.state.data.AccijnzenData.data[i].received){
            accijnzenChecked++;
          }
        }
        //#vatrefund checked
        if(this.state.data.VatrefundData.data[0].alg_volm_received) { vatrefundChecked++ }
        if(this.state.data.VatrefundData.data[0].volm_land_received) {vatrefundChecked++ }
        if(this.state.data.VatrefundData.data[0].ivat_rep_received) { vatrefundChecked++ }
        //#descriptionsChecked for each country
        for(let i = 0; i < this.state.data.DirregData.data.length; i++){
          let code = this.state.data.DirregData.data[i].code;
          let count = 0;
          for(let j = 0; j < this.state.data.DirregData.data[i].descriptions.length; j++){
            if(this.state.data.DirregData.data[i].descriptions[j].received) { count++ }
          }
          let element = [code, count]
          descriptionsChecked.push(element);
          if(this.state.data.DirregData.data[i].received){
            dirregChecked++;
          }
        }
        this.setState({vatrefundChecked:vatrefundChecked, accijnzenChecked:accijnzenChecked, descriptionsChecked:descriptionsChecked, dirregChecked:dirregChecked})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens
      //await SqlFunction.DoSql('',this.state.data);
      if (this.props.close) 
      {
        this.props.close()
      }
    }


    ValueCheck(that,waarde,type,colId) {
      let data = this.state.data;
      data[colId] = waarde;
     
    }       


    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }

    async updateCheckboxes(menu, submenu, index, desc_index, checked){
      let data;
      let updData;

      switch(menu){
        case "accijnzen":
            //Update database
            data = this.state.data.AccijnzenData.data[index];
            data.received = checked;
            await SqlFunction.DoSql("ChgAccijnzen", data);

            //Update state
            updData = this.state.data;
            updData.AccijnzenData.data[index].received = checked;
            this.setState({ data: updData });
          break;

        case "accChangesAccijnzen":
            data = this.state.data.AccChangesData.data;
            data.accijnzen_received = checked;
            await SqlFunction.DoSql("ChgAccChanges", data);

            updData = this.state.data;
            updData.AccChangesData.accijnzen_received = checked;
            this.setState({ data: updData });
          break;

        case "vatrefund":
          data = this.state.data.VatrefundData.data[index];
          if(submenu === "algemeen"){ data.alg_volm_received = checked; }
          if(submenu === "volmland"){ data.volm_land_received = checked; }
          if(submenu === "ivatrep"){ data.ivat_rep_received = checked; }
          await SqlFunction.DoSql("ChgVatrefund", data);

          updData = this.state.data;
          if(submenu === "algemeen"){ updData.VatrefundData.data[index].alg_volm_received = checked; }
          if(submenu === "volmland"){ updData.VatrefundData.data[index].volm_land_received = checked; }
          if(submenu === "ivatrep"){ updData.VatrefundData.data[index].ivat_rep_received = checked; }
          this.setState({ data: updData });
          break;

        case "accChangesVatrefund":
          data = this.state.data.AccChangesData.data;
          data.vatrefund_received = checked;
          await SqlFunction.DoSql("ChgAccChanges", data);

          updData = this.state.data;
          updData.AccChangesData.vatrefund_received = checked;
          this.setState({ data: updData });
          break;

        case "dirreg":
          if(submenu === "descriptions"){
            data = this.state.data.DirregData.data[index].descriptions[desc_index];
            data.received = checked;
            await SqlFunction.DoSql("ChgDirregVolmachten", data);
  
            updData = this.state.data;
            updData.DirregData.data[index].descriptions[desc_index].received = checked;
            this.setState({ data: updData });
          }
          if(submenu === "countries"){
            data = this.state.data.DirregData.data[index];
            data.received = checked;
            await SqlFunction.DoSql("ChgDirreg", data);
  
            updData = this.state.data;
            updData.DirregData.data[index].received = checked;
            this.setState({ data: updData });
          }
          
          break;

        case "accChangesDirreg":
          data = this.state.data.AccChangesData.data;
          data.dirreg_received = checked;
          await SqlFunction.DoSql("ChgAccChanges", data);

          updData = this.state.data;
          updData.AccChangesData.dirreg_received = checked;
          this.setState({ data: updData });
          break;

        default:
          return;
      }

    }

    async handleChecked(event){
      let name = event.target.name;
      let split = name.split("_");

      let menu = split[0];
      let submenu = split[1];
      let code = split[2];
      let description = split[3]

      let dirregChecked = this.state.dirregChecked;
      let descriptionsChecked = this.state.descriptionsChecked
      let vatrefundChecked = this.state.vatrefundChecked;
      let accijnzenChecked = this.state.accijnzenChecked;

      switch(menu){
        //Checkbox IN accijnzenmenu clicked
        case "accijnzen":
          for(let i = 0; i < this.state.data.AccijnzenData.data.length; i++){
            if(submenu === this.state.data.AccijnzenData.data[i].country_txt){
              let checked = this.state.data.AccijnzenData.data[i].received;

              if(!checked){
                accijnzenChecked++;
              } 
              else {
                accijnzenChecked--;
              }
              this.updateCheckboxes("accijnzen", null, i, null, !checked);
            }
          }

          if(accijnzenChecked === this.state.data.AccijnzenData.data.length){
            this.updateCheckboxes("accChangesAccijnzen", null, null, null, true);
          }
          if(accijnzenChecked !== this.state.data.AccijnzenData.data.length && this.state.data.AccChangesData.data.accijnzen_received){
            this.updateCheckboxes("accChangesAccijnzen", null, null, null, false);
          }
          this.setState({accijnzenChecked:accijnzenChecked})
          break;

        case "vatrefund":
          if(event.target.checked){
            this.updateCheckboxes("vatrefund", submenu, 0, null, true);
            vatrefundChecked++;
          } 
          else {
            this.updateCheckboxes("vatrefund", submenu, 0, null, false);
            vatrefundChecked--;
          }

          if(vatrefundChecked === this.state.maxVatrefundChecks){
            this.updateCheckboxes("accChangesVatrefund", null, null, null, true);
          }
          if(vatrefundChecked !== this.state.maxVatrefundChecks && this.state.data.AccChangesData.data.vatrefund_received){
            this.updateCheckboxes("accChangesVatrefund", null, null, null, false);
          }
          this.setState({vatrefundChecked:vatrefundChecked});
          
          break;

        case "dirreg":
          if(event.target.checked){
            if(submenu === "descriptions"){
              for(let i = 0; i < this.state.data.DirregData.data.length; i++){
                if(code === this.state.data.DirregData.data[i].code){
                  for(let j = 0; j < this.state.data.DirregData.data[i].descriptions.length; j++){
                    if(description === this.state.data.DirregData.data[i].descriptions[j].description){
                      this.updateCheckboxes("dirreg", submenu, i, j, true);
                      let count = descriptionsChecked[i][1];
                      count++;
                      descriptionsChecked[i][1] = count;
                      if(descriptionsChecked[i][1] === this.state.data.DirregData.data[i].descriptions.length){
                        this.updateCheckboxes("dirreg", "countries", i, null, true);
                        dirregChecked++;
                        this.setState({dirregChecked:dirregChecked, descriptionsChecked:descriptionsChecked})

                        if(dirregChecked === this.state.data.DirregData.data.length){
                          this.updateCheckboxes("accChangesDirreg", null, null, null, true);
                        }
                      }
                    }
                  }
                }
              }
            }
            if(submenu === "countries"){
              for(let i = 0; i < this.state.data.DirregData.data.length; i++){
                if(code === this.state.data.DirregData.data[i].code){
                  for(let j = 0; j < this.state.data.DirregData.data[i].descriptions.length; j++){
                    if(!this.state.data.DirregData.data[i].descriptions[j].received){
                      this.updateCheckboxes("dirreg", "descriptions", i, j, true);
                    }
                  }
                  let count = descriptionsChecked[i][1];
                  count = this.state.data.DirregData.data[i].descriptions.length;
                  descriptionsChecked[i][1] = count;

                  this.updateCheckboxes("dirreg", "countries", i, null, true);
                  dirregChecked++;
                  this.setState({dirregChecked:dirregChecked, descriptionsChecked:descriptionsChecked})

                  if(dirregChecked === this.state.data.DirregData.data.length){
                    this.updateCheckboxes("accChangesDirreg", null, null, null, true);
                  }
                }
              }
            }
          }
          else {
            if(submenu === "descriptions"){
              for(let i = 0; i < this.state.data.DirregData.data.length; i++){
                if(code === this.state.data.DirregData.data[i].code){
                  for(let j = 0; j < this.state.data.DirregData.data[i].descriptions.length; j++){
                    if(description === this.state.data.DirregData.data[i].descriptions[j].description){
                      this.updateCheckboxes("dirreg", submenu, i, j, false);
                      let count = descriptionsChecked[i][1];
                      count--;
                      descriptionsChecked[i][1] = count;

                      if(this.state.descriptionsChecked[i][1] !== this.state.data.DirregData.data[i].descriptions.length && this.state.data.DirregData.data[i].received){
                        this.updateCheckboxes("dirreg", "countries", i, null, false);
                        dirregChecked--;
                        this.setState({dirregChecked:dirregChecked, descriptionsChecked:descriptionsChecked})

                        if(dirregChecked !== this.state.data.DirregData.data.length && this.state.data.AccChangesData.data.dirreg_received){
                          this.updateCheckboxes("accChangesDirreg", null, null, null, false);
                        }
                      }
                    }
                  }
                }
              }
            }
            if(submenu === "countries"){
              for(let i = 0; i < this.state.data.DirregData.data.length; i++){
                if(code === this.state.data.DirregData.data[i].code){
                  for(let j = 0; j < this.state.data.DirregData.data[i].descriptions.length; j++){
                    if(this.state.data.DirregData.data[i].descriptions[j].received){
                      this.updateCheckboxes("dirreg", "descriptions", i, j, false);
                    }
                  }
                  descriptionsChecked[i][1] = 0;
                  this.updateCheckboxes("dirreg", "countries", i, null, false);
                  dirregChecked--;
                  this.setState({dirregChecked:dirregChecked, descriptionsChecked:descriptionsChecked})

                  if(dirregChecked !== this.state.data.DirregData.data.length && this.state.data.AccChangesData.data.dirreg_received){
                    this.updateCheckboxes("accChangesDirreg", null, null, null, false);
                  }
                }
              }
            }
          }

          break;

        //Hoofdvinkje van een menu wordt aangevinkt
        case "main":
          if(submenu === "accijnzen"){
            if(event.target.checked){
              for(let i = 0; i < this.state.data.AccijnzenData.data.length; i++){
                if(!this.state.data.AccijnzenData.data[i].received){
                  this.updateCheckboxes("accijnzen", null, i, null, true);
                }
              }

              this.updateCheckboxes("accChangesAccijnzen", null, null, null, true);

            } else {
              for(let i = 0; i < this.state.data.AccijnzenData.data.length; i++){
                if(this.state.data.AccijnzenData.data[i].received){
                  this.updateCheckboxes("accijnzen", null, i, null, false);
                }
              }

              this.updateCheckboxes("accChangesAccijnzen", null, null, null, false);

            }
          }

          if(submenu === "vatrefund"){
            if(event.target.checked){
              if(!this.state.data.VatrefundData.data[0].alg_volm_received) { this.updateCheckboxes("vatrefund", "algemeen", 0, null, true) }
              if(!this.state.data.VatrefundData.data[0].volm_land_received) { this.updateCheckboxes("vatrefund", "volmland", 0, null, true) }
              if(!this.state.data.VatrefundData.data[0].ivat_rep_received) { this.updateCheckboxes("vatrefund", "ivatrep", 0, null, true) }
              this.updateCheckboxes("accChangesVatrefund", null, null, null, true);
            } else {
              if(this.state.data.VatrefundData.data[0].alg_volm_received) { this.updateCheckboxes("vatrefund", "algemeen", 0, null, false) }
              if(this.state.data.VatrefundData.data[0].volm_land_received) { this.updateCheckboxes("vatrefund", "volmland", 0, null, false) }
              if(this.state.data.VatrefundData.data[0].ivat_rep_received) { this.updateCheckboxes("vatrefund", "ivatrep", 0, null, false) }
              this.updateCheckboxes("accChangesVatrefund", null, null, null, false);
            }
          }

          if(submenu === "dirreg"){
            if(event.target.checked){
              for (let i = 0; i < this.state.data.DirregData.data.length; i++) {
                for (let j = 0; j < this.state.data.DirregData.data[i].descriptions.length; j++) {
                  if (!this.state.data.DirregData.data[i].descriptions[j].received) {
                    this.updateCheckboxes("dirreg", "descriptions", i, j, true);
                  }
                }
                let count = descriptionsChecked[i][1];
                count = this.state.data.DirregData.data[i].descriptions.length;
                descriptionsChecked[i][1] = count;

                if(!this.state.data.DirregData.data[i].received){
                  this.updateCheckboxes("dirreg", "countries", i, null, true);
                }
                dirregChecked = this.state.data.DirregData.data.length;
                this.setState({dirregChecked:dirregChecked, descriptionsChecked:descriptionsChecked})
                this.updateCheckboxes("accChangesDirreg", null, null, null, true);
              }
            } else {
              for (let i = 0; i < this.state.data.DirregData.data.length; i++) {
                for (let j = 0; j < this.state.data.DirregData.data[i].descriptions.length; j++) {
                  if (this.state.data.DirregData.data[i].descriptions[j].received) {
                    this.updateCheckboxes("dirreg", "descriptions", i, j, false);
                  }
                }
                descriptionsChecked[i][1] = 0;
                if(this.state.data.DirregData.data[i].received){
                  this.updateCheckboxes("dirreg", "countries", i, null, false);
                }
                dirregChecked = 0;
                this.setState({dirregChecked:dirregChecked, descriptionsChecked:descriptionsChecked})
                this.updateCheckboxes("accChangesDirreg", null, null, null, false);
              }
            }
          }
          
          break;

        default:
          return;
      }

    }

    getAccijnzen(){
      let accijnzen = [];
      let landen = [];

      for(let i = 0; i < this.state.data.AccijnzenData.data.length; i++){
        let volmachten = [];

        for(let j = 0; j < this.state.data.AccijnzenData.data[i].descriptions.length; j++){
          volmachten.push(
            <li>{this.state.data.AccijnzenData.data[i].descriptions[j].description}</li>
          );
        }

        landen.push(
          <div style={{ width: "100%" }}>
            <div>
              <FormControlLabel
                aria-label="acznland"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                label={this.state.data.AccijnzenData.data[i].country_txt}
                control={<Checkbox 
                  id={this.state.data.AccijnzenData.data[i].country_txt}
                  name={"accijnzen_" + this.state.data.AccijnzenData.data[i].country_txt}
                  onClick={this.handleChecked}
                  checked={this.state.data.AccijnzenData.data[i].received}
                />}
              />
            </div>
            <div style={{ marginLeft: "50px", marginTop: "-15px" }}>
              {volmachten}
            </div>
          </div>
        );
      }

      accijnzen.push(
        <div style={{ marginLeft: "50px", marginTop: "-20px", width: "100%" }}>
          {landen}
        </div>
      );

      return accijnzen;
    }

    getVatrefund(){
      let vatrefund = [];
      let landen = [];

      for(let i = 0; i < this.state.data.VatrefundData.data[0].countries.length; i++){
        landen.push(
        <li>{this.state.data.VatrefundData.data[0].countries[i].code}</li>
        );
      }

      vatrefund.push(
        <div style={{ marginLeft: "50px", marginTop: "-20px" }}>
          <div>
            <FormControlLabel
              aria-label="algvolm"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              label="Algemene volmacht"
              control={<Checkbox
                id={"vatrefund_algemeen"}
                name={"vatrefund_algemeen"}
                onClick={this.handleChecked}
                checked={this.state.data.VatrefundData.data[0].alg_volm_received}
              />}
            />
          </div>
          <div style={{ marginTop: "-10px" }}>
            <FormControlLabel
              aria-label="volmland"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              label="Volmacht per land van recuperatie"
              control={<Checkbox 
                id={"vatrefund_volmland"}
                name={"vatrefund_volmland"}
                onClick={this.handleChecked}
                checked={this.state.data.VatrefundData.data[0].volm_land_received}
              />}
            />
            <div style={{ marginLeft: "50px", marginTop: "-15px" }}>
              {landen}
            </div>
          </div>
          <div style={{ marginTop: "-10px" }}>
            <FormControlLabel
              aria-label="ivatrep"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              label="Ivat reporting"
              control={<Checkbox 
                id={"vatrefund_ivatrep"}
                name={"vatrefund_ivatrep"}
                onClick={this.handleChecked}
                checked={this.state.data.VatrefundData.data[0].ivat_rep_received}
              />}
            />
          </div>
        </div>
      );

      return vatrefund;
    }

    getDirreg(){
      let dirreg = [];
      let landen = [];

      for(let i = 0; i < this.state.data.DirregData.data.length; i++){
        let descriptions = [];

        for(let j = 0; j < this.state.data.DirregData.data[i].descriptions.length; j++){
            descriptions.push(
              <div style={{ marginTop: "-25px" }}>
                <FormControlLabel
                  aria-label="dirregland"
                  onClick={(event) => event.stopPropagation()}
                  onFocus={(event) => event.stopPropagation()}
                  label={this.state.data.DirregData.data[i].descriptions[j].description}
                  control={<Checkbox 
                    id={"dirreg"}
                    name={"dirreg_descriptions_" + this.state.data.DirregData.data[i].code + "_" + this.state.data.DirregData.data[i].descriptions[j].description}
                    onClick={this.handleChecked}
                    checked={this.state.data.DirregData.data[i].descriptions[j].received}
                  />}
                />
              </div>
            );
        }

        landen.push(
          <div>
            <div style={{ marginTop: "-10px" }}>
              <FormControlLabel
                aria-label="dirreglanden"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                label={this.state.data.DirregData.data[i].code}
                control={<Checkbox 
                  id={"dirreg"}
                  name={"dirreg_countries_" + this.state.data.DirregData.data[i].code}
                  onClick={this.handleChecked}
                  checked={this.state.data.DirregData.data[i].received}
                />}
              />
            </div>
            <div style={{ marginLeft: "30px", marginTop: "-20px" }}>
              {descriptions}
            </div>
          </div>
        );
      }

      dirreg.push(
        <div style={{ marginLeft: "50px", marginTop: "-10px" }}>
          {landen}
        </div>
      );

      return dirreg;
    }

    render() {
        const {fullScreen,classes, open} = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal80
                }}

                fullScreen={fullScreen}
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible",zIndex:'1001',width:'100%'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                maxWidth='md'
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title + " " + this.state.data.MainData.data.name}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px'}}  className={classes.blockContent}>
                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="boekhouding"
                      id="boekhouding"
                    >
                      <FormControlLabel
                        aria-label="Boekhouding"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox />}
                        label="Boekhouding"
                      />
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>
                        Alles van de boekhouding
                      </Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="accijnzen-content"
                      id="accijnzen-header"
                      style={{ verticalAlign: "center" }}
                    >
                      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "80%" }}>
                          <div style={{ width: "70%" }}>
                            <FormControlLabel
                              aria-label="Accijnzen"
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                              control={<Checkbox 
                                id={"main_accijnzen"}
                                name={"main_accijnzen"}
                                onClick={this.handleChecked}
                                checked={this.state.data.AccChangesData.data.accijnzen_received}
                              />}
                              label="Accijnzen"
                            />
                          </div>
                          <div style={{ marginRight: "30px", marginLeft: "30px", marginTop: "9px", width: "300px" }}>
                            <Typography>
                              opgevraagd op: {(this.state.data.AccChangesData.data.accijnzen_reqdate).split("-").reverse().join("-")}
                                </Typography>
                          </div>
                        </div>
                        <div style={{ width: "20%" }}>
                          <FormControlLabel
                            aria-label="Accijnzen"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            label="Ontvangen"
                            labelPlacement="start"
                            control={<Checkbox />}
                          />
                        </div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {this.getAccijnzen()}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="vat_refund-content"
                      id="vat_refund-header"
                      style={{ verticalAlign: "center" }}
                    >
                      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "80%" }}>
                          <div style={{ width: "70%" }}>
                            <FormControlLabel
                              aria-label="VAT_refund"
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                              control={<Checkbox 
                                id={"main_vatrefund"}
                                name={"main_vatrefund"}
                                onClick={this.handleChecked}
                                checked={this.state.data.AccChangesData.data.vatrefund_received}
                              />}
                              label="VAT refund"
                            />
                          </div>
                          <div  style={{ marginRight: "30px", marginLeft: "30px", marginTop: "9px", width: "300px" }}>
                            <Typography>
                              opgevraagd op: {(this.state.data.AccChangesData.data.vatrefund_reqdate).split("-").reverse().join("-")}
                            </Typography>
                          </div>
                        </div>
                        <div style={{ width: "20%" }}>
                          <FormControlLabel
                            aria-label="VAT_refund"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            label="Ontvangen"
                            labelPlacement="start"
                            control={<Checkbox />}
                          />
                        </div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {this.getVatrefund()}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>

                  <ExpansionPanel>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-label="Expand"
                      aria-controls="dirreg"
                      id="dirreg"
                    >
                      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "80%" }}>
                          <div style={{ width: "70%" }}>
                            <FormControlLabel
                              aria-label="dirreg"
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                              control={<Checkbox 
                                id={"main_dirreg"}
                                name={"main_dirreg"}
                                onClick={this.handleChecked}
                                checked={this.state.data.AccChangesData.data.dirreg_received}
                              />}
                              label="Directe registratie - Personenvervoerders"
                            />
                          </div>
                          <div style={{ marginRight: "30px", marginLeft: "30px", marginTop: "9px", width: "300px" }}>
                            <Typography>
                              opgevraagd op: {(this.state.data.AccChangesData.data.dirreg_reqdate).split("-").reverse().join("-")}
                                  </Typography>
                          </div>
                        </div>
                        <div style={{ width: "20%" }}>
                          <FormControlLabel
                            aria-label="dirreg"
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            label="Ontvangen"
                            labelPlacement="start"
                            control={<Checkbox />}
                          />
                        </div>
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      {this.getDirreg()}
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  {this.state.save &&
                    <Button color="primary"  onClick={() => this.handleSave()}>
                      Opslaan
                    </Button>
                  }
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopKlantGegevens.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopKlantGegevens)));

