import React from 'react';
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'

import TableGrid from "../../components/TableGrid/TableGrid.jsx";

import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';

import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import EditSelect from "../../components/editselect.jsx";

import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";

var gridapi = null;
var rowpinnend = null;
class PopAccTarieven extends React.Component {
    state = {
        data: this.props.data.data,
        staffel: this.props.data.staffel,
        filename:'',
        save:true,
        tabvalue:'1',
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);

        this.CellClick = this.CellClick.bind(this);
        this.CellCheck = this.CellCheck.bind(this);


        this.onGridReady = this.onGridReady.bind(this);
        this.handleSave = this.handleSave.bind(this);

        this.handleTabChange = this.handleTabChange.bind(this);


    }
    async handleTabChange(event, newValue) {
    }
    onGridReady(params)
    {
      gridapi = params;
    }

    componentDidMount(){
        this.setState({data:this.props.data.data,staffel: this.props.data.staffel})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens
      let terdata = await SqlFunction.DoSql('ChgAccTarief',this.state.data);
      let newdata = await SqlFunction.DoSql('GetAccTarief',terdata);
      this.setState({data:newdata.data, staffel:newdata.staffel});
    }

    async ErrorCheck(that, waarde, type, colId) {
      let terug = false;
      if (colId === 'datum')
      {
        var utc = moment(waarde, "DD-MM-YYYY", true)
        if(!utc.isValid()) {terug = true}
      }
      if (colId === 'type_txt')
      {
        if (waarde)
        {
          if (waarde.length < 1)
          {
            terug = true;
          }
        }
        else
        {
            terug = true;
        }
      }
      if (colId === 'job_txt')
      {
        if (waarde)
        {
          if (waarde.length < 1)
          {
            terug = true;
          }
        }
        else
        {
            terug = true;
        }
      }

      that.setState({ error: terug })
    } 


    ValueCheck(that,waarde,type,colId) {
      let data = this.state.data;
      let save = this.state.save;
      let error = false;

      data[colId] = waarde;
      if (colId === 'datum')
      {
        var utc = moment(waarde, "DD-MM-YYYY", true)
        if(!utc.isValid()) {error = true}
      }
      if (colId === 'country_txt')
      {
        data.country_txt = waarde.value;
        data.country = waarde.id;
      }
      if (colId === 'type_txt')
      {
        data.type_txt = waarde.text;
        data.type = waarde.id;
        if (waarde.id)
        {
          if (waarde.id.length < 1)
          {
            error = true;
          }

        }
        else
        {
            error = true;
        }
      }
      if (colId === 'job_txt')
      {
        data.job_txt = waarde.value;
        data.jobid = waarde.id;
        if (waarde.id)
        {
          if (waarde.id.length < 1)
          {
            error = true;
          }

        }
        else
        {
            error = true;
        }
      }
      save = error;
      that.setState({error:error})
      this.setState({save:save, data:data})
      return;
    }       

    async CellClick(waarde) {
        if (this.state.save === true) {return}
        var data = waarde.data
        let column = waarde.column.colDef.field;
        let retdata = null;
 
        if (column === 'save')
        {
          if (data.save === true) 
          {
            if (data.id === 0) {rowpinnend = null}
            data.tariefid = this.state.data.id;
            data.verwijder = '2';
            retdata = await SqlFunction.DoSql('ChgStaffelAccTarief',data);
            waarde.data.save='';
            if (data.id === 0) 
            {
              waarde.data.id = retdata.id;
              gridapi.api.stopEditing();
              gridapi.api.updateRowData({
                add: [waarde.data],
                addIndex: 0
              });
              waarde.node.setData({id:0,tariefid:0,mintarief:'',maxtarief:'',type:'',tarief:'',typetxt:'',save:'',verwijder:''});
            }
            else {
              waarde.node.setData(waarde.data);
            }
            if (rowpinnend && rowpinnend.save === true)
            {
              rowpinnend.tariefid = this.state.data.id;
              rowpinnend.verwijder = '2';
              retdata = await SqlFunction.DoSql('ChgStaffelAccTarief',rowpinnend);
              rowpinnend = null;
            }
            let newdata = await SqlFunction.DoSql('GetAccTarief',this.state.data);
            rowpinnend = null;
            this.setState({data:newdata.data, staffel:newdata.staffel});      

          }
        }


        if (column === 'verwijder')
        {
            gridapi.api.updateRowData({ remove: [waarde.data]})
            let subdata = waarde.data
            subdata.job_id = this.props.jobid;
            await SqlFunction.DoSql('DelStaffelAccTarief',subdata);
           
            if (rowpinnend && rowpinnend.save === true)
            {
                rowpinnend = null;
            }
            rowpinnend = null;
            let newdata = await SqlFunction.DoSql('GetAccTarief',this.state.data);
            this.setState({data:newdata.data, staffel:newdata.staffel});      

        }
        return;
    }

    async CellCheck(params,waarde,datacol) {
        var colId = params.props.column.getId();
        let terug = false
        let tarief = 0
        let mintarief = 0
        let maxtarief = 0
        if (colId === 'tarief')
        {
          tarief = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(tarief)) {tarief = 0}
          params.props.data.tarief = tarief          
        }
        if (colId === 'mintarief')
        {
          mintarief = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(mintarief)) {mintarief = 0}
          params.props.data.mintarief = mintarief          
        }
        if (colId === 'maxtarief')
        {
          maxtarief = (waarde && waarde.constructor === String) ? parseFloat(waarde) : waarde;
          if (isNaN(maxtarief)) {maxtarief = 0}
          params.props.data.maxtarief = maxtarief          

        }
        tarief = (params.props.data && params.props.data.tarief && params.props.data.tarief.constructor === String) ? parseFloat(params.props.data.tarief) : params.props.data.tarief;
        mintarief = (params.props.data && params.props.data.mintarief && params.props.data.mintarief.constructor === String) ? parseFloat(params.props.data.mintarief) : params.props.data.mintarief;
        maxtarief = (params.props.data && params.props.data.maxtarief && params.props.data.maxtarief.constructor === String) ? parseFloat(params.props.data.maxtarief) : params.props.data.maxtarief;

        if (params.props.data && params.props.data.type && params.props.data.type.length > 1 && params.props.data.tarief > 0  )
        {
            if (mintarief > 0 || maxtarief > 0)
            {
              params.props.data.save = true;
            }
            else
            {
             params.props.data.save = ''; 
            }
        }
        else
        {
            params.props.data.save = ''; 
        }        

        await params.setState({error:terug})
    }       





    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var top = '50px';
        var left = '0px';
        var bottom = '10px';

        return (
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                  <Grid container spacing={1}>
                    <Grid item sm={6} md={6} lg={6}>
                      <EditDateTime className="card-title m-t-10"
                        value={this.state.data.datum}
                        coltype = 'STR'                        
                        labelText="Begin datum tarief"
                        inputProps={{value:this.state.data.datum}}
                        formControlProps={{fullWidth: true}}
                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'datum')}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'datum')}
                      />
                      <EditAutoSelect className="card-title m-t-10"
                          value={this.state.data.job_txt}
                          labelText='Soort Job'
                          lijst='AllJobDefMain'
                          colId='job_txt'
                          selectcel='jobid'
                          id={this.state.data.jobid}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'job_txt')}
                          setError={(params,value,type) => this.ErrorCheck(params,value,type,'job_txt')}
                      />

                      <EditAutoSelect className="card-title m-t-10"
                          value={this.state.data.country_txt}
                          labelText='Land'
                          lijst='AllLanden'
                          colId='country_txt'
                          selectcel='country'
                          id={this.state.data.country}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'country_txt')}
                          setError={(params,value,type) => this.ErrorCheck(params,value,type,'country_txt')}
                      />
                      <EditSelect className="card-title m-t-10"
                        value={this.state.data.type_txt}
                        labelText='Soort'
                        data={this.state.data}
                        type='AllTarType'
                        colId='type_txt'
                        selectcel='type'
                        id={this.state.data.type}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'type_txt')}
                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'type_txt')}
                      />

                    </Grid>
                    <Grid item sm={6} md={6} lg={6}>
                      <EditString className="card-title m-t-10"
                        value={this.state.data.tarief}
                        coltype = 'NUM'                        
                        labelText="Tarief"
                        decimal={4}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'tarief')}
                      />
                      <EditString className="card-title m-t-10"
                        value={this.state.data.nihil}
                        coltype = 'NUM'                        
                        labelText="Nihil Tarief"
                        decimal={4}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'nihil')}
                      />
                      <EditString className="card-title m-t-10"
                        value={this.state.data.mintar}
                        coltype = 'NUM'                        
                        labelText="Minimum Tarief"
                        decimal={4}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'mintar')}
                      />

                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12} lg={12}>
                       <Nav tabs >
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.tabvalue === '1' })}
                              onClick={(event) => { this.handleTabChange(event,'1'); }}
                            >
                              Staffel
                            </NavLink>
                          </NavItem>
                            <TabContent style={{width:'99%', marginTop:'10px'}} activeTab={this.state.tabvalue}>
                              <TabPane tabId="1" style={{width:'99%', marginTop:'10px'}}>
                                { this.state.tabvalue === '1' && this.state.staffel && this.state.staffel.kol && this.state.staffel.data && 
                                this.props.data.id !== 0 &&
                                  <TableGrid
                                    tableheight="300px" 
                                    columns={this.state.staffel.kol}
                                    data={this.state.staffel.data} 
                                    sizetofit={false}
                                    sortable={false}
                                    suppressMenu={true}
                                    enableRowGroup={false}
                                    suppressColumnVirtualisation={true}    
                                    CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                                    CellValueCheck={(params,value,error) => this.CellCheck(params,value,error)}
                                    filter={false}
                                    sleutel='JobStaffelTarief'
                                    editable={true}
                                    GridReady={this.onGridReady}
                                    pinnedTopRowData={[{id:0,tariefid:this.state.data.id,mintarief:'',maxtarief:'',type:'',tarief:'',typetxt:'',save:'',verwijder:''}] }
                                  />
                                }
                              </TabPane>                          
                            </TabContent>
                        </Nav>
                    </Grid>
                  </Grid>




                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                    <Button disabled={this.state.save} color="primary"  onClick={() => this.handleSave()}>
                      Opslaan
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopAccTarieven.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopAccTarieven)));

