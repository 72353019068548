import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import PopJobAanvrNum from "./PopJobAanvrNum.jsx";
import {clickview } from "../../redux/settings/action";




class PopJobDubbelFactuur extends React.Component {
    state = {
        data: this.props.data,
        kol: this.props.kol,
        filename:'',
        save:false,
        PopUpScreen:null,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        nivo: (this.props.nivo) ? this.props.nivo : 0,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.ClosePopUp = this.ClosePopUp.bind(this);
    }

    async ClosePopUp() 
    {
      this.setState({PopUpScreen:null})
    }

    async CellClick(waarde) {
      let hdata = waarde.data;
      hdata.id = hdata.job_id;
      let jobdata = await SqlFunction.DoSql('GetJob', hdata);
      let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
      if (jobdata)
      {
        this.setState({PopUpScreen:(<PopJobAanvrNum open={true} nivo={this.state.nivo + 1} data={jobdata} close={this.ClosePopUp} title={title}/>)});
        this.props.dispatch(clickview({ id: hdata.id, vorm: 'JOB' }));
      }
      return;
    }


    componentDidMount(){
        this.setState({data:this.props.data, kol:this.props.kol})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };


    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var tableheight = Math.floor(window.innerHeight * .80) + 'px';
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : top;
        return (

            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible",zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'absolute'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                  <Grid container style={{width: '90%', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                    <Grid item sm={12} md={12} lg={12}>
                      <TableGrid
                        tableheight={tableheight}
                        columns={this.state.kol}
                        data={this.state.data} 
                        sortable={false}
                        sizetofit={false}
                        suppressMenu={true}
                        enableRowGroup={false}
                        suppressColumnVirtualisation={true}
                        CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                        filter={false}
                        headerComponentParams = {null}
                        editable={true}
                        sleutel='AllDubbelFacturen'      
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                {this.state.PopUpScreen}
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopJobDubbelFactuur.propTypes = {
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobDubbelFactuur)));

