import React from "react";
import CalculatorDisplay from "./CalculatorDisplay";
import CalculatorButtonPanel from "./CalculatorButtonPanel";
import {calculate} from "./CalculatorLogic";
import PropTypes from "prop-types";
import "./Calculator.css";

export default class Calculator extends React.Component {
  state = {
    total: null,
    next: null,
    operation: null,
  };

  static propTypes = {
    clickHandler: PropTypes.func,
    resultHandler: PropTypes.func
  };

  handleKeyDown = (event) => {
    let { key } = event
    const calcOperations = ['+', '-', '*', '/', '%', '.', '='];
    if ((/\d/).test(key)) {
      event.preventDefault()
      this.handleClick(key)
    } else if (calcOperations.includes(key)) {
      event.preventDefault()
      this.handleClick(key)
    } else if (key === 'Backspace') {
      event.preventDefault()
      this.handleClick('back')
    } else if (key === 'Escape') {
      event.preventDefault()
      this.handleClick('AC')
    } else if (key === 'Enter') {
      this.handleClick('=')
      event.preventDefault()
      this.handleResult();
    } else if (key === ',') {
      event.preventDefault();
      this.handleClick('.')
    }
  };
  
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }


  handleClick = buttonName => {
    this.setState(calculate(this.state, buttonName));
  };

  handleResult = () => {
    if (this.state.next !== null) {
      this.props.resultHandler(this.state.next);
    } else {
      this.props.resultHandler(this.state.total);
    }
  }

  render() {
    return (
      <div className="calculator-app">
        <CalculatorDisplay value={this.state.next || this.state.total || "0"} />
        <CalculatorButtonPanel clickHandler={this.handleClick} resultHandler={this.handleResult}  />
      </div>
    );
  }
}