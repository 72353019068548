import React from 'react';
import GenFunc from "../../functions/gen_functions.js";
import {AllMunten, AllMainProductCodes, AllSubProductCodes, AllMainVatRefCodes, AllSubVatRefCodes} from "../../redux/settings/paramActions";
import {AllLanden, AllOnkosten} from "../../redux/settings/landActions";
import {AllJobSubj, AllJobs,AllVatRefCode, GetSubVatRefCode} from "../../redux/settings/jobsActions";
import {AllSuppliers} from "../../redux/settings/supplierActions";
import {AllAccounts} from "../../redux/settings/accountActions";
import {AllRegOms,AllRegistratie} from "../../redux/settings/registrationActions";

import classNames from "classnames";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/components/customInputStyle.jsx";
import { connect } from 'react-redux'

import IconButton from '@material-ui/core/IconButton';
import AddCircle from '@material-ui/icons/AddCircle';


var {data_id, data_code, resultaat} = '';
const mapStateToProps = state => ({
  ...state
});


class GridEditAutoSelect extends React.Component {
    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
        this.changekeyup = this.changekeyup.bind(this);
        this.loaddata = this.loaddata.bind(this);
        this.inploaddata = this.inploaddata.bind(this);

//        this.componentWillMount = this.componentWillMount.bind(this)
        this.inputid = GenFunc.MakeId()
        this.getValue = this.getValue.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.setNewData = this.setNewData.bind(this);
        this.getFilter = this.getFilter.bind(this);
        this.setFilter = this.setFilter.bind(this);

        this.onBlur = this.onBlur.bind(this);

        this.state = {
            value:'',
            toevoegen:false,
            preval:'',
            error:true,
            reason:'reset',
            autoSelect:true,
            data:[],
            origdata:[],
            datacol:null,
        }
        
    }
    async loaddata() {
      let data = [];
      switch (this.props.colDef.type) 
      {
        case 'AllMunten':
          data_id = 'id';
          data_code = 'code';
          if (!this.props.param.AllMuntenData) {
            await this.props.dispatch(AllMunten());
          }
          data = this.props.param.AllMuntenData.data
        break;

        case 'AllSubVatRefCodes':
          data_id = 'id';
          data_code = 'text';
          await this.props.dispatch(AllSubVatRefCodes());
          data = this.props.param.AllSubVatRefCodesData ? this.props.param.AllSubVatRefCodesData.data : [];
        break;

        case 'AllMainVatRefCodes':
          data_id = 'id';
          data_code = 'text';
          await this.props.dispatch(AllMainVatRefCodes());
          data = this.props.param.AllMainVatRefCodesData.data
        break;


        case 'AllSubProductCodes':
          data_id = 'id';
          data_code = 'text';
          if (!this.props.param.AllSubProductCodesData) {
            await this.props.dispatch(AllSubProductCodes());
          }
          data = this.props.param.AllSubProductCodesData.data
        break;

        case 'AllMainProductCodes':
          data_id = 'id';
          data_code = 'text';
          if (!this.props.param.AllMainProductCodesData) {
            await this.props.dispatch(AllMainProductCodes());
          }
          data = this.props.param.AllMainProductCodesData.data
        break;


        case 'AllSuppliers':
          data_id = 'id';
          data_code = 'name';
          if (!this.props.supplier.AllData) {
            await this.props.dispatch(AllSuppliers());
          }
          data = this.props.supplier.AllData.data
        break;
        
        case 'AllRegOms':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.registration.AllRegOmsData) {
            await this.props.dispatch(AllRegOms());
          }
          data = this.props.registration.AllRegOmsData.data
        break;

        case 'AllRegistratie':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.registration.AllRegData) {
            await this.props.dispatch(AllRegistratie());
          }
          data = this.props.registration.AllRegData && this.props.registration.AllRegData.data ? this.props.registration.AllRegData.data : []
          break;

        case 'AllRegistratieNum':
          data_id = 'id';
          data_code = 'regnummer';
          if (!this.props.registration.AllRegData) {
            await this.props.dispatch(AllRegistratie());
          }
          data = this.props.registration.AllRegData && this.props.registration.AllRegData.data ? this.props.registration.AllRegData.data : []
          break;

        case 'AllAccounts':
          data_id = 'id';
          data_code = 'name';
          if (!this.props.account.AllData) {
            await this.props.dispatch(AllAccounts());
          }
          data =  this.props.account.AllData && this.props.account.AllData.data ? this.props.account.AllData.data : []
          break;

        case 'AllAccountsName':
          data_id = 'id';
          data_code = 'name';
          if (!this.props.account.AllData) {
            await this.props.dispatch(AllAccounts());
          }
          data =  this.props.account.AllData && this.props.account.AllData.data ? this.props.account.AllData.data : []
          break;

        case 'AllAccountsNumber':
          data_id = 'id';
          data_code = 'number';
          if (!this.props.account.AllData) {
            await this.props.dispatch(AllAccounts());
          }
          data =  this.props.account.AllData && this.props.account.AllData.data ? this.props.account.AllData.data : []
          break;


        case 'AllJobs':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.jobs.AllJobsData) {
            await this.props.dispatch(AllJobs());
          }
          data = this.props.jobs.AllJobsData.data
        break;


        case 'AllJobSubjTime':
          data_id = 'id';
          data_code = 'text';
          if (!this.props.jobs.AllJobSubjData) {
            await this.props.dispatch(AllJobSubj());
          }
          data = this.props.jobs.AllJobSubjData ? this.props.jobs.AllJobSubjData.data : []

          let findindex = data.findIndex(x => x.id === "99");
          if (findindex < 0)
          {
            data.push({ id: '99', text: 'Afwezig' })
          }
          break;



        case 'AllDefOnkosten':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.land.AllOnkostenData) {
            await this.props.dispatch(AllOnkosten());
          }
          data = this.props.land.AllOnkostenData.data
          break;

        case 'AllVatRefCodeData':
          data_id = 'id';
          data_code = 'code';
          if (!this.props.jobs.AllVatRefCodeData) {
            await this.props.dispatch(AllVatRefCode());
          }
          data = this.props.jobs.AllVatRefCodeData.data
          break;

        case 'AllVatRefCodeLand':
          data_id = 'id';
          data_code = 'code';
          if (!this.props.jobs.AllVatRefCodeLandData) {
            data = [];
          }
          else
          {
            data = this.props.jobs.AllVatRefCodeLandData.data
          }
          break;

        case 'SubVatRefCode':
          data_id = 'id';
          data_code = 'code';
          if (!this.props.jobs.SubVatRefCodeData) {
            data = [];
          }
          else
          {
            data = this.props.jobs.SubVatRefCodeData.data
          }
          break;


          
          

        case 'AllLanden':
          data_id = 'id';
          data_code = 'nametxt';
          if (!this.props.land.AllData) {
            await this.props.dispatch(AllLanden());
          }
          data = this.props.land.AllData.data
        break;


        default:    
          break;
      }   
      this.setState({data:data, origdata:data})
    }

    async inploaddata() {
      let data = [];
      switch (this.props.colDef.type) 
      {
        case 'AllMunten':
          data_id = 'id';
          data_code = 'code';
          if (!this.props.param.AllMuntenData) {
            await this.props.dispatch(AllMunten());
          }
          data = this.props.param.AllMuntenData.data
        break;

        case 'AllSubVatRefCodes':
          data_id = 'id';
          data_code = 'text';
          await this.props.dispatch(AllSubVatRefCodes());
          data = this.props.param.AllSubVatRefCodesData.data
        break;

        case 'AllMainVatRefCodes':
          data_id = 'id';
          data_code = 'text';
          await this.props.dispatch(AllMainVatRefCodes());
          data = this.props.param.AllMainVatRefCodesData.data
        break;


        case 'AllSubProductCodes':
          data_id = 'id';
          data_code = 'text';
          if (!this.props.param.AllSubProductCodesData) {
            await this.props.dispatch(AllSubProductCodes());
          }
          data = this.props.param.AllSubProductCodesData.data
        break;

        case 'AllMainProductCodes':
          data_id = 'id';
          data_code = 'text';
          if (!this.props.param.AllMainProductCodesData) {
            await this.props.dispatch(AllMainProductCodes());
          }
          data = this.props.param.AllMainProductCodesData.data
        break;


        case 'AllSuppliers':
          data_id = 'id';
          data_code = 'name';
          if (!this.props.supplier.AllData) {
            await this.props.dispatch(AllSuppliers());
          }
          data = this.props.supplier.AllData.data
        break;
        
        case 'AllRegOms':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.registration.AllRegOmsData) {
            await this.props.dispatch(AllRegOms());
          }
          data = this.props.registration.AllRegOmsData.data
        break;

        case 'AllRegistratie':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.registration.AllRegData) {
            await this.props.dispatch(AllRegistratie());
          }
          data = this.props.registration.AllRegData && this.props.registration.AllRegData.data ? this.props.registration.AllRegData.data : []
          break;

        case 'AllRegistratieNum':
          data_id = 'id';
          data_code = 'regnummer';
          if (!this.props.registration.AllRegData) {
            await this.props.dispatch(AllRegistratie());
          }
          data = this.props.registration.AllRegData && this.props.registration.AllRegData.data ? this.props.registration.AllRegData.data : []
          break;

        case 'AllAccounts':
          data_id = 'id';
          data_code = 'name';
          if (!this.props.account.AllData) {
            await this.props.dispatch(AllAccounts());
          }
          data =  this.props.account.AllData && this.props.account.AllData.data ? this.props.account.AllData.data : []
          break;


        case 'AllAccountsName':
          data_id = 'id';
          data_code = 'name';
          if (!this.props.account.AllData) {
            await this.props.dispatch(AllAccounts());
          }
          data =  this.props.account.AllData && this.props.account.AllData.data ? this.props.account.AllData.data : []
          break;

        case 'AllAccountsNumber':
          data_id = 'id';
          data_code = 'number';
          if (!this.props.account.AllData) {
            await this.props.dispatch(AllAccounts());
          }
          data =  this.props.account.AllData && this.props.account.AllData.data ? this.props.account.AllData.data : []
          break;

        case 'AllJobSubjTime':
          data_id = 'id';
          data_code = 'text';
          if (!this.props.jobs.AllJobSubjData) {
            await this.props.dispatch(AllJobSubj());
          }
          data = this.props.jobs.AllJobSubjData ? this.props.jobs.AllJobSubjData.data : []

          let findindex = data.findIndex(x => x.id === "99");
          if (findindex < 0)
          {
            data.push({ id: '99', text: 'Afwezig' })
          }
          break;



        case 'AllJobs':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.jobs.AllJobsData) {
            await this.props.dispatch(AllJobs());
          }
          data = this.props.jobs.AllJobsData.data
        break;


        case 'AllDefOnkosten':
          data_id = 'id';
          data_code = 'description';
          if (!this.props.land.AllOnkostenData) {
            await this.props.dispatch(AllOnkosten());
          }
          data = this.props.land.AllOnkostenData.data
          break;

        case 'AllVatRefCodeData':
          data_id = 'id';
          data_code = 'code';
          if (!this.props.jobs.AllVatRefCodeData) {
            await this.props.dispatch(AllVatRefCode());
          }
          data = this.props.jobs.AllVatRefCodeData.data
          break;

        case 'AllVatRefCodeLand':
          data_id = 'id';
          data_code = 'code';
          if (!this.props.jobs.AllVatRefCodeLandData) {
            data = [];
          }
          else
          {
            data = this.props.jobs.AllVatRefCodeLandData.data
          }
          break;

        case 'SubVatRefCode':
          data_id = 'id';
          data_code = 'code';
          if (!this.props.jobs.SubVatRefCodeData) {
            data = [];
          }
          else
          {
            data = this.props.jobs.SubVatRefCodeData.data
          }
          break;


          
          

        case 'AllLanden':
          data_id = 'id';
          data_code = 'nametxt';
          if (!this.props.land.AllData) {
            await this.props.dispatch(AllLanden());
          }
          data = this.props.land.AllData.data
        break;


        default:    
          break;
      }   
      return data;
    }

    async componentDidMount() {
        await this.loaddata();
        await this.getFilter();      
        this.props.node.lastKey = null
        let datacol = {}
        if (this.props.value)
        {
    
           datacol[data_code] = this.props.value;
           if (this.props.colDef.selectcel)
           {
            datacol[data_id] = this.props.data[this.props.colDef.selectcel];
           }
           this.setState({datacol:datacol,value:this.props.value,preval:this.props.value})

        }

        setTimeout(() => {
          let element = document.getElementById(this.inputid);
          if (element) {element.focus()}
       }, 10);    
    }


    async setNewData(value,id)
    {
      await this.loaddata();
      let datacol = {}
      datacol[data_code] = value;
      datacol[data_id] = id;
      this.setState({datacol:datacol,value:value,preval:value})
    }

    async setFilter(filter)
    {
      let data = [];
      switch (this.props.colDef.type) 
      {
        case 'AllDefOnkosten':
          await this.props.dispatch(AllOnkosten());
          if (this.props.land.AllOnkostenData && this.props.land.AllOnkostenData.data) 
          {
            data = this.props.land.AllOnkostenData.data
          }
          break;

        case 'AllRegistratieNum':
          if (!this.props.registration.AllRegData) {
            await this.props.dispatch(AllRegistratie());
          }
          data = this.props.registration.AllRegData && this.props.registration.AllRegData.data ? this.props.registration.AllRegData.data : []
          break;

        case 'SubVatRefCode':
          await this.props.dispatch(GetSubVatRefCode(filter));
          data = this.props.jobs.SubVatRefCodeData && this.props.jobs.SubVatRefCodeData.data ? this.props.jobs.SubVatRefCodeData.data : []
          break;

        case 'AllSubProductCodes':
          await this.props.dispatch(AllSubProductCodes());

          data = this.props.param.AllSubProductCodesData.data
          break;

        case 'AllMainProductCodes':
          await this.props.dispatch(AllMainProductCodes());
          data = this.props.param.AllMainProductCodesData.data
          break;

        case 'AllSubVatRefCodes':
          await this.props.dispatch(AllSubVatRefCodes());
          data = this.props.param.AllSubVatRefCodesData && this.props.param.AllSubVatRefCodesData.data ? this.props.param.AllSubVatRefCodesData.data : []

          break;

        case 'AllMainVatRefCodes':
          await this.props.dispatch(AllMainVatRefCodes());
          data = this.props.param.AllMainVatRefCodesData && this.props.param.AllMainVatRefCodesData.data ? this.props.param.AllMainVatRefCodesData.data : []

          break;

        default:    
          break;
      }         
      if (filter && filter.length > 0 && data)
      {
        let datfilt = data;
        for (var ti = 0; ti < filter.length; ti++) 
        {
          let rowfilt = filter[ti];
          let filtfield = rowfilt.field;
          let filtvalue = rowfilt.value;
          if (filtfield && filtvalue)
          {
            datfilt = datfilt.filter(o=> o[filtfield] === filtvalue);
          }
        }         
        data = datfilt;
      }
      this.setState({data:data, origdata:data})
    }

    getFilter()
    {
       if (this.props.agGridReact.props.getFilter)
       {
          this.props.agGridReact.props.getFilter(this)
       }
    }

    async onBlur()
    {
      var colId = this.props.column.getId();

      let waarde = resultaat
      if (waarde && waarde.length > 0)
      {
        var inpresult = this.state.data.filter((item)=>{
          return item[data_code].toLowerCase().indexOf(waarde.toLowerCase()) >= 0; 
        });  
        if (Array.isArray(inpresult))
        {
          if (inpresult.length > 0)
          {
            waarde  = inpresult[0];
          }
          else
          {
            waarde = null; 
          }
        }
      }
      if (waarde && waarde[data_code] !== undefined && waarde[data_code].length > 0)
      {
        await this.setState({value:waarde[data_code],preval:waarde[data_code],autoSelect:false,error:false,datacol:null, toevoegen:false})
        if (this.props.colDef.selectcel)
        {
            this.props.data[this.props.colDef.selectcel] = waarde[data_id];
        }
        this.props.node.setDataValue(colId, waarde[data_code]);
        this.props.agGridReact.props.onCellValueCheck(this,waarde[data_code],waarde)
      }
      else
      {
        await this.setState({value:waarde,preval:waarde,autoSelect:true,error:true,datacol:null, toevoegen:false})
        if (this.props.colDef.selectcel)
        {
          this.props.data[this.props.colDef.selectcel] = null;
        }
        this.props.node.setDataValue(colId, waarde);
        this.props.agGridReact.props.onCellValueCheck(this,waarde,null)
      }
    }

    async componentWillUnmount() 
    {
      let waarde = null;
      if (this.state.value && this.state.value.length > 0)
      {
        var inpresult = this.state.data.filter((item)=>{
          return item[data_code].toLowerCase().indexOf(this.state.value.toLowerCase()) >= 0; 
        });  
        if (Array.isArray(inpresult))
        {
          if (inpresult.length > 0)
          {
            waarde  = inpresult[0];
          }
          else
          {
            waarde = this.state.value; 
          }
        }
      }        
      var colId = this.props.column.getId();
      let voegtoe = false      
      if (waarde && waarde[data_code] !== undefined && waarde[data_code].length > 0)
      {
        if (this.props.colDef.selectcel)
        {
          this.props.data[this.props.colDef.selectcel] = waarde[data_id];
        }
        this.props.node.setDataValue(colId, waarde[data_code]);
        this.props.agGridReact.props.onCellValueCheck(this,waarde[data_code],waarde)
      }
      else
      {
        if (this.props.colDef.autoselverpl === false && waarde)
        {
          var result = this.state.data.filter((item)=>{
            return item[data_code].toLowerCase().indexOf(waarde.toLowerCase()) >= 0; 
          });          
          let verder = true;
          if (Array.isArray(result))
          {
            if (result.length > 0)
            {
              verder = false;
            }
          }
          if (verder === true)
          {

            if (waarde.length > 0)
            {
              voegtoe =true
            }
            if (this.props.colDef.selectcel)
            {
              this.props.data[this.props.colDef.selectcel] = 0;
            }
            this.props.node.setDataValue(colId, waarde);
            let terwaarde = {id:0,text:waarde,value:waarde}
            this.props.agGridReact.props.onCellValueCheck(this,terwaarde)
          }
        }
      }
    }

    getValue() {
        return this.state.value;
    }
    isPopup() {
        return false;
    }
    addRecord(event, waarde, stateName)
    {
       if (this.props.agGridReact.props.addRecord)
       {
          this.props.agGridReact.props.addRecord(this,this.state.value)
       }
    }

    async changekeyup(event, waarde, stateName, keycode) 
    {
      switch (event.key) {
        case 'Home':
        case 'End':
        case 'PageUp':
        case 'PageDown':
        case 'ArrowDown':
        case 'ArrowUp':
        case 'ArrowLeft':
        case 'ArrowRight':
        case 'Enter':
        case 'Escape':
          return;

        default:
          let hwaarde = event.target.value;   
          var inpresult = this.state.origdata;
          resultaat = hwaarde;
          if (hwaarde && hwaarde.length > 0)
          {
            if (this.props.colDef.type === 'AllAccounts')
            {
              inpresult = this.state.data.filter((item)=>{
              return (item['name'].toLowerCase().indexOf(this.hwaarde.toLowerCase()) || item['number'].toLowerCase().indexOf(hwaarde.toLowerCase()) )>= 0; 
              });  

            }
            else
            {
              inpresult = this.state.data.filter((item)=>{
              return item[data_code].toLowerCase().indexOf(hwaarde.toLowerCase()) >= 0; 
              });  
            }            
          }           
          await this.setState({value:hwaarde,data:inpresult})
          return;

      }      
    }


    async change(event, waarde, stateName, keycode) 
    {
      var inpresult = null;
      if (this.props.node && this.props.node.lastKey && this.props.node.lastKey.keyCode === 9)    
      {
        if (this.state.value && this.state.value.length > 0)
        {
          if (this.props.colDef.type === 'AllAccounts')
          {
            inpresult = this.state.data.filter((item)=>{
            return (item['name'].toLowerCase().indexOf(this.state.value.toLowerCase()) || item['number'].toLowerCase().indexOf(this.state.value.toLowerCase()) )>= 0; 
            });  

          }
          else
          {
            inpresult = this.state.data.filter((item)=>{
            return item[data_code].toLowerCase().indexOf(this.state.value.toLowerCase()) >= 0; 
            });  
          }
          if (Array.isArray(inpresult))
          {
            if (inpresult.length > 0)
            {
              waarde  = inpresult[0];
            }
            else
            {
              waarde = null; 
            }
          }
        }        
      }
      if (stateName === 'unmount')    
      {
        if (this.state.value && this.state.value.length > 0)
        {
          if (this.props.colDef.type === 'AllAccounts')
          {
            inpresult = this.state.data.filter((item)=>{
            return (item['name'].toLowerCase().indexOf(this.state.value.toLowerCase()) || item['number'].toLowerCase().indexOf(this.state.value.toLowerCase()) )>= 0; 
            });  

          }
          else
          {
            inpresult = this.state.data.filter((item)=>{
            return item[data_code].toLowerCase().indexOf(this.state.value.toLowerCase()) >= 0; 
            });  
          }

          if (Array.isArray(inpresult))
          {
            if (inpresult.length > 0)
            {
              waarde  = inpresult[0];
            }
            else
            {
              waarde = null; 
            }
          }
        }        
      }

      if (stateName === 'reset')
      {
        return;
      }

      var colId = this.props.column.getId();
      let voegtoe = false      
      if (waarde && waarde[data_code] !== undefined && waarde[data_code].length > 0)
      {
        this.setState({value:waarde[data_code],preval:waarde[data_code],autoSelect:false,error:true,datacol:waarde, toevoegen:false})
        if (this.props.colDef.selectcel)
        {
          this.props.data[this.props.colDef.selectcel] = waarde[data_id];
        }
        this.props.node.setDataValue(colId, waarde[data_code]);
        this.props.agGridReact.props.onCellValueCheck(this,waarde[data_code],waarde)
      }
      else
      {
        if (this.props.colDef.autoselverpl === false && waarde)
        {
          var result = null
          if (this.props.colDef.type === 'AllAccounts')
          {
            result = this.state.data.filter((item)=>{
            return (item['name'].toLowerCase().indexOf(this.state.value.toLowerCase()) || item['number'].toLowerCase().indexOf(this.state.value.toLowerCase()) )>= 0; 
            });  

          }
          else
          {
            result = this.state.data.filter((item)=>{
            return item[data_code].toLowerCase().indexOf(this.state.value.toLowerCase()) >= 0; 
            });  
          }

          let verder = true;
          if (Array.isArray(result))
          {
            if (result.length > 0)
            {
              verder = false;
            }
          }
          if (verder === true)
          {

            if (waarde.length > 0)
            {
              voegtoe =true
            }
            this.setState({value:waarde,preval:waarde, autoSelect:true,error:true,datacol:null,toevoegen:voegtoe})
            if (this.props.colDef.selectcel)
            {
              this.props.data[this.props.colDef.selectcel] = 0;
            }
            this.props.node.setDataValue(colId, waarde);
            let terwaarde = {id:0,text:waarde,value:waarde}
            this.props.agGridReact.props.onCellValueCheck(this,terwaarde)
          }
          else
          {
            this.setState({toevoegen:voegtoe})
          }
        }
        if (this.props.colDef.autoselverpl === false && !waarde)
        {
          this.setState({toevoegen:false})
        }
        if (event && !event.target && stateName === 'input')
        {
            this.setState({value:event,error:true,autoSelect:true, toevoegen:false})
        }
        if (stateName === 'clear')
        {
            this.setState({value:'',datacol:null,autoSelect:false,toevoegen:false})
            if (this.props.colDef.selectcel)
            {
              this.props.data[this.props.colDef.selectcel] = "";
            }
            this.props.node.setDataValue(colId, "");
            this.props.agGridReact.props.onCellValueCheck(this,'');
            this.setState({error:true});
        }
      }
    }

    render() {
      const {
        classes,
      } = this.props;

      const formControlProps={}
      var formControlClasses;
      if (formControlProps !== undefined) {
        formControlClasses = classNames(
          formControlProps.className,
          classes.formControl
        );
      } else {
        formControlClasses = classes.formControl;
      }
      let strlengte = (this.props.column.actualWidth - 22).toString()+'px';
      let startend = this.state.toevoegen === true ? (<IconButton onClick ={this.addRecord} style= {{top:'15px',left:'-20px',position:'absolute'}} aria-label="toggle password visibility">
                        <AddCircle/>
                      </IconButton>) : null;
      return (
           <div style={this.state.toevoegen === true ? {top:'-22px',position:'relative',marginBottom:'15px', paddingRight:'5px',paddingLeft:'20px',display:'block'}:
         {top:'-22px',position:'relative',marginBottom:'15px',paddingRight:'5px',display:'block'}}>
        {startend}
        <Autocomplete  {...formControlProps}  className={formControlClasses}
          id="country-select-demo"
          autoComplete={true}
          disableOpenOnFocus={true}
          selectOnFocus = {true}
          blurOnSelect={true}
//          autoHighlight={true}
          autoSelect={this.state.autoSelect}      
          style={{width:strlengte}}
          options={this.state.data}
          value={this.state.value}
          defaultValue={this.props.value}
          classes={{option: this.props.classes.AutocompleteOption, listbox:this.props.classes.AutocompleteListbox}}
          getOptionLabel={option => (typeof option === 'string' ? option : option[data_code])}
          onChange ={this.change}
          renderInput={params => (
            <TextField
              {...params}
              error={this.state.error}
              onKeyUp = {(event) => { this.changekeyup(event,this.state.value,'input',event.keyCode)}}
              classes={{root: this.props.classes.selectinputbrut}}   
                inputProps={{
                  ...params.inputProps,
              startadornment: startend,             

                  style:{marginBottom:'10px',width:'100%',fontSize:'small'},
                  id:this.inputid,
                }}        
            />
          )}
        />
        </div>
      );
    }
}
export default connect(mapStateToProps)(withStyles(styles)(GridEditAutoSelect));
