import React, {createRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';

import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditSelect from "../../components/editselect.jsx";

import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";

import {AllJobDefMainAcc,AllJobDefMainDiv, AllRegistratieAcc, AllJobDefMainType} from "../../redux/settings/jobsActions";
import {AllLandenAcc} from "../../redux/settings/landActions";
import Dropzone from 'react-dropzone';

var regisobj = null;
var accountobj = null;
var countryobj = null;
var jobtypeobj = null;
var periodeobj = null;
class PopCreateJob extends React.Component {
    state = {
        data: this.props.data,
        filename:'',
        save:false,
        edit:true,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
    }

    constructor(props, context) {

        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.SaveCheck = this.SaveCheck.bind(this);
        this.SetObject = this.SetObject.bind(this)


    }
    async componentDidMount(){
        let edit = true
        if (this.props.data && this.props.data.registration_id && this.props.data.registration_id.length > 4)
        {
          edit = false;
          if (this.props.data.type)
          {
            await this.props.dispatch(AllJobDefMainAcc(this.props,this.props.data.account_id,this.props.data.country,this.props.data.registration_id));

            if (jobtypeobj)
            {  
              jobtypeobj.loaddata()
            }

          }
        }

        if (this.props.path === '/jobdivers')
        {
            await this.props.dispatch(AllJobDefMainDiv(this.props));
            if (jobtypeobj)
            {  
              jobtypeobj.loaddata()
            }
        }

        this.setState({data:this.props.data,edit:edit})
        if (this.props.data && this.props.data.account_id && this.props.data.account_id.length > 4)
        {
          await this.props.dispatch(AllLandenAcc(this.props,this.props.data.account_id));
          if (countryobj)
          {  
            countryobj.loaddata()
          }
        }
    }
    SetObject(object,colid)
    {
        switch (colid) 
        {
          case 'registratie_txt':
            regisobj = object;
            break;

          case 'periode_txt':
            periodeobj = object;
            break;

          case 'account_name':
            accountobj = object;
            break;
          case 'country_txt':
            countryobj = object;
            break;

          case 'job_txt':
            jobtypeobj = object;
            break;

          default:    
            break;
        } 
    }


    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens
      let data = this.state.data;
      if (data.dagen)
      {
        let dagen = data.dagen.trim()
        data.dagen = dagen
      }
      data.org_create_date = data.create_date;
      if (data.jaar < GenFunc.Get_Jaar())
      {
         data.create_date = '01-01-'+data.jaar
      }

      let terdata = await SqlFunction.DoSql('CreaJob',data);

      if (this.props.close) 
      {
        this.props.close(terdata)
      }
    }

    SaveCheck(data) 
    {
      let chsave = false;
      if (data.account_id.length < 4 )
      {
        chsave = true;
      }
      if (data.registration_id.length < 4 )
      {
        if (this.props.path !== '/jobdivers')
        {
          chsave = true;
        }        
      }
      if (data.aang_periode.length < 4 )
      {
        if (this.props.path !== '/jobdivers')
        {
          chsave = true;
        }        
      }

      if (data.country.length < 4 )
      {
        if (this.props.path !== '/jobdivers')
        {
          chsave = true;
        }        
      }
      if (data.job_id.length < 4 )
      {
        chsave = true;
      }

      if (data.jaar < 2010 || data.jaar > 2100)
      {
        chsave = true;
      }
      return chsave
    }


    async ValueCheck(that,waarde,type,colId,verplicht) 
    {
      let tariefdata = null;
      let data = this.state.data;
      var registfilter = [];

      switch (colId) 
      {
        case 'aang_periode_txt':
        case 'aang_periode':
          if (waarde.id && waarde.text)
          {
            data.aang_periode_txt = waarde.text;
            data.aang_periode = waarde.id;
            if (waarde.code)
            {
              data.aang_periode_code = waarde.code;
            }
          }
          if(waarde && waarde.id && waarde.id.length > 2) 
          {
            that.setState({error:false});
          }
          else
          {
            that.setState({error:true})
          }
          break;

        case 'dagen':
          data.dagen = waarde;
          break;


        case 'jaar':
          data.jaar = waarde;
          if (waarde < 2010 || waarde > 2100)
          {
            that.setState({error:true})
          }
          else
          {
            that.setState({error:false})
          }
          break;

        case 'job_txt':
        case 'job_id':
          if (waarde.id)
          {
            data.job_txt = waarde.value;
            data.job_id = waarde.id;
            if (waarde.code)
            {
              data.code = waarde.code;
            }
            if (data.job_id && data.job_id.length > 4)
            {
              if (that.overzdata &&  data.origin !== 'REGISTRATIE')
              {
                await this.props.dispatch(AllRegistratieAcc(this.props,data.account_id,data.country,that.overzdata.subject));
                if (this.props.jobs && this.props.jobs.AllRegistratieAccData && this.props.jobs.AllRegistratieAccData.data)
                {
                  if (this.props.jobs.AllRegistratieAccData.data.length === 1) 
                  {
                    let hudata = this.props.jobs.AllRegistratieAccData.data[0];
                    data.registratie_txt = hudata.text;
                    data.registration_id = hudata.id;   
                    data.kantoor_txt = hudata.btwkant_txt;
                    data.kantoor_id = hudata.btwkant;
                    data.aang_periode_txt = hudata.periodes_txt;
                    data.aang_periode = hudata.periodes;    
                    if (periodeobj)
                    {  
                      periodeobj.setState({error:false})
                    }                                 

                  }
                  else
                  {
                    data.registratie_txt = '';
                    data.registration_id = '';                    
                    data.kantoor_txt = '';
                    data.kantoor_id = 0;
                    data.aang_periode_txt = '';
                    data.aang_periode = '';                 

                  }
                }
                if (regisobj)
                {  
                  regisobj.loaddata()
                }
              }
            }
            tariefdata = await SqlFunction.DoSql('GetTarief',data);
            if (tariefdata ) {data.tarief = tariefdata.tarief}
          }
          if (type === 'DIDMOUNT')
          {
            if (data.job_id && data.job_id.length > 4)
            {
              if (that.overzdata)
              {
                await this.props.dispatch(AllRegistratieAcc(this.props,data.account_id,data.country,that.overzdata.subject));

                if (regisobj)
                {  
                  regisobj.loaddata()
                }
              }
            }
          }            

          if(waarde && waarde.id && waarde.id.length > 2) 
          {
            that.setState({error:false});
          }
          else
          {
            that.setState({error:true})
          }
          break;

        case 'account_name':
        case 'account_id':
            if (data.account_id !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  if (that.overzdata.onhold === true || that.overzdata.onhold === '1')
                  {
                    data.account_name = '';
                    data.account_id = '';
                    data.registratie_txt = '';
                    data.registration_id = '';
                    data.user_id = '';
                    data.user_name = '';
                    data.job_id = '';
                    data.job_txt = '';
                    data.country = '';
                    data.country_txt = '';
                    if (accountobj)
                    {
                      accountobj.SetWaarde("","")
                    }
                    if (regisobj)
                    {
                      regisobj.SetWaarde("","")
                      regisobj.SetFilter(registfilter)
                    }
                  }
                  else
                  {
                    data.account_name = waarde.value;
                    data.account_id = waarde.id;
                    data.registratie_txt = '';
                    data.registration_id = '';
                    data.job_id = '';
                    data.job_txt = '';
                    data.country = '';
                    data.country_txt = '';
                    data.user_id = that.overzdata.user;
                    data.user_name = that.overzdata.user_name;
                    await this.props.dispatch(AllLandenAcc(this.props,data.account_id));
                    if (countryobj)
                    {  
                      countryobj.loaddata()
                    }

                    if (regisobj)
                    {
                      regisobj.SetWaarde("","")
                      regisobj.SetFilter(registfilter)
                    }
                  }
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.account_name = '';
                    data.account_id = '';
                    data.registratie_txt = '';
                    data.registration_id = '';
                    data.user_id = '';
                    data.user_name = '';
                    data.job_id = '';
                    data.job_txt = '';
                    data.country = '';
                    data.country_txt = '';
                    accountobj.SetWaarde('','')
                  }
                }
                tariefdata = await SqlFunction.DoSql('GetTarief',data);
                if (tariefdata) {data.tarief = tariefdata.tarief}

            }
            if (type === 'DIDMOUNT')
            {
                await this.props.dispatch(AllLandenAcc(this.props,data.account_id));

            }            
            if(waarde && waarde.id && waarde.id.length > 2) 
            {
              that.setState({error:false});
            }
            else
            {
              that.setState({error:true})
            }
            break;

        case 'registratie_txt':
        case 'registration_id':
            if (data.registration_id !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  data.registratie_txt = waarde.value;
                  data.registration_id = waarde.id;
                  data.kantoor_txt = that.overzdata.btwkant_txt;
                  data.kantoor_id = that.overzdata.btwkant;
                  data.aang_periode_txt = that.overzdata.periodes_txt;
                  data.aang_periode = that.overzdata.periodes;                 
                  if (periodeobj)
                  {  
                    periodeobj.setState({error:false})
                  }

                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.registratie_txt = '';
                    data.registration_id = '';
                    data.kantoor_txt = '';
                    data.kantoor_id = '';

                  }
                }
            }
            if(waarde && waarde.id && waarde.id.length > 2) 
            {
              that.setState({error:false});
            }
            else
            {
              that.setState({error:true})
            }
            break;

            
        case 'country_txt':
        case 'country':
            if (data.country !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  data.country_txt = waarde.value;
                  data.country = waarde.id;
                  data.kantoor_txt = '';
                  data.kantoor_id = '';
                  data.registration_id = '';
                  data.registratie_txt = '';
                  data.job_id = '';
                  data.job_txt = '';
                  if (regisobj)
                  {
                    regisobj.SetWaarde('','')
                    registfilter.push({field:'land',value:waarde.id})
                    if (data.account_id && data.account_id.length > 4)
                    {
                      registfilter.push({field:'accountid',value:data.account_id})
                    }
                    if (data.user_id && data.user_id.length > 4)
                    {
                      registfilter.push({field:'user_id',value:data.user_id})
                    }
                  }
                  if (regisobj)
                  {
                    regisobj.SetFilter(registfilter)
                  }
                  await this.props.dispatch(AllJobDefMainAcc(this.props,data.account_id,data.country,data.registration_id));

                  if (jobtypeobj)
                  {
                    jobtypeobj.loaddata()
                  }

                  tariefdata = await SqlFunction.DoSql('GetTarief',data);
                  if (tariefdata)
                  {
                      data.tarief = tariefdata.tarief;
                  }
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.country_txt = '';
                    data.country = '';
                    data.job_id = '';
                    data.job_txt = '';
                    countryobj.SetWaarde('','')
                    if (data.account_id && data.account_id.length > 4)
                    {
                      registfilter.push({field:'accountid',value:data.account_id})
                    }
                    if (regisobj)
                    {
                      regisobj.SetFilter(registfilter)
                    }
                    tariefdata = await SqlFunction.DoSql('GetTarief',data);
                    if (tariefdata) {data.tarief = tariefdata.tarief}
                  }
                }
            }

            if (data.country && type === 'DIDMOUNT')
            {
                await this.props.dispatch(AllJobDefMainAcc(this.props,data.account_id,data.country,data.registration_id));
                if (jobtypeobj)
                {
                  jobtypeobj.loaddata()
                }
          }
            if(waarde && waarde.id && waarde.id.length > 2) 
            {
              that.setState({error:false});
            }
            else
            {
              that.setState({error:true})
            }
            break;


        default:    
          data[colId] = waarde;
          break;
      } 
      let wsave = await this.SaveCheck(data);
      this.setState({data:data,save:wsave})
    }       

    async ErrorCheck(that,waarde,type,colId,verplicht) 
    {
      switch (colId) 
      {
        case 'aang_periode_txt':
        case 'aang_periode':
        case 'job_txt':
        case 'job_id':
        case 'account_name':
        case 'account_id':
        case 'registratie_txt':
        case 'registration_id':
        case 'country_txt':
        case 'country':

          if(waarde && waarde.length > 2) 
          {
            that.setState({error:false});
          }
          else
          {
            that.setState({error:true})
          }
          break;

        case 'jaar':
          if (waarde < 2010 || waarde > 2100)
          {
            that.setState({error:true})
          }
          else
          {
            that.setState({error:false})
          }
          break;
          

        default:    
          break;
      } 
    }       
    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }

    render() {
        const {fullScreen,classes, open} = this.props;
        return (
            <Dialog
                classes={{
                    root: classes.center + " " + classes.modalRoot,
                    paper: classes.modal80
                }}

                fullScreen={fullScreen}
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible",zIndex:'1001'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                maxWidth='md'
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',width:'900px'}}  className={classes.blockContent}>
                  <Grid container spacing={1}>
                    {this.state.edit === false &&
                      <Grid container spacing={1}>
                        <Grid item sm={12} md={12} lg={12}>
                          <small className="text-muted p-t-30 db">Klant</small> 
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                          <h6>{this.props.data.account_name}</h6> 
                        </Grid>
                      </Grid>
                    }
                    {this.props.data && this.state.edit === true &&
                      <Grid item sm={12} md={12} lg={12}>
                        <EditAutoSelect className="card-title m-t-10"
                          value={this.state.data.account_name}
                          labelText="Klant"
                          lijst='AllAccounts'
                          colId="account_name"
                          selectcel='account_id'
                          SetObject = {(object,colid) => this.SetObject(object,"account_name")} 
                          id={this.state.data.account_id}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,"account_name",true)}
                          setError={(params,value,type) => this.ErrorCheck(params,value,type,'account_name')}
                        />
                      </Grid>
                    }
                    {this.state.edit === false && this.props.path !== '/jobdivers' &&
                      <Grid container spacing={1}>
                        <Grid item sm={12} md={12} lg={12}>
                          <small className="text-muted p-t-30 db">Land</small> 
                        </Grid>
                        <Grid item sm={12} md={12} lg={12}>
                          <h6>{this.props.data.country_txt}</h6> 
                        </Grid>
                      </Grid>
                    }

                    {this.state.data && this.state.data.account_id && this.state.data.account_id.length > 4 && this.state.edit === true &&
                      this.props.path !== '/jobdivers' &&
                      <Grid item sm={12} md={12} lg={12}>
                        <EditAutoSelect className="card-title m-t-10"
                          value={this.state.data.country_txt}
                          labelText="Land"
                          lijst='AllLandenAcc'
                          colId="country_txt"
                          selectcel='country'
                          SetObject = {(object,colid) => this.SetObject(object,"country_txt")} 
                          id={this.state.data.country}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,"country_txt",true)}
                          setError={(params,value,type) => this.ErrorCheck(params,value,type,'country_txt')}
                        />
                      </Grid>
                    }

                    {this.state.data && this.state.data.account_id && this.state.data.account_id.length > 4 &&
                      ((this.state.data.country && this.state.data.country.length > 4) || this.props.path === '/jobdivers') &&
                      <Grid item xs={12}>
                        <EditAutoSelect className="card-title m-t-10"
                          value={this.state.data.job_txt}
                          labelText='Soort Job'
                          lijst='AllJobDefMainAcc'
                          data={this.state.data}
                          colId='job_txt'
                          selectcel='job_id'
                          SetObject = {(object,colid) => this.SetObject(object,"job_txt")} 
                          id={this.state.data.job_id}
                          setError={(params,value,type) => this.ErrorCheck(params,value,type,'job_txt')}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'job_txt',true)}
                        />
                      </Grid>
                    }
                    {this.state.data && this.state.data.account_id && this.state.data.account_id.length > 4 && 
                      this.state.data.job_id && this.state.data.job_id.length > 4 && this.state.data.country && this.state.data.country.length > 4 &&
                      this.props.jobs && this.props.jobs.AllRegistratieAccData && this.props.jobs.AllRegistratieAccData.data && this.props.jobs.AllRegistratieAccData.data.length > 1 &&
                      this.state.edit === true && this.props.path !== '/jobdivers'&&                     

                      <Grid item sm={12} md={12} lg={12}>
                        <EditAutoSelect className="card-title m-t-10"
                          value={this.state.data.registratie_txt}
                          labelText="Registratie"
                          lijst='AllRegistratieAcc'
                          colId="registratie_txt"
                          SetObject = {(object,colid) => this.SetObject(object,'registratie_txt')} 
                          selectcel='registration_id'
                          id={this.state.data.registration_id}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'registratie_txt',false)}
                          setError={(params,value,type) => this.ErrorCheck(params,value,type,'registratie_txt')}
                        />
                      </Grid>
                    }

                    {this.state.data && this.state.data.account_id && this.state.data.account_id.length > 4 && 
                      this.state.data.job_id && this.state.data.job_id.length > 4 && 
                      (this.state.data.country && this.state.data.country.length > 4 || this.props.path === '/jobdivers') &&
                      <Grid item sm={12} md={12} lg={12}>
                        <EditString className="card-title m-t-10"
                          value={this.state.data.jaar}
                          labelText="Jaar"
                          coltype = 'STR'                        
                          format = {[/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'jaar')}
                          setError={(params,value,type) => this.ErrorCheck(params,value,type,'jaar')}
                        />
                      </Grid>
                    }
                    {this.state.data && this.state.data.account_id && this.state.data.account_id.length > 4 && 
                      this.state.data.job_id && this.state.data.job_id.length > 4 && 
                      (this.state.data.country && this.state.data.country.length > 4 || this.props.path === '/jobdivers') &&
                      <Grid item sm={12} md={12} lg={12}>
                        <EditString className="card-title m-t-10"
                          value={this.state.data.tarief}
                          coltype = 'NUM'                        
                          labelText="Tarief"
                          decimal={2}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'tarief')}
                        />
                      </Grid>
                    }


                    {this.state.data && this.state.data.account_id && this.state.data.account_id.length > 4 && 
                      this.state.data.job_id && this.state.data.job_id.length > 4 && this.state.data.country && this.state.data.country.length > 4 && 
                      <Grid item sm={6} md={6} lg={6}>
                        <EditSelect className="card-title m-t-10"
                          value={this.state.data.aang_periode_txt}
                          labelText="Periodiciteit"
                          data={this.state.data}
                          type='AllPeriodes'
                          colId='aang_periode_txt'
                          selectcel='aang_periode'
                          SetObject = {(object,colid) => this.SetObject(object,'periode_txt')} 
                          id={this.state.data.aang_periode}
                          setError={(params,value,type) => this.ErrorCheck(params,value,type,'aang_periode_txt')}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'aang_periode_txt',true)}
                        />
                      </Grid>
                    }

                    {this.state.data.aang_periode_txt === 'Dagen' &&
                      <Grid item sm={6} md={6} lg={6}>
                        <EditString className="card-title m-t-10"
                          value={this.state.data.dagen}
                          labelText="Dagen"
                          coltype = 'STR'                        
                          format = {[/[0-9]/,/[0-9]/,/[0-9]/]}
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dagen')}
                        />
                      </Grid>
                    }
                  </Grid>
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  <Button color="primary" disabled={this.state.save} onClick={() => this.handleSave()}>
                    Aanmaken
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
  ...state
});


PopCreateJob.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopCreateJob)));

