import React from 'react';
import GenFunc from "../functions/gen_functions.js";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import styles from "../assets/components/customCheckStyle.jsx";

class EditCheck extends React.Component {
    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
        this.inputid = GenFunc.MakeId()
        this.state = {
            value: (this.props.value === '0' || this.props.value === 'N' || this.props.value === 0 || this.props.value === false ) ? false : true,
            error:false,
        }
    }
    componentDidMount() {
        if (this.props.setError)
        {
          this.props.setError(this,this.props.value,'STRING')
        }

    }
    change(event, valid, stateName) 
    {
      if (event.target) 
      {
        this.setState({value:event.target.checked})
        this.props.ValueCheck(this,event.target.checked,'CHECK')

      } 
    }
    render() {
      const {classes} = this.props;  
      return (
        <FormControl component="fieldset" style={this.props.style}>
          <FormControlLabel
            labelPlacement={this.props.labelPlacement ? this.props.labelPlacement : "start"}
            control={<Checkbox 
              classes={{colorPrimary: classes.brutcol}}
              onChange={this.change} 
              value={this.state.value} 
              disabled = {this.props.disabled ? this.props.disabled : false}
              checked={this.state.value} 
              id={this.inputid}
              color="primary"
              />}
            label={this.props.labelText}
            style={this.props.Objstyle ? this.props.Objstyle : {width:'100%'}}
          />
        </FormControl>    
      );
    }
}
export default withStyles(styles)(EditCheck);