import { combineReducers } from 'redux';
import settings from './settings/reducer';
import user from './settings/users_reducer';
import param from './settings/param_reducer';
import registration from './settings/registration_reducer';
import account from './settings/accounts_reducer';
import contact from './settings/contact_reducer';
import kantoor from './settings/kantoor_reducer';
import jobs from './settings/jobs_reducer';
import land from './settings/land_reducer';
import supplier from './settings/suppliers_reducer';
import template from './settings/template_reducer';
import activity from './settings/activity_reducer';
import timeregistration from './settings/timeregistration_reducer';

const reducers = combineReducers({
    settings,
    user,
    param,
    registration,
    account,
    contact,
    kantoor,
    jobs,
    land,
    supplier,
    template,
    activity,
    timeregistration,
});

export default reducers;
