import React from 'react';
import GenFunc from "../../functions/gen_functions.js";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import styles from "../../assets/components/customInputStyle.jsx";
import Datetime from "react-datetime";
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Clear from "@material-ui/icons/Exclamation";
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';

import moment from 'moment'


interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}

class GridEditDateTime extends React.Component {

  NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        value={props.value}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue,
            },
          });
        }}
        format="##-##-####"
        showmask="false"
      />
    );
  }        

  constructor(props) {
    super(props);
    this.change = this.change.bind(this);

    this.renderInputComponent = this.renderInputComponent.bind(this);
    this.NumberFormatCustom = this.NumberFormatCustom.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);

    this.inputid = GenFunc.MakeId()
    this.state = {
      value: this.props.value,
      error: false,
      canTab: false,
      calendarOpen: false
    }
  }

  componentDidMount() {
    this.props.agGridReact.props.onCellValueCheck(this, this.props.value)
    setTimeout(() => {
      document.activeElement.blur();
      let element = document.getElementById(this.inputid);
      element.focus()
      element.select()
    }, 10);
  }

  getValue() {
    return this.state.value;
  }

  isPopup() {
    return true;
  }

  change(event, valid, stateName) {
    var colId = this.props.column.getId();
    if (event._isAMomentObject) {
      this.setState({ value: event._i })
      this.props.node.setDataValue(colId, event._i);
      this.props.agGridReact.props.onCellValueCheck(this, event._i)

      if (this.state.canTab){
        setTimeout(() => {
          this.props.agGridReact.api.tabToNextCell();
        }, 100); 
      }

      this.setState({ canTab: true })
      
    }
    else {
      this.setState({ value: event })
      this.props.node.setDataValue(colId, event);
      this.props.agGridReact.props.onCellValueCheck(this, event)

      if (event.length > 0) {
        var utc = moment(event, "DD-MM-YYYY", true)
        if (!utc.isValid()) { 
          this.setState({ error: true, canTab: true })
        }
      }
    }

  }

  toggleCalendar() {
    const newCalendarState = !this.state.calendarOpen;
    this.setState({
      calendarOpen: newCalendarState
    });
  }

  renderInputComponent(inputProps) {
    const {
      classes,
      id,
      helperText,
    } = this.props;


    const formControlProps = { fullWidth: true }
    var underlineClasses = classNames({
      [classes.brutUnderline]: !this.state.error,
      [classes.underlineError]: this.state.error,
      [classes.underline]: true,
    });
    if (this.state.error) {
      underlineClasses = classNames(
        classes.underlineError);

    }

    const inputClasses = classNames({
      [classes.input]: true,
      [classes.whiteInput]: this.state.white
    });
    var formControlClasses;
    if (formControlProps !== undefined) {
      formControlClasses = classNames(
        formControlProps.className,
        classes.formControl
      );
    } else {
      formControlClasses = classes.formControl;
    }
    var helpTextClasses = classNames({
      [classes.labelRootError]: this.state.error,
      [classes.labelRootSuccess]: this.state.success && !this.state.error
    });

    return (
      <FormControl style={{ top: '-20px', position: 'relative', marginBottom: '-20px', width: '100%' }} {...formControlProps} className={formControlClasses}>

        <Input
          classes={{
            input: inputClasses,
            disabled: classes.disabled,
            underline: underlineClasses
          }}
          inputComponent={this.NumberFormatCustom}
          inputProps={{
            ...inputProps,
            id: this.inputid,
          }}


        />

        {this.state.error ? (
          <Clear className={classes.feedback + " " + classes.labelRootError} />
        ) : this.state.error ? (
          <Clear className={classes.feedback + " " + classes.labelRootError} />
        ) : null}

        {helperText !== undefined ? (
          <FormHelperText id={id + "-text"} className={helpTextClasses}>
            {helperText}
          </FormHelperText>
        ) : null}
      </FormControl>

    )
  }

  render() {
    const {
      id
    } = this.props;


    return (
      <div>
        <Grid container spacing={1} alignItems="flex-end">
          <Grid style={{width: 'calc(100% - 40px)'}} item>
            <Datetime
              timeFormat={false}
              style={{ width: '100%' }}
              defaultValue={this.state.value}
              input={true}
              onChange={this.change}
              renderInput={this.renderInputComponent}
              closeOnSelect={true}
              locale={'nl-be'}
              open={this.state.calendarOpen}
              dateFormat={"DD-MM-YYYY"}
              id={id}
            />
          </Grid>
          <Grid item>
            <IconButton onClick ={this.toggleCalendar} style= {{marginLeft: '-20px'}} aria-label="toggle calendar selection visibility">
              <CalendarToday/>
            </IconButton>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(GridEditDateTime);