import React from 'react';
import classnames from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import styles from "../../assets/views/customViewStyle.jsx";
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import PictureUpload from "../../components/PictureUpload.jsx";
import EditString from "../../components/editstring.jsx";
import EditCheck from "../../components/editcheck.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { AllAccounts, GetAccount, GetContAccount, CreateAccountKantoor } from "../../redux/settings/accountActions";
import { GetLand, GetOnkostenLand, GetKantorenLand, GetKlantenLand, CreateLand, GetRegLand, GetVolmachtenLand, GetVatRefCodeLand } from "../../redux/settings/landActions";
import { AllKantoren, GetKantoor, GetContKantoor, CreateLandKantoor } from "../../redux/settings/kantoorActions";
import { setPanelRight, setTitleRight, setSubTitle} from "../../redux/settings/action";
import {GetRegistration} from "../../redux/settings/registrationActions";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import {AllMunten } from '../../redux/settings/paramActions.js';

var gridapi = null;

const mapStateToProps = state => ({
    ...state
});

class Landpage extends React.Component {

    state = {
        tableheight: Math.floor(window.innerHeight * .70) + 'px',
        tabvalue: '1',
        pinnedSubVatRefCode:[{id:0,code_id:'',code_txt:'',country:'',code:'',rep_code:'',btw:'',aftrek:'',save:'',verwijder:''}],        
        foto: this.props.land && this.props.land.MainData && this.props.land.MainData.data ? this.props.land.MainData.data.foto : null,
        edit: this.props.land && this.props.land.MainData && this.props.land.MainData.data && this.props.land.MainData.data.id === 0 ? true : false,
        save: false,
        subsave: false,
        checkval: false,
        data: this.props.land && this.props.land.MainData && this.props.land.MainData.data && this.props.land.MainData.data.id === 0 ? this.props.land.MainData.data : null,
        speedactions: [
            { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
            { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuw land' },
            { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
            { icon: <BusinessIcon />, name: 'NewKantoor', tooltip: 'Nieuw kantoor'},
            { icon: <AccountBoxIcon />, name: 'NewAccount', tooltip: 'Nieuwe klant'},
        ],
        speedopen: false,
    };

    constructor(props, context) {
        super(props);
        this.resizeFunction = this.resizeFunction.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this)
        this.ValueCheck = this.ValueCheck.bind(this);
        this.handleSpeedClose = this.handleSpeedClose.bind(this);
        this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
        this.ZetEdit = this.ZetEdit.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.MainSave = this.MainSave.bind(this);
        this.checkconfirm = this.checkconfirm.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.handleJa = this.handleJa.bind(this);
        this.ActionClick = this.ActionClick.bind(this);
        this.SetPhoto = this.SetPhoto.bind(this);
        this.KantCellClick = this.KantCellClick.bind(this);
        this.KlantCellClick = this.KlantCellClick.bind(this);
        this.OnkostenChanged = this.OnkostenChanged.bind(this);
        this.OnkostenCheck = this.OnkostenCheck.bind(this);
        this.OnkostenCellClick = this.OnkostenCellClick.bind(this);
        this.OnkostenSaveAll = this.OnkostenSaveAll.bind(this);
        this.RegCellClick = this.RegCellClick.bind(this);
        this.GetTaalcodes = this.GetTaalcodes.bind(this);
        this.GetTaalcodesEdit = this.GetTaalcodesEdit.bind(this);
        this.volmachtenChanged = this.volmachtenChanged.bind(this);
        this.volmachtenCheck = this.volmachtenCheck.bind(this);
        this.volmachtenCellClick = this.volmachtenCellClick.bind(this);
        this.volmachtenSaveAll = this.volmachtenSaveAll.bind(this);
        this.SubVatRefCodeChanged = this.SubVatRefCodeChanged.bind(this);
        this.SubVatRefCodeCheck = this.SubVatRefCodeCheck.bind(this);
        this.SubVatRefCodeCellClick = this.SubVatRefCodeCellClick.bind(this);
        this.getFilter = this.getFilter.bind(this);

    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
        this.props.dispatch(setTitleRight('Landen'))
        this.props.dispatch(setSubTitle('Land'))
        this.props.dispatch(setPanelRight(true))
    }

    componentWillUnmount() {
        this.checkconfirm(this.props.land.MainData);
        this.props.dispatch(setPanelRight(false))
        this.props.dispatch(setTitleRight(''))
        window.removeEventListener("resize", this.resizeFunction);
    }

    getFilter(params) 
    {
      var colId = params.props.column.getId();
      var filtar = []
      if (colId === 'code_id_code')
      {
        if (params.props.data.maincode && params.props.data.maincode.length > 3)
        {
            filtar.push({field:'main_id',value:params.props.data.maincode})
            params.setFilter(filtar);
        }
        else
        {
            params.setFilter(filtar);
        }

      }

    }

    SubVatRefCodeChanged(params,waarde,that) {
    }                        

    SubVatRefCodeCheck(params,waarde,datacol) {
        var colId = params.props.column.getId();
        params.props.data.save = true;
        let error = false;
        switch (colId) 
        {
            case 'maincode_code':
                if (datacol )
                {
                    if (datacol.description)
                    {
                        params.props.data.maincode = datacol.id;      
                        params.props.data.mainid_txt = datacol.description;      
                        params.props.data.code_id_code = '';      
                        params.props.data.code_id = ''
                        params.props.data.code_id_txt =''
                    }
                }
                if (waarde && waarde.length > 0) 
                {
                    error = false
                    params.props.data.save = true;
                } else 
                { 
                    error = true
                    params.props.data.save = '';
                }
                break;


            case 'code_id_code':
                if (datacol )
                {
                    if (datacol.description)
                    {
                        params.props.data.code_id_txt = datacol.description;     
                        params.props.data.code_id = datacol.id;      
                        params.props.data.maincode = datacol.main_id;      
                        params.props.data.maincode_code = datacol.maincode_code;      
                        params.props.data.mainid_txt = datacol.main_description;      
                    }
                }
                break;

            default:    
                params.props.data[colId] = waarde;
                break;
        } 
        if ((params.props.data.maincode && params.props.data.maincode.length > 0)) 
        {
          params.props.data.save = true;
        } else 
        { 
          params.props.data.save = '';
        }
        params.props.node.setData(params.props.data);
        params.setState({error:error})
    }       

      async SubVatRefCodeCellClick(waarde) {
        var data = waarde.data
        let column = waarde.column.colDef.field;
        let retdata = null;
        if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit'))
        {
          if (data.save === true) 
          {
            data.country = this.props.land.MainData.data.id
            retdata = await SqlFunction.DoSql('ChgSubVatRefCode',data);
            waarde.data.save='';
            if (data.id === 0) {
              waarde.data.id = retdata.id;
              gridapi.api.stopEditing();
              gridapi.api.updateRowData({
                add: [waarde.data],
                addIndex: 0
              });
              waarde.node.setData({id:0,code_id:'',code_txt:'',country:'',code:'',rep_code:'',maincode:'',btw:'',aftrek:'',code_id_txt:'',code_id_code:'',maincode_code:'',mainid_txt:'',save:'',verwijder:''});
            }
            else {
              waarde.node.setData(waarde.data);
            }
            this.setState({subsave:false})

            await this.props.dispatch(GetVatRefCodeLand(this.props.land.MainData.data.id))

          }
        }
        if (column === 'verwijder' && GenFunc.allowed(this.props.user.userData.secrows,'AllVatRefCode','edit'))
        {
          if (data.verwijder === true || data.verwijder === '1') 
          {
            retdata = await SqlFunction.DoSql('DeleteSubVatRefCode',data);
            await this.props.dispatch(GetVatRefCodeLand(this.props.land.MainData.data.id))

          }
        }

        

        return;
      }



    resizeFunction() {
        if (window.innerHeight >= 960) {
            this.setState({ tableheight: Math.floor(window.innerHeight * .70) + 'px' });
        }
        else {
            this.setState({ tableheight: '600px' });
        }
    }

    onGridReady(params) {
        gridapi = params;
    }

    getContextMenuItems(params, sleutel) {
        return null;
    }

    async ValueCheck(that, waarde, type, colId) {
        let data = this.state.data;
        let terug = false;
        let taalcodes = this.props.land.AllTaalcodesData.data;

        switch (colId) {
            case 'munt_txt':
                data.munt_txt = waarde.value;
                data.munt = waarde.id;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.land.AllData.data, data);
                break;

            default:
                data[colId] = waarde;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.land.AllData.data, data, taalcodes);
                break;
        }

        

        that.setState({ error: terug })
        terug = await this.CheckSave(data);
        this.setState({ save: terug, data: data, checkval: false })
    } 

    handleSpeedClose() {
        this.setState({ speedopen: false });
    };

    handleSpeedOpen() {
        this.setState({ speedopen: true });
    };

    async ZetEdit() {
        await this.props.dispatch(AllMunten(this.props))
        this.setState({ edit: true, data: this.props.land.MainData.data })
    }

    CheckSave(data) {
        let taalcodes = this.props.land.AllTaalcodesData.data;
        let terug = true
        terug = Validation.ValidCheck(this.props.settings.sub_title, data.name, 'name', this, this.props.land.AllData.data, data);
        if(terug === false){
            for(let i = 0; i < taalcodes.length; i++){
                if(terug === false) {
                    terug = Validation.ValidCheck(this.props.settings.sub_title, data[(taalcodes[i].omschrijving).toLowerCase()], (taalcodes[i].omschrijving).toLowerCase(), this, this.props.land.AllData.data, data, taalcodes);
                }
            }
        }
        return !terug;
    }

    async handleTabChange(event, newValue) {
        if (newValue === '1') {
            //Taalcodes
        }
        if (newValue === '2') {
            // Aanvaardbare onkosten
            await this.props.dispatch(GetOnkostenLand(this.props.land.MainData.data.id))
        }

        if (newValue === 'IVAT') {
            // Aanvaardbare onkosten
            this.setState({pinnedSubVatRefCode:[{id:0,code_id:'',code_txt:'',country:'',code:'',rep_code:'',btw:'',aftrek:'',save:'',verwijder:''}]});        
            await this.props.dispatch(GetVatRefCodeLand(this.props.land.MainData.data.id))
        }



        if (newValue === '3') {
            // Kantoren
            await this.props.dispatch(GetKantorenLand(this.props.land.MainData.data.id))
        }
        if (newValue === '4') {
            // Klanten
            await this.props.dispatch(GetKlantenLand(this.props.land.MainData.data.id))
        }
        if (newValue === '5') {
            // Registraties
            await this.props.dispatch(GetRegLand(this.props.land.MainData.data.id))
        }
        if (newValue === '6') {
            //Volmachten
            await this.props.dispatch(GetVolmachtenLand(this.props.land.MainData.data.id))
        }

        this.setState({ tabvalue: newValue });

    };

    async MainSave() {
        if (this.state.save === true) {
            let retdata = await SqlFunction.DoSql('ChgLanden', this.state.data);
            
            this.setState({ edit: false, save: false, data: null, checkval: false })
            if (retdata) {
                await this.props.dispatch(GetLand(retdata.id))
            }
        }
    }

    checkconfirm(parameters) {
        if (this.state.save === true || this.state.subsave === true) {
            confirmAlert({
                closeOnEscape: false,
                closeOnClickOutside: false,
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Aanpassingen niet doorgevoerd.</h1>
                            <p>Wens je de aanpassingen alsnog te bewaren?</p>
                            <button
                                onClick={() => {
                                    this.handleJa(parameters);
                                    onClose();
                                }}
                            >
                                Ja
                            </button>
                            <button onClick={() => {
                                this.setState({ edit: false, save: false, subsave: false, checkval: false })
                                onClose();
                            }}
                            >
                                Nee
                            </button>
                        </div>
                    );
                }
            });
        }

    }

    async componentWillReceiveProps(nextProps, nextState) {
        if (this.props.land.MainData) {
            if (this.props.land.MainData.data.id !== nextProps.land.MainData.data.id) {
                await this.checkconfirm(this.props.land.MainData);
                if (nextProps.land.MainData.data.id === 0) {
                    this.setState({ edit: true, save: false, subsave: false })
                }
                else {
                    this.setState({ edit: false, save: false, subsave: false })
                    this.setState({ tabvalue: '1' });
                }
            }
        }
    }

    async handleJa(parameters) {
        if (this.state.save === true) {
            await SqlFunction.DoSql('ChgLanden', this.state.data);
        }
        if (this.state.subsave === true) {
        }
        //    this.setState({edit:false,save:false,subsave:false})
    }

    async ActionClick(name) { 
        let data = null;
        switch (name) {
            case 'Edit':
                await this.props.dispatch(AllMunten(this.props))
                this.setState({ edit: true, data: this.props.land.MainData.data, speedopen: false })
                break;

            case 'New':
                await this.props.dispatch(CreateLand())
                this.setState({ edit: true, data: this.props.land.MainData.data, speedopen: false })
                break;

            case 'Save':
                this.MainSave();
                break;

            case 'NewKantoor':
                await this.props.dispatch(AllKantoren(this.props));
                data = { countryid: this.props.land.MainData.data.id, country_name: this.props.land.MainData.data.name };
                this.props.dispatch(setSubTitle('Kantoor'));
                await this.props.dispatch(CreateLandKantoor(data))
                this.props.history.push('/kantoor');
                break;

            case 'NewAccount':
                await this.props.dispatch(AllAccounts(this.props));
                data = { countryid: this.props.land.MainData.data.id, country_name: this.props.land.MainData.data.name };
                this.props.dispatch(setSubTitle('Klant'));
                await this.props.dispatch(CreateAccountKantoor(data))
                this.props.history.push('/account');
                break;

            default:
                break;
        }
        this.setState({ speedopen: false });
    }

    async SetPhoto(fileName) {
        if (this.state.data && this.state.edit === true) {
            let data = this.state.data;
            data.foto = fileName;
            this.setState({ data: data });
        }
    }

    async KantCellClick(waarde) {
        await this.props.dispatch(AllKantoren(this.props))
        this.props.dispatch(setSubTitle('Kantoor'))
        let id = waarde.data.id;
        await this.props.dispatch(GetKantoor(id))
        await this.props.dispatch(GetContKantoor(id))
        this.props.history.push('/kantoor');
        return;
    }

    async KlantCellClick(waarde) {
        await this.props.dispatch(AllAccounts(this.props))
        this.props.dispatch(setSubTitle('Klantenfiche'))
        let id = waarde.data.id;
        await this.props.dispatch(GetAccount(id))
        await this.props.dispatch(GetContAccount(id))
        this.props.history.push('/account');
        return;
    }

    OnkostenChanged(params,waarde,that) {
    } 
    
    OnkostenCheck(params, waarde) {
        var colId = params.props.column.getId();
        params.props.data.save = true;
        let terug = Validation.ValidCheck('Onkosten', waarde, colId, params, gridapi);

        if (colId === 'description') {
            if (waarde && waarde.description) {
                params.props.data.description = waarde.description;
            }
        }

        if (params.props.data && params.props.data.description.length >= 1 && params.props.data.dirreg >= 0 && params.props.data.dirreg <= 100 && params.props.data.vereenv >= 0 && params.props.data.vereenv <= 100) {
            params.props.data.save = true;
        }
        else {
            params.props.data.save = '';
        }   
        params.setState({ error: terug })
    }  
    
    async OnkostenCellClick(waarde) {
        var data = waarde.data
        let column = waarde.column.colDef.field;
        let retdata = null;
        if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows, 'AllLanden', 'edit')) {
            if (data.save === true) {
                data.mainid = this.props.land.MainOnkostenData.data.id;
                data['land'] = this.props.land.MainData.data.id;
                retdata = await SqlFunction.DoSql('ChgOnkosten', data);
                waarde.data.save = '';
                if (data.id === 0) {
                    waarde.data.id = retdata.id;
                    gridapi.api.stopEditing();
                    gridapi.api.updateRowData({
                        add: [waarde.data],
                        addIndex: 0
                    });
                    waarde.node.setData(GenFunc.zetdataleeg('Onkosten'));
                }
                else {
                    waarde.node.setData(waarde.data);
                }
                this.setState({ subsave: false })
            }
        }
        return;
    }

    async OnkostenSaveAll(items) {
        if (Array.isArray(items) && GenFunc.allowed(this.props.user.userData.secrows, 'AllLanden', 'edit')) {
            var i = 0;
            for (i = 0; i < items.length; i++) {
                let data = items[i];
                await SqlFunction.DoSql('ChgOnkosten', data);
            }
        }
    }

    async RegCellClick(waarde) {
        this.props.dispatch(setSubTitle('Registratie'))
        await this.props.dispatch(GetRegistration(waarde.data.id))
        await this.props.dispatch(GetAccount(waarde.data.accountid))
        this.props.history.push('/registration');
        return;
    }

    volmachtenChanged(params, waarde, that){

    }

    volmachtenCheck(params, waarde){
        var colId = params.props.column.getId();
        params.props.data.save = true;
        let terug = Validation.ValidCheck('Volmachten', waarde, colId, params, gridapi);

        if (colId === 'omschrijving') {
            if (waarde && waarde.omschrijving) {
                params.props.data.omschrijving = waarde.omschrijving;
            }
        }
        params.props.data.save = !terug;
        params.setState({ error: terug })
    }

    async volmachtenCellClick(waarde){
        var data = waarde.data
        let column = waarde.column.colDef.field;
        let retdata = null;
        if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows, 'VolmachtenLand', 'edit')) {
          if (data.save === true) {
            data.country = this.props.land.MainData.data.id;
            retdata = await SqlFunction.DoSql('ChgVolmachtenLand', data);
            waarde.data.save = '';
            if (data.id === 0) {
              waarde.data.id = retdata.id;
              gridapi.api.stopEditing();
              gridapi.api.updateRowData({
                add: [waarde.data],
                addIndex: 0
              });
              waarde.node.setData(GenFunc.zetdataleeg('VolmachtenLand'));
            }
            else {
              waarde.node.setData(waarde.data);
            }
            this.setState({ subsave: false })
          }
        }
        if (column === 'verwijder' && GenFunc.allowed(this.props.user.userData.secrows, 'VolmachtenLand', 'verwijder')) {
          await SqlFunction.DoSql('DeleteVolmachtenLand', waarde.data);
          await this.props.dispatch(GetVolmachtenLand(this.props.land.MainData.data.id))
          return;
        }
    
        return;
    }

    volmachtenSaveAll(items){

    }

    GetTaalcodes(){
        let taalcodes = [];
        let taalcodesleft = [];
        let taalcodesright = [];

        if(this.props.land.AllTaalcodesData.data.length % 2 === 0){
            for(let i =0; i < this.props.land.AllTaalcodesData.data.length; i++){
                if(i < this.props.land.AllTaalcodesData.data.length/2){
                    taalcodesleft.push(
                        <div style={{ width: "35%" }}>
                            <small className="text-muted p-t-30 db">{this.props.land.AllTaalcodesData.data[i].text}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.MainData.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                } else {
                    taalcodesright.push(
                        <div style={{ width: "35%" }}>
                            <small className="text-muted p-t-30 db">{this.props.land.AllTaalcodesData.data[i].text}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.MainData.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                }
            }
        } else {
            for(let i =0; i < this.props.land.AllTaalcodesData.data.length; i++){
                if(i <= this.props.land.AllTaalcodesData.data.length/2){
                    taalcodesleft.push(
                        <div style={{ width: "35%" }}>
                            <small className="text-muted p-t-30 db">{this.props.land.AllTaalcodesData.data[i].text}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.MainData.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                } else {
                    taalcodesright.push(
                        <div style={{ width: "35%" }}>
                            <small className="text-muted p-t-30 db">{this.props.land.AllTaalcodesData.data[i].text}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.MainData.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                }
            }
        }

        taalcodes.push(
            <div style={{ marginTop: "25px" }} key={"taalcodesdiv"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "35%" }}>
                        {taalcodesleft}
                    </div>
                    <div style={{ width: "15%" }}></div>
                    <div style={{ width: "35%" }}>
                        {taalcodesright}
                    </div>
                </div>
            </div>
        );

        return taalcodes;
    }

    GetTaalcodesEdit() {
        let taalcodes = [];
        let taalcodesleft = [];
        let taalcodesright = [];

        if(this.props.land.AllTaalcodesData.data.length % 2 === 0){
            for(let i = 0; i < this.props.land.AllTaalcodesData.data.length; i++){
                if(i < this.props.land.AllTaalcodesData.data.length/2){
                    taalcodesleft.push(
                        <EditString className="card-title m-t-10"
                            value={this.state.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}
                            coltype='STR'
                            labelText={this.props.land.AllTaalcodesData.data[i].text}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, (this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase())}
                        />
                    );
                } else {
                    taalcodesright.push(
                        <EditString className="card-title m-t-10"
                            value={this.state.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}
                            coltype='STR'
                            labelText={this.props.land.AllTaalcodesData.data[i].text}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, (this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase())}
                        />
                    );
                }
            }
        } else {
            for (let i = 0; i < this.props.land.AllTaalcodesData.data.length; i++) {
                if (i <= this.props.land.AllTaalcodesData.data.length / 2) {
                    taalcodesleft.push(
                        <EditString className="card-title m-t-10"
                            value={this.state.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}
                            coltype='STR'
                            labelText={this.props.land.AllTaalcodesData.data[i].text}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, (this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase())}
                        />
                    );
                } else {
                    taalcodesright.push(
                        <EditString className="card-title m-t-10"
                            value={this.state.data[(this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase()]}
                            coltype='STR'
                            labelText={this.props.land.AllTaalcodesData.data[i].text}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, (this.props.land.AllTaalcodesData.data[i].omschrijving).toLowerCase())}
                        />
                    );
                }
            }
        }

        taalcodes.push(
            <div style={{ marginTop: "25px" }} key={"taalcodesdiv"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "35%" }}>
                        {taalcodesleft}
                    </div>
                    <div style={{ width: "15%" }}></div>
                    <div style={{ width: "35%" }}>
                        {taalcodesright}
                    </div>
                </div>
            </div>
        );

        return taalcodes;
    }

    render() {
        const { classes } = this.props;
        var { tableheight } = this.state;
        let vscan = false;
        if (this.props.land.MainData.data.scandoc === '1' || this.props.land.MainData.data.scandoc === true)
        {
           vscan = true;
        }

        return (
            <div>
                <Toolbar style={{ background: '#fff', padding: '14px 10px', boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px' }}>
                    <Grid container>
                        <Grid item xs={8}>
                            <h4 tag="h4" className="mt-3" onClick={() => { this.setState({ edit: false }) }}>{this.props.settings.sub_title}</h4>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        icon={<SpeedDialIcon style={{ zIndex: '10' }} />}
                        direction="left"
                        classes={{ root: classes.speedDial, fab: classes.speedFabRoot }}
                        onClose={this.handleSpeedClose}
                        onOpen={this.handleSpeedOpen}
                        open={this.state.speedopen}
                    >
                        {this.state.speedactions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipPlacement='bottom'
                                FabProps={{
                                    disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? false :
                                    (action.name === 'Edit' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                    (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? true :
                                    (action.name === 'New' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? false :
                                    (action.name === 'New' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                    (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? true :
                                    (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === true) ? false :
                                    (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === false) ? true :
                                    (action.name === 'Save' && this.state.edit === false && this.state.editfact === false) ? true :
                                    (action.name === 'Save' && this.state.save === false) ? true : false
                                }}
                                onClick={() => { this.ActionClick(action.name) }}
                                tooltipTitle={action.tooltip}
                            />
                        ))}
                    </SpeedDial>
                </Toolbar>
                {this.props.land && this.props.land.MainData && this.props.land.MainData.data && 
                    <Grid  container style={{width: 'auto', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Card className='card-brut'>
                                    {this.state.edit === false &&
                                        <PerfectScrollbar className="sidebar-nav">
                                            <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                                <PictureUpload stylemain={{textAlign:'center',width:'100%'}} inputstyle={{width:'100%',height:'160px'}}  style={{width:'150px',alignItems: 'center',  textAlign: 'center', borderRadius: '5%'}} onClick={() => { this.ZetEdit() }} DefaultPhoto = {this.props.land.MainData.data.foto} />
                                                <h4 style={{ marginTop: '25px', marginBottom: '50px', cursor: 'pointer' }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">{this.props.land.MainData.data.name}</h4>

                                                <div className='div_line' style={{ marginBottom: '25px' }}></div>
                                                <small className="text-muted p-t-30 db">Munteenheid</small>
                                                <h6>{this.props.land.MainData.data.munt_txt}</h6>
                                                <small className="text-muted p-t-30 db">Landcode</small>
                                                <h6>{this.props.land.MainData.data.code}</h6>
                                                <small className="text-muted p-t-30 db">Standaard BTW%</small>
                                                <h6>{this.props.land.MainData.data.defbtw}</h6>
                                                <small className="text-muted p-t-30 db">VAT Refund - Meesturen ingescande documenten 
                                                    <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" 
                                                        control={<Checkbox checked={vscan} />} 
                                                    />
                                                </small>
                                                <div className='div_line' style={{ marginTop: '25px', marginBottom: '25px' }}></div>

                                                <small className="text-muted p-t-30 db">Informatie</small>
                                                <EditString className="card-title m-t-10"
                                                    value={this.props.land.MainData.data.info}
                                                    coltype = 'STR'  
                                                    multiline={true}                      
                                                    disabled
                                                    readonly = {true}
                                                 />                                                
                                                <div className='div_line' style={{ marginTop: '25px', marginBottom: '25px' }}></div>
                                                
                                            </CardBody>
                                        </PerfectScrollbar>
                                    }
                                    {this.state.edit === true &&
                                        <PerfectScrollbar className="sidebar-nav">
                                        <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                            <PictureUpload stylemain={{textAlign:'center',width:'100%'}} inputstyle={{width:'100%',height:'160px'}}  style={{width:'150px',alignItems: 'center',  textAlign: 'center', borderRadius: '5%'}} SetFileName={this.SetPhoto} DefaultPhoto = {this.props.land.MainData.data.foto} />
                                            <EditString className="card-title m-t-10"
                                                value={this.state.data.name}
                                                coltype='STR'
                                                labelText="Land"
                                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'name')}
                                            />
                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                <EditAutoSelect className="card-title m-t-10"
                                                    value={this.state.data.munt_txt}
                                                    GoogleZoek={true}
                                                    labelText="Munteenheid"
                                                    lijst='AllMunten'
                                                    colId='munt_txt'
                                                    selectcel='munt'
                                                    id={this.state.data.munt}
                                                    ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'munt_txt')}
                                                />
                                            </div>
                                            <EditString className="card-title m-t-10"
                                                value={this.state.data.code}
                                                coltype='STR'
                                                labelText="Landcode"
                                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'code')}
                                            />

                                            <EditString className="card-title m-t-10"
                                                value={this.state.data.defbtw}
                                                labelText="Standaard BTW%"
                                                coltype = 'NUM'                        
                                                decimal={2}
                                                ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'defbtw',false)}
                                            />
                                            <div style={{marginLeft: '-22px'}}>
                                                <EditCheck className="card-title m-t-10"
                                                    value={this.state.data.scandoc}
                                                    labelPlacement="start"
                                                    labelText="AT Refund - Meesturen ingescande documenten "
                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'scandoc')}
                                                />
                                            </div>

                                            <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                                            <Grid container spacing={1}>
                                                <Grid item sm={12} md={12} lg={12}>
                                                    <small className="text-muted p-t-30 db">Informatie</small>
                                                </Grid>
                                                <Grid item sm={12} md={12} lg={12}>
                                                    <EditString className="card-title m-t-10"
                                                        value={this.state.data.info}
                                                        coltype='STR'
                                                        multiline={true}
                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'info')}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                                        </CardBody>
                                    </PerfectScrollbar>
                                    }
                                </Card>
                            </Grid>
                            <Grid item xs={9}>
                                <Card className='card-brut'>
                                    <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                        <div className='div_line'>
                                            <Nav tabs >
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.tabvalue === '1' })}
                                                        onClick={(event) => { this.handleTabChange(event, '1'); }}
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        Taalcodes
                                                        </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.tabvalue === '2' })}
                                                        onClick={(event) => { this.handleTabChange(event, '2'); }}
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        Aanvaardbare onkosten
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.tabvalue === 'IVAT' })}
                                                        onClick={(event) => { this.handleTabChange(event, 'IVAT'); }}
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        IVAT Codes
                                                    </NavLink>
                                                </NavItem>

                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.tabvalue === '3' })}
                                                        onClick={(event) => { this.handleTabChange(event, '3'); }}
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        Kantoren
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.tabvalue === '4' })}
                                                        onClick={(event) => { this.handleTabChange(event, '4'); }}
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        Klanten
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.tabvalue === '5' })}
                                                        onClick={(event) => { this.handleTabChange(event, '5'); }}
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        Registraties
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.tabvalue === '6' })}
                                                        onClick={(event) => { this.handleTabChange(event, '6'); }}
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        Volmachten
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={this.state.tabvalue}>
                                                <TabPane tabId="1">
                                                    <div style={{ marginTop: "25px" }}>
                                                        {!this.state.edit && this.GetTaalcodes()}
                                                        {this.state.edit && this.GetTaalcodesEdit()}
                                                    </div>
                                                </TabPane> 
                                                <TabPane tabId="2">
                                                    {this.state.tabvalue === '2' && this.props.land.MainOnkostenData && this.props.land.MainOnkostenData.data && this.props.land.MainOnkostenData.kol &&
                                                        this.props.land && this.props.land.MainData && this.props.land.MainData.data && this.props.land.MainData.data.id !== 0 &&
                                                        <TableGrid
                                                            tableheight={tableheight}
                                                            columns={this.props.land.MainOnkostenData.kol}
                                                            data={this.props.land.MainOnkostenData.data}
                                                            CellValueChanged={(params,waarde,object) => this.OnkostenChanged(params,waarde,object)}
                                                            CellValueCheck={(params,value,error) => this.OnkostenCheck(params,value,error)}
                                                            CellClickedEvent={(column, colDef, value) => this.OnkostenCellClick(column,colDef, value)}
                                                            SaveAll={(params) => this.OnkostenSaveAll(params)}
                                                            sizetofit={false}
                                                            sortable={false}
                                                            suppressMenu={true}
                                                            enableRowGroup={false}
                                                            suppressColumnVirtualisation={true}
                                                            filter={false}
                                                            sleutel='AllOnkosten'
                                                            pinnedTopRowData={[{id:0,description:'',dirreg:'',vereenv:'',save:''}] }
                                                            editable={true}
                                                            GridReady={this.onGridReady}
                                                        />
                                                    }
                                                </TabPane> 
                                                <TabPane tabId="3">
                                                    {this.state.tabvalue === '3' && this.props.land.KantorenData && this.props.land.KantorenData.data && this.props.land.KantorenData.kol &&
                                                        this.props.land && this.props.land.MainData && this.props.land.MainData.data && this.props.land.MainData.data.id !== 0 &&
                                                        <TableGrid
                                                            tableheight={tableheight}
                                                            columns={this.props.land.KantorenData.kol}
                                                            data={this.props.land.KantorenData.data}
                                                            sizetofit={true}
                                                            sortable={false}
                                                            suppressMenu={true}
                                                            enableRowGroup={false}
                                                            suppressColumnVirtualisation={true}
                                                            filter={true}
                                                            sleutel='AllKantorenLand'
                                                            getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllSecPar')}
                                                            editable={false}
                                                            GridReady={this.onGridReady}
                                                            CellClickedEvent={(column, colDef, value) => this.KantCellClick(column, colDef, value)}
                                                        />
                                                    }
                                                </TabPane> 
                                                <TabPane tabId="IVAT">
                                                    {this.state.tabvalue === 'IVAT' && this.props.land.VatRefData && this.props.land.VatRefData.data && this.props.land.VatRefData.kol &&
                                                        this.props.land.MainData.data && this.props.land.MainData.data.id !== 0 &&
                                                        <TableGrid
                                                            tableheight={tableheight}
                                                            columns={this.props.land.VatRefData.kol}
                                                            data={this.props.land.VatRefData.data}
                                                            CellValueChanged={(params,waarde,object) => this.SubVatRefCodeChanged(params,waarde,object)}
                                                            CellValueCheck={(params,value,error) => this.SubVatRefCodeCheck(params,value,error)}
                                                            CellClickedEvent={(column, colDef, value) => this.SubVatRefCodeCellClick(column,colDef, value)}
                                                            SaveAll={(params) => this.SubJobSaveAll(params)}
                                                            getFilter={(params) => this.getFilter(params)}
                                                            sortable={true}
                                                            sizetofit={false}
                                                            suppressMenu={true}
                                                            enableRowGroup={true}
                                                            headerComponentParams = {null}
                                                            suppressColumnVirtualisation={true}
                                                            filter={false}
                                                            editable={true}
                                                            pinnedTopRowData={this.state.pinnedSubVatRefCode}
                                                            GridReady={this.onGridReady}
                                                            sleutel='SubVatRefCode'                     
                                                            getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllSecPar')}
                                                        />
                                                    }
                                                </TabPane> 

                                                <TabPane tabId="4">
                                                    {this.state.tabvalue === '4' && this.props.land.KlantenData && this.props.land.KlantenData.data && this.props.land.KlantenData.kol &&
                                                        this.props.land && this.props.land.MainData && this.props.land.MainData.data && this.props.land.MainData.data.id !== 0 &&
                                                        <TableGrid
                                                            tableheight={tableheight}
                                                            columns={this.props.land.KlantenData.kol}
                                                            data={this.props.land.KlantenData.data}
                                                            sizetofit={true}
                                                            sortable={false}
                                                            suppressMenu={true}
                                                            enableRowGroup={false}
                                                            suppressColumnVirtualisation={true}
                                                            filter={true}
                                                            sleutel='AllKlantenLand'
                                                            getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllSecPar')}
                                                            editable={false}
                                                            GridReady={this.onGridReady}
                                                            CellClickedEvent={(column, colDef, value) => this.KlantCellClick(column, colDef, value)}
                                                        />
                                                    }
                                                </TabPane>
                                                <TabPane tabId="5">
                                                    {this.state.tabvalue === '5' && this.props.land.RegData && this.props.land.RegData.data && this.props.land.RegData.kol &&
                                                        this.props.land && this.props.land.MainData && this.props.land.MainData.data && this.props.land.MainData.data.id !== 0 &&
                                                        <TableGrid
                                                            tableheight={tableheight}
                                                            columns={this.props.land.RegData.kol}
                                                            data={this.props.land.RegData.data}
                                                            sizetofit={true}
                                                            sortable={false}
                                                            suppressMenu={true}
                                                            enableRowGroup={false}
                                                            suppressColumnVirtualisation={true}
                                                            filter={true}
                                                            sleutel='RegLanden'
                                                            getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllSecPar')}
                                                            editable={false}
                                                            GridReady={this.onGridReady}
                                                            sql={() => this.props.dispatch(GetRegLand(this.props.user.MainData.data.id))}
                                                            CellClickedEvent={(column, colDef, value) => this.RegCellClick(column, colDef, value)}
                                                        />
                                                    }
                                                </TabPane>
                                                <TabPane tabId="6">
                                                    {this.state.tabvalue === '6' && this.props.land.VolmachtenData && this.props.land.VolmachtenData.data && this.props.land.VolmachtenData.kol &&
                                                        this.props.land && this.props.land.MainData && this.props.land.MainData.data && this.props.land.MainData.data.id !== 0 &&
                                                        <TableGrid
                                                            tableheight={tableheight}
                                                            columns={this.props.land.VolmachtenData.kol}
                                                            data={this.props.land.VolmachtenData.data}
                                                            CellValueChanged={(params,waarde,object) => this.volmachtenChanged(params,waarde,object)}
                                                            CellValueCheck={(params,value,error) => this.volmachtenCheck(params,value,error)}
                                                            CellClickedEvent={(column, colDef, value) => this.volmachtenCellClick(column,colDef, value)}
                                                            SaveAll={(params) => this.volmachtenSaveAll(params)}
                                                            sizetofit={false}
                                                            sortable={false}
                                                            suppressMenu={true}
                                                            enableRowGroup={false}
                                                            suppressColumnVirtualisation={true}
                                                            filter={false}
                                                            sleutel='VolmachtenLand'
                                                            pinnedTopRowData={[{id:0,country:this.props.land.MainData.data.id,omschrijving:'',save:''}] }
                                                            editable={true}
                                                            GridReady={this.onGridReady}
                                                        />
                                                    }
                                                </TabPane>  
                                            </TabContent>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }

}
export default connect(mapStateToProps)(withStyles(styles)(Landpage));
