import React from 'react';
import classnames from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Card, CardBody, TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import styles from "../../assets/views/customViewStyle.jsx";
import PerfectScrollbar from 'react-perfect-scrollbar';
import EditString from "../../components/editstring.jsx";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import {GetTaalcode, GetFactuuropbouw, AllLanden, CreateTaalcode, CreateFactuuropbouw } from "../../redux/settings/landActions";
import { setPanelRight, setTitleRight, setSubTitle } from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import { AllJobDefMain } from '../../redux/settings/jobsActions.js';


const mapStateToProps = state => ({
    ...state
});

class Taalcodespage extends React.Component {

    state = {
        tableheight: Math.floor(window.innerHeight * .70) + 'px',
        tabvalue: '1',
        edit: this.props.land && this.props.land.MainTaalcodeData && this.props.land.MainTaalcodeData.data && this.props.land.MainTaalcodeData.data.id === 0 ? true : false,
        save: false,
        subsave: false,
        checkval: false,
        data: { 
            taalcodedata: this.props.land && this.props.land.MainTaalcodeData && this.props.land.MainTaalcodeData.data && this.props.land.MainTaalcodeData.data.id === 0 ? this.props.land.MainTaalcodeData.data : null, 
            factuuropbouwdata: this.props.land && this.props.land.FactuuropbouwData && this.props.land.FactuuropbouwData.data && this.props.land.FactuuropbouwData.data.id === 0 ? this.props.land.FactuuropbouwData.data : null,
            landendata: this.props.land && this.props.land.AllData && this.props.land.AllData.data ? this.props.land.AllData.data : null,
            jobdefsdata: this.props.jobs && this.props.jobs.AllJobDefMainData && this.props.jobs.AllJobDefMainData.data ? this.props.jobs.AllJobDefMainData.data : null,
        },
        speedactions: [
            { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
            { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe taalcode' },
            { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
        ],
        speedopen: false,
        loadededit: false,
        checksave: false,
        updatecomponent: true,
    };

    constructor(props, context) {
        super(props);
        this.resizeFunction = this.resizeFunction.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this)
        this.ValueCheck = this.ValueCheck.bind(this);
        this.handleSpeedClose = this.handleSpeedClose.bind(this);
        this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
        this.ZetEdit = this.ZetEdit.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.MainSave = this.MainSave.bind(this);
        this.checkconfirm = this.checkconfirm.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.handleJa = this.handleJa.bind(this);
        this.ActionClick = this.ActionClick.bind(this);
        this.Factuuropbouw = this.Factuuropbouw.bind(this);
        this.FactuuropbouwEdit = this.FactuuropbouwEdit.bind(this);
        this.Landen = this.Landen.bind(this);
        this.LandenEdit = this.LandenEdit.bind(this);
        this.jobDefs = this.jobDefs.bind(this);
        this.jobDefsEdit = this.jobDefsEdit.bind(this);
        this.DetectKeypress = this.DetectKeypress.bind(this);
        this.DetectMousemove = this.DetectMousemove.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
        this.props.dispatch(setTitleRight('Taalcodes'))
        this.props.dispatch(setSubTitle('Taalcode'))
        this.props.dispatch(setPanelRight(true))
        document.addEventListener("keydown", this.DetectKeypress, false);
        document.addEventListener("mousemove", this.DetectMousemove, false);
    }

    componentWillMount(){
        if(this.props.land.MainTaalcodeData.data.id === 0){
            let data = this.state.data;
            data.taalcodedata = Object.assign({}, this.props.land.MainTaalcodeData.data);
            data.factuuropbouwdata = Object.assign({}, this.props.land.FactuuropbouwData.data);
            data.landendata = Object.assign({}, this.props.land.AllData.data);
            data.jobdefsdata = Object.assign({}, this.props.jobs.AllJobDefMainData.data);
            for (let i = 0; i < this.props.land.AllData.data.length; i++) {
                data.landendata[i]["new"] = "";
            }
            for (let i = 0; i < this.props.jobs.AllJobDefMainData.data.length; i++) {
                data.jobdefsdata[i]["new"] = "";
            }
            this.setState({ data: data })
        }
    }

    componentWillUnmount() {
        this.checkconfirm(this.props.land.MainTaalcodeData);
        this.props.dispatch(setPanelRight(false))
        this.props.dispatch(setTitleRight(''))
        window.removeEventListener("resize", this.resizeFunction);
        document.removeEventListener("keydown", this.DetectKeypress, false);
        document.removeEventListener("mousemove", this.DetectMousemove, false);
    }

    //Stop updaten/valuechecken bij openen nieuwe taalcode want alles is leeg
    componentDidUpdate() {
        if (this.state.loadededit) {
            this.setState({loadededit: false});
        }
    }

    //Stop updaten bij typen, vermijden van trage input
    shouldComponentUpdate(nextProps, nextState){
        if(this.state.updatecomponent){
            return true;
        } else {
            return false;
        }
    }

    DetectKeypress(event){
        if(event.keyCode === 9){
            this.setState({ updatecomponent: true })
        } else {
            this.setState({ updatecomponent: false })
        }
    }

    DetectMousemove(event){
        if(!this.state.updatecomponent){
            this.setState({ updatecomponent: true })
        }
    }

    resizeFunction() {
        if (window.innerHeight >= 960) {
            this.setState({ tableheight: Math.floor(window.innerHeight * .70) + 'px' });
        }
        else {
            this.setState({ tableheight: '600px' });
        }
    }


    getContextMenuItems(params, sleutel) {
        return null;
    }

    async ValueCheck(that, waarde, type, colId) {
        if (this.state.loadededit) {
            this.setState({ save: true })
            return;
        }
        let data = this.state.data;
        let taalcodedata = data.taalcodedata;
        let factuuropbouwdata = data.factuuropbouwdata;
        let landendata = data.landendata;
        let jobdefsdata = data.jobdefsdata;
        let terug = false;

        let split = colId.split("_");
        let datatype = split[0];
        let col = split[1];
        let index = split[2];

        switch(datatype){
            case 'factuuropbouw':
                factuuropbouwdata[col] = waarde;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.land.FactuuropbouwData.data, factuuropbouwdata);
                break;

            case 'taalcode':
                taalcodedata[col] = waarde;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.land.MainTaalcodeData.data, taalcodedata);
                break;

            case 'landen':
                if(this.props.land.MainTaalcodeData.data.id !== 0){
                    landendata[parseInt(index)][(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] = waarde;
                } else {
                    landendata[parseInt(index)].new = waarde;
                }
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.land.AllData.data, landendata);
                break;

            case 'jobdefs':
                if(this.props.land.MainTaalcodeData.data.id !== 0){
                    jobdefsdata[parseInt(index)][(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] = waarde;
                } else {
                    jobdefsdata[parseInt(index)].new = waarde;
                }
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.jobs.AllJobDefMainData.data, jobdefsdata);
                break;

            default:
                return;
        }

        that.setState({ error: terug })

        data.factuuropbouwdata = factuuropbouwdata;
        data.taalcodedata = taalcodedata;
        data.landendata = landendata;
        data.jobdefsdata = jobdefsdata;
        
        if(this.state.checksave){
            terug = await this.CheckSave(data);
        } else {
            terug = false;
            if(col === this.props.land.AllData.data[this.props.land.AllData.data.length-1].name){
                this.setState({ checksave: true });
            }
        }
        this.setState({ save: terug, data: data, checkval: false });
    } 

    handleSpeedClose() {
        this.setState({ speedopen: false });
    };

    handleSpeedOpen() {
        this.setState({ speedopen: true });
    };

    async ZetEdit() {
        let data = this.state.data;
        data.taalcodedata = Object.assign({}, this.props.land.MainTaalcodeData.data);
        data.factuuropbouwdata = Object.assign({}, this.props.land.FactuuropbouwData.data);
        data.landendata = Object.assign({}, this.props.land.AllData.data);
        data.jobdefsdata = Object.assign({}, this.props.jobs.AllJobDefMainData.data);
        this.setState({ edit: true, data: data, loadededit: true, checksave: true })
    }

    CheckSave(data) {
        let terug = true
        terug = Validation.ValidCheck(this.props.settings.sub_title, data.taalcodedata.omschrijving, 'taalcode_omschrijving', this, this.props.land.MainTaalcodeData.data, data.taalcodedata);
        if(!terug){
            for(let i = 0; i < Object.keys(data.jobdefsdata).length; i++){
                let colId = "jobdefs_" + data.jobdefsdata[i].description + "_" + i;
                if(!terug){
                    if(this.props.land.MainTaalcodeData.data.id !== 0){
                        terug = Validation.ValidCheck(this.props.settings.sub_title, data.jobdefsdata[i][(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()], colId, this, this.props.jobs.AllJobDefMainData.data, data.jobdefsdata);
                    } else {
                        terug = Validation.ValidCheck(this.props.settings.sub_title, data.jobdefsdata[i].new, colId, this, this.props.jobs.AllJobDefMainData.data, data.jobdefsdata);
                    }

                }
            }
        }
        if(!terug){
            for(let i = 0; i < Object.keys(data.landendata).length; i++){
                let colId = "landen_" + data.landendata[i].name + "_" + i;
                if(!terug){
                    if(this.props.land.MainTaalcodeData.data.id !== 0){
                        terug = Validation.ValidCheck(this.props.settings.sub_title, data.landendata[i][(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()], colId, this, this.props.land.AllData.data, data.landendata);
                    } else {
                        terug = Validation.ValidCheck(this.props.settings.sub_title, data.landendata[i].new, colId, this, this.props.land.AllData.data, data.landendata);
                    }

                }
            }
        }
        if(!terug){
            let factarray = Object.keys(data.factuuropbouwdata);
            factarray.splice(0, 2);
            for(let i = 0; i < factarray.length; i++){
                let colId = "factuuropbouw_" + factarray[i];
                if(!terug){
                    terug = Validation.ValidCheck(this.props.settings.sub_title, data.factuuropbouwdata[factarray[i]], colId, this, this.props.land.FactuuropbouwData.data, data.factuuropbouwdata);
                }
            }
        }
        
        return !terug;
    }

    async handleTabChange(event, newValue) {
        this.setState({ tabvalue: newValue });
    };

    async MainSave() {
        if (this.state.save === true) {
            //Opslaan gegevens bestaande taalcode
            if(this.props.land.MainTaalcodeData.data.id !== 0){
                let rettaalcodedata = await SqlFunction.DoSql('ChgTaalcodes', this.state.data.taalcodedata);
                let columndata = {
                    oldname: (this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase(),
                    newname: (this.state.data.taalcodedata.omschrijving).toLowerCase()
                }
                await SqlFunction.DoSql('ChgLandenColumnName', columndata);
                await SqlFunction.DoSql('ChgJobDefMainColumnName', columndata);
                let retfactuuropbouwdata = await SqlFunction.DoSql('ChgFactuuropbouw', this.state.data.factuuropbouwdata);
                let updatelandenrows = [];
                for(let i = 0; i < this.props.land.AllData.data.length; i++){
                    let data = { id: this.state.data.landendata[i].id };
                    data[(this.state.data.taalcodedata.omschrijving).toLowerCase()] = this.state.data.landendata[i][(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]
                    updatelandenrows.push(data);
                }
                let updatelandencolumndata = {
                    column_name: (this.state.data.taalcodedata.omschrijving).toLowerCase(),
                    rowdata: updatelandenrows
                }
                let retlandendata = await SqlFunction.DoSql('ChgLandenColumn', updatelandencolumndata);

                let updateJobDefsRows = [];
                for(let i = 0; i < this.props.jobs.AllJobDefMainData.data.length; i++){
                    let data = { id: this.state.data.jobdefsdata[i].id };
                    data[(this.state.data.taalcodedata.omschrijving).toLowerCase()] = this.state.data.jobdefsdata[i][(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]
                    updateJobDefsRows.push(data);
                }
                let updateJobDefsColumnData = {
                    column_name: (this.state.data.taalcodedata.omschrijving).toLowerCase(),
                    rowdata: updateJobDefsRows
                }
                let retjobdefsdata = await SqlFunction.DoSql('ChgJobDefMainColumn', updateJobDefsColumnData);
    
                let data = this.state.data;
                data.taalcodedata = null;
                data.factuuropbouwdata = null;
                data.landendata = null;
                data.jobdefsdata = null;
                this.setState({ edit: false, save: false, data: data, checkval: false })
                if (rettaalcodedata && retfactuuropbouwdata && retlandendata && retjobdefsdata) {
                    await this.props.dispatch(GetTaalcode(rettaalcodedata.id));
                    await this.props.dispatch(GetFactuuropbouw(rettaalcodedata.id));
                    await this.props.dispatch(AllLanden(this.props));
                    await this.props.dispatch(AllJobDefMain(this.props));
                }
            } else {
                //Opslaan gegevens nieuwe taalcode
                //Add new column
                let addcolumn = { colname: (this.state.data.taalcodedata.omschrijving).toLowerCase() }
                let retaddlandencoldata = await SqlFunction.DoSql('AddLandenColumn', addcolumn);
                let retaddjobdefcoldata = await SqlFunction.DoSql('AddJobDefMainColumn', addcolumn);

                //Update column in landen
                let updatelandenrows = [];
                for(let i = 0; i < this.props.land.AllData.data.length; i++){
                    let data = { id: this.state.data.landendata[i].id };
                    data[retaddlandencoldata.colname] = this.state.data.landendata[i].new;
                    updatelandenrows.push(data);
                }
                let updatelandencolumndata = {
                    column_name: retaddlandencoldata.colname,
                    rowdata: updatelandenrows
                }
                let retlandendata = await SqlFunction.DoSql('ChgLandenColumn', updatelandencolumndata);

                //Update column in jobdef_main
                let updateJobDefRows = [];
                for(let i = 0; i < this.props.jobs.AllJobDefMainData.data.length; i++){
                    let data = { id: this.state.data.jobdefsdata[i].id };
                    data[retaddjobdefcoldata.colname] = this.state.data.jobdefsdata[i].new;
                    updateJobDefRows.push(data);
                }
                let updateJobDefColumnData = {
                    column_name: retaddjobdefcoldata.colname,
                    rowdata: updateJobDefRows
                }
                let retjobdefdata = await SqlFunction.DoSql('ChgJobDefMainColumn', updateJobDefColumnData);

                //Update taalcodes tabel
                let rettaalcodedata = await SqlFunction.DoSql('ChgTaalcodes', this.state.data.taalcodedata);

                //Update factuuropbouw table
                this.state.data.factuuropbouwdata.taalcode = rettaalcodedata.id
                let retfactuuropbouwdata = await SqlFunction.DoSql('ChgFactuuropbouw', this.state.data.factuuropbouwdata);

                let data = this.state.data;
                data.taalcodedata = null;
                data.factuuropbouwdata = null;
                data.landendata = null;
                data.jobdefsdata = null;
                this.setState({ edit: false, save: false, data: data, checkval: false })
                if (rettaalcodedata && retfactuuropbouwdata && retlandendata && retjobdefdata) {
                    await this.props.dispatch(GetTaalcode(rettaalcodedata.id));
                    await this.props.dispatch(GetFactuuropbouw(rettaalcodedata.id));
                    await this.props.dispatch(AllLanden(this.props));
                    await this.props.dispatch(AllJobDefMain(this.props));
                }
            }
        }
    }

    checkconfirm(parameters) {
        if (this.state.save === true || this.state.subsave === true) {
            confirmAlert({
                closeOnEscape: false,
                closeOnClickOutside: false,
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Aanpassingen niet doorgevoerd.</h1>
                            <p>Wens je de aanpassingen alsnog te bewaren?</p>
                            <button
                                onClick={() => {
                                    this.handleJa(parameters);
                                    onClose();
                                }}
                            >
                                Ja
                            </button>
                            <button onClick={() => {
                                this.setState({ edit: false, save: false, subsave: false, checkval: false })
                                onClose();
                            }}
                            >
                                Nee
                            </button>
                        </div>
                    );
                }
            });
        }

    }

    async componentWillReceiveProps(nextProps, nextState) {
        if (this.props.land.MainTaalcodeData) {
            if (this.props.land.MainTaalcodeData.data.id !== nextProps.land.MainTaalcodeData.data.id) {
                await this.checkconfirm(this.props.land.MainTaalcodeData);
                if (nextProps.land.MainTaalcodeData.data.id === 0) {
                    let data = this.state.data;
                    data.taalcodedata = Object.assign({}, this.props.land.MainTaalcodeData.data);
                    data.factuuropbouwdata = Object.assign({}, this.props.land.FactuuropbouwData.data);
                    data.landendata = Object.assign({}, this.props.land.AllData.data);
                    for(let i = 0; i < this.props.land.AllData.data.length; i++){
                        data.landendata[i]["new"] = "";
                    }
                    this.setState({ edit: true, data: data })
                }
                else {
                    this.setState({ edit: false, save: false, subsave: false })
                    this.setState({ tabvalue: '1' });
                }
            }
        }
    }

    async handleJa(parameters) {    
        if (this.state.save === true) {
            //Opslaan gegevens bestaande taalcode
            if(this.props.land.MainTaalcodeData.data.id !== 0){
                let rettaalcodedata = await SqlFunction.DoSql('ChgTaalcodes', this.state.data.taalcodedata);
                let columndata = {
                    oldname: (this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase(),
                    newname: (this.state.data.taalcodedata.omschrijving).toLowerCase()
                }
                await SqlFunction.DoSql('ChgLandenColumnName', columndata);
                await SqlFunction.DoSql('ChgJobDefMainColumnName', columndata);
                let retfactuuropbouwdata = await SqlFunction.DoSql('ChgFactuuropbouw', this.state.data.factuuropbouwdata);
                let updatelandenrows = [];
                for(let i = 0; i < this.props.land.AllData.data.length; i++){
                    let data = { id: this.state.data.landendata[i].id };
                    data[(this.state.data.taalcodedata.omschrijving).toLowerCase()] = this.state.data.landendata[i][(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]
                    updatelandenrows.push(data);
                }
                let updatelandencolumndata = {
                    column_name: (this.state.data.taalcodedata.omschrijving).toLowerCase(),
                    rowdata: updatelandenrows
                }
                let retlandendata = await SqlFunction.DoSql('ChgLandenColumn', updatelandencolumndata);

                let updateJobDefsRows = [];
                for(let i = 0; i < this.props.jobs.AllJobDefMainData.data.length; i++){
                    let data = { id: this.state.data.jobdefsdata[i].id };
                    data[(this.state.data.taalcodedata.omschrijving).toLowerCase()] = this.state.data.jobdefsdata[i][(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]
                    updateJobDefsRows.push(data);
                }
                let updateJobDefsColumnData = {
                    column_name: (this.state.data.taalcodedata.omschrijving).toLowerCase(),
                    rowdata: updateJobDefsRows
                }
                let retjobdefsdata = await SqlFunction.DoSql('ChgJobDefMainColumn', updateJobDefsColumnData);
    
                let data = this.state.data;
                data.taalcodedata = null;
                data.factuuropbouwdata = null;
                data.landendata = null;
                data.jobdefsdata = null;
                this.setState({ edit: false, save: false, data: data, checkval: false })
                if (rettaalcodedata && retfactuuropbouwdata && retlandendata && retjobdefsdata) {
                    await this.props.dispatch(GetTaalcode(rettaalcodedata.id));
                    await this.props.dispatch(GetFactuuropbouw(rettaalcodedata.id));
                    await this.props.dispatch(AllLanden(this.props));
                    await this.props.dispatch(AllJobDefMain(this.props));
                }
            } else {
                //Opslaan gegevens nieuwe taalcode
                //Add new column
                let addcolumn = { colname: (this.state.data.taalcodedata.omschrijving).toLowerCase() }
                let retaddlandencoldata = await SqlFunction.DoSql('AddLandenColumn', addcolumn);
                let retaddjobdefcoldata = await SqlFunction.DoSql('AddJobDefMainColumn', addcolumn);

                //Update column in landen
                let updatelandenrows = [];
                for(let i = 0; i < this.props.land.AllData.data.length; i++){
                    let data = { id: this.state.data.landendata[i].id };
                    data[retaddlandencoldata.colname] = this.state.data.landendata[i].new;
                    updatelandenrows.push(data);
                }
                let updatelandencolumndata = {
                    column_name: retaddlandencoldata.colname,
                    rowdata: updatelandenrows
                }
                let retlandendata = await SqlFunction.DoSql('ChgLandenColumn', updatelandencolumndata);

                //Update column in jobdef_main
                let updateJobDefRows = [];
                for(let i = 0; i < this.props.jobs.AllJobDefMainData.data.length; i++){
                    let data = { id: this.state.data.jobdefsdata[i].id };
                    data[retaddjobdefcoldata.colname] = this.state.data.jobdefsdata[i].new;
                    updateJobDefRows.push(data);
                }
                let updateJobDefColumnData = {
                    column_name: retaddjobdefcoldata.colname,
                    rowdata: updateJobDefRows
                }
                let retjobdefdata = await SqlFunction.DoSql('ChgJobDefMainColumn', updateJobDefColumnData);

                //Update taalcodes tabel
                let rettaalcodedata = await SqlFunction.DoSql('ChgTaalcodes', this.state.data.taalcodedata);

                //Update factuuropbouw table
                this.state.data.factuuropbouwdata.taalcode = rettaalcodedata.id
                let retfactuuropbouwdata = await SqlFunction.DoSql('ChgFactuuropbouw', this.state.data.factuuropbouwdata);

                let data = this.state.data;
                data.taalcodedata = null;
                data.factuuropbouwdata = null;
                data.landendata = null;
                data.jobdefsdata = null;
                this.setState({ edit: false, save: false, data: data, checkval: false })
                if (rettaalcodedata && retfactuuropbouwdata && retlandendata && retjobdefdata) {
                    await this.props.dispatch(GetTaalcode(rettaalcodedata.id));
                    await this.props.dispatch(GetFactuuropbouw(rettaalcodedata.id));
                    await this.props.dispatch(AllLanden(this.props));
                    await this.props.dispatch(AllJobDefMain(this.props));
                }
            }
        }
        if (this.state.subsave === true) {
        }
    }

    async ActionClick(name) {
        switch (name) {
            case 'Edit':
                let data = this.state.data;
                data.taalcodedata = Object.assign({}, this.props.land.MainTaalcodeData.data);
                data.factuuropbouwdata = Object.assign({}, this.props.land.FactuuropbouwData.data);
                data.landendata = Object.assign({}, this.props.land.AllData.data);
                this.setState({ edit: true, data: data, loadededit: true, checksave: true, speedopen: false })
                break;

            case 'New':
                this.setState({ checksave: false});
                await this.props.dispatch(CreateTaalcode());
                await this.props.dispatch(CreateFactuuropbouw());
                let newdata = this.state.data;
                newdata.taalcodedata = Object.assign({}, this.props.land.MainTaalcodeData.data);
                newdata.factuuropbouwdata = Object.assign({}, this.props.land.FactuuropbouwData.data);
                newdata.landendata = Object.assign({}, this.props.land.AllData.data);
                for(let i = 0; i < this.props.land.AllData.data.length; i++){
                    newdata.landendata[i]["new"] = "";
                }
                this.setState({ edit: true, data: newdata, loadededit: true, speedopen: false });
                break;

            case 'Save':
                this.MainSave();
                break;

            default:
                break;
        }
        this.setState({ speedopen: false });
    }

    Factuuropbouw(){
        let factuuropbouw = [];

        factuuropbouw.push(
            <div style={{ marginTop: "25px" }} key={"factuuropbouwdiv"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "35%" }}>
                        <h5 style={{ cursor: "pointer", marginBottom: "-1px" }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">Hoofding</h5>
                        <small className="text-muted p-t-30 db">Factuur</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.factuur}</h6>
                        <small className="text-muted p-t-30 db">Creditnota</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.creditnota}</h6>
                        <small className="text-muted p-t-30 db">Factuurnummer</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.factnr}</h6>
                        <small className="text-muted p-t-30 db">Klantnummer</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.klantnr}</h6>
                        <small className="text-muted p-t-30 db">Datum</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.factdate}</h6>
                        <small className="text-muted p-t-30 db">Vervaldatum</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.factenddate}</h6>
                        <small className="text-muted p-t-30 db">Omschrijving</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.description}</h6>
                        <small className="text-muted p-t-30 db">Totaal excl.</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.totaalexcl}</h6>

                        <h5 style={{ cursor: "pointer", marginTop: "5px", marginBottom: "-1px" }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">Betalingsvoorwaarde/BTW vermelding</h5>
                        <small className="text-muted p-t-30 db">Te vermelden bij betaling</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.vermelding}</h6>
                        <small className="text-muted p-t-30 db">Betaling binnen de 30 dagen na factuurdatum</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.betalingfactdate}</h6>
                        <small className="text-muted p-t-30 db">Domiciliëring</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.domiciliering}</h6>
                        <small className="text-muted p-t-30 db">BTW verlegd</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.btwverlegd}</h6>
                        <small className="text-muted p-t-30 db">Export</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.export}</h6>

                        <h5 style={{ cursor: "pointer", marginTop: "5px", marginBottom: "-1px" }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">Betreft</h5>
                        <small className="text-muted p-t-30 db">Ereloon voor de geleverde prestaties</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.prestaties}</h6>
                        
                    </div>
                    <div style={{ width: "15%" }}></div>
                    <div style={{ width: "35%" }}>
                        <small className="text-muted p-t-30 db">Klant</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.klant}</h6>
                        <small className="text-muted p-t-30 db">Extra jobs</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.extra}</h6>

                        <h5 style={{ cursor: "pointer", marginBottom: "-1px" }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">Inhoud</h5>
                        <small className="text-muted p-t-30 db">Land</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.land}</h6>
                        <small className="text-muted p-t-30 db">Registratienummer</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.regnr}</h6>
                        <small className="text-muted p-t-30 db">Periode</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.periode}</h6>
                        <small className="text-muted p-t-30 db">Aangifte BTW</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.aangiftebtw}</h6>
                        <small className="text-muted p-t-30 db">Ereloon</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.ereloon}</h6>
                        <small className="text-muted p-t-30 db">Teruggave</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.teruggave}</h6>
                        <small className="text-muted p-t-30 db">Koers</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.koers}</h6>
                        <small className="text-muted p-t-30 db">Bedrag in €</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.bedrageuro}</h6>
                        <small className="text-muted p-t-30 db">Nihil</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.nihil}</h6>

                        <h5 style={{ cursor: "pointer", marginTop: "5px", marginBottom: "-1px" }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">Footer</h5>
                        <small className="text-muted p-t-30 db">Totaal ereloon</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.totaalereloon}</h6>
                        <small className="text-muted p-t-30 db">BTW</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.btw}</h6>
                        <small className="text-muted p-t-30 db">Te betalen</small>
                        <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{this.props.land.FactuuropbouwData.data.betalen}</h6>
                    </div>
                </div>
            </div>
        );

        return factuuropbouw;
    }

    FactuuropbouwEdit() {
        let factuuropbouwedit = [];

        factuuropbouwedit.push(
            <div style={{ marginTop: "25px" }} key={"factuuropbouweditdiv"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "35%" }}>
                        <h5 style={{ marginBottom: "-1px" }} className="card-title m-t-10">Hoofding</h5>
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.factuur}
                            coltype='STR'
                            labelText={"Factuur"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_factuur')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.creditnota}
                            coltype='STR'
                            labelText={"Creditnota"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_creditnota')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.factnr}
                            coltype='STR'
                            labelText={"Factuurnummer"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_factnr')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.klantnr}
                            coltype='STR'
                            labelText={"Klantnummer"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_klantnr')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.factdate}
                            coltype='STR'
                            labelText={"Datum"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_factdate')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.factenddate}
                            coltype='STR'
                            labelText={"Vervaldatum"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_factenddate')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.description}
                            coltype='STR'
                            labelText={"Omschrijving"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_description')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.totaalexcl}
                            coltype='STR'
                            labelText={"Totaal excl"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_totaalexcl')}
                        />

                        <h5 style={{ marginBottom: "-1px", marginTop: "5px" }} className="card-title m-t-10">Betalingsvoorwaarde/BTW vermelding</h5>
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.vermelding}
                            coltype='STR'
                            labelText={"Te vermelden bij betaling"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_vermelding')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.betalingfactdate}
                            coltype='STR'
                            labelText={"Betaling binnen de 30 dagen na factuurdatum"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_betalingfactdate')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.domiciliering}
                            coltype='STR'
                            labelText={"Domiciliëring"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_domiciliering')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.btwverlegd}
                            coltype='STR'
                            labelText={"BTW verlegd"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_btwverlegd')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.export}
                            coltype='STR'
                            labelText={"Export"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_export')}
                        />

                        <h5 style={{ marginBottom: "-1px", marginTop: "5px" }} className="card-title m-t-10">Betreft</h5>
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.prestaties}
                            coltype='STR'
                            labelText={"Ereloon voor de geleverde prestaties"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_prestaties')}
                        />
                    </div>
                    <div style={{ width: "15%" }}></div>
                    <div style={{ width: "35%" }}>
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.klant}
                            coltype='STR'
                            labelText={"Klant"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_klant')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.extra}
                            coltype='STR'
                            labelText={"Extra jobs"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_extra')}
                        />
                        
                        <h5 style={{ marginBottom: "-1px" }} className="card-title m-t-10">Inhoud</h5>
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.land}
                            coltype='STR'
                            labelText={"Land"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_land')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.regnr}
                            coltype='STR'
                            labelText={"Registratienummer"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_regnr')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.periode}
                            coltype='STR'
                            labelText={"Periode"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_periode')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.aangiftebtw}
                            coltype='STR'
                            labelText={"Aangifte BTW"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_aangiftebtw')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.ereloon}
                            coltype='STR'
                            labelText={"Ereloon"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_ereloon')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.teruggave}
                            coltype='STR'
                            labelText={"Teruggave"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_teruggave')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.koers}
                            coltype='STR'
                            labelText={"Koers"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_koers')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.bedrageuro}
                            coltype='STR'
                            labelText={"Bedrag in €"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_bedrageuro')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.nihil}
                            coltype='STR'
                            labelText={"Nihil"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_nihil')}
                        />
                        
                        <h5 style={{ marginBottom: "-1px", marginTop: "5px" }} className="card-title m-t-10">Footer</h5>
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.totaalereloon}
                            coltype='STR'
                            labelText={"Totaal ereloon"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_totaalereloon')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.btw}
                            coltype='STR'
                            labelText={"BTW"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_btw')}
                        />
                        <EditString className="card-title m-t-10"
                            value={this.state.data.factuuropbouwdata.betalen}
                            coltype='STR'
                            labelText={"Te betalen"}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'factuuropbouw_betalen')}
                        />
                    </div>
                </div>
            </div>
        );

        return factuuropbouwedit;
    }

    Landen(){
        let landen = [];
        let landenleft = [];
        let landenright = [];

        if(this.props.land.AllData.data.length % 2 === 0){
            for(let i = 0; i < this.props.land.AllData.data.length; i++){
                let omschrijving = this.props.land.AllData.data[i];
                if(i < this.props.land.AllData.data.length/2){
                    landenleft.push(
                        <div key={"evenlandenleftdiv_" + i}>
                            <small className="text-muted p-t-30 db">{this.props.land.AllData.data[i].name}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                } else {
                    landenright.push(
                        <div key={"evenlandenrightdiv_" + i}>
                            <small className="text-muted p-t-30 db">{this.props.land.AllData.data[i].name}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                }
            }
        } else {
            for(let i = 0; i < this.props.land.AllData.data.length; i++){
                let omschrijving = this.props.land.AllData.data[i];
                if(i <= this.props.land.AllData.data.length/2){
                    landenleft.push(
                        <div key={"onevenlandenleftdiv_" + i}>
                            <small className="text-muted p-t-30 db">{this.props.land.AllData.data[i].name}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                } else {
                    landenright.push(
                        <div key={"onevenlandenrightdiv_" + i}>
                            <small className="text-muted p-t-30 db">{this.props.land.AllData.data[i].name}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                }
            }
        }

        landen.push(
            <div style={{ marginTop: "25px" }} key={"landendiv"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "35%" }}>
                        {landenleft}
                    </div>
                    <div style={{ width: "15%" }}></div>
                    <div style={{ width: "35%" }}>
                        {landenright}
                    </div>
                </div>
            </div>
        );

        return landen;
    }

    LandenEdit(){
        let landenedit = [];
        let landenleft = [];
        let landenright = [];

        if(this.props.land.AllData.data.length % 2 === 0){
            for(let i = 0; i < this.props.land.AllData.data.length; i++){
                let omschrijving = this.state.data.landendata[i];
                if(i < this.props.land.AllData.data.length/2){
                    landenleft.push(
                        <EditString className="card-title m-t-10"
                            value={omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] || omschrijving.new}
                            coltype='STR'
                            labelText={this.props.land.AllData.data[i].name}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "landen_" + this.props.land.AllData.data[i].name + "_" + i)}
                        />
                    );
                } else {
                    landenright.push(
                        <EditString className="card-title m-t-10"
                            value={omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] || omschrijving.new}
                            coltype='STR'
                            labelText={this.props.land.AllData.data[i].name}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "landen_" + this.props.land.AllData.data[i].name + "_" + i)}
                        />
                    );
                }
            }
        } else if(this.props.land.AllData.data.length % 2 === 1){
            for(let i = 0; i < this.props.land.AllData.data.length; i++){
                let omschrijving = this.state.data.landendata[i];
                if(i <= this.props.land.AllData.data.length/2){
                    landenleft.push(
                        <EditString className="card-title m-t-10"
                            value={omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] || omschrijving.new}
                            coltype='STR'
                            labelText={this.props.land.AllData.data[i].name}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "landen_" + this.props.land.AllData.data[i].name + "_" + i)}
                        />
                    );
                } else {
                    landenright.push(
                        <EditString className="card-title m-t-10"
                            value={omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] || omschrijving.new}
                            coltype='STR'
                            labelText={this.props.land.AllData.data[i].name}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "landen_" + this.props.land.AllData.data[i].name + "_" + i)}
                        />
                    );
                }
            }
        }

        landenedit.push(
            <div style={{ marginTop: "25px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "35%" }}>
                        {landenleft}
                    </div>
                    <div style={{ width: "15%" }}></div>
                    <div style={{ width: "35%" }}>
                        {landenright}
                    </div>
                </div>
            </div>
        );

        return landenedit;
    }

    jobDefs() {
        let jobDefs = [];
        let jobDefsLeft = [];
        let jobDefsRight = [];

        if (this.props.jobs && this.props.jobs.AllJobDefMainData && this.props.jobs.AllJobDefMainData.data && this.props.jobs.AllJobDefMainData.data.length % 2 === 0) {
            for (let i = 0; i < this.props.jobs.AllJobDefMainData.data.length; i++) {
                let omschrijving = this.props.jobs.AllJobDefMainData.data[i];
                if (i < this.props.jobs.AllJobDefMainData.data.length / 2) {
                    jobDefsLeft.push(
                        <div key={"evenjobdefsleftdiv_" + i}>
                            <small className="text-muted p-t-30 db">{this.props.jobs.AllJobDefMainData.data[i].description}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                } else {
                    jobDefsRight.push(
                        <div key={"evenjobdefsrightdiv_" + i}>
                            <small className="text-muted p-t-30 db">{this.props.jobs.AllJobDefMainData.data[i].description}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                }
            }
        } else {
            for (let i = 0; i < this.props.jobs.AllJobDefMainData.data.length; i++) {
                let omschrijving = this.props.jobs.AllJobDefMainData.data[i];
                if (i <= this.props.jobs.AllJobDefMainData.data.length / 2) {
                    jobDefsLeft.push(
                        <div key={"onevenjobdefsleftdiv_" + i}>
                            <small className="text-muted p-t-30 db">{this.props.jobs.AllJobDefMainData.data[i].description}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                } else {
                    jobDefsRight.push(
                        <div key={"onevenjobdefsrightdiv_" + i}>
                            <small className="text-muted p-t-30 db">{this.props.jobs.AllJobDefMainData.data[i].description}</small>
                            <h6 style={{ cursor: "pointer" }} onClick={() => { this.ZetEdit() }}>{omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()]}</h6>
                        </div>
                    );
                }
            }
        }

        jobDefs.push(
            <div style={{ marginTop: "25px" }} key={"jobdefsdiv"}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "35%" }}>
                        {jobDefsLeft}
                    </div>
                    <div style={{ width: "15%" }}></div>
                    <div style={{ width: "35%" }}>
                        {jobDefsRight}
                    </div>
                </div>
            </div>
        );

        return jobDefs;
    }

    jobDefsEdit() {
        let jobDefsEdit = [];
        let jobDefsEditLeft = [];
        let jobDefsEditRight = [];

        if (this.props.jobs && this.props.jobs.AllJobDefMainData && this.props.jobs.AllJobDefMainData.data && this.props.jobs.AllJobDefMainData.data.length % 2 === 0) {
            for (let i = 0; i < this.props.jobs.AllJobDefMainData.data.length; i++) {
                let omschrijving = this.state.data.jobdefsdata[i];
                if (i < this.props.jobs.AllJobDefMainData.data.length / 2) {
                    jobDefsEditLeft.push(
                        <EditString className="card-title m-t-10"
                            value={omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] || omschrijving.new}
                            coltype='STR'
                            labelText={this.props.jobs.AllJobDefMainData.data[i].description}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "jobdefs_" + this.props.jobs.AllJobDefMainData.data[i].code + "_" + i)}
                        />
                    );
                } else {
                    jobDefsEditRight.push(
                        <EditString className="card-title m-t-10"
                            value={omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] || omschrijving.new}
                            coltype='STR'
                            labelText={this.props.jobs.AllJobDefMainData.data[i].description}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "jobdefs_" + this.props.jobs.AllJobDefMainData.data[i].code + "_" + i)}
                        />
                    );
                }
            }
        } else if (this.props.jobs.AllJobDefMainData.data.length % 2 === 1) {
            for (let i = 0; i < this.props.jobs.AllJobDefMainData.data.length; i++) {
                let omschrijving = this.state.data.jobdefsdata[i];
                if (i <= this.props.jobs.AllJobDefMainData.data.length / 2) {
                    jobDefsEditLeft.push(
                        <EditString className="card-title m-t-10"
                            value={omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] || omschrijving.new}
                            coltype='STR'
                            labelText={this.props.jobs.AllJobDefMainData.data[i].description}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "jobdefs_" + this.props.jobs.AllJobDefMainData.data[i].code + "_" + i)}
                        />
                    );
                } else {
                    jobDefsEditRight.push(
                        <EditString className="card-title m-t-10"
                            value={omschrijving[(this.props.land.MainTaalcodeData.data.omschrijving).toLowerCase()] || omschrijving.new}
                            coltype='STR'
                            labelText={this.props.jobs.AllJobDefMainData.data[i].description}
                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, "jobdefs_" + this.props.jobs.AllJobDefMainData.data[i].code + "_" + i)}
                        />
                    );
                }
            }
        }

        jobDefsEdit.push(
            <div style={{ marginTop: "25px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ width: "35%" }}>
                        {jobDefsEditLeft}
                    </div>
                    <div style={{ width: "15%" }}></div>
                    <div style={{ width: "35%" }}>
                        {jobDefsEditRight}
                    </div>
                </div>
            </div>
        );

        return jobDefsEdit;
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Toolbar style={{ background: '#fff', padding: '14px 10px', boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px' }}>
                    <Grid container>
                        <Grid item xs={8}>
                            <h4 tag="h4" className="mt-3" onClick={() => { this.setState({ edit: false }) }}>{this.props.settings.sub_title}</h4>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        icon={<SpeedDialIcon style={{ zIndex: '10' }} />}
                        direction="left"
                        classes={{ root: classes.speedDial, fab: classes.speedFabRoot }}
                        onClose={this.handleSpeedClose}
                        onOpen={this.handleSpeedOpen}
                        open={this.state.speedopen}
                    >
                        {this.state.speedactions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipPlacement='bottom'
                                FabProps={{
                                    disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? false :
                                    (action.name === 'Edit' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                    (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? true :
                                    (action.name === 'New' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? false :
                                    (action.name === 'New' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                    (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? true :
                                    (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === true) ? false :
                                    (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === false) ? true :
                                    (action.name === 'Save' && this.state.edit === false && this.state.editfact === false) ? true :
                                    (action.name === 'Save' && this.state.save === false) ? true : false
                                }}
                                onClick={() => { this.ActionClick(action.name) }}
                                tooltipTitle={action.tooltip}
                            />
                        ))}
                    </SpeedDial>
                </Toolbar>
                {this.props.land && this.props.land.MainTaalcodeData && this.props.land.MainTaalcodeData.data && 
                    <Grid  container style={{width: 'auto', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Card className='card-brut'>
                                    {this.state.edit === false &&
                                        <PerfectScrollbar className="sidebar-nav">
                                            <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                                <h4 style={{ marginTop: '25px', marginBottom: '50px', cursor: 'pointer' }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">{this.props.land.MainTaalcodeData.data.omschrijving}</h4>

                                                <div className='div_line' style={{ marginBottom: '25px' }}></div>
                                                <small className="text-muted p-t-30 db">Taalcode</small>
                                                <h6>{this.props.land.MainTaalcodeData.data.code}</h6>
                                                
                                            </CardBody>
                                        </PerfectScrollbar>
                                    }
                                    {this.state.edit === true &&
                                        <PerfectScrollbar className="sidebar-nav">
                                        <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                            <EditString className="card-title m-t-10"
                                                value={this.state.data.taalcodedata.omschrijving}
                                                coltype='STR'
                                                labelText="Omschrijving"
                                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'taalcode_omschrijving')}
                                            />
                                            <EditString className="card-title m-t-10"
                                                value={this.state.data.taalcodedata.code}
                                                coltype='STR'
                                                labelText="Taalcode"
                                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'taalcode_code')}
                                            />
                                            <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                                        </CardBody>
                                    </PerfectScrollbar>
                                    }
                                </Card>
                            </Grid>
                            <Grid item xs={9}>
                                <Card className='card-brut'>
                                    <CardBody style={{minHeight: Math.floor(window.innerHeight * .85) + "px", width: "100%"}}>
                                        <div className='div_line'>
                                            <Nav tabs >
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "default" }}
                                                        className={classnames({ active: this.state.tabvalue === '1' })}
                                                        onClick={(event) => { this.handleTabChange(event, '1'); }}
                                                    >
                                                        Factuuropbouw
                                                        </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "default" }}
                                                        className={classnames({ active: this.state.tabvalue === '2' })}
                                                        onClick={(event) => { this.handleTabChange(event, '2'); }}
                                                    >
                                                        Landen
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "default" }}
                                                        className={classnames({ active: this.state.tabvalue === '3' })}
                                                        onClick={(event) => { this.handleTabChange(event, '3'); }}
                                                    >
                                                        Jobdefinities
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={this.state.tabvalue}>
                                                <TabPane tabId="1">
                                                    {!this.state.edit && this.Factuuropbouw()}
                                                    {this.state.edit && this.FactuuropbouwEdit()}
                                                </TabPane> 
                                                <TabPane tabId="2">
                                                    {!this.state.edit && this.Landen()}
                                                    {this.state.edit && this.LandenEdit()}
                                                </TabPane> 
                                                <TabPane tabId="3">
                                                    {!this.state.edit && this.jobDefs()}
                                                    {this.state.edit && this.jobDefsEdit()}
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }

}
export default connect(mapStateToProps)(withStyles(styles)(Taalcodespage));