import ListMain from '../views/mainlist/LstMain';
import Userpage from '../views/users/Userpage';
import Accountpage from '../views/accounts/Accountpage';
import Contactpage from '../views/contacts/Contactpage';
import Registrationpage from '../views/registration/Registrationpage';
import Jobdefmainpage from '../views/jobs/Jobdefmainpage';
import Betaalmainfinpage from '../views/jobs/Betaalmainfinpage';
import Kantoorpage from '../views/kantoren/Kantoorpage';
import KantContactpage from '../views/kant_contacts/KantContactpage';
import Landpage from '../views/landen/Landpage';
import Supplierpage from '../views/suppliers/Supplierpage';
import Taalcodespage from '../views/taalcodes/Taalcodespage';
import UserDashBoard from '../views/dashboard/UserDashBoard';
import Templatepage from '../views/templates/Templatepage';
import CreateInvoicepage from '../views/invoices/CreateInvoicepage';
import OpenInvoicepage from '../views/invoices/OpenInvoicepage';
import Vatrefcodepage from '../views/jobs/Vatrefcodepage';
import IvatCodesPage from '../views/ivatcodes/Ivatcodespage';
import TimeRegistrationPage from '../views/timeregistration/TimeRegistrationPage';

var ThemeRoutes = [
	{
		navlabel: true,
		name: "Dashboard",
		icon: "mdi mdi-dots-horizontal",
		security_name: 'userdashboard',

	},
	{
		path: '/userdashboard',
		name: 'User Dashboard',
		security_name: 'userdashboard',
		icon:'Dashboard',
		component: UserDashBoard,
		menu:true
	},
	{
		path: '/timeregistration',
		name: 'Tijdsregistratie',
		security_name: 'timeregistration',
		icon:'AccessTime',
		component: TimeRegistrationPage,
		menu:true
	},
	{
		path: '/alltimeregistration',
		name: 'Alle Tijdsregistraties',
		security_name: 'AllTimereg',
		icon:'AccessTime',
		component: ListMain,
		menu:true
	},

	{
		navlabel: true,
		name: "Klanten",
		icon: "mdi mdi-dots-horizontal",
		security_name: 'AllAccounts',

	},
	{
		path: '/accounts',
		name: 'Klanten',
		security_name: 'AllAccounts',
		icon:'AccountBalance',
		component: ListMain,
		menu:true
	},
	{
		path: '/contacts',
		name: 'Contactpersonen',
		security_name: 'AllContacts',
		icon:'SupervisorAccount',
		component: ListMain,
		menu:true
	},
	{
		path: '/invoices',
		name: 'Facturen',
		security_name: 'AllFacturen',
		icon:'MenuBook',
		component: ListMain,
		menu:true
	},
	{
		navlabel: true,
		name: "Kantoren",
		icon: "mdi mdi-dots-horizontal",
		security_name: 'AllKantoren',

	},
	{
		path: '/kantoren',
		name: 'Kantoren',
		security_name: 'AllKantoren',
		icon:'AccountBalance',
		component: ListMain,
		menu:true
	},
	{
		path: '/contactskant',
		name: 'Contactpersonen',
		security_name: 'AllKantContacts',
		icon:'SupervisorAccount',
		component: ListMain,
		menu:true
	},
	{
		navlabel: true,
		name: "Leveranciers",
		icon: "mdi mdi-dots-horizontal",
		security_name: 'AllSuppliers',

	},
	{
		path: '/suppliers',
		name: 'Leveranciers',
		security_name: 'AllSuppliers',
		icon:'AccountBalance',
		component: ListMain,
		menu:true
	},


	{
		navlabel: true,
		name: "Activiteiten",
		icon: "mdi mdi-dots-horizontal",
		security_name: 'AllRegistratie',
	},
	{
		path: '/dirreg',
		name: 'Directe Registraties',
		security_name: 'AllRegistratie',
		icon:'Registration',
		component: ListMain,
		menu:true
	},
	{
		path: '/accijnz',
		name: 'Accijnzen',
		security_name: 'AllRegistratie',
		icon:'Registration',
		component: ListMain,
		menu:true
	},
	{
		path: '/vatref',
		name: 'Vat Refund',
		security_name: 'AllRegistratie',
		icon:'Registration',
		component: ListMain,
		menu:true
	},


	{
		navlabel: true,
		name: "Jobs",
		icon: "mdi mdi-dots-horizontal",
		security_name: 'Jobs Parameters',

	},
	{
		path: '/jobdirreg',
		name: 'Directe Registraties',
		security_name: 'AllJobs',
		icon:'Registration',
		component: ListMain,
		menu:true
	},
	{
		path: '/jobaccijnz',
		name: 'Accijnzen',
		security_name: 'AllJobs',
		icon:'Registration',
		component: ListMain,
		menu:true
	},
	{
		path: '/jobvatref',
		name: 'Vat Refund',
		security_name: 'AllJobs',
		icon:'Registration',
		component: ListMain,
		menu:true
	},
	{
		path: '/jobdivers',
		name: 'Diversen',
		security_name: 'AllJobs',
		icon:'Registration',
		component: ListMain,
		menu:true
	},

	{
		path: '/jobfin',
		name: 'Betaallijst Finanz',
		security_name: 'AllBetaalFin',
		icon:'Registration',
		component: ListMain,
		menu:true
	},	
  	{
	    collapse: true,
	    path: "/jobs_parameters",
		name: "Jobs Parameters",
		security_name: 'Jobs Parameters',
	    state: "openJobsParameters",
	    icon: 'ti-settings',
		menu: true,
    	child: [
			{
				path: '/job_parameters/jobdef',
				name: 'Job Definities',
				security_name: 'AllJobDefMain',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/job_parameters/jobstatus',
				name: 'Jobstatus',
				security_name: 'AllJobStatus',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: 'POPUP',
				name: 'Indexering tarieven',
				security_name: 'AllJobDefMain',
				icon: 'mdi mdi-comment-processing-outline',
				component: 'IndexTarief',
				menu:true
			},
			{
				path: 'POPUP',
				name: 'Initialisatie Jobs Jaar',
				security_name: 'AllJobDefMain',
				icon: 'mdi mdi-comment-processing-outline',
				component: 'CreaJaarJob',
				menu:true
			},
			{
				path: 'POPUP',
				name: 'Initialisatie DFV Jaar',
				security_name: 'AllJobDefMain',
				icon: 'mdi mdi-comment-processing-outline',
				component: 'CreaJaarJobDfv',
				menu:true
			},

    	]
  	},	

	{
		navlabel: true,
		name: "Algemeen",
		icon: "mdi mdi-dots-horizontal",
		security_name: 'Parameters',

	},

  	{
	    collapse: true,
	    path: "/parameters",
		name: "Parameters",
		security_name: 'Parameters',
	    state: "openParameters",
	    icon: 'ti-settings',
		menu: true,
    	child: [
			{
				path: '/parameters/users',
				name: 'Gebruikers',
				security_name: 'AllUsers',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/gensecparam',
				name: 'Security Parameters',
				security_name: 'AllSecPar',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/munten',
				name: 'Munten',
				security_name: 'AllMunten',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/landen',
				name: 'Landen',
				security_name: 'AllLanden',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/ivatprod',
				name: 'IVAT Productcodes',
				security_name: 'AllMainProductCodes',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},


			{
				path: '/parameters/templates',
				name: 'Templates',
				security_name: 'AllTemplates',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/taalcodes',
				name: 'Taalcodes',
				security_name: 'AllTaalcodes',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/factzendwijzen',
				name: 'Factuur zendwijzen',
				security_name: 'AllFactZendwijzen',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/postcodes',
				name: 'Postcodes',
				security_name: 'AllPostcodes',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/rekeningen',
				name: 'Bank rekeningen',
				security_name: 'AllRekeningen',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},			
			{
				path: '/parameters/betalingsvoorwaarden',
				name: 'Betalingsvoorwaarden',
				security_name: 'AllBetalingsvoorwaarden',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/btwregimes',
				name: 'BTW regimes',
				security_name: 'AllBtwRegimes',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/aansprekingen',
				name: 'Aansprekingen',
				security_name: 'AllAansprekingen',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
			{
				path: '/parameters/functies',
				name: 'Functies',
				security_name: 'AllFuncties',
				icon: 'mdi mdi-comment-processing-outline',
				component: ListMain,
				menu:true
			},
    	]
  	},	

	{
		path: '/user',
		name: 'Gebruiker',
		security_name: 'Gebruiker',
		icon: '',
		component: Userpage,
		menu: false,
	},
	{
		path: '/account',
		name: 'Klant',
		security_name: 'Klant',
		icon: '',
		component: Accountpage,
		menu: false,
	},
	{
		path: '/billing',
		name: 'Facturatie',
		security_name: 'Facturatie',
		icon: '',
		component: CreateInvoicepage,
		menu: false,
	},
	{
		path: '/invoice',
		name: 'Factuurdetails',
		security_name: 'Factuurdetails',
		icon: '',
		component: OpenInvoicepage,
		menu: false,
	},
	{
		path: '/kantoor',
		name: 'Kantoor',
		security_name: 'Kantoor',
		icon: '',
		component: Kantoorpage,
		menu: false,
	},
	{
		path: '/kantcontact',
		name: 'Kantoor contactpersoon',
		security_name: 'KantoorContactpersoon',
		icon: '',
		component: KantContactpage,
		menu: false,
	},
	{
		path: '/leverancier',
		name: 'Leverancier',
		security_name: 'Leverancier',
		icon: '',
		component: Supplierpage,
		menu: false,
	},
	{
		path: '/contact',
		name: 'Contactpersoon',
		security_name: 'Contactpersoon',
		icon: '',
		component: Contactpage,
		menu: false,
	},
	{
		path: '/registration',
		name: 'Registratie',
		security_name: 'Registratie',
		icon: '',
		component: Registrationpage,
		menu: false,
	},
	{
		path: '/jobmaindef',
		name: 'Job definitie',
		security_name: 'MainJobDef',
		icon: '',
		component: Jobdefmainpage,
		menu: false,
	},	
	{
		path: '/vatrefcodepage',
		name: 'Vat refund codes',
		security_name: 'AllVatRefCode',
		icon: '',
		component: Vatrefcodepage,
		menu: false,
	},	


	{
		path: '/betaalmainpage',
		name: 'Betaallijst Finanz',
		security_name: 'AllBetaalFin',
		icon: '',
		component: Betaalmainfinpage,
		menu: false,
	},	

	{
		path: '/template',
		name: 'Template',
		security_name: 'Template',
		icon: '',
		component: Templatepage,
		menu: false,
	},

	{
		path: '/land',
		name: 'Land',
		security_name: 'Land',
		icon: '',
		component: Landpage,
		menu: false,
	},
	{
		path: '/ivatcode',
		name: 'Ivatcode',
		security_name: 'AllMainProductCodes',
		icon: '',
		component: IvatCodesPage,
		menu: false,
	},

	{
		path: '/taalcode',
		name: 'Taalcode',
		security_name: 'Taalcode',
		icon: '',
		component: Taalcodespage,
		menu: false,
	},
	{
		path: '/allactivities',
		name: 'Alle Activiteiten',
		security_name: 'AllActivities',
		icon: '',
		component: ListMain,
		menu: false,
	},
	{
		path: '/allmyactivities',
		name: 'Mijn Activiteiten',
		security_name: 'AllMyActivities',
		icon: '',
		component: ListMain,
		menu: false,
	},


	{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true },
];
export default ThemeRoutes;
