import { ALLTEMPLATES_SUCCESS,GETTEMPLATE_SUCCESS,GETTEMPLATEFIELDS_SUCCESS } from '../constants/';


const SqlFunction = require("../../functions/sql_functions");
const axios = require('axios');

export const AllTemplatesSuccess = (payload) => {
    return {
        type: ALLTEMPLATES_SUCCESS,
        payload: payload.response.data
    }
}

export const GetTemplateSuccess = (payload) => {
    return {
        type: GETTEMPLATE_SUCCESS,
        payload: payload.response.data
    }
}

export const GetTemplateFieldsSuccess = (payload) => {
    return {
        type: GETTEMPLATEFIELDS_SUCCESS,
        payload: payload.response.data
    }
}


export function AllTemplates(props) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('AllTemplates', {});
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(AllTemplatesSuccess({ response })) },
            err => { dispatch(AllTemplatesSuccess(null)) }
        );
    }
}

export function CreateTemplate() {
    return function action(dispatch) {
        let response = { data: null }
        let data = { id: 0, description: '', temp_text: ''}
        response.data = { data: data }
        dispatch(GetTemplateSuccess({ response }));
    }
}

export function GetTemplate(id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetTemplate', { id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetTemplateSuccess({ response })) },
            err => { dispatch(GetTemplateSuccess(null)) }
        );
    }
}

export function GetTemplateFields() {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('AllTemplFields', {});
        const request = axios(authOptions);
        return request.then(
             response => {
                if (response.data && response.data.data) {
                    var i = 0;
                    let item = null;
                    var items = [];
                    var terdata = response.data.data;
                    var len = terdata.length;

                    for (; i < len; i++) 
                    {
                        item = terdata[i];
                        item.key = item.id;
                        item.text = item.description;
                        item.value = item.code;
                        let itemCopy = Object.assign({}, item);                
                        items.push(itemCopy);
                    };
                    response.data = { data: items }
                    dispatch(GetTemplateFieldsSuccess({ response }))
                }
                else {
                    response.data = { data: [] }
                  dispatch(GetTemplateFieldsSuccess({ response }))
                }
              },            
            response => { dispatch(GetTemplateFieldsSuccess({ response })) },
            err => { dispatch(GetTemplateFieldsSuccess(null)) }
        );
    }
}


