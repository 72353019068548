import CalculatorButton from "./CalculatorButton";
import React from "react";
import PropTypes from "prop-types";
import "./Calculator.css";
import Backspace from '@material-ui/icons/Backspace';
import Check from '@material-ui/icons/Check';


export default class CalculatorButtonPanel extends React.Component {
  static propTypes = {
    clickHandler: PropTypes.func,
    resultHandler: PropTypes.func
  };

  handleClick = buttonName => {
    this.props.clickHandler(buttonName);
  };

  handleResult = () => {
    this.props.resultHandler();
  }

  render() {
    return (
      <div className="calculator-button-panel">
        <div>
          <CalculatorButton name="back" clickHandler={this.handleClick}><Backspace /></CalculatorButton>
          <CalculatorButton name="+/-" clickHandler={this.handleClick} />
          <CalculatorButton name="%" clickHandler={this.handleClick} />
          <CalculatorButton name="/" clickHandler={this.handleClick}>÷</CalculatorButton>
        </div>
        <div>
          <CalculatorButton name="7" clickHandler={this.handleClick} />
          <CalculatorButton name="8" clickHandler={this.handleClick} />
          <CalculatorButton name="9" clickHandler={this.handleClick} />
          <CalculatorButton name="*" clickHandler={this.handleClick}>x</CalculatorButton>
        </div>
        <div>
          <CalculatorButton name="4" clickHandler={this.handleClick} />
          <CalculatorButton name="5" clickHandler={this.handleClick} />
          <CalculatorButton name="6" clickHandler={this.handleClick} />
          <CalculatorButton name="-" clickHandler={this.handleClick} />
        </div>
        <div>
          <CalculatorButton name="1" clickHandler={this.handleClick} />
          <CalculatorButton name="2" clickHandler={this.handleClick} />
          <CalculatorButton name="3" clickHandler={this.handleClick} />
          <CalculatorButton name="+" clickHandler={this.handleClick} />
        </div>
        <div>
          <CalculatorButton name="0" clickHandler={this.handleClick} wide />
          <CalculatorButton name="." clickHandler={this.handleClick} />
          <CalculatorButton name="=" clickHandler={this.handleClick} />
        </div>
        <div>
          <CalculatorButton name="OK" clickHandler={this.handleResult} wide><Check /></ CalculatorButton>
          <CalculatorButton name="AC" clickHandler={this.handleClick} wide />
        </div>
      </div>
    );
  }
}