import React, {
  Component,
} from 'react';

import CustomCheck from "../CustomCheck.jsx";
import GenFunc from "../../functions/gen_functions.js";

export default class GridRenderUnCheck extends Component {
    constructor(props) {
        super(props);
        this.inputid = GenFunc.MakeId()
    }
    render() {
        return (
          <div  style={{width:this.props.width, position:'absolute',marginBottom:'0px',top:'3px'}}>
            <CustomCheck
                ref={this.myInput}
                style={{width:this.props.width}}                
                id={this.inputid}
                value={false}
            />
          </div>
        );
    }
}