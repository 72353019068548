import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { Card, CardBody,  TabContent, TabPane, Nav, NavItem, NavLink, } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import styles from "../../assets/views/customViewStyle.jsx";
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import { setPanelRight, setTitleRight, setSubTitle } from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import {GetMainIvat } from '../../redux/settings/paramActions.js';

var gridapi = null;
var rowpinnend = null;

const mapStateToProps = state => ({
    ...state
});

class IvatCodesPage extends React.Component {

    state = {
        tableheight: Math.floor(window.innerHeight * .70) + 'px',
        tabvalue: '1',
        pinnedSubVatRefCode:[{id:0,main_id:'',code:'',description:'',save:'',verwijder:''}],        
        edit: this.props.param && this.props.param.MainIvatData && this.props.param.MainIvatData.data && this.props.param.MainIvatData.data.id === 0 ? true : false,
        save: false,
        subsave: false,
        checkval: false,
        data: this.props.param && this.props.param.MainIvatData && this.props.param.MainIvatData.data && this.props.param.MainIvatData.data.id === 0 ? this.props.param.MainIvatData.data : null,
        speedactions: [
            { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
            { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe code' },
            { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
        ],
        speedopen: false,
    };

    constructor(props, context) {
        super(props);
        this.resizeFunction = this.resizeFunction.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this)
        this.ValueCheck = this.ValueCheck.bind(this);
        this.handleSpeedClose = this.handleSpeedClose.bind(this);
        this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
        this.ZetEdit = this.ZetEdit.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.MainSave = this.MainSave.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.ActionClick = this.ActionClick.bind(this);
        this.SubcodeChanged = this.SubcodeChanged.bind(this);
        this.SubcodeCheck = this.SubcodeCheck.bind(this);
        this.SubcodeCellClick = this.SubcodeCellClick.bind(this);
        this.SubcodeSaveAll = this.SubcodeSaveAll.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
        this.props.dispatch(setTitleRight('IvatCode'))
        this.props.dispatch(setSubTitle('IvatCode'))
        this.props.dispatch(setPanelRight(true))
    }

    componentWillUnmount() {
        this.props.dispatch(setPanelRight(false))
        this.props.dispatch(setTitleRight(''))
        window.removeEventListener("resize", this.resizeFunction);
    }


    resizeFunction() {
        if (window.innerHeight >= 960) {
            this.setState({ tableheight: Math.floor(window.innerHeight * .70) + 'px' });
        }
        else {
            this.setState({ tableheight: '600px' });
        }
    }

    onGridReady(params) {
        gridapi = params;
    }

    getContextMenuItems(params, sleutel) {
        return null;
    }

    async ValueCheck(that, waarde, type, colId) {
        let data = this.state.data;
        let terug = false;
        data[colId] = waarde;
        terug = Validation.ValidCheck('IvatCode', waarde, colId, that, this.props.param.AllMainProductCodesData.data, data);
        that.setState({ error: terug })
        terug = await this.CheckSave(data);
        this.setState({ save: terug, data: data, checkval: false })
    } 

    handleSpeedClose() {
        this.setState({ speedopen: false });
    };

    handleSpeedOpen() {
        this.setState({ speedopen: true });
    };

    async ZetEdit() {
        this.setState({ edit: true, data: this.props.param.MainIvatData.data })
    }

    CheckSave(data) {
        let terug = true
        terug = Validation.ValidCheck('IvatCode', data.code, 'code', this, this.props.param.AllMainProductCodesData.data, data);
        if (terug) {Validation.ValidCheck('IvatCode', data.description, 'description', this, this.props.param.AllMainProductCodesData.data, data)}
        return !terug;
    }

    async handleTabChange(event, newValue) {
        if (newValue === '1') {
            //Taalcodes
        }
        this.setState({ tabvalue: newValue });

    };

    async MainSave() {
        if (this.state.save === true) {
            let retdata = await SqlFunction.DoSql('ChgMainIvat', this.state.data);
            
            this.setState({tabvalue:1, edit: false, save: false, data: null, checkval: false })
            if (retdata) {
                await this.props.dispatch(GetMainIvat(retdata.id))
            }
        }
    }


    async componentWillReceiveProps(nextProps, nextState) {
        if (this.props.param.MainIvatData) {
            if (this.props.param.MainIvatData.data.id !== nextProps.param.MainIvatData.data.id) {
                if (nextProps.param.MainIvatData.data.id === 0) {
                    this.setState({ edit: true, save: false, subsave: false })
                }
                else {
                    this.setState({ edit: false, save: false, subsave: false })
                    this.setState({ tabvalue: '1' });
                }
            }
        }
    }

    async ActionClick(name) {
        switch (name) {
            case 'Edit':
                this.setState({ edit: true, data: this.props.param.MainIvatData.data, speedopen: false })
                break;

            case 'New':
                this.setState({tabvalue:0, edit: true, data: {id:0,code:'',description:''}, speedopen: false })
                break;

            case 'Save':
                this.MainSave();
                break;

            default:
                break;
        }
        this.setState({ speedopen: false });
    }


    SubcodeChanged(params,waarde,that) {
    } 
    
    SubcodeCheck(params, waarde) {
        var colId = params.props.column.getId();
        params.props.data.save = false;
        let terug = Validation.ValidCheck('IvatCode', waarde, colId, params, this.props.param.MainIvatData.SubData,params.props.data);
        params.props.data[colId] = waarde;

        if (params.props.data && params.props.data.description.length >= 1 && params.props.data.code.length > 0 && terug === false) 
        {
            params.props.data.save = true;
        }
        else {
            params.props.data.save = '';
        }   
        if (params.props.node.rowPinned === 'top')
        {
            rowpinnend = params.props.data;
            rowpinnend.soort = 'ONKOSTEN' 
        }

        params.setState({ error: terug })
    }  
    
    async SubcodeCellClick(waarde) {
        var data = waarde.data
        let column = waarde.column.colDef.field;
        let retdata = null;
        if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows, 'AllMainProductCodes', 'edit')) {
            if (data.save === true) {
                data.main_id = this.props.param.MainIvatData.data.id;
                retdata = await SqlFunction.DoSql('ChgIvatSub', data);
                waarde.data.save = '';
                if (data.id === 0) {rowpinnend = null}
                waarde.data.verwijder = '1';
                if (data.id === 0) {
                    waarde.data.id = retdata.id;
                    gridapi.api.stopEditing();
                    gridapi.api.updateRowData({
                        add: [waarde.data],
                        addIndex: 0
                    });
                    waarde.node.setData({id:0,main_id:this.props.param.MainIvatData.data.id,code:'',description:'',save:'',verwijder:''});
                }
                else {
                    waarde.node.setData(waarde.data);
                }
                if (rowpinnend && rowpinnend.save === true)
                {
                    rowpinnend = null;
                }
                rowpinnend = null;

                this.setState({ subsave: false })
            }
        }

        if (column === 'verwijder'  && GenFunc.allowed(this.props.user.userData.secrows, 'AllMainProductCodes', 'edit'))
        {
            gridapi.api.updateRowData({ remove: [waarde.data]})
            let subdata = waarde.data
            await SqlFunction.DoSql('DelIvatSub',subdata);
           
            if (rowpinnend && rowpinnend.save === true)
            {
                rowpinnend = null;
            }
            rowpinnend = null;
        }



        return;
    }

    async SubcodeSaveAll(items) {
        if (Array.isArray(items) && GenFunc.allowed(this.props.user.userData.secrows, 'AllMainProductCodes', 'edit')) {
            var i = 0;
            for (i = 0; i < items.length; i++) {
                let data = items[i];
                await SqlFunction.DoSql('ChgIvatSub', data);
            }
        }
    }

    render() {
        const { classes } = this.props;
        var { tableheight } = this.state;
        return (
            <div>
                <Toolbar style={{ background: '#fff', padding: '14px 10px', boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px' }}>
                    <Grid container>
                        <Grid item xs={8}>
                            <h4 tag="h4" className="mt-3" onClick={() => { this.setState({ edit: false }) }}>{this.props.settings.sub_title}</h4>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        icon={<SpeedDialIcon style={{ zIndex: '10' }} />}
                        direction="left"
                        classes={{ root: classes.speedDial, fab: classes.speedFabRoot }}
                        onClose={this.handleSpeedClose}
                        onOpen={this.handleSpeedOpen}
                        open={this.state.speedopen}
                    >
                        {this.state.speedactions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipPlacement='bottom'
                                FabProps={{
                                    disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? false :
                                    (action.name === 'Edit' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                    (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? true :
                                    (action.name === 'New' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? false :
                                    (action.name === 'New' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                    (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows, 'Landen', 'edit')) ? true :
                                    (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === true) ? false :
                                    (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === false) ? true :
                                    (action.name === 'Save' && this.state.edit === false && this.state.editfact === false) ? true :
                                    (action.name === 'Save' && this.state.save === false) ? true : false
                                }}
                                onClick={() => { this.ActionClick(action.name) }}
                                tooltipTitle={action.tooltip}
                            />
                        ))}
                    </SpeedDial>
                </Toolbar>
                {this.props.param && this.props.param.MainIvatData && this.props.param.MainIvatData.data && 
                    <Grid  container style={{width: 'auto', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Card className='card-brut'>
                                    {this.state.edit === false &&
                                        <PerfectScrollbar className="sidebar-nav">
                                            <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                                <h4 style={{ marginTop: '25px', marginBottom: '50px', cursor: 'pointer' }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">{this.props.param.MainIvatData.data.code}</h4>
                                                <h4 style={{ marginTop: '25px', marginBottom: '50px', cursor: 'pointer' }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">{this.props.param.MainIvatData.data.description}</h4>
                                                <div className='div_line' style={{ marginBottom: '25px' }}></div>
                                            </CardBody>
                                        </PerfectScrollbar>
                                    }
                                    {this.state.edit === true &&
                                        <PerfectScrollbar className="sidebar-nav">
                                        <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                            <EditString className="card-title m-t-10"
                                                value={this.state.data.code}
                                                coltype='STR'
                                                labelText="Code"
                                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'code')}
                                            />
                                            <EditString className="card-title m-t-10"
                                                value={this.state.data.description}
                                                coltype='STR'
                                                labelText="Omschrijving"
                                                ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'description')}
                                            />
                                            <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                                        </CardBody>
                                    </PerfectScrollbar>
                                    }
                                </Card>
                            </Grid>
                            <Grid item xs={9}>
                                <Card className='card-brut'>
                                    <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                        <div className='div_line'>
                                            <Nav tabs >
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: this.state.tabvalue === '1' })}
                                                        onClick={(event) => { this.handleTabChange(event, '1'); }}
                                                        style={{ cursor: 'default' }}
                                                    >
                                                        Subcodes
                                                        </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={this.state.tabvalue}>
                                                <TabPane tabId="1">
                                                    {this.state.tabvalue === '1' && this.props.param.MainIvatData.SubData && this.props.param.MainIvatData.SubData && this.props.param.MainIvatData.SubKol &&
                                                        this.props.param && this.props.param.MainIvatData && this.props.param.MainIvatData.data && this.props.param.MainIvatData.data.id !== 0 &&
                                                        <TableGrid
                                                            tableheight={tableheight}
                                                            columns={this.props.param.MainIvatData.SubKol}
                                                            data={this.props.param.MainIvatData.SubData}
                                                            CellValueChanged={(params,waarde,object) => this.SubcodeChanged(params,waarde,object)}
                                                            CellValueCheck={(params,value,error) => this.SubcodeCheck(params,value,error)}
                                                            CellClickedEvent={(column, colDef, value) => this.SubcodeCellClick(column,colDef, value)}
                                                            SaveAll={(params) => this.SubcodeSaveAll(params)}
                                                            sizetofit={false}
                                                            sortable={false}
                                                            suppressMenu={true}
                                                            enableRowGroup={false}
                                                            suppressColumnVirtualisation={true}
                                                            filter={false}
                                                            sleutel='AllSubMainProductCodes'
                                                            pinnedTopRowData={[{id:0,main_id:this.props.param.MainIvatData.data.id,code:'',description:'',save:'',verwijder:''}]}
                                                            editable={true}
                                                            GridReady={this.onGridReady}
                                                        />
                                                    }
                                                </TabPane> 
                                            </TabContent>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }

}
export default connect(mapStateToProps)(withStyles(styles)(IvatCodesPage));
