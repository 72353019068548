import React, {createRef } from 'react';
import moment from 'moment'
import 'moment/locale/nl-be'

import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Validation from "../../functions/validation.js";
import AddCircle from '@material-ui/icons/AddCircle';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import {zetview} from "../../redux/settings/action";
import {AllVatRefCodeLand } from "../../redux/settings/jobsActions";
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import PopJobDubbelFactuur from "./PopJobDubbelFactuur.jsx";
import PopAddSupplier from "../suppliers/PopAddSupplier.jsx";
import PopJobEditSubVatRefund from "./PopJobEditSubVatRefund.jsx";
import FileDownload from "../../components/FileDownload.jsx";
import Dropzone from 'react-dropzone';

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point 
  if (dropzoneRef.current) {
    dropzoneRef.current.open()
  }
};
moment.locale('nl-be');
const accdropid = GenFunc.MakeId()
const accframeid = GenFunc.MakeId()
const accformid = GenFunc.MakeId()
var clickdata = null;



var gridapi = null;
var rowpinnend = null;

class PopJobEditVatRefundOnk extends React.Component {
    state = {
        data: this.props.data,
        kol: this.props.kol,
        filename:'',
        save:false,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        nivo: (this.props.nivo) ? this.props.nivo : 0,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleNew = this.handleNew.bind(this);

        this.handleSave = this.handleSave.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.CellCheck = this.CellCheck.bind(this);
        this.CheckSaveOnkost = this.CheckSaveOnkost.bind(this);
        this.ClosePopUp = this.ClosePopUp.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.onDrop = this.onDrop.bind(this);

    }

    async ClosePopUp() 
    {
      let jobdata = await SqlFunction.DoSql('GetJob',{id:this.props.jobid});
      this.setState({data:jobdata.vatrefund})          
      this.setState({PopUpScreen:null})
    }

    onGridReady(params)
    {
      gridapi = params;
    }
    addRecord(params, waarde) 
    {
      var colId = params.props.column.getId();
      if (colId === 'supplier_txt')
      {
        this.setState({PopUpScreen:(<PopAddSupplier open={true} waarde={waarde} toevobject={params} close={this.ClosePopUp} title='Toevoegen leverancier' />)});
      }
    }
    async onDrop(files) {
        if (!clickdata) {return}
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          formData.append('file', file);
        }
        formData.set('accountid', this.props.dropdata.account_id);
        formData.set('reg_id', this.props.dropdata.registration_id);
        formData.set('kant_id', this.props.dropdata.kantoor_id);
        formData.set('job_id', this.props.dropdata.id);
        formData.set('workflow_id', this.props.dropworkflow.id);
        formData.set('type', 'workflow');    
        formData.set('vatref_id', clickdata.mainid);    
        await SqlFunction.DoSqlBody('saveFile', formData);
        let jobdata = await SqlFunction.DoSql('GetJob',{id:this.props.jobid});
        this.setState({data:jobdata.vatrefund})                
    };


    async handleNew() 
    {
        let retdata = await SqlFunction.DoSql('GetJobMainVatRef',{id:''});
        let kol = [];
        if (retdata && retdata.subonkkol) { kol = retdata.subonkkol}
        let data = {id:0,supplier_id:'',factnum:'',job_id:this.props.jobid,factdatum:'',supplier_txt:'',scan:'0',pdf:''};
        this.setState({PopUpScreen:(<PopJobEditSubVatRefund open={true}  dropworkflow={this.props.dropworkflow} dropdata={this.props.dropdata} kol={kol} jobid={this.props.jobid} subonkosten={[]} country={this.props.country} data={data} listdata={this.state.data} close={this.ClosePopUp} title='Vat Refund ontvangen documenten' />)});
    }


    async CellClick(waarde) {

        var data = waarde.data
        let column = waarde.column.colDef.field;
        let jobdata = null;
        let rowonkost = null;
        var i = 0;
        if (column === 'scan')
        {
            clickdata = data
            if (data && (data.scan === 1 || data.scan === true || data.scan === '1'))
            {
              data.scan = false;
              waarde.node.setData(data);                 
              await SqlFunction.DoSql('ChgVatRefundOnkostenScan',data);
              jobdata = await SqlFunction.DoSql('GetJob',{id:this.props.jobid});
              this.setState({data:jobdata.vatrefund})                

               return;
            }
            else
            {
              data.scan = true;
              waarde.node.setData(data);                 
              await SqlFunction.DoSql('ChgVatRefundOnkostenScan',data);
              jobdata = await SqlFunction.DoSql('GetJob',{id:this.props.jobid});
              this.setState({data:jobdata.vatrefund})                
              return;
 
            }
        }

        if (column === 'verwijder')
        {
            await SqlFunction.DoSql('DelVatRefundOnkostenMain',data);
            jobdata = await SqlFunction.DoSql('GetJob',{id:this.props.jobid});
            this.setState({data:jobdata.vatrefund})                
        }
        else
        {
            let hdata = {};
            hdata.id = waarde.data.mainid;
            let retdata = await SqlFunction.DoSql('GetJobMainVatRef',hdata);
            if (retdata && retdata.subonkkol && retdata.data && retdata.subonkosten) 
            {
                this.setState({PopUpScreen:(<PopJobEditSubVatRefund open={true} dropworkflow={this.props.dropworkflow} dropdata={this.props.dropdata} kol={retdata.subonkkol} subonkosten={retdata.subonkosten} jobid={this.props.jobid} country={this.props.country} data={retdata.data} listdata={this.state.data} close={this.ClosePopUp} title='Vat Refund ontvangen documenten' />)});
            }
        }
        return;
    }

    async CellCheck(params,waarde) {
        var colId = params.props.column.getId();
        params.props.data.save = true;
        let terug = false
        let totaal = 0
        let bedrag = 0
        let btw = 0
        let mvh = 0
        if (params.props.data.btw) 
        {
            if (params.props.data.btw.constructor === String)
            {
                btw = parseFloat(params.props.data.btw);           
                if (isNaN(btw)) {btw = 0}
            }
            else
            {
                btw = params.props.data.btw;           
            }
        }
        if (params.props.data.mvh) 
        {
            if (params.props.data.mvh.constructor === String)
            {
                mvh = parseFloat(params.props.data.mvh);           
                if (isNaN(mvh)) {mvh = 0}
            }
            else
            {
                mvh = params.props.data.mvh;           
            }
        }
        if (colId === 'supplier_txt')
        {
            if (waarde && waarde.id === 0)
            {
                params.props.data.supplier_id =  waarde.id
                params.props.data.supplier_txt =  waarde.text
            }
        }
        if (colId === 'mvh')
        {
            bedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(bedrag)) {bedrag = 0}
            totaal = bedrag + btw
            params.props.data.totaal = totaal;
        }    
        if (colId === 'btw')
        {
            bedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(bedrag)) {bedrag = 0}
            totaal = bedrag + mvh
            params.props.data.totaal = totaal;
        }    
        if (colId === 'factnum')
        {
            params.props.data.factnum =  waarde
            if (params.state.losefocus === true && params.state.error === false)
            {
                let faktdata = await SqlFunction.DoSql('GetDubbelFactuur',params.props.data);
                if (faktdata && faktdata.data && faktdata.data.length > 0)
                {
                    this.props.dispatch(zetview(this.props.jobname, this.props.jobid, 'JOB',this));
                    this.setState({PopUpScreen:(<PopJobDubbelFactuur open={true} nivo={this.state.nivo + 1} mainid={this.state.data.id} data={faktdata.data} kol={faktdata.kol} close={this.ClosePopUp} title='Factuur reeds gebruikt bij' />)});
                }
            }
            else
            {
                terug = Validation.ValidCheck('AllJobOnkosten', waarde, colId, params, gridapi);
            }
        }
        if (params.props.node.rowPinned === 'top')
        {
            rowpinnend = params.props.data;
            rowpinnend.soort = 'ONKOSTEN' 
        }
        await params.setState({error:terug})
        await this.CheckSaveOnkost(params);
    }       



    componentDidMount(){
        this.props.dispatch(AllVatRefCodeLand(this.props.country));
        let onkostenkol = this.props.kol
        for (var i = 0; i < onkostenkol.length; i++) 
        { 
          let rij = onkostenkol[i];
          if (rij.field !== 'save' && rij.field !== 'verwijder' && rij.field !== 'totaal')
          {
            rij.editable = true
          }
          switch (rij.field) 
            {
              case 'save':
              case 'verwijder':

                rij.width = 65
                break;

              case 'totaal':
              case 'btw':
              case 'mvh':
                rij.width = 165
                break;

              case 'factdat':
                rij.width = 80
                break;

              case 'factnum':
                rij.width = 200
                break;

              case 'type_onkost_txt':
              case 'supplier_txt':

                rij.width = 250
                break;

              default:    
                break;
            } 


          onkostenkol[i] = rij;
        }

        this.setState({data:this.props.data, kol:onkostenkol})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
        if (gridapi && gridapi.api && gridapi.api.gridCore && gridapi.api.gridCore.rowRenderer &&
          gridapi.api.gridCore.rowRenderer.pinnedRowModel &&
          gridapi.api.gridCore.rowRenderer.pinnedRowModel.pinnedTopRows[0] &&
          gridapi.api.gridCore.rowRenderer.pinnedRowModel.pinnedTopRows[0].data)
        {
            let pindata = gridapi.api.gridCore.rowRenderer.pinnedRowModel.pinnedTopRows[0].data;
            if (pindata.save === true)
            {
                pindata.job_id = this.props.jobid;
                pindata.verwijder = '2';
                await SqlFunction.DoSql('ChgJobOnkosten',pindata);
            }
        }
        for (var i = 0; i < this.state.data.length; i++) 
        {
            let data = this.state.data[i];
            if (data.save === true)
            {
                data.job_id = this.props.jobid;
                data.verwijder = '2';
                await SqlFunction.DoSql('ChgJobOnkosten',data);
            }
        }
        if (this.props.close) 
        {
            this.props.close()
        }
    }
    CheckSaveOnkost(params) {
      let terug = false
      if (params.props.data.totaal > 0 && params.props.data.supplier_id.length > 4 && params.props.data.type_onkost.length > 4)
      {
          terug = false;
      }
      else
      {
          terug = true;
      }
      if (terug === false)
      {
          terug = Validation.ValidCheck('JobOnkost', params.props.data.factnum, 'factnum', params, gridapi);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobOnkost', params.props.data.factdat, 'factdat', params, gridapi);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobOnkost', params.props.data.type_onkost, 'type_onkost', params, gridapi);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobOnkost', params.props.data.type_onkost, 'supplier_id', params, gridapi);
      }

      if (terug === true)
      {
          params.props.data.save = false;
      }
      else
      {
        params.props.data.save = true;
      }
      params = {
          force: true,
          columns: ['factnum'],
      };
      gridapi.api.refreshCells(params)          
    }

    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var tableheight = Math.floor(window.innerHeight * .55) + 'px';
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : top;

        return (

            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'400px',position: 'absolute'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right',right: '50px'}} className={classes.closeButton} onClick={() => this.handleNew()}>
                    <AddCircle />
                  </IconButton>                

                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'scroll',height:'60vh'}}  className={classes.blockContent}>
                  <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick ={true} noKeyboard={true}>
                    {({getRootProps, getInputProps}) => (
                      <section className="container" style={{width:'100%',maxWidth:'100%'}}>
                        <div {...getRootProps({className: 'dropzone'})}>
                          <input {...getInputProps()} />
                          <button style={{display: 'none'}} id={accdropid} type="button" onClick={openDialog}></button>         
                          <FileDownload formid={accformid} frameid={accframeid} DocData={this.state.DownloadData}/>                
                          <Grid container style={{width: '100%', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                            <Grid item sm={12} md={12} lg={12}>
                              <TableGrid
                                tableheight={tableheight}
                                columns={this.state.kol}
                                data={this.state.data} 
                                sortable={true}
                                sizetofit={true}
                                suppressMenu={true}
                                enableRowGroup={false}
                                suppressColumnVirtualisation={true}
                                CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                                filter={false}
                                headerComponentParams = {null}
                                editable={false}
                                sleutel="VatRefund"      
                                GridReady={this.onGridReady}
                              />
                            </Grid>
                          </Grid>
                        </div>
                      </section>
                    )}
                  </Dropzone>                          
                  {this.state.PopUpScreen}
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  <Button color="primary"  onClick={() => this.handleSave()}>
                    Bewaren
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopJobEditVatRefundOnk.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobEditVatRefundOnk)));

