import React from 'react';
import BigCalendar from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/nl-be'
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import { Card, CardBody } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import TimeIcon from '@material-ui/icons/AccessTime';
import DeleteIcon from "@material-ui/icons/Delete";
import EditDateTime from "../../components/editdatetime.jsx";
import EditString from "../../components/editstring.jsx";
import EditSelect from "../../components/editselect.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import { CreateTimeRegistration, GetLatestTimeRegistrationFromUser, GetTimeRegistrationFromUserById, GetTimeRegistrationsFromUserOnDay } from '../../redux/settings/timeregistrationActions.js';
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
moment.locale('nl-be');

var gridapi = null;
var rowpinnend = null;
var changdat = null;
const localizer = BigCalendar.momentLocalizer(moment)
const mapStateToProps = state => ({
    ...state
});


function Event({ event }) {
    if (event.id === 'TOT') {
        let tijdver = event.tijd / 60
        let uur = Math.floor(event.tijd / 60)
        let minuten = event.tijd - (uur * 60)
        let tittijd = ''
        if (uur > 0) {
            if (minuten > 0) {
                tittijd = uur + ' Uur ' + minuten + ' Min'
            }
            else {
                tittijd = uur + ' Uur'
            }
        }
        else {
            if (minuten > 0) {
                tittijd = minuten + ' Min'
            }
        }
        let bcol = '#6a7e95';
        if (event.afwezig === 1)
        {
            bcol = 'red';
        }
        return (
            <div style={{ backgroundColor: bcol, color: 'white', overflow: 'hidden', borderWidth: 'thin', borderColor: 'darkgray', borderStyle: 'solid' }}>
                <div>
                    <div dir="ltr" style={{ marginBottom: '5px', marginTop: '5px', color: 'white', fontSize: '1.35em', textAlign: 'center', overflow: 'hidden' }}>{tittijd}</div>
                </div>
            </div>
        )

    }
    else {
        return null;
    }
}

class TimeRegistrationPage extends React.Component {
    state = {
        tableheight: Math.floor(window.innerHeight * .75) + 'px',
        speedopen: false,
        speedactions: [
            { icon: <TimeIcon />, name: 'New', tooltip: 'Nieuwe registratie' },
            { icon: <EditIcon />, name: 'Edit', tooltip: 'Registratie wijzigen' },
            { icon: <SaveIcon />, name: 'Save', tooltip: 'Registratie opslaan' },
            { icon: <DeleteIcon />, name: 'Delete', tooltip: 'Registratie verwijderen' },
        ],
        data: "",
        currentView: 'month',
        tot_tijd: 0,
        lst_activ: [],
        weekStartDate: "",
        weekEndDate: "",
        weergave:'MND',
        todaysDate: moment(),
        pickedDateString: "",
        edit: false,
        save: false,
        delete: false,
        timeRegsOnDayArray: [],
        user: { id: this.props.user.userData.id, name: this.props.user.userData.name },
    }

    constructor(props, context) {
        super(props);
        this.resizeFunction = this.resizeFunction.bind(this);
        this.handleSpeedClose = this.handleSpeedClose.bind(this);
        this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
        this.actionClick = this.actionClick.bind(this);
        this.weergaveClick = this.weergaveClick.bind(this);
        this.valueCheck = this.valueCheck.bind(this);
        this.checkSave = this.checkSave.bind(this);
        this.timeCheck = this.timeCheck.bind(this);
        this.checkDelete = this.checkDelete.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.endEdit = this.endEdit.bind(this);
        this.setCalendar = this.setCalendar.bind(this);
        this.selectDate = this.selectDate.bind(this);
        this.selectSlot = this.selectSlot.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.CellCheck = this.CellCheck.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.zetTotaal = this.zetTotaal.bind(this);
    }

    async componentDidMount() {
        let date = new Date();
        let dag = date.getDate()
        let maand = date.getMonth() + 1;
        let jaar = date.getFullYear();
        let sdag = dag.toString();
        let smaand = maand.toString();
        let sjaar = jaar.toString();
        if (dag < 10) { sdag = '0' + sdag }
        if (maand < 10) { smaand = '0' + smaand }
        let new_date = sdag + '-' + smaand + '-' + sjaar;
        let zoekdatum = sjaar + '-' + smaand + '-' + sdag;
        await this.props.dispatch(GetTimeRegistrationsFromUserOnDay(this.state.user.id, zoekdatum))        
        changdat = new_date

        this.setState({ selectedDate: date, pickedDateString: new_date, weergave: 'DAG'  })
        window.addEventListener("resize", this.resizeFunction);
        this.setCalendar();

        let rij = [{  user_id:this.state.user.id, 
                    account_id:'',
                    activiteit:'',
                    activiteit_txt:'',
                    account_number:'', 
                    account_name:'',
                    verwijder:'',
                    save:'',
                    date:date,
                    time_from:'',
                    time_till:'',
                    description:''}]       
        if (gridapi)
        {
            gridapi.api.updateRowData({ add: rij})
            var lastrow = gridapi.api.getLastDisplayedRow();
                    gridapi.api.setFocusedCell(lastrow, 'account_name',);  
                    gridapi.api.startEditingCell({
                        rowIndex: lastrow,
                        colKey: 'account_name',
                    });    
        }

    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }

    onGridReady(params)
    {
        gridapi = params;
        let rij = [{  user_id:this.state.user.id, 
                    account_id:'',
                    activiteit:'',
                    activiteit_txt:'',
                    account_number:'', 
                    account_name:'',
                    verwijder:'',
                    save:'',
                    date:this.state.selectedDate,
                    time_from:'08:00',
                    time_till:'',
                    description:''}]       
        if (this.props.timeregistration &&
            this.props.timeregistration.TimeRegistrationsOnDayData &&
            this.props.timeregistration.TimeRegistrationsOnDayData.data)
        {
            let timedata = this.props.timeregistration.TimeRegistrationsOnDayData.data;
            if (timedata.length > 0)
            {
                let timerow = timedata[timedata.length - 1];
                rij[0].time_from = timerow.time_till
            }
        }
        this.zetTotaal(this.props.timeregistration.TimeRegistrationsOnDayData.data);
        if (gridapi)
        {
            gridapi.api.updateRowData({ add: rij})
            var lastrow = gridapi.api.getLastDisplayedRow();
                    gridapi.api.setFocusedCell(lastrow, 'account_name',);  
                    gridapi.api.startEditingCell({
                        rowIndex: lastrow,
                        colKey: 'account_name',
                    });    
        }      
    }
    resizeFunction() {
        if (window.innerHeight >= 960) {
            this.setState({ tableheight: Math.floor(window.innerHeight * .75) + 'px' });
        }
        else {
            this.setState({ tableheight: '600px' });
        }
    }
    async CellCheck(params,waarde,datacol) {
        if (!waarde) {return}
        var colId = params.props.column.getId();
        params.props.data.save = true;

        if (colId === 'account_name')
        {
            if (!waarde) 
            {
                params.props.data.account_id = '';       
                params.props.data.account_name = '';       

            }
            if (datacol )
            {
                params.props.data.account_id = datacol.id;      
                params.props.data.account_name =  datacol.name;  
            }
        }
        if (colId === 'activiteit_txt')
        {
            if (!waarde) 
            {
                params.props.data.activiteit = '';       
                params.props.data.activiteit_txt = '';       

            }
            if (datacol )
            {
                params.props.data.activiteit = datacol.id;      
                params.props.data.activiteit_txt =  datacol.text;  
            }
        }

        params.props.node.setData(params.props.data);        
    }       

    async CellClick(waarde) {
        if (this.state.save === true) {return}
        var data = waarde.data
        let column = ''
        column = waarde.column.colDef.field;
        if (column === 'save')
        {
            if (data.save === true) 
            {
                data.user_id = this.state.user.id;
                data.date = this.state.pickedDateString;
                await SqlFunction.DoSql('ChgTimeRegistration', data);
            }
        }

        if (column === 'verwijder')
        {
            await SqlFunction.DoSql('DeleteTimeRegistration', data);
        }
        if (column === 'verwijder' || column === 'save')
        {
            gridapi.api.setRowData([]);                    
            let date = this.state.pickedDateString
            let parsedDate = moment(date, "DD/MM/YYYY");
            let jaar = parsedDate.format('YYYY')
            let maand = parsedDate.format('MM')
            let dag = parsedDate.format('DD')
            let zoekdatum = new Date(jaar, maand, dag, 0, 0, 0)
            let sdag = dag.toString();
            let smaand = maand.toString();
            let sjaar = jaar.toString();
            let sqldatum = sjaar + '-' + smaand + '-' + sdag;
            await this.props.dispatch(GetTimeRegistrationsFromUserOnDay(this.state.user.id, sqldatum))                    
            let rij = [{  user_id:this.state.user.id, 
                        account_id:'',
                        activiteit:'',
                        activiteit_txt:'',
                        account_number:'', 
                        account_name:'',
                        verwijder:'',
                        save:'',
                        date:this.state.pickedDateString,
                        time_from:'08:00',
                        time_till:'',
                        description:''}]       
            if (this.props.timeregistration &&
                this.props.timeregistration.TimeRegistrationsOnDayData &&
                this.props.timeregistration.TimeRegistrationsOnDayData.data)
            {
                let timedata = this.props.timeregistration.TimeRegistrationsOnDayData.data;
                if (timedata.length > 0)
                {
                    let timerow = timedata[timedata.length - 1];
                    rij[0].time_from = timerow.time_till
                }
            }
            if (gridapi)
            {
                gridapi.api.updateRowData({ add: rij})
                var lastrow = gridapi.api.getLastDisplayedRow();
                        gridapi.api.setFocusedCell(lastrow, 'account_name',);  
                        gridapi.api.startEditingCell({
                            rowIndex: lastrow,
                            colKey: 'account_name',
                        });    
            }     
            this.setCalendar(this.state.user.id);
            this.zetTotaal(this.props.timeregistration.TimeRegistrationsOnDayData.data);
        }



        return;
    }

    handleSpeedClose() {
        this.setState({ speedopen: false });
    };

    handleSpeedOpen() {
        this.setState({ speedopen: true });
    };

    async selectDate(data) {
        if (this.state.edit) { this.setState({ edit: false }); }
        if (data.id === 'TOT') { return }
        await this.props.dispatch(GetTimeRegistrationFromUserById(this.state.user.id, data.id));
        this.checkDelete();
    }

    async weergaveClick(type) {
        this.setState({weergave:type})
    }

    async changeDate(date, view, action) {
        let dag = date.getDate()
        let maand = date.getMonth() + 1;
        let jaar = date.getFullYear();
        let sdag = dag.toString();
        let smaand = maand.toString();
        let sjaar = jaar.toString();
        if (dag < 10) { sdag = '0' + sdag }
        if (maand < 10) { smaand = '0' + smaand }
        let new_date = sdag + '-' + smaand + '-' + sjaar;
        this.setState({ selectedDate: date, pickedDateString: new_date })
    }

    async selectSlot(data) {
        let dag = data.start.getDate()
        let maand = data.start.getMonth() + 1;
        let jaar = data.start.getFullYear();
        let sdag = dag.toString();
        let smaand = maand.toString();
        let sjaar = jaar.toString();
        if (dag < 10) { sdag = '0' + sdag }
        if (maand < 10) { smaand = '0' + smaand }
        let new_date = sdag + '-' + smaand + '-' + sjaar;
        let zoekdatum = sjaar + '-' + smaand + '-' + sdag;
        changdat = new_date        
        await this.props.dispatch(GetTimeRegistrationsFromUserOnDay(this.state.user.id, zoekdatum))        
        this.setState({ weergave: 'DAG', selectedDate: data.start, pickedDateString: new_date })
    }

    async setCalendar(userid) {
        let retdata = []
        if (userid) {
            retdata = await SqlFunction.DoSql('GetAllTimeRegUser', { userid: userid });
        }
        else {
            retdata = await SqlFunction.DoSql('GetAllTimeRegUser', { userid: this.state.user.id });
        }
        let lst_activ = [];
        let tot_array = [];
        var vandaag = new Date();
        let kleiner = true;
        let afwezig = 0;
        let gew_uren = 0;
        let afw_uren = 0;
        let olddatum = '';

        if (retdata) {
            for (var i = 0; i < retdata.data.length; i++) {
                let rij = retdata.data[i];
                if (rij.date !== olddatum)
                {
                    afwezig = 0;
                    olddatum = rij.date;
                }
                let datum = null;
                let sdatum = rij.date;
                let uurvan = rij.time_from.split(":")[0];
                let minvan = rij.time_from.split(":")[1];
                let uurtot = rij.time_till.split(":")[0];
                let mintot = rij.time_till.split(":")[1];
                let start_date = null;
                let end_date = null;
                let tel_datum_van = null;
                let tel_datum_tot = null;
                let totaalmin = 0
                let tijd = 0
                if (sdatum) {
                    let y = sdatum.split("-")[0];
                    let m = sdatum.split("-")[1];
                    let nm = parseFloat(m) - 1;
                    m = nm.toString();
                    let d = sdatum.split("-")[2];
                    tel_datum_van = moment(sdatum + ' ' + rij.time_from, "YYYY-MM-DD HH:mm", false);
                    tel_datum_tot = moment(sdatum + ' ' + rij.time_till, "YYYY-MM-DD HH:mm", false);
                    let duur = moment.duration(tel_datum_tot.diff(tel_datum_van))
                    tijd = (duur.get("hours") * 60) + duur.get("minutes");
                    start_date = new Date(y, m, d, uurvan, minvan, 0);
                    end_date = new Date(y, m, d, uurtot, mintot, 0);
                }
                afwezig = 0;
                if (rij.activiteit_txt === 'Afwezig')
                {
                    afwezig = 1;
                }
                if (start_date && end_date) {
                    let agendarij =
                    {
                        'id': rij.id,
                        'datum': sdatum,
                        'tijd': tijd,
                        'account_name': rij.account_name,
                        'account_id': rij.account_id,
                        'code': rij.activiteit_txt,
                        'description': rij.description,
                        'title': rij.activiteit_txt + '(' + tijd + ' min)',
                        'start': start_date,
                        'end': end_date,
                    }
                    // lst_activ.push(agendarij);
                    // zoeken in totaal rij
                    let tot_found = false;
                    for (var tri = 0; tri < tot_array.length; tri++) {
                        let totrij = tot_array[tri];
                        if (totrij.datum === sdatum && totrij.afwezig === afwezig) {
                            tot_found = tri
                            tri = tot_array.length + 1
                        }
                    }
                    if (tot_found === false) {
                        if (sdatum) {
                            let sy = sdatum.split("-")[0];
                            let sm = sdatum.split("-")[1];
                            let snm = parseFloat(sm) - 1;
                            sm = snm.toString();
                            let sd = sdatum.split("-")[2];
                            start_date = new Date(sy, sm, sd, 0, 0, 0);
                            end_date = new Date(sy, sm, sd, 0, 0, 0);
                        }

                        let totaalrij =
                        {
                            'id': 'TOT',
                            'afwezig' : afwezig,
                            'datum': sdatum,
                            'tijd': tijd,
                            'account_name': 'Totaal in minuten',
                            'title': 'Totaal in minuten',
                            'start': start_date,
                            'end': end_date,
                        }
                        afwezig = 0;
                        tot_array.push(totaalrij);
                    }
                    else {
                        let gevrij = tot_array[tot_found];
                        gevrij.tijd = gevrij.tijd + tijd;
                        tot_array[tot_found] = gevrij;
                    }
                }
            }
            // Nu totalen zetten in agenda
            for (var totari = 0; totari < tot_array.length; totari++) {
                let totaalrij = tot_array[totari];
                lst_activ.push(totaalrij);
            }
            // einde zetten totalen     
        }
        this.setState({ lst_activ: lst_activ })
    }

    async zetTotaal(retdata) {
        let lst_activ = [];
        let tot_array = [];
        var vandaag = new Date();
        let kleiner = true;
        let tijd = 0
        if (retdata) {
            for (var i = 0; i < retdata.length; i++) {
                let rij = retdata[i];
                let datum = null;
                let sdatum = rij.date;
                let uurvan = rij.time_from.split(":")[0];
                let minvan = rij.time_from.split(":")[1];
                let uurtot = rij.time_till.split(":")[0];
                let mintot = rij.time_till.split(":")[1];
                if (sdatum) {
                    let y = sdatum.split("-")[0];
                    let m = sdatum.split("-")[1];
                    let nm = parseFloat(m) - 1;
                    m = nm.toString();
                    let d = sdatum.split("-")[2];
                    let tel_datum_van = moment(sdatum + ' ' + rij.time_from, "YYYY-MM-DD HH:mm", false);
                    let tel_datum_tot = moment(sdatum + ' ' + rij.time_till, "YYYY-MM-DD HH:mm", false);
                    let duur = moment.duration(tel_datum_tot.diff(tel_datum_van))
                    tijd = tijd + (duur.get("hours") * 60) + duur.get("minutes");
                }
            }
        }
        let tijdver = tijd / 60
        let uur = Math.floor(tijd / 60)
        let minuten = tijd - (uur * 60)
        let tittijd = ''
        if (uur > 0) {
            if (minuten > 0) {
                tittijd = uur + ' Uur ' + minuten + ' Min'
            }
            else {
                tittijd = uur + ' Uur'
            }
        }
        else {
            if (minuten > 0) {
                tittijd = minuten + ' Min'
            }
        }        
        this.setState({ tot_tijd: tittijd })
    }
    async startEdit() {
        let editData = this.props.timeregistration.MainTimeRegistrationData.data;
        let date = editData.date.split("-").reverse().join("-");
        await this.props.dispatch(GetTimeRegistrationsFromUserOnDay(this.state.user.id, date))
        let timeRegsOnDayArray = this.props.timeregistration.TimeRegistrationsOnDayData.data;
        let index = timeRegsOnDayArray.map(function (timereg) { return timereg.id }).indexOf(editData.id);
        timeRegsOnDayArray.splice(index, 1);

        this.setState({ edit: true, delete: false, data: editData, timeRegsOnDayArray: timeRegsOnDayArray, save: true });
    }

    async endEdit() {
        if (this.state.edit === true)
        {
            this.actionClick('Save')
        }
        else
        {
            this.actionClick('Edit')
        }
    }

    async actionClick(name) {
        switch (name) {
            case 'New':
                if (this.state.edit) { this.setState({ edit: false }) }
                await this.props.dispatch(CreateTimeRegistration());

                this.setState({ edit: true, data: this.props.timeregistration.MainTimeRegistrationData.data, speedopen: false, delete: false })
                break;

            case 'Edit':
                this.startEdit();
                break;

            case 'Save':
                if (this.state.save) {
                    let data = this.state.data;
                    data.user_id = this.state.user.id;

                    let retdata = await SqlFunction.DoSql('ChgTimeRegistration', data);
                    this.setCalendar(this.state.user.id);

                    await this.props.dispatch(GetTimeRegistrationFromUserById(this.state.user.id, retdata.id));
                    this.checkDelete();

                    this.setState({ edit: false, save: false })
                }
                break;

            case 'Delete':
                await SqlFunction.DoSql('DeleteTimeRegistration', this.props.timeregistration.MainTimeRegistrationData.data);
                await this.setCalendar()
                await this.props.dispatch(GetLatestTimeRegistrationFromUser(this.state.user.id))
                this.checkDelete();
                break;

            default:
                return;
        }
        this.setState({ speedopen: false });
    }

    async valueCheck(that, waarde, type, colId) {
        let data = this.state.data;
        let error = false;

        switch (colId) {
            case 'user_name':
                let user = {
                    id: waarde.id,
                    name: waarde.value
                }
                if (waarde.id !== "") {
                    await this.setCalendar(waarde.id)
                    await this.props.dispatch(GetLatestTimeRegistrationFromUser(user.id))
                    this.checkDelete();
                    this.setState({ user: user, edit: false })
                }
                break;

            case 'date':
                let date = waarde;
                if (date && date.length === 10 && date.charAt(9) !== " " && date !== changdat) 
                {
                    if (gridapi)
                    {
                        gridapi.api.setRowData([]);                    
                    }
                    let parsedDate = moment(date, "DD/MM/YYYY");
                    let jaar = parsedDate.format('YYYY')
                    let maand = parsedDate.format('MM')
                    let zmaand = parsedDate.format('MM') - 1

                    let dag = parsedDate.format('DD')
                    let zoekdatum = new Date(jaar, zmaand, dag, 0, 0, 0)
                    let sdag = dag.toString();
                    let smaand = maand.toString();
                    let sjaar = jaar.toString();
                    let sqldatum = sjaar + '-' + smaand + '-' + sdag;
                    changdat = date;
                    await this.props.dispatch(GetTimeRegistrationsFromUserOnDay(this.state.user.id, sqldatum))                    
                    await this.setState({ selectedDate: zoekdatum, currentView: 'day', edit: false, pickedDateString: date })
                    let rij = [{  user_id:this.state.user.id, 
                        account_id:'',
                        activiteit:'',
                        activiteit_txt:'',
                        account_number:'', 
                        account_name:'',
                        verwijder:'',
                        save:'',
                        date:zoekdatum,
                        time_from:'08:00',
                        time_till:'',
                        description:''}]       
                    if (this.props.timeregistration &&
                        this.props.timeregistration.TimeRegistrationsOnDayData &&
                        this.props.timeregistration.TimeRegistrationsOnDayData.data)
                    {
                        let timedata = this.props.timeregistration.TimeRegistrationsOnDayData.data;
                        if (timedata.length > 0)
                        {
                            let timerow = timedata[timedata.length - 1];
                            rij[0].time_from = timerow.time_till
                        }
                    }
                    this.zetTotaal(this.props.timeregistration.TimeRegistrationsOnDayData.data);
                    if (gridapi)
                    {
                        gridapi.api.updateRowData({ add: rij})
                        var lastrow = gridapi.api.getLastDisplayedRow();
                                gridapi.api.setFocusedCell(lastrow, 'account_name',);  
                                gridapi.api.startEditingCell({
                                    rowIndex: lastrow,
                                    colKey: 'account_name',
                                });    
                    }                         

                }
                else
                {
                    this.setState({pickedDateString: date })
                }
                break;

            case 'registratiedatum':
                data.date = waarde;
                let dateCheck = moment(waarde, "DD-MM-YYYY", true);
                error = dateCheck.isValid() ? false : true;
                if (!error) {
                    let date = waarde.split("-").reverse().join("-");
                    await this.props.dispatch(GetTimeRegistrationsFromUserOnDay(this.state.user.id, date))

                    let week = dateCheck.week();
                    let year = dateCheck.year();
                    if ((week === 52 || week === 53) && dateCheck.month() === 0) {
                        dateCheck.subtract(1, "y");
                        year = dateCheck.year();
                    }
                    data.week = week + "/" + year;
                    data.time_from = "";
                    data.time_till = "";
                    this.setState({ timeRegsOnDayArray: this.props.timeregistration.TimeRegistrationsOnDayData.data });
                } else {
                    data.time_from = "";
                    data.time_till = "";
                }
                break;

            case 'uurvan':
                data.time_from = waarde;
                error = this.timeCheck(waarde, "from");
                break;

            case 'uurtot':
                data.time_till = waarde;

                if (data.time_from !== waarde) {
                    let time_fromSplitted = (data.time_from).split(":");
                    let newRegStart = Number(time_fromSplitted[0]) * 60 + Number(time_fromSplitted[1]);

                    error = this.timeCheck(waarde, "till", newRegStart);
                } else {
                    error = true;
                }
                break;

            case 'account_id':
                data.account_name = waarde.value;
                data.account_id = waarde.id;
                break;

            case 'activiteit_txt':
            case 'activiteit':
                data.activiteit_txt = waarde.value;
                data.activiteit = waarde.id;
                break;


            case 'omschrijving':
                data.description = waarde;
                break;

            default:
                return;
        }

        that.setState({ error: error });
        let canSave = this.checkSave(data);
        this.setState({ save: canSave, data: data })
    }

    checkSave(data) {
        let canSave = false;

        let dateCheck = moment(data.date, "DD-MM-YYYY", true);
        canSave = dateCheck.isValid() ? true : false;

        if (canSave) {
            canSave = !this.timeCheck(data.time_from, "from");
        }
        if (canSave) {
            if (data.time_from !== data.time_till) {
                let time_fromSplitted = (data.time_from).split(":");
                let newRegStart = Number(time_fromSplitted[0]) * 60 + Number(time_fromSplitted[1]);
                canSave = !this.timeCheck(data.time_till, "till", newRegStart);
            } else {
                canSave = false;
            }
        }

        return canSave;
    }

    checkDelete() {
        let canDelete = false;
        if (this.props.timeregistration && this.props.timeregistration.MainTimeRegistrationData && this.props.timeregistration.MainTimeRegistrationData.data && this.props.timeregistration.MainTimeRegistrationData.data !== null) {
            canDelete = true;
        }
        this.setState({ delete: canDelete })
    }

    timeCheck(waarde, field, newRegStart) {
        let error = true;
        if (waarde.length === 5) {
            let splittedTime = waarde.split(":");
            let hours = Number(splittedTime[0]);
            let minutes = Number(splittedTime[1]);
            if (hours <= 19 && hours >= 7 && minutes < 60) {
                let dayData = this.state.timeRegsOnDayArray;
                let timeToCheck = hours * 60 + minutes;
                if (dayData.length !== 0) {
                    let periodsOverlap = false;
                    for (let i = 0; i < dayData.length; i++) {
                        let time_fromSplitted = (dayData[i].time_from).split(":");
                        let time_tillSplitted = (dayData[i].time_till).split(":");
                        let regStart = Number(time_fromSplitted[0]) * 60 + Number(time_fromSplitted[1]);
                        let regEnd = Number(time_tillSplitted[0]) * 60 + Number(time_tillSplitted[1]);
                        if (field === "from") {
                            if ((regStart <= timeToCheck && timeToCheck < regEnd) || hours === 19) {
                                periodsOverlap = true;
                                break;
                            }
                        }
                        if (field === "till") {
                            //first condition checks if periods overlap, second one checks if endtime is after starttime, third one checks if endtime is after 19:00
                            if ((newRegStart < regEnd && regStart < timeToCheck) || newRegStart > timeToCheck || (hours === 19 && minutes > 0)) {
                                periodsOverlap = true;
                                break;
                            }
                        }
                    }
                    error = periodsOverlap ? true : false;
                } else {
                    if (field === "till") {
                        error = (newRegStart > timeToCheck || (hours === 19 && minutes > 0)) ? true : false;
                    } else {
                        error = hours === 19 ? true : false;
                    }
                }
            }
        }

        return error;
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Toolbar style={{ background: '#fff', padding: '14px 10px', boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px', width: 'calc(100% - 27px)', zIndex: '2', position: 'fixed' }}>
                    <Grid container>
                        <Grid item xs={8}>
                            <h4 tag="h4" className="mt-3" onClick={this.endEdit} style={{ cursor: "pointer" }}>Tijdsregistratie</h4>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                </Toolbar>
                <section className="container" style={{ width: '100%', maxWidth: '100%' }}>
                    <Grid container style={{ width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Card className='card_brut'>
                                    <CardBody style={{ minHeight: Math.floor(window.innerHeight * .85) + 'px', overflow: "auto" }}>
                                        <div style={{ height: (Math.floor(window.innerHeight * .85)) - 50 + 'px' }}>
                                            <div style={{ width: "100%", height: "5%" }}>
                                                <div key={"weekInfo"} style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                    <div style={{ width: "25%", marginTop: "-15px" }}>
                                                        {GenFunc.allowed(this.props.user.userData.secrows, 'AllTimeRegistrations', 'zien') &&
                                                            <EditAutoSelect className="card-title m-t-10"
                                                                value={this.state.user.name}
                                                                labelText="Gebruiker"
                                                                lijst='AllUsers'
                                                                colId='user_name'
                                                                selectcel='user'
                                                                id={this.state.user.id}
                                                                ValueCheck={(params, value, type) => this.valueCheck(params, value, type, 'user_name')}
                                                            />}
                                                    </div>
                                                    <div style={{ display: "flex", flexDirection: "row", width: "50%", margin: "auto", justifyContent: "center" }}>
                                                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                                                            <Button onClick={() => { this.weergaveClick('MND') }} size="small">Maandweergave</Button>
                                                            <Button onClick={() => { this.weergaveClick('DAG') }} size="small">Dagweergave</Button>
                                                        </ButtonGroup>
                                                    </div>
                                                    <div style={{ width: "5%", margin: "auto", justifyContent: "left", textAlign: "left", marginTop: "-10px", marginLeft: "-25px" }}>
                                                        { this.state.weergave === 'DAG' && this.props.timeregistration.TimeRegistrationsOnDayData && 
                                                            this.props.timeregistration.TimeRegistrationsOnDayData.data &&  
                                                            <div>  
                                                                <small className="text-muted p-t-30 db">Totale tijd</small>
                                                                <h6 style={{ marginTop: "8px"}}> {this.state.tot_tijd} </h6>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div style={{ width: "20%", margin: "auto", justifyContent: "center", textAlign: "right", marginTop: "-15px", marginRight: "-15px" }}>
                                                        <EditDateTime className="card-title m-t-10"
                                                            value={this.state.pickedDateString}
                                                            coltype='STR'
                                                            labelText="Kies datum"
                                                            formControlProps={{ fullWidth: true }}
                                                            ValueCheck={(params, value, type) => this.valueCheck(params, value, type, 'date')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div key={"daysOverview"} style={{ width: "100%", height: "95%", marginTop: '25px' }}>
                                                {this.state.weergave === 'MND' &&
                                                    <BigCalendar
                                                        style={{ minWidth: '90vw', maxWidth: '90vw', minHeight: '95%', fontSize: '0.75em', overflow: 'auto' }}
                                                        popup
                                                        onSelectEvent={event => this.selectSlot(event)}
                                                        view='month'
                                                        selectable={true}
                                                        onSelectSlot={event => this.selectSlot(event)}
                                                        onNavigate={(date, view, action) => this.changeDate(date, view, action)}
                                                        localizer={localizer}
                                                        min={new Date(2000, 1, 0, 7, 0, 0)}
                                                        max={new Date(2000, 1, 0, 19, 0, 0)}
                                                        step={15}
                                                        views={{ month: true}}
                                                        date={this.state.selectedDate}
                                                        events={this.state.lst_activ}
                                                        components={{ event: Event, }}
                                                        startAccessor="start"
                                                        endAccessor="end"
                                                    />
                                                }
                                                { this.state.weergave === 'DAG' && this.props.timeregistration.TimeRegistrationsOnDayData && this.props.timeregistration.TimeRegistrationsOnDayData.data 
                                                    && this.props.timeregistration.TimeRegistrationsOnDayData.kol &&
                                                    <TableGrid
                                                      tableheight={this.state.tableheight} 
                                                      columns={this.props.timeregistration.TimeRegistrationsOnDayData.kol}
                                                      data={this.props.timeregistration.TimeRegistrationsOnDayData.data} 
                                                      CellValueCheck={(params,value,error) => this.CellCheck(params,value,error)}
                                                      CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}                                                      
                                                      sizetofit={false}
                                                      sortable={false}
                                                      suppressMenu={true}
                                                      enableRowGroup={false}
                                                      GridReady={this.onGridReady}
                                                      suppressColumnVirtualisation={true}                                
                                                      filter={false}
                                                      sleutel='TijdReg'
                                                      editable={true}
                                                    />
                                                }

                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
            </div>
        );
    }
}
export default connect(mapStateToProps)(withStyles(styles)(TimeRegistrationPage));