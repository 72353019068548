import React from 'react';
import moment from 'moment'
import 'moment/locale/nl-be'
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import EditCheck from "../../components/editcheck.jsx";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";

const mapStateToProps = state => ({
  ...state
});


var gridapi = null;
class PopJobTarieven extends React.Component {
    state = {
        data: this.props.data.data,
        staffel: this.props.jobs.AllJobDefMainIndexData,
        filename:'',
        save:false,
        tabvalue:'1',
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);

        this.CellClick = this.CellClick.bind(this);
        this.CellCheck = this.CellCheck.bind(this);


        this.onGridReady = this.onGridReady.bind(this);
        this.handleSave = this.handleSave.bind(this);

        this.handleTabChange = this.handleTabChange.bind(this);


    }
    async handleTabChange(event, newValue) {
    }
    onGridReady(params)
    {
      gridapi = params;
    }

    componentDidMount(){
        this.setState({data:this.props.data.data,staffel: this.props.jobs.AllJobDefMainIndexData})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSave(){
      // Bewaren gegevens
      let chgdata = this.state.data;
      chgdata.jobs = this.state.staffel.data;
      await SqlFunction.DoSql('ChgIndexTarief',chgdata);
      if (this.props.close) {
            this.props.close()
        }
    }


    async ErrorCheck(that, waarde, type, colId) {
      let terug = false;
      if (colId === 'datum')
      {
        var utc = moment(waarde, "DD-MM-YYYY", true)
        if(!utc.isValid()) {terug = true}
      }
      that.setState({ error: terug })
    } 


    ValueCheck(that,waarde,type,colId) {
      let data = this.state.data;
      let staffel = this.state.staffel;
      let staffeldata = staffel.data;

      let save = true;
      let error = false;
      let aanpassen = 0;
      var utc = null;
      data[colId] = waarde;

      if (colId === 'alljobs')
      {
        aanpassen = 1
        if (waarde === true) {
          data.alljobs = '1'
        }
        if (waarde === false) {
          data.alljobs = '0'
        }
      }

      if (colId === 'allaccounts')
      {
        aanpassen = 1
        if (waarde === true) {
          data.allaccounts = '1'
        }
        if (waarde === false) {
          data.allaccounts = '0'
        }
      }

      if (colId === 'datum')
      {
        utc = moment(waarde, "DD-MM-YYYY", true)
        if(!utc.isValid()) {error = true}
      }
      if (aanpassen === 1)
      {
         // Nu aanpassen van gridtabel
        for (var teller = 0; teller < staffeldata.length; teller++) 
        {
          let stdata = staffeldata[teller];

          stdata.chk_job = data.alljobs;
          stdata.chk_account = data.allaccounts;
          staffeldata[teller] = stdata;
        }
        staffel.data = staffeldata;
        var params = {
          force: true,
        };
        if (gridapi) {gridapi.api.refreshCells(params)}
      }
      if (save === true)
      {
        utc = moment(data.datum, "DD-MM-YYYY", true)
        if(!utc.isValid()) {save = false}
      }
      if (save === true)
      {
         var tarief = (data.tarief && data.tarief.constructor === String) ? parseFloat(data.tarief) : data.tarief;
         if (isNaN(tarief)) {tarief = 0}
         if (tarief === 0)
         {
            save = false
         }
      }
      that.setState({error:error})
      this.setState({data:data,staffel:staffel,save:save})
    }       

    async CellClick(waarde) {
        return;
    }

    async CellCheck(params,waarde,datacol) {
        var colId = params.props.column.getId();
        params.props.data.save = true;
        if (colId === 'chk_job')
        {
             params.props.data.chk_job = waarde;
        }

        if (colId === 'chk_account')
        {
          params.props.data.chk_account = waarde;
        }
    }       





    componentWillUnmount() {
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var top = '50px';
        var left = '0px';
        var bottom = '10px';

        return (
            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                  <Grid container spacing={1}>
                    <Grid item sm={4} md={4} lg={4}>
                      <EditDateTime className="card-title m-t-10"
                        value={this.state.data.datum}
                        coltype = 'STR'                        
                        labelText="Begin datum nieuwe tarieven"
                        inputProps={{value:this.state.data.datum}}
                        formControlProps={{fullWidth: true}}
                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'datum')}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'datum')}
                      />
                      <EditString className="card-title m-t-10"
                        value={this.state.data.tarief}
                        coltype = 'NUM'                        
                        labelText="Aanpassingspercentage"
                        suffix = 'PERC'
                        decimal={4}
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'tarief')}
                      />
                      <Grid container spacing={1}>
                        <Grid item sm={6} md={6} lg={6}>
                          <div style={{width:'100%',textAlign:'left', marginLeft:'-20px',marginTop: '10px'}}>
                            <EditCheck className="card-title m-t-10"
                              value={this.state.data.alljobs}
                              labelPlacement="start"
                              labelText="Alle Jobs"
                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'alljobs')}
                            />
                          </div>
                        </Grid>
                        <Grid item sm={6} md={6} lg={6}>
                          <div style={{width:'100%',textAlign:'left', marginLeft:'-20px',marginTop: '10px'}}>
                            <EditCheck className="card-title m-t-10"
                              value={this.state.data.allaccounts}
                              labelPlacement="start"
                              labelText="Klanten"
                              ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'allaccounts')}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={8} md={8} lg={8}>
                      { this.state.staffel && this.state.data.alljobs === '0' && this.state.staffel.kol && this.state.staffel.data && 
                        <TableGrid
                          tableheight="700px" 
                          columns={this.state.staffel.kol}
                          data={this.state.staffel.data} 
                          sizetofit={false}
                          sortable={false}
                          suppressMenu={true}
                          enableRowGroup={false}
                          suppressColumnVirtualisation={true}    
                          CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                          CellValueCheck={(params,value,error) => this.CellCheck(params,value,error)}
                          filter={false}
                          sleutel='AllJobDefMainIndex'
                          editable={true}
                          GridReady={this.onGridReady}
                        />
                      }
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  {this.state.save &&
                    <Button color="primary"  onClick={() => this.handleSave()}>
                      Verwerken
                    </Button>
                  }
                </DialogActions>
            </Dialog>
        );
    }
}


PopJobTarieven.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobTarieven)));

