import { GETTIMEREGISTRATION_SUCCESS, GETTIMEREGISTRATIONSFROMUSERONDAY_SUCCESS, GETALLTIMEREGISTRATION_SUCCESS } from '../constants/';

const INIT_STATE = {
    MainTimeRegistrationData: null,
    TimeRegistrationsOnDayData: null,
    AllData:null,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        
        case GETALLTIMEREGISTRATION_SUCCESS:
            return {
                ...state,
                AllData: action.payload
            };

        case GETTIMEREGISTRATION_SUCCESS:
            return {
                ...state,
                MainTimeRegistrationData: action.payload
            };

        case GETTIMEREGISTRATIONSFROMUSERONDAY_SUCCESS:
            return {
                ...state,
                TimeRegistrationsOnDayData: action.payload
            };

        default:
            return state;
    }
};
