import { GETTIMEREGISTRATION_SUCCESS, GETTIMEREGISTRATIONSFROMUSERONDAY_SUCCESS,GETALLTIMEREGISTRATION_SUCCESS } from '../constants/';
  
  const SqlFunction = require("../../functions/sql_functions");
  const axios = require('axios');

  export const GetTimeRegistrationSuccess = (payload) => {
    return {
      type: GETTIMEREGISTRATION_SUCCESS,
      payload: payload.response.data
    }
  } 

  export const GetAllTimeRegistrationSuccess = (payload) => {
    return {
      type: GETALLTIMEREGISTRATION_SUCCESS,
      payload: payload.response.data
    }
  } 
  
  

  export const GetTimeRegistrationsOnDaySuccess = (payload) => {
    return {
      type: GETTIMEREGISTRATIONSFROMUSERONDAY_SUCCESS,
      payload: payload.response.data
    }
  } 

  export function GetLatestTimeRegistrationFromUser(userid) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetLatestTimeRegistrationFromUser', { userid: userid });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetTimeRegistrationSuccess({ response })) },
            err => { dispatch(GetTimeRegistrationSuccess(null)) }
        );
    }
  } 
  
  export function GetTimeRegistrationFromUserById(userid, id) {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetTimeRegistrationFromUserById', { userid: userid, id: id });
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetTimeRegistrationSuccess({ response })) },
            err => { dispatch(GetTimeRegistrationSuccess(null)) }
        );
    }
  }
  
export function GetAllTimeRegistration() {
    return function action(dispatch) {
        let authOptions = SqlFunction.SetAuth('GetAllTimeReg', {});
        const request = axios(authOptions);
        return request.then(
            response => { dispatch(GetAllTimeRegistrationSuccess({ response })) },
            err => { dispatch(GetAllTimeRegistrationSuccess(null)) }
        );
    }
  }

  export function GetTimeRegistrationsFromUserOnDay(userid, date) {
    return function action(dispatch) {
      let authOptions = SqlFunction.SetAuth('GetTimeRegistrationsFromUserOnDay', { userid: userid, date: date });
      const request = axios(authOptions);
      return request.then(
        response => { dispatch(GetTimeRegistrationsOnDaySuccess({ response })) },
        () => { dispatch(GetTimeRegistrationsOnDaySuccess(null)) }
      );
    }
  } 

  export function CreateTimeRegistration() {
    return function action(dispatch) {
      let response = { data: null };
      let data = { id:0, user_id:0, account_number:'',account_id:'',account_name:'',date:'', activiteit:'',activiteit_txt:'', time_from:'', time_till:'', week:'', activity:'', description:'' };
      response.data = { data:data };
      dispatch(GetTimeRegistrationSuccess({ response }));
    }
  }