import React, { createRef } from 'react';
import classnames from 'classnames';
import moment from 'moment'
import 'moment/locale/nl-be'
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import AccountMultiPlus from '@material-ui/icons/AccountMultiPlus';
import GraphicEq from '@material-ui/icons/GraphicEq';
import Expences from '@material-ui/icons/Expences';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import EditString from "../../components/editstring.jsx";
import EditCheck from "../../components/editcheck.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";
import FileDownload from "../../components/FileDownload.jsx";
import EditSelect from "../../components/editselect.jsx";
import {AllPostcodes} from "../../redux/settings/paramActions";
import {AllAccounts,GetAccount,CreateAccount} from "../../redux/settings/accountActions";
import {GetContact,AllContacts} from "../../redux/settings/contactActions";
import {AllUsers,GetUser} from "../../redux/settings/userActions";
import {CreateRegistration,GetRegistration,GetDocRegistration,GetCorRegistration,GetJobsRegistration, setTab,AllRegistratie} from "../../redux/settings/registrationActions";
import {zetview,clickview,setPanelRight, setTitleRight,setSubTitle,zetListMain,ZetListData, zetpopup,resetview} from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import Dropzone from 'react-dropzone';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SubSpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SubSpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ChangedAccData from '@material-ui/icons/BorderColor';
import BookPlus from '@material-ui/icons/BookPlus';
import SaveIcon from '@material-ui/icons/Save';
import FilePlus from '@material-ui/icons/FilePlus';
import CalendarPlus from '@material-ui/icons/CalendarPlus';
import Delete from "@material-ui/icons/Delete";
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import PopJobAanvrNum from "../jobs/PopJobAanvrNum.jsx";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepConnector from '@material-ui/core/StepConnector';
import PopCreateJob from "../jobs/PopCreateJob.jsx";
import Slider from '@material-ui/core/Slider';
import Timeline from "../../components/TimeLine.jsx";
import PopDetailCor from "../templates/PopDetailCor.jsx";


const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const dropzoneRef = createRef();
const openDialog = () => {
  // Note that the ref is set async,
  // so it might be null at some point 
  if (dropzoneRef.current) {
    dropzoneRef.current.open()
  }
};
moment.locale('nl-be');
const accdropid = GenFunc.MakeId()
const accframeid = GenFunc.MakeId()
const accformid = GenFunc.MakeId()

var dropdoc = null;
var gridapi = null;


const mapStateToProps = state => ({
  ...state
});


class Registrationpage extends React.Component {

  state = {
    DownloadData:{id:''},
    tableheight: Math.floor(window.innerHeight * .70) + 'px',
    tabvalue: this.props.registration && this.props.registration.LastTab ? this.props.registration.LastTab : '1',
    edit:this.props.registration && this.props.registration.MainData && this.props.registration.MainData.data && this.props.registration.MainData.data.id === 0 ? true : false,
    editfact:this.props.registration && this.props.registration.MainData && this.props.registration.MainData.data && this.props.registration.MainData.data.id === 0 ? true : false,
    workflowstories:null,
    save:false,
    jobgraph:false,
    subsave:false,
    adrestel:0,
    checkval:false,
    PopUpScreen:null,
    jaar:new Date().getFullYear(),    
    data:this.props.registration && this.props.registration.MainData && this.props.registration.MainData.data && this.props.registration.MainData.data.id === 0 ? this.props.registration.MainData.data : null,
    speedactions:[
      { icon: <EditIcon />, name: 'Edit', tooltip:'Aanpassen gegevens' },
      { icon: <MdiNewBox />, name: 'New',  tooltip:'Nieuwe registratie'  },
      { icon: <SaveIcon />, name: 'Save', tooltip:'Bewaren gegevens'  },
      { icon: <Delete />, name: 'Delete', tooltip:'Verwijderen gegevens'  },

    ],
    subspeedactions: [
      { icon: <AccountMultiPlus />, name: 'Contact', tooltip: 'Nieuwe contactpersoon' },
      { icon: <BookPlus />, name: 'NewRegistratie', tooltip: 'Nieuwe registratie' },
      { icon: <ChangedAccData />, name: 'ChangedAccData', tooltip: 'Gewijzigde klantgegevens' },
    ],
    speedopen: false,
    subspeedopen: false,    
    subspeedzien:true,

  };



  constructor(props, context) {
    super(props);
    this.resizeFunction = this.resizeFunction.bind(this);    
    this.getContextMenuItems = this.getContextMenuItems.bind(this);
    this.ValueCheck = this.ValueCheck.bind(this);
    this.adrchange = this.adrchange.bind(this);
    this.Fgov = this.Fgov.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.SecValueCheck = this.SecValueCheck.bind(this);
    this.SecCellClick = this.SecCellClick.bind(this);
    this.DocCellClick = this.DocCellClick.bind(this);

    this.valuejaar = this.valuejaar.bind(this);
    this.JaarChange = this.JaarChange.bind(this);
    this.GetGrafJob = this.GetGrafJob.bind(this);
    this.getGrafJobStories = this.getGrafJobStories.bind(this);

    this.ContCellClick = this.ContCellClick.bind(this);
    this.JobCellClick = this.JobCellClick.bind(this);
    this.ClosePopCreaJobs = this.ClosePopCreaJobs.bind(this);
    
    
    this.ContDetClick = this.ContDetClick.bind(this);
    this.KlantFactClick = this.KlantFactClick.bind(this);


    this.UserDetClick = this.UserDetClick.bind(this);

    this.handleSpeedClose = this.handleSpeedClose.bind(this);
    this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
    this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
    this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);

    this.ZetEdit = this.ZetEdit.bind(this);
    this.CreaAcount = this.CreaAcount.bind(this);
    this.SecSaveAll = this.SecSaveAll.bind(this);
    this.CheckSave = this.CheckSave.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.MainSave = this.MainSave.bind(this)
    this.checkconfirm = this.checkconfirm.bind(this)
    this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this)
    this.handleJa = this.handleJa.bind(this)
    this.handleJobRegJa = this.handleJobRegJa.bind(this)
    this.JobNumAanvr = this.JobNumAanvr.bind(this)
    this.CheckOnhold = this.CheckOnhold.bind(this)
    this.ActionClick = this.ActionClick.bind(this)
    this.SubActionClick = this.SubActionClick.bind(this)
    this.ErrorCheck = this.ErrorCheck.bind(this);

    this.AccDetClick = this.AccDetClick.bind(this)
    this.ClosePopJobs = this.ClosePopJobs.bind(this)

    this.handleJobAangRegJa = this.handleJobAangRegJa.bind(this)
    this.handleJobAangVatJa = this.handleJobAangVatJa.bind(this)

    this.CorCellClick = this.CorCellClick.bind(this);
    this.ClosePopDetailCor = this.ClosePopDetailCor.bind(this);

    this.onGridReadyJobs = this.onGridReadyJobs.bind(this);

  }


  valuejaar(value: number) {
    return `${value}`;
  }

  JaarChange(event,value) {
    this.GetGrafJob(value);
    this.setState({jaar:value})
  }

  onGridReadyJobs(params) {
    this.jobsgridapi = params;
    gridapi = params;
  }


  async GetGrafJob(jaar) {
    let data = {id:this.props.registration.MainData.data.id,jaar:jaar}
    let retdata = await SqlFunction.DoSql('GetGrafRegJob', data);
    this.getGrafJobStories(retdata)
  }

  async getGrafJobStories(dataworkflows) 
  {
    var stories = [];
    const { classes } = this.props;

    var teller = 0;
    let storiedata = {}
    let inverted = false
    if (dataworkflows)
    {
      for (teller = 0; teller < dataworkflows.length; teller++) 
      {
        let data = dataworkflows[teller];
        data.index = teller;
        storiedata = 
        {
          badgeColor:data.status === 'c8011e2728d9d4c2f636f067f89cc14862c' ? 'success' : 'primary',
          inverted:inverted,
          id:data.id,
          jobid:data.job_id,
          badgeIcon: data.start_date.substring(0, 5),
          index:teller,
          title:data.description,
          description:data.description,
          badgeClick: () =>this.JobCellClick({data:data}),
          titleColor: "primary",
          body: (
            <div>
              <Grid container spacing={1}>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Code</small>
                  <h6>{data.code}</h6>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Status</small>
                  <h6>{data.status_txt}</h6>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Periode</small>
                  <h6>{data.periode}</h6>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <small className="text-muted p-t-30 db">Land</small>
                  <h6>{data.country_txt}</h6>
                </Grid>
              </Grid>
            </div>
          ),
          footer: (
            <div style={{overflow:'auto'}}>
              <Stepper nonLinear activeStep={0} connector={<QontoConnector />}>
                {data.workflows.map(datavld => {
                  return (
                    <Step key={GenFunc.MakeId()}>
                      <StepButton
                        completed={datavld.completed === '1' ? true : false}
                        active={false}
                        classes={{root: classes.stepIcon}}           
  
                      >
                        {datavld.title}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>  
            </div>
          )
        }
        stories.push(storiedata);
        inverted = !inverted;
      }
    }
    this.setState({workflowstories:stories})   
  }  

  async ClosePopDetailCor(terdata) {
    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    await this.props.dispatch(GetCorRegistration(this.props.registration.MainData.data.id))
  }


  async ClosePopJobs() {

    if (this.jobsgridapi) {this.jobsgridapi.refreshData()}
    this.setState({ PopUpScreen: null });
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    await this.props.dispatch(GetRegistration(this.props.registration.MainData.data.id))
    let prevtab = this.state.tabvalue
    this.handleTabChange(null,prevtab)
  }

  async ClosePopCreaJobs(terdata) {

    this.setState({ PopUpScreen: null });
    if (this.jobsgridapi) {this.jobsgridapi.refreshData()}
    this.props.dispatch(resetview())
    this.props.dispatch(zetpopup(null));
    await this.props.dispatch(GetJobsRegistration(this.props.registration.MainData.data.id))
    await this.props.dispatch(ZetListData('GetJobsRegistration',this.props.registration.MainData.data.id));
    await this.props.dispatch(zetListMain('GetJobsRegistration',this.props.registration.MainData.data.id));

    let prevtab = this.state.tabvalue
    this.handleTabChange(null,prevtab)
    if (terdata.id)
    {
      let jobdata = await SqlFunction.DoSql('GetJob', terdata);
      let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
      window.scrollTo(0, 0);
      this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} data={jobdata} close={this.ClosePopJobs} title={title} />)));
    }

  }



  async handleTabChange(event, newValue)  {
    if (newValue === '1')
    {
      let subspeedactions = [
      { icon: <FilePlus />, name: 'Document', tooltip: 'Certificaat toevoegen' },
      ]
      this.setState({subspeedactions:subspeedactions})
//      await this.props.dispatch(GetContAccount(this.props.account.MainData.data.id))
    }

    if (newValue === '4')
    {
      // Documenten
      let subspeedactions = [
      { icon: <FilePlus />, name: 'Document', tooltip: 'Document toevoegen' },
      ]
      this.setState({subspeedactions:subspeedactions})
      await this.props.dispatch(GetDocRegistration(this.props.registration.MainData.data.id))
    }
    if (newValue === '5')
    {
      // Documenten
      let subspeedactions = [
      { icon: <FilePlus />, name: 'Correspondentie', tooltip: 'Document toevoegen' },
      ]
      this.setState({subspeedactions:subspeedactions})
      await this.props.dispatch(GetCorRegistration(this.props.registration.MainData.data.id))
    }


    if (newValue === '2')
    {
      let subspeedactions = [
        { icon: <CalendarPlus />, name: 'Job', tooltip:'Nieuwe job maken'  },
        { icon: <Expences />, name: 'Onkostennota', tooltip: 'Onkostennota' },
        { icon: <GraphicEq />, name: 'Grafische voorstelling', tooltip: 'Grafische voorstelling' },
      ]
      this.setState({subspeedactions:subspeedactions})
      // Jobs
      await this.props.dispatch(GetJobsRegistration(this.props.registration.MainData.data.id))
    }
    this.props.dispatch(setTab(newValue))
    this.setState({tabvalue:newValue});

  };

  handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:true });
  };

  handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
  };

  handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
  };

  handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
  };

  async AccDetClick(waarde) {
    this.props.dispatch(zetview(this.props.registration.MainData.data.type_txt+' '+this.props.registration.MainData.data.account_name,this.props.registration.MainData.data.id,'REG'));
    this.props.dispatch(clickview({id:this.props.registration.MainData.data.accountid, vorm:'ACC'}));
    await this.props.dispatch(AllAccounts(this.props))
    this.props.dispatch(setSubTitle('Klantenfiche'))
    await this.props.dispatch(GetAccount(this.props.registration.MainData.data.accountid))
    this.props.history.push('/account');
    return;
  }


  async ActionClick(name)
  {
    let hval = 'http://';

    switch (name) 
    {
      case 'Edit':
        this.setState({edit:true,data:this.props.registration.MainData.data,speedopen:false})
        break;

      case 'New':
        await this.props.dispatch(CreateRegistration())
        this.setState({edit:true,data:this.props.registration.MainData.data,speedopen:false})
        break;

      case 'Save':
        this.MainSave();
        break;

      case 'Delete':
          let acc_id = this.props.registration.MainData.data.accountid;
          await SqlFunction.DoSql('DelRegistration',this.props.registration.MainData.data);
          if (acc_id && acc_id.length > 3)
          {
            this.props.dispatch(clickview({id:acc_id, vorm:'ACC'}));
            await this.props.dispatch(AllAccounts(this.props))
            this.props.dispatch(setSubTitle('Klantenfiche'))
            await this.props.dispatch(GetAccount(acc_id))
            this.props.history.push('/account');
          }
          else
          {
            await this.props.dispatch(AllRegistratie(this.props))  
            this.props.history.push('/userdashboard');
          }


        break;


      case 'Document':
        dropdoc = null;
        let element = document.getElementById(accdropid);
        element.click()
        break;


      case 'Fgov':
        if (this.state && this.state.data && this.state.data.name && this.state.data.name.length > 2)
        {
           hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord='+this.state.data.name+"&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
           window.open(hval,"_blank");
        }
        else
        {
          if (this.props.account && this.props.account.MainData && this.props.account.MainData.data && this.props.account.MainData.data.name && this.props.account.MainData.data.name.length > 2)
          {
             hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord='+this.props.account.MainData.data.name+"&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
             window.open(hval,"_blank");
          }
          else
          {
             hval = 'https://kbopub.economie.fgov.be'
             window.open(hval,"_blank");
          }
        }

        break;

      default:    
        break;
    }     
    this.setState({speedopen:false});
  }
  async SubActionClick(name) {
    let data = null;
    switch (name) {
      case 'Edit':
        this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data, speedopen: false })
        break;

      case 'New':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        await this.props.dispatch(CreateAccount())
        this.setState({ edit: true, editfact: true, data: this.props.account.MainData.data, speedopen: false })
        break;

      case 'Grafische voorstelling':
        let jobgraph = !this.state.jobgraph;
        this.GetGrafJob(this.state.jaar);
        this.setState({jobgraph:jobgraph})
        break;

      case 'Onkostennota':
        data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.registration.MainData.data.accountid
        data.user_id = this.props.user.userData.id
        data.registration_id = this.props.registration.MainData.data.id
        data.kantoor_id = this.props.registration.MainData.data.btwkant
        data.country = this.props.registration.MainData.data.land
        data.start_date = GenFunc.Get_Vandaag();
        data.registratie_txt = this.props.registration.MainData.data.description
        data.account_name = this.props.registration.MainData.data.account_name
        data.country_txt = this.props.registration.MainData.data.land_txt
        data.kantoor_txt = this.props.registration.MainData.data.btwkant_txt
        data.user_name = this.props.user.userData.name
        data.create_date = GenFunc.Get_Vandaag();
        data.jaar = GenFunc.Get_Jaar();
        data.dagen = 0;
        data.job_id='c901f08f895fb98ab9159f51fd0297e236d';
        data.job_txt = 'Boeken onkosten';
        data.origin = 'REGISTRATIE';
        data.type = this.props.registration.MainData.data.type;
        this.setState({ PopUpScreen: (<PopCreateJob open={true} data={data} close={this.ClosePopCreaJobs} title='Aanmaken job' />) });
        break;


      case 'Correspondentie':
        data = await GenFunc.zetdataleeg('Cor');
        data.registration_id = this.props.registration.MainData.data.id;
        data.account_id = this.props.registration.MainData.data.accountid;
        data.country = this.props.registration.MainData.data.land;
        data.datum = GenFunc.Get_Vandaag();
        data.account_name = this.props.registration.MainData.data.account_name
        data.country_txt = this.props.registration.MainData.data.land_txt;
        this.setState({ PopUpScreen: (<PopDetailCor open={true} data={data} close={this.ClosePopDetailCor} title='Aanmaken document' />) });
        break;


      case 'Job':
        data = await GenFunc.zetdataleeg('Job');
        data.account_id = this.props.registration.MainData.data.accountid
        data.user_id = this.props.user.userData.id
        data.registration_id = this.props.registration.MainData.data.id
        data.kantoor_id = this.props.registration.MainData.data.btwkant
        data.country = this.props.registration.MainData.data.land
        data.start_date = GenFunc.Get_Vandaag();
        data.registratie_txt = this.props.registration.MainData.data.description
        data.account_name = this.props.registration.MainData.data.account_name
        data.country_txt = this.props.registration.MainData.data.land_txt
        data.kantoor_txt = this.props.registration.MainData.data.btwkant_txt
        data.user_name = this.props.user.userData.name
        data.create_date = GenFunc.Get_Vandaag();
        data.jaar = GenFunc.Get_Jaar();
        data.type = this.props.registration.MainData.data.type;        
        data.origin = 'REGISTRATIE';
        data.dagen = 0;
        this.setState({ PopUpScreen: (<PopCreateJob open={true} data={data} close={this.ClosePopCreaJobs} title='Aanmaken job' />) });
        break;


      case 'Document':
        if (this.state.save === true && (this.state.edit === true || this.state.editfact === true)) 
        {
          await this.checkconfirm(this.props.account.MainData);
        }
        else
        {
          this.setState({edit:false, editfact:false})
        }

        dropdoc = null;
        let element = document.getElementById(accdropid);
        element.click()
        break;


      default:
        break;
    }
    this.setState({ speedopen: false });
  }

  Fgov(website)
  {
      let hval = 'http://'+website;
      if (website === 'FGOV')
      {
         hval = 'https://kbopub.economie.fgov.be/kbopub/zoeknaamfonetischform.html?searchWord='+this.props.account.MainData.data.name+"&_oudeBenaming=on&pstcdeNPRP=&postgemeente1=&ondNP=true&_ondNP=on&ondRP=true&_ondRP=on&rechtsvormFonetic=ALL&vest=true&_vest=on&filterEnkelActieve=true&_filterEnkelActieve=on&actionNPRP=Zoek"
      }
      window.open(hval,"_blank");
  };

  async CreaAcount()
  {
    await this.props.dispatch(CreateAccount())
    this.setState({edit:true,editfact:true, data:this.props.account.MainData.data})
  }

  ZetEdit()
  {
    if (this.props.registration.MainData && this.props.registration.MainData.data)
    {
     this.setState({edit:true, data:this.props.registration.MainData.data})
    }
  }

  async componentDidMount(){
    window.addEventListener("resize", this.resizeFunction);
    if (!this.props.registration.AllRegData)
    {
      this.props.dispatch(AllRegistratie())  
    }

    this.props.dispatch(setTitleRight('Registraties'))
    this.props.dispatch(setSubTitle('Registratie'))
    this.props.dispatch(setPanelRight(true))
    let prevtab = this.state.tabvalue
    await this.setState({ tabvalue: '' })
    if (prevtab === '2')
    {
      await this.props.dispatch(GetJobsRegistration(this.props.registration.MainData.data.id))
    }
    this.handleTabChange(null,prevtab)
    await this.setState({ tabvalue: prevtab })


    if (this.props.registration.MainData)
    {
      if (this.props.registration.MainData.data && this.props.registration.MainData.data.id === 0)
      {
        this.setState({edit:true,save:false,subsave:false,data:this.props.registration.MainData.data})
      }
      else
      {
        if (this.props.registration.MainData.data && this.props.registration.MainData.data.onhold === '1')
        {        
            this.CheckOnhold()

        }
        else
        {
            this.JobNumAanvr()
        }
      }
    }

  }

  componentWillUnmount() 
  {
    this.checkconfirm(this.props.account.MainData);    
    this.props.dispatch(setPanelRight(false))
    this.props.dispatch(setTitleRight(''))
    window.removeEventListener("resize", this.resizeFunction);
  }

  async componentWillReceiveProps(nextProps, nextState) 
  {
    if (this.props.registration.MainData)
    {
      if (this.props.registration.MainData.data.id !== nextProps.registration.MainData.data.id)
      {
        await this.checkconfirm(this.props.registration.MainData);
        if (nextProps.registration.MainData.data.id === 0)
        {
          this.setState({edit:true,save:false,subsave:false})
          this.handleTabChange(null,'1')
        }
        else
        {
          let prevtab = this.state.tabvalue
          await this.setState({ tabvalue: '' })
          await this.props.dispatch(GetDocRegistration(nextProps.registration.MainData.data.id))
          await this.props.dispatch(GetCorRegistration(nextProps.registration.MainData.data.id))
          if (prevtab === '2')
          {
            await this.props.dispatch(GetJobsRegistration(this.props.registration.MainData.data.id))
          }
          this.setState({edit:false,editfact:false,save:false,subsave:false})
          this.handleTabChange(null,prevtab)
          await this.setState({ tabvalue: prevtab })
          if (nextProps.registration.MainData.data && nextProps.registration.MainData.data.onhold === '1')
          {        
              this.CheckOnhold()

          }
          else
          {
              this.JobNumAanvr()
          }
        }
      }
    }
  }

  async adrchange(teller)
  {
    await this.setState({adrestel:teller,checkval:true})
  }

  async handleJa(parameters)
  {
    if (this.state.save === true)
    {
      await SqlFunction.DoSql('ChgRegistratie',this.state.data);
    }
    if (this.state.subsave === true)
    {
    }
    this.setState({edit:false,editfact:false, save:false,subsave:false})
  }


  checkconfirm (parameters) 
  {

    if (this.state.save === true || this.state.subsave === true)
    {
      confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,        
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <h1>Aanpassingen niet doorgevoerd.</h1>
            <p>Wens je de aanpassingen alsnog te bewaren?</p>
            <button
              onClick={() => {
                this.handleJa(parameters);
                onClose();
              }}
            >
              Ja
            </button>
            <button onClick={() => {
                this.setState({edit:false,editfact:false, save:false,subsave:false,checkval:false})
                onClose();
              }}
            >
              Nee
            </button>
          </div>
        );
      }
      });
    }

  }

  resizeFunction() 
  {
    if (window.innerHeight >= 960) 
    {
      this.setState({tableheight: Math.floor(window.innerHeight * .70) + 'px'});
    }
    else
    {
      this.setState({tableheight: '600px'});
    }
  }


  async handleJobRegJa() {
    if (this.props.registration.MainData.data) 
    {
      let chdata = this.props.registration.MainData.data;
      chdata.jobcrea = '1';
      let retdata = await SqlFunction.DoSql('ChgRegistratie',chdata);
      this.setState({edit:false,editfact:false, save:false,data:null,checkval:false})
      await this.props.dispatch(GetRegistration(retdata.id))
      let jobzkdata = {id:this.props.registration.MainData.data.job_id_aanvr}
      let jobdata = await SqlFunction.DoSql('GetJob',jobzkdata);
      let title = 'Jobdetail - '+ jobdata.data.description +' - '+jobdata.data.account_name
      this.setState({PopUpScreen:(<PopJobAanvrNum open={true} data={jobdata} close={this.ClosePopJobs} title={title}  />)});
    }
  }

  async JobNumAanvr()
  {
      let jobcrea = false
      if (this.props.registration.MainData && this.props.registration.MainData.data && (this.props.registration.MainData.data.type_txt === 'Vat Refund' || this.props.registration.MainData.data.type === "ec01cb3c87e4b5ce2fe28308fd9f2a7baf3" ))
      {
          // Is nummer in aanvraag ? en geen nummer
          // controle of aangiftes gemaakt moeten worden
          let d = new Date();
          let jaar = d.getFullYear();
          if (parseInt(this.props.registration.MainData.data.aan_jaar) < jaar)
          {              
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,        
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Job(s) Aangifte maken voor VAT Refund ?</h1>
                    <button
                      onClick={() => {
                        this.handleJobAangVatJa();
                        onClose();
                      }}
                    >
                      Ja
                    </button>
                    <button onClick={() => {
                      onClose();
                    }}
                    >
                      Nee
                    </button>
                  </div>
                );
                }
            });            
          }
          // einde controle
      }

      if (this.props.registration.MainData && this.props.registration.MainData.data && (this.props.registration.MainData.data.type_txt === 'Directe Registratie' || this.props.registration.MainData.data.type === "c401ca14238a0b923820dcc509a6f75849b" ))
      {
          // Is nummer in aanvraag ? en geen nummer
          if (this.props.registration.MainData.data.jobaanvr === true)
          {
            if (this.props.registration.MainData.data.job_id_aanvr.length < 1 && this.props.registration.MainData.data.regnummer.length < 1)
            {
              jobcrea = true
            }
          }
          else
          {
            if (this.props.registration.MainData.data.job_id_aanvr.length < 1 && this.props.registration.MainData.data.regnummer.length < 1)
            {
              jobcrea = true
            }
          }
          if (jobcrea === true)
          {
            confirmAlert({
              closeOnEscape: false,
              closeOnClickOutside: false,        
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Job Aanvraag registratienummer aanmaken?</h1>
                    <button
                      onClick={() => {
                        this.handleJobRegJa();
                        onClose();
                      }}
                    >
                      Ja
                    </button>
                    <button onClick={() => {
                      onClose();
                    }}
                    >
                      Nee
                    </button>
                  </div>
                );
                }
            });            
          }
          else
          {
              // controle of aangiftes gemaakt moeten worden
              let d = new Date();
              let jaar = d.getFullYear();

              if (this.props.registration.MainData.data.regnummer.length > 1 && parseInt(this.props.registration.MainData.data.aan_jaar) < jaar)
              {              
                confirmAlert({
                  closeOnEscape: false,
                  closeOnClickOutside: false,        
                  customUI: ({ onClose }) => {
                    return (
                      <div className='custom-ui'>
                        <h1>Job(s) Aangifte maken voor registratie ?</h1>
                        <button
                          onClick={() => {
                            this.handleJobAangRegJa();
                            onClose();
                          }}
                        >
                          Ja
                        </button>
                        <button onClick={() => {
                          onClose();
                        }}
                        >
                          Nee
                        </button>
                      </div>
                    );
                    }
                });            


              }
              // einde controle
          }
          // Nummer niet in aanvraag (nummer aanvragen ?)
      }

  }

  async handleJobAangRegJa() 
  {
    if (this.props.registration.MainData && this.props.registration.MainData.data) 
    {
      let data = this.props.registration.MainData.data
      data.registration_id = data.id
      await SqlFunction.DoSql('CreaJobAanReg',data);
      let prevtab = this.state.tabvalue
      this.handleTabChange(null,prevtab)


    }
  }

  async handleJobAangVatJa() 
  {
    if (this.props.registration.MainData && this.props.registration.MainData.data) 
    {
      let data = this.props.registration.MainData.data
      data.registration_id = data.id
      await SqlFunction.DoSql('CreaJobAanVat',data);
      let prevtab = this.state.tabvalue
      this.handleTabChange(null,prevtab)


    }
  }


  async CheckOnhold()
  {
      if (this.props.registration.MainData && this.props.registration.MainData.data && this.props.registration.MainData.data.onhold === '1')
      {
        // Is nummer in aanvraag ? en geen nummer
          confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,        
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Deze klant staat onhold, geen verwerkingen toegelaten</h1>
                  <h1>{this.props.registration.MainData.data.infohold}</h1>
                  <button
                    onClick={() => {
                      onClose();
                    }}
                  >
                    Ok
                  </button>
                </div>
              );
              }
          });            
      }

  }

  async MainSave() {
    if (this.state.save === true) 
    {
      let retdata = await SqlFunction.DoSql('ChgRegistratie',this.state.data);
      this.setState({edit:false,editfact:false, save:false,data:null,checkval:false})
      await this.props.dispatch(GetRegistration(retdata.id))
      this.JobNumAanvr()

    }
  }

  async SecCellClick(waarde) {
    var data = waarde.data
    let column = waarde.column.colDef.field;
    let retdata = null;
    if (column === 'save' && GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit'))
    {
      if (data.save === true) 
      {
        retdata = await SqlFunction.DoSql('ChgSecUserParam',data);
        if (retdata.nwzip === true)
        {
          this.props.dispatch(AllPostcodes())
        }
        waarde.data.save='';
        waarde.node.setData(waarde.data); 
        this.setState({subsave:false})
      }
    }
    return;
  }

  async ContCellClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name,this.props.account.MainData.data.id,'ACC'));
    this.props.dispatch(clickview({id:waarde.data.id, vorm:'CONT'}));

    await this.props.dispatch(AllContacts(this.props))
    this.props.dispatch(setSubTitle('Contactpersoon'))
    await this.props.dispatch(GetContact(waarde.data.id))
    this.props.history.push('/contact');
    return;
  }

  async JobCellClick(waarde) {
    let column = waarde.column.colDef.field;
    var data = waarde.data;    
    if (column === 'verwijder')
    {
      if (data.verwijder && (data.verwijder === '1' || data.verwijder === 1 || data.verwijder === true))
      {
          await SqlFunction.DoSql('DeleteJob', data);
          if (this.jobsgridapi) {this.jobsgridapi.purData()}
      }      
      return;
    }

    let jobdata = await SqlFunction.DoSql('GetJob', waarde.data);
    let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name;
    window.scrollTo(0, 0);
    this.props.dispatch(zetpopup((<PopJobAanvrNum open={true} history={this.props.history} data={jobdata} close={this.ClosePopJobs} title={title} />)));
    return;
  }


  async KlantFactClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name,this.props.account.MainData.data.id,'ACC'));
    this.props.dispatch(clickview({id:this.props.account.MainData.data.factklant, vorm:'ACC'}));
    await this.props.dispatch(GetAccount(this.props.account.MainData.data.factklant))
    return;
  }

  async ContDetClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name,this.props.account.MainData.data.id,'ACC'));
    this.props.dispatch(clickview({id:this.props.account.MainData.data.contact, vorm:'CONT'}));

    await this.props.dispatch(AllContacts(this.props))
    this.props.dispatch(setSubTitle('Contactpersoon'))
    await this.props.dispatch(GetContact(this.props.account.MainData.data.contact))
    this.props.history.push('/contact');
    return;
  }

  async UserDetClick(waarde) {
    this.props.dispatch(zetview(this.props.account.MainData.data.name,this.props.account.MainData.data.id,'ACC'));
    this.props.dispatch(clickview({id:this.props.account.MainData.data.user, vorm:'USR'}));
    await this.props.dispatch(AllUsers(this.props))
    this.props.dispatch(setSubTitle('Gebruiker'))
    await this.props.dispatch(GetUser(this.props.account.MainData.data.user))
    this.props.history.push('/user');
    return;
  }



  async DocCellClick(waarde) {
    let column = waarde.column.colDef.field;
    if (column === 'upload')
    {
      dropdoc = waarde.data;
      let element = document.getElementById(accdropid);
      element.click()
      return;
    }
    if (column === 'verwijder')
    {
      await SqlFunction.DoSql('DeleteFile',waarde.data);
      await this.props.dispatch(GetDocRegistration(this.props.registration.MainData.data.id))
      return;
    }
    await this.setState({DownloadData:waarde.data});
    document.getElementById(accformid).submit();
    return;
  }

  async CorCellClick(waarde) {
    if (waarde.data && waarde.data.id)
    {
      let cordata = await SqlFunction.DoSql('GetCor', waarde.data);
      let title = 'Document correspondentie - ' + cordata.data.description
      window.scrollTo(0, 0);
      this.props.dispatch(zetpopup((<PopDetailCor open={true} data={cordata.data} close={this.ClosePopDetailCor} title={title} />)));
    }
    return;
  }



  getContextMenuItems(params,sleutel) 
  {
    return null;
  }

  async SecSaveAll(items)
  {
    if (Array.isArray(items) && GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit'))
    {
      var i = 0;
      for (i = 0; i < items.length; i++) 
      {
        let data = items[i];
        await SqlFunction.DoSql('ChgSecUserParam',data);
      }
    }
  }                      


  CheckSave(data)
  {
    let terug = true
    terug = Validation.ValidCheck(this.props.settings.sub_title,data.type,'type',this,null,data) ;
    if (terug === false)
    {
      terug = Validation.ValidCheck(this.props.settings.sub_title,data.accountid,'accountid',this,null,data) ;
    }
    return !terug;
  }

  SecValueCheck(params,waarde) {
    if (GenFunc.allowed(this.props.user.userData.secrows,'Gebruikers','edit'))
    {
      params.props.data.save = true;
      this.setState({subsave:true})
    }
  }       

  async onDrop(files) {
    const formData = new FormData();
    this.handleTabChange(null,'0');
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
        formData.append('file', file);

    }
    if (dropdoc)
    {
      formData.set('accountid', dropdoc.account_id);
      formData.set('reg_id', dropdoc.reg_id);
      formData.set('kant_id', dropdoc.kant_id);

      formData.set('type', dropdoc.type);    
      formData.set('docid', dropdoc.id);    
      formData.set('docdata', dropdoc);    
    }
    else
    {
      formData.set('accountid', this.props.registration.MainData.data.accountid);
      formData.set('reg_id', this.props.registration.MainData.data.id);
      formData.set('kant_id', this.props.registration.MainData.data.btwkant);
      formData.set('type', 'registration');    
    }

    await SqlFunction.DoSqlBody('saveFile',formData);
    dropdoc = null;
    await this.props.dispatch(GetDocRegistration(this.props.registration.MainData.data.id))
    this.handleTabChange(null,'4');
  };

  async ErrorCheck(that, waarde, type, colId) {
    let data = this.state.data;
    let terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.registration.MainData.data, data);
    that.setState({ error: terug })
  }      


  async ValueCheck(that,waarde,type,colId) {
    let data = this.state.data;
    let terug = false;
    switch (colId) 
    {
      case 'account_name':
        data.account_name = waarde.value;
        data.accountid = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.value,colId,that,null,data) ;
        break;

      case 'direind_txt':
        data.direind_txt = waarde.value;
        data.direind = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.value,colId,that,null,data) ;
        break;

      case 'dirpomp_txt':
        data.dirpomp_txt = waarde.value;
        data.dirpomp = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.value,colId,that,null,data) ;
        break;

      case 'btwkant_txt':
        data.btwkant_txt = waarde.value;
        data.btwkant = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.value,colId,that,null,data) ;
        break;


      case 'land_txt':
        data.land_txt = waarde.value;
        data.land = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.value,colId,that,null,data) ;
        break;

      case 'periodes_txt':
        data.periodes_txt = waarde.text;
        data.periodes = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.text,colId,that,null,data) ;
        break;


      case 'type_txt':
        data.type_txt = waarde.text;
        data.type = waarde.id;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.text,colId,that,null,data) ;
        break;

      case 'regnummer':
        if (waarde && waarde.length > 0)
        {
            data.jobaanvr = false;
        }
        else
        {
            data.jobaanvr = true;
        }
        data[colId] = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde,colId,that,null,data) ;
        break;


      default:    
        data[colId] = waarde;
        terug = Validation.ValidCheck(this.props.settings.sub_title,waarde,colId,that,null,data) ;
        break;
    } 
    that.setState({error:terug})
    terug = await this.CheckSave(data);

    this.setState({save:terug,data:data,checkval:false})
  }       

  render() {
    const { classes } = this.props;
    var {tableheight} = this.state;
    let jobsleutel = 'GetJobsRegistrationDir';
    if (this.props.registration.MainData && this.props.registration.MainData.data  && this.props.registration.MainData.data.int_type === '2')
    {
      jobsleutel = 'GetJobsRegistrationAcc';
    }
    if (this.props.registration.MainData && this.props.registration.MainData.data  && this.props.registration.MainData.data.int_type === '3')
    {
      jobsleutel = 'GetJobsRegistrationVat';
    }
    return (
      <div >
        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
          <Grid container>
            <Grid item xs={8}>          
              <h4 style={{cursor:'pointer'}} tag="h4" className="mt-3" onClick={() => {this.setState({edit:false, editfact:false})}}>Detail Activiteit</h4>  
            </Grid>
            <Grid item xs={4}>  
            </Grid>
          </Grid>
          {this.props.registration.MainData && this.props.registration.MainData.data  &&
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllRegistratie','edit')) ? false :
                                     (action.name === 'Edit' && (this.state.edit === true ) ) ? true :
                                     (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'AllRegistratie','edit')) ? true :
                                     (action.name === 'New' && this.props.registration.MainData.data.onhold === '0' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllRegistratie','edit')) ? false :
                                     (action.name === 'New' && this.props.registration.MainData.data.onhold === '1' && (this.state.edit === true)) ? true :
                                     (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'AllRegistratie','edit')) ? true :
                                     (action.name === 'New' && this.props.registration.MainData.data.onhold === '1') ? true :
                                     (action.name === 'Delete' && this.props.registration.MainData.data.onhold === '1' && !GenFunc.allowed(this.props.user.userData.secrows,'AllRegistratie','verwijder')) ? true :                                     
                                     (action.name === 'Delete' && this.props.registration.MainData.data.onhold === '1') ? true :
                                     (action.name === 'Save' && (this.state.edit === true) && this.state.save === true) ? false :
                                     (action.name === 'Save' && (this.state.edit === true) && this.state.save === false) ? true :
                                     (action.name === 'Save' && this.state.edit === false) ? true : 
                                     (action.name === 'Save' && this.state.save === false) ? true : false}}
                onClick={() => {this.ActionClick(action.name)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
          </SpeedDial>
          }  
          { (this.state.tabvalue === '1' || this.state.tabvalue === '2' || this.state.tabvalue === '5' || this.state.tabvalue === '4' ) && this.state.subspeedzien &&
             this.props.registration.MainData && this.props.registration.MainData.data && this.props.registration.MainData.data.onhold === '0' && this.state.edit === false &&
            <SpeedDial
              ariaLabel="SpeedDial example"
              icon={<SubSpeedDialIcon style={{zIndex:'10'}}/>}
              direction="left"
              classes={{root: classes.subspeedDialToolb, fab: classes.subspeedFabRoot}}           
              onClose={this.handleSubSpeedClose}
              onOpen={this.handleSubSpeedOpen}
              open={this.state.subspeedopen}          
            >
            {this.state.subspeedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipPlacement='bottom'
                FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'Edit' && (this.state.edit === true || this.state.editfact === true) ) ? true :
                                     (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'New' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'New' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'NewRegistratie' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'NewRegistratie' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'NewRegistratie' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'Contact' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? false :
                                     (action.name === 'Contact' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'Contact' && !GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? true :
                                     (action.name === 'Document' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'Document' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'Document' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'ChangedAccData' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                     (action.name === 'ChangedAccData' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'ChangedAccData' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                     (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === true) ? false :
                                     (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === false) ? true :
                                     (action.name === 'Save' && this.state.edit === false && this.state.editfact === false) ? true : 
                                     (action.name === 'Save' && this.state.save === false) ? true : false}}
                onClick={() => {this.SubActionClick(action.name)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
            </SpeedDial>     
          }     
        </Toolbar>
        {this.props.registration && this.props.registration.MainData && this.props.registration.MainData.data && 
          <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick ={true} noKeyboard={true}>
            {({getRootProps, getInputProps}) => (
              <section className="container" style={{width:'100%',maxWidth:'100%'}}>
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <button style={{display: 'none'}} id={accdropid} type="button" onClick={openDialog}></button>         
                  <FileDownload formid={accformid} frameid={accframeid} DocData={this.state.DownloadData}/>

                  <Grid container style={{width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px'}}>
                    <Grid container spacing={1}>
                      <Grid item xs={this.state.edit === false ? 3 : 5}>
                        <Card className='card_brut'>
                          {this.state.edit === false && this.props.registration.MainData.data.id && this.props.registration.MainData.data.id.length > 4 &&
                            <PerfectScrollbar className="sidebar-nav">
                              <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.AccDetClick()}} className="card-title m-t-10">{this.props.registration.MainData.data.account_name} </h4>
                                <h4 style={{marginTop: '15px',cursor: 'pointer'}} onClick={() => {this.AccDetClick()}} className="card-title m-t-10">{this.props.registration.MainData.data.acc_number} </h4>

                                <div onClick={() => {this.ZetEdit()}} style={{width:'100%'}}>
                                  <h5 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.description}</h5> 
                                  <h5 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.type_txt}</h5> 
                                  <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.land_txt}</h6> 
                                  <small className="text-muted p-t-30 db">Periodiciteit</small>                  
                                  {this.props.registration.MainData.data.periodes_txt === 'Dagen' &&
                                    <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.dagen} Dagen</h6> 
                                  }
                                  {this.props.registration.MainData.data.periodes_txt !== 'Dagen' &&
                                    <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.periodes_txt}</h6> 
                                  }
                                  <div className='div_line' style={{marginBottom: '25px'}}></div>
                                  {(this.props.registration.MainData.data.type_txt === 'Directe Registratie' || this.props.registration.MainData.data.type === "c401ca14238a0b923820dcc509a6f75849b" ) &&
                                    <Grid container spacing={1}>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Nummer in aanvraag: <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" control={<Checkbox checked={this.props.registration.MainData.data.jobaanvr} />} /></small>    
                                      </Grid>
                                      {this.props.registration.MainData.data.numinaanvr_txt && this.props.registration.MainData.data.numinaanvr_txt.length > 3 &&
                                        <Grid item sm={12} md={12} lg={12}>
                                          <small className="text-muted p-t-30 db">Job aanvraag registratienummer</small>                  
                                          <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.numinaanvr_txt}</h6> 
                                        </Grid>
                                      }
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Registratie nummer</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.regnummer}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Registratie nummer BIS</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.regnummerbis}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Datum aanvraag</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.dat_aanvr}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Datum toekenning</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.dat_toek}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Datum stopzetting</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.dat_stop}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Datum laatste volmacht</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.dat_l_volm}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Forfetair: <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" control={<Checkbox checked={this.props.registration.MainData.data.forfetair} />} /></small>    
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Btw Kantoor</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.btwkant_txt}</h6> 
                                      </Grid>

                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">URL Portaal</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.port_link}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Login portaal</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.port_login}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Paswoord portaal</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.port_pasw}</h6> 
                                      </Grid>
                                    </Grid>
                                  }
                                  {this.props.registration.MainData.data.type_txt === 'Vat Refund' &&
                                    <Grid container spacing={1}>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Naam Sitck/Certificaat</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.stick_name}</h6> 
                                      </Grid>

                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Vervaldatum Stick/Certificaat</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.stick_enddate}</h6> 
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                    </Grid>
                                  }

                                  { (this.props.registration.MainData.data.type_txt === 'Accijnzen' || this.props.registration.MainData.data.type === "c8011e2728d9d4c2f636f067f89cc14862c") &&
                                    <Grid container spacing={1}>
                                      <Grid item sm={6} md={6} lg={6}>
                                        <small className="text-muted p-t-30 db">Publiek: <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" control={<Checkbox checked={this.props.registration.MainData.data.publiek} />} /></small>    
                                      </Grid>
                                      <Grid item sm={6} md={6} lg={6}>
                                        <small className="text-muted p-t-30 db">Privaat: <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" control={<Checkbox checked={this.props.registration.MainData.data.privaat} />} /></small>    
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Vergunningsnummer eindgebruiker</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.vernumeind}</h6> 
                                      </Grid>

                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Datum toekenning eindgebruiker</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.dattoekeind}</h6> 
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Directie eindgebruiker</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.direind_txt}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Datum stopzetting eindgebruiker</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.datstopeind}</h6> 
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <h6> Job eindgebruiker : {this.props.registration.MainData.data.jobeind_txt}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Vergunningsnummer Pomphouder</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.vernumpomp}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Datum toekenning Pomphouder</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.dattoekpomp}</h6> 
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Directie Pomphouder</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.dirpomp_txt}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Datum stopzetting Pomphouder</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.datstoppomp}</h6> 
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <h6> Job Pomphouder : {this.props.registration.MainData.data.jobpomp_txt}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <small className="text-muted p-t-30 db">Datum laatste volmacht</small>                  
                                        <h6 style={{cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} >{this.props.registration.MainData.data.dat_l_volm}</h6> 
                                      </Grid>
                                    </Grid>
                                  }
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <small className="text-muted p-t-30 db">Info</small>    
                                      <EditString className="card-title m-t-10"
                                          style={{cursor: 'pointer'}} 
                                          onClick={() => {this.ZetEdit()}}
                                          value={this.props.registration.MainData.data.info}
                                          coltype = 'STR'  
                                          multiline={true}                      
                                          disabled
                                          readonly = {true}
                                       />                                                
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                    </Grid>
                                  </Grid>
                                </div>
                              </CardBody>
                            </PerfectScrollbar>
                          }
                          {this.state.edit === true &&
                            <PerfectScrollbar styleclassName="sidebar-nav">
                              <CardBody style={{textAlign:'left',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditAutoSelect className="card-title m-t-10"
                                        value={this.state.data.account_name}
                                        labelText="Klant"
                                        lijst='AllAccounts'
                                        colId='account_name'
                                        selectcel='accountid'
                                        id={this.state.data.accountid}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'account_name')}
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'account_name')}

                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditSelect className="card-title m-t-10"
                                        value={this.state.data.type_txt}
                                        data={this.state.data}
                                        labelText="Soort registratie"
                                        type='AllRegType'
                                        colId='type_txt'
                                        selectcel='type'
                                        id={this.state.data.type}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'type_txt')}
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'type_txt')}
                                      />
                                    </Grid>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <EditAutoSelect className="card-title m-t-10"
                                        value={this.state.data.land_txt}
                                        labelText="Land"
                                        lijst='AllLanden'
                                        colId='land_txt'
                                        selectcel='land'
                                        id={this.state.data.land}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'land_txt')}
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'land_txt')}
                                      />
                                    </Grid>

                                    <Grid item sm={8} md={8} lg={8}>
                                      <EditSelect className="card-title m-t-10"
                                        value={this.state.data.periodes_txt}
                                        data={this.state.data}
                                        labelText="Periodiciteit"
                                        type='AllPeriodes'
                                        colId='periodes_txt'
                                        selectcel='periodes'
                                        id={this.state.data.periodes}
                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'periodes_txt')}
                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'periodes_txt')}

                                      />
                                    </Grid>
                                    <Grid item sm={4} md={4} lg={4}>
                                      {this.state.data.periodes_txt === 'Dagen' &&
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.dagen}
                                          coltype = 'NUM'                        
                                          labelText="Dagen"
                                          decimal= {0}
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dagen')}
                                        />
                                      }
                                    </Grid>

                                  </Grid>

                                  {this.state.data.type_txt === 'Directe Registratie' &&
                                    <Grid container spacing={1}>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <medium className="text-muted p-t-30 db">Nummer in aanvraag <FormControlLabel style={{marginLeft:'5px',paddingTop:'5px'}} disabled labelPlacement="start" control={<Checkbox checked={this.state.data.jobaanvr} />} /></medium>    
                                      </Grid>
                                      {this.state.data.numinaanvr_txt && this.state.data.numinaanvr_txt.length > 3 &&
                                        <Grid item sm={12} md={12} lg={12}>
                                          <h6> Job aanvraag registratienummer : {this.state.data.numinaanvr_txt}</h6> 
                                        </Grid>
                                      }
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.regnummer}
                                          coltype = 'STR'                        
                                          labelText="Registratie nummer"
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'regnummer')}
                                        />
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.regnummerbis}
                                          coltype = 'STR'                        
                                          labelText="Registratie nummer BIS"
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'regnummerbis')}
                                        />
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.dat_aanvr}
                                            coltype = 'STR'                        
                                            labelText="Datum aanvraag"
                                            inputProps={{value:this.state.data.dat_aanvr}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dat_aanvr')}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.dat_toek}
                                            coltype = 'STR'                        
                                            labelText="Datum toekenning"
                                            inputProps={{value:this.state.data.dat_toek}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dat_toek')}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.dat_stop}
                                            coltype = 'STR'                        
                                            labelText="Datum stopzetting"
                                            inputProps={{value:this.state.data.dat_stop}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dat_stop')}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.dat_l_volm}
                                            coltype = 'STR'                        
                                            labelText="Datum laatste volmacht"
                                            inputProps={{value:this.state.data.dat_l_volm}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dat_l_volm')}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{width:'100%',textAlign:'left', marginLeft:'-20px',marginTop: '10px'}}>
                                          <EditCheck className="card-title m-t-10"
                                            value={this.state.data.forfetair}
                                            labelPlacement="start"
                                            labelText="Forfetair"
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'forfetair')}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditAutoSelect className="card-title m-t-10"
                                          value={this.state.data.btwkant_txt}
                                          filter={[{field:'type_reg',value:this.state.data.type}]}
                                          labelText="Btw Kantoor"
                                          lijst='AllKantoren'
                                          colId='btwkant_txt'
                                          selectcel='btwkant'
                                          id={this.state.data.btwkant}
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'btwkant_txt')}
                                        />
                                      </Grid>

                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.port_link}
                                          coltype = 'STR'                        
                                          labelText="URL Portaal"
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'port_link')}
                                        />
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.port_login}
                                          coltype = 'STR'                        
                                          labelText="Login portaal"
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'port_login')}
                                        />
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.port_pasw}
                                          coltype = 'STR'                        
                                          labelText="Paswoord portaal"
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'port_pasw')}
                                        />
                                      </Grid>
                                    </Grid>
                                  }

                                  {this.state.data.type_txt === 'Accijnzen' &&
                                    <Grid container spacing={1}>

                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={6} md={6} lg={6}>
                                        <div style={{width:'100%',textAlign:'left', marginLeft:'-20px',marginTop: '10px'}}>
                                          <EditCheck className="card-title m-t-10"
                                            value={this.state.data.publiek}
                                            labelPlacement="start"
                                            labelText="Publiek"
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'publiek')}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item sm={6} md={6} lg={6}>
                                        <div style={{width:'100%',textAlign:'left', marginLeft:'-20px',marginTop: '10px'}}>
                                          <EditCheck className="card-title m-t-10"
                                            value={this.state.data.privaat}
                                            labelPlacement="start"
                                            labelText="Privaat"
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'privaat')}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.vernumeind}
                                          coltype = 'STR'                        
                                          labelText="Vergunningsnummer eindgebruiker"
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'vernumeind')}
                                        />
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.dattoekeind}
                                            coltype = 'STR'                        
                                            labelText="Datum toekenning eindgebruiker"
                                            inputProps={{value:this.state.data.dattoekeind}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dattoekeind')}
                                          />
                                        </div>
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditAutoSelect className="card-title m-t-10"
                                          value={this.state.data.direind_txt}
                                          labelText="Directie eindgebruiker"
                                          lijst='AllKantoren'
                                          colId='direind_txt'
                                          selectcel='direind'
                                          id={this.state.data.direind}
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'direind_txt')}
                                        />
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.datstopeind}
                                            coltype = 'STR'                        
                                            labelText="Datum stopzetting eindgebruiker"
                                            inputProps={{value:this.state.data.datstopeind}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'datstopeind')}
                                          />
                                        </div>
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <h6> Job eindgebruiker : {this.state.data.jobeind_txt}</h6> 
                                      </Grid>

                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.vernumpomp}
                                          coltype = 'STR'                        
                                          labelText="Vergunningsnummer Pomphouder"
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'vernumpomp')}
                                        />
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.dattoekpomp}
                                            coltype = 'STR'                        
                                            labelText="Datum toekenning Pomphouder"
                                            inputProps={{value:this.state.data.dattoekpomp}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dattoekpomp')}
                                          />
                                        </div>
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditAutoSelect className="card-title m-t-10"
                                          value={this.state.data.dirpomp_txt}
                                          filter={[{field:'type_reg',value:this.state.data.type}]}
                                          labelText="Directie Pomphouder"
                                          lijst='AllKantoren'
                                          colId='dirpomp_txt'
                                          selectcel='dirpomp'
                                          id={this.state.data.dirpomp}
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dirpomp_txt')}
                                        />
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.datstoppomp}
                                            coltype = 'STR'                        
                                            labelText="Datum stopzetting Pomphouder"
                                            inputProps={{value:this.state.data.datstoppomp}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'datstoppomp')}
                                          />
                                        </div>
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <h6> Job Pomphouder : {this.state.data.jobpomp_txt}</h6> 
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.dat_l_volm}
                                            coltype = 'STR'                        
                                            labelText="Datum laatste volmacht"
                                            inputProps={{value:this.state.data.dat_l_volm}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'dat_l_volm')}
                                          />
                                        </div>
                                      </Grid>
                                    </Grid>
                                  }
                                  {this.state.data.type_txt === 'Vat Refund' &&
                                    <Grid container spacing={1}>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                      <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.stick_name}
                                          coltype = 'STR'                        
                                          labelText="Naam Sitck/Certificaat"
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'stick_name')}
                                        />
                                      </Grid>

                                      <Grid item sm={12} md={12} lg={12}>
                                        <div style={{marginTop: '-14px'}}>
                                          <EditDateTime className="card-title m-t-10"
                                            value={this.state.data.stick_enddate}
                                            coltype = 'STR'                        
                                            labelText="Vervaldatum Stick/Certificaat"
                                            inputProps={{value:this.state.data.stick_enddate}}
                                            formControlProps={{fullWidth: true}}
                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'stick_enddate')}
                                          />
                                        </div>
                                      </Grid>                                      
                                      <Grid item sm={12} md={12} lg={12}>
                                        <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                      </Grid>
                                    </Grid>
                                  }

                                  <Grid item sm={12} md={12} lg={12}>
                                    <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                                  </Grid>
                                  <Grid container spacing={1}>
                                    <Grid item sm={12} md={12} lg={12}>
                                      <small className="text-muted p-t-30 db">Info</small>                  
                                    </Grid>

                                    <Grid item sm={12} md={12} lg={12}>
                                        <EditString className="card-title m-t-10"
                                          value={this.state.data.info}
                                          coltype = 'STR'  
                                          multiline={true}                      
                                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'info')}
                                        />
                                    </Grid>
                                  </Grid>
                                  <div className='div_line' style={{height: '20px', borderColor:'#4F5467',borderWidth:'2px'}}></div>
                              </CardBody>
                            </PerfectScrollbar>
                          }
                        </Card>
                      </Grid>
                      <Grid item xs={this.state.edit === false ? 9 : 7}>
                        <Card className='card_brut'>
                          <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                            <div className='div_line'>
                              <Nav tabs >
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '1' })}
                                    onClick={(event) => { this.handleTabChange(event,'1'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Certificaten
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '2' })}
                                    onClick={(event) => { this.handleTabChange(event,'2'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Jobs
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '4' })}
                                    onClick={(event) => { this.handleTabChange(event,'4'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Documenten
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames({ active: this.state.tabvalue === '5' })}
                                    onClick={(event) => { this.handleTabChange(event,'5'); }}
                                    style={{ cursor: 'default' }}
                                  >
                                    Correspondentie
                                  </NavLink>
                                </NavItem>

                              </Nav>
                              <TabContent activeTab={this.state.tabvalue}>
                                <TabPane tabId="1">
                                </TabPane>
                                <TabPane tabId="2">
                                  { this.state.tabvalue === '2' && this.props.registration.JobsData && this.props.registration.JobsData.data && this.props.registration.JobsData.kol &&
                                    this.props.registration && this.state.jobgraph === false && this.props.registration.MainData && this.props.registration.MainData.data && this.props.registration.MainData.data.id !== 0 &&
                                   <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.registration.JobsData.kol}
                                      data={this.props.registration.JobsData.data} 
                                      sizetofit={false}
                                      sortable={true}
                                      suppressMenu={false}
                                      enableRowGroup={true}
                                      suppressColumnVirtualisation={true}                                
                                      filter={true}
                                      sleutel={jobsleutel}
                                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                      editable={false}
                                      sql={() => this.props.dispatch(GetJobsRegistration(this.props.registration.MainData.data.id))}
                                      CellClickedEvent={(column, colDef, value) => this.JobCellClick(column,colDef, value)}
                                      infinite= {true}          
                                      conditie= {this.props.registration.JobsData.conditie}          
                                      funktie= "GetJobsRegistratie"   
                                      GridReady={this.onGridReadyJobs}
       

                                    />

                                  }
                                  { this.state.tabvalue === '2' && this.props.registration.JobsData && this.props.registration.JobsData.data && this.props.registration.JobsData.kol &&
                                    this.props.settings.listmaindata && this.props.settings.listmaindata.mainkey && this.state.jobgraph === true && this.state.workflowstories &&
                                    this.props.registration && this.props.registration.MainData && this.props.registration.MainData.data && this.props.registration.MainData.data.id !== 0 &&
                                    <div>
                                      <Grid container spacing={1}>
                                        <Grid style={{paddingTop:'50px'}} item xs={12}>
                                          <Slider
                                            defaultValue={this.state.jaar}
                                            getAriaValueText={this.valuejaar}
                                            onChangeCommitted={(event,value) => this.JaarChange(event,value)}
                                            aria-labelledby="discrete-slider"
                                            valueLabelDisplay="on"
                                            step={1}
                                            marks
                                            min={2015}
                                            max={2050}
                                          />                                        
                                        </Grid>
                                        <Grid style={{paddingTop:'50px',overflowY:'auto', overflowX:'hidden', height:tableheight}} item xs={12}>
                                          <Timeline style={{overflowY:'auto', overflowX:'hidden', height:tableheight}} stories={this.state.workflowstories} />
                                        </Grid>
                                      </Grid>


                                    </div>
                                  }


                                </TabPane>
                                <TabPane tabId="4">
                                
                                  { this.state.tabvalue === '4' && this.props.registration.DocData && this.props.registration.DocData.data && this.props.registration.DocData.kol &&
                                    this.props.registration && this.props.registration.MainData && this.props.registration.MainData.data && this.props.registration.MainData.data.id !== 0 &&
                                    <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.registration.DocData.kol}
                                      data={this.props.registration.DocData.data} 
                                      sizetofit={true}
                                      sortable={false}
                                      suppressMenu={true}
                                      enableRowGroup={false}
                                      suppressColumnVirtualisation={true}                                
                                      filter={true}
                                      sleutel='DocAccounts'
                                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                      editable={false}
                                      sql={() => this.props.dispatch(GetDocRegistration(this.props.registration.MainData.data.id))}
                                      CellClickedEvent={(column, colDef, value) => this.DocCellClick(column,colDef, value)}
                                    />
                                  }                      
                                </TabPane>

                                <TabPane tabId="5">
                                
                                  { this.state.tabvalue === '5' && this.props.registration.CorData && this.props.registration.CorData.data && this.props.registration.CorData.kol &&
                                    this.props.registration && this.props.registration.MainData && this.props.registration.MainData.data && this.props.registration.MainData.data.id !== 0 &&
                                    <TableGrid
                                      tableheight={tableheight} 
                                      columns={this.props.registration.CorData.kol}
                                      data={this.props.registration.CorData.data} 
                                      sizetofit={true}
                                      sortable={false}
                                      suppressMenu={true}
                                      enableRowGroup={false}
                                      suppressColumnVirtualisation={true}                                
                                      filter={true}
                                      sleutel='CorAccounts'
                                      getContextMenuItems={(param) => this.getContextMenuItems(param,'AllSecPar')}                     
                                      editable={false}
                                      sql={() => this.props.dispatch(GetCorRegistration(this.props.registration.MainData.data.id))}
                                      CellClickedEvent={(column, colDef, value) => this.CorCellClick(column,colDef, value)}
                                    />
                                  }                      
                                </TabPane>

                              </TabContent>




                            </div>
                          </CardBody>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </section>
            )}
          </Dropzone>
        }
        {this.state.PopUpScreen}
      </div>
    );
  }
}
export default connect(mapStateToProps)(withStyles(styles)(Registrationpage));
