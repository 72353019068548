// SETTINGS CONSTANTS
export const BEGIN_BACKEND_CALL = "BEGIN_BACKEND_CALL";
export const BACKEND_CALL_ERROR = "BACKEND_CALL_ERROR";
export const BACKEND_CALL_SUCCESS = "BACKEND_CALL_SUCCESS";

export const ALLFILTLANDEN_SUCCESS = 'ALLFILTLANDEN_SUCCESS';


export const LOGO_BG = 'LOGO_BG';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const THEME = 'THEME';
export const DIRECTION = 'DIRECTION';
export const SIDEBAR_POSITION = 'SIDEBAR_POSITION';
export const HEADER_POSITION = 'HEADER_POSITION';
export const LAYOUT = 'LAYOUT';
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE';
export const SIDEBAR_STATE = 'SIDEBAR_STATE';
export const PANEL_RIGHT = 'PANEL_RIGHT';
export const RIGHT_TITLE = 'RIGHT_TITLE';
export const LOAD_VIEW_SUCCESS = 'LOAD_VIEW_SUCCESS';
export const RESET_VIEW_SUCCESS = 'RESET_VIEW_SUCCESS';
export const CLICK_VIEW_SUCCESS = 'CLICK_VIEW_SUCCESS';
export const CLICK_VIEW_LEEG_SUCCESS = 'CLICK_VIEW_LEEG_SUCCESS';

export const GETLISTMAIN_SUCCESS = 'GETLISTMAIN_SUCCESS';
export const ZET_MAINLIST_SUCCESS = 'ZET_MAINLIST_SUCCESS';
export const ZET_POPUP_SUCCESS = 'ZET_POPUP_SUCCESS';

export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const AllUSERS_SUCCESS = 'AllUSERS_SUCCESS';
export const GETUSER_SUCCESS = 'GETUSER_SUCCESS';
export const USERACCOUNTS_SUCCESS = 'USERACCOUNTS_SUCCESS';
export const GETJOBSUSER_SUCCESS = 'GETJOBSUSER_SUCCESS';

export const ACC_TAB = 'ACC_TAB';
export const AllACCOUNTS_SUCCESS = 'AllACCOUNTS_SUCCESS';
export const GETACCOUNT_SUCCESS = 'GETACCOUNT_SUCCESS';
export const GETDOCACCOUNT_SUCCESS = 'GETDOCACCOUNT_SUCCESS';
export const GETVOLMACCOUNT_SUCCESS = 'GETVOLMACCOUNT_SUCCESS';
export const GETCORACCOUNT_SUCCESS = 'GETCORACCOUNT_SUCCESS';
export const GETCONTACCOUNT_SUCCESS = 'GETCONTACCOUNT_SUCCESS';
export const GETREGACCOUNT_SUCCESS = 'GETREGACCOUNT_SUCCESS';
export const GETTARACCOUNT_SUCCESS = 'GETTARACCOUNT_SUCCESS';
export const GETJOBSACCOUNT_SUCCESS = 'GETJOBSACCOUNT_SUCCESS';
export const GETACCIJNZENACCOUNT_SUCCESS = 'GETACCIJNZENACCOUNT_SUCCESS';
export const GETVATREFUNDACCOUNT_SUCCESS = 'GETVATREFUNDACCOUNT_SUCCESS';
export const GETDIRREGACCOUNT_SUCCESS = 'GETDIRREGACCOUNT_SUCCESS';
export const GETACCCHANGES_SUCCESS = 'GETACCCHANGES_SUCCESS';
export const GETALLFACTURENACCOUNT_SUCCESS = 'GETALLFACTURENACCOUNT_SUCCESS';
export const GETFACTUURACCOUNT_SUCCESS = 'GETFACTUURACCOUNT_SUCCESS';
export const GETALLFACTUREN_SUCCESS = 'GETALLFACTUREN_SUCCESS';
export const GETFACTURATIEACCOUNT_SUCCESS = 'GETFACTURATIEACCOUNT_SUCCESS';
export const GETACCOUNTINVOICEDETAILS_SUCCESS = 'GETACCOUNTINVOICEDETAILS_SUCCESS';
export const GETTIMEREGISTRATION_SUCCESS = 'GETTIMEREGISTRATION_SUCCESS';
export const GETALLTIMEREGISTRATION_SUCCESS = 'GETALLTIMEREGISTRATION_SUCCESS';


export const GETTIMEREGISTRATIONSFROMUSERONDAY_SUCCESS = 'GETTIMEREGISTRATIONSFROMUSERONDAY_SUCCESS';
export const ALLPERIODESACC_SUCCESS = 'ALLPERIODESACC_SUCCESS';

export const AllACTIVITIES_SUCCESS = 'AllACTIVITIES_SUCCESS';
export const GETACTIVITY_SUCCESS = 'GETACTIVITY_SUCCESS';
export const MYOPENACTIVITIES_SUCCESS = 'MYOPENACTIVITIES_SUCCESS';
export const ALLMYACTIVITIES_SUCCESS = 'ALLMYACTIVITIES_SUCCESS';




export const AllCONTACTS_SUCCESS = 'AllCONTACTS_SUCCESS';
export const GETCONTACT_SUCCESS = 'GETCONTACT_SUCCESS';
export const CONT_TAB = 'CONT_TAB';
export const GETCORCONTACT_SUCCESS = 'GETCORCONTACT_SUCCESS';
export const GETDOCCONTACT_SUCCESS = 'GETDOCCONTACT_SUCCESS';

export const ALLKANTCONTACTS_SUCCESS = 'ALLKANTCONTACTS_SUCCESS';
export const GETKANTCONTACT_SUCCESS = 'GETKANTCONTACT_SUCCESS';
export const GETDOCKANTCONTACT_SUCCESS = 'GETDOCKANTCONTACT_SUCCESS';

export const ALLKANTOREN_SUCCESS = 'ALLKANTOREN_SUCCESS';
export const GETKANTOOR_SUCCESS = 'GETKANTOOR_SUCCESS';
export const GETDOCKANTOOR_SUCCESS = 'GETDOCKANTOOR_SUCCESS';
export const GETCONTKANTOOR_SUCCESS = 'GETCONTKANTOOR_SUCCESS';
export const GETREGKANTOOR_SUCCESS = 'GETREGKANTOOR_SUCCESS';
export const GETJOBSKANTOOR_SUCCESS = 'GETJOBSKANTOOR_SUCCESS';
export const KANT_TAB = 'KANT_TAB';

export const ALLSUPPLIERS_SUCCESS = 'ALLSUPPLIERS_SUCCESS';
export const GETSUPPLIER_SUCCESS = 'GETSUPPLIER_SUCCESS';
export const GETDOCSUPPLIER_SUCCESS = 'GETDOCSUPPLIER_SUCCESS'; 
export const GETREGSUPPLIER_SUCCESS = 'GETREGSUPPLIER_SUCCESS';
export const SUP_TAB = 'SUP_TAB';

export const ALLLANDEN_SUCCESS = 'ALLLANDEN_SUCCESS';
export const ALLLANDENACC_SUCCESS = 'ALLLANDENACC_SUCCESS';
export const ALLACCTYPE_SUCCESS = 'ALLACCTYPE_SUCCESS';

export const GETLAND_SUCCESS = 'GETLAND_SUCCESS';
export const ALLONKOSTEN_SUCCESS = 'ALLONKOSTEN_SUCCESS';
export const GETONKOSTENLAND_SUCCESS = 'GETONKOSTENLAND_SUCCESS';
export const GETKANTORENLAND_SUCCESS = 'GETKANTORENLAND_SUCCESS';
export const GETVATREFCODELAND_SUCCESS = 'GETVATREFCODELAND_SUCCESS';


export const GETKLANTENLAND_SUCCESS = 'GETKLANTENLAND_SUCCESS';
export const GETREGLAND_SUCCESS = 'GETREGLAND_SUCCESS';
export const ALLTAALCODES_SUCCESS = 'ALLTAALCODES_SUCCESS';
export const GETTAALCODE_SUCCESS = 'GETTAALCODE_SUCCESS';
export const GETFACTUUROPBOUW_SUCCESS = 'GETFACTUUROPBOUW_SUCCESS';
export const GETVOLMACHTENLANDEN_SUCCESS = 'GETVOLMACHTENLANDEN_SUCCESS';

export const ALLTEMPLATES_SUCCESS = 'ALLTEMPLATES_SUCCESS';
export const GETTEMPLATE_SUCCESS = 'GETTEMPLATE_SUCCESS';
export const GETTEMPLATEFIELDS_SUCCESS = 'GETTEMPLATEFIELDS_SUCCESS';

export const MAIN_TITLE = 'MAIN_TITLE';
export const SUB_TITLE = 'SUB_TITLE';
export const SECURITY_TITLE = 'SECURITY_TITLE';

export const ALLSECPARAM_SUCCESS = 'ALLSECPARAM_SUCCESS';
export const ALLMUNTEN_SUCCESS = 'ALLMUNTEN_SUCCESS';

export const ALLSUBPRODUCTCODES_SUCCESS = 'ALLSUBPRODUCTCODES_SUCCESS';
export const ALLMAINPRODUCTCODES_SUCCESS = 'ALLMAINPRODUCTCODES_SUCCESS';

export const ALLSUBVATREFCODES_SUCCESS = 'ALLSUBVATREFCODES_SUCCESS';
export const ALLMAINVATREFCODES_SUCCESS = 'ALLMAINVATREFCODES_SUCCESS';
export const GETMAINIVAT_SUCCESS = 'GETMAINIVAT_SUCCESS';



export const ALLPOSTCODES_SUCCESS = 'ALLPOSTCODES_SUCCESS';
export const ALLREKENINGEN_SUCCESS = 'ALLREKENINGEN_SUCCESS';
export const ALLPERIODES_SUCCESS = 'ALLPERIODES_SUCCESS';
export const ALLSTARTDATUMS_SUCCESS = 'ALLSTARTDATUMS_SUCCESS';
export const ALLBTWPERCENTAGES_SUCCESS = 'ALLBTWPERCENTAGES_SUCCESS';
export const ALLFACTZENDWIJZEN_SUCCESS = 'ALLFACTZENDWIJZEN_SUCCESS';
export const ALLBETALINGSVOORWAARDEN_SUCCESS = 'ALLBETALINGSVOORWAARDEN_SUCCESS';
export const ALLBTWREGIMES_SUCCESS = 'ALLBTWREGIMES_SUCCESS';
export const ALLAANSPREKINGEN_SUCCESS = 'ALLAANSPREKINGEN_SUCCESS';
export const ALLFUNCTIES_SUCCESS = 'ALLFUNCTIES_SUCCESS';

export const ALLREGOMS_SUCCESS = 'ALLREGOMS_SUCCESS';
export const ALLREGISTRATIE_SUCCESS = 'ALLREGISTRATIE_SUCCESS';
export const ALLREGTAR_SUCCESS = 'ALLREGTAR_SUCCESS';
export const ALLREGTYPE_SUCCESS = 'ALLREGTYPE_SUCCESS';
export const REGISTRATIE_SUCCESS = 'REGISTRATIE_SUCCESS';
export const GETDOCREGISTRATIE_SUCCESS = 'GETDOCREGISTRATIE_SUCCESS';
export const GETJOBSREGISTRATIE_SUCCESS = 'GETJOBSREGISTRATIE_SUCCESS';
export const GETCORREGISTRATIE_SUCCESS = 'GETCORREGISTRATIE_SUCCESS';
export const REG_TAB = 'REG_TAB';

export const ALLJOBSUBJ_SUCCESS = 'ALLJOBSUBJ_SUCCESS';
export const ALLJOBSNEW_SUCCESS = 'ALLJOBSNEW_SUCCESS';

export const ALLJOBS_SUCCESS = 'ALLJOBS_SUCCESS';
export const ALLJOBDEFMAIN_SUCCESS = 'ALLJOBDEFMAIN_SUCCESS';
export const ALLJOBDEFMAINACC_SUCCESS = 'ALLJOBDEFMAINACC_SUCCESS';
export const ALLREGISTRATIEACC_SUCCESS = 'ALLREGISTRATIEACC_SUCCESS';
export const ALLJOBDEFMAININDEX_SUCCESS = 'ALLJOBDEFMAININDEX_SUCCESS';

export const ALLJOBREGNUMACC_SUCCESS = 'ALLJOBREGNUMACC_SUCCESS';



export const ALLBETAALFIN_SUCCESS = 'ALLBETAALFIN_SUCCESS';
export const BETAALFIN_SUCCESS = 'BETAALFIN_SUCCESS';
export const GETSUBBETAALFIN_SUCCESS = 'GETSUBBETAALFIN_SUCCESS';
export const ALLVATREFCODE_SUCCESS = 'ALLVATREFCODE_SUCCESS';
export const VATREFCODE_SUCCESS = 'VATREFCODE_SUCCESS';
export const ALLVATREFCODELAND_SUCCESS = 'ALLVATREFCODELAND_SUCCESS';


export const GETSUBVATREFCODE_SUCCESS = 'GETSUBVATREFCODE_SUCCESS';




export const JOBDEFMAINALLCODES_SUCCESS = 'JOBDEFMAINALLCODES_SUCCESS';
export const JOBMAIN_SUCCESS = 'JOBMAIN_SUCCESS';
export const GETSUBJOBMAIN_SUCCESS = 'GETSUBJOBMAIN_SUCCESS';
export const GETSUBJOBTARIEF_SUCCESS = 'GETSUBJOBTARIEF_SUCCESS';
export const GETJOBDEFWORKFLOW_SUCCESS = 'GETJOBDEFWORKFLOW_SUCCESS';
export const ALLJOBSTATUS_SUCCESS = 'ALLJOBSTATUS_SUCCESS';
export const ALLJOBLAYOUT_SUCCESS = 'ALLJOBLAYOUT_SUCCESS';
export const ALLVELDEN_SUCCESS = 'ALLVELDEN_SUCCESS';
export const ALLJOBSTOINVOICEACCOUNT_SUCCESS = 'ALLJOBSTOINVOICEACCOUNT_SUCCESS';
export const ALLJOBSTOINVOICE_SUCCESS = 'ALLJOBSTOINVOICE_SUCCESS';
export const JOBDEFLISTCODES_SUCCESS = 'JOBDEFLISTCODES_SUCCESS';
