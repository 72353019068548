import React, {createRef } from 'react';
import classnames from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditSelect from "../../components/editselect.jsx";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import Dropzone from 'react-dropzone';
import FileDownload from "../../components/FileDownload.jsx";
import AccountMultiPlus from '@material-ui/icons/AccountMultiPlus';
import SaveIcon from '@material-ui/icons/Save';
import FilePlus from '@material-ui/icons/FilePlus';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import { GetDocKantoor, GetContKantoor, GetKantoor, CreateKantoor, AllKantContacts, GetKantContact, CreateKantContact, GetRegKantoor, GetJobsKantoor, setTab } from '../../redux/settings/kantoorActions.js';
import {AllPostcodes} from "../../redux/settings/paramActions";
import {setPanelRight, setTitleRight, setSubTitle,zetview,clickview, ZetListData, zetListMain} from "../../redux/settings/action";
import {GetRegistration} from "../../redux/settings/registrationActions";
import {GetAccount} from "../../redux/settings/accountActions";
import PopJobAanvrNum from "../jobs/PopJobAanvrNum.jsx";
import PopAddZipCode from "../zipcode/PopAddZipcode.jsx";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SubSpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SubSpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

const dropzoneRef = createRef();
const openDialog = () => {
    if (dropzoneRef.current) {
        dropzoneRef.current.open()
    }
};
const accdropid = GenFunc.MakeId();
const accframeid = GenFunc.MakeId();
const accformid = GenFunc.MakeId();

var dropdoc = null;

const mapStateToProps = state => ({
    ...state
});

class Kantoorpage extends React.Component {

    state = {
        DownloadData: { id: '' },
        tableheight: Math.floor(window.innerHeight * .70) + 'px',
        tabvalue: this.props.kantoor && this.props.kantoor.LastTab ? this.props.kantoor.LastTab : '1',
        edit: this.props.kantoor && this.props.kantoor.MainData && this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.id === 0 ? true : false,
        save: false,
        subsave: false,
        adrestel: 0,
        subspeedzien:true,

        PopUpScreen: null,
        checkval: false,
        data: this.props.kantoor && this.props.kantoor.MainData && this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.id === 0 ? this.props.kantoor.MainData.data : null,
        speedactions: [
            { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
            { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuw Kantoor' },
            { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
        ],
        speedopen: false,
        subspeedactions: [
          { icon: <AccountMultiPlus />, name: 'Contact', tooltip: 'Nieuwe contactpersoon' },
        ],
        subspeedopen: false,  
    };

    constructor(props, context) {
        super(props);
        this.resizeFunction = this.resizeFunction.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.DocCellClick = this.DocCellClick.bind(this);
        this.handleSpeedClose = this.handleSpeedClose.bind(this);
        this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
        this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
        this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);
        this.ZetEdit = this.ZetEdit.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.MainSave = this.MainSave.bind(this);
        this.checkconfirm = this.checkconfirm.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.handleJa = this.handleJa.bind(this);
        this.ActionClick = this.ActionClick.bind(this)
        this.SubActionClick = this.SubActionClick.bind(this);
        this.ContCellClick = this.ContCellClick.bind(this);
        this.RegCellClick = this.RegCellClick.bind(this);
        this.JobCellClick = this.JobCellClick.bind(this);
        this.ClosePopJobs = this.ClosePopJobs.bind(this);        this.ClosePopZipcode = this.ClosePopZipcode.bind(this);


    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
        this.props.dispatch(setTitleRight('Kantoren'))
        this.props.dispatch(setSubTitle('Kantoor'))
        this.props.dispatch(setPanelRight(true))
    }

    componentWillUnmount() {
        this.checkconfirm(this.props.kantoor.MainData);
        this.props.dispatch(setPanelRight(false))
        this.props.dispatch(setTitleRight(''))
        window.removeEventListener("resize", this.resizeFunction);
    }

    resizeFunction() {
        if (window.innerHeight >= 960) {
            this.setState({ tableheight: Math.floor(window.innerHeight * .70) + 'px' });
        }
        else {
            this.setState({ tableheight: '600px' });
        }
    }

    async ClosePopZipcode(retdata) {
        let data = this.state.data;
        this.setState({ PopUpScreen: null });
        if (retdata) 
        {
            data.zipcode_txt = retdata.zipcode + ' - '+retdata.place;
            data.zipcode = retdata.id;
            data.country_txt = retdata.country_txt;
            data.country = retdata.country;
            this.setState({data:data})
        }
        await this.props.dispatch(AllPostcodes());
    }


    getContextMenuItems(params, sleutel) {
        return null;
    }

    async ErrorCheck(that, waarde, type, colId) {
      let data = this.state.data;
      let terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.kantoor.AllData.data, data);
      that.setState({ error: terug })
    }      

    async ValueCheck(that, waarde, type, colId) {
        let data = this.state.data;
        let terug = false;
        switch (colId) {
            case 'country_txt':
                data.country_txt = waarde.value;
                data.country = waarde.id;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.kantoor.AllData.data, data);
                break;

            case 'type_reg_txt':
                data.type_reg_txt = waarde.text;
                data.type_reg = waarde.id;
                terug = Validation.ValidCheck(this.props.settings.sub_title,waarde.text,colId,that,null,data) ;
                break;

            case 'zipcode_txt':
                if (waarde.id === 0)
                {
                    // openen aanmaken nieuwe postcode
                    this.setState({PopUpScreen:(<PopAddZipCode open={true}  toevobject={that}  waarde={waarde.value} close={this.ClosePopZipcode} title='Toevoegen postode aan lijst' />)});
                    // Einde aanmaken nieuwe postcode
                }

                data.zipcode_txt = waarde.value;
                data.zipcode = waarde.id;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.kantoor.AllData.data, data);
                if (that.overzdata) {
                    data.country_txt = that.overzdata.country_txt;
                    data.country = that.overzdata.country;
                }
                break;

            default:
                data[colId] = waarde;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.kantoor.AllData.data, data);
                break;
        }
        that.setState({ error: terug })
        terug = await this.CheckSave(data);
        this.setState({ save: terug, data: data, checkval: false })
    }   
    
    async onDrop(files) {
        const formData = new FormData();
        this.handleTabChange(null, '0');
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('file', file);

        }
        if (dropdoc) {
            formData.set('kant_id', dropdoc.kantoor_id);
            formData.set('type', dropdoc.type);
            formData.set('docid', dropdoc.id);
            formData.set('docdata', dropdoc);
        }
        else {
            formData.set('kant_id', this.props.kantoor.MainData.data.id);
            formData.set('type', 'kantoor');
        }
        await SqlFunction.DoSqlBody('saveFile', formData);
        dropdoc = null;
        this.handleTabChange(null, '2');
    };

    async DocCellClick(waarde) {
        let column = waarde.column.colDef.field;
        if (column === 'upload') {
            dropdoc = waarde.data;
            let element = document.getElementById(accdropid);
            element.click()
            return;
        }
        if (column === 'verwijder') {
            await SqlFunction.DoSql('DeleteFile', waarde.data);
            await this.props.dispatch(GetDocKantoor(this.props.kantoor.MainData.data.id))
            return;
        }
        await this.setState({ DownloadData: waarde.data });
        document.getElementById(accformid).submit();
        return;
    }

    handleSpeedClose() {
    this.setState({ speedopen: false, subspeedzien:true });
    };

    handleSpeedOpen() {
    this.setState({ speedopen: true, subspeedzien:false });
    };

    handleSubSpeedClose() {
    this.setState({ subspeedopen: false });
    };

    handleSubSpeedOpen() {
    this.setState({ subspeedopen: true });
    };


    ZetEdit() {
        if (GenFunc.allowed(this.props.user.userData.secrows,'AllKantoren','edit') === true)
        {
            this.setState({ edit: true, data: this.props.kantoor.MainData.data })
        }
    }

    CheckSave(data) {
        let terug = true
        terug = Validation.ValidCheck(this.props.settings.sub_title, data.name, 'name', this, this.props.kantoor.AllData.data, data);
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.e_mail, 'e-mail', this, this.props.kantoor.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.main_phone, 'main_phone', this, this.props.kantoor.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.adress, 'adress', this, this.props.kantoor.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.iban, 'iban', this, this.props.kantoor.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.bic, 'bic', this, this.props.kantoor.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.info, 'info', this, this.props.kantoor.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.zipcode_txt, 'zipcode_txt', this, this.props.kantoor.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.country_txt, 'country_txt', this, this.props.kantoor.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.type_reg_txt, 'type_reg_txt', this, this.props.kantoor.AllData.data, data);
        }
        return !terug;
    }

    async handleTabChange(event, newValue) {
        if (this.state.save === true && this.state.edit === true ) 
        {
            await this.checkconfirm(this.props.kantoor.MainData);
        }
        else
        {
            this.setState({edit:false})
        }

        let subspeedzien = false;

        if (newValue === '1') {
            // Contactpersonen
            let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'AllContacts','edit') === true) ? 
            [
              { icon: <AccountMultiPlus />, name: 'Contact', tooltip: 'Nieuwe contactpersoon' },
            ] : [];
            subspeedzien = true;
            this.setState({subspeedactions:subspeedactions})
            await this.props.dispatch(GetContKantoor(this.props.kantoor.MainData.data.id))
        }
        if (newValue === '2') {
            // Documenten
            let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'Docu','edit') === true) ? [
            { icon: <FilePlus />, name: 'Document', tooltip: 'Document toevoegen' },
            ]: null;
            this.setState({subspeedactions:subspeedactions})
            subspeedzien = true;
            await this.props.dispatch(GetDocKantoor(this.props.kantoor.MainData.data.id))
        }
        if (newValue === '3') {
            // registraties
            await this.props.dispatch(GetRegKantoor(this.props.kantoor.MainData.data.id))
        }
        if (newValue === '4') {
            // Jobs
            await this.props.dispatch(GetJobsKantoor(this.props.kantoor.MainData.data.id))
            await this.props.dispatch(ZetListData('GetJobsKantoor', this.props.kantoor.MainData.data.id));
            await this.props.dispatch(zetListMain('GetJobsKantoor', this.props.kantoor.MainData.data.id));
        }

        if (this.state.edit === true)
        {
          await this.MainSave();
        }
        this.props.dispatch(setTab(newValue))
        this.setState({ tabvalue: newValue, subspeedzien:subspeedzien });
    };

    async MainSave() {
        if (this.state.save === true) {
            let retdata = await SqlFunction.DoSql('ChgKantoor', this.state.data);
            if (retdata && retdata.nwzip === true) {
                this.props.dispatch(AllPostcodes())
            }
            this.setState({ edit: false, save: false, data: null, checkval: false })
            if (retdata) {
                await this.props.dispatch(GetKantoor(retdata.id))
                await this.props.dispatch(GetContKantoor(retdata.id))
            }
        }
    }

    checkconfirm(parameters) {
        if (this.state.save === true || this.state.subsave === true) {
            confirmAlert({
                closeOnEscape: false,
                closeOnClickOutside: false,
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Aanpassingen niet doorgevoerd.</h1>
                            <p>Wens je de aanpassingen alsnog te bewaren?</p>
                            <button
                                onClick={() => {
                                    this.handleJa(parameters);
                                    onClose();
                                }}
                            >
                                Ja
                            </button>
                            <button onClick={() => {
                                this.setState({ edit: false, save: false, subsave: false, checkval: false })
                                onClose();
                            }}
                            >
                                Nee
                            </button>
                        </div>
                    );
                }
            });
        }

    }

    async componentWillReceiveProps(nextProps, nextState) {
        if (this.props.kantoor.MainData) {
            if (this.props.kantoor.MainData.data.id !== nextProps.kantoor.MainData.data.id) {
                await this.checkconfirm(this.props.kantoor.MainData);
                if (nextProps.kantoor.MainData.data.id === 0) {
                    this.setState({ edit: true, save: false, subsave: false })
                }
                else {
                    this.setState({ edit: false, save: false, subsave: false })
                    this.setState({ tabvalue: '1' });
                }
            }
        }
    }

    async handleJa(parameters) {
        if (this.state.save === true) {
            let retdata = await SqlFunction.DoSql('ChgKantoor', this.state.data);
            if (retdata.nwzip === true) {
                this.props.dispatch(AllPostcodes())
            }

        }
        if (this.state.subsave === true) {
        }
        //    this.setState({edit:false,save:false,subsave:false})
    }

    async ActionClick(name) {
        switch (name) {
            case 'Edit':
                this.setState({ edit: true, data: this.props.kantoor.MainData.data, speedopen: false })
                break;

            case 'New':
                await this.props.dispatch(CreateKantoor())
                this.setState({ edit: true, data: this.props.kantoor.MainData.data, speedopen: false })
                break;

            case 'Save':
                this.MainSave();
                break;

            default:
                break;
        }
        this.setState({ speedopen: false });
    }

  async SubActionClick(name) {
    switch (name) {
        case 'Document':
            if (this.state.save === true && this.state.edit === true) 
            {
              await this.checkconfirm(this.props.kantoor.MainData);
            }
            else
            {
                this.setState({edit:false})
            }
            dropdoc = null;
            let element = document.getElementById(accdropid);
            element.click()
            break;

        case 'Contact':
            if (this.state.save === true && this.state.edit === true) 
            {
              await this.checkconfirm(this.props.kantoor.MainData);
            }
            else
            {
                this.setState({edit:false})
            }
            this.props.dispatch(zetview(this.props.kantoor.MainData.data.name, this.props.kantoor.MainData.data.id, 'KANT'));
            this.props.dispatch(clickview({ id: '', vorm: 'KANTCONT' }));
            await this.props.dispatch(AllKantContacts(this.props));
            let data = { kantoorid: this.props.kantoor.MainData.data.id, kantoor_name: this.props.kantoor.MainData.data.name };
            this.props.dispatch(setSubTitle('Contactpersoon'));
            await this.props.dispatch(CreateKantContact(data))
            this.props.history.push('/kantcontact');
            break;

        default:
            break;
    }
    this.setState({ speedopen: false });
  }


    async ContCellClick(waarde) {
        this.props.dispatch(zetview(this.props.kantoor.MainData.data.name, this.props.kantoor.MainData.data.id, 'KANT'));
        this.props.dispatch(clickview({ id: waarde.data.id, vorm: 'CONT' }));

        await this.props.dispatch(AllKantContacts(this.props));
        this.props.dispatch(setSubTitle('Contactpersoon'));
        await this.props.dispatch(GetKantContact(waarde.data.id))
        this.props.history.push('/kantcontact');
        return;
    }

    async RegCellClick(waarde) {
        this.props.dispatch(setSubTitle('Registratie'))
        this.props.dispatch(zetview(this.props.kantoor.MainData.data.name, this.props.kantoor.MainData.data.id, 'KANT'));
        this.props.dispatch(clickview({ id: waarde.data.id, vorm: 'REG' }));
        await this.props.dispatch(GetRegistration(waarde.data.id))
        await this.props.dispatch(GetAccount(waarde.data.accountid))
        this.props.history.push('/registration');
        return;
    }

    async JobCellClick(waarde) {
        let jobdata = await SqlFunction.DoSql('GetJob', waarde.data);
        let title = 'Jobdetail - ' + jobdata.data.description + ' - ' + jobdata.data.account_name
        this.setState({ PopUpScreen: (<PopJobAanvrNum open={true} data={jobdata} close={this.ClosePopJobs} title={title} />) });
        return;
      }
    
      async ClosePopJobs() {
        this.setState({ PopUpScreen: null });
      }

    render() {
        const { classes } = this.props;
        var { tableheight } = this.state;
        let mailref = (this.props.kantoor && this.props.kantoor.MainData && this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.e_mail.length > 3)
            ? "mailto:" + this.props.kantoor.MainData.data.e_mail : null;


        return (
            <div>
                <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
                    <Grid container>
                        <Grid item xs={8}>
                            <h4 tag="h4" className="mt-3" onClick={() => { this.setState({ edit: false }) }}>{this.props.settings.sub_title}</h4>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>


                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        icon={<SpeedDialIcon style={{ zIndex: '10' }} />}
                        direction="left"
                        classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
                        onClose={this.handleSpeedClose}
                        onOpen={this.handleSpeedOpen}
                        open={this.state.speedopen}
                    >
                        {this.state.speedactions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipPlacement='bottom'
                                FabProps={{
                                    disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows, 'AllKantoren', 'edit')) ? false :
                                    (action.name === 'Edit' && (this.state.edit === true)) ? true :
                                    (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows, 'AllKantoren', 'edit')) ? true :
                                    (action.name === 'New' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows, 'AllKantoren', 'edit')) ? false :
                                    (action.name === 'New' && (this.state.edit === true )) ? true :
                                    (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows, 'AllKantoren', 'edit')) ? true :
                                    (action.name === 'Save' && (this.state.edit === true ) && this.state.save === true) ? false :
                                    (action.name === 'Save' && (this.state.edit === true ) && this.state.save === false) ? true :
                                    (action.name === 'Save' && this.state.edit === false ) ? true :
                                    (action.name === 'Save' && this.state.save === false) ? true : false
                                }}
                                onClick={() => { this.ActionClick(action.name) }}
                                tooltipTitle={action.tooltip}
                            />
                        ))}
                    </SpeedDial>
                    {this.state.subspeedzien && this.state.edit === false && 
                    <SpeedDial
                      ariaLabel="SpeedDial example"
                      icon={<SubSpeedDialIcon style={{zIndex:'10'}}/>}
                      direction="left"
                      classes={{root: classes.subspeedDialToolb, fab: classes.subspeedFabRoot}}           
                      onClose={this.handleSubSpeedClose}
                      onOpen={this.handleSubSpeedOpen}
                      open={this.state.subspeedopen}          
                    >
                      {this.state.subspeedactions.map(action => (
                        <SpeedDialAction
                          key={action.name}
                          icon={action.icon}
                          tooltipPlacement='bottom'
                          FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                               (action.name === 'Edit' && (this.state.edit === true || this.state.editfact === true) ) ? true :
                                               (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                               (action.name === 'New' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                               (action.name === 'New' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                               (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                               (action.name === 'NewRegistratie' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                               (action.name === 'NewRegistratie' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                               (action.name === 'NewRegistratie' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                               (action.name === 'Contact' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? false :
                                               (action.name === 'Contact' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                               (action.name === 'Contact' && !GenFunc.allowed(this.props.user.userData.secrows,'Contactpersonen','edit')) ? true :
                                               (action.name === 'Document' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                               (action.name === 'Document' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                               (action.name === 'Document' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                               (action.name === 'ChangedAccData' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? false :
                                               (action.name === 'ChangedAccData' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                               (action.name === 'ChangedAccData' && !GenFunc.allowed(this.props.user.userData.secrows,'Klanten','edit')) ? true :
                                               (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === true) ? false :
                                               (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === false) ? true :
                                               (action.name === 'Save' && this.state.edit === false && this.state.editfact === false) ? true : 
                                               (action.name === 'Save' && this.state.save === false) ? true : false}}
                          onClick={() => {this.SubActionClick(action.name)}}                             
                          tooltipTitle={action.tooltip}
                        />
                      ))}
                    </SpeedDial>     
                    }     
                </Toolbar>

                {this.props.kantoor && this.props.kantoor.MainData && this.props.kantoor.MainData.data &&
                    <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick={true} noKeyboard={true}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container" style={{ width: '100%', maxWidth: '100%' }}>
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <button style={{ display: 'none' }} id={accdropid} type="button" onClick={openDialog}></button>
                                    <FileDownload formid={accformid} frameid={accframeid} DocData={this.state.DownloadData} />
                                    <Grid container style={{width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px'}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={this.state.edit === false ? 3 : 5}>
                                                <Card className='card_brut'>
                                                    {this.state.edit === false &&
                                                        <PerfectScrollbar className="sidebar-nav">
                                                            <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                                                <h4 style={{ marginTop: '25px', cursor: 'pointer' }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">{this.props.kantoor.MainData.data.name} </h4>
                                                                <h6 style={{ marginTop: '25px', cursor: 'pointer' }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">{this.props.kantoor.MainData.data.type_reg_txt} </h6>
                                                                <h6 style={{ cursor: 'pointer' }}> <a href={mailref}>{this.props.kantoor.MainData.data.e_mail}</a></h6>
                                                                <h6 style={{ cursor: 'pointer' }}> <a href={mailref}>{this.props.kantoor.MainData.data.email2}</a></h6>

                                                                <div onClick={() => {this.ZetEdit()}} style={{width:'100%', cursor: 'pointer'}}>
                                                                    <h6 style={{ marginBottom: '25px' }}>{this.props.kantoor.MainData.data.main_phone}</h6>
                                                                    <div className='div_line' style={{marginBottom: '25px'}}></div>
                                                                    <div  style={{ marginTop: '25px', marginBottom: '25px' }}>
                                                                        <small className="text-muted p-t-30 db">Adres</small>
                                                                        {this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.adres_1 && this.props.kantoor.MainData.data.adres_1.length > 1 &&
                                                                                <h6>{this.props.kantoor.MainData.data.adres_1} </h6>
                                                                        }
                                                                        {this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.adres_2 && this.props.kantoor.MainData.data.adres_2.length > 1 &&
                                                                                <h6>{this.props.kantoor.MainData.data.adres_2} </h6>
                                                                        }
                                                                        {this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.adres_3 && this.props.kantoor.MainData.data.adres_3.length > 1 &&
                                                                                <h6>{this.props.kantoor.MainData.data.adres_3} </h6>
                                                                        }
                                                                        <h6>{this.props.kantoor.MainData.data.adress} {this.props.kantoor.MainData.data.huisnum} </h6>
                                                                        <small className="text-muted p-t-30 db">Gemeente</small>
                                                                        <h6>{this.props.kantoor.MainData.data.zipcode_txt}</h6>
                                                                        <small className="text-muted p-t-30 db">Land</small>
                                                                        <h6>{this.props.kantoor.MainData.data.country_txt}</h6>
                                                                    </div>
                                                                    <div className='div_line' style={{marginBottom: '25px'}}></div>
                                                                    <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                                                                        <small className="text-muted p-t-30 db">IBAN</small>
                                                                        <h6>{this.props.kantoor.MainData.data.iban}</h6>
                                                                        <small className="text-muted p-t-30 db">BIC</small>
                                                                        <h6>{this.props.kantoor.MainData.data.bic}</h6>
                                                                    </div>
                                                                    <div className='div_line' style={{marginBottom: '25px'}}></div>
                                                                    <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                                                                        <small className="text-muted p-t-30 db" >Informatie</small>   
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.props.kantoor.MainData.data.info}
                                                                            coltype = 'STR'  
                                                                            multiline={true}                      
                                                                            disabled
                                                                            readonly = {true}
                                                                         />                                                                                       
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </PerfectScrollbar>
                                                    }
                                                    {this.state.edit === true &&
                                                        <PerfectScrollbar styleclassName="sidebar-nav">
                                                            <CardBody style={{textAlign:'left',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.name}
                                                                        coltype='STR'
                                                                        labelText="Naam"
                                                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'name')}
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'name')}
                                                                    />
                                                                </Grid>
                                                                <Grid style={{textAlign:'lefyt'}} item sm={12} md={12} lg={12}>
                                                                  <EditSelect className="card-title m-t-10"
                                                                    value={this.state.data.type_reg_txt}
                                                                    data={this.state.data}
                                                                    labelText="Type kantoor"
                                                                    type='AllRegType'
                                                                    colId='type_reg_txt'
                                                                    selectcel='type_reg'
                                                                    id={this.state.data.type_reg}
                                                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'type_reg_txt')}
                                                                    setError={(params,value,type) => this.ErrorCheck(params,value,type,'type_reg_txt')}
                                                                  />
                                                                </Grid>

                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.e_mail}
                                                                        coltype='STR'
                                                                        labelText="E-mail"
                                                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'e_mail')}
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'e_mail')}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.email2}
                                                                        coltype='STR'
                                                                        labelText="E-mail "
                                                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'email2')}
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'email2')}
                                                                    />
                                                                </Grid>

                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.main_phone}
                                                                        coltype='STR'
                                                                        labelText="Telefoonnummer"
                                                                        format = {['+',/[0-9]/,/\d/,' ','(',/[0-9]/,')',/[0-9]/,/\d/,' ',/\d/, /\d/,' ',/\d/,/\d/,' ',/\d/,/\d/,' ',/\d/,/\d/,' ',/[0-9]/,/[0-9]/,' ',/[0-9]/,/[0-9]/]}
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'main_phone')}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.adres_1}
                                                                        coltype='STR'
                                                                        labelText="Adresregel 1"
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'adres_1')}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.adres_2}
                                                                        coltype='STR'
                                                                        labelText="Adresregel 2"
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'adres_2')}
                                                                    />
                                                                </Grid>

                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.adres_3}
                                                                        coltype='STR'
                                                                        labelText="Adresregel 3"
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'adres_3')}
                                                                    />
                                                                </Grid>

                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={8} md={8} lg={8}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.adress}
                                                                            coltype='STR'
                                                                            labelText="Straat"
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'adress')}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.huisnum}
                                                                            coltype = 'STR'                        
                                                                            labelText="Huisnummer"
                                                                            setError={(params,value,type) => this.ErrorCheck(params,value,type,'huisnum')}
                                                                            ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'huisnum')}
                                                                        />
                                                                    </Grid>
                                                                </Grid>

                                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                                    <EditAutoSelect className="card-title m-t-10"
                                                                        checkval={this.state.checkval}
                                                                        value={this.state.data.zipcode_txt}
                                                                        GoogleZoek={true}
                                                                        labelText="Gemeente"
                                                                        lijst='AllPostcodes'
                                                                        colId='zipcode_txt'
                                                                        selectcel='zipcode'
                                                                        id={this.state.data.zipcode}
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'zipcode_txt')}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                                    <EditAutoSelect className="card-title m-t-10"
                                                                        checkval={this.state.checkval}
                                                                        value={this.state.data.country_txt}
                                                                        GoogleZoek={true}
                                                                        labelText="Land"
                                                                        lijst='AllLanden'
                                                                        colId='country_txt'
                                                                        selectcel='country'
                                                                        id={this.state.data.country}
                                                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'country_txt')}
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'country_txt')}
                                                                    />
                                                                </div>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.iban}
                                                                        coltype='STR'
                                                                        labelText="IBAN"
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'iban')}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.bic}
                                                                        coltype='STR'
                                                                        labelText="BIC"
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'bic')}
                                                                    />
                                                                </Grid>
                                                                <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <small className="text-muted p-t-30 db">Informatie</small>
                                                                    </Grid>

                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.info}
                                                                            coltype='STR'
                                                                            multiline={true}
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, type, 'info')}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                                                            </CardBody>
                                                        </PerfectScrollbar>
                                                    }
                                                </Card>
                                            </Grid>
                                            <Grid item xs={this.state.edit === false ? 9 : 7}>
                                                <Card className='card_brut'>
                                                    <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                                        <div className='div_line'>
                                                            <Nav tabs >
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={classnames({ active: this.state.tabvalue === '1' })}
                                                                        onClick={(event) => { this.handleTabChange(event, '1'); }}
                                                                        style={{ cursor: 'default' }}
                                                                    >
                                                                        Contactpersonen
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={classnames({ active: this.state.tabvalue === '2' })}
                                                                        onClick={(event) => { this.handleTabChange(event, '2'); }}
                                                                        style={{ cursor: 'default' }}
                                                                    >
                                                                        Documenten
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={classnames({ active: this.state.tabvalue === '3' })}
                                                                        onClick={(event) => { this.handleTabChange(event, '3'); }}
                                                                        style={{ cursor: 'default' }}
                                                                    >
                                                                        Registraties
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={classnames({ active: this.state.tabvalue === '4' })}
                                                                        onClick={(event) => { this.handleTabChange(event, '4'); }}
                                                                        style={{ cursor: 'default' }}
                                                                    >
                                                                        Jobs
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                            <TabContent activeTab={this.state.tabvalue}>
                                                                <TabPane tabId="1">
                                                                    {this.state.tabvalue === '1' && this.props.kantoor.ContData && this.props.kantoor.ContData.data && this.props.kantoor.ContData.kol &&
                                                                        this.props.kantoor && this.props.kantoor.MainData && this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.id !== 0 &&
                                                                        <TableGrid
                                                                            tableheight={tableheight}
                                                                            columns={this.props.kantoor.ContData.kol}
                                                                            data={this.props.kantoor.ContData.data}
                                                                            sizetofit={true}
                                                                            sortable={false}
                                                                            suppressMenu={true}
                                                                            enableRowGroup={false}
                                                                            suppressColumnVirtualisation={true}
                                                                            filter={true}
                                                                            sleutel='ContKantoor'
                                                                            getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllSecPar')}
                                                                            editable={false}
                                                                            sql={() => this.props.dispatch(GetContKantoor(this.props.kantoor.MainData.data.id))}
                                                                            CellClickedEvent={(column, colDef, value) => this.ContCellClick(column, colDef, value)}
                                                                        />
                                                                    }
                                                                </TabPane> 
                                                                <TabPane tabId="2">
                                                                    {this.state.tabvalue === '2' && this.props.kantoor.DocData && this.props.kantoor.DocData.data && this.props.kantoor.DocData.kol &&
                                                                        this.props.kantoor && this.props.kantoor.MainData && this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.id !== 0 &&
                                                                        <TableGrid
                                                                            tableheight={tableheight}
                                                                            columns={this.props.kantoor.DocData.kol}
                                                                            data={this.props.kantoor.DocData.data}
                                                                            sizetofit={true}
                                                                            sortable={false}
                                                                            suppressMenu={true}
                                                                            enableRowGroup={false}
                                                                            suppressColumnVirtualisation={true}
                                                                            filter={true}
                                                                            sleutel='DocKantoor'
                                                                            getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllSecPar')}
                                                                            editable={false}
                                                                            sql={() => this.props.dispatch(GetDocKantoor(this.props.kantoor.MainData.data.id))}
                                                                            CellClickedEvent={(column, colDef, value) => this.DocCellClick(column, colDef, value)}
                                                                        />
                                                                    }
                                                                </TabPane> 
                                                                <TabPane tabId="3">
                                                                    {this.state.tabvalue === '3' && this.props.kantoor.RegData && this.props.kantoor.RegData.data && this.props.kantoor.RegData.kol &&
                                                                        this.props.kantoor && this.props.kantoor.MainData && this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.id !== 0 &&
                                                                        <TableGrid
                                                                            tableheight={tableheight}
                                                                            columns={this.props.kantoor.RegData.kol}
                                                                            data={this.props.kantoor.RegData.data}
                                                                            sizetofit={true}
                                                                            sortable={false}
                                                                            suppressMenu={true}
                                                                            enableRowGroup={false}
                                                                            suppressColumnVirtualisation={true}
                                                                            filter={true}
                                                                            sleutel='RegKantoren'
                                                                            getContextMenuItems={(param) => this.getContextMenuItems(param, 'AllSecPar')}
                                                                            editable={false}
                                                                            sql={() => this.props.dispatch(GetRegKantoor(this.props.user.MainData.data.id))}
                                                                            CellClickedEvent={(column, colDef, value) => this.RegCellClick(column, colDef, value)}
                                                                        />
                                                                    }
                                                                </TabPane> 
                                                                <TabPane tabId="4">
                                                                    {this.state.tabvalue === '4' && this.props.kantoor.JobsData && this.props.kantoor.JobsData.data && this.props.kantoor.JobsData.kol &&
                                                                        this.props.settings.listmaindata && this.props.settings.listmaindata.mainkey &&
                                                                        this.props.kantoor && this.props.kantoor.MainData && this.props.kantoor.MainData.data && this.props.kantoor.MainData.data.id !== 0 &&
                                                                        <TableGrid
                                                                            tableheight={tableheight}
                                                                            columns={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['kol']}
                                                                            data={this.props[this.props.settings.listmaindata.mainkey][this.props.settings.listmaindata.subkey]['data']}
                                                                            CellClickedEvent={(column, colDef, value) => this.JobCellClick(column, colDef, value)}
                                                                            sortable={this.props.settings.listmaindata.sortable}
                                                                            sizetofit={this.props.settings.listmaindata.sizetofit}
                                                                            suppressMenu={this.props.settings.listmaindata.suppressMenu}
                                                                            enableRowGroup={this.props.settings.listmaindata.enableRowGroup}
                                                                            headerComponentParams={null}
                                                                            suppressColumnVirtualisation={this.props.settings.listmaindata.suppressColumnVirtualisation}
                                                                            filter={this.props.settings.listmaindata.filter}
                                                                            editable={false}
                                                                            sleutel={this.props.settings.listmaindata.referentie}
                                                                        />
                                                                    }

                                                                </TabPane>
                                                            </TabContent>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                }
                {this.state.PopUpScreen}
            </div>
        );
    }
}
export default connect(mapStateToProps)(withStyles(styles)(Kantoorpage));