import React, { createRef } from 'react';
import classnames from 'classnames';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink,  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import TableGrid from "../../components/TableGrid/TableGrid.jsx";

import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";

import Dropzone from 'react-dropzone';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SubSpeedDialIcon from '@material-ui/lab/SpeedDialIcon/SubSpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import FileDownload from "../../components/FileDownload.jsx";

import SaveIcon from '@material-ui/icons/Save';
import FilePlus from '@material-ui/icons/FilePlus';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';

import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import {AllPostcodes} from "../../redux/settings/paramActions";
import {setPanelRight, setTitleRight, setSubTitle} from "../../redux/settings/action";
import {GetRegistration} from "../../redux/settings/registrationActions";
import {GetAccount} from "../../redux/settings/accountActions";
import {AllSuppliers,setTab, GetDocSupplier, GetRegSupplier, GetSupplier, CreateSupplier } from '../../redux/settings/supplierActions.js';

const dropzoneRef = createRef();
const openDialog = () => {
    if (dropzoneRef.current) {
        dropzoneRef.current.open()
    }
};
const suppdropid = GenFunc.MakeId();
const suppframeid = GenFunc.MakeId();
const suppformid = GenFunc.MakeId();

var dropdoc = null;

const mapStateToProps = state => ({
    ...state
});

class Supplierpage extends React.Component {

    state = {
        DownloadData: { id: '' },
        tableheight: Math.floor(window.innerHeight * .70) + 'px',
        tabvalue: this.props.supplier && this.props.supplier.LastTab ? this.props.supplier.LastTab : '1',
        edit: this.props.supplier && this.props.supplier.MainData && this.props.supplier.MainData.data && this.props.supplier.MainData.data.id === 0 ? true : false,
        save: false,
        subsave: false,
        adrestel: 0,
        checkval: false,
        data: this.props.supplier && this.props.supplier.MainData && this.props.supplier.MainData.data && this.props.supplier.MainData.data.id === 0 ? this.props.supplier.MainData.data : null,
        speedactions: [
            { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
            { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe leverancier' },
            { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
        ],
        subspeedactions: [
           { icon: <FilePlus />, name: 'Document', tooltip: 'Document toevoegen' },
        ],
        speedopen: false,
        subspeedopen: false, 
        subspeedzien:true,
        tabsubspeedzien:true,
    };

    constructor(props) {
        super(props);
        this.resizeFunction = this.resizeFunction.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.DocCellClick = this.DocCellClick.bind(this);
        this.handleSpeedClose = this.handleSpeedClose.bind(this);
        this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
        this.handleSubSpeedClose = this.handleSubSpeedClose.bind(this);
        this.handleSubSpeedOpen = this.handleSubSpeedOpen.bind(this);


        this.ZetEdit = this.ZetEdit.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.MainSave = this.MainSave.bind(this);
        this.checkconfirm = this.checkconfirm.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.handleJa = this.handleJa.bind(this);
        this.ActionClick = this.ActionClick.bind(this)
        this.SubActionClick = this.SubActionClick.bind(this);
        this.RegCellClick = this.RegCellClick.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);

    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);
        this.props.dispatch(setTitleRight('Leveranciers'))
        this.props.dispatch(setSubTitle('Leverancier'))
        this.props.dispatch(setPanelRight(true))
        let prevtab = this.state.tabvalue;
        this.handleTabChange(prevtab)
    }

    componentWillUnmount() {
        this.checkconfirm(this.props.supplier.MainData);
        this.props.dispatch(setPanelRight(false))
        this.props.dispatch(setTitleRight(''))
        window.removeEventListener("resize", this.resizeFunction);
    }

    resizeFunction() {
        if (window.innerHeight >= 960) {
            this.setState({ tableheight: Math.floor(window.innerHeight * .70) + 'px' });
        }
        else {
            this.setState({ tableheight: '600px' });
        }
    }


    getContextMenuItems() {
        return null;
    }
    async ErrorCheck(that, waarde, type, colId) {
      let data = this.state.data;
      let terug = Validation.ValidCheck('Leverancier', waarde, colId, that, this.props.supplier.AllData.data, data);

      that.setState({ error: terug })
    }      


    async ValueCheck(that, waarde, colId) {
        let data = this.state.data;
        let terug = false;
        switch (colId) {
            case 'country_txt':
                data.country_txt = waarde.value;
                data.country = waarde.id;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.supplier.AllData.data, data);
                break;

            case 'zipcode_txt':
                data.zipcode_txt = waarde.value;
                data.zipcode = waarde.id;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde.value, colId, that, this.props.supplier.AllData.data, data);
                if (that.overzdata) {
                    data.country_txt = that.overzdata.country_txt;
                    data.country = that.overzdata.country;
                }
                break;


            case 'vatnumber':
                data[colId] = waarde.toUpperCase();
                terug = Validation.ValidCheck('Leverancier', waarde.toUpperCase(), colId, that, this.props.supplier.AllData.data, data);
                console.log(terug)
                break;


            default:
                data[colId] = waarde;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.supplier.AllData.data, data);
                break;
        }
        that.setState({ error: terug })
        terug = await this.CheckSave(data);
        this.setState({ save: terug, data: data, checkval: false })
    }   
    
    async onDrop(files) {
        const formData = new FormData();
        this.handleTabChange('0');
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            formData.append('file', file);

        }
        if (dropdoc) {
            formData.set('supplier_id', dropdoc.supplier_id);
            formData.set('type', dropdoc.type);
            formData.set('docid', dropdoc.id);
            formData.set('docdata', dropdoc);
        }
        else {
            formData.set('supplier_id', this.props.supplier.MainData.data.id);
            formData.set('type', 'supplier');
        }

        await SqlFunction.DoSqlBody('saveFile', formData);
        dropdoc = null;
        await this.props.dispatch(GetDocSupplier(this.props.supplier.MainData.data.id))
        this.handleTabChange('1');
    };

    async DocCellClick(waarde) {
        let column = waarde.column.colDef.field;
        if (column === 'upload') {
            dropdoc = waarde.data;
            let element = document.getElementById(suppdropid);
            element.click()
            return;
        }
        if (column === 'verwijder') {
            await SqlFunction.DoSql('DeleteFile', waarde.data);
            await this.props.dispatch(GetDocSupplier(this.props.supplier.MainData.data.id))
            return;
        }
        await this.setState({ DownloadData: waarde.data });
        document.getElementById(suppformid).submit();
        return;
    }

    handleSpeedClose() {
      this.setState({ speedopen: false, subspeedzien:this.state.tabsubspeedzien });
    };

    handleSpeedOpen() {
      this.setState({ speedopen: true, subspeedzien:false });
    };

    handleSubSpeedClose() {
      this.setState({ subspeedopen: false });
    };

    handleSubSpeedOpen() {
      this.setState({ subspeedopen: true });
    };


    ZetEdit() {
        this.setState({ edit: true, data: this.props.supplier.MainData.data })
    }

    CheckSave(data) {
        let terug = true
        terug = Validation.ValidCheck(this.props.settings.sub_title, data.name, 'name', this, this.props.supplier.AllData.data, data);
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.e_mail, 'e-mail', this, this.props.supplier.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.zipcode_txt, 'zipcode_txt', this, this.props.supplier.AllData.data, data);
        }
        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.country_txt, 'country_txt', this, this.props.supplier.AllData.data, data);
        }

        if (terug === false) {
            terug = Validation.ValidCheck(this.props.settings.sub_title, data.vatnumber, 'vatnumber', this, this.props.supplier.AllData.data, data);
        }
        return !terug;
    }

    async handleTabChange(newValue) {
        let subspeedzien = false;

        if (newValue === '1') {
          let subspeedactions = (GenFunc.allowed(this.props.user.userData.secrows,'Docu','edit') === true) ? [
          { icon: <FilePlus />, name: 'Document', tooltip: 'Document toevoegen' },
            ]: null;
            this.setState({subspeedactions:subspeedactions})
            subspeedzien = true;
            // Documenten
            await this.props.dispatch(GetDocSupplier(this.props.supplier.MainData.data.id))
        }
        if (newValue === '2') {
            // registraties
            await this.props.dispatch(GetRegSupplier(this.props.supplier.MainData.data.id))
        }
        this.props.dispatch(setTab(newValue))
        this.setState({ tabvalue: newValue, subspeedzien:subspeedzien, tabsubspeedzien:subspeedzien });

    };

    async MainSave() {
        if (this.state.save === true) {
            let retdata = await SqlFunction.DoSql('ChgSupplier', this.state.data);
            if (retdata && retdata.nwzip === true) {
                this.props.dispatch(AllPostcodes())
            }
            await this.props.dispatch(AllSuppliers());            
            this.setState({ edit: false, save: false, data: null, checkval: false })
            if (retdata) {
                await this.props.dispatch(GetSupplier(retdata.id))
                await this.props.dispatch(GetDocSupplier(retdata.id))
            }
        }
    }

    checkconfirm(parameters) {
        if (this.state.save === true || this.state.subsave === true) {
            confirmAlert({
                closeOnEscape: false,
                closeOnClickOutside: false,
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Aanpassingen niet doorgevoerd.</h1>
                            <p>Wens je de aanpassingen alsnog te bewaren?</p>
                            <button
                                onClick={() => {
                                    this.handleJa();
                                    onClose();
                                }}
                            >
                                Ja
                            </button>
                            <button onClick={() => {
                                this.setState({ edit: false, save: false, subsave: false, checkval: false })
                                onClose();
                            }}
                            >
                                Nee
                            </button>
                        </div>
                    );
                }
            });
        }

    }

    async componentWillReceiveProps(nextProps, nextState) {
        if (this.props.supplier.MainData) {
            if (this.props.supplier.MainData.data.id !== nextProps.supplier.MainData.data.id) {
                await this.checkconfirm(this.props.supplier.MainData);
                if (nextProps.supplier.MainData.data.id === 0) {
                    this.setState({ edit: true, save: false, subsave: false })
                    this.handleTabChange(null,'1')
                }
                else {
                    this.setState({ edit: false, save: false, subsave: false })
                    let prevtab = this.state.tabvalue
                    this.handleTabChange(null,prevtab)
                }
            }
        }
    }

    async handleJa() {
        if (this.state.save === true) {
            let retdata = await SqlFunction.DoSql('ChgSupplier', this.state.data);
            if (retdata.nwzip === true) {
                this.props.dispatch(AllPostcodes())
            }

        }
        if (this.state.subsave === true) {
        }
        //    this.setState({edit:false,save:false,subsave:false})
    }

  async SubActionClick(name) {
    switch (name) {
        case 'Document':
            dropdoc = null;
            let element = document.getElementById(suppdropid);
            element.click()
            break;


      default:
        break;
    }
    this.setState({ speedopen: false });
  }


    async ActionClick(name) {

        switch (name) {
            case 'Edit':
                this.setState({ edit: true, data: this.props.supplier.MainData.data, speedopen: false })
                break;

            case 'New':
                await this.props.dispatch(CreateSupplier())
                this.setState({ edit: true, data: this.props.supplier.MainData.data, speedopen: false })
                break;

            case 'Save':
                this.MainSave();
                break;


            default:
                break;
        }
        this.setState({ speedopen: false });
    }

    async RegCellClick(waarde) {
        this.props.dispatch(setSubTitle('Registratie'))
        await this.props.dispatch(GetRegistration(waarde.data.id))
        await this.props.dispatch(GetAccount(waarde.data.accountid))
        this.props.history.push('/registration');
        return;
    }

    render() {
        const { classes } = this.props;
        var { tableheight } = this.state;
        let mailref = (this.props.supplier && this.props.supplier.MainData && this.props.supplier.MainData.data && this.props.supplier.MainData.data.e_mail.length > 3)
            ? "mailto:" + this.props.supplier.MainData.data.e_mail : null;

        return (
            <div>
                <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px',width: 'calc(100% - 27px)', zIndex: '2', position:'fixed'}}>
                    <Grid container>
                        <Grid item xs={8}>
                            <h4 tag="h4" className="mt-3" onClick={() => { this.setState({ edit: false }) }}>{this.props.settings.sub_title}</h4>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                      <SpeedDial
                        ariaLabel="SpeedDial example"
                        icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
                        direction="left"
                        classes={{root: classes.speedDialToolb, fab: classes.speedFabRoot}}           
                        onClose={this.handleSpeedClose}
                        onOpen={this.handleSpeedOpen}
                        open={this.state.speedopen}          
                      >
                        {this.state.speedactions.map(action => (
                          <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipPlacement='bottom'
                            FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllSuppliers','edit')) ? false :
                                                 (action.name === 'Edit' && (this.state.edit === true) ) ? true :
                                                 (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'AllSuppliers','edit')) ? true :
                                                 (action.name === 'New' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllSuppliers','edit')) ? false :
                                                 (action.name === 'New' && (this.state.edit === true)) ? true :
                                                 (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'AllSuppliers','edit')) ? true :
                                                 (action.name === 'Save' && (this.state.edit === true ) && this.state.save === true) ? false :
                                                 (action.name === 'Save' && (this.state.edit === true ) && this.state.save === false) ? true :
                                                 (action.name === 'Save' && this.state.edit === false ) ? true : 
                                                 (action.name === 'Save' && this.state.save === false) ? true : false}}
                            onClick={() => {this.ActionClick(action.name)}}                             
                            tooltipTitle={action.tooltip}
                          />
                        ))}
                      </SpeedDial>          

                      {this.state.subspeedzien && this.state.edit === false && 
                        <SpeedDial
                          ariaLabel="SpeedDial example"
                          icon={<SubSpeedDialIcon style={{zIndex:'10'}}/>}
                          direction="left"
                          classes={{root: classes.subspeedDialToolb, fab: classes.subspeedFabRoot}}           
                          onClose={this.handleSubSpeedClose}
                          onOpen={this.handleSubSpeedOpen}
                          open={this.state.subspeedopen}          
                        >
                          {this.state.subspeedactions.map(action => (
                            <SpeedDialAction
                              key={action.name}
                              icon={action.icon}
                              tooltipPlacement='bottom'
                              FabProps={{disabled: (action.name === 'Save' && this.state.save === false) ? true : false}}
                              onClick={() => {this.SubActionClick(action.name)}}                             
                              tooltipTitle={action.tooltip}
                            />
                          ))}
                        </SpeedDial>     
                      }     
                </Toolbar>
                {this.props.supplier && this.props.supplier.MainData && this.props.supplier.MainData.data &&
                    <Dropzone ref={dropzoneRef} onDrop={this.onDrop} noClick={true} noKeyboard={true}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container" style={{ width: '100%', maxWidth: '100%' }}>
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <button style={{ display: 'none' }} id={suppdropid} type="button" onClick={openDialog}></button>
                                    <FileDownload formid={suppformid} frameid={suppframeid} DocData={this.state.DownloadData} />

                                    <Grid container style={{width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px'}}>

                                        <Grid container spacing={1}>
                                            <Grid item xs={this.state.edit === false ? 3 : 5}>
                                                <Card className='card_brut'>
                                                    {this.state.edit === false &&
                                                        <PerfectScrollbar className="sidebar-nav">
                                                            <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                                                <h4 style={{ marginTop: '25px', cursor: 'pointer' }} onClick={() => { this.ZetEdit() }} className="card-title m-t-10">{this.props.supplier.MainData.data.name} </h4>
                                                                <h6 style={{ cursor: 'pointer' }}> {this.props.supplier.MainData.data.vatnumber}</h6>
                                                                <h6 style={{ cursor: 'pointer' }}> <a href={mailref}>{this.props.supplier.MainData.data.e_mail}</a></h6>
                                                                <h6 style={{ marginBottom: '25px' }}>{this.props.supplier.MainData.data.main_phone}</h6>
                                                                <div className='div_line' style={{marginBottom: '25px'}}></div>
                                                                <div  style={{ marginTop: '25px', marginBottom: '25px' }}>
                                                                    <small className="text-muted p-t-30 db">Adres</small>
                                                                    <h6 onClick={() => { this.ZetEdit() }}>{this.props.supplier.MainData.data.adress}</h6>
                                                                    <small className="text-muted p-t-30 db">Gemeente</small>
                                                                    <h6 onClick={() => { this.ZetEdit() }}>{this.props.supplier.MainData.data.zipcode_txt}</h6>
                                                                    <small className="text-muted p-t-30 db">Land</small>
                                                                    <h6 onClick={() => { this.ZetEdit() }}>{this.props.supplier.MainData.data.country_txt}</h6>
                                                                </div>
                                                                <div className='div_line' style={{marginBottom: '25px'}}></div>
                                                                <div style={{ marginTop: '25px', marginBottom: '25px' }}>
                                                                    <small className="text-muted p-t-30 db" >Informatie</small>  
                                                                      <EditString className="card-title m-t-10"
                                                                          style={{cursor: 'pointer'}} 
                                                                          onClick={() => {this.ZetEdit()}}
                                                                          value={this.props.supplier.MainData.data.info}
                                                                          coltype = 'STR'  
                                                                          multiline={true}                      
                                                                          disabled
                                                                          readonly = {true}
                                                                       />                                                
                                                                </div>
                                                            </CardBody>
                                                        </PerfectScrollbar>
                                                    }
                                                    {this.state.edit === true &&
                                                        <PerfectScrollbar styleclassName="sidebar-nav">
                                                            <CardBody style={{textAlign:'center',height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.name}
                                                                        coltype='STR'
                                                                        labelText="Naam"
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'name')}
                                                                    />
                                                                </Grid>
                                                                  <Grid item sm={12} md={12} lg={12}>
                                                                      <EditString className="card-title m-t-10"
                                                                        value={this.state.data.vatnumber}
                                                                        coltype = 'STR'                        
                                                                        labelText="BTW nummer"
                                                                        format = {[/[a-z, A-Z]/, /[a-z, A-Z]/,'-',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/, /[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/, /[a-z, A-Z, 0-9]/, /[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,'.',/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/,/[a-z, A-Z, 0-9]/]}
                                                                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,'vatnumber')}
                                                                        setError={(params,value,type) => this.ErrorCheck(params,value,type,'vatnumber')}
                                                                      />
                                                                  </Grid>

                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.e_mail}
                                                                        coltype='STR'
                                                                        labelText="E-mail"
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'e_mail')}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.main_phone}
                                                                        coltype='STR'
                                                                        labelText="Telefoonnummer"
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'main_phone')}
                                                                    />
                                                                </Grid>
                                                                <Grid item sm={12} md={12} lg={12}>
                                                                    <EditString className="card-title m-t-10"
                                                                        value={this.state.data.adress}
                                                                        coltype='STR'
                                                                        labelText="Adres"
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'adress')}
                                                                    />
                                                                </Grid>
                                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                                    <EditAutoSelect className="card-title m-t-10"
                                                                        checkval={this.state.checkval}
                                                                        value={this.state.data.zipcode_txt}
                                                                        GoogleZoek={true}
                                                                        labelText="Gemeente"
                                                                        lijst='AllPostcodes'
                                                                        colId='zipcode_txt'
                                                                        selectcel='zipcode'
                                                                        id={this.state.data.zipcode}
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'zipcode_txt')}
                                                                    />
                                                                </div>
                                                                <div style={{ width: '100%', textAlign: 'center' }}>
                                                                    <EditAutoSelect className="card-title m-t-10"
                                                                        checkval={this.state.checkval}
                                                                        value={this.state.data.country_txt}
                                                                        GoogleZoek={true}
                                                                        labelText="Land"
                                                                        lijst='AllLanden'
                                                                        colId='country_txt'
                                                                        selectcel='country'
                                                                        id={this.state.data.country}
                                                                        ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'country_txt')}
                                                                    />
                                                                </div>
                                                                <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                                                                <Grid container spacing={1}>
                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <small className="text-muted p-t-30 db">Informatie</small>
                                                                    </Grid>

                                                                    <Grid item sm={12} md={12} lg={12}>
                                                                        <EditString className="card-title m-t-10"
                                                                            value={this.state.data.info}
                                                                            coltype='STR'
                                                                            multiline={true}
                                                                            ValueCheck={(params, value, type) => this.ValueCheck(params, value, 'info')}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <div className='div_line' style={{ marginBottom: '25px', marginTop: '25px', borderColor: '#4F5467', borderWidth: '2px' }}></div>
                                                            </CardBody>
                                                        </PerfectScrollbar>
                                                    }
                                                </Card>
                                            </Grid>
                                            <Grid item xs={this.state.edit === false ? 9 : 7}>
                                                <Card className='card_brut'>
                                                    <CardBody style={{height:Math.floor(window.innerHeight * .85) + 'px'}}>
                                                        <div className='div_line'>
                                                            <Nav tabs >
                                                                <NavItem>
                                                                    <NavLink
                                                                        className={classnames({ active: this.state.tabvalue === '1' })}
                                                                        onClick={(event) => { this.handleTabChange('1'); }}
                                                                        style={{ cursor: 'default' }}
                                                                    >
                                                                        Documenten
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                            <TabContent activeTab={this.state.tabvalue}>
                                                                <TabPane tabId="1">
                                                                    {this.state.tabvalue === '1' && this.props.supplier.DocData && this.props.supplier.DocData.data && this.props.supplier.DocData.kol &&
                                                                        this.props.supplier && this.props.supplier.MainData && this.props.supplier.MainData.data && this.props.supplier.MainData.data.id !== 0 &&
                                                                        <TableGrid
                                                                            tableheight={tableheight}
                                                                            columns={this.props.supplier.DocData.kol}
                                                                            data={this.props.supplier.DocData.data}
                                                                            sizetofit={true}
                                                                            sortable={false}
                                                                            suppressMenu={true}
                                                                            enableRowGroup={false}
                                                                            suppressColumnVirtualisation={true}
                                                                            filter={true}
                                                                            sleutel='DocKantoor'
                                                                            getContextMenuItems={(param) => this.getContextMenuItems()}
                                                                            editable={false}
                                                                            sql={() => this.props.dispatch(GetDocSupplier(this.props.kantoor.MainData.data.id))}
                                                                            CellClickedEvent={(column, colDef, value) => this.DocCellClick(column, colDef, value)}
                                                                        />
                                                                    }
                                                                </TabPane> 
                                                            </TabContent>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                }
            </div>
        );
    }
}
export default connect(mapStateToProps)(withStyles(styles)(Supplierpage));
