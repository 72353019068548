import React from 'react';
import NativeListener from 'react-native-listener';
import GenFunc from "../../functions/gen_functions.js";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import styles from "../../assets/components/customInputStyle.jsx";
import Clear from "@material-ui/icons/Exclamation";
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import Calculator from "../calculator/Calculator";
import IconButton from '@material-ui/core/IconButton';
import DialPad from '@material-ui/icons/Calculator';
import { Grid } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';


interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}


interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}

function replaceeval(item, index, arr) {
  arr[index] = eval(item);
}


class GridEditString extends React.Component {

    TextMaskCustom(propster:TextMaskCustomProps) {
      const { inputRef, ...other } = propster;
      var ares = this.props.colDef.format.split(";"); 
      ares.forEach(replaceeval)      
      let mask = ares;
      return (
        <MaskedInput
          {...other}
          mask={mask}
          placeholderChar={'\u2000'}
          showMask = {false}
        />      
      )
    }  

    NumberFormatCustom(props: NumberFormatCustomProps) {
      const { inputRef,onChange, ...other } = props;
      let prefix = this.props.colDef.prefix ? this.props.colDef.prefix : null;
      let suffix = this.props.colDef.suffix ? this.props.colDef.suffix : null;
      let showmask = "false";
      if (prefix === 'EUR') {prefix = '€'} else {prefix = ''}
      if (suffix === 'PERC') {suffix = '%'} else {suffix = ''}

      return (
        <NumberFormat
          {...other}
          getInputRef={inputRef}
          onValueChange={values => {
            onChange({
              target: {
                value: values.floatValue,
              },
            });
          }}         
          isNumericString={true} 
          thousandSeparator={'.'} 
          decimalSeparator={','}  
          decimalScale={this.props.colDef.decimal ? this.props.colDef.decimal : this.props.colDef.decimaal ? this.props.colDef.decimaal : '4'}
          format={this.props.colDef.format ? this.props.colDef.format : null}
          prefix={prefix}
          suffix={suffix}
          showmask = {showmask}
        />
      );
  }      

    constructor(props) {
        super(props);
        this.change = this.change.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.TextMaskCustom = this.TextMaskCustom.bind(this);
        this.NumberFormatCustom = this.NumberFormatCustom.bind(this);
        this.toggleCalculator = this.toggleCalculator.bind(this);
        this.handleCalculatorResult = this.handleCalculatorResult.bind(this);
        this.calcid = GenFunc.MakeId()
        this.inputid = GenFunc.MakeId()
        this.state = {
            value:this.props.value,
            error:false,
            losefocus:false,
            anchorEl:null,
            calculatorOpen: false
        }
    }
    componentDidMount() {
        this.props.agGridReact.props.onCellValueCheck(this,this.props.value)
        setTimeout(() => {
          document.activeElement.blur();
          let element = document.getElementById(this.inputid);
          if (element)
          {
            element.focus()
            element.select()
          }
       }, 10);    
    }
    getValue() {
        return this.state.value;
    }
    isPopup() {
        return false;
    }
    change(event, valid, stateName) 
    {
      var colId = this.props.column.getId();
      if(event.target)
      {
        this.setState({value:event.target.value,losefocus:false})
        this.props.node.setDataValue(colId, event.target.value);
        this.props.agGridReact.props.onCellValueCheck(this,event.target.value)
      } 
      else 
      {
        this.setState({value:event,losefocus:false})
        this.props.node.setDataValue(colId, event);
        this.props.agGridReact.props.onCellValueCheck(this,event)
      }
    }

    async handleBlur(event, valid, stateName) 
    {
      await this.setState({losefocus:true})
      this.props.agGridReact.props.onCellValueCheck(this,this.state.value)
    }

    handleKeyPress(event) {
      if(event.key === 'Enter')
      {
        this.props.agGridReact.props.onCellValueCheck(this,this.state.value)
      }
      if(event.key === '+' && this.props.colDef.coltype === 'NUM' && this.state.calculatorOpen === false)
      {
        document.getElementById(this.calcid).click();        
      }      

      if(event.shiftKey === false && event.key === 'Tab'){
        event.preventDefault();
        this.props.agGridReact.props.onCellValueCheck(this,this.state.value)
        this.props.agGridReact.api.tabToNextCell();
      }
      if(event.key === 'ArrowRight')
      {
        this.props.agGridReact.api.tabToNextCell();
      }
    }

    handleCalculatorResult(result) {
      this.setState({value: result});
    }
    toggleCalculator(event) {
      const calculatorOpen = !this.state.calculatorOpen;
      this.setState({
        calculatorOpen: calculatorOpen,
        anchorEl: event.currentTarget,  
      });
    }


    render() {
      const {
        classes,
        labelText,
        id,
        labelProps,
        helperText,      
      } = this.props;

      const formControlProps={fullWidth: true, size:'small'}
      const labelClasses = classNames({
        [" " + classes.labelRootError]: this.state.error,
        [" " + classes.labelRootSuccess]: this.state.success && !this.state.error
      });
      const underlineClasses = classNames({
        [classes.brutUnderline]: !this.state.error,
        [classes.underlineError]: this.state.error,
        [classes.underline]: true,
      });
      const inputClasses = classNames({
        [classes.input]: true,
        [classes.whiteInput]: this.state.white
      });
      var formControlClasses;
      if (formControlProps !== undefined) {
        formControlClasses = classNames(
          formControlProps.className,
          classes.formControl
        );
      } else {
        formControlClasses = classes.formControl;
      }
      var helpTextClasses = classNames({
        [classes.labelRootError]: this.state.error,
        [classes.labelRootSuccess]: this.state.success && !this.state.error
      });      
      let strlengte = this.props.column.actualWidth.toString()+'px';
      let inputComponent = (this.props.colDef.coltype === 'STR' && this.props.colDef.format) ?
          {inputComponent: this.TextMaskCustom} :
          (this.props.colDef.coltype === 'NUM') ?
          {inputComponent: this.NumberFormatCustom} : null
      if ( !this.props.colDef.contfield || (this.props.colDef.contfield && this.props.node.data[this.props.colDef.contfield] && this.props.node.data[this.props.colDef.contfield].length > 0 && this.props.colDef.checkvalue.indexOf(this.props.node.data[this.props.colDef.contfield]) >= 0 ))
      {
        return (
          <NativeListener style={{position: 'relative', width:'100%'}} onKeyDown={this.handleKeyPress}>
            <FormControl style={{ top: '-27px', position: 'relative', marginBottom: '15px', width: '100%' }} {...formControlProps} className={formControlClasses}>
              {labelText !== undefined ? (
                <InputLabel
                  className={classes.labelRoot + " " + labelClasses + " " + classes.labelRootError}
                  htmlFor={id}
                  {...labelProps}
                >
                  {labelText}
                </InputLabel>
              ) : null}
              <Grid container spacing={1} alignItems="flex-end">
                <Grid style={{display:'flex', width:'98%'}} item >
                  <Input
                    classes={{
                      input: inputClasses,
                      disabled: classes.disabled,
                      underline: underlineClasses
                    }}
                    style={(this.props.colDef.coltype === 'NUM') ? {paddingBottom: '15px', width:'100%' } : {paddingBottom: '15px', width:'100%' }}
                    id={id}
                    {...inputComponent}
                    inputProps={{
                      onChange: event => this.change(event, true),
                      onBlur: event => this.handleBlur(event, true),
                      value: this.state.value,
                      id: this.inputid,
                    }}
                  />
                {
                  (this.props.colDef.coltype === 'NUM') ?
                  <div style={{float:'right'}}>
                    <IconButton id={this.calcid}  onClick= {(event) => { this.toggleCalculator(event)}}  style= {{marginLeft: '-20px', marginTop: '-10px'}} aria-label="toggle calculator visibility">
                      <DialPad/>
                    </IconButton>
                    <Popper id='popinp'  style={{zIndex:'1500'}} open={this.state.calculatorOpen} anchorEl={this.state.anchorEl}>
                      <Calculator resultHandler={this.handleCalculatorResult} />
                    </Popper>

                  </div>
                  : null
                }

                </Grid>
              </Grid>
              {this.state.error ? (
                <Clear className={classes.feedback + " " + classes.labelRootError} />
              ) : null}

              {helperText !== undefined ? (
                <FormHelperText id={id + "-text"} className={helpTextClasses}>
                  {helperText}
                </FormHelperText>
              ) : null}
            </FormControl>
          </NativeListener>         
        );
      }
      else
      {
        return (<div></div>)
      }
    }
}

export default withStyles(styles)(GridEditString);