import React from 'react';
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import { Card, CardBody  } from 'reactstrap';
import Grid from '@material-ui/core/Grid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import SqlFunction from "../../functions/sql_functions";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { withStyles } from '@material-ui/core/styles';
import styles from "../../assets/views/customViewStyle.jsx";
import { JobDefListCodes } from '../../redux/settings/jobsActions.js';
import FormatList from '@material-ui/icons/FormatListBulleted'
import PdfBoxBrutax from '@material-ui/icons/PictureAsPdf';
import PdfBox from '@material-ui/icons/PictureAsPdfOutlined'
import PdfMail from '@material-ui/icons/MailOutlined'
import { GetAllFacturen } from '../../redux/settings/accountActions';

import Checkbox from '@material-ui/core/Checkbox';

var ereloontotaalexclbtw = 0;
var totaalbtw = 0;
var ereloontotaalinclbtw = 0;

const mapStateToProps = state => ({
    ...state
});

class OpenInvoicepage extends React.Component {

    state = {
        tableheight: Math.floor(window.innerHeight * .75) + 'px',
        speedopen: false,
        speedactions: [
            { icon: <FormatList />, name: 'Overview', tooltip: 'Facturenoverzicht' },
            { icon: <PdfBox />, name: 'InvoicePdf', tooltip: 'PDF zonder briefhoofd' },
            { icon: <PdfBoxBrutax />, name: 'InvoicePdfBrutax', tooltip: 'PDF met briefhoofd' },
            { icon: <PdfMail />, name: 'MailInvoicePdfBrutax', tooltip: 'Mail factuur' }

        ],
        invoicejoblist: [],
        extralinesdata: [],
        makedr: false,
        makeaccijnzen: false,
        makevatrefund: false,
        factuurnr: "",
        factuurdate: "",
        factuurenddate: "",
        factuurtype: [],
        btwpercentage: "",
        klant: "",
        taal: "",
        factuurklant: "",
        factuurklantemail: "",
        ereloontotaalexclbtw: 0,
        totaalbtw: 0,
        ereloontotaalinclbtw: 0,
        invoicePayed: false,
        invoicePrinted: false,
        invoiceMailed: false,
        invoiceData : "",
        invoiceId: 0,
        invoicedescription: "",
    }

    constructor(props, context) {
        super(props);
        this.resizeFunction = this.resizeFunction.bind(this);
        this.getContextMenuItems = this.getContextMenuItems.bind(this);
        this.handleSpeedClose = this.handleSpeedClose.bind(this);
        this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
        this.getDirreg = this.getDirreg.bind(this);
        this.getAccijnzen = this.getAccijnzen.bind(this);
        this.getVatrefund = this.getVatrefund.bind(this);
        this.getExtraLines = this.getExtraLines.bind(this);
        this.getInvoiceDetails = this.getInvoiceDetails.bind(this);
        this.actionClick = this.actionClick.bind(this);
        this.sendMail = this.sendMail.bind(this);
        this.valueCheck = this.valueCheck.bind(this);
        this.handleInvoicePayed = this.handleInvoicePayed.bind(this);
        this.handleInvoiceSend = this.handleInvoiceSend.bind(this);
        this.sortInvoiceJobs = this.sortInvoiceJobs.bind(this);
    }

    async componentDidMount() {
        window.addEventListener("resize", this.resizeFunction);

        let maindata = this.props.account.MainFactuurData.data;
        
        let makedr;
        let makeaccijnzen;
        let makevatrefund;

        if(maindata.type === "Extra jobs"){
            for (let i = 0; i < maindata.factuurlines.length; i++){
                if(maindata.factuurlines[i].omschrijving !== ""){
                    let row = {
                        "omschrijving": maindata.factuurlines[i].omschrijving,
                        "tarief": maindata.factuurlines[i].ereloon
                    }
                    this.state.extralinesdata.push(row);
                }
            }
            makedr = false;
            makeaccijnzen = false;
            makevatrefund = false;
        } else {
            this.state.invoicejoblist = [];
            for (let i = 0; i < maindata.factuurlines.length; i++) {
                if (maindata.factuurlines[i].omschrijving === "") {

                    let row = {
                        "tarief": Number(maindata.factuurlines[i].ereloon),
                        "vat_facttot": Number(maindata.factuurlines[i].ereloon),
                        "country_txt": maindata.factuurlines[i].land,
                        "periode": maindata.factuurlines[i].periode,
                        "regnummer": maindata.factuurlines[i].regnr,
                        "code": maindata.factuurlines[i].subtype,
                        "saldotebet": maindata.factuurlines[i].aangiftebtw,
                        "vat_basisfact": maindata.factuurlines[i].teruggave,
                        "vat_koers": maindata.factuurlines[i].koers,
                        "munt": maindata.factuurlines[i].munteenheid
                    }
                    this.state.invoicejoblist.push(row);
                } else {
                    let row = {
                        "omschrijving": maindata.factuurlines[i].omschrijving,
                        "tarief": maindata.factuurlines[i].ereloon
                    }
                    this.state.extralinesdata.push(row);
                }
            }

            switch (maindata.type) {
                case "Directe Registratie":
                    makedr = true;
                    makeaccijnzen = false;
                    makevatrefund = false;
                    break;

                case "Accijnzen":
                    makedr = false;
                    makeaccijnzen = true;
                    makevatrefund = false;
                    break;

                case "VAT-Refund":
                    makedr = false;
                    makeaccijnzen = false;
                    makevatrefund = true;
                    break;

                default:
                    return;
            }
        }

        let factdate = (maindata.factdate).split('-').reverse().join('-');
        let factenddate = (maindata.factenddate).split('-').reverse().join('-');
        let btwperc = maindata.btw;
        let klant = maindata.klant;
        let taal = (maindata.taalcode_txt).toLowerCase();
        let factklant = {
            name: maindata.factklant_name,
            adress: maindata.factklant_address,
            zipcode: maindata.factklant_zipcode,
            country: maindata.factklant_country,
            vatnumber: maindata.factklant_vatnumber,
            number: maindata.factklant_number
        };
        await this.props.dispatch(JobDefListCodes(taal));
        let facttype = (this.props.jobs && this.props.jobs.JobDefListCodesData && this.props.jobs.JobDefListCodesData.data) ? this.props.jobs.JobDefListCodesData.data : '';
        let payed = maindata.betaalstatus === "Betaald" ? true : false;
        let printed;
        let mailed;
        switch(maindata.zendwijze_status){
            case "Mail&Print":
                printed = true;
                mailed = true;
                break;

            case "Print":
                printed = true;
                mailed = false;
                break;

            case "Mail":
                printed = false;
                mailed = true;
                break;

            case "Te verzenden":
                printed = false;
                mailed = false;
                break;

            default:
                return;
        }
        this.setState({ factuurnr: maindata.factnr, factuurdate: factdate, factuurenddate: factenddate, btwpercentage: btwperc, klant: klant, taal: taal, factuurklant: factklant, 
            factuurtype: facttype, invoicePayed: payed, invoiceMailed: mailed, invoicePrinted: printed, invoiceData: maindata, makedr: makedr, makeaccijnzen: makeaccijnzen, 
            makevatrefund: makevatrefund, invoicedescription: maindata.vermelding })
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }

    async componentWillReceiveProps(nextProps, nextState) {

    }

    resizeFunction() {
        if (window.innerHeight >= 960) {
            this.setState({ tableheight: Math.floor(window.innerHeight * .75) + 'px' });
        }
        else {
            this.setState({ tableheight: '600px' });
        }
    }

    getContextMenuItems(params, sleutel) {
        return null;
    }

    handleSpeedClose() {
        this.setState({ speedopen: false });
    };

    handleSpeedOpen() {
        this.setState({ speedopen: true });
    };

    async actionClick(name) {
        switch (name) {
            case 'Overview':
                await this.props.dispatch(GetAllFacturen());
                this.props.history.push('/invoices');
                break;

            case 'InvoicePdf':
                document.getElementById('INVOICEPDF').submit();
                break;

            case 'InvoicePdfBrutax':
                document.getElementById('INVOICEPDFBRUTAX').submit();
                break;

            case 'MailInvoicePdfBrutax':
                await SqlFunction.DoSql('MailFactuur', this.props.account.MainFactuurData.data)
                break;

            default:
                return;
        }
        this.setState({ speedopen: false });
    }

    sortInvoiceJobs(jobs){
        let sortedArray = [];
        let countryFilter = "country_txt";

        for(let i = 0; i < jobs.length; i++){
            if(sortedArray.filter(obj => obj.code === this.state.factuurtype[jobs[i].code]['code']).length > 0){
                let codeIndex = sortedArray.map(function(obj) { return obj.code }).indexOf(this.state.factuurtype[jobs[i].code]['code']);

                if(sortedArray[codeIndex].countries.filter(country => country.countryName === jobs[i][countryFilter]).length > 0){
                    let countryIndex = sortedArray[codeIndex].countries.map(function(country) { return country.countryName }).indexOf(jobs[i][countryFilter]);
                    sortedArray[codeIndex].countries[countryIndex].jobs.push(jobs[i]);
                } else {
                    let jobsArray = [];
                    jobsArray.push(jobs[i])
                    let country = {
                        countryName: jobs[i][countryFilter],
                        jobs: jobsArray
                    };
                    sortedArray[codeIndex].countries.push(country);
                }
            } else {
                let jobsArray = [];
                jobsArray.push(jobs[i])
                let countriesArray = [];
                countriesArray.push({
                    countryName: jobs[i][countryFilter],
                    jobs: jobsArray
                })
                sortedArray.push({
                    code: this.state.factuurtype[jobs[i].code]['code'],
                    countries: countriesArray
                });
            }
        }

        let sortedInvoiceJobList = [];
        for(let i = 0; i < sortedArray.length; i++){
            for(let j = 0; j < sortedArray[i].countries.length; j++){
                let sortedOnPeriod = sortedArray[i].countries[j].jobs.sort(function(a, b) {
                    let aSplitted = a.periode.split("/");
                    let bSplitted = b.periode.split("/");
                    let aMonth = Number(aSplitted[0]);
                    let bMonth = Number(bSplitted[0]);
                    let aYear = Number(aSplitted[1]);
                    let bYear = Number(bSplitted[1]);
                    
                    if(aYear === bYear){
                        return aMonth - bMonth;
                    } else {
                        return aYear - bYear;
                    }
                })
                sortedInvoiceJobList = sortedInvoiceJobList.concat(sortedOnPeriod);
            }
        }

        return sortedInvoiceJobList;
    }

    getDirreg(){
        let dirreg = [];

        let regs = [];
        let regstype = [];
        let regstotaal = 0;

        ereloontotaalexclbtw = 0;

        let drjobs = this.sortInvoiceJobs(this.state.invoicejoblist);
        
        for(let i = 0; i < drjobs.length; i++){
            if(!regstype.includes(this.state.factuurtype[drjobs[i].code]['code'])){
                regstype[drjobs[i].code] = this.state.factuurtype[drjobs[i].code]['code'];
            }
        }

        for(let i = 0; i < Object.keys(regstype).length; i++){
            let type = [];
            let layout = [];
            let totaal = 0;
            for(let j = 0; j < drjobs.length; j++){
                if(Object.values(regstype)[i] === this.state.factuurtype[drjobs[j].code]['code']){
                    regstotaal += Number(drjobs[j].tarief);
                    totaal += Number(drjobs[j].tarief);
                    layout.push(
                        <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{drjobs[j].countryobj ? drjobs[j].countryobj[this.state.taal] : drjobs[j].country_txt}</label></div>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{drjobs[j].regnummer}</label></div>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{Object.keys(regstype)[i] !== "AANVR" ? drjobs[j].periode : ""}</label></div>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{Object.keys(regstype)[i] !== "AANVR" ? (new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((drjobs[j].saldotebet) * 100) / 100)).replace("€", "") : ""}</label></div>
                            <div style={{ width: "20%", textAlign: "center" }}><label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((drjobs[j].tarief) * 100) / 100)}</label></div>
                        </div>
                    );
                }
                
            }
            type['layout'] = layout;
            type['ereloon'] = totaal;
            type['code'] = Object.keys(regstype)[i];
            regs[Object.values(regstype)[i]] = type;
        }

        ereloontotaalexclbtw = regstotaal;
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;

        for(let i = 0; i < Object.keys(regs).length; i++){
            dirreg.push(
                <div name={"dr" + i} key={"dr" + i}>
                    <div name={"regs"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{Object.keys(regs)[i]}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((Object.values(regs)[i].ereloon) * 100) / 100)}</label>
                            </div>
                        </div>
                        <div name={"dirregs"} style={{ width: "90%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.land}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.regnr}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{Object.values(regs)[i].code !== "AANVR" ? this.props.land.FactuuropbouwData.data.periode : ""}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{Object.values(regs)[i].code !== "AANVR" ? this.props.land.FactuuropbouwData.data.aangiftebtw : ""}</label></div>
                                <div style={{ width: "20%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.ereloon}</label></div>
                            </div>
                            {Object.values(regs)[i].layout}
                        </div>
                    </div>
                </div>
            );
        }
        

        return dirreg;
    }

    getAccijnzen() {
        let accijnzen = [];

        return accijnzen;
    }

    getVatrefund() {
        let vatrefund = [];

        let vats = [];
        let vatstype = [];
        let vatstotaal = 0;

        ereloontotaalexclbtw = 0;

        let vatjobs = this.sortInvoiceJobs(this.state.invoicejoblist);
        
        for(let i = 0; i < vatjobs.length; i++){
            if(!vatstype.includes(this.state.factuurtype[vatjobs[i].code]['code'])){
                vatstype.push(this.state.factuurtype[vatjobs[i].code]['code']);
            }
        }

        for(let i = 0; i < vatstype.length; i++){
            let type = [];
            let layout = [];
            let totaal = 0;
            for(let j = 0; j < vatjobs.length; j++){
                if(vatstype[i] === this.state.factuurtype[vatjobs[j].code]['code']){
                    vatstotaal += Number(vatjobs[j].vat_facttot);
                    totaal += Number(vatjobs[j].vat_facttot);
                    let amountInEur = Number(vatjobs[j].vat_basisfact) * Number(vatjobs[j].vat_koers);
                    layout.push(
                        <div style={{ display: "flex", flexDirection: "row" }} key={"layout" + j}>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{vatjobs[j].countryobj ? vatjobs[j].countryobj[this.state.taal] : vatjobs[j].country_txt}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{vatjobs[j].periode}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{vatjobs[j].inEur ? Number(vatjobs[j].inEur).toLocaleString("es-ES", {minimumFractionDigits: 2}) : amountInEur.toLocaleString("es-ES", {minimumFractionDigits: 2})} {vatjobs[j].munt ? vatjobs[j].munt : vatjobs[j].countryobj.munt_txt}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{Number(vatjobs[j].vat_koers).toLocaleString("es-ES", {minimumFractionDigits: 2})}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{Number(vatjobs[j].vat_basisfact).toLocaleString("es-ES", {minimumFractionDigits: 2})}</label></div>
                            <div style={{ width: "16.5%", textAlign: "center" }}><label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((vatjobs[j].vat_facttot) * 100) / 100)}</label></div>
                        </div>
                    );
                }
                
            }
            type['layout'] = layout;
            type['ereloon'] = totaal;
            vats[vatstype[i]] = type;
        }

        ereloontotaalexclbtw = vatstotaal;
        // check if belgie
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;

//        this.setState({ereloontotaalexclbtw:ereloontotaalexclbtw, totaalbtw:totaalbtw, ereloontotaalinclbtw:ereloontotaalinclbtw })

        for(let i = 0; i < Object.keys(vats).length; i++){
            vatrefund.push(
                <div name={"vat" + i} key={"vat" + i}>
                    <div name={"vats"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{Object.keys(vats)[i]}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((Object.values(vats)[i].ereloon) * 100) / 100)}</label>
                            </div>
                        </div>
                        <div style={{ width: "80%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.land}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.periode}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.teruggave}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.koers}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{(this.props.land.FactuuropbouwData.data.bedrageuro).replace("euro", "€")}</label></div>
                                <div style={{ width: "16.5%", textAlign: "center" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.ereloon}</label></div>
                            </div>
                            {Object.values(vats)[i].layout}
                        </div>
                    </div>
                </div>
            );
        }

        return vatrefund;
    }

    getExtraLines() {
        let extralines = [];
        let extradata = [];
        let extratotaal = 0;
        for (let i = 0; i < this.state.extralinesdata.length; i++) {
            extratotaal += Number(this.state.extralinesdata[i].tarief);
            extradata.push(
                <div style={{ display: "flex", flexDirection: "row", marginTop: "-10px" }} key={"extraline_" + i}>
                    <div style={{ width: "82.5%" }}>
                        <label>{this.state.extralinesdata[i].omschrijving}</label>
                    </div>
                    <div style={{ width: "8%" }}></div>
                    <div style={{ width: "6.5%" }}>
                        <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round((this.state.extralinesdata[i].tarief) * 100) / 100)}</label>
                    </div>
                    <div style={{ width: "2%" }}></div>
                </div>
            );
        }

        if(!this.state.makedr && !this.state.makeaccijnzen && !this.state.makevatrefund){
            ereloontotaalexclbtw = 0;
        }
        ereloontotaalexclbtw += extratotaal;
        totaalbtw = ereloontotaalexclbtw / 100 * parseInt(this.state.btwpercentage);
        ereloontotaalinclbtw = ereloontotaalexclbtw + totaalbtw;
//        this.setState({ereloontotaalexclbtw:ereloontotaalexclbtw, totaalbtw:totaalbtw, ereloontotaalinclbtw:ereloontotaalinclbtw })
        extralines.push(
            <div name={"extralines"} key={"allextralines"}>
                {this.state.extralinesdata.length !== 0 &&
                    <div name={"extra"} style={{ marginTop: "10px" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                            <div style={{ width: "85%" }}>
                                <label>{this.props.land.FactuuropbouwData.data.extra}</label>
                            </div>
                            <div style={{ width: "15%", textAlign: "center" }}>
                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(extratotaal * 100) / 100)}</label>
                            </div>
                        </div>
                        <div style={{ width: "80%", margin: "auto", display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
                                <div style={{ width: "82.5%" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.description}</label></div>
                                <div style={{ width: "8%" }}></div>
                                <div style={{ width: "6.5%" }}><label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.ereloon}</label></div>
                                <div style={{ width: "2%" }}></div>
                            </div>
                            {extradata}
                        </div>
                    </div>
                }
            </div>
        );
        
        extralines.push(
            
        );

        return extralines;
    }

    getInvoiceDetails(){
        let details = [];

        let payed = this.props.account.MainFactuurData.data.betaalstatus;

        details.push(
            <div style={{ width: '100%', marginTop: "25px", display: "flex", flexDirection: "column" }} key={"invoiceDetails"}>
                <h4 style={{ marginTop: '25px' }} className="card-title m-t-10">{this.state.klant}</h4>
                <h6 style={{ marginTop: '15px', marginBottom: "15px" }} className="card-title m-t-10">Facturatiegegevens</h6>
                <small className="text-muted p-t-30 db">Factuurtype</small>
                <h6>{this.props.account.MainFactuurData.data.type}</h6>
                <small className="text-muted p-t-30 db">Taalcode</small>
                <h6>{this.props.account.MainFactuurData.data.taalcode_txt}</h6>
                <small className="text-muted p-t-30 db">Betalingsvoorwaarden</small>
                <h6>{this.props.account.MainFactuurData.data.factdetails_payment}</h6>
                <small className="text-muted p-t-30 db">Btw regime</small>
                <h6>{this.props.account.MainFactuurData.data.factdetails_vatreg}</h6>
                <small className="text-muted p-t-30 db">Zendwijze factuur</small>
                <h6>{this.props.account.MainFactuurData.data.factdetails_sendmethod}</h6>
                <small className="text-muted p-t-30 db">Facturatieklant</small>
                <h6>{this.props.account.MainFactuurData.data.factklant_name}</h6>
                <small className="text-muted p-t-30 db">Email facturatieklant</small>
                <h6 onClick={this.sendMail} style={{ cursor: "pointer", textDecoration: "underline" }}>{this.props.account.MainFactuurData.data.factdetails_mail}</h6>
                <div className='div_line' style={{ marginTop: '25px', marginBottom: '25px' }}></div>
                <h6 style={{ marginTop: '15px', marginBottom: "15px" }} className="card-title m-t-10">Betaalstatus</h6>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <h6 className="text-muted p-t-30 db" style={{ marginLeft: "10px" }}>Betaald</h6>
                        <Checkbox
                            name={"betaald"}
                            onClick={this.handleInvoicePayed}
                            checked={this.state.invoicePayed}
                            style={{ color: "#137cbd", marginTop: "-15px" }}
                        />
                    </div>
                </div>
                <h6 style={{ marginTop: '15px', marginBottom: "15px" }} className="card-title m-t-10">Zendwijze status</h6>
                {payed === "Niet betaald" && <div id={"zendwijzeCheck"} style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <h6 className="text-muted p-t-30 db">Mail</h6>
                        <Checkbox
                            name={"mail"}
                            onClick={this.handleInvoiceSend}
                            checked={this.state.invoiceMailed}
                            style={{ color: "#137cbd", marginTop: "-15px" }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Checkbox
                            name={"print"}
                            onClick={this.handleInvoiceSend}
                            checked={this.state.invoicePrinted}
                            style={{ color: "#137cbd", marginTop: "-15px" }}
                        />
                        <h6 className="text-muted p-t-30 db">Print</h6>
                    </div>
                </div>}
                {payed === "Betaald" && <div id={"zendwijzeCheck"} style={{ display: "flex", flexDirection: "row", justifyContent: "center", pointerEvents: "none", opacity: "0.5" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <h6 className="text-muted p-t-30 db">Mail</h6>
                        <Checkbox
                            name={"mail"}
                            onClick={this.handleInvoiceSend}
                            checked={this.state.invoiceMailed}
                            style={{ color: "#137cbd", marginTop: "-15px" }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <Checkbox
                            name={"print"}
                            onClick={this.handleInvoiceSend}
                            checked={this.state.invoicePrinted}
                            style={{ color: "#137cbd", marginTop: "-15px" }}
                        />
                        <h6 className="text-muted p-t-30 db">Print</h6>
                    </div>
                </div>}
                <div className='div_line' style={{ marginTop: '25px', marginBottom: '25px' }}></div>
            </div>
        );

        return details;
    }

    async handleInvoicePayed(event){
        let status;
        if(event.target.checked){
            status = "Betaald";
        } else {
            status = "Niet betaald";
        }
        let updInvoiceData = {...this.state.invoiceData}
        updInvoiceData.betaalstatus = status;
        this.setState({ invoiceData: updInvoiceData, invoicePayed: event.target.checked })
        await SqlFunction.DoSql('ChgFactuur', updInvoiceData)

        if(status === "Betaald"){
            let check = document.getElementById("zendwijzeCheck");
            check.style.pointerEvents = "none";
            check.style.opacity = "0.5";
        } else {
            let check = document.getElementById("zendwijzeCheck");
            check.style.pointerEvents = "auto";
            check.style.opacity = "1";
        }
    }

    handleInvoiceSend(event){
        let printed = this.state.invoicePrinted;
        let mailed = this.state.invoiceMailed;

        switch(event.target.name){
            case "print":
                printed = event.target.checked;
                break;
            
            case "mail":
                mailed = event.target.checked;
                break;

            default:
                return;
        }

        this.setState({ invoicePrinted: printed, invoiceMailed: mailed }, async function(){
            let status;
            if(this.state.invoicePrinted && this.state.invoiceMailed){
                status = "Mail&Print"
            } else if(this.state.invoicePrinted && !this.state.invoiceMailed){
                status = "Print"
            } else if(!this.state.invoicePrinted && this.state.invoiceMailed){
                status = "Mail";
            } else {
                status = "Te verzenden"
            }
            let updInvoiceData = { ...this.state.invoiceData }
            updInvoiceData.zendwijze_status = status;
            this.setState({invoiceData:updInvoiceData})
            await SqlFunction.DoSql('ChgFactuur', updInvoiceData)
        });

    }

    valueCheck(){
    }

    sendMail(){
        window.location = "mailto:" + this.state.factuurklantemail;
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Toolbar style={{ background: '#fff', padding: '14px 10px', boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px', width: 'calc(100% - 27px)', zIndex: '2', position: 'fixed' }}>
                    <Grid container>
                        <Grid item xs={8}>
                            <h4 tag="h4" className="mt-3">{this.props.settings.sub_title}</h4>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>
                    <SpeedDial
                        ariaLabel="SpeedDial example"
                        icon={<SpeedDialIcon style={{ zIndex: '10' }} />}
                        direction="left"
                        classes={{ root: classes.speedDialToolb, fab: classes.speedFabRoot }}
                        onClose={this.handleSpeedClose}
                        onOpen={this.handleSpeedOpen}
                        open={this.state.speedopen}
                    >
                        {this.state.speedactions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipPlacement='bottom'
                                FabProps={{}}
                                onClick={() => { this.actionClick(action.name) }}
                                tooltipTitle={action.tooltip}
                            />
                        ))}
                    </SpeedDial>
                </Toolbar>
                <section className="container" style={{ width: '100%', maxWidth: '100%' }}>
                    <Grid container style={{ width: 'auto', padding: '54px 0px', margin: '-15px -41px 35px' }}>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <Card className='card_brut'>
                                    <PerfectScrollbar className="sidebar-nav">
                                        <CardBody style={{ textAlign: 'center', height: Math.floor(window.innerHeight * .85) + 'px' }}>
                                            {this.getInvoiceDetails()}
                                        </CardBody>
                                    </PerfectScrollbar>
                                </Card>
                            </Grid>
                            <Grid item xs={9}>
                                <Card className='card_brut'>
                                    <CardBody style={{ minHeight: Math.floor(window.innerHeight * .85) + 'px', overflow: "auto" }}>
                                        <div>
                                            <div id={"invoicepdfdiv"} style={{ padding: "20px" }}>
                                                <div style={{ marginTop: "25px" }}>
                                                    <div name={"Factuur_top"}>
                                                        <div name={"Klantgeg"} style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                            <div style={{ width: "70%" }}></div>
                                                            <div style={{ widht: "30%", display: "flex", flexDirection: "column" }}>
                                                                <label>{this.state.factuurklant.name}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.adress}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.zipcode}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.country}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.vatnumber}</label>
                                                            </div>
                                                        </div>
                                                        <label style={{ fontSize: "11px", fontStyle: "italic", marginBottom: "-5px" }}>{"*" + this.props.land.FactuuropbouwData.data.vermelding}</label>
                                                        <div name={"Factuurdatums"} style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "10px" }}>
                                                            <div style={{ width: "15%", display: "flex", flexDirection: "column" }}>
                                                                <label>{this.props.land.FactuuropbouwData.data.factnr + "*"}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.klantnr + "*"}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.factdate}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.factenddate}</label>
                                                            </div>
                                                            <div style={{ width: "85%", display: "flex", flexDirection: "column" }}>
                                                                <label>{this.state.factuurnr}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurklant.number}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurdate}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.state.factuurenddate}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div name={"Factuur_center"}>
                                                        <div className='div_line' style={{ marginBottom: "5px" }} />
                                                        <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                                            <div style={{ width: "85%" }}>
                                                                <label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.description}</label>
                                                            </div>
                                                            <div style={{ width: "15%", textAlign: "center" }}>
                                                                <label style={{ fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.totaalexcl}</label>
                                                            </div>
                                                        </div>
                                                        <div className='div_line' style={{ marginBottom: "10px" }} />
                                                        {this.state.klant !== this.state.factuurklant.name &&
                                                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                                <label>{this.props.land.FactuuropbouwData.data.prestaties}</label>
                                                                <div style={{ marginTop: "-10px" }}>
                                                                    <label>{this.props.land.FactuuropbouwData.data.klant}</label>
                                                                    <label style={{ marginLeft: "25px" }}>{this.state.klant}</label>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div name={"Factuur_bottom"} style={{ marginTop: "15px" }}>
                                                        {this.state.makedr &&
                                                            this.getDirreg()
                                                        }
                                                        {this.state.makeaccijnzen &&
                                                            this.getAccijnzen()
                                                        }
                                                        {this.state.makevatrefund &&
                                                            this.getVatrefund()
                                                        }
                                                        {this.state.extralinesdata.length !== 0 &&
                                                            this.getExtraLines()
                                                        }
                                                        <div className='div_line' style={{ marginBottom: "5px", marginTop: "25px" }} />
                                                        <div name={"KostenTotaal"} style={{ display: "flex", flexDirection: "row", width: "100%", marginTop: "25px" }}>
                                                            <div style={{ width: "65%" }} />
                                                            <div style={{ display: "flex", flexDirection: "column", width: "20%" }}>
                                                                <label>{this.props.land.FactuuropbouwData.data.totaalereloon}</label>
                                                                <label style={{ marginTop: "-10px" }}>{this.props.land.FactuuropbouwData.data.btw + " (" + this.state.btwpercentage + "%)"}</label>
                                                                <label style={{ marginTop: "10px", fontWeight: "bold" }}>{this.props.land.FactuuropbouwData.data.betalen}</label>
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "column", width: "15%", textAlign: "center" }}>
                                                                <label>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(ereloontotaalexclbtw * 100) / 100)}</label>
                                                                <label style={{ marginTop: "-10px" }}>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(totaalbtw * 100) / 100)}</label>
                                                                <label style={{ marginTop: "10px", fontWeight: "bold" }}>{new Intl.NumberFormat('de-DE', { style: "currency", currency: "EUR" }).format(Math.round(ereloontotaalinclbtw * 100) / 100)}</label>
                                                            </div>
                                                        </div>
                                                        <div className='div_line' style={{ marginBottom: "5px", marginTop: "25px" }} />
                                                        <div name={"Vermelding"} style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                                                            {this.state.invoicedescription && <label>{this.state.invoicedescription}</label>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <form id='INVOICEPDF' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{ width: '100%' }} >
                                                <input style={{ display: 'none' }} type="text" defaultValue='CreateInvoicePdf' id="function" name="function" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={this.props.account.MainFactuurData.data.id} id="id" name="id" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid" />
                                            </form>
                                            <form id='INVOICEPDFBRUTAX' action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{ width: '100%' }} >
                                                <input style={{ display: 'none' }} type="text" defaultValue='CreateInvoicePdfBrutax' id="function" name="function" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={this.props.account.MainFactuurData.data.id} id="id" name="id" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid" />
                                                <input style={{ display: 'none' }} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid" />
                                            </form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </section>
            </div>
        );
    }
}
export default connect(mapStateToProps)(withStyles(styles)(OpenInvoicepage));