import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux'
import Toolbar from '@material-ui/core/Toolbar';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import styles from "../../assets/views/customViewStyle.jsx";
import EditString from "../../components/editstring.jsx";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import MdiNewBox from '@material-ui/icons/MdiNewBox';
import PdfBox from '@material-ui/icons/Print';
import { GetTemplate,CreateTemplate,AllTemplates } from "../../redux/settings/templateActions";
import { setPanelRight, setTitleRight, setSubTitle } from "../../redux/settings/action";
import Validation from "../../functions/validation.js";
import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions.js";
import { Editor, getTinymce } from '@tinymce/tinymce-react';



const mapStateToProps = state => ({
    ...state
});

var tinymce = null;
var titleid = null;
var editorid = null;

// Create Document Component

class Templatepage extends React.Component {

    state = {
        editorState: '',
        edit: this.props.template && this.props.template.MainData && this.props.template.MainData.data && this.props.template.MainData.data.id === 0 ? true : false,
        save: false,
        checkval: false,
        data: this.props.template && this.props.template.MainData && this.props.template.MainData.data && this.props.template.MainData.data.id === 0 ? this.props.template.MainData.data : null,
        speedactions: [
            { icon: <EditIcon />, name: 'Edit', tooltip: 'Aanpassen gegevens' },
            { icon: <MdiNewBox />, name: 'New', tooltip: 'Nieuwe template' },
            { icon: <SaveIcon />, name: 'Save', tooltip: 'Bewaren gegevens' },
            { icon: <PdfBox />, name: 'Pdf', tooltip: 'Maak PDF' },
        ],
        speedopen: false,
    };

    constructor(props, context) {
        titleid = GenFunc.MakeId()
        editorid = GenFunc.MakeId()

        super(props);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.handleSpeedClose = this.handleSpeedClose.bind(this);
        this.handleSpeedOpen = this.handleSpeedOpen.bind(this);
        this.ZetEdit = this.ZetEdit.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
        this.MainSave = this.MainSave.bind(this);
        this.CreatePdf = this.CreatePdf.bind(this);
        this.checkconfirm = this.checkconfirm.bind(this);
        this.componentWillReceiveProps = this.componentWillReceiveProps.bind(this);
        this.handleJa = this.handleJa.bind(this);
        this.ActionClick = this.ActionClick.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.setmenuitems = this.setmenuitems.bind(this);

    }

    onEditorStateChange(content, editor) {
      let data = this.state.data
      data.temp_text = content 
      this.setState({editorState:content, save:true});
    }


    async setmenuitems() {
        var items = [];
        var item
        let retdata = await SqlFunction.DoSql('AllTemplFields', {});

        if (retdata && retdata.data) 
        {
          var i = 0;
          var len = retdata.data.length;
          for (; i < len; i++) 
          {
            item = retdata.data[i];
            item.type = 'menuitem';
            item.text = item.description;
            item.value = item.code;
            let itemCopy = Object.assign({}, item);                
            items.push(itemCopy);
          }
        }
        return items;
    }

    async componentDidMount() {
        this.props.dispatch(setTitleRight('Templates'))
        this.props.dispatch(setSubTitle('Template'))
        this.props.dispatch(setPanelRight(true))
        let retdata = await this.setmenuitems();
        this.setState({data:this.props.template.MainData.data,editorState:this.props.template.MainData.data.temp_text, velden:retdata})
    }

    componentWillUnmount() {
        this.checkconfirm(this.props.template.MainData);
        this.props.dispatch(setPanelRight(false))
        this.props.dispatch(setTitleRight(''))
    }

    async ValueCheck(that, waarde, type, colId) {
        let data = this.state.data;
        let terug = false;
        switch (colId) {
            case 'description':
                data[colId] = waarde;
                terug = Validation.ValidCheck('AllTemplates', data.description, 'description', this, this.props.template.AllData.data, data);
                break;
            default:
                data[colId] = waarde;
                terug = Validation.ValidCheck(this.props.settings.sub_title, waarde, colId, that, this.props.template.AllData.data, data);
                break;
        }
        that.setState({ error: terug })        
        this.setState({ save: !terug, data: data, checkval: false })
    } 

    handleSpeedClose() {
        this.setState({ speedopen: false });
    };

    handleSpeedOpen() {
        this.setState({ speedopen: true });
    };

    async ZetEdit() {
        this.setState({ edit: true, data: this.props.template.MainData.data,editorState:this.props.template.MainData.data.temp_text })
    }

    CheckSave(data) {
        let terug = true
        terug = Validation.ValidCheck('AllTemplates', data.description, 'description', this, this.props.template.AllData.data, data);
        return !terug;
    }


    async MainSave() {
        if (this.state.save === true) 
        {
            let retdata = await SqlFunction.DoSql('ChgTemplate', this.state.data);
            await this.props.dispatch(AllTemplates())
            if (retdata) {await this.props.dispatch(GetTemplate(retdata.id))}
            this.setState({ edit: false, save: false, data: this.props.template.MainData.data, checkval: false })

        }
    }

    async CreatePdf() {
        document.getElementById('TEMPPDFUPL001').submit();
    }



    checkconfirm(parameters) {
        if (this.state.save === true) {
            confirmAlert({
                closeOnEscape: false,
                closeOnClickOutside: false,
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Aanpassingen niet doorgevoerd.</h1>
                            <p>Wens je de aanpassingen alsnog te bewaren?</p>
                            <button
                                onClick={() => {
                                    this.handleJa(parameters);
                                    onClose();
                                }}
                            >
                                Ja
                            </button>
                            <button onClick={() => {
                                onClose();
                            }}
                            >
                                Nee
                            </button>
                        </div>
                    );
                }
            });
        }

    }

    async componentWillReceiveProps(nextProps, nextState) {
        if (this.props.template.MainData) {
            if (this.props.template.MainData.data.id !== nextProps.template.MainData.data.id) {
                await this.checkconfirm(this.props.template.MainData);
                if (nextProps.template.MainData.data.id === 0) {
                    this.setState({ edit: true, save: false, data:this.props.template.MainData.data })
                }
                else {
                    this.setState({ edit: false, save: false, data:this.props.template.MainData.data})
                }
            }
        }
    }

    async handleJa(parameters) {
        if (this.state.save === true) {
            await SqlFunction.DoSql('ChgTemplate', this.state.data);
        }
    }
    async PrintTiny() {
        await document.getElementById(titleid).focus() 
        await document.getElementById(titleid).click() 

//        await tinymce.selection.select(tinymce.dom.select('div')[0]);
//        await tinymce.selection.setCursorLocation();
        tinymce.execCommand('mcePrint');

    }
    async ActionClick(name) {
        switch (name) {
            case 'Edit':
                this.setState({ edit: true, data: this.props.template.MainData.data, speedopen: false })
                break;

            case 'New':
                await this.props.dispatch(CreateTemplate())
                this.setState({ edit: true, data: this.props.template.MainData.data, speedopen: false })
                break;

            case 'Save':
                this.MainSave();
                break;

            case 'Pdf':
                if (tinymce)
                {
                    tinymce.execCommand('mcePrint');
                }
//                this.CreatePdf();

                break;

            default:
                break;
        }
        this.setState({ speedopen: false });
    }


render() {
    const { classes } = this.props;
    let tableheight = Math.floor(window.innerHeight * .68)
    let that = this;
    let vld_document = (<form id='TEMPPDFUPL001' action="https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='CreatePdf' id="function" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.state.data && this.state.data.id} id="id" name="id"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secid" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecid" name="clsecid"/>
        </form>);  
    return (
    <div >
        <Toolbar style={{background:'#fff', padding: '14px 10px',boxShadow: '0 1px 4px 0 rgba(0,0,0,.1)', margin: '-27px -27px 25px'}}>
          <Grid container>
            <Grid item xs={8}>          
              <h4 id={titleid} style={{cursor:'pointer'}} tag="h4" className="mt-3" >Template pagina</h4>  
            </Grid>
            <Grid item xs={4}>  
            </Grid>
          </Grid>
          <SpeedDial
            ariaLabel="SpeedDial example"
            icon={<SpeedDialIcon style={{zIndex:'10'}}/>}
            direction="left"
            classes={{root: classes.speedDial, fab: classes.speedFabRoot}}           
            onClose={this.handleSpeedClose}
            onOpen={this.handleSpeedOpen}
            open={this.state.speedopen}          
          >
            {this.state.speedactions.map(action => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                FabProps={{disabled: (action.name === 'Edit' && this.state.edit === false && GenFunc.allowed(this.props.user.userData.secrows,'AllTemplates','edit')) ? false :
                                     (action.name === 'Edit' && (this.state.edit === true || this.state.editfact === true) ) ? true :
                                     (action.name === 'Edit' && !GenFunc.allowed(this.props.user.userData.secrows,'AllTemplates','edit')) ? true :
                                     (action.name === 'New' && this.state.edit === false && this.state.editfact === false && GenFunc.allowed(this.props.user.userData.secrows,'AllTemplates','edit')) ? false :
                                     (action.name === 'New' && (this.state.edit === true || this.state.editfact === true)) ? true :
                                     (action.name === 'New' && !GenFunc.allowed(this.props.user.userData.secrows,'AllTemplates','edit')) ? true :
                                     (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === true) ? false :
                                     (action.name === 'Save' && (this.state.edit === true || this.state.editfact === true) && this.state.save === false) ? true :
                                     (action.name === 'Save' && this.state.edit === false && this.state.editfact === false) ? true : 
                                     (action.name === 'Save' && this.state.save === false) ? true : 
                                     (action.name === 'Pdf' && tinymce === null) ? true : false}}

                tooltipPlacement='bottom'
                onClick={() => {this.ActionClick(action.name)}}                             
                tooltipTitle={action.tooltip}
              />
            ))}
          </SpeedDial>          

        </Toolbar>   
        {vld_document}     
        <Grid container style={{width: 'auto', padding: '14px 10px', margin: '-15px -27px 35px'}}>
            <Grid item xs={12} style={{background: 'white', overflow: 'auto',paddingLeft: '10px',paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px'}}>
                {this.state.edit === false &&
                    <h4 style={{marginTop: '25px',cursor: 'pointer'}} onClick={() => {this.ZetEdit()}} className="card-title m-t-10">{this.props.template.MainData.data.description} </h4>
                }
                {this.state.edit === true &&
                  <EditString className="card-title m-t-10"
                    value={this.state.data.description}
                    coltype = 'STR'                        
                    labelText="Omschrijving"
                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'description')}
                  />
                }
            </Grid>
        </Grid>
        <Grid container style={{width: 'auto', padding: '14px 10px', margin: '-50px -27px 35px'}}>

            <Grid item xs={12} style={{background: 'white',height: '73vh', overflow: 'auto',paddingLeft: '10px',paddingRight: '10px', paddingTop: '10px', paddingBottom: '10px'}}>
                {this.state.edit === false &&
                    <div style={{width:'100%', textAlign:'left', overflowY:'auto', display:'inline-block',height:'73vh',cursor:'pointer'}} 
                        dangerouslySetInnerHTML={{ __html: this.props.template.MainData.data.temp_text}}  onClick={() => {this.ZetEdit()}}>
                    </div>
                }
                {this.state.edit === true && this.state.velden &&
                    <Editor
                      id={editorid}   
                      onEditorChange={this.onEditorStateChange}      
                      init={{
                        editor_deselector : "mceNoEditor",
                        height:tableheight,
                        language: 'nl',
                        plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime table paste code'
                      ],
                      toolbar: 'undo redo | formatselect | ' +
                        'bold italic backcolor | alignleft aligncenter ' +
                        'alignright alignjustify | bullist numlist outdent indent |  image | removeformat | customInsertButton | myvelden',
                      init_instance_callback: function (editor) {
                        editor.on('BeforeExecCommand', function (e) {
                          if (e.command === 'mcePrint') {
                            document.getElementById(titleid).focus() 
                            editor.selection.select(editor.dom.select('div')[0]);
                            editor.selection.setCursorLocation();

                          }
                        });
                      },                        
  //<div style="position: absolute; left: -10000px, top: -10000px; width: 0px; height: 0px; overflow: hidden;"></div>
                      images_upload_handler: function (blobInfo, success, failure) {
                        var fileName = '';
                        if( typeof(blobInfo.blob().name) !== undefined )
                        {
                            fileName = blobInfo.blob().name;
                        }
                        else
                        {   fileName = blobInfo.filename();}
                        if (fileName.length > 2)
                        {
                            const formData = new FormData();
                            formData.append('file', blobInfo.blob(), fileName);
                            SqlFunction.DoSqlBody('saveFileTemp', formData);
                            let datalink = "../server/files/templates/"+fileName
                            success(datalink)
                        }
                      },
                      setup: function (editor) {
                        tinymce = editor
                        editor.ui.registry.addAutocompleter('specialchars', {
                            ch: '{',
                            minChars: 1,
                            columns: 1,
                            fetch: function (pattern) {

                              var matchedChars = that.state.velden.filter(function (char) {
                                return char.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
                              });

                              tinymce = getTinymce()                                

                              return new tinymce.util.Promise(function (resolve) {
                                var results = matchedChars.map(function (char) {
                                  return {
                                    value: char.value,
                                    text: char.text,
                                  }
                                });
                                resolve(results);
                              });
                            },
                            onAction: function (autocompleteApi, rng, value) {
                              editor.selection.setRng(rng);
                              editor.insertContent(value);
                              autocompleteApi.hide();
                            }
                        });

                        editor.ui.registry.addMenuButton('myvelden', {
                          text: 'Velden',
                          fetch: function (callback) {
                              var items = [];
                              var item
                              var i = 0;
                              var len = that.state.velden.length;
                              for (; i < len; i++) 
                              {
                                item = that.state.velden[i];
                                item.type = 'menuitem';
                                let code = item.code
                                item.onAction =  function () {
                                  editor.insertContent(code);
                                }               
                                let itemCopy = Object.assign({}, item);                
                                items.push(itemCopy);
                              };
                            callback(items);
                          }
                        });
                      },
                      }}
                      value={this.state.editorState}
                    />                
                }
            </Grid>
        </Grid>
      </div>
    );
  }

}
export default connect(mapStateToProps)(withStyles(styles)(Templatepage));
