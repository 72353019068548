
import {ALLSECPARAM_SUCCESS,ALLMUNTEN_SUCCESS, ALLPOSTCODES_SUCCESS,ALLREGOMS_SUCCESS,ALLPERIODES_SUCCESS, ALLFACTZENDWIJZEN_SUCCESS, ALLBETALINGSVOORWAARDEN_SUCCESS, 
    ALLBTWREGIMES_SUCCESS, ALLBTWPERCENTAGES_SUCCESS, ALLSTARTDATUMS_SUCCESS, ALLAANSPREKINGEN_SUCCESS,ALLREGTYPE_SUCCESS,
    ALLFILTLANDEN_SUCCESS,
    ALLJOBS_SUCCESS,ALLACCTYPE_SUCCESS, ALLFUNCTIES_SUCCESS,ALLREKENINGEN_SUCCESS,ALLSUBPRODUCTCODES_SUCCESS,ALLMAINPRODUCTCODES_SUCCESS,
    ALLMAINVATREFCODES_SUCCESS,ALLSUBVATREFCODES_SUCCESS,GETMAINIVAT_SUCCESS} from '../constants/';

const INIT_STATE = {
    AllSecParData:null,
    AllMuntenData:null,
    ALLFiltLandenData:null,
    AllFactZendwijzenData:null,  
    AllPostcodesData:null,   
    AllRekeningenData:null,
    AllBetalingsvoorwaardenData:null, 
    AllBtwRegimesData:null,
    AllRegOmsData:null,
    AllPeriodesData:null,
    AllStartdatumsData: null,
    AllBtwPercentagesData:null,
    AllAansprekingenData:null,
    AllRegTypeData:null,
    AllJobsData:null,
    AllAccTypeData:null,
    AllFunctiesData: null,
    AllMainProductCodesData: null,
    AllSubProductCodesData: null,
    AllMainVatRefCodesData: null,
    AllSubVatRefCodesData: null,
    MainIvatData:null,

}

export default (state = INIT_STATE, action) => {
    switch (action.type) {


        case GETMAINIVAT_SUCCESS:
            return {
                ...state,
                MainIvatData: action.payload
            };


        case ALLSECPARAM_SUCCESS:
            return {
                ...state,
                AllSecParData: action.payload
            };

        case ALLREKENINGEN_SUCCESS:
            return {
                ...state,
                AllRekeningenData: action.payload
            };

        case ALLMAINVATREFCODES_SUCCESS:
            return {
                ...state,
                AllMainVatRefCodesData: action.payload
            };

        case ALLSUBVATREFCODES_SUCCESS:
            return {
                ...state,
                AllSubVatRefCodesData: action.payload
            };


        case ALLMAINPRODUCTCODES_SUCCESS:
            return {
                ...state,
                AllMainProductCodesData: action.payload
            };

        case ALLSUBPRODUCTCODES_SUCCESS:
            return {
                ...state,
                AllSubProductCodesData: action.payload
            };

        case ALLREGTYPE_SUCCESS:
            return {
                ...state,
                AllRegTypeData: action.payload
            };

        case ALLMUNTEN_SUCCESS:
            return {
                ...state,
                AllMuntenData: action.payload
            };

        case ALLFILTLANDEN_SUCCESS:
            return {
                ...state,
                ALLFiltLandenData: action.payload
            };

        case ALLACCTYPE_SUCCESS:
            return {
                ...state,
                AllAccTypeData: action.payload
            };

        case ALLREGOMS_SUCCESS:
            return {
                ...state,
                AllRegOmsData: action.payload
            };

        case ALLPERIODES_SUCCESS:
            return {
                ...state,
                AllPeriodesData: action.payload
            };

        case ALLJOBS_SUCCESS:
            return {
                ...state,
                AllJobsData: action.payload
            };

        case ALLSTARTDATUMS_SUCCESS:
            return {
                ...state,
                AllStartdatumsData: action.payload
            }

        case ALLBTWPERCENTAGES_SUCCESS:
            return {
                ...state,
                AllBtwPercentagesData: action.payload
            };
            
        case ALLFACTZENDWIJZEN_SUCCESS:
            return {
                ...state,
                AllFactZendwijzenData: action.payload
            };

        case ALLBTWREGIMES_SUCCESS:
            return {
                ...state,
                AllBtwRegimesData: action.payload
            };

        case ALLPOSTCODES_SUCCESS:
            return {
                ...state,
                AllPostcodesData: action.payload
            };

        case ALLBETALINGSVOORWAARDEN_SUCCESS:
            return {
                ...state,
                AllBetalingsvoorwaardenData: action.payload
            }

        case ALLAANSPREKINGEN_SUCCESS:
            return {
                ...state,
                AllAansprekingenData: action.payload
            }

        case ALLFUNCTIES_SUCCESS:
            return {
                ...state,
                AllFunctiesData: action.payload
            }

        default:
            return state;


    }
};