import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Validation from "../../functions/validation.js";
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import {clickview } from "../../redux/settings/action";

import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";

import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditDateTime from "../../components/editdatetime.jsx";

import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";

import TableGrid from "../../components/TableGrid/TableGrid.jsx";
import PopAddSupplier from "../suppliers/PopAddSupplier.jsx";


var gridapi = null;
var rowpinnend = null;
var kolid = 1;
var bewaren = false;

class PopJobEditSubOnkosten extends React.Component {
    state = {
        data: this.props.data,
        kol: this.props.kol,
        filename:'',
        save:false,
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
        nivo: (this.props.nivo) ? this.props.nivo : 0,
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.CheckSave = this.CheckSave.bind(this);
        this.CellClick = this.CellClick.bind(this);
        this.CellCheck = this.CellCheck.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);

        this.CheckSaveOnkost = this.CheckSaveOnkost.bind(this);
        this.ClosePopUp = this.ClosePopUp.bind(this);
        this.onGridReady = this.onGridReady.bind(this);
        this.addRecord = this.addRecord.bind(this);
        this.ErrorCheck = this.ErrorCheck.bind(this);
        this.getFilter = this.getFilter.bind(this);

        this.checkconfirm = this.checkconfirm.bind(this);
        this.handleSubSave = this.handleSubSave.bind(this);
    }

    async ClosePopUp() 
    {
      this.setState({PopUpScreen:null})
      this.props.dispatch(clickview({ id: this.props.jobid, vorm: 'JOB' }));
    }

    onGridReady(params)
    {
      gridapi = params;
      let rij = [{id:0,onk_id:0,type_onkost:'',mvh:'',btw:'',totaal:'',type_onkost_txt:'',save:'',verwijder:'',toegperc:100}]        
      gridapi.api.updateRowData({ add: rij})
        if (this.props.data.id === 0) {return}
        var lastrow = gridapi.api.getLastDisplayedRow();
                    gridapi.api.setFocusedCell(lastrow, 'type_onkost_txt',);  
                    gridapi.api.startEditingCell({
                        rowIndex: lastrow,
                        colKey: 'type_onkost_txt',

                    });    



    }
    addRecord(params, waarde) 
    {
      this.setState({PopUpScreen:(<PopAddSupplier open={true} waarde={waarde} toevobject={params} hoofdvorm={this} close={this.ClosePopUp} title='Toevoegen leverancier' />)});
    }

    getFilter(params) 
    {
      var colId = params.props.column.getId();
      var filtar = []
      if (colId === 'type_onkost_txt')
      {
        filtar.push({field:'land',value:this.props.country})
        params.setFilter(filtar);
      }
    }


    async ErrorCheck(that, waarde, type, colId) {
        let terug = false;
        let bedrag = 0;
        switch (colId) 
        {
            case 'btw':
            case 'mvh':
            case 'totaal':
                if (waarde.constructor === String)
                {
                    bedrag = parseFloat(waarde);           
                    if (isNaN(bedrag)) {bedrag = 0}
                }
                else
                {
                    bedrag = waarde;           
                }
                break

            default:    
                terug = Validation.ValidCheck('JobOnkost', waarde, colId, that,null, this.state.data, this.props.listdata);

                break;
        }
        that.setState({ error: terug})
        this.setState({save : terug})
    }      

    async CheckSave() {
      let terug = false
      let bedrag = 0;
      let waarde = '';

      terug = Validation.ValidCheck('JobOnkost', this.state.data.factnum, 'factnum', null,null, this.state.data, this.props.listdata);
      if (terug === false)
      {
        let faktdata = await SqlFunction.DoSql('GetDubbelFactuur',this.state.data);
        if (faktdata && faktdata.data && faktdata.data.length > 0)
        {
            terug = true            
        }
      }
      if (terug === false)
      {
        // kijken naar andere jobs of factuur is ingegeven

        terug = Validation.ValidCheck('JobOnkost', this.state.data.factdat, 'factdat', null,null, this.state.data, this.props.listdata);
      }
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobOnkost', this.state.data.supplier_txt, 'supplier_txt', null,null, this.state.data, this.props.listdata);
      }

      if (terug === false)
      {
        bedrag = 0;
        waarde = this.state.data.btw;
        if (waarde.constructor === String)
        {
            bedrag = parseFloat(waarde);           
            if (isNaN(bedrag)) {bedrag = 0}
        }
        else
        {
            bedrag = waarde;           
        }

      }
      if (terug === false)
      {
        bedrag = 0;
        waarde = this.state.data.mvh;
        if (waarde.constructor === String)
        {
            bedrag = parseFloat(waarde);           
            if (isNaN(bedrag)) {bedrag = 0}
        }
        else
        {
            bedrag = waarde;           
        }

      }
      if (terug === false)
      {
        bedrag = 0;
        waarde = this.state.data.totaal;
        if (waarde.constructor === String)
        {
            bedrag = parseFloat(waarde);           
            if (isNaN(bedrag)) {bedrag = 0}
        }
        else
        {
            bedrag = waarde;           
        }

      }
      this.setState({save:terug})
    }


    async ValueCheck(that,waarde,type,colId,verplicht,overzdata) 
    {
        let data = this.state.data;
        let terug = false;
        let bedrag = 0;


        switch (colId) 
        {
            case 'supplier_txt':
                if (waarde.id === 0)
                {
                    this.setState({PopUpScreen:(<PopAddSupplier open={true} waarde={waarde.value} toevobject={that} close={this.ClosePopUp} title='Toevoegen leverancier' />)});
                }
                data.supplier_txt = waarde.value;
                data.supplier_id = waarde.id;
                if (overzdata)
                {
                    data.sup_vatnumber = overzdata.vatnumber
                    data.sup_adres = overzdata.adress
                    data.sup_zipcode = overzdata.zipcode_txt
                    data.sup_country_txt = overzdata.country_txt
                    data.munt_eenheid = overzdata.munt_eenheid
                }
                else
                {
                    data.sup_vatnumber = ''
                    data.sup_adres = ''
                    data.sup_zipcode = ''
                    data.sup_country_txt = ''
                    data.munt_eenheid = ''
                }
                terug = Validation.ValidCheck('JobOnkost', waarde.value, colId, that, null, data, this.props.listdata);                
                break;


            case 'btw':
            case 'mvh':
            case 'totaal':
                if (waarde.constructor === String)
                {
                    bedrag = parseFloat(waarde);           
                    if (isNaN(bedrag)) {bedrag = 0}
                }
                else
                {
                    bedrag = waarde;           
                }
                data[colId] = bedrag;
                let btw = data.btw;
                if (btw.constructor === String)
                {
                    btw = parseFloat(btw);           
                    if (isNaN(btw)) {btw = 0}
                }
                let mvh = data.mvh;

                if (mvh.constructor === String)
                {
                    mvh = parseFloat(mvh);           
                    if (isNaN(mvh)) {mvh = 0}
                }
                if (colId === 'totaal')
                {
                    if (type === 'ENTER')
                    {
                        var lastrow = gridapi.api.getLastDisplayedRow();
                            gridapi.api.setFocusedCell(lastrow, 'type_onkost_txt',);  
                            gridapi.api.startEditingCell({
                                rowIndex: lastrow,
                                colKey: 'type_onkost_txt',
                        });    

                    }                    
                }
                else
                {
                    data.totaal = mvh + btw ;
                }

                break



            case 'factnum':
                data[colId] = waarde;
                terug = Validation.ValidCheck('JobOnkost', waarde, colId, that,null, data, this.props.listdata);
                if (terug === false)
                {
                  let faktdata = await SqlFunction.DoSql('GetDubbelFactuur',this.state.data);
                  if (faktdata && faktdata.data && faktdata.data.length > 0)
                  {
                    terug = true            
                  }
                }
                break



            default:    
                data[colId] = waarde;
                terug = Validation.ValidCheck('JobOnkost', waarde, colId, that,null, data, this.props.listdata);
                break;
        }
        that.setState({ error: terug })
        if (terug === true)
        {

        }
        this.CheckSave();
    }       
    async CellClick(waarde) {
        if (this.state.save === true) {return}

        var data = waarde.data
        let column = waarde.column.colDef.field;
        if (column === 'save')
        {
            if (data.save === true) 
            {
                this.handleSubSave();
                bewaren = true
            }
        }

        if (column === 'verwijder')
        {
            gridapi.api.updateRowData({ remove: [waarde.data]})
            let subdata = waarde.data
            subdata.job_id = this.props.jobid;
            await SqlFunction.DoSql('DelJobSubOnkosten',subdata);
           
            if (rowpinnend && rowpinnend.save === true)
            {
                rowpinnend = null;
            }
            rowpinnend = null;
        }
        return;
    }

    async CellCheck(params,waarde,datacol) {
        if (!waarde) {return}
        if (this.state.save === true) {return}
        var colId = params.props.column.getId();
        params.props.data.save = true;
        let terug = false
        let totaal = 0
        let bedrag = 0
        let btw = 0
        let mvh = 0
        if (params.props.data.btw) 
        {
            if (params.props.data.btw.constructor === String)
            {
                btw = parseFloat(params.props.data.btw);           
                if (isNaN(btw)) {btw = 0}
            }
            else
            {
                btw = params.props.data.btw;           
            }
        }
        if (params.props.data.mvh) 
        {
            if (params.props.data.mvh.constructor === String)
            {
                mvh = parseFloat(params.props.data.mvh);           
                if (isNaN(mvh)) {mvh = 0}
            }
            else
            {
                mvh = params.props.data.mvh;           
            }
        }
        if (colId === 'type_onkost_txt')
        {
                let dirreg = 100
                if (datacol)
                {
                    dirreg = (datacol.dirreg.constructor === String) ? parseFloat(datacol.dirreg) : datacol.dirreg;
                    if (isNaN(dirreg)) {dirreg = 100}
                }
                params.props.data.toegperc = dirreg;

        }


        if (colId === 'mvh')
        {
            bedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(bedrag)) {bedrag = 0}
            totaal = bedrag + btw
            params.props.data.totaal = totaal;
        }    
        if (colId === 'btw')
        {
            bedrag = (waarde.constructor === String) ? parseFloat(waarde) : waarde;
            if (isNaN(bedrag)) {bedrag = 0}
            totaal = bedrag + mvh
            params.props.data.totaal = totaal;
        }    
        if (params.props.node.rowPinned === 'top')
        {
            rowpinnend = params.props.data;
            rowpinnend.soort = 'ONKOSTEN' 
        }
        params.props.node.setData(params.props.data);        

        await params.setState({error:terug})
        await this.CheckSaveOnkost(params);
    }       


    componentDidMount(){
        this.setState({data:this.props.data, kol:this.props.kol, griddata:this.props.subonkosten})
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };

    async handleSubSave(){
        let save_subdata = [];
        let retdata = null;
        gridapi.api.forEachNode(function(node) { 
            if (node.data.type_onkost_txt && node.data.type_onkost_txt.length > 2)
            {
                save_subdata.push(node.data)            
            }
        });   
        let savemain = this.state.data
        savemain.job_id = this.props.jobid;
        savemain.verwijder = '2';
        savemain.country = this.props.country;
        let savedata = {data:savemain, subonkosten:save_subdata}
        retdata = await SqlFunction.DoSql('ChgJobOnkosten',savedata);
        if (retdata)
        {
            retdata = await SqlFunction.DoSql('GetJobOnkost',retdata);
        }
        if (retdata && retdata.data && retdata.subonkosten) 
        {
            this.setState({data:retdata.data, griddata:retdata.subonkosten})
        }

        let rij = [{id:0,onk_id:0,type_onkost:'',mvh:'',btw:'',totaal:'',type_onkost_txt:'',save:'',verwijder:'',toegperc:100}]        
        gridapi.api.updateRowData({ add: rij})
        var lastrow = this.state.griddata.length;
        gridapi.api.setFocusedCell(lastrow, 'type_onkost_txt',);  
        gridapi.api.startEditingCell({
            rowIndex: lastrow,
            colKey: 'type_onkost_txt',
        });    
        var params = {
          force: true,
        };

    }

    async handleSave(){
        let save_subdata = [];
        gridapi.api.forEachNode(function(node) { 
            if (node.data.type_onkost_txt && node.data.type_onkost_txt.length > 2)
            {
                save_subdata.push(node.data)            
            }
        });   


        let savemain = this.state.data
        savemain.job_id = this.props.jobid;
        savemain.verwijder = '2';
        savemain.country = this.props.country;
        let savedata = {data:savemain, subonkosten:save_subdata}
        await SqlFunction.DoSql('ChgJobOnkosten',savedata);
        bewaren = false
        if (this.props.close) 
        {
            this.props.close()
        }
    }
    CheckSaveOnkost(params) {
      let terug = false
      bewaren = true
      if (terug === false)
      {
        terug = Validation.ValidCheck('JobOnkost', params.props.data.type_onkost, 'type_onkost', params, gridapi);
      }
      let totaal = 0;
          gridapi.api.forEachNode(function(node) { 
            let nodtot = 0;
            if (node.data.totaal.constructor === String)
            {
                nodtot = parseFloat(node.data.totaal);           
                if (isNaN(nodtot)) {nodtot = 0}
            }
            else
            {
                nodtot = node.data.totaal
            }
            totaal = totaal + nodtot;
          });   

      let statetotaal = 0
        if (this.state.data.totaal.constructor === String)
        {
            statetotaal = parseFloat(this.state.data.totaal);           
            if (isNaN(statetotaal)) {statetotaal = 0}
        }
        else
        {
            statetotaal = this.state.data.totaal
        }

        if (totaal > statetotaal) {terug = true}

      if (terug === true)
      {
          params.props.data.save = false;
      }
      else
      {
        params.props.data.save = true;
      }
      params = {
          force: true,
      };
      gridapi.api.refreshCells(params)          
    }

    componentWillUnmount() 
    {
        this.checkconfirm();    

    }

    checkconfirm () 
    {
        if (bewaren === false) {return}
        gridapi.api.forEachNode(function(node) { 
            if (node.data.save === true)
            {
                bewaren = true
            }
        });   

        if (bewaren === true)
        {
          confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,        
          customUI: ({ onClose }) => {
            return (
              <div className='custom-ui'>
                <h1>Gegevens zijn nog niet bewaard.</h1>
                <p>Wens je de aanpassingen alsnog te bewaren?</p>
                <button
                  onClick={() => {
                    this.handleSave();
                    onClose();
                  }}
                >
                  Ja
                </button>
                <button onClick={() => {
                    if (this.props.close) 
                    {
                        this.props.close()
                    }                    
                    onClose();
                  }}
                >
                  Nee
                </button>
              </div>
            );
          }
          });
        }

    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var tableheight = Math.floor(window.innerHeight * .50) + 'px';
        var top = (this.state.nivo === 0) ? '50px' : (50+(50 * this.state.nivo)) + 'px'
        var left = (20 * this.state.nivo) + 'px'
        var bottom = (this.state.nivo === 0) ? '10px' : top;

        return (

            <Dialog
                fullWidth={true}
                maxWidth='xl'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'400px',position: 'absolute'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">{this.props.title}</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'scroll',height:'60vh'}}  className={classes.blockContent}>
                  <Grid container style={{width: '100%', padding: '14px 10px', margin: '-15px -27px 35px'}}>
                    <Grid container>
                        <Grid style={{paddingLeft: '20px', paddingRight: '20px'}} item sm={6} md={6} lg={6}>
                            <Grid item sm={12} md={12} lg={12}>
                              <EditAutoSelect className="card-title m-t-10"
                                value={this.state.data.supplier_txt}
                                labelText="Leverancier"
                                lijst='AllSuppliers'
                                colId="supplier_txt"
                                selectcel='supplier_id'
                                addRecord={this.addRecord}
                                id={this.state.data.supplier_id}
                                ValueCheck={(params,value,type,overzdata) => this.ValueCheck(params,value,type,"supplier_txt",true,overzdata)}
                                setError={(params,value,type) => this.ErrorCheck(params,value,type,'supplier_txt')}
                              />
                              <h6>{this.state.data.sup_vatnumber}</h6>
                              <h6>{this.state.data.supplier_txt}</h6>
                              <h6>{this.state.data.sup_adres}</h6>
                              <h6>{this.state.data.sup_zipcode}</h6>
                              <h6>{this.state.data.sup_country_txt}</h6>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <EditString className="card-title m-t-10"
                                    value={this.state.data.factnum}
                                    coltype = 'STR'                        
                                    labelText="Factuurnummer"
                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'factnum')}
                                    setError={(params,value,type) => this.ErrorCheck(params,value,type,'factnum')}
                                />
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <EditDateTime className="card-title m-t-10"
                                    value={this.state.data.factdat}
                                    coltype = 'STR'                        
                                    labelText="Factuur datum"
                                    inputProps={{value:this.state.data.factdat}}
                                    formControlProps={{fullWidth: false}}
                                    calstyle={{width:'50%', paddingLeft: '20px', paddingRight: '20px'}}
                                    ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'factdat',false)}
                                    setError={(params,value,type) => this.ErrorCheck(params,value,type,'factdat')}
                                />
                            </Grid>
                        </Grid>
                        <Grid style={{paddingLeft: '20px', paddingRight: '20px'}} item sm={6} md={6} lg={6}>
                            <Grid item sm={12} md={12} lg={12}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.mvh}
                                  labelText="MVH"
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'mvh',false)}
                                  setError={(params,value,type) => this.ErrorCheck(params,value,type,'mvh')}

                                />
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.btw}
                                  labelText="BTW"
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  setError={(params,value,type) => this.ErrorCheck(params,value,type,'btw')}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'btw',false)}
                                />
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <EditString className="card-title m-t-10"
                                  value={this.state.data.totaal}
                                  labelText="Totaal"
                                  coltype = 'NUM'                        
                                  decimal={4}
                                  setError={(params,value,type) => this.ErrorCheck(params,value,type,'totaal')}
                                  ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'totaal',false)}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sm={12} md={12} lg={12}>
                          <TableGrid
                            tableheight={tableheight}
                            columns={this.state.kol}
                            data={this.state.griddata} 
                            sortable={true}
                            sizetofit={true}
                            suppressMenu={true}
                            enableRowGroup={false}
                            suppressColumnVirtualisation={true}
                            CellClickedEvent={(column, colDef, value) => this.CellClick(column,colDef, value)}
                            CellValueCheck={(params,value,error) => this.CellCheck(params,value,error)}
                            filter={false}
                            getFilter={(params) => this.getFilter(params)}
                            headerComponentParams = {null}
                            editable={true}
                            sleutel="JobSubOnkost"      
                            GridReady={this.onGridReady}
                          />

                    </Grid>
                  </Grid>
                  {this.state.PopUpScreen}
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  <Button disabled={this.state.save} style={{marginBottom: '30px'}} color="primary"  onClick={() => this.handleSave()}>
                    Bewaren
                  </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {

    }
}

PopJobEditSubOnkosten.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobEditSubOnkosten)));

