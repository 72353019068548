import React from 'react';
import moment from 'moment'
import 'moment/locale/nl-be'
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import gdprPopupStyle from "../../assets/views/gdprPopupStyle";
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide/Slide";
import EditString from "../../components/editstring.jsx";
import EditAutoSelect from "../../components/editautoselect.jsx";
import EditSelect from "../../components/editselect.jsx";
import EditCheck from "../../components/editcheck.jsx";

import SqlFunction from "../../functions/sql_functions";
import GenFunc from "../../functions/gen_functions";
import {AllLandenAcc, AllPeriodesAcc} from "../../redux/settings/landActions";

var countryobj = null;
var periodeobj = null;

const mapStateToProps = state => ({
  ...state
});

const PdfListingKlant = GenFunc.MakeId()

class PopJobKlantDirInfo extends React.Component {
    state = {
        jaar: this.props.jaar,
        account_name:null, 
        land_txt:null, 
        type_txt:null,
        data: this.props.data,
        filename:'',
        save:false,
        tabvalue:'1',
        StatFileUpload: 'ok',
        refid: GenFunc.MakeId(),
    }

    constructor(props, context) {
        super(props, context);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.handlePdfListingKlant = this.handlePdfListingKlant.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.ValueCheck = this.ValueCheck.bind(this);
        this.SetObject = this.SetObject.bind(this);

    }
    async componentDidMount(){
        if (this.props.data && this.props.data.account_id && this.props.data.account_id.length > 4)
        {
          await this.props.dispatch(AllLandenAcc(this.props,this.props.data.account_id,1));
          if (countryobj)
          {  
            countryobj.loaddata()
          }
        }
        this.setState({data:this.props.data, jaar:this.props.jaar})
    }
    
    SetObject(object,colid)
    {
        switch (colid) 
        {
          case 'country_txt':
            countryobj = object;
            break;

          case 'periode_txt':
            periodeobj = object;
            break;

          default:    
            break;
        } 
    }

    handleClose = () => {
        if (this.props.close) {
            this.props.close()
        }
    };


    async ValueCheck(that,waarde,type,colId) {
      let data = this.state.data;
      let save = false;
      switch (colId) 
      {
        case 'country_txt':
        case 'country':
            if (data.country !== waarde.id && type !== 'DIDMOUNT')
            {
                if (that.overzdata)
                {
                  data.country_txt = waarde.value;
                  data.country = waarde.id;
                }
                else
                {
                  if (that.state.clear === true)
                  {
                    data.country_txt = '';
                    data.country = '';
                  }
                }
            }
            break;


        default:    
          data[colId] = waarde;
          break;
      } 
      if (data.jaar && data.jaar > 2015) {save = true}
      this.setState({data:data,save:save})
    }       


    async handlePdfListingKlant(){
      console.log(this.state.data)

      document.getElementById(PdfListingKlant).submit();
    }


    transition(props) {
        return <Slide direction={"up"} { ...props }/>;
    }


    render() {
        const {classes, open} = this.props;
        var top = '50px';
        var left = '0px';
        var bottom = '10px';

        let vld_pdf_list_klant = (<form id={PdfListingKlant} action=" https://app.brutax.com/server/index.php" method="post" encType="multipart/form-data" target="_blank" style={{width: '100%'}} >
        <input style={{display:'none'}} type="text" defaultValue='DirectOverzicht' id="functionlist" name="function"/>
        <input style={{display:'none'}} type="text" defaultValue={this.state.data && this.state.data.account_id} id="idlist" name="id"/>
        <input style={{display:'none'}} type="text" value={this.state.data && this.state.data.country} defaultValue={this.state.data && this.state.data.country} id="idcountry" name="country"/>
        <input style={{display:'none'}} type="text" value={this.state.data && this.state.data.jaar} defaultValue={this.state.data && this.state.data.jaar} id="idjaar" name="jaar"/>
        <input style={{display:'none'}} type="text" value={this.state.data && this.state.data.jaaropgave} defaultValue={this.state.data && this.state.data.jaaropgave} id="idjaaropgave" name="jaaropgave"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="secidlist" name="secid"/>
        <input style={{display:'none'}} type="text" defaultValue={JSON.parse(localStorage.getItem('secid'))} id="clsecidlist" name="clsecid"/>
        </form>);  

        return (
            <Dialog
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={this.handleClose}
                style={{overflow: "visible", right:'10px', zIndex:'1001',left: left,marginBottom:'40px',top:top, bottom:bottom,minHeight:'600px',position: 'fixed'}}
                scroll='paper'
                aria-labelledby="gdpr-dialog-title"
                disableBackdropClick
                disableEscapeKeyDown                
                aria-describedby="notice-modal-slide-description"
                id={"Dia"+this.state.refid}
            >
                <DialogTitle
                    id={"DiaTit"+this.state.refid}
                    disableTypography
                    className={classes.modalHeader}
                >
                  <Typography style={{width:'80%', float:'left'}} variant="h6">Overzicht lijst directe registratie</Typography>                
                  <IconButton aria-label="close" style={{padding:'0px', float:'right'}} className={classes.closeButton} onClick={() => this.handleClose()}>
                    <CloseIcon />
                  </IconButton>                
                </DialogTitle>
                <DialogContent  id={"DiaCont"+this.state.refid} style={{paddingTop: '15px',overflow:'hidden'}}  className={classes.blockContent}>
                  <Grid container spacing={1}>
                    <Grid item sm={12} md={12} lg={12}>
                      <div style={{width:'100%',textAlign:'left', marginLeft:'-20px',marginTop: '10px'}}>
                        <EditCheck className="card-title m-t-10"
                          value={this.state.data.jaaropgave}
                          labelPlacement="start"
                          labelText="Jaaropgave"
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'jaaropgave')}
                        />
                      </div>
                    </Grid>

                    <Grid item sm={12} md={12} lg={12}>
                      <EditString className="card-title m-t-10"
                        value={this.state.data.jaar}
                        coltype = 'STR' 
                        format = {[/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}
                        labelText="Jaar"
                        ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,'jaar')}
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                        <EditAutoSelect className="card-title m-t-10"
                          value={this.state.data.country_txt}
                          labelText="Land"
                          lijst='AllLandenAcc'
                          colId="country_txt"
                          selectcel='country'
                          id={this.state.data.country}
                          SetObject = {(object,colid) => this.SetObject(object,"country_txt")} 
                          ValueCheck={(params,value,type) => this.ValueCheck(params,value,type,"country_txt",true)}
                        />
                    </Grid>
                  </Grid>
                  {vld_pdf_list_klant}
                </DialogContent>
                <DialogActions id={"DiaAct"+this.state.refid}>
                  {this.state.data.jaar.length > 3 && this.state.data.country.length > 2 &&
                    <Button color="primary"  onClick={() => this.handlePdfListingKlant()}>
                      Maken Lijst
                    </Button>
                  }
                </DialogActions>
            </Dialog>
        );
    }
}


PopJobKlantDirInfo.propTypes = {
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default withStyles(gdprPopupStyle)(withMobileDialog()(connect(mapStateToProps)(PopJobKlantDirInfo)));

